import React,{useState,useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";




//Own
import InfoCard from "../components/info-card";
import API from "../../utils/api";

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2)
  },
  infoContainer:{
padding:8,
  }
}));

export default function UserInfo(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [nombreCompleto,setNombreCompleto] = useState("");
  
  const [imagenPerfil,setImagenPerfil] = useState("");
  const [email,setEmail] = useState("");
  const [telefono,setTelefono] = useState("");
  

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(()=>{
    const cargarPerfil = async()=>{
        const request = await API.fetchGetRequest(`api/perfil/${props.userName}`);
        try {
          if (request.data.data !==null) {
            setImagenPerfil(request.data.data.usuario.ImagenPerfil);
            setNombreCompleto(request.data.data.usuario.NombreCompletoUsuario);
            setEmail(request.data.data.usuario.CorreoElectronico);
            setTelefono(request.data.data.usuarioAD.Telefono);
          }else{
            setNombreCompleto("");
            setEmail("");
            setTelefono("");
            setImagenPerfil("")
          }
        } catch (error) {
          console.log("cargarPerfil error:" + error);
        }
      }
      cargarPerfil();
      },[props.userName]);

  return (
    <div>
      <span onClick={handleClick} style={{ cursor: "pointer" }}>
        {props.children}
      </span>

      <div>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          {/* <Typography variant="h5">Perfil</Typography>
          {props.userName}
          <div className={classes.infoContainer}>
            <Grid container>
              <Grid item xs={3} sm={3} md={3} lg={3}>
                <AccountCircleIcon />
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={8}>
                {nombreCompleto}
              </Grid>
            </Grid>
          </div>

          <div>{email}</div>
          <div>{telefono}</div>
          <div>{props.userName}</div> */}
          <InfoCard
          imagenPerfil = {imagenPerfil}
          userName={props.userName}
          nombreCompleto = {nombreCompleto}
          email = {email}
          telefono = {telefono}
          />
        </Popover>
      </div>
    </div>
  );
}
