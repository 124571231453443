import React, { useState, useEffect } from 'react'
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
  IconButton,
  Fab,
  Tooltip,
  Dialog,
  makeStyles
} from '@material-ui/core'
import {
  Alert
} from '@material-ui/lab'
import { toast, ToastContainer } from 'react-toastify'
import { Add, Delete } from '@material-ui/icons'
import { useStoreActions, useStoreState } from 'easy-peasy'

import Layout from '../layout/containers/Layout'
import LoadingButton from './componentes/utils/LoadingButton'
import { getUsuarioInserta } from './utils/utilidades'
import api from '../utils/api'
import CrearRol from './componentes/usuarios/CrearRol'

const useStyles = makeStyles((theme) => ({
  list: {
    width: '100%',
    maxWidth: 360,
    height: '70vh',
    backgroundColor: theme.palette.background.paper,
    overflowY: 'auto'
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  }
}))

const PermisosRoles = () => {
  const classes = useStyles()
  const {
    agrupacion,
    permisos,
    roles,
    permisosRol
  } = useStoreState(state => ({
    agrupacion: state.agrupacion,
    permisos: state.permisos,
    roles: state.roles,
    permisosRol: state.permisosRol
  }))
  const {
    getAgrupacion,
    getPermisos,
    getRoles,
    getPermisosRol
  } = useStoreActions(actions => ({
    getAgrupacion: actions.getAgrupacion,
    getPermisos: actions.getPermisos,
    getRoles: actions.getRoles,
    getPermisosRol: actions.getPermisosRol
  }))
  const [selected, setSelected] = useState(null)
  const [listaPermisos, setListaPermisos] = useState([])
  const [loading, setLoading] = useState(false)
  const [dialogCrear, setDialogCrear] = useState(false)
  const selectRol = (rol) => {
    const tmp = { ...rol }
    getPermisosRol(tmp.CodigoRol)
    setSelected(tmp)
  }
  const addPermiso = (permiso) => {
    const tmp = {
      id: permiso.CodigoPermiso,
      label: permiso.Permiso
    }
    if (selected && !listaPermisos.find(i => i.id === tmp.id)) {
      const arr = [...listaPermisos, tmp]
      setListaPermisos(arr)
    }
  }
  const removePermiso = (permisoRol) => {
    const tmp = listaPermisos.filter(i => i.id !== permisoRol.id)
    setListaPermisos(tmp)
  }
  const savePermisos = async () => {
    try {
      setLoading(true)
      const body = {
        ...selected,
        UsuarioModifico: getUsuarioInserta(),
        PermisosRol: listaPermisos
      }
      await api.post(`api/desktop/rol/${selected.CodigoRol}`, body)
    } catch (error) {
      toast.error('Ha ocurrido un error')
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getAgrupacion()
    getPermisos()
    getRoles()
    setListaPermisos([])
  }, [])
  useEffect(() => {
    setListaPermisos(permisosRol)
  }, [permisosRol])
  return <Layout titulo="Permisos por rol">
    <Grid container justify="center">
      <Grid item xs={12} md={10}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Typography variant="h6">Roles</Typography>
            <List className={classes.list}>
              {
                roles.map((i, idx) => <ListItem
                  button
                  key={idx}
                  onClick={() => selectRol(i)}
                >
                  <ListItemText primary={i.Rol} secondary={i.Descripcion} />
                </ListItem>)
              }
            </List>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h6">Permisos</Typography>
            <List className={classes.list}>
              {
                permisos.map((i, idx) => <ListItem key={idx}>
                  <ListItemText primary={i.Permiso} secondary={i.Descripcion} />
                  <ListItemIcon>
                    <IconButton edge="end" onClick={() => addPermiso(i)}>
                      <Add />
                    </IconButton>
                  </ListItemIcon>
                </ListItem>)
              }
            </List>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h6">Permisos por rol</Typography>
            <List className={classes.list}>
              {
                listaPermisos.map((i, idx) => <ListItem key={idx}>
                  <ListItemText primary={i.label} />
                  <ListItemIcon>
                    <IconButton edge="end" onClick={() => removePermiso(i)}>
                      <Delete />
                    </IconButton>
                  </ListItemIcon>
                </ListItem>)
              }
              {
                !selected && <Alert severity="info" variant="filled">
                  Selecciona un rol
                </Alert>
              }
            </List>
            <LoadingButton
              loading={loading}
              fullWidth
              variant="contained"
              color="primary"
              style={{marginTop: 10}}
              onClick={savePermisos}
            >
              Guardar
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    <ToastContainer />
    <Tooltip title={"Agregar rol"}>
      <Fab
        onClick={() => setDialogCrear(true)}
        style={{ backgroundColor: "#69BD4B", color: 'white' }}
        className={classes.fab}
        color="primary"
      >
        <Add />
      </Fab>
    </Tooltip>
    <Dialog open={dialogCrear}>
      <CrearRol
        onClose={() => setDialogCrear(false)}
        onRefresh={() => {
          getRoles()
          setDialogCrear(false)
        }}
      />
    </Dialog>
  </Layout>
}

export default PermisosRoles