import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";


//Own components
import Layout from "../layout/containers/Layout";
import PlantillaListTable from "./plantilla-list-table";
import AddButton from "../helpers/components/add-button-float";
import API from "../utils/api";
import { isSignedIn } from "../utils/auth";
import {validaPermisoPaginaActual} from "../utils/helpers";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%"
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none"
  },
  buttonAdd: {
    margin: theme.spacing.unit
  }
});

class PlantillaList extends Component {
  state = {
    open: false,
    actionType: "", //Crear, Actualizar
    codigo: "",
    nombre: "",
    descripcion: "",
    activo: "",
    codigoEntidad:null,
    plantillas: [],
    entidades:[],
  };

  async componentWillMount() {

        //CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
        const llaveAgrupacion = "Menu.Principal.Web";
        const llavePagina = "Menu.Principal.Web.Flujos";
        await validaPermisoPaginaActual(llaveAgrupacion, llavePagina);
        //FIN DE CONSULTA DE PERMISOS

    this.cargarLista();
    this.cargarListaEntidades();
  }
  async cargarLista() {
    const request = await API.fetchGetRequest("api/flujo/listar");

    this.setState({
      plantillas: request.data.data
    });
  }

  async cargarListaEntidades() {
    const request = await API.fetchGetRequest("api/entidad/listarCombo");

    this.setState({
      entidades: request.data.data
    });
  }


  async cargarDatosEdicion() {
    const request = await API.fetchGetRequest(
      "api/flujo/cargarDatosEdicion/" + this.state.codigo
    );

    this.setState({
      codigo: request.data.data.CodigoPlantillaTareas,
      nombre: request.data.data.Nombre,
      descripcion: request.data.data.Descripcion,
      activo: request.data.data.Activo,
      codigoEntidad:request.data.data.CodigoEntidad===null?"":request.data.data.CodigoEntidad,

    });
  }

  editar = codigo => {
    this.setState(
      {
        codigo: codigo,
        actionType: "Actualizar"
      },
      async () => {
        await this.cargarDatosEdicion();
        this.handleOpenAddModal();
      }
    );

    
  };

  handleOpenAddModal = () => {
    this.cargarListaEntidades();
    
    this.setState({
      open: true
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
      codigo: "",
      nombre: "",
      descripcion: "",
      activo: "",
      codigoEntidad:"",
      actionType: ""
    });
  };

  handleChange = name => event => {
    if (name == "activo") {
      this.setState({ [name]: event.target.checked });
    } else {
      this.setState({
        [name]: event.target.value
      });
    }
  };

  redireccionar = (codigo)=>{
    this.props.history.push("/flujo/" + codigo);
  }

  guardar= async () => {
    const { nombre, usuario, email, autenticacionWindows } = this.state;

    if (nombre.trim() == "") {
      toast.warn("Ingrese un nombre válido");
      return;
    }

    try {
      let vIsSignedIn = await isSignedIn();
      let palabra = "";
      if (vIsSignedIn.response) {
        let request;
        let data;

        if (this.state.actionType == "Agregar") {
          data = {
            CodigoPlantillaTareas: this.state.codigo,
            Nombre: this.state.nombre,
            Descripcion: this.state.descripcion,
            Activo: true,
            UsuarioOpera: vIsSignedIn.userName,
            CodigoEntidad:this.state.codigoEntidad===""?null:this.state.codigoEntidad,
          };
          palabra = "grabado";
        } else {
          data = {
            CodigoPlantillaTareas: this.state.codigo,
            Nombre: this.state.nombre,
            Descripcion: this.state.descripcion,
            Activo: this.state.activo,
            UsuarioOpera: vIsSignedIn.userName,
            CodigoEntidad:this.state.codigoEntidad===""?null:this.state.codigoEntidad,
            
          };
          palabra = "editado";
        }

        request = await API.post(`api/flujo/grabarEditar`, data);
        if (request.status != 200) {
          alert(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        }

        if (request.statusCode === 401) {
          //alert("cerrar sesion");
        } else {
          toast.success("El registro se " + palabra + " exitosamente.");
        
        //Redirecciona cuando la acción es grabar
          if(this.state.actionType == "Agregar"){
                                  // this.props.history.push("/entidad/" + request.data.data.Codigo);
                                  this.redireccionar(request.data.data.CodigoPlantillaTareas);
                                }else{
                                  this.handleClose();
                                  this.cargarLista();
                                } 
         
        }
      }
    } catch (e) {
      alert("hubo un error");
    }
  };

  
  eliminar = async codigo => {
    let vIsSignedIn = await isSignedIn();

    if (vIsSignedIn.response) {
      const request = await API.post(
        `api/flujo/eliminar/${codigo}/${vIsSignedIn.userName}`
      );
      if (request.status != 200) {
        alert(
          "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
        );
      }

      if (request.statusCode === 401) {
        //alert("cerrar sesion");
      } else {
        toast.success("El registro se eliminó exitosamente.");
        this.cargarLista();
      }
    } else {
      toast.error("La sessión no se encuentra activa");
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <Layout titulo="FLUJOS">
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12} sm={12}>
            <PlantillaListTable
              data={this.state.plantillas}
              editar={this.editar}
              eliminar={this.eliminar}
              redireccionar={this.redireccionar}
            />
          </Grid>
        </Grid>

        <AddButton
          onClick={() => {
            this.setState({ actionType: "Agregar" }, this.handleOpenAddModal());
          }}
        />

        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.open}
          onClose={this.handleClose}
        >
          <div style={getModalStyle()} className={classes.paper}>
            <form className={classes.container} noValidate autoComplete="off">
              <Typography variant="title" gutterBottom>
                {this.state.actionType == "Agregar" ? (
                  <div>Crear</div>
                ) : (
                  <div>Editar</div>
                )}
              </Typography>
              <TextField
                required
                id="txtNombre"
                label="Nombre"
                className={classes.textField}
                value={this.state.nombre}
                onChange={this.handleChange("nombre")}
                margin="normal"
                variant="outlined"
                inputProps={{ maxLength: 512 }}
              />
              <TextField
                id="txtDescripcion"
                label="Descripción"
                className={classes.textField}
                value={this.state.descripcion}
                onChange={this.handleChange("descripcion")}
                margin="normal"
                variant="outlined"
                multiline
                inputProps={{ maxLength: 512 }}
              />
              {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl className={classes.formControl} style={{width:"100%"}}>
                  <InputLabel htmlFor="codigoEntidad">Entidad</InputLabel>
                  <Select
                    name="codigoEntidad"
                    inputRef={this.codigoEntidad}
                    value={this.state.codigoEntidad}
                    onChange={this.handleChange("codigoEntidad")}
                    input={
                      <Input
                        name="codigoEntidadCombo"
                        id="codigoEntidadCombo"
                      />
                    }
                  >
                    {this.state.entidades.map(item => (
                      <MenuItem key={item.Codigo} value={item.Codigo}>
                        {item.Nombre}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText />
                </FormControl>
              </Grid> */}

              {this.state.actionType === "Actualizar" ? (
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.activo}
                        onChange={this.handleChange("activo")}
                        value="activo"
                        color="primary"
                      />
                    }
                    label="Activo"
                  />
                  <br />
                </div>
              ) : (
                <div></div>
              )}
              <Grid container spacing={3}>
                <Grid
                  item
                  xs={12}
                  lg={12}
                  sm={12}
                  
                >
                  <Button
                    variant="contained"
                    style={{ backgroundColor: "#69bd4b", color: "white" }}
                    className={classes.buttonAdd}
                    onClick={() => this.guardar()}
                  >
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </Modal>
        <ToastContainer />
      </Layout>
    );
  }
}
const PlantillaListContainer = withStyles(styles)(PlantillaList);
export default withRouter(PlantillaListContainer);

