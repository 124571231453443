import React, { useState, useEffect } from 'react'
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  Fab,
  makeStyles,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField
} from '@material-ui/core'
import { Add, Delete, Edit } from '@material-ui/icons'
import { ToastContainer, toast } from 'react-toastify'
import Layout from '../layout/containers/Layout'
import Api from '../utils/api'
import { tipoDocumentoModel } from './utils/modelos'
import LoadingButton from './componentes/utils/LoadingButton'

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  }
}))

const TiposDocumentos = () => {
  const classes = useStyles()
  const [tipos, setTipos] = useState([])
  const [dialogAdd, setDialogAdd] = useState(false)
  const [dialogEdit, setDialogEdit] = useState(false)
  const [formAdd, setFormAdd] = useState({
    codigo: '',
    nombre: ''
  })
  const [formEdit, setFormEdit] = useState({
    codigo: '',
    nombre: ''
  })
  const [selected, setSelected] = useState(null)
  const [loading, setLoading] = useState(false)
  const getTipos = async () => {
    const { data } = await Api.fetchGetRequest('api/documentos/tipodocumento')
    setTipos(data)
  }
  const changeAdd = (dato, valor) => {
    const tmp = { ...formAdd }
    tmp[dato] = valor
    setFormAdd(tmp)
  }
  const changeEdit = (dato, valor) => {
    const tmp = { ...formEdit }
    tmp[dato] = valor
    setFormEdit(tmp)
  }
  const selectEdit = (id) => {
    const item = tipos.find(i =>  i.Id === id)
    setFormEdit({
      codigo: item.CodigoTipoDocumento,
      nombre: item.NombreTipoDocumento
    })
    setSelected({...item})
    setDialogEdit(true)
  }
  const updateTipo = async () => {
    try {
      setLoading(true)
      const datos = await tipoDocumentoModel.validate(formEdit)
      const usuario = JSON.parse(localStorage.getItem('userData'))
      const body = {
        codigoTipoDocumento: datos.codigo,
        nombreTipoDocumento: datos.nombre,
        usuarioModifico: usuario.usuarioAD
      }
      await Api.post(`api/documentos/tipodocumento/editar/${selected.Id}`, body)
      setDialogEdit(false)
      setSelected(null)
      getTipos()
    } catch(err) {
      if (err.name === 'ValidationError') {
        toast.error(err.errors[0])
      } else {
        toast.error('Ha ocurrido un error')
      }
    } finally {
      setLoading(false)
    }
  }
  const insertTipo = async () => {
    try {
      setLoading(true)
      const datos = await tipoDocumentoModel.validate(formAdd)
      const usuario = JSON.parse(localStorage.getItem('userData'))
      const body = {
        codigoTipoDocumento: datos.codigo,
        nombreTipoDocumento: datos.nombre,
        usuarioInserto: usuario.usuarioAD
      }
      await Api.post('api/documentos/tipodocumento/crear', body)
      setDialogAdd(false)
      setFormAdd({
        codigo: '',
        nombre: ''
      })
      getTipos()
    } catch(err) {
      if (err.name === 'ValidationError') {
        toast.error(err.errors[0])
      } else {
        toast.error('Ha ocurrido un error')
      }
    } finally {
      setLoading(false)
    }
  }
  const borrarTipo = async (id) => {
    try {
      if (window.confirm('¿Estas seguro de borrar el registro?')) {
        const usuario = JSON.parse(localStorage.getItem('userData'))
        await Api.post(`api/documentos/tipodocumento/desactivar/${id}`, {
          usuarioModifico: usuario.usuarioAD
        })
        getTipos()
      }
    } catch (error) {
      console.log(error)
      toast.error('Ha ocurrido un error')
    }
  }
  useEffect(() => {
    getTipos()
  }, [])
  return <Layout titulo="Tipos de documentos">
    <Grid container justify="center">
      <Grid item xs={12} md={6}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Código</TableCell>
                <TableCell>Tipo de documento</TableCell>
                <TableCell>Opciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                tipos.map((i, idx) => <TableRow key={idx}>
                  <TableCell>{i.CodigoTipoDocumento}</TableCell>
                  <TableCell>{i.NombreTipoDocumento}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => selectEdit(i.Id)}>
                      <Edit />
                    </IconButton>
                    <IconButton onClick={() => borrarTipo(i.Id)}>
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>)
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
    {/* ------------------------------ Action button ----------------------------- */}
    <Fab
      onClick={() => setDialogAdd(true)}
      style={{ backgroundColor: "#69bd4b", color: "white" }}
      className={classes.fab}
    >
      <Add />
    </Fab>
    {/* --------------------------------- Guardar -------------------------------- */}
    <Dialog
      fullWidth
      maxWidth="sm"
      open={dialogAdd}
      onClose={() => setDialogAdd(false)}
    >
      <DialogTitle>Agregar nuevo tipo de documento</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          label="Código de tipo de documento"
          value={formAdd.codigo}
          onChange={ev => changeAdd('codigo', ev.target.value)}
        />
        <TextField
          fullWidth
          label="Tipo de documento"
          value={formAdd.nombre}
          onChange={ev => changeAdd('nombre', ev.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDialogAdd(false)}>Cancelar</Button>
        <LoadingButton loading={loading} onClick={insertTipo} color="primary">Guardar</LoadingButton>
      </DialogActions>
    </Dialog>
    {/* --------------------------------- Editar --------------------------------- */}
    <Dialog
      fullWidth
      maxWidth="sm"
      open={dialogEdit}
      onClose={() => setDialogEdit(false)}
    >
      <DialogTitle>Editar tipo de documento</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          label="Código de tipo de documento"
          value={formEdit.codigo}
          onChange={ev => changeEdit('codigo', ev.target.value)}
        />
        <TextField
          fullWidth
          label="Tipo de documento"
          value={formEdit.nombre}
          onChange={ev => changeEdit('nombre', ev.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDialogEdit(false)}>Cancelar</Button>
        <LoadingButton loading={loading} onClick={updateTipo} color="primary">Guardar</LoadingButton>
      </DialogActions>
    </Dialog>
    <ToastContainer />
  </Layout>
}

export default TiposDocumentos
