import React, { useState, useEffect } from 'react'
import {
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Grid,
  Divider
} from '@material-ui/core'
import { toast } from 'react-toastify'
import api from '../../../utils/api'
import SelectUsuario from '../usuarios/SelectUsuario'

const FormularioExterno = ({ onChange, listUsuarios = [] }) => {
  const [masters, setMasters] = useState({
    tipos: [],
    // centros: []
  })
  const [datos, setDatos] = useState({
    Edicion: '',
    NumeroUnidades: '',
    AnioLanzamiento: '',
    AutorOriginal: '',
    DuenioOriginal: '',
    FechaIngreso: '',
    ISBN: '',
    // IdCentroDocumental: '',
    CodigoResponsable: '',
    IdTipoDocumentoExterno: ''
  })
  const [tipo, setTipo] = useState('')
  const [isLibro, setIsLibro] = useState(false)
  const getMasters = async () => {
    try {
      const reqTipos = api.fetchGetRequest('api/documentos/tipo-documento-externo')
      // const reqCentros = api.fetchGetRequest('api/documentos/centro-documental')
      const responses = await Promise.all([reqTipos])
      setMasters({
        tipos: responses[0].data,
        // centros: responses[1].data.map(i => ({
        //   ...i,
        //   CodigoCentroDocumental: `CD-${i.AbreviaturaDivision}-${i.Macroproceso.AbreviaturaProceso}-${i.Correlativo}`
        // }))
      })
    } catch (error) {
      toast.error('Ha ocurrido un error')
    }
  }
  const changeDatos = (key, value, parseTo) => {
    const tmp = {...datos}
    let parsed = null
    switch (parseTo) {
      case 'int':
        parsed = parseInt(value)
        break
      case 'float':
        parsed = parseFloat(value)
        break
      default:
        parsed = value
        break
    }
    tmp[key] = parsed
    setDatos(tmp)
    onChange(tmp)
  }
  useEffect(() => {
    const selected = masters.tipos.find(i => i.Nombre === 'Libro')
    setIsLibro(selected && selected.Id === datos.IdTipoDocumentoExterno)
  }, [datos])
  useEffect(() => {
    getMasters()
  }, [])
  return <>
    <Grid item xs={12}>
      <Divider />
    </Grid>
    <Grid item xs={12} md={6}>
      <FormControl fullWidth>
        <InputLabel>Tipo de archivo</InputLabel>
        <Select
          value={datos.IdTipoDocumentoExterno}
          onChange={ev => changeDatos('IdTipoDocumentoExterno', ev.target.value, 'int')}
        >
          {
            masters.tipos.map(i => <MenuItem key={i.Nombre} value={i.Id}>{i.Nombre}</MenuItem>)
          }
        </Select>
      </FormControl>
    </Grid>
    <Grid item xs={12} md={6}>
      <TextField
        value={datos.Edicion}
        onChange={ev => changeDatos('Edicion', ev.target.value)}
        fullWidth
        label="Versión/edición"
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <TextField
        fullWidth
        label="No. de unidades"
        value={datos.NumeroUnidades}
        onChange={ev => changeDatos('NumeroUnidades', ev.target.value)}
        type="number"
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <TextField
        fullWidth
        label="Año de lanzamiento"
        value={datos.AnioLanzamiento}
        onChange={ev => changeDatos('AnioLanzamiento', ev.target.value)}
        type="number"
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <TextField
        fullWidth
        label="Autor original"
        value={datos.AutorOriginal}
        onChange={ev => changeDatos('AutorOriginal', ev.target.value)}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <TextField
        fullWidth
        label="Dueño original del documento"
        value={datos.DuenioOriginal}
        onChange={ev => changeDatos('DuenioOriginal', ev.target.value)}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <TextField
        fullWidth
        label="Fecha de ingreso"
        value={datos.FechaIngreso}
        onChange={ev => changeDatos('FechaIngreso', ev.target.value)}
        type="date"
        InputLabelProps={{ shrink: true }}
      />
    </Grid>
    {
      isLibro && <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="ISBN"
          value={datos.ISBN}
          onChange={ev => changeDatos('ISBN', ev.target.value)}
        />
      </Grid>
    }
    {/* <Grid item xs={12} md={6}>
      <FormControl fullWidth>
        <InputLabel>Ubicación actual (centro documental)</InputLabel>
        <Select
          value={datos.IdCentroDocumental}
          onChange={ev => changeDatos('IdCentroDocumental', ev.target.value, 'int')}
        >
          {
            masters.centros.map(i => <MenuItem
                key={i.CodigoCentroDocumental}
                value={i.IdCentroDocumental}
              >
                {i.CodigoCentroDocumental} - {i.Ubicacion}
              </MenuItem>)
          }
        </Select>
      </FormControl>
    </Grid> */}
    <Grid item xs={12} md={6}>
      <SelectUsuario
        label="Responsable"
        onSelect={selected => changeDatos('CodigoResponsable', selected)}
      />
    </Grid>
    {/* <Grid item xs={12} md={6}>
      <TextField
        fullWidth
        label="Nota"
        multiline
        rows={4}
      />
    </Grid> */}
    <Grid item xs={12}>
      <Divider />
    </Grid>
  </>
}

export default FormularioExterno
