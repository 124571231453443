import React, { useState, useEffect } from "react";
import { Tooltip, Avatar, makeStyles, Typography } from "@material-ui/core";
import { green, red, blue, yellow, grey } from "@material-ui/core/colors";
import { Check, Close, HourglassFull, Remove, Loop } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  large: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  success: {
    background: green[500],
  },
  failure: {
    background: red[500],
  },
  info: {
    background: blue[500],
  },
  warning: {
    background: yellow[500],
  },
  pending: {
    background: grey[500],
  },
  etapas: {
    display: "flex",
    justifyContent: "space-around",
    minWidth: theme.spacing(4) * 10,
  },
  etapa: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    marginRight: 10,
  },
}));
const pasos = [
  { paso: 1, nombre: "Codificación" },
  { paso: 2, nombre: "Creación" },
  { paso: 3, nombre: "Revisión" },
  { paso: 4, nombre: "Aprobación" },
  { paso: 5, nombre: "Publicación" },
];
const estadoAccion = {
  1: "creó la solicitud",
  2: "aprobó la solicitud",
  3: "rechazó la solicitud",
  4: "subió el documento",
  5: "aprobó el documento",
  6: "rechazó el documento",
  7: "hizo oficial el documento",
  9: "solicitó cambios",
  13: "solicitó cambios",
};
const EtapasSolicitud = ({ ordenEstado, solicitudData, historico }) => {
  const classes = useStyles();
  const [hist, setHist] = useState([]);
  const getIcon = (paso, estado) => {
    let icon = null;
    if (
      (paso === 1 && estado === 1 && estado !== 13) ||
      (paso === 2 && estado === 3 && estado !== 13) ||
      (paso === 3 && estado === 4 && estado !== 13) ||
      (paso === 4 && estado === 6 && estado !== 13) ||
      (paso === 5 && estado === 9 && estado !== 13)
    ) {
      icon = <HourglassFull />;
    }
    // if (paso === 1 && estado === 3) {
    //   icon = <Close />
    // } else if ((paso === 3 && estado === 6) ||
    //     (paso === 4 && estado === 9)) {
    //   icon = <Loop />
    // }
    if ((paso === 1 && estado === 2) || (paso === 4 && estado === 8)) {
      icon = <Close />;
    }
    if (
      (paso === 1 && estado === 13) ||
      (paso === 3 && estado === 5) ||
      (paso === 4 && estado === 7) ||
      (paso === 5 && estado === 10)
    ) {
      icon = <Loop />;
    }
    if (!icon) {
      if (
        (paso === 1 && estado >= 3 && estado !== 13) ||
        (paso === 2 && estado >= 4 && estado !== 13) ||
        (paso === 3 && estado >= 6 && estado !== 13) ||
        (paso === 4 && estado >= 9 && estado !== 13) ||
        (paso === 5 && estado >= 11 && estado !== 13)
      ) {
        icon = <Check />;
      } else {
        icon = <Remove />;
      }
    }
    return icon;
  };
  const getColor = (paso, estado) => {
    let color = null;
    if (
      (paso === 1 && estado === 1 && estado !== 13) ||
      (paso === 2 && estado === 3 && estado !== 13) ||
      (paso === 3 && estado === 4 && estado !== 13) ||
      (paso === 4 && estado === 6 && estado !== 13) ||
      (paso === 5 && estado === 9 && estado !== 13)
    ) {
      color = classes.info;
    }
    if ((paso === 1 && estado === 2) || (paso === 4 && estado === 8)) {
      color = classes.failure;
    }
    if (
      (paso === 1 && estado === 13) ||
      (paso === 3 && estado === 5) ||
      (paso === 4 && estado === 7) ||
      (paso === 5 && estado === 10)
    ) {
      color = classes.warning;
    }
    if (!color) {
      if (
        (paso === 1 && estado >= 3 && estado !== 13) ||
        (paso === 2 && estado >= 4 && estado !== 13) ||
        (paso === 3 && estado >= 6 && estado !== 13) ||
        (paso === 4 && estado >= 9 && estado !== 13) ||
        (paso === 5 && estado >= 11 && estado !== 13)
      ) {
        color = classes.success;
      } else {
        color = classes.pending;
      }
    }
    return color;
  };
  const getMessage = (paso, estado, pasoData) => {
    let msg = "";
    const histItem = hist.find((i) => i.Accion === estadoAccion[paso]);
    const fecha = histItem ? ` - ${histItem.Fecha}` : "";

    let revisor = "---";
    if (pasoData.ListaRevisores !== null) {
      revisor = pasoData.ListaRevisores.length
        ? pasoData.ListaRevisores[0].Revisor
        : "--";
    }

    let autorizador = "---";
    if (pasoData.ListaAutorizadoresFinales !== null) {
      autorizador = pasoData.ListaAutorizadoresFinales.length
        ? pasoData.ListaAutorizadoresFinales[0].AutorizadorFinal
        : "--";
    }

    if (paso === 1) {
      msg =
        estado === 1 || estado === 13
          ? `Código pendiente de asignar por ${pasoData.Autorizador}${fecha}`
          : estado === 2
          ? `Borrador cancelado por ${pasoData.Autorizador}${fecha}`
          : estado >= 3
          ? `Código asignado por ${pasoData.Autorizador}${fecha}`
          : "Fase pendiente";
    }
    if (paso === 2) {
      msg =
        estado === 3
          ? `Subir documento ${pasoData.Solicitante}`
          : estado >= 4 && estado !== 13
          ? `Documento subido por ${pasoData.Solicitante}${fecha}`
          : "Fase pendiente";
    }
    if (paso === 3) {
      msg =
        estado === 4
          ? `Documento pendiente de revisión por ${revisor}`
          : estado === 5
          ? `Cambios solicitados por ${revisor}${fecha}, corregir`
          : estado >= 6 && estado !== 13
          ? `Revisión aprobada por ${revisor}${fecha}`
          : "Fase pendiente";
    }
    if (paso === 4) {
      msg =
        estado === 6
          ? `Documento revisado pendiente de aprobación por ${autorizador}`
          : estado === 7
          ? `Cambios solicitados por  ${autorizador}${fecha}`
          : estado === 8
          ? `Borrador cancelado por  ${autorizador}${fecha}`
          : estado >= 9 && estado !== 13
          ? `Documento aprobado por ${autorizador}${fecha}`
          : "Fase pendiente";
    }
    if (paso === 5) {
      msg =
        estado === 9
          ? `Documento aprobado pendiente de publicación${fecha}`
          : estado === 10
          ? `Solicitud de ajustes de formato${fecha}`
          : estado >= 11 && estado !== 13
          ? "Documento publicado"
          : "Fase pendiente";
    }
    return msg || "Mensaje no conocido";
  };
  useEffect(() => {
    setHist(
      historico.filter((i) => i.IdSolicitud === solicitudData.IdSolicitud)
    )
  }, [historico])
  return (
    <div className={classes.etapas}>
      {pasos.map((i) => (
        <div className={classes.etapa} key={i.paso}>
          <Tooltip title={getMessage(i.paso, ordenEstado, solicitudData)}>
            <Avatar className={[classes.large, getColor(i.paso, ordenEstado)]}>
              {getIcon(i.paso, ordenEstado)}
            </Avatar>
          </Tooltip>
          <Typography variant="caption">{i.nombre}</Typography>
        </div>
      ))}
      {solicitudData.EsObsoleto && (
        <div className={classes.etapa}>
          <Tooltip title="Aprobar obsolescencia">
            <Avatar className={[classes.large, classes.pending]}>
              {<HourglassFull />}
            </Avatar>
          </Tooltip>
          <Typography variant="caption">Obsolescencia</Typography>
        </div>
      )}
    </div>
  );
};

export default EtapasSolicitud;
