import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Loader from "react-loader-spinner";
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Mensaje from "../../helpers/components/message";

//Inputs Formulario

//Scroll bar
import "react-perfect-scrollbar/dist/css/styles.css";

//Appbar
import { fade } from "@material-ui/core/styles";

//Own components
import Layout from "../../layout/containers/Layout";
import API from "../../utils/api";
import { isSignedIn, signOut } from "../../utils/auth";
import { validaPermisoPaginaActual } from "../../utils/helpers";
import CardTarea from "../components/card-tarea";

//CSS
import { registrarAnalitica } from "../../utils/firebase-config";
import "../components/gestion-style.css";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

//const theme = useTheme();

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  paperUserModal: {
    position: "absolute",
    width: "75%",
    height: "600px",
    maxHeight: "600px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  popover: {
    pointerEvents: "none",
  },
  paperPopover: {
    padding: theme.spacing(1),
    background: "#000",
    color: "#fff",
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
});

class ListaTareas extends Component {
  state = {
    tipo: 0,
    lista: [],
    pendientes: [],
    terminados: [],
    value: 0,
    openDrawer: false,
    cargando: true,
  };

  async componentDidMount() {

    //CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
    const llaveAgrupacion = "Menu.Principal.Web";
    const llavePagina = "Menu.Principal.Web.Gestiones";
    await validaPermisoPaginaActual(llaveAgrupacion, llavePagina);
    //FIN DE CONSULTA DE PERMISOS
    registrarAnalitica('Tareas_Pendientes');
    const session = await isSignedIn();
    if (session.response) {
      this.setState(
        {
          codigoUsuarioActual: session["codigoUsuario"],
          usuarioAfectado: parseInt(session["codigoUsuario"]),
        },
        async () => {
          this.makeRequesPendientes();
        }
      );
    } else {
      window.location.href = "/login";
    }
  }

  recargar = async () => {
    this.makeRequesPendientes();
  }

  makeRequesPendientes = async () => {
    //Obtenemos los filtros del storage, de lo contrario dejamos el default.
    let direccion = "asc";
    let filtroPorReq = "fecha-creacion";
    const { filtrosExtras } = this.state;
    let uri;
    try {
      const session = await isSignedIn();
      uri = `api/aviso/bandejaTareasPendientes/${direccion}/${filtroPorReq}/${session["codigoUsuario"]}`;


      const request = await API.fetchGetRequestWithParams(uri, filtrosExtras);
      console.log('RequestTareasPendientes:', request);

      if (request.statusCode === 401) {
        if (await signOut()) {
          window.location.href = "/login";
        }
        return;
      }

      if (request.data.response) {
        //OK
        this.setState({
          terminados: request.data.data,//request.data.asignados,
          isLoading: false,
          error: false,
          cargando: false,
        });
      } else {
        //El API alerta error.
        this.setState({
          isLoading: false,
          error: false,
          cargando: false,
          pendientes: [],
        });
      }
    } catch (e) {
      console.log("error", e)
      //Hubo error en el request al server
      this.setState({
        isLoading: false,
        cargando: false,
        error: true,
      });
    }finally{
      this.setState({
        isLoading: false,
        cargando: false,
      });
    }
  };

  handleChange = (name) => (event) => {
    if (name == "activo") {
      this.setState({ [name]: event.target.checked });
    } else {
      this.setState({
        [name]: event.target.value,
      });
    }
  };

  onClickAcept = async (tarea) => {
    const session = await isSignedIn();
    let objectRequest = {};
    objectRequest.CodigoUsuario = session.codigoUsuario;
    objectRequest.CodigoEstadoTarea = 3;
    objectRequest.Comentario = null;
    await this.postAvisoTarea(objectRequest, tarea);
    this.recargar();
  };

  onClickCancel = async (tarea) => {
    const session = await isSignedIn();
    let objectRequest = {};
    objectRequest.CodigoUsuario = session.codigoUsuario;
    objectRequest.CodigoEstadoTarea = 4;
    objectRequest.Comentario = null;
    await this.postAvisoTarea(objectRequest, tarea);
    this.recargar();
  };

  onClickRuta = async (tarea) => {
    let codigoAvisoTarea = tarea
    let uri = `api/aviso/tarea/obtenerRutaTarea/${codigoAvisoTarea}`;

    const request = await API.fetchGetRequest(uri);
    console.log(request)
  };

  postAvisoTarea = async (objectRequest, codigoTarea) => {
    try {
      const uri = `api/aviso/tarea/${codigoTarea}`;
      let objects = objectRequest;
      console.log("url: " + uri)
      const request = await API.post(uri, objects);
      if (request.statusCode === 401) {
        if (await signOut()) {
          window.location.href = "/login";
        }
        return;
      }

      if (request.data.response) {
        toast.info("La tarea se operó correctamente");
      } else {
        window.alert(request.data.mensaje);
      }
    } catch (e) {
      toast.error("Error: " + e);
    }
  };

  handleChangeCheck = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  handlePopoverOpen = (event, textoPopover) => {
    this.setState({
      anchorEl: event.currentTarget,
      popOverOpen: true,
      textoPopover: textoPopover,
    });
  };

  handlePopoverClose = () => {
    this.setState({ anchorEl: null, popOverOpen: false, textoPopover: "" });
  };

  handleChangeTabs = (event, newValue) => {
    this.setState({ value: newValue });
  };

  handleChangeIndex = (index) => {
    this.setState({ value: index });
  };

  render() {
    const { classes } = this.props;

    return (
      <Layout
        titulo={
          "Tareas pendientes"
        }
      >
        <div
          style={{
            color: "#2196F3",
            display: "flex",
            alignContent: "center",
            fontSize: "1m",
            marginTop: 10,
          }}
        ></div>

        {this.state.cargando ? (
          <div
            style={{
              width: "100%",
              marginTop: 10,
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loader
              type="Oval"
              color="#3498db"
              height="50"
              width="50"
              style={{ textAlign: "center" }}
            />
          </div>
        ) : (
          <></>
        )}
        {(this.state.terminados.length === 0 && !this.state.cargando) ?
          (<div style={{
            width: "100%",
            marginTop: 10,
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
          }}>
            <Mensaje>Felicitaciones! Por el momento no cuenta con ninguna tarea pendiente.</Mensaje>
          </div>)
          : (
            <Container maxWidth="md">
              {this.state.terminados.map((item, index) => (
                <CardTarea
                  key={item.CodigoTarea}
                  CodigoAviso={item.CodigoAviso}
                  CodigoTarea={item.CodigoAvisoTarea}
                  Problema={item.Problema}
                  Estado={item.Estado}
                  porcentajeAvance={item.PorcentajeAvance}
                  Nombre={item.TareaTitulo}
                  Descripcion={item.TareaDescripcion}
                  GestionTitulo={item.Titulo}
                  Fecha={item.FechaInsertoAmigable}
                  FechaVencimiento={item.TareaFechaVencimiento}
                  NombreEstado={item.TareaEstado}
                  Activo={item.Activo}
                  Atrasado={item.Atrasado}
                  SLA={item.SLA}
                  Solicitante={item.Solicitante}
                  Quedan={item.CausasTiempoEsperaEstimado != null ? item.CausasTiempoEsperaEstimado : item.ProblemaTiempoEsperaEstimado}
                  Padre={item.TareaRuta}
                  recargar={this.recargar}
                  onClickCancel={this.onClickCancel}
                  onClickAcept={this.onClickAcept}
                  colorSemaforo={item.colorSemaforo}
                />
              ))}

            </Container>
          )}
        <ToastContainer />
        <Popover
          id="mouse-over-popover"
          className={classes.popover}
          classes={{
            paper: classes.paperPopover,
          }}
          open={this.state.popOverOpen}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          onClose={this.handlePopoverClose}
          disableRestoreFocus
        >
          <Typography>{this.state.textoPopover}</Typography>
        </Popover>
      </Layout>
    );
  }
}
const ListaTareasPendientesContainer = withStyles(styles)(ListaTareas);
export default withRouter(ListaTareasPendientesContainer);
