import { action, thunk } from 'easy-peasy'
import api from '../utils/api'

const permisos = sessionStorage.getItem('permisosGesDoc')
  ? JSON.parse(sessionStorage.getItem('permisosGesDoc'))
  : []

const UsuariosStore = {
  agrupacion: null,
  permisos: [],
  roles: [],
  permisosRol: [],
  permisosGesDoc: permisos,
  setAgrupacion: action((state, payload) => {
    state.agrupacion = payload
  }),
  setPermisos: action((state, payload) => {
    state.permisos = payload
  }),
  setRoles: action((state, payload) => {
    state.roles = payload
  }),
  setPermisosRol: action((state, payload) => {
    state.permisosRol = payload
  }),
  setPermisosGesDoc: action((state, payload) => {
    state.permisosGesDoc = payload
  }),
  getAgrupacion: thunk(async (actions, _) => {
    const { data: { list } } = await api.fetchGetRequest('api/desktop/permisoagrupacion/listado')
    actions.setAgrupacion(list.find(i => i.PermisoAgrupacion === 'GESDOC') || null)
  }),
  getPermisos: thunk(async (actions, _) => {
    const { data: { permisos } } = await api.fetchGetRequest('api/desktop/permiso/listado')
    actions.setPermisos(permisos.filter(i => i.PermisoAgrupacion === 'GESDOC'))
  }),
  getRoles: thunk(async (actions, _) => {
    const { data: { roles } } = await api.fetchGetRequest('api/desktop/rol/listado')
    actions.setRoles(roles.filter(i => i.Rol.toUpperCase().includes('GESDOC')))
  }),
  getPermisosRol: thunk(async (actions, idRol) => {
    const { data: { rol } } = await api.fetchGetRequest(`api/desktop/rol/${idRol}`)
    actions.setPermisosRol(rol.PermisosRol)
  }),
  getPermisosGesDoc: thunk(async (actions, _) => {
    const key = 'GESDOC'
    const usuario = JSON.parse(localStorage.getItem('userData'))
    const codigo = usuario ? usuario.codigoUsuario : 0
    const { data } = await api.fetchGetRequest(`api/permiso/detalle/agrupacion/${key}/${codigo}`)
    const permisos = data.map(i => i.Llave)
    actions.setPermisosGesDoc(permisos)
    sessionStorage.setItem('permisosGesDoc', JSON.stringify(permisos))
  })
}

export default UsuariosStore
