import React, { useState, useEffect, useRef } from 'react'
import {
  Box,
  Button,
  makeStyles
} from '@material-ui/core'
import Layout from '../layout/containers/Layout'
import { toast, ToastContainer } from 'react-toastify'
import api from '../utils/api'
import LoadingButton from './componentes/utils/LoadingButton'

const useStyles = makeStyles(theme => ({
  editor: {
    width: '100%',
    height: 'calc(80vh - 100px)'
  }
}))

const BlogPreguntas = () => {
  const [loading, setLoading] = useState(false)
  const submitContent = async () => {
    try {
      setLoading(true)
      const textarea = document.getElementById('txt-editor')
      const html = window.sceditor.instance(textarea).val()
      var blob = new Blob([html], {type : 'text/plain'});
      var file = new File([blob], 'content.txt');
      const formData = new FormData()
      formData.append('content', file)
      await api.postFile('api/documentos/blog', formData)
      // await api.realizarPeticionPostPut('api/documentos/blog', body, 'POST')
    } catch (error) {
      toast.error('Ha ocurrido un error')
    } finally {
      setLoading(false)
    }
  }
  const getContent = async () => {
    try {
      const { data } = await api.fetchGetRequest('api/documentos/blog')
      const textarea = document.getElementById('txt-editor')
      window.sceditor.instance(textarea).val(data)
    } catch (error) {
      toast.error('Ha ocurrido un error')
    }
  }
  useEffect(() => {
    const textarea = document.getElementById('txt-editor')
    window.sceditor.create(textarea, {
      format: 'xhtml',
      style: 'https://cdn.jsdelivr.net/npm/sceditor@3/minified/themes/content/default.min.css'
    })
    getContent()
  }, [])
  const classes = useStyles()
  return <Layout titulo="Preguntas frecuentes" style={{height: '100%'}}>
    <textarea id="txt-editor" className={classes.editor} />
    <Box justifyContent="center" display="flex" width="100%">
      <LoadingButton
        color="primary"
        variant="contained"
        style={{width: '50%'}}
        loading={loading}
        onClick={submitContent}
      >
        Guardar
      </LoadingButton>
    </Box>
    <ToastContainer />
  </Layout>
}

export default BlogPreguntas
