import React, { useEffect,useState, Fragment, useRef } from "react";
// import { withRouter } from "react-router-dom";
import { Badge, Grid } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
// import {render} from 'react-dom';
import {sortableContainer, sortableElement} from 'react-sortable-hoc';
import TextField from "@material-ui/core/TextField";
// import { withStyles } from "@material-ui/core/styles";
// import DateFnsUtils from "@date-io/date-fns";
// import esLocale from "date-fns/locale/es";
// import { DatePicker, MuiPickersUtilsProvider, Day } from "material-ui-pickers";
// import Fab from "@material-ui/core/Fab";
// import MaskedInput from 'react-text-mask';
// import DetailsIcon from "@material-ui/icons/Details";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import TreeIcon from "./tree-icon";
import Loader from "react-loader-spinner";
import ListAltIcon from '@material-ui/icons/ListAlt';
//Select
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";


//Upload file
// import {DropzoneArea} from 'material-ui-dropzone';

//Checkbox
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from '@material-ui/core/Checkbox';
import  "../plantillaProblema/styles.css";
import {arrayMoveControls} from '../utils/dndUtils';

//Drawer
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// import Drawer from '@material-ui/core/Drawer';
// import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
// import List from '@material-ui/core/List';
// import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";

import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";


//Tabs
// import Paper from '@material-ui/core/Paper';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
// import Box from '@material-ui/core/Box';
// import PropTypes from 'prop-types';
// import FormGroup from '@material-ui/core/FormGroup'
// import FormLabel from '@material-ui/core/FormLabel'

//chip
// import ChipInput from 'material-ui-chip-input'

import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";

//Own components
import API from "../utils/api";
import { isSignedIn } from "../utils/auth";
// import {convertirASegundos,convertToYearMonthDay,convertDMY,convertYMD,convertirHHMM} from "../utils/helpers";
// import InputAdornment from '@material-ui/core/InputAdornment';
// import DispositivoListContainer from "../usuario/containers/usuario-list";
import TareaCard from "./tarea-card";
import AddButton from "../helpers/components/add-button-float";
// import {validaPermisoPaginaActual} from "../utils/helpers";
import FormList  from "../avisos-objects/components/form-list";
import ActionMenuFormTab from "../avisos-objects/components/action-menu-form-tab";
import FormDesigner from "../form-designer/container/form-designer";
// import ListaObjetos from "../avisos-objects/components/lista-objetos";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// import MoreVertIcon from "@material-ui/icons/MoreVert";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import Chip from "@material-ui/core/Chip";
import FaceIcon from "@material-ui/icons/Face";
import GroupIcon from '@material-ui/icons/Group';
import { Add, FileCopy } from "@material-ui/icons";
// import { Badge } from "material-ui";


function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "100%",
    marginTop: "15px",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: "rgb(33, 126, 207)",
  },
  chip: {
    marginTop: "10px",
    marginLeft: "5px",
    marginRight: "5px",
  },
}), {index: 1});

const SortableItemObject = sortableElement(
  ({ value, editar, confirmarEliminar, navegar,showForm,openModalUsuarios,eliminarUsuario, duplicarTarea, classes}) => {
    const [expanded, setExpanded] = useState(false);
    const handleExpandClick = () => {
      setExpanded(!expanded);
    };
    return(
    <li style={{ textAlign: "right" }}>
      <IconButton
        style={{ top: "0", color: "#3f51b5" }}
        aria-label="Editar"
        onClick={(event) => {
          navegar(value.CodigoTareaPlantilla, value.Titulo, "AITTareaPlantilla");
        }}
      >
        {/* <DetailsIcon /> */}
        <TreeIcon/>
      </IconButton>

      <IconButton
        style={{ top: "0", color: "#3f51b5" }}
        aria-label="Editar"
        onClick={(event) => {
          showForm(value.CodigoTareaPlantilla, value.Titulo, "TAREA");
        }}
      >
        <ListAltIcon />
      </IconButton>

      <IconButton
        style={{ top: "0", color: "#3f51b5" }}
        aria-label="Duplicar"
        onClick={(event) => {
          duplicarTarea(value.CodigoTareaPlantilla);
        }}
      >
        <FileCopy />
      </IconButton>

      <IconButton
        style={{ top: "0", color: "#3f51b5" }}
        aria-label="Editar"
        onClick={(event) => {
          editar(value.CodigoTareaPlantilla);
        }}
      >
        <EditRoundedIcon />
      </IconButton>

      <IconButton
        aria-label="Eliminar"
        style={{ top: "0", color: "#f50057" }}
        onClick={() => confirmarEliminar(value.CodigoTareaPlantilla)}
      >
        <DeleteForeverRoundedIcon />
      </IconButton>
      
      <div>
<CardActions disableSpacing>
        <Typography paragraph>
          <Badge badgeContent={value.usuariosAsignados.length} color="primary">
            {value.usuariosAsignados.length < 1 ? (
              <span style={{ color: "rgb(245, 0, 87)" }}>
                Sin usuarios/roles asignados &nbsp;&nbsp;&nbsp;
              </span>
            ) : (
              <span style={{ color: "rgb(63, 81, 181)" }}>
                Usuarios/roles asignados &nbsp;&nbsp;&nbsp;
              </span>
            )}
          </Badge>
        </Typography>
        <IconButton
          style={{ marginLeft: "auto" }}
          aria-label="Agregar asignado"
          onClick={() => {
            openModalUsuarios(value.CodigoTareaPlantilla);
          }}
        >
          <PersonAddIcon />
        </IconButton>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <div>
          
          {value.usuariosAsignados.map((item,index) => (
              <Chip
                key = {`user-${index}`}
                className={classes.chip}
                icon={item.tipo==="Usuario"?<FaceIcon />: (item.tipo ==="Rol"?<GroupIcon />:<TreeIcon />)}
                label={item.NombreCompletoUsuario}
                onDelete={() => {
                  eliminarUsuario(item.CodigoGrupoUsuario);
                }}
                color={item.tipo==="Usuario"?"primary": (item.tipo==="Rol"?"secondary":"secondary")}
                variant = {item.tipo==="RolDinamico"?"outlined": "default"}
              />
            ))}
          </div>
          <Typography paragraph></Typography>
        </CardContent>
      </Collapse>
</div>

      <div className="dragCard" data-codigocampo={value.CodigoCampo} zIndex="1">
        <TareaCard
          codigoTareaPlantilla={value.codigoTareaPlantilla}
          tipoTarea={value.NombreTareaTipo}
          titulo={value.Titulo}
          descripcion={value.Descripcion}
          activo={value.Activo}
        />
      </div>



    </li>
  )
}
);





const SortableContainer = sortableContainer(({children}) => {
  return <ul>{children}</ul>;
});




// const useStyles = makeStyles(theme => ({
//   root: {
//     display: "flex"
//   },

 
//   menuButton: {
//     marginRight: theme.spacing(2)
//   },
//   hide: {
//     display: "none"
//   },
   
//   contentShift: {
//     transition: theme.transitions.create("margin", {
//       easing: theme.transitions.easing.easeOut,
//       duration: theme.transitions.duration.enteringScreen
//     }),
//     marginLeft: 0
//   }
// }));

 // const theme = useTheme();

function TaskDesignerIndependiente(props) {
  const [open, setOpen] = useState(false);
  const [openToolbar, setOpenToolbar] = useState(false);
  const [actionType, setActionType] = useState(""); // Crear, Actualizar
  const [items, setItems] = useState([]);
  const [tareaTipos, setTareaTipos] = useState([]);
  const [codigoPlantillaTareas, setCodigoPlantillaTareas] = useState("");
  const [codigoTareaPlantilla, setCodigoTareaPlantilla] = useState("");
  const [codigoTareaTipo, setCodigoTareaTipo] = useState("");
  const [titulo, setTitulo] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [comentario, setComentario] = useState("");
  const [orden, setOrden] = useState("");
  const [activo, setActivo] = useState("");
  const [secuencial, setSecuencial] = useState(false);
  const [cambiarEstadoPorHijos, setCambiarEstadoPorHijos] = useState(false);
  const [cambiarEstadoHijos, setCambiarEstadoHijos] = useState(false);
  const [confirmTexto, setConfirmTexto] = useState("");
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [codigoTareaEliminar, setCodigoTareaEliminar] = useState("");
  const [nombreFlujo, setNombreFlujo] = useState("");
  const [nombreObjetoPadre, setNombreObjetoPadre] = useState("");
  const [cargando, setCargando] = useState(false);
  const [history, setHistory] = useState([]);
  const [idObjetoPadre, setIdObjetoPadre] = useState(0);
  const [llaveObjetoPadre, setLlaveObjetoPadre] = useState("");
  const [codigoPlantilla, setCodigoPlantilla] = useState("");
  const [openFormPanel, setOpenFormPanel] = useState(false);
  const [textoBusquedaForm, setTextoBusquedaForm] = useState("");
  const [listaForms, setListaForms] = useState([]);
  const [openChooseForm, setOpenChooseForm] = useState(false);
  const [confirmTextoCambioForm, setConfirmTextoCambioForm] = useState("");
  const [confirmOpenCambioForm, setConfirmOpenCambioForm] = useState(false);
  const [codigoFormPendienteconfirmar, setCodigoFormPendienteconfirmar] = useState("");
  const [txtNombreForm, setTxtNombreForm] = useState("");
  const [openNewForm, setOpenNewForm] = useState(false);
  const [codigoProblema, setCodigoProblema] = useState(props.codigoProblema);
  const [botonBackDisplay, setBotonBackDisplay] = useState(false);
  const [mostrarEncabezadoGestion, setMostrarEncabezadoGestion] = useState(true);
  const [mostrarUsuariosAsignados, setMostrarUsuariosAsignados] = useState(true);
  const [mostrarEtiquetas, setMostrarEtiquetas] = useState(true);
  const [mostrarComentarios, setMostrarComentarios] = useState(true);
  const [mostrarAdjuntos, setMostrarAdjuntos] = useState(true);
  const [mostrarBotonCancelar, setMostrarBotonCancelar] = useState(true);

  const bottomRef = useRef();

const scrollToElement = () => {
  const {current} = bottomRef
   if (current !== null){
     current.scrollIntoView({behavior: "smooth"})
   }
} 

  
  const theme = useTheme();
  const classes = useStyles();

  const styles ={
    container: {
      display: "flex",
      flexWrap: "wrap"
    },
    textField: {
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit,
      width: "100%"
    },
    paper: {
      position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none"
    },
    buttonAdd: {
      margin: theme.spacing.unit
    },
    paperPopover: {
      padding: theme.spacing(1),
      background: "#000",
      color: "#fff",
    },
    rootAppbar: {
      flexGrow: 1,
    },
    root: {
      //flexGrow: 1,
      width: "100%",
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
    },
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      // backgroundColor: fade(theme.palette.common.white, 0.15),
      // "&:hover": {
      //   backgroundColor: fade(theme.palette.common.white, 0.25),
      // },
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: "inherit",
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "12ch",
        "&:focus": {
          width: "20ch",
        },
      },
    },
    paperUserModal: {
      position: "absolute",
      width: "75%",
      height: "600px",
      maxHeight: "600px",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing.unit * 2,
      outline: "none",
    },
    paperModal: {
      position: "absolute",
      width: "90%",
      height: "600px",
      maxHeight: "600px",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing.unit * 2,
      outline: "none",
    },
    paperNuevoForm: {
      position: "absolute",
      width: "50%",
      height: "300px",
      maxHeight: "300px",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing.unit * 2,
      outline: "none",
    },
    bigPaper: {
      position: "absolute",
      width: "95%",
      height: "95%",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing.unit * 2,
      outline: "none",
    },
  };
  
  // const onSortEnd = ({ oldIndex, newIndex }) => {
  //   this.setState(
  //     ({ items }) => ({
  //       items: arrayMoveControls(items, oldIndex, newIndex),
  //     }),
  //     () => {
  //       this.cambiarOrden();
  //     }
  //   );
  // };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setItems(prevItems => {
      const updatedItems = arrayMoveControls(prevItems, oldIndex, newIndex);
      // Llamar a cambiarOrden() después de actualizar el estado
      cambiarOrden();
      return updatedItems;
    });
  };

  useEffect(()=>{
    cargarListaTipoTarea();
  },[]);

  useEffect(()=>{
     setCodigoPlantillaTareas(props.codigoPlantillaTareas)
     console.log('CodigoPlantillaTareas_R:', props.codigoPlantillaTareas);
    },[props.codigoPlantillaTareas]);

  useEffect(()=>{
    if(codigoPlantillaTareas>0){
      start();
    }
    
  },[codigoPlantillaTareas]);

   
  const start = async()=>{
     await init();
    await cargarNombreFlujo();
  }

  async function init(){
    console.log("INIT!!", props.codigoPlantillaTareas)
  

    if (isNaN(props.codigoPlantillaTareas)) {
      return
    }
    const codigoFlujo = props.codigoPlantillaTareas;

   if (!isNaN(codigoFlujo)) {
    //Cargar lista subentidades

    let hhistory = history;   

    hhistory.push({
      idObjeto: codigoFlujo,
      nombre: "Administrador de Tareas",
      llave: "AITPlantillaTareas"
    }); //inserta la primera posición del array para navegacion

    setCodigoProblema(props.codigoProblema);
    setCodigoPlantillaTareas(codigoFlujo);
    setIdObjetoPadre(codigoFlujo);
    setLlaveObjetoPadre("AITPlantillaTareas");
    setHistory(hhistory);

    cargarListaTareas(codigoFlujo,"AITPlantillaTareas");
    
    }
  }

  const cargarListaTareas = async (idObjetoPadreParam, llaveObjetoPadreParam) => {
    try {
      setCargando(true);
   
        let codigoTareaPadre = !idObjetoPadreParam?idObjetoPadre: idObjetoPadreParam;
        let llaveTareaPadre = !llaveObjetoPadreParam?llaveObjetoPadre:llaveObjetoPadreParam;

        let params = {
          codigoPadre: codigoTareaPadre,
          codigoProblema: codigoProblema,
          llave: llaveTareaPadre,
        };
        const request = await API.post(`api/flujo/listaTareasProblemaPost`, params);
      
          if (Array.isArray(request.data.data)) {
            setItems(request.data.data);
          } else {
            setItems([]);
          }
          
    } catch (error) {
      console.error('Error al cargar las tareas', error);
    }finally{
      setCargando(false);
    }
  };

  async function cargarListaTareasOld(idObjetoPadre, llaveObjetoPadre) {
    try {
      //Loader
      setCargando(true);

      //Muestra/oculta botón para regresar en la navegación
      botonBack();

      const request = await API.fetchGetRequest(
        `api/flujo/listaTareas/${idObjetoPadre}/${llaveObjetoPadre}`
      );
      setItems(request.data.data);
      
      if (request.statusCode != 200) {
        alert(
          "Hubo un error al realizar la operación, consulta con un administrador"
        );
      }

      console.log(request.statusCode);
      if (request.statusCode === 401) {
        //alert("cerrar sesion");
        window.location.href = "/login";
      } else {
        if (Array.isArray(request.data.data)) {
          setItems(request.data.data)
        }
      }
    } catch (error) {
      console.log(error);
      if (error.toString().includes("401")) {
        //window.location.href = "/login";
      }
    } finally {
      setCargando(false);
    }
  }

 const buscarForm = async () => {
    let criterio =
      textoBusquedaForm === null ||
      textoBusquedaForm === undefined
        ? ""
        : textoBusquedaForm;
    let data = {
      codigoEntidad: null,
      criterio: criterio,
    };
    const request = await API.post(`api/form/buscar`, data);
    try {
      if (Array.isArray(request.data.data)) {       
          setListaForms(request.data.data);       
      }
    } catch (error) {
      console.log("cargarListaForms error:" + error);
    }
  };


  async function cargarNombreFlujo() {
    console.log('codigoPlantillaTareas', codigoPlantillaTareas)
    const request = await API.fetchGetRequest(
      `api/flujo/cargarDatosEdicion/${codigoPlantillaTareas}`
    );

    // obtiene el primer elmento del historial de navegación
    let hhistory = history;
    let historyRoot = hhistory[0];
    let idObjeto = historyRoot["idObjeto"];
    let llave = historyRoot["llave"];
    let nombre = request.data.data.Nombre;
    let object = {
      idObjeto: idObjeto,
      nombre: nombre,
      llave:llave,
    };
    console.log('Objeto history', object);
    //Limpia history
    hhistory = [];
    hhistory.push(object);

    setNombreFlujo(nombre);
    setNombreObjetoPadre(nombre);
    setHistory(hhistory);    
  }

  async function cargarDatosEdicion(codigoTareaPlantilla) {
    const request = await API.fetchGetRequest(
      `api/flujo/cargarDatosEdicionTarea/${codigoTareaPlantilla}`
    );
    
      setTitulo( request.data.data[0].Titulo);
      setDescripcion( request.data.data[0].Descripcion);
      setComentario( request.data.data[0].Comentario);
      setCodigoTareaTipo( request.data.data[0].CodigoTareaTipo);
      setOrden( request.data.data[0].Orden);
      setActivo( request.data.data[0].Activo);
      setCambiarEstadoHijos( request.data.data[0].CambiarEstadoHijos);
      setCambiarEstadoPorHijos( request.data.data[0].CambiarEstadoPorHijos);
      setSecuencial(request.data.data[0].Secuencial);
      setCodigoPlantilla(request.data.data[0].CodigoPlantilla);  
      setMostrarEncabezadoGestion(request.data.data[0].MostrarEncabezadoGestion);
      setMostrarUsuariosAsignados(request.data.data[0].MostrarUsuariosAsignados);
      setMostrarEtiquetas(request.data.data[0].MostrarEtiquetas);
      setMostrarComentarios(request.data.data[0].MostrarComentarios);
      setMostrarAdjuntos(request.data.data[0].MostrarAdjuntos);
      setMostrarBotonCancelar(request.data.data[0].MostrarBotonCancelar);
  }

  async function cargarListaTipoTarea() {
    const request = await API.fetchGetRequest(`api/flujo/listaTipoTarea`);
    if (Array.isArray(request.data.data)) {
      setTareaTipos(request.data.data);
      setCodigoTareaTipo(1);
      }
  }

  const editar = async (codigo) => {
    setCodigoTareaPlantilla(codigo);
    setActionType("Actualizar");   
    await cargarDatosEdicion(codigo);
    handleOpenAddModal();
     
  };

 const guardar = async () => {
   
    if (titulo.trim() == "") {
      toast.warn("Ingrese un nombre válido");
      return;
    }

    try {
      let vIsSignedIn = await isSignedIn();
      let palabra = "";
      if (vIsSignedIn.response) {
        let request;
        let data;

        if (actionType === "Agregar") {
          data = {
            CodigoTareaPlantilla: null,
            CodigoPlantillaTareas: codigoPlantillaTareas,
            CodigoTareaTipo: codigoTareaTipo,
            Titulo: titulo,
            Descripcion: descripcion,
            Comentario: comentario,
            Orden: 0,
            Activo: true,
            Eliminado: false,
            UsuarioOpera: vIsSignedIn.userName,
            CodigoUsuarioOpera: vIsSignedIn.codigoUsuario,
            CodigoPadre: idObjetoPadre,
            LlavePadre: llaveObjetoPadre,
            CambiarEstadoHijos: cambiarEstadoHijos,
            CambiarEstadoPorHijos: cambiarEstadoPorHijos,
            Secuencial: secuencial,
            MostrarEncabezadoGestion: mostrarEncabezadoGestion===null?false:mostrarEncabezadoGestion,
            MostrarUsuariosAsignados: mostrarUsuariosAsignados === null?false:mostrarUsuariosAsignados,
            MostrarEtiquetas: mostrarEtiquetas===null?false:mostrarEtiquetas,
            MostrarComentarios: mostrarComentarios ===null?false:mostrarComentarios,
            MostrarAdjuntos: mostrarAdjuntos === null?false:mostrarAdjuntos,
            MostrarBotonCancelar: mostrarBotonCancelar === null?false:mostrarBotonCancelar
          };
          palabra = "grabado";
        } else {
          data = {
            CodigoTareaPlantilla: codigoTareaPlantilla,
            CodigoPlantillaTareas: codigoPlantillaTareas,
            CodigoTareaTipo: codigoTareaTipo,
            Titulo: titulo,
            Descripcion: descripcion,
            Comentario: comentario,
            Orden: orden,
            Activo: activo,
            Eliminado: false,
            UsuarioOpera: vIsSignedIn.userName,
            CodigoUsuarioOpera:vIsSignedIn.codigoUsuario,
            CodigoPadre:idObjetoPadre,
           LlavePadre:llaveObjetoPadre,
             CambiarEstadoHijos:cambiarEstadoHijos,
             CambiarEstadoPorHijos :cambiarEstadoPorHijos,
            Secuencial:secuencial,
            MostrarEncabezadoGestion: mostrarEncabezadoGestion===null?false:mostrarEncabezadoGestion,
            MostrarUsuariosAsignados: mostrarUsuariosAsignados === null?false:mostrarUsuariosAsignados,
            MostrarEtiquetas: mostrarEtiquetas===null?false:mostrarEtiquetas,
            MostrarComentarios: mostrarComentarios ===null?false:mostrarComentarios,
            MostrarAdjuntos: mostrarAdjuntos === null?false:mostrarAdjuntos,
            MostrarBotonCancelar: mostrarBotonCancelar === null?false:mostrarBotonCancelar
          };
          palabra = "editado";
        }

        request = await API.post(`api/flujo/grabarEditarTarea`, data);
        if (request.status != 200) {
          alert(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        }

        if (request.statusCode === 401) {
          //alert("cerrar sesion");
        } else {
          toast.success("La tarea se " + palabra + " exitosamente.");
          handleClose();
          cargarListaTareas(idObjetoPadre, llaveObjetoPadre);
          //document.getElementById("bottom").scrollIntoView();
          scrollToElement();
        }
      }
    } catch (e) {
      alert("hubo un error");
    }
  };

  const duplicarTarea = async (codigoTareaPlantilla) => {
   console.log("codigoPlantillaTareas", codigoPlantillaTareas);
   console.log("CodigoTareaPlantilla", codigoTareaPlantilla);
   console.log("llaveObjetoPadre", llaveObjetoPadre);
   console.log("idObjetoPadre", idObjetoPadre);
   
   
    try {
      let vIsSignedIn = await isSignedIn();
      if (vIsSignedIn.response) {
        let request;
        let data = {
            CodigoTareaPlantillla: codigoTareaPlantilla,
            CodigoPlantillaTareas: codigoPlantillaTareas,
            UsuarioOpera: vIsSignedIn.userName,
            CodigoUsuarioOpera: vIsSignedIn.codigoUsuario,
            CodigoPadre: idObjetoPadre,
            LlavePadre: llaveObjetoPadre,           
          };
              

        request = await API.post(`api/flujo/duplicarTarea`, data);
        if (request.status != 200) {
          alert(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu tarea o consulta con un administrador"
          );
        }

        if (request.statusCode === 401) {
          //alert("cerrar sesion");
        } else {
          toast.success("La tarea se duplicó exitosamente.");
          handleClose();
          cargarListaTareas(idObjetoPadre, llaveObjetoPadre);
          //document.getElementById("bottom").scrollIntoView();
          scrollToElement();
        }
      }
    } catch (e) {
      alert("hubo un error");
    }
  };

  const cambiarOrden = async () => {
    let vIsSignedIn = await isSignedIn();
    let object = {
      tareas: items,
      usuarioOpera: vIsSignedIn.userName,
    };
    let request = await API.post(`api/flujo/cambiarOrden`, object);
    if (request.status != 200) {
      alert(
        "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
      );
    }

    if (request.statusCode === 401) {
      //alert("cerrar sesion");
    } else {
      //Activos
      cargarListaTareas(idObjetoPadre,llaveObjetoPadre);
    }
  };

 const handleChange = (name) => (event) => {

  const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

  switch (name) {
    case 'activo':
      setActivo(value);
      break;
    case 'secuencial':
      setSecuencial(value);
      break;
        case 'cambiarEstadoPorHijos':
      setCambiarEstadoPorHijos(value);
      break;
    case 'cambiarEstadoHijos':
      setCambiarEstadoHijos(value);
      break;
      case 'titulo':
      setTitulo(value);
      break;
      case 'descripcion':
      setDescripcion(value);
      break;
      case 'comentario':
      setComentario(value);
      break;
      case 'codigoTareaTipo':
        setCodigoTareaTipo(value);
        break;
      case 'txtNombreForm' :
        setTxtNombreForm(value);
        break;

      case 'textoBusquedaForm':
        setTextoBusquedaForm(value);
        break;

        case 'mostrarEncabezadoGestion':
        setMostrarEncabezadoGestion(value);
        break;

        case 'mostrarUsuariosAsignados':
        setMostrarUsuariosAsignados(value);
        break;

        case 'mostrarEtiquetas':
        setMostrarEtiquetas(value);
        break;

        case 'mostrarComentarios':
        setMostrarComentarios(value);
        break;

        case 'mostrarAdjuntos':
        setMostrarAdjuntos(value);
        break;

        case 'mostrarBotonCancelar':
          setMostrarBotonCancelar(value);
          break;
    default:
     
      break;
  }

  };

  const confirmarAccion = (codigoTareaPlantilla) => {
    
      setConfirmTexto(`¿Confirma que desea eliminar este elemento? esta acción no podrá revertirse.`);
      setConfirmOpen(true);
      setCodigoTareaEliminar(codigoTareaPlantilla);
   
  };

  const funcionOk = () => {
    eliminarCampo();
  };

  const cerrarConfirm = () => {
   setConfirmTexto("");
    setConfirmOpen(false);
  };

  const eliminarCampo = async () => {
    try {
      let vIsSignedIn = await isSignedIn();

      if (vIsSignedIn.response) {
        const request = await API.post(
          `api/flujo/eliminarTarea/${codigoTareaEliminar}/${codigoPlantillaTareas}`
        );
        if (request.status != 200) {
          alert(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        }

        if (request.statusCode === 401) {
          //alert("cerrar sesion");
        } else {
          toast.success("La tarea se eliminó exitosamente.");

         cargarListaTareas(idObjetoPadre,llaveObjetoPadre);
        }
      } else {
        toast.error("La sessión no se encuentra activa");
      }
    } catch (e) {
      alert(e);
    } finally {
      setCodigoTareaEliminar("");
    }
  };

  const handleOpenAddModal = () => {
    setOpen(true);
  };

  const handleClose = () => {
    
      setOpen(false);
      setCodigoTareaPlantilla("");
      setCodigoTareaTipo(1);
      setTitulo("");
      setDescripcion("");
      setComentario("");
      setOrden("");
      setActivo("");
      setCambiarEstadoHijos(false);
      setCambiarEstadoPorHijos(false);
      setSecuencial(false);
      setActionType("");
      };

  const showForm = async (codigo, titulo, tipoObjeto) => {
    setCodigoTareaPlantilla(codigo);
    cargarDatosEdicion(codigo);
    handleOpenFormPanel();
  };

  const handleOpenFormPanel = () => {
    setOpenFormPanel(true);
  };

  const handleCloseFormPanel = () => {    
      setOpenFormPanel(false);
      setCodigoTareaPlantilla("");
      setCodigoPlantilla("");
     
  };

  const handleOpenChooseForm = () => {    
     setOpenChooseForm(true);   
 };

 
 const handleCloseChooseForm = () => {
       setOpenChooseForm(false);
      setTextoBusquedaForm("");  
 };

 const handleOpenNewForm = () => {   
     setOpenNewForm(true); 
};

 const handleCloseNewForm = ()=>{
  setOpenNewForm(false); 
  setTxtNombreForm(""); 
}

 const confirmarCambioForm = (codigoForm) => {  
    setConfirmTextoCambioForm( `¿Confirma que desea reemplazar el formulario actual por este nuevo?`);
    setConfirmOpenCambioForm( true);
    setCodigoFormPendienteconfirmar( codigoForm);
  
};

const funcionOkCamioForm = () => {
  cambiarForm();
};

const cerrarConfirmCambioForm = () => { 
    setConfirmTextoCambioForm("");
    setConfirmOpenCambioForm(false); 
};

const asignarForm = async (codigoForm) => {
  if (
   codigoPlantilla === "" ||
   codigoPlantilla === null ||
   codigoPlantilla === undefined
  ) {
   setCodigoFormPendienteconfirmar(codigoForm)
      cambiarForm(codigoFormPendienteconfirmar);   
  } else {
    confirmarCambioForm(codigoForm);
  }
};

const cambiarForm = async (codigoForm) => {
  try {
    let vIsSignedIn = await isSignedIn();

    if (vIsSignedIn.response) {
      let data = {
        CodigoTarea: codigoTareaPlantilla,
        CodigoPlantilla: codigoForm === null? codigoForm: codigoFormPendienteconfirmar,
        usuarioOpera: vIsSignedIn.userName,
      };
      const request = await API.post(
        `api/form/asignarFormularioTarea`,
        data
      );
      if (request.status != 200) {
        alert(
          "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
        );
      }

      if (request.statusCode === 401) {
        //alert("cerrar sesion");
      } else {
        toast.success("Formulario asignado exitosamente.");
        //await this.cargarDatosEdicion();
        handleCloseChooseForm();
      }
    } else {
      toast.error("La sessión no se encuentra activa");
    }
  } catch (e) {
    alert(e);
  } finally {
    await cargarDatosEdicion(codigoTareaPlantilla);
    setCodigoFormPendienteconfirmar("");
    handleCloseChooseForm();
  }
};


  const navegar = (idObjeto, nombre, llave) => {
    if (llave === "AITTareaPlantilla") {
      let hhistory = history;
      hhistory.push({ idObjeto: idObjeto, nombre: nombre, llave:llave });
      
          setIdObjetoPadre(idObjeto);
          setLlaveObjetoPadre(llave);
          setNombreObjetoPadre(nombre);
          setHistory(hhistory);
      
          cargarListaTareas(idObjeto, llave);
       
    }
   };

  const regresar = () => {
    let hhistory = history;

    let cantidadPosiciones = hhistory.length;
    let nombreObjetoPadre;
    let idObjetoPadre;
    let llaveObjetoPadre;

    if (cantidadPosiciones > 1) {
      //Elimina el último elemento del array que corresponde al idObjetoPadre actual
      hhistory.pop();
      //Actualiza cantidad de elementos del array
      cantidadPosiciones = hhistory.length;
      //Encuentra el valor de la nueva última posición del array y la asigna al idObjetoPadre
      idObjetoPadre = hhistory[cantidadPosiciones - 1].idObjeto;
      nombreObjetoPadre = hhistory[cantidadPosiciones - 1].nombre;
      llaveObjetoPadre = hhistory[cantidadPosiciones - 1].llave;

      //Actualiza valores del state
      
          setIdObjetoPadre(idObjetoPadre);
          setNombreObjetoPadre(nombreObjetoPadre);
          setLlaveObjetoPadre(llaveObjetoPadre);
          setHistory(hhistory);
       
          cargarListaTareas(idObjetoPadre,llaveObjetoPadre);        
    }
  };

  const botonBack = () => {
    let cantidadPosiciones = history.length;
    let display;
    if (cantidadPosiciones > 1) {
      display = "inline-block";
    } else {
      display = "none";
    }

   setBotonBackDisplay(display);
  };

  const grabarNuevoForm = async () => {
   
    if (txtNombreForm.trim() == "") {
      toast.warn("Ingrese un nombre válido");
      return;
    }

    try {
      let vIsSignedIn = await isSignedIn();
      let palabra = "";
      if (vIsSignedIn.response) {
        let request;
        let data;

       
          data = {
            CodigoPlantilla: null,
            Nombre: txtNombreForm,
            Activo: true,
            UsuarioOpera: vIsSignedIn.userName,
            CodigoTarea:codigoTareaPlantilla,
          };
         

        request = await API.post(`api/form/grabarAsignarATarea`, data);
        if (request.status != 200) {
          alert(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        }

        if (request.statusCode === 401) {
          //alert("cerrar sesion");
        } else {
          toast.success("El registro se creó exitosamente.");

          //Redirecciona cuando la acción es grabar
            handleCloseNewForm();
            cargarDatosEdicion(codigoTareaPlantilla);
          }
      }
    } catch (e) {
      alert("hubo un error");
    }
  };

  const openModalUsuarios = async(codigoTareaPlantilla)=>{
    console.log('codigoTareaPlanilla -taskDesigner:', codigoTareaPlantilla)
    try {
      await props.openModalUsuarios(codigoTareaPlantilla, cargarListaTareas);
      
    } catch (error) {
      console.error('Error al asignar usuario', error)
    }
  }

  const eliminarUsuario = async(codigoGrupoUsuario)=>{
    try {
      await props.eliminarUsuario(codigoGrupoUsuario);
      cargarListaTareas(idObjetoPadre, llaveObjetoPadre);
    } catch (error) {
      console.error('Error al eliminar usuario', error)
    }
  }
   
    return (
      <Fragment>
        <div>
          {history.length>1 && (
          <AppBar position="static" style={{ width: "100%" }}>
            <Toolbar>
              <IconButton
                style={{
                  display: botonBackDisplay,
                  color: "#FFF",
                }}
                onClick={() =>{regresar()} }
              >
                <ArrowBackIosIcon />
              </IconButton>
              <Typography variant="h6" noWrap>
                {nombreObjetoPadre}
              </Typography>
            </Toolbar>
          </AppBar>
          )}
        </div>

         {cargando ? (
          <div
            style={{
              width: "100%",
              marginTop: 10,
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loader
              type="Oval"
              color="#3498db"
              height="50"
              width="50"
              style={{ textAlign: "center" }}
            />
          </div>
        ) : (
          <></>
        )}
       
        <div>
          <Grid item xs={12} md={12} lg={8} sm={12} style={{ margin: "auto" }}>
            <SortableContainer pressDelay={150} onSortEnd={onSortEnd}>
              {items.map((value, index) => (
                <SortableItemObject
                  key={`item-task${index}`}
                  index={index}
                  value={value}
                  editar={editar}
                  confirmarEliminar={confirmarAccion}
                  navegar={navegar}
                  showForm={showForm}
                  openModalUsuarios={openModalUsuarios}
                  eliminarUsuario={eliminarUsuario}
                  duplicarTarea = {duplicarTarea}
                  classes = {classes}
                />
              ))}
            </SortableContainer>
          </Grid>
          <Grid item xs={12} lg={12} sm={12}>
                      <div zIndex="1" style={{
                        cursor:'pointer', 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center',
                        padding:15,
                        marginBottom:15,
                        margin:10,
                        borderColor: '#c4c4c4',
                        borderStyle:'dashed', 
                        borderWidth: '1.5px'
                      }}>
                     <Add style={{ fontSize: 100, color: "#e0e0e0" }} onClick={()=>{
                      console.log("sono io")
                      setActionType("Agregar");
                      handleOpenAddModal();
                     }}/>
                      </div>
                      <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
     
     
            <div id="bottom" ref={bottomRef} ></div>
                      </Grid>
        </div>
        {/* {props.showAddButton != false &&(
            <AddButton
            onClick={() => {
              console.log("sono io")
              setActionType("Agregar");
              handleOpenAddModal();
              //this.setState({ actionType: "Agregar" }, this.handleOpenAddModal());
            }}
 />
        )} */}
     
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={open}
          onClose={handleClose}
        >
          <div style={{top:'50%', left: '50%', transform: `translate(-50%, -50%)`,
          ...styles.paper,
          }} className={styles.paper}>
            <form style={{...styles.container}} noValidate autoComplete="off">
              <Typography variant="title" gutterBottom>
                {actionType == "Agregar" ? (
                  <div>Crear</div>
                ) : (
                  <div>Editar</div>
                )}
              </Typography>
              <TextField
                required
                id="txtTitulo"
                label="Título"
                style={{...styles.textField}}
                value={titulo}
                onChange={handleChange("titulo")}
                margin="normal"
                inputProps={{ maxLength: 512 }}
              />
              <TextField
                id="txtDescripcion"
                label="Descripción"
                style={{...styles.textField}}
                value={descripcion}
                onChange={handleChange("descripcion")}
                margin="normal"
                multiline
                inputProps={{ maxLength: 512 }}
              />
              <TextField
                id="txtComentario"
                label="Comentario"
                style={{...styles.textField}}
                value={comentario}
                onChange={handleChange("comentario")}
                margin="normal"
                multiline
                inputProps={{ maxLength: 512 }}
              />
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl
                   style={{...styles.FormControl,width:"100%"}}
                     >
                  <InputLabel htmlFor="codigoTareaTipo">
                    Tipo de tarea
                  </InputLabel>
                  <Select
                    name="codigoTareaTipo"
                    //inputRef={codigoTareaTipoRef}
                    value={codigoTareaTipo}
                    onChange={handleChange("codigoTareaTipo")}
                    input={
                      <Input name="codigoTareaTipo" id="codigoTareaTipo" />
                    }
                  >
                    {tareaTipos.map((item) => (
                      <MenuItem
                        key={item.CodigoTareaTipo}
                        value={item.CodigoTareaTipo}
                      >
                        {item.Nombre}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText />
                </FormControl>
              </Grid>
              
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={secuencial}
                      onChange={handleChange("secuencial")}
                      value="secuencial"
                      color="primary"
                    />
                  }
                  label="¿Es secuencial?"
                />
                <br />
              </div>
              {actionType === "Actualizar" ? (
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={activo}
                        onChange={handleChange("activo")}
                        value="activo"
                        color="primary"
                      />
                    }
                    label="Activo"
                  />
                  <br />
                </div>
              ) : (
                <div></div>
              )}

              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={mostrarEncabezadoGestion}
                      onChange={handleChange("mostrarEncabezadoGestion")}
                      value="Mostrar encabezado gestión"
                      color="primary"
                    />
                  }
                  label="Mostrar encabezado de gestión"
                />
                <br />
              </div>

              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={mostrarUsuariosAsignados}
                      onChange={handleChange("mostrarUsuariosAsignados")}
                      value="Mostrar usuariosAsignados"
                      color="primary"
                    />
                  }
                  label="Usuarios asignados"
                />
                <br />
              </div>

              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={mostrarEtiquetas}
                      onChange={handleChange("mostrarEtiquetas")}
                      value="Mostrar etiquetas"
                      color="primary"
                    />
                  }
                  label="Mostrar etiquetas"
                />
                <br />
              </div>
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={mostrarComentarios}
                      onChange={handleChange("mostrarComentarios")}
                      value="Mostrar comentarios"
                      color="primary"
                    />
                  }
                  label="Mostrar comentarios"
                />
                <br />
              </div>

              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={mostrarAdjuntos}
                      onChange={handleChange("mostrarAdjuntos")}
                      value="Mostrar adjuntos"
                      color="primary"
                    />
                  }
                  label="Mostrar adjuntos"
                />
                <br />
              </div>

              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={mostrarBotonCancelar}
                      onChange={handleChange("mostrarBotonCancelar")}
                      value="Mostrar botón cancelar"
                      color="primary"
                    />
                  }
                  label="Mostrar botón cancelar"
                />
                <br />
              </div>

      
              <Grid container spacing={3}>
                <Grid item xs={12} lg={12} sm={12}>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: "#69bd4b", color: "white" }}
                    styles={{...styles.buttonAdd}}
                    onClick={() => guardar()}
                  >
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </Modal>
        
        <Modal
          // style={{ zIndex: 2990 }}
          aria-labelledby="Seleccionar un formulario"
          aria-describedby="Seleccionar un formulario"
          open={openFormPanel}
          onClose={handleCloseFormPanel}
        >
          <div style={{...styles.bigPaper}}>
            <PerfectScrollbar>
              <form style={{...styles.container}} noValidate autoComplete="off">
              {/* <Grid container spacing={3} style={{ width: "100% !important" }}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    color:"#3f51b5",
                   // fontWeight:"bold",
                  }}
                >Opciones de formulario
                  <ActionMenuFormTab
                    asignar={handleOpenChooseForm}
                    nuevoForm={handleOpenNewForm}
                  />
                </Grid>
                </Grid> */}
               
                <Grid container spacing={1}>
                  <Grid item xs={12} lg={12} sm={12}>
                    {codigoPlantilla === "" ||
                    codigoPlantilla === null ||
                    codigoPlantilla === undefined ? (
                      <div>No hay datos para mostrar</div>
                    ) : (
                      <FormDesigner
                        codigoPlantilla={codigoPlantilla}
                      />
                    )}
                  </Grid>
                </Grid>
              </form>
            </PerfectScrollbar>
          </div>
        </Modal>
        <Modal
          
          aria-labelledby="Seleccionar un formulario"
          aria-describedby="Seleccionar un formulario"
          open={openChooseForm}
          onClose={handleCloseChooseForm}
        >
          <div style={{...styles.paperModal, top:'50%', left: '50%', transform: `translate(-50%, -50%)`}} >
            <PerfectScrollbar>
              <form style={{...styles.container}} noValidate autoComplete="off">
                <Typography variant="h6" gutterBottom>
                  Elegir formulario
                </Typography>

                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextField
                      id="txtTextoBusquedaForm"
                      label="Nombre"
                      style={{...styles.textField}}
                      value={textoBusquedaForm}
                      onChange={handleChange("textoBusquedaForm")}
                      onKeyPress={(ev) => {
                        console.log("keyP: " + ev.key);
                        if (ev.key === "Enter") {
                          buscarForm();
                          ev.preventDefault();
                        }
                      }}
                      margin="normal"
                      inputProps={{ maxLength: 512 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#217ecf",
                        color: "white",
                        width: "100%",
                        ...styles.buttonAdd
                      }}
                      onClick={() => buscarForm()}
                    >
                      Buscar
                    </Button>
                  </Grid>
                </Grid>

                <Grid item xs={12} lg={12} sm={12}>
                  <Grid container spacing={1}>
                    <FormList
                      registros={listaForms}
                      seleccionarForm={asignarForm}
                    />
                  </Grid>
                </Grid>
              </form>
            </PerfectScrollbar>
          </div>
        </Modal>

        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={openNewForm}
          onClose={handleCloseNewForm}
        >
          <div style={{...styles.paperNuevoForm}} className={styles.paperNuevoForm}>
            <form styles={{...styles.container}} noValidate autoComplete="off">
              <Typography variant="title" gutterBottom>               
                Crear nuevo formulario            
              </Typography>
              <TextField
                required
                id="txtNombreForm"
                label="Nombre"
                style={{...styles.textField}}
                value={txtNombreForm}
                onChange={handleChange("txtNombreForm")}
                margin="normal"
                variant="outlined"
                inputProps={{ maxLength: 512 }}
              />
            
             
              <Grid container spacing={3}>
                <Grid item xs={12} lg={12} sm={12}>
                  <Button
                    variant="contained"
                    style={{...styles.buttonAdd, backgroundColor: "#69bd4b", color: "white" }}
                    onClick={() => grabarNuevoForm()}
                  >
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </Modal>




<ConfirmDialog
  id="confirmar"
  keepMounted
  open={confirmOpen}
  onClose={cerrarConfirm}
  value=""
  texto={confirmTexto}
  okfunction={funcionOk}
/> 

        <ConfirmDialog
          id="confirmarCambioForm"
          keepMounted
          open={confirmOpenCambioForm}
          onClose={cerrarConfirmCambioForm}
          value=""
          texto={confirmTextoCambioForm}
          okfunction={funcionOkCamioForm}
          style={{ zIndex: 3000 }}
        /> 
        <ToastContainer />
      </Fragment>
    );
}

export default TaskDesignerIndependiente;

