import React, { useState, useEffect } from 'react';
import { Button, CircularProgress, Grid, IconButton, Typography, TextField, Switch, FormControlLabel } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import ColorPicker from 'material-ui-color-picker';
import plantillaCorreo from "./plantillaCorreo";
import { PhotoCamera } from '@material-ui/icons';
import { TIPOS_TEMAS } from '../../utils/helpers';

const UploadInput = ({ id, setFoto, foto, classes }) => {

    const onFileChange = (e, file) => {
        file = file || e.target.files[0];
        setFoto(file);
    }

    return (
        <div className={classes.uploadContainer}>
            <input
                accept="image/*"
                className={classes.input}
                id={id}
                type="file"
                onChange={onFileChange}
            />
            <label htmlFor={id}>
                <IconButton color="primary" aria-label="upload picture" component="span">
                    <PhotoCamera />
                </IconButton>
            </label>
            <label htmlFor={id}>
                {foto ? <Typography color='primary'>Imagen cargada</Typography> :
                    <Typography color='textPrimary'>* Selecciona una imagen</Typography>}
            </label>
        </div>
    )
}


const FormularioTema = ({ values, onChangeValue, guardar, loading, classes }) => {

    const [headerImg, setHeaderImg] = useState(null);
    const [footerImg, setFooterImg] = useState(null);

    useEffect(() => {
        const file = values.HeaderImg;
        if (file) {
            const pattern = /-*/;

            const reader = new FileReader();
            if (file.type.match(pattern)) {
                reader.onload = (e) => {
                    setHeaderImg(reader.result)
                };
                reader.readAsDataURL(file);
            }
        }
    }, [values.HeaderImg])

    useEffect(() => {
        const file = values.FooterImg;
        if (file) {
            const pattern = /-*/;

            const reader = new FileReader();
            if (file.type.match(pattern)) {
                reader.onload = (e) => {
                    setFooterImg(reader.result)
                };
                reader.readAsDataURL(file);
            }
        }
    }, [values.FooterImg])

    return (
        <Grid container spacing={3}>
            <Grid item sm={12} md={4}>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={12} sm={12}>
                        <TextField
                            required
                            id="Nombre"
                            label="Nombre del tema"
                            value={values.nombre}
                            onChange={onChangeValue("nombre")}
                            fullWidth
                            type="string"
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} lg={12} sm={12}>
                        <Autocomplete
                            options={TIPOS_TEMAS}
                            value={values.tipo}
                            required
                            onChange={onChangeValue("tipo")}
                            fullWidth
                            getOptionLabel={(option) => option === null ? "" : option.label}
                            renderInput={(params) =>
                                <TextField {...params} required
                                    id="Tipo"
                                    label="Tipo"
                                    fullWidth
                                    type="string"
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={12} lg={12} sm={12} style={{ marginTop: '1rem' }}>
                        <Typography variant='body2' color='textPrimary'>Imagen Header</Typography>
                        <UploadInput
                            id="HeaderImg"
                            classes={classes}
                            setFoto={onChangeValue("HeaderImg")}
                            foto={(values.HeaderUrl || values.HeaderImg)}
                        />
                    </Grid>
                    <Grid item xs={12} lg={12} sm={12} style={{ marginTop: '1rem' }}>
                        <Typography variant='body2' color='textPrimary'>Imagen Footer</Typography>
                        <UploadInput
                            id="FooterImg"
                            classes={classes}
                            setFoto={onChangeValue("FooterImg")}
                            foto={(values.FooterUrl || values.FooterImg)}
                        />
                    </Grid>
                    <Grid item xs={12} lg={12} sm={12}>
                        <ColorPicker
                            id='colorNombre'
                            name='colorNombre'
                            label="Color nombre"
                            required
                            style={{ width: "100%" }}
                            defaultValue={values.colorNombre}
                            value={values.colorNombre}
                            margin="normal"
                            variant='outlined'
                            onChange={onChangeValue("colorNombre")}
                            inputProps={{ zIndex: 0, value: values.colorNombre }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={12} sm={12}>
                        <ColorPicker
                            id='colorGerencia'
                            name='colorGerencia'
                            label="Color gerencia"
                            required
                            style={{ width: "100%" }}
                            defaultValue={values.colorGerencia}
                            value={values.colorGerencia}
                            margin="normal"
                            variant='outlined'
                            onChange={onChangeValue("colorGerencia")}
                            inputProps={{ zIndex: 0, value: values.colorGerencia }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={12} sm={12}>
                        <ColorPicker
                            id='colorMensaje'
                            name='colorMensaje'
                            label="Color mensaje"
                            required
                            style={{ width: "100%" }}
                            defaultValue={values.colorMensaje}
                            value={values.colorMensaje}
                            margin="normal"
                            variant='outlined'
                            onChange={onChangeValue("colorMensaje")}
                            inputProps={{ zIndex: 0, value: values.colorMensaje }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={12} sm={12}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={values.temaActivo}
                                    onChange={onChangeValue("temaActivo")}
                                    color="primary"
                                    name="temaActivo"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                            }
                            label="Tema activo"
                        />
                    </Grid>
                    <Grid item xs={12} lg={12} sm={12}>
                        <Button
                            disabled={loading}
                            variant="contained"
                            style={{ backgroundColor: "#69bd4b", color: "white" }}
                            onClick={guardar}
                        >
                            {loading ? <CircularProgress size={25} color='inherit' /> : 'Guardar'}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item sm={12} md={8}>
                <iframe
                    sandbox
                    seamless
                    srcdoc={plantillaCorreo(values, headerImg, footerImg)}
                    width='100%'
                    height={1500}
                    style={{ border: 'none' }}
                ></iframe>
            </Grid>
        </Grid>
    )
}

export default FormularioTema;
