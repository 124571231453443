import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Grid, Chip, Container, Paper } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { IOSSwitch } from "../../helpers/components/ios-switch";
import {
  FloatingMenu,
  MainButton,
  ChildButton,
} from "react-floating-button-menu";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";
import AddAlertIcon from "@material-ui/icons/AddAlert";
import PersonIcon from "@material-ui/icons/Person";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Loader from "react-loader-spinner";
import Popover from "@material-ui/core/Popover";
import MaskedInput from "react-text-mask";
import PropTypes from "prop-types";
import NoteAddIcon from "@material-ui/icons/Note";

//Appbar
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import InputBase from "@material-ui/core/InputBase";
import { fade, makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";

//Scroll bar
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import UsuariosList from "../../plantillaProblema/components/usuarios-list";
import RolList from "../components/rol-list";

//Own components
import Layout from "../../layout/containers/Layout";
import ListaObjetos from "../components/lista-objetos";
import ObjetosTabla from "../components/objetos-tabla";
import SecondNavigation from "../components/second-navigation";
import AddButton from "../../helpers/components/add-button-float";
import API from "../../utils/api";
import { isSignedIn } from "../../utils/auth";
import { consultarPermiso, convertYYYYMMDDToDate } from "../../utils/helpers";
import { convertirASegundos, convertToYearMonthDay } from "../../utils/helpers";
import Causas from "./causas";
import { validaPermisoPaginaActual } from "../../utils/helpers";
import {
  getNavigationHistory,
  setNavigationHistory,
} from "../../utils/navigationGestion";
//CSS
import "../components/gestion-style.css";
import { DataGrid } from "devextreme-react";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

function getBigModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    maxHeight: 800,
  };
}

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0-9]/, /[0-9]/, ":", /[0-5]/, /[0-9]/]}
      placeholderChar={"\u2000"}
      showMask
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },

  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  paperUserModal: {
    position: "absolute",
    width: "75%",
    height: "600px",
    maxHeight: "600px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
  modalLargote: {
    position: "absolute",
    width: "75%",
    height: "800px",
    maxHeight: "800px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
  secondNavigationModal: {
    position: "absolute",
    width: "50%",
    height: "400px",
    maxHeight: "400px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  popover: {
    pointerEvents: "none",
  },
  paperPopover: {
    padding: theme.spacing(1),
    background: "#000",
    color: "#fff",
  },
});

class AdministradorAvisos extends Component {
  state = {
    actionType: "Agregar",
    openDirectoryModal: false,
    openProblema: false,
    openAsignarUsuarios: false,
    openCausa: false,
    openMoverA: false,
    isOpenFabMenu: false,
    actionType: "", //Crear, Actualizar
    idObjetoPadre: null,
    nombreObjetoPadre: "Administrar Gestiones",
    llaveObjetoPadre: "AITModulo",
    idObjetoEditar: "",
    llaveObjetoEditar: "",
    nombre: "",
    activo: false,
    fechaInicio: "",
    fechaFin: "",
    rangoRojo: "",
    rangoAmarillo: "",
    codigoUsuarioResponsable: "",
    nombreUsuarioResponsable: "",
    botonBackDisplay: "none",
    listaObjetos: [],
    listaUsuarios: [],
    history: [],
    anchorEl: null,
    popOverOpen: false,
    textoPopover: "",
    problema: {
      codigoProblema: "",
      nombre: "",
      descripcion: "",
      popular: "",
      tiempoEsperaEstimado: "",
      activo: "",
      tiempoEsperaSegundos: 0,
      fechaInicio: null,
      fechaFin: null,
      esEditable: "",
      codigoPlantilla: "",
      codigoPlantillaTareas: "",
    },

    llaveTablaCarpeta: "AITCarpeta",
    llaveTablaGestion: "AITProblema",
    listaRoles: [],
    listaRolesObjeto: [],
    openPermisosModal: false,
    rolSeleccionado: [],
    C: false,
    R: false,
    U: false,
    D: false,
    E: false,
    N: false,
    G: false,
    permisoCarpetaActual: {
      C: false,
      R: false,
      U: false,
      D: false,
      E: false,
      N: false,
      G: false,
    },
    codigoProblemaMover: null,
    editandoRol: false,
    idRolEditando: null,
    nombreRolEditando: "",
    cargandoGrabacionPermisos: false,
  };

  async componentWillMount() {
    //CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
    const llaveAgrupacion = "Menu.Principal.Web";
    const llavePagina = "Menu.Principal.Web.Gestiones";
    await validaPermisoPaginaActual(llaveAgrupacion, llavePagina);
    //FIN DE CONSULTA DE PERMISOS

    const verify = await isSignedIn();
    console.log(verify.response);
    if (!verify.response) {
      window.location.href = "/login";
    }

    //sí viene el parámetro DPI guardar en estado, para luego abrir una nueva gestión reenviando este DPI como param
    if(this.props.match.params.dpi !==null){
      this.setState({dpi:this.props.match.params.dpi})
    }

    //Add instructions for check navigation history in sessionStorage
    let historial = await getNavigationHistory();
    console.log("historial", historial);
    if (Array.isArray(historial) && historial.length > 0) {
      //Obtiene el último elemento del array
      let actualElement = historial[historial.length - 1];
      if (actualElement) {
        this.setState(
          {
            idObjetoPadre: actualElement.idObjeto,
            nombreObjetoPadre: actualElement.nombre,
            llaveObjetoPadre: actualElement.llave,
            history: historial,
          },
          async () => {
            let permisos = await consultarPermiso(
              this.state.idObjetoPadre,
              this.state.llaveObjetoPadre
            );
            this.setState({ permisoCarpetaActual: permisos });
            this.cargarLista();
          }
        );
      }
    } else {
      //Si no hay datos guardados en la session de navegación debe crearlos
      await this.obtenerIdModulo();
      let history = this.state.history;
      history.push({
        idObjeto: this.state.idObjetoPadre,
        nombre: this.state.nombreObjetoPadre,
        llave: "AITModulo",
      }); //inserta la primera posición del array para navegacion
      this.setState({ history: history }, async () => {
        let permisos = await consultarPermiso(
          this.state.idObjetoPadre,
          "AITModulo"
        );
        this.setState({ permisoCarpetaActual: permisos });
        //Graba datos de navegación
        setNavigationHistory(history);
        this.cargarLista();
      });
    }
  }

  async obtenerIdModulo() {
    try {
      //Loader
      this.setState({ cargando: true });

      let request = await API.fetchGetRequest(
        `api/carpetaController/obtenerModulo/1`
      );
      if (request.statusCode !== 200) {
        alert(
          "Hubo un error al obtener el ID del módulo, consulta con un administrador"
        );
      }

      console.log(request.statusCode);
      if (request.statusCode === 401) {
        //alert("cerrar sesion");
        window.location.href = "/login";
      } else {
        this.setState({
          idObjetoPadre: request.data.data.Codigo,
          nombreObjetoPadre: request.data.data.Nombre,
        });
      }
    } catch (error) {
      console.log(error);
      if (error.toString().includes("401")) {
        window.location.href = "/login";
      }
    } finally {
      this.setState({ cargando: false });
    }
  }

  async cargarLista() {
    try {
      //Loader
      this.setState({ cargando: true });

      //Muestra/oculta botón para regresar en la navegación
      this.botonBack();

      const verify = await isSignedIn();
      if (!verify.response) {
        window.location.href = "/login";
      }
      const { idObjetoPadre, nombreObjetoPadre, llaveObjetoPadre } = this.state;
      const params = {
        codigoUsuario: verify.codigoUsuario,
      };
      const request = await API.fetchGetRequestWithParams(
        `api/carpetaController/listar/${idObjetoPadre}/${llaveObjetoPadre}`,
        params
      );
      // const request = await API.post(
      //   `api/procesoGestionWeb/listarObjetosHijos`,
      //   data
      // );
      if (request.statusCode != 200) {
        alert(
          "Hubo un error al realizar la operación, consulta con un administrador"
        );
      }

      console.log(request.statusCode);
      if (request.statusCode === 401) {
        //alert("cerrar sesion");
        window.location.href = "/login";
      } else {
        if (Array.isArray(request.data.data)) {
          this.setState({
            listaObjetos: request.data.data,
          });
        }
      }
    } catch (error) {
      console.log(error);
      if (error.toString().includes("401")) {
        window.location.href = "/login";
      }
    } finally {
      this.setState({ cargando: false });
    }
  }

  cargarPermisosPorId = async (idPermiso) => {
    let respuesta = {};
    const verify = await isSignedIn();

    console.log(verify.response);
    if (!verify.response) {
      window.location.href = "/login";
    }

    const request = await API.fetchGetRequest(
      `api/permiso/listaPermisosPorId/${idPermiso}`
    );

    if (request.data.data !== null) {
      respuesta = request.data.data;
      console.log("PermisoPorID", respuesta);
      this.setState({
        C: respuesta.C,
        R: respuesta.R,
        U: respuesta.U,
        D: respuesta.D,
        E: respuesta.E,
        N: respuesta.N,
        G: respuesta.G,
        editandoRol: true,
        idRolEditando: respuesta.CodigoRol,
        nombreRolEditando: respuesta.nombreRol,
      });
    }
    if (request.statusCode != 200) {
      alert(
        "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
      );
    }
  };

  async cargarDatosEdicionCarpeta() {
    const request = await API.fetchGetRequest(
      "api/carpetaController/cargarDatosEdicion/" + this.state.idObjetoEditar
    );

    this.setState({
      nombre: request.data.data[0].Nombre,
      activo: request.data.data[0].Activo,
    });
  }

  async cargarListaRolesPorObjeto(idObjeto, llave) {
    const request = await API.fetchGetRequest(
      `api/permiso/listaRolesPorObjeto/${idObjeto}/${llave}`
    );
    if (Array.isArray(request.data.data)) {
      this.setState({
        listaRolesObjeto: request.data.data,
      });
    }
  }

  async cargarListaRoles() {
    const request = await API.fetchGetRequest(`api/permiso/listarRoles/`);
    if (Array.isArray(request.data.data)) {
      this.setState({
        listaRoles: request.data.data,
      });
    }
  }

  // async cargarDatosEdicionProblema() {
  //   const request = await API.fetchGetRequest(
  //     "api/problemaV2/listarObjetoPorId/" +
  //       this.state.idObjetoEditar +
  //       "/" +
  //       this.state.llaveObjetoEditar
  //   );

  //   let problema =  this.state.problema;
  //   problema.nombre= request.data.data[0].Nombre;
  //   problema.descripcion = request.data.data[0].Descripcion;
  //   problema.popular = request.data.data[0].Popular;
  //   problema.tiempoEsperaSegundos = request.data.data[0].tiempoEsperaSegundos;

  //   problema.fechaInicio = await convertYYYYMMDDToDate(
  //         request.data.data[0].FechaInicio
  //       );
  //       problema.fechaFin = await convertYYYYMMDDToDate(request.data.data[0].FechaFin);

  //   this.setState(
  //     {

  //       rangoRojo: request.data.data[0].RangoRojo,
  //       rangoAmarillo: request.data.data[0].RangoAmarillo,
  //       codigoUsuarioResponsable: request.data.data[0].CodigoUsuarioResponsable,
  //       activo: request.data.data[0].Activo,
  //     },
  //     () => {
  //       if (request.data.data[0].CodigoUsuarioResponsable > 0) {
  //         this.cargarUsuarioResponsable(
  //           request.data.data[0].CodigoUsuarioResponsable
  //         );
  //       }
  //     }
  //   );
  // }

  async cargarDatosEdicionProceso() {
    const request = await API.fetchGetRequest(
      "api/procesoGestionWeb/listarObjetoPorId/" +
        this.state.idObjetoEditar +
        "/" +
        this.state.llaveObjetoEditar
    );

    this.setState(
      {
        nombre: request.data.data[0].Nombre,
        fechaInicio: await convertYYYYMMDDToDate(
          request.data.data[0].FechaInicial
        ),
        fechaFin: await convertYYYYMMDDToDate(request.data.data[0].FechaFinal),
        rangoRojo: request.data.data[0].RangoRojo,
        rangoAmarillo: request.data.data[0].RangoAmarillo,
        codigoUsuarioResponsable: request.data.data[0].CodigoUsuarioResponsable,
        activo: request.data.data[0].Activo,
      },
      () => {
        if (request.data.data[0].CodigoUsuarioResponsable > 0) {
          this.cargarUsuarioResponsable(
            request.data.data[0].CodigoUsuarioResponsable
          );
        }
      }
    );
  }

  editar = async (idObjeto, llave) => {
    let vIsSignedIn = await isSignedIn();
    if (!vIsSignedIn.response) {
      this.window.location.href = "/login";
    }
    //Valida permisos para editar
    let permisos = await consultarPermiso(idObjeto, llave);
    if (!permisos.U) {
      toast.warn("No tienes permiso para editar este elemento");
      return;
    }

    this.setState(
      {
        idObjetoEditar: idObjeto,
        llaveObjetoEditar: llave,
        actionType: "Actualizar",
      },
      async () => {
        if (llave === this.state.llaveTablaCarpeta) {
          await this.cargarDatosEdicionCarpeta();
          this.handleOpenDirectoryModal();
        } else if (llave === this.state.llaveTablaGestion) {
          window.location.href = `/tipogestion/${idObjeto}`;
        } else if (llave == "PROCESO_GESTION") {
          await this.cargarDatosEdicionProceso();
          this.handleOpenProcessModal();
        }
      }
    );
  };

  clonar = async (idObjeto, llave) => {
    let vIsSignedIn = await isSignedIn();
    if (!vIsSignedIn.response) {
      this.window.location.href = "/login";
    }
    //Valida permisos para editar
    let permisos = await consultarPermiso(idObjeto, llave);
    if (!permisos.U) {
      toast.warn("No tienes permiso para editar este elemento");
      return;
    }

    if (llave === this.state.llaveTablaCarpeta) {
    } else if (llave === this.state.llaveTablaGestion) {
      let data = {
        codigoProblema: idObjeto,
        codigoCarpeta: this.state.idObjetoPadre,
        codigoUsuarioOpera: vIsSignedIn.codigoUsuario,
        usuarioOpera: vIsSignedIn.userName,
      };
      let uri = `api/problema/ClonarTipoGestion`;
      let request = await API.post(uri, data);
      if (request.status != 200) {
        alert(
          "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
        );
      }

      if (request.statusCode === 401) {
        //alert("cerrar sesion");
      } else {
        toast.success("el tipo de gestión se ha clonado exitosamente.");
        this.cargarLista();
      }
    }
  };

  handleChangeProblema = (name) => (event) => {
    if (name === "activo" || name === "popular") {
      // this.setState({ [name]: event.target.checked });

      const { problema } = { ...this.state };
      const currentState = problema;
      const { checked } = event.target;
      currentState[name] = checked;

      this.setState({ problema: currentState });
    } else {
      // this.setState({
      //   [name]: event.target.value
      // });

      const { problema } = { ...this.state };
      const currentState = problema;
      const { value } = event.target;
      currentState[name] = value;

      //Si el campo que se actualiza es el tiempo de espera debe calcular su valor en segundos
      if (name === "tiempoEsperaEstimado") {
        let segundos = convertirASegundos(value);
        currentState.tiempoEsperaSegundos = segundos;
      }

      this.setState({ problema: currentState });
    }
  };

  handleOpenDirectoryModal = () => {
    this.setState({
      openDirectoryModal: true,
      isOpen: false,
    });
  };

  handleOpenProblemModal = () => {
    this.setState({
      openProblema: true,
      isOpen: false,
    });
  };

  handleOpenProcessModal = () => {
    this.setState({
      openProcessModal: true,
      isOpen: false,
    });
  };

  handleOpenPermisosModal = async (idObjeto, llave) => {
    //Valida que solo el rol administrador pueda ejecuar esta acción
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
    }

    const req = await API.fetchGetRequest(
      `api/usuario/getRolByUser/${session["codigoUsuario"]}`
    );

    if (Array.isArray(req.data.data)) {
      const rolesAsignados = req.data.data;
      const rolAdmin = 1;
      console.log("Es admin", rolesAsignados.includes(rolAdmin));
      if (!rolesAsignados.includes(rolAdmin)) {
        toast.warn("No tienes acceso para usar este recurso");
        return;
      }

      this.cargarListaRolesPorObjeto(idObjeto, llave);
      this.cargarListaRoles();
      this.setState({
        openPermisosModal: true,
        isOpen: false,
        idObjetoPermiso: idObjeto,
        llaveObjetoPermiso: llave,
      });
    } else {
      toast.warn("No se ha podido verificar el nivel de acceso");
      return;
    }
  };

  handleOpenMoverA = async (idObjeto, llave) => {
    //Valida que solo el rol administrador pueda ejecuar esta acción
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
    }

    console.log("Roles");
    console.log(session.roles);

    let rolAdmon = 1;

    if (!session.roles.includes(rolAdmon)) {
      toast.warn("No tienes acceso para usar este recurso");
      return;
    }

    this.cargarListaRolesPorObjeto(idObjeto, llave);
    this.cargarListaRoles();
    this.setState({
      openMoverA: true,
      isOpen: false,
      codigoProblemaMover: idObjeto,
    });
  };

  moverGestion = async (codigoCarpeta) => {
    if (codigoCarpeta === null || this.state.codigoProblemaMover === null) {
      this.setState({ openMoverA: false, codigoProblemaMover: null });
      return;
    }
    try {
      let vIsSignedIn = await isSignedIn();
      let palabra = "";
      if (vIsSignedIn.response) {
        let request;
        let data = {
          codigoProblema: this.state.codigoProblemaMover,
          codigoCarpetaDestino: codigoCarpeta,
          codigoUsuarioOpera: vIsSignedIn.codigoUsuario,
          usuarioOpera: vIsSignedIn.userName,
        };

        console.log("Data", data);
        request = await API.post(`api/problema/moverA`, data);
        if (request.status != 200) {
          alert(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        }

        if (request.statusCode === 401) {
          //alert("cerrar sesion");
        } else {
          toast.success("La gestión se movió exitosamente.");
          this.setState({ openMoverA: false, codigoProblemaMover: null });
          this.limpiarState();
          this.cargarLista();
        }
      }
    } catch (e) {
      alert("hubo un error");
    }
  };

  verCausas = (codigoEntidad, llave) => {
    this.props.history.push("/causasv2/" + codigoEntidad);
  };

  handleClose = () => {
    this.setState({
      openProblema: false,
      openDirectoryModal: false,
      openProcessModal: false,
      openCausa: false,
      openPermisosModal: false,
      codigoEntidadCausa: "",
      idObjetoEditar: "",
      llaveObjetoEditar: "",
      nombre: "",
      activo: "",
      actionType: "",
    });
    this.limpiarState();
  };

  handleOpenAsignarUsuarios = (codigoTarea) => {
    console.log("codigoTarea: " + codigoTarea);
    this.setState({
      openAsignarUsuarios: true,
    });
  };

  handleCloseAsignarUsuarios = () => {
    this.setState({
      openAsignarUsuarios: false,
      txtTextoBusquedaUsuario: "",
      listaUsuarios: [],
    });
  };

  handleChange = (name) => (event) => {
    if (
      name === "activo" ||
      name === "C" ||
      name === "R" ||
      name === "U" ||
      name === "D" ||
      name === "E" ||
      name === "N" ||
      name === "G"
    ) {
      this.setState({ [name]: event.target.checked });
    } else {
      this.setState({
        [name]: event.target.value,
      });
    }
  };

  irConfiguracion = async (codigo) => {
    let permisos = await consultarPermiso(codigo, "AITProblema");
    if (!permisos.U) {
      toast.warn("No tienes permiso para configurar este elemento");
      return;
    }
    this.props.history.push("/problem/" + codigo);
  };

  nuevaInstancia = async (codigo, llave) => {
    var permisos = await consultarPermiso(codigo, llave);
    console.log("PermisosEjecutar");
    console.log(permisos);

    if (!permisos.E) {
      toast.warn("No tienes permiso para ejecutar este elemento");
      return;
    }
    if(this.state.dpi !== null){
      window.open(`/aviso/nuevo/${codigo}/${this.state.dpi}`);
    }else{
      this.props.history.push("/aviso/nuevo/" + codigo);
    }
   
  };

  navegar = (idObjeto, nombre, llave) => {
    if (llave === "PROCESO_GESTION") {
      return;
    } else if (llave === this.state.llaveTablaCarpeta) {
      let history = this.state.history;
      console.log("Llave", llave);
      if (idObjeto === null || idObjeto === undefined) {
        console.log("idObjeto", idObjeto);
        toast.warn(
          "No pudimos cargar la información, por favor inténtalo nuevamente."
        );
        return;
      }
      history.push({ idObjeto: idObjeto, nombre: nombre, llave: llave });
      this.setState(
        {
          idObjetoPadre: idObjeto,
          nombreObjetoPadre: nombre,
          llaveObjetoPadre: llave,
          history: history,
        },
        async () => {
          let permisos = await consultarPermiso(idObjeto, llave);
          this.setState({ permisoCarpetaActual: permisos });
          console.log("permisos");
          console.log(permisos);
          //Guarda historial de navegación en session
          setNavigationHistory(history);
          this.cargarLista();
        }
      );
    }
  };

  regresar = () => {
    let history = this.state.history;

    let cantidadPosiciones = history.length;
    let nombreObjetoPadre;
    let idObjetoPadre;
    let llaveObjetoPadre;
    if (cantidadPosiciones > 1) {
      //Elimina el último elemento del array que corresponde al idObjetoPadre actual
      history.pop();
      //Actualiza cantidad de elementos del array
      cantidadPosiciones = history.length;
      //Encuentra el valor de la nueva última posición del array y la asigna al idObjetoPadre
      idObjetoPadre = history[cantidadPosiciones - 1].idObjeto;
      nombreObjetoPadre = history[cantidadPosiciones - 1].nombre;
      llaveObjetoPadre = history[cantidadPosiciones - 1].llave;

      //Actualiza valores del state
      this.setState(
        {
          idObjetoPadre: idObjetoPadre,
          nombreObjetoPadre: nombreObjetoPadre,
          llaveObjetoPadre: llaveObjetoPadre,
          history: history,
        },
        async () => {
          let permisos = await consultarPermiso(
            idObjetoPadre,
            llaveObjetoPadre
          );
          this.setState({ permisoCarpetaActual: permisos });
          console.log("permisos");
          console.log(permisos);
          //Almacena historial de navegación
          setNavigationHistory(history);
          this.cargarLista();
        }
      );
    }
  };

  botonBack = () => {
    let cantidadPosiciones = this.state.history.length;
    let display;
    if (cantidadPosiciones > 1) {
      display = "inline-block";
    } else {
      display = "none";
    }

    this.setState({ botonBackDisplay: display });
  };

  guardarDirectorio = async () => {
    const { nombre } = this.state;

    if (nombre.trim() == "") {
      toast.warn("Ingrese un nombre válido");
      return;
    }

    try {
      let vIsSignedIn = await isSignedIn();
      let palabra = "";
      if (vIsSignedIn.response) {
        let request;
        let data;

        if (this.state.actionType == "Agregar") {
          data = {
            IdCarpeta: null,
            IdPadre: this.state.idObjetoPadre,
            TablaPadre: this.state.llaveObjetoPadre,
            Nombre: this.state.nombre,
            Activo: true,
            UsuarioOpera: vIsSignedIn.userName,
            CodigoUsuarioOpera: vIsSignedIn.codigoUsuario,
          };
          palabra = "grabado";
        } else {
          data = {
            IdCarpeta: this.state.idObjetoEditar,
            IdPadre: this.state.idObjetoPadre,
            TablaPadre: this.state.llaveObjetoPadre,
            Nombre: this.state.nombre,
            Activo: this.state.activo,
            UsuarioOpera: vIsSignedIn.userName,
            CodigoUsuarioOpera: vIsSignedIn.codigoUsuario,
          };
          palabra = "editado";
        }

        request = await API.post(`api/carpetaController/grabarEditar`, data);
        if (request.status != 200) {
          alert(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        }

        if (request.statusCode === 401) {
          //alert("cerrar sesion");
        } else {
          toast.success("La carpeta se ha " + palabra + " exitosamente.");
          this.handleClose();
          this.limpiarState();
          this.cargarLista();
          //Redirecciona cuando la acción es grabar
          //   if(this.state.actionType == "Agregar"){
          //                           // this.props.history.push("/entidad/" + request.data.data.Codigo);
          //                           this.redireccionar(request.data.data.CodigoPlantillaTareas);
          //                         }else{
          //                           this.handleClose();
          //                           this.cargarLista();
          //                         }
        }
      }
    } catch (e) {
      alert("hubo un error");
    }
  };

  eliminar = async (idObjeto, llave) => {
    let vIsSignedIn = await isSignedIn();
    if (vIsSignedIn.response) {
      let data = {
        Id: idObjeto,
        CodigoUsuarioOpera: vIsSignedIn.codigoUsuario,
        UsuarioOpera: vIsSignedIn.userName,
      };

      //Valida permisos
      let permisos = await consultarPermiso(idObjeto, llave);
      if (!permisos.D) {
        toast.warn("No tienes permiso para eliminar este elemento");
        return;
      }

      let endpoint = "";
      switch (llave) {
        case this.state.llaveTablaCarpeta:
          endpoint = `api/carpetaController/eliminar/`;
          break;
        case this.state.llaveTablaGestion:
          endpoint = `api/problema/eliminar/${idObjeto}/${vIsSignedIn.userName}`;
          data = undefined;
          break;
      }

      let request = await API.post(endpoint, data);
      if (request.status != 200) {
        alert(
          "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
        );
      }

      if (request.status === 401) {
        //alert("cerrar sesion");
      } else {
        toast.success("El registro se eliminó exitosamente.");
        this.cargarLista();
      }
    } else {
      toast.error("La sessión no se encuentra activa");
      window.location.href = "/login";
    }
  };

  guardarProceso = async () => {
    const {
      nombre,
      fechaInicio,
      fechaFin,
      activo,
      rangoRojo,
      rangoAmarillo,
      codigoUsuarioResponsable,
    } = this.state;

    if (nombre.trim() == "") {
      toast.warn("Ingrese un nombre válido");
      return;
    }

    try {
      let vIsSignedIn = await isSignedIn();
      let palabra = "";
      let apiEndpoint = "";
      if (vIsSignedIn.response) {
        let request;
        let data;

        if (this.state.actionType === "Agregar") {
          data = {
            idObjeto: null,
            idObjetoPadre: this.state.idObjetoPadre,
            nombre: nombre,
            fechaInicial: fechaInicio,
            fechaFinal: fechaFin,
            rangoRojo: rangoRojo === "" ? null : rangoRojo,
            rangoAmarillo: rangoAmarillo === "" ? null : rangoAmarillo,
            codigoUsuarioResponsable: codigoUsuarioResponsable,
            activo: true,
            usuarioOpera: vIsSignedIn.userName,
          };
          palabra = "grabado";
          apiEndpoint = `api/procesoGestionWeb/grabarEditarProceso`;
        } else if (this.state.actionType === "Actualizar") {
          data = {
            idObjeto: this.state.idObjetoEditar,
            idObjetoPadre: this.state.idObjetoPadre,
            nombre: nombre,
            fechaInicial: fechaInicio,
            fechaFinal: fechaFin,
            rangoRojo: rangoRojo,
            rangoAmarillo: rangoAmarillo,
            codigoUsuarioResponsable: codigoUsuarioResponsable,
            activo: activo,
            usuarioOpera: vIsSignedIn.userName,
          };
          palabra = "editado";
          apiEndpoint = `api/procesoGestionWeb/grabarEditarProceso`;
        } else if (this.state.actionType === "Clonar") {
          data = {
            idObjeto: this.state.idObjetoEditar,
            idObjetoPadre: this.state.idObjetoPadre,
            nombre: nombre,
            fechaInicial: fechaInicio,
            fechaFinal: fechaFin,
            rangoRojo: rangoRojo,
            rangoAmarillo: rangoAmarillo,
            codigoUsuarioResponsable: codigoUsuarioResponsable,
            activo: activo,
            usuarioOpera: vIsSignedIn.userName,
          };
          palabra = "clonado";
          apiEndpoint = `api/procesoGestionWeb/clonarProceso`;
        }

        request = await API.post(apiEndpoint, data);
        if (request.status != 200) {
          alert(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        }

        if (request.statusCode === 401) {
          //alert("cerrar sesion");
        } else {
          toast.success("El proceso se ha " + palabra + " exitosamente.");
          this.handleClose();
          this.limpiarState();
          this.cargarLista();
          //Redirecciona cuando la acción es grabar
          //   if(this.state.actionType == "Agregar"){
          //                           // this.props.history.push("/entidad/" + request.data.data.Codigo);
          //                           this.redireccionar(request.data.data.CodigoPlantillaTareas);
          //                         }else{
          //                           this.handleClose();
          //                           this.cargarLista();
          //                         }
        }
      }
    } catch (e) {
      alert("hubo un error");
    }
  };

  otorgarPermisos = async () => {
    const {
      rolSeleccionado,
      idObjetoPermiso,
      llaveObjetoPermiso,
      C,
      R,
      U,
      D,
      E,
      N,
      G,
      editandoRol,
      idRolEditando,
      nombreRolEditando,
    } = this.state;

    try {
      this.setState({ cargandoGrabacionPermisos: true });

      let vIsSignedIn = await isSignedIn();
      let palabra = "";
      if (vIsSignedIn.response) {
        let request;
        let data;

        let codigoRol = [];

        if (editandoRol) {
          codigoRol.push(idRolEditando);
        } else {
          rolSeleccionado.forEach((item, i) => {
            codigoRol.push(item.CodigoRol);
          });
        }

        if (codigoRol.length < 1) {
          toast.warn("Debe seleccionar al menos un rol");
          return;
        }

        data = {
          Id: idObjetoPermiso,
          Llave: llaveObjetoPermiso,
          CodigoRol: codigoRol,
          C: C,
          R: R,
          U: U,
          D: D,
          E: E,
          N: N,
          G: G,
          CodigoUsuarioOpera: vIsSignedIn.codigoUsuario,
          UsuarioOpera: vIsSignedIn.userName,
        };
        console.log("Rol seleccionado");
        console.log(rolSeleccionado);
        request = await API.post(`api/permiso/otorgar`, data);
        if (request.status != 200) {
          alert(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        } else {
          toast.success("El permiso se ha otorgado con éxito ");
          this.limpiarState();
          let rselec = [];
          this.setState({ rolSeleccionado: rselec });
          this.setState();
          this.cargarListaRolesPorObjeto(idObjetoPermiso, llaveObjetoPermiso);
          //this.handleClose();
        }
      }
    } catch (e) {
      alert("hubo un error");
    } finally {
      this.setState({ cargandoGrabacionPermisos: false });
    }
  };

  revocarPermisos = async (codigoRol) => {
    const { idObjetoPermiso, llaveObjetoPermiso, C, R, U, D, E, N, G } =
      this.state;
    try {
      let vIsSignedIn = await isSignedIn();
      let palabra = "";
      if (vIsSignedIn.response) {
        let request;
        let data;

        data = {
          Id: idObjetoPermiso,
          Llave: llaveObjetoPermiso,
          CodigoRol: [codigoRol],
          C: C,
          R: R,
          U: U,
          D: D,
          E: E,
          N: N,
          G: G,
          CodigoUsuarioOpera: vIsSignedIn.codigoUsuario,
          UsuarioOpera: vIsSignedIn.userName,
        };

        request = await API.post(`api/permiso/revocar`, data);
        if (request.status != 200) {
          alert(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        }

        if (request.status === 401) {
          //alert("cerrar sesion");
        } else {
          this.setState({
            rolSeleccionado: [],
            C: false,
            R: false,
            U: false,
            D: false,
            E: false,
            N: false,
            G: false,
            editandoRol: false,
            idRolEditando: null,
            nombreRolEditando: "",
          });
          toast.success("El permiso se ha revocado con éxito ");
          this.cargarListaRolesPorObjeto(idObjetoPermiso, llaveObjetoPermiso);
          //this.handleClose();
        }
      }
    } catch (e) {
      alert("hubo un error");
    }
  };

  limpiarState = () => {
    this.setState({
      idObjetoEditar: "",
      llaveObjetoEditar: "",
      nombre: "",
      activo: "",
      usuarioOpera: "",
      cargando: false,
      rangoRojo: null,
      rangoAmarillo: null,
      problema: {
        codigoProblema: "",
        nombre: "",
        descripcion: "",
        popular: "",
        tiempoEsperaEstimado: "",
        activo: "",
        tiempoEsperaSegundos: 0,
        fechaInicio: null,
        fechaFin: null,
        esEditable: "",
        codigoPlantilla: "",
        codigoPlantillaTareas: "",
        codigoProblemaMover: null,
      },
      rolSeleccionado: [],
      C: false,
      R: false,
      U: false,
      D: false,
      E: false,
      N: false,
      G: false,
      editandoRol: false,
      idRolEditando: null,
      nombreRolEditando: "",
    });
  };

  cargarListaUsuarios = async () => {
    let criterio =
      this.state.txtTextoBusquedaUsuario === "" ||
      this.state.txtTextoBusquedaUsuario === null ||
      this.state.txtTextoBusquedaUsuario === undefined
        ? "x-x"
        : this.state.txtTextoBusquedaUsuario;

    const request = await API.fetchGetRequest(`api/Usuario/buscar/${criterio}`);
    try {
      if (Array.isArray(request.data.usuarios)) {
        this.setState({
          listaUsuarios: request.data.usuarios,
        });
      } else {
        this.setState({
          listaUsuarios: [],
        });
      }
    } catch (error) {
      console.log("cargarListaTareas error:" + error);
    }
  };

  seleccionarUsuario = async (codigoUsuario) => {
    try {
      let vIsSignedIn = await isSignedIn();
      if (
        codigoUsuario === "" ||
        codigoUsuario === null ||
        codigoUsuario === undefined
      ) {
        return;
      }

      this.cargarUsuarioResponsable(codigoUsuario);
    } catch (e) {
      alert(e);
    } finally {
      this.handleCloseAsignarUsuarios();
    }
  };

  cargarUsuarioResponsable = async (codigoUsuario) => {
    const request = await API.fetchGetRequest(`api/Usuario/${codigoUsuario}`);
    try {
      if (request.data !== null) {
        this.setState({
          codigoUsuarioResponsable: request.data.CodigoUsuario,
          nombreUsuarioResponsable: request.data.NombreCompletoUsuario,
        });
      }

      console.log(request);
    } catch (error) {
      console.log("cargarUsuarioResponsable error:" + error);
    }
  };

  guardarProblema = async () => {
    const { nombre, fechaInicio, fechaFin } = this.state.problema;

    if (nombre.trim() == "") {
      toast.warn("Ingrese un nombre válido");
      return;
    }

    //Valida fechas
    if (!isNaN(Date.parse(fechaInicio)) || !isNaN(Date.parse(fechaFin))) {
      //Si una de las dos no es fecha
      if (
        (!isNaN(Date.parse(fechaInicio)) &&
          !isNaN(Date.parse(fechaFin)) === false) ||
        (!isNaN(Date.parse(fechaFin)) &&
          !isNaN(Date.parse(fechaInicio)) === false)
      ) {
        toast.warn("Olvidó ingresar una de las fechas");
        return;
      }

      //Si la fecha inicial es mayor que la final
      if (new Date(fechaInicio) > new Date(fechaFin)) {
        toast.warn("La fecha de inicio no debe ser mayor que la final");
        return;
      }
    }

    try {
      let vIsSignedIn = await isSignedIn();
      let palabra = "";
      if (vIsSignedIn.response) {
        let request;
        let data;

        if (this.state.actionType == "Agregar") {
          data = {
            CodigoProblema: null,
            CodigoSubEntidad: this.state.idObjetoPadre,
            Nombre: this.state.problema.nombre,
            Descripcion: this.state.problema.descripcion,
            Popular: this.state.problema.popular,
            TiempoEsperaEstimado: this.state.problema.tiempoEsperaSegundos,
            Activo: true,
            Eliminado: false,
            UsuarioOpera: vIsSignedIn.userName,
            CodigoPlantilla: null,
            CodigoPlantillaTareas: null,
            FechaInicio: this.state.problema.fechaInicio,
            FechaFin: this.state.problema.fechaFin,
            EsEditable: true,
          };
          palabra = "grabado";
        } else {
          data = {
            CodigoProblema: this.state.problema.codigoProblema,
            CodigoSubEntidad: this.state.idObjetoPadre,
            Nombre: this.state.problema.nombre,
            Descripcion: this.state.problema.descripcion,
            Popular: this.state.problema.popular,
            TiempoEsperaEstimado: this.state.problema.tiempoEsperaSegundos,
            Activo: true,
            Eliminado: false,
            UsuarioOpera: vIsSignedIn.userName,
            CodigoPlantilla: this.state.problema.codigoPlantilla,
            CodigoPlantillaTareas: this.state.problema.codigoPlantillaTareas,
            FechaInicio: this.state.problema.fechaInicio,
            FechaFin: this.state.problema.fechaFin,
            EsEditable: true,
          };
          palabra = "editado";
        }

        request = await API.post(`api/problema/grabarEditarTipoGestion`, data);

        if (request.status != 200) {
          alert(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        }

        if (request.statusCode === 401) {
          //alert("cerrar sesion");
        } else {
          toast.success("El tipo de gestión se " + palabra + " exitosamente.");
          //Redirecciona cuando la acción es grabar
          if (this.state.actionType == "Agregar") {
            window.location.href = `/tipogestion/${request.data.data.CodigoProblema}`;
            console.log(request.data.data.CodigoProblema);
          }

          // this.cargarLista();
          // this.limpiarState();
          // this.handleClose();
        }
      }
    } catch (e) {
      alert(e);
    }
  };

  guardarProceso = async () => {
    const {
      nombre,
      fechaInicio,
      fechaFin,
      activo,
      rangoRojo,
      rangoAmarillo,
      codigoUsuarioResponsable,
    } = this.state;

    if (nombre.trim() == "") {
      toast.warn("Ingrese un nombre válido");
      return;
    }

    try {
      let vIsSignedIn = await isSignedIn();
      let palabra = "";
      let apiEndpoint = "";
      if (vIsSignedIn.response) {
        let request;
        let data;

        if (this.state.actionType === "Agregar") {
          data = {
            idObjeto: null,
            idObjetoPadre: this.state.idObjetoPadre,
            nombre: nombre,
            fechaInicial: fechaInicio,
            fechaFinal: fechaFin,
            rangoRojo: rangoRojo === "" ? null : rangoRojo,
            rangoAmarillo: rangoAmarillo === "" ? null : rangoAmarillo,
            codigoUsuarioResponsable: codigoUsuarioResponsable,
            activo: true,
            usuarioOpera: vIsSignedIn.userName,
          };
          palabra = "grabado";
          apiEndpoint = `api/procesoGestionWeb/grabarEditarProceso`;
        } else if (this.state.actionType === "Actualizar") {
          data = {
            idObjeto: this.state.idObjetoEditar,
            idObjetoPadre: this.state.idObjetoPadre,
            nombre: nombre,
            fechaInicial: fechaInicio,
            fechaFinal: fechaFin,
            rangoRojo: rangoRojo,
            rangoAmarillo: rangoAmarillo,
            codigoUsuarioResponsable: codigoUsuarioResponsable,
            activo: activo,
            usuarioOpera: vIsSignedIn.userName,
          };
          palabra = "editado";
          apiEndpoint = `api/procesoGestionWeb/grabarEditarProceso`;
        } else if (this.state.actionType === "Clonar") {
          data = {
            idObjeto: this.state.idObjetoEditar,
            idObjetoPadre: this.state.idObjetoPadre,
            nombre: nombre,
            fechaInicial: fechaInicio,
            fechaFinal: fechaFin,
            rangoRojo: rangoRojo,
            rangoAmarillo: rangoAmarillo,
            codigoUsuarioResponsable: codigoUsuarioResponsable,
            activo: activo,
            usuarioOpera: vIsSignedIn.userName,
          };
          palabra = "clonado";
          apiEndpoint = `api/procesoGestionWeb/clonarProceso`;
        }

        request = await API.post(apiEndpoint, data);
        if (request.status != 200) {
          alert(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        }

        if (request.statusCode === 401) {
          //alert("cerrar sesion");
        } else {
          toast.success("La carpeta se ha " + palabra + " exitosamente.");
          this.handleClose();
          this.limpiarState();
          this.cargarLista();
          //Redirecciona cuando la acción es grabar
          //   if(this.state.actionType == "Agregar"){
          //                           // this.props.history.push("/entidad/" + request.data.data.Codigo);
          //                           this.redireccionar(request.data.data.CodigoPlantillaTareas);
          //                         }else{
          //                           this.handleClose();
          //                           this.cargarLista();
          //                         }
        }
      }
    } catch (e) {
      alert("hubo un error");
    }
  };

  handlePopoverOpen = (event, textoPopover) => {
    this.setState({
      anchorEl: event.currentTarget,
      popOverOpen: true,
      textoPopover: textoPopover,
    });
  };

  handlePopoverClose = () => {
    this.setState({ anchorEl: null, popOverOpen: false, textoPopover: "" });
  };

  render() {
    const { classes } = this.props;

    return (
      <Layout titulo="Administrar Gestiones">
        <div className={classes.root}>
          <Container maxWidth="md">
            <AppBar position="static">
              <Toolbar>
                <IconButton
                  style={{
                    display: this.state.botonBackDisplay,
                    color: "#FFF",
                  }}
                  onClick={() => this.regresar()}
                >
                  <ArrowBackIosIcon />
                </IconButton>
                <Typography className={classes.title} variant="h6" noWrap>
                  {this.state.nombreObjetoPadre}
                </Typography>

                {/* <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Buscar..."
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ "aria-label": "Buscar" }}
                  onChange={this.handleChange("criterioBusqueda")}
                  onKeyPress={(ev) => {
                    console.log("keyP: " + ev.key);
                    if (ev.key === "Enter") {
                      this.buscar();
                      ev.preventDefault();
                    }
                  }}
                />
              </div> */}
              </Toolbar>
            </AppBar>

            {this.state.cargando ? (
              <div
                style={{
                  width: "100%",
                  marginTop: 10,
                  textAlign: "center",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Loader
                  type="Oval"
                  color="#3498db"
                  height="50"
                  width="50"
                  style={{ textAlign: "center" }}
                />
              </div>
            ) : (
              <></>
            )}

            <Grid container spacing={3}>
              <Grid item xs={12} lg={12} sm={12}>
                <ObjetosTabla
                  data={this.state.listaObjetos}
                  editar={this.editar}
                  eliminar={this.eliminar}
                  navegar={this.navegar}
                  irConfiguracion={this.irConfiguracion}
                  nuevaInstancia={this.nuevaInstancia}
                  verCausas={this.verCausas}
                  clonar={this.clonar}
                  permisos={this.handleOpenPermisosModal}
                  moverA={this.handleOpenMoverA}
                />
              </Grid>
            </Grid>
          </Container>
        </div>

        <FloatingMenu
          slideSpeed={400}
          direction="up"
          spacing={8}
          className="FloatBottomDiv"
          isOpen={this.state.isOpen}
          style={{ left: "92vw" }}
        >
          <MainButton
            style={{
              backgroundColor: "#2c3e50",
              visibility: this.state.permisoCarpetaActual.C
                ? "visible"
                : "hidden",
            }}
            iconResting={
              <AddIcon
                style={{ fontSize: 20 }}
                nativeColor="white"
                style={{ color: "#FFF" }}
                text="Nuevo"
              />
            }
            iconActive={
              <ClearIcon
                style={{ fontSize: 20 }}
                nativeColor="white"
                style={{ color: "#FFF" }}
              />
            }
            onClick={() => this.setState({ isOpen: !this.state.isOpen })}
            size={56}
            //size={150}
          />

          {this.state.llaveObjetoPadre === "AITCarpeta" ? (
            <ChildButton
              disabled
              onMouseEnter={(event) => {
                this.handlePopoverOpen(event, "Nuevo tipo de gestión");
              }}
              onMouseLeave={this.handlePopoverClose}
              style={{ backgroundColor: "#FF0000" }}
              icon={
                <AddAlertIcon
                  style={{ fontSize: 20 }}
                  nativeColor="black"
                  //style={{ color: "#FFF" }}
                  style={{ color: "#2c3e50" }}
                />
              }
              size={40}
              onClick={() => {
                this.setState({ actionType: "Agregar" });
                this.handleOpenProblemModal();
              }}
            />
          ) : (
            <></>
          )}

          {/*
          <ChildButton
            onMouseEnter={(event) => {
              this.handlePopoverOpen(event, "Nuevo proceso");
            }}
            onMouseLeave={this.handlePopoverClose}
            style={{ backgroundColor: "#FF0000" }}
            icon={
              <NoteAddIcon
                style={{ fontSize: 20 }}
                nativeColor="black"
                style={{ color: "#FFF" }}
              />
            }
            size={40}
            onClick={() => {
              this.setState({ actionType: "Agregar" });
              this.handleOpenProcessModal();
            }}
          /> */}
          <ChildButton
            onMouseEnter={(event) => {
              this.handlePopoverOpen(event, "Nueva carpeta");
            }}
            onMouseLeave={this.handlePopoverClose}
            style={{ backgroundColor: "rgb(220,0,78)" }}
            backgroundColor="rgb(220,0,78)"
            back
            icon={
              <CreateNewFolderIcon
                style={{ fontSize: 20 }}
                nativeColor="black"
                style={{ color: "#2c3e50" }}
              />
            }
            size={40}
            onClick={() => {
              this.setState({ actionType: "Agregar" });
              this.handleOpenDirectoryModal();
            }}
          />
        </FloatingMenu>

        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.openDirectoryModal}
          onClose={this.handleClose}
        >
          <div style={getModalStyle()} className={classes.paper}>
            <form className={classes.container} noValidate autoComplete="off">
              <Typography variant="title" gutterBottom>
                {this.state.actionType == "Agregar" ? (
                  <div>Crear carpeta</div>
                ) : (
                  <div>Editar carpeta</div>
                )}
              </Typography>
              <TextField
                required
                id="txtNombre"
                label="Nombre"
                className={classes.textField}
                value={this.state.nombre}
                onChange={this.handleChange("nombre")}
                onKeyPress={(ev) => {
                  console.log("keyP: " + ev.key);
                  if (ev.key === "Enter") {
                    this.guardarDirectorio();
                    ev.preventDefault();
                  }
                }}
                margin="normal"
                variant="outlined"
                inputProps={{ maxLength: 512 }}
              />

              {this.state.actionType === "Actualizar" ? (
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.activo}
                        onChange={this.handleChange("activo")}
                        value="activo"
                        color="primary"
                      />
                    }
                    label="Activo"
                  />
                  <br />
                </div>
              ) : (
                <div></div>
              )}
              <Grid container spacing={3}>
                <Grid item xs={6} lg={6} sm={6} md={6}>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#f50057",
                      color: "white",
                      width: "100%",
                    }}
                    className={classes.buttonAdd}
                    onClick={() => this.handleClose()}
                  >
                    Cancelar
                  </Button>
                </Grid>
                <Grid item xs={6} lg={6} sm={6} md={6}>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#69bd4b",
                      color: "white",
                      width: "100%",
                    }}
                    className={classes.buttonAdd}
                    onClick={() => this.guardarDirectorio()}
                  >
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </Modal>

        {/* MODAL PARA NUEVO PROBLEMA */}
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.openProblema}
          onClose={this.handleClose}
        >
          {/* Modal para nuevo problema */}
          <div style={getModalStyle()} className={classes.paper}>
            <form className={classes.container} noValidate autoComplete="off">
              <Typography variant="title" gutterBottom>
                {this.state.actionType == "Agregar" ? (
                  <div>Crear</div>
                ) : (
                  <div>Editar</div>
                )}
              </Typography>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    required
                    id="txtNombre"
                    label="Nombre"
                    className={classes.textField}
                    value={this.state.problema.nombre}
                    onChange={this.handleChangeProblema("nombre")}
                    margin="normal"
                    inputProps={{ maxLength: 512 }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    id="txtDescripcion"
                    label="Descripción"
                    className={classes.textField}
                    value={this.state.problema.descripcion}
                    onChange={this.handleChangeProblema("descripcion")}
                    margin="normal"
                    multiline
                    inputProps={{ maxLength: 512 }}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel htmlFor="formatted-text-mask-input">
                      Tiempo de espera (HH:mm)
                    </InputLabel>
                    <Input
                      value={this.state.problema.tiempoEsperaEstimado}
                      onChange={this.handleChangeProblema(
                        "tiempoEsperaEstimado"
                      )}
                      id="formatted-text-mask-input"
                      inputComponent={TextMaskCustom}
                    />
                  </FormControl>
                </Grid>

                {this.state.actionType === "Actualizar" ? (
                  <div></div>
                ) : (
                  <div></div>
                )}

                <Grid container spacing={3}>
                  <Grid item xs={6} lg={6} sm={6} md={6}>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#f50057",
                        color: "white",
                        width: "100%",
                      }}
                      className={classes.buttonAdd}
                      onClick={() => this.handleClose()}
                    >
                      Cancelar
                    </Button>
                  </Grid>
                  <Grid item xs={6} lg={6} sm={6} md={6}>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#69bd4b",
                        color: "white",
                        width: "100%",
                      }}
                      className={classes.buttonAdd}
                      onClick={() => this.guardarProblema()}
                    >
                      Guardar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </div>
        </Modal>

        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.openProcessModal}
          onClose={this.handleClose}
        >
          <div style={getModalStyle()} className={classes.paper}>
            <form className={classes.container} noValidate autoComplete="off">
              <Typography variant="title" gutterBottom>
                <div>{this.state.actionType}</div>
              </Typography>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  required
                  id="txtNombre"
                  label="Nombre"
                  className={classes.textField}
                  value={this.state.nombre}
                  onChange={this.handleChange("nombre")}
                  margin="normal"
                  inputProps={{ maxLength: 512 }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  id="FechaInicio"
                  label="Fecha inicio"
                  type="date"
                  defaultValue=""
                  value={this.state.fechaInicio}
                  className={classes.textField}
                  onChange={this.handleChange("fechaInicio")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  id="FechaFin"
                  label="Fecha fin"
                  type="date"
                  defaultValue=""
                  value={this.state.fechaFin}
                  className={classes.textField}
                  onChange={this.handleChange("fechaFin")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  id="txtRangoRojo"
                  label="Rango rojo"
                  className={classes.textField}
                  value={this.state.rangoRojo}
                  onChange={this.handleChange("rangoRojo")}
                  margin="normal"
                  inputProps={{ maxLength: 4 }}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  id="txtRangoAmarillo"
                  label="Rango amarillo"
                  className={classes.textField}
                  value={this.state.rangoAmarillo}
                  onChange={this.handleChange("rangoAmarillo")}
                  margin="normal"
                  inputProps={{ maxLength: 4 }}
                />
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={10} sm={10} md={10} lg={10}>
                  <TextField
                    required
                    id="txtNombreUsuario"
                    label="Responsable"
                    className={classes.textField}
                    value={this.state.nombreUsuarioResponsable}
                    onChange={this.handleChange("nombreUsuarioResponsable")}
                    margin="normal"
                    inputProps={{ maxLength: 255 }}
                    disabled
                  />
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2}>
                  <IconButton
                    color="primary"
                    aria-label="Asignar responsable"
                    style={{ marginTop: 30 }}
                    onClick={this.handleOpenAsignarUsuarios}
                  >
                    <PersonIcon />
                  </IconButton>
                </Grid>
              </Grid>

              {this.state.actionType === "Actualizar" ? (
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.activo}
                        onChange={this.handleChange("activo")}
                        value="activo"
                        color="primary"
                      />
                    }
                    label="Activo"
                  />
                  <br />
                </div>
              ) : (
                <div></div>
              )}
              <Grid container spacing={3}>
                <Grid container spacing={3}>
                  <Grid item xs={6} lg={6} sm={6} md={6}>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#f50057",
                        color: "white",
                        width: "100%",
                      }}
                      className={classes.buttonAdd}
                      onClick={() => this.handleClose()}
                    >
                      Cancelar
                    </Button>
                  </Grid>
                  <Grid item xs={6} lg={6} sm={6} md={6}>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#69bd4b",
                        color: "white",
                        width: "100%",
                      }}
                      className={classes.buttonAdd}
                      onClick={() => this.guardarProceso()}
                    >
                      Guardar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </div>
        </Modal>

        <Modal
          // style={{ zIndex: 2990 }}
          aria-labelledby="Asignar usuarios"
          aria-describedby="Asignar usuarios"
          open={this.state.openAsignarUsuarios}
          onClose={this.handleCloseAsignarUsuarios}
        >
          <div style={getModalStyle()} className={classes.paperUserModal}>
            <PerfectScrollbar>
              <form className={classes.container} noValidate autoComplete="off">
                <Typography variant="h6" gutterBottom>
                  Asignar usuario(s)
                </Typography>

                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextField
                      id="txtTextoBusquedaUsuario"
                      label="Usuario"
                      className={classes.textField}
                      value={this.state.txtTextoBusquedaUsuario}
                      onChange={this.handleChange("txtTextoBusquedaUsuario")}
                      onKeyPress={(ev) => {
                        console.log("keyP: " + ev.key);
                        if (ev.key === "Enter") {
                          this.cargarListaUsuarios();
                          ev.preventDefault();
                        }
                      }}
                      margin="normal"
                      inputProps={{ maxLength: 512 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#217ecf",
                        color: "white",
                        width: "100%",
                      }}
                      className={classes.buttonAdd}
                      onClick={() => this.cargarListaUsuarios()}
                    >
                      Buscar
                    </Button>
                  </Grid>
                </Grid>

                <Grid item xs={12} lg={12} sm={12}>
                  <Grid container spacing={1}>
                    <UsuariosList
                      registros={this.state.listaUsuarios}
                      seleccionarUsuario={this.seleccionarUsuario}
                    />
                  </Grid>
                </Grid>
              </form>
            </PerfectScrollbar>
          </div>
        </Modal>

        {/* MODAL PARA NUEVO PROBLEMA */}
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.openCausa}
          onClose={this.handleClose}
        >
          {/* Modal para causas */}
          <div style={getModalStyle()} className={classes.paper}>
            <form className={classes.container} noValidate autoComplete="off">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Causas
                    codigoSubEntidad={this.state.codigoEntidadCausa}
                    cerrar={this.handleClose}
                  />
                </Grid>
              </Grid>
            </form>
          </div>
        </Modal>

        {/* MODAL PARA GESTIÖN DE PERMISOS */}
        <Modal
          aria-labelledby="Administración de permisos"
          aria-describedby="Administración de permisos"
          open={this.state.openPermisosModal}
          onClose={this.handleClose}
        >
          <div style={getBigModalStyle()} className={classes.modalLargote}>
            <form className={classes.container} noValidate autoComplete="off">
              <Grid container spacing={3}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  style={{ height: 500 }}
                >
                  <h4>Roles con acceso</h4>
                  <PerfectScrollbar style={{ width: "100%", maxHeight: 700 }}>
                    {this.state.listaRolesObjeto.map((permiso, i) => (
                      <Chip
                        key={permiso.Id}
                        label={permiso.Descripcion}
                        onClick={() => {
                          this.cargarPermisosPorId(permiso.Id);
                        }}
                        onDelete={() => {
                          this.revocarPermisos(permiso.CodigoRol);
                        }}
                        color="primary"
                        style={{
                          margin: 5,
                        }}
                      />
                    ))}
                  </PerfectScrollbar>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  style={{ height: 700 }}
                >
                  {this.state.editandoRol ? (
                    <h4>{`Edita los permisos del rol ${this.state.nombreRolEditando}`}</h4>
                  ) : (
                    <h4>Selecciona uno o más roles para otorgarles acceso</h4>
                  )}

                  <PerfectScrollbar style={{ width: "100%", maxHeight: 700 }}>
                    {/* <RolList registros={this.state.listaRoles} /> */}
                    {!this.state.editandoRol && (
                      <Autocomplete
                        value={this.state.rolSeleccionado}
                        required
                        multiple
                        className={classes.textField}
                        style={{ width: "100%", margin: 0, marginTop: 25 }}
                        options={this.state.listaRoles}
                        autoHighlight
                        getOptionLabel={(option) => option.Descripcion}
                        // inputValue={
                        //   Array.isArray(this.state.listaRoles)
                        //     ? this.state.listaRoles[0].Descripcion
                        //     : ""
                        // }
                        disabled={false}
                        renderOption={(option) => (
                          <React.Fragment>
                            <span></span>
                            {option.Descripcion}
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Rol"
                            variant="outlined"
                            required
                          />
                        )}
                        // onChange={(event, newInputValue) => {
                        //   this.setState({
                        //     rolSeleccionado: parseInt(
                        //       newInputValue ? newInputValue.CodigoRol : -1
                        //     ),
                        //   });
                        // }}

                        //Cuando es de selección múltiple:
                        onChange={(event, newValue) => {
                          this.setState({ rolSeleccionado: [...newValue] });
                        }}
                      />
                    )}

                    <Grid
                      item
                      xs={12}
                      lg={12}
                      md={12}
                      sm={12}
                      style={{ padding: 8 }}
                    >
                      <Paper>
                        <Grid
                          item
                          xs={12}
                          lg={12}
                          md={12}
                          sm={12}
                          style={{ padding: 8 }}
                        >
                          <Grid item xs={12} sm={12} lg={12}>
                            <Typography
                              variant="h6"
                              style={{ color: "#16a085" }}
                            >
                              Carpetas y tipos de gestión
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <FormControlLabel
                              control={
                                <IOSSwitch
                                  checked={this.state.N}
                                  onChange={this.handleChange("N")}
                                  name="Navigate"
                                />
                              }
                              label="Navegación (Mostrar en árbol de navegación)"
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <FormControlLabel
                              control={
                                <IOSSwitch
                                  checked={this.state.C}
                                  onChange={this.handleChange("C")}
                                  name="Create"
                                />
                              }
                              label="Crear nuevas carpetas o tipos de gestión"
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <FormControlLabel
                              control={
                                <IOSSwitch
                                  checked={this.state.U}
                                  onChange={this.handleChange("U")}
                                  name="Update"
                                />
                              }
                              label="Modificar carpetas o tipos de gestión"
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <FormControlLabel
                              control={
                                <IOSSwitch
                                  checked={this.state.D}
                                  onChange={this.handleChange("D")}
                                  name="Delete"
                                />
                              }
                              label="Eliminar carpetas o tipos de gestión"
                            />
                          </Grid>
                        </Grid>
                      </Paper>
                      <Paper style={{ marginTop: 8 }}>
                        <Grid
                          item
                          xs={12}
                          lg={12}
                          md={12}
                          sm={12}
                          style={{ padding: 8 }}
                        >
                          <Grid item xs={12} sm={12} lg={12}>
                            <Typography
                              variant="h6"
                              style={{ color: "#2980b9" }}
                            >
                              Tipos de gestión
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <FormControlLabel
                              control={
                                <IOSSwitch
                                  checked={this.state.E}
                                  onChange={this.handleChange("E")}
                                  name="Execute"
                                />
                              }
                              label="Ingresar una nueva gestión"
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <FormControlLabel
                              control={
                                <IOSSwitch
                                  checked={this.state.R}
                                  onChange={this.handleChange("R")}
                                  name="Read"
                                />
                              }
                              label="Consulta (Ver en reportes, ver detalle de instancias)"
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <FormControlLabel
                              control={
                                <IOSSwitch
                                  checked={this.state.G}
                                  onChange={this.handleChange("G")}
                                  name="God"
                                />
                              }
                              label="Modificar detalle de cualquier gestión"
                            />
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} lg={12} sm={12}>
                      <Button
                        disabled={this.state.cargandoGrabacionPermisos}
                        variant="contained"
                        style={{ backgroundColor: "#69bd4b", color: "white" }}
                        className={classes.buttonAdd}
                        onClick={() => {
                          this.otorgarPermisos();
                        }}
                      >
                        {this.state.cargandoGrabacionPermisos ? (
                          <div
                            style={{
                              width: "100%",
                              marginTop: 0,
                              textAlign: "center",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Loader
                              type="Oval"
                              color="#FFF"
                              height="20"
                              width="20"
                              style={{ textAlign: "left", marginRight: 5 }}
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                        Guardar
                      </Button>
                      <Button
                        variant="contained"
                        style={{ backgroundColor: "#c0392b", color: "white" }}
                        className={classes.buttonAdd}
                        onClick={() => {
                          this.limpiarState();
                        }}
                      >
                        Cancelar
                      </Button>
                    </Grid>
                  </PerfectScrollbar>
                </Grid>
                <Grid item xs={12} lg={12} sm={12} md={12}>
                  <Button
                    style={{
                      width: "100%",
                    }}
                    className={classes.buttonAdd}
                    onClick={() => this.handleClose()}
                    color="primary"
                  >
                    Cerrar
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </Modal>

        <Modal
          // style={{ zIndex: 2990 }}
          aria-labelledby="Mover a"
          aria-describedby="Mover a"
          open={this.state.openMoverA}
          onClose={() => {
            this.setState({ openMoverA: false });
          }}
        >
          <div
            style={getModalStyle()}
            className={classes.secondNavigationModal}
          >
            {/* <PerfectScrollbar> */}
            <form className={classes.container} noValidate autoComplete="off">
              <Typography variant="h6" gutterBottom>
                Mover a
              </Typography>

              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <SecondNavigation moverA={this.moverGestion} />
                </Grid>
              </Grid>
            </form>
            {/* </PerfectScrollbar> */}
          </div>
        </Modal>

        <ToastContainer />
        <Popover
          id="mouse-over-popover"
          className={classes.popover}
          classes={{
            paper: classes.paperPopover,
          }}
          open={this.state.popOverOpen}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          onClose={this.handlePopoverClose}
          disableRestoreFocus
        >
          <Typography>{this.state.textoPopover}</Typography>
        </Popover>
      </Layout>
    );
  }
}
const AdministradorAvisosContainer = withStyles(styles)(AdministradorAvisos);
export default withRouter(AdministradorAvisosContainer);
