import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Checkbox,
  FormControlLabel,
  Divider,
  Paper,
  Tooltip,
  Modal,
  Fade,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
} from "@material-ui/core";

import BackdropModal from "@material-ui/core/Backdrop";

import clsx from "clsx";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import {
  ChromeReaderMode,
  Edit,
  People,
  AttachFile,
  Cached,
  Delete,
  Label,
  ViewList,
  Add,
} from "@material-ui/icons";

import { green } from "@material-ui/core/colors";
import { NavLink } from "react-router-dom";

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import DataGrid, {
  Column,
  Pager,
  Paging,
  Sorting,
  ColumnFixing,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Grouping,
  GroupPanel,
  Export,
  Selection,
  Editing,
  Scrolling,
} from "devextreme-react/data-grid";

import PerfectScrollbar from "react-perfect-scrollbar";

import API from "../../utils/api";
import { isSignedIn, signOut } from "../../utils/auth";
import { validaPermisoPaginaActual } from "../../utils/helpers";
import UsuariosList from "../../plantillaProblema/components/usuarios-list";
import { ToastContainer, toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import "devextreme/dist/css/dx.material.teal.light.compact.css";

const width_proportion = "100%";
const titleColor = "#2B3C4D";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paperUserModal: {
    position: "absolute",
    width: "75%",
    height: "600px",
    maxHeight: "600px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
  table: {
    width: "100%",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 210,
  },
  textFieldDireccion: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 230,
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  greenAvatar: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  paperCard: {
    padding: 20,
    width: "100%",
  },
  formControlSelects: {
    // margin: theme.spacing(1),
    // minWidth: 230,

    marginBottom: 10,
    marginTop: 10,
    width: "100%",
    marginLeft: 30,
  },
  formControlSelect: {
    // margin: theme.spacing(1),
    width: width_proportion,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  rootTabla: {
    width: "100%",
    marginTop: theme.spacing.unit * 1,
    overflowX: "auto",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperModal: {
    position: "absolute",
    width: "70%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const PuestoLaboral = (props) => {
  const classes = useStyles();
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors: fieldsErrors },
    register,
  } = useForm();

  let applyFilterTypes = [
    {
      key: "auto",
      name: "Immediately",
    },
    {
      key: "onClick",
      name: "On Button Click",
    },
  ];

  const [userName, setUserName] = useState("");
  const [open, setOpen] = useState(false);
  const [cargandoMaestros, setCargandoMaestros] = useState(false);
  const [textoBusquedaUsuario, setTextoBusquedaUsuario] = useState("");
  const [usuariosDisponiblesCopia, setUsuariosDisponiblesCopia] = useState([]);
  const [puestoLaboral, setPuestoLaboral] = useState([]);
  const [maestroNivelPuestoLaboral, setMaestroNivelPuestoLaboral] = useState(
    []
  );
  const [
    codigoResponsableUnidadOrganizacion,
    setCodigoResponsableUnidadOrganizacion,
  ] = useState(0);
  const [codigoNivelPuestoLaboral, setCodigoNivelPuestoLaboral] = useState(0);
  const [puestoLaboralNombre, setPuestoLaboralNombre] = useState("");
  const [numeroVersion, setNumeroVersion] = useState("");
  const [fechaCreacion, setFechaCreacion] = useState(new Date());
  const [dpiResponsable, setDpiResponsable] = useState("");
  const [
    valueAutoCompleteNivelPuestoLaboral,
    setValueAutoCompleteNivelPuestoLaboral,
  ] = useState(null);

  const [maestroTipoResponsable, setMaestroTipoResponsable] = useState([]);
  const [
    codigoTipoResponsableUnidadOrganizacion,
    setCodigoTipoResponsableUnidadOrganizacion,
  ] = useState(0);
  const [
    valueAutoCompleteTipoResponsableUnidadOrganizacion,
    setValueAutoCompleteTipoResponsableUnidadOrganizacion,
  ] = useState(null);

  const [positionDisableSorting, setPositionDisableSorting] = useState(false);
  const [showFilterRow, setShowFilterRow] = useState(true);
  const [showHeaderFilter, setShowHeaderFilter] = useState(true);
  const [currentFilter, setCurrentFilter] = useState(applyFilterTypes[0].key);
  const [autoExpandAll, setAutoExpandAll] = useState(true);

  const [buscando, setBackDrop] = useState(false);

  const handleCerrarBackDrop = () => {
    setBackDrop(false);
  };
  const handleAbrirBackDrop = () => {
    setBackDrop(true);
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const setSearchUsuario = (name) => (event) => {
    setTextoBusquedaUsuario(event.target.value);
  };

  useEffect(() => {
    const cargar = async () => {
      // CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
      const llaveAgrupacion = "Menu.Principal.Web";
      const llavePagina = "Menu.Principal.Web.PuestoLaboral";
      await validaPermisoPaginaActual(llaveAgrupacion, llavePagina);
      // FIN DE CONSULTA DE PERMISOS

      handleAbrirBackDrop();

      await cargarMaestroTipoResponsable();

      handleCerrarBackDrop();
    };

    cargar();
  }, []);

  useEffect(() => {
    const cargar = async () => {
      handleAbrirBackDrop();

      await cargarMaestroResponsableUnidadOrganizacion();

      handleCerrarBackDrop();
    };

    cargar();
  }, [props.codigoUnidadOrganizacion]);

  useEffect(() => {
    const cargar = async () => {
      handleAbrirBackDrop();

      await cargarMaestroResponsableUnidadOrganizacion();

      handleCerrarBackDrop();
    };

    cargar();
  }, [props.codigoUnidadOrganizacion]);

  const cargarMaestroResponsableUnidadOrganizacion = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri =
        "api/ResponsableUnidadOrganizacion/" + props.codigoUnidadOrganizacion;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setPuestoLaboral(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cargarMaestroTipoResponsable = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/TipoResponsableUnidadOrganizacion";

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setMaestroTipoResponsable(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cargarListaUsuarios = async () => {
    let crit = textoBusquedaUsuario;
    let criterio =
      crit === "" || crit === null || crit === undefined ? "x-x" : crit;

    const request = await API.fetchGetRequest(`api/Usuario/buscar/${criterio}`);
    try {
      if (Array.isArray(request.data.usuarios)) {
        setUsuariosDisponiblesCopia(request.data.usuarios);
      } else {
        setUsuariosDisponiblesCopia([]);
      }
    } catch (error) {
      console.log("cargarListaUsuarios error:" + error);
    }
  };

  const seleccionarUsuario = async (codigoUsuario) => {
    let registroUsuario = usuariosDisponiblesCopia.find(
      (w) => w.CodigoUsuario === codigoUsuario
    );
    setDpiResponsable(registroUsuario.Usuario);

    const data = {
      CodigoResponsableUnidadOrganizacion: codigoResponsableUnidadOrganizacion,
      CodigoUnidadOrganizacion: props.codigoUnidadOrganizacion,
      CodigoTipoResponsableUnidadOrganizacion:
        codigoTipoResponsableUnidadOrganizacion,
      DPI: registroUsuario.Usuario,
      FechaInicio: "",
      FechaFin: "",
      Activo: true,
      Eliminado: false,
      UsuarioInserto: userName,
      FechaInserto: "",
      UsuarioModifico: "",
      FechaModifico: "",
    };

    console.log(data);
    // dataFicha.usuarioInserto = "prueba";
    try {
      handleAbrirBackDrop();
      let vIsSignedIn = await isSignedIn();
      if (vIsSignedIn.response) {
        let request;

        request = await API.post("api/ResponsableUnidadOrganizacion/", data);
        if (request.status !== 200) {
          toast.error(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        } else {
          toast.success("Se guardó exitosamente");

          await cargarMaestroResponsableUnidadOrganizacion();
          setDpiResponsable("");

          setCodigoResponsableUnidadOrganizacion(0);
        }
      }
    } catch (e) {
      console.log("EXCEPTION: " + e);
      toast.error(
        "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
      );
    } finally {
      handleCerrarBackDrop();
      handleCloseModal();
    }
    setOpen(false);
  };

  const cuandoHayError = async (data) => {
    toast.info(
      "Existen algunos campos con error, por favor valida el formulario",
      {
        position: "top-right",
        autoClose: 3000, // Esta en milisegundos 3 segundos
      }
    );
  };

  const handleDateChangeFechaCreacion = (date) => {
    setFechaCreacion(date);
  };

  const onExporting = async (e) => {};

  const [dialogoAbierto, abrirDialogo] = useState(false);
  const [dialogoAbiertoEditar, abrirDialogoEditar] = useState(false);
  const handleAbrirDialogoEliminar = (id) => {
    abrirDialogo(true);

    setCodigoResponsableUnidadOrganizacion(id);
  };

  const handleCerrarDialogo = () => {
    abrirDialogo(false);
  };

  const handleAbrirDialogoEditar = (id) => {
    abrirDialogoEditar(true);

    setCodigoResponsableUnidadOrganizacion(id);
  };

  const handleCerrarDialogoEditar = () => {
    abrirDialogoEditar(false);
  };

  const quitarResponsable = async (pId) => {
    handleCerrarDialogo();
    handleAbrirBackDrop();

    try {
      let uri = "api/ResponsableUnidadOrganizacion/Quitar/" + pId;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        toast.error(
          "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
        );
      } else {
        await cargarMaestroResponsableUnidadOrganizacion();

        toast.success("Se quitó el registro con éxito", {
          position: "bottom-right",
          autoClose: 3000, // Esta en milisegundos 3 segundos
        });
      }
    } catch (error) {
      console.log("Error en  eliminación de proyectos" + error);
    }

    handleCerrarBackDrop();

    //alert("Quitar recurso del proyecto" + codigoRecurso);
  };

  const editarProyecto = async (pId) => {
    handleCerrarDialogoEditar();
    handleAbrirBackDrop();

    const proyectosExcluidos = puestoLaboral.filter(
      (elemento) => elemento.CodigoPuestoLaboral === pId
    );

    console.log("Editar el proyecto" + proyectosExcluidos);

    try {
      handleOpenModal();
      setCodigoResponsableUnidadOrganizacion(pId);
      setPuestoLaboralNombre(proyectosExcluidos[0].PuestoLaboral);
      setNumeroVersion(proyectosExcluidos[0].NumeroVersion);
      setFechaCreacion(proyectosExcluidos[0].FechaCreacion);
      setCodigoNivelPuestoLaboral(
        proyectosExcluidos[0].CodigoNivelPuestoLaboral
      );

      const itemNivelPuestoLaboral = maestroNivelPuestoLaboral.filter(
        (elemento) =>
          elemento.CodigoNivelPuestoLaboral ===
          proyectosExcluidos[0].CodigoNivelPuestoLaboral
      );
      setValueAutoCompleteNivelPuestoLaboral(itemNivelPuestoLaboral[0]);
    } catch (error) {
      console.log("Error en  edición de proyectos" + error);
    }

    handleCerrarBackDrop();

    //alert("Quitar recurso del proyecto" + codigoRecurso);
  };

  const cellOpciones = (rowInfo) => {
    return (
      <>
        <Tooltip
          title="Eliminar"
          style={{ marginRight: 10, cursor: "pointer" }}
        >
          <Delete
            style={{ color: "#337ab7", cursor: "pointer" }}
            onClick={() =>
              handleAbrirDialogoEliminar(
                rowInfo.data.CodigoResponsableUnidadOrganizacion
              )
            }
          />
        </Tooltip>
      </>
    );
  };

  const handleOnChangeTipoResponsable = async (event, newValue) => {
    handleAbrirBackDrop();
    if (newValue !== null) {
      setCodigoTipoResponsableUnidadOrganizacion(
        newValue.CodigoTipoResponsableUnidadOrganizacion
      );
      setValueAutoCompleteTipoResponsableUnidadOrganizacion(newValue);
    }
    handleCerrarBackDrop();
  };

  return (
    <React.Fragment>
      <Backdrop className={classes.backdrop} open={buscando}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer />

      <Dialog
        open={dialogoAbierto}
        onClose={handleCerrarDialogo}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Eliminación</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro que deseas eliminar este registro?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCerrarDialogo} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={() =>
              quitarResponsable(codigoResponsableUnidadOrganizacion)
            }
            color="primary"
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={dialogoAbiertoEditar}
        onClose={handleCerrarDialogoEditar}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Edición</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro que deseas editar este registro?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCerrarDialogoEditar} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={() => editarProyecto(codigoResponsableUnidadOrganizacion)}
            color="primary"
          >
            Editar
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={BackdropModal}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div style={getModalStyle()} className={classes.paperUserModal}>
          <PerfectScrollbar>
            {/* <Fade in={open}> */}
            {/* <div className={classes.paperModal}> */}
            <h2 id="simple-modal-title">Busque y seleccione a un gestor </h2>
            <Grid container spacing={3}>
              <form className={classes.container} noValidate autoComplete="off">
                <Grid item xs={6} sm={6} md={12} lg={12}>
                  <FormControl className={classes.formControlSelects}>
                    <Controller
                      name="tipoResponsable"
                      required
                      render={({ PuestoLaboral }) => (
                        <Autocomplete
                          id="tipoResponsable"
                          options={maestroTipoResponsable}
                          getOptionLabel={(option) =>
                            option.TipoResponsableUnidadOrganizacion
                          }
                          value={
                            valueAutoCompleteTipoResponsableUnidadOrganizacion
                          }
                          onChange={(event, newValue) =>
                            handleOnChangeTipoResponsable(event, newValue)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Tipo Responsable"
                              variant="outlined"
                            />
                          )}
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    id="txtTextoBusquedaUsuario"
                    label="Usuario"
                    className={classes.textField}
                    value={textoBusquedaUsuario}
                    onChange={setSearchUsuario("txtTextoBusquedaUsuario")}
                    onKeyPress={(ev) => {
                      console.log("keyP: " + ev.key);
                      if (ev.key === "Enter") {
                        cargarListaUsuarios();
                        ev.preventDefault();
                      }
                    }}
                    margin="normal"
                    inputProps={{ maxLength: 512 }}
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#217ecf",
                      color: "white",
                      width: "100%",
                    }}
                    className={classes.buttonAdd}
                    onClick={() => cargarListaUsuarios()}
                  >
                    Buscar
                  </Button>
                </Grid>
                <Grid item xs={12} lg={12} sm={12}>
                  <Grid container spacing={1}>
                    <UsuariosList
                      registros={usuariosDisponiblesCopia}
                      seleccionarUsuario={seleccionarUsuario}
                    />
                  </Grid>
                </Grid>
              </form>
            </Grid>
            {/* </div> */}
            {/* </Fade> */}
          </PerfectScrollbar>
        </div>
      </Modal>
      <AppBar
        position="sticky"
        className={classes.appBar}
        style={{ backgroundColor: titleColor }}
      >
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            style={{ display: "flex", flexGrow: 1 }}
          >
            {`Gestores`}
          </Typography>

          <Tooltip title={"Crear nuevo ítem"} aria-label="Crear nuevo ítem">
            <IconButton
              aria-label="Crear nuevo ítem"
              style={{ color: "white" }}
            >
              <Add onClick={handleOpenModal} />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <Paper className={classes.rootTabla}>
        <DataGrid
          dataSource={puestoLaboral}
          //   defaultColumns={this.props.columns}
          showBorders={true}
          rowAlternationEnabled={true}
          //ref={(ref) => (dataGrid = ref)}
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          onExporting={onExporting}
          className={classes.table}
        >
          <FilterRow visible={showFilterRow} applyFilter={currentFilter} />
          <HeaderFilter visible={showHeaderFilter} />
          <GroupPanel visible={false} />
          <Grouping autoExpandAll={autoExpandAll} />
          <SearchPanel visible={false} width={240} placeholder="Search..." />
          {/* <Selection mode="multiple" /> */}
          <Editing
            mode="row"
            useIcons={true}
            allowUpdating={false}
            allowDeleting={false}
          />
          <Column
            caption="Opciones"
            allowFiltering={false}
            allowSorting={false}
            cellRender={cellOpciones}
          />

          <Column dataField="DPI" caption="DPI" />
          <Column dataField="CodigoAnterior" caption="Código Empleado" />
          <Column dataField="NombreCompleto" caption="Nombre" />
          <Column dataField="TipoResponsableUnidadOrganizacion" caption="Rol" />

          <ColumnFixing enabled={true} />
          <Sorting mode="multiple" />
          <Paging defaultPageSize={20} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[10, 20, 50]}
            showInfo={true}
          />
          <Export enabled={true} allowExportSelectedData={true} />
          <Scrolling columnRenderingMode="virtual" />
        </DataGrid>
      </Paper>
    </React.Fragment>
  );
};

export default PuestoLaboral;
