import React from "react";
import { withRouter } from "react-router-dom";
import Container from "@material-ui/core/Container";

//Own components
// import Layout from "../../layout/containers/Layout";

import ComponenteResponsabilidadNegocio from "../components/funciones-puesto-componente";
const ContainerResponsabilidadNegocio = (props) => {

  return (
    <React.Fragment>
      <Container maxWidth="100%">
        <ComponenteResponsabilidadNegocio
          puestoLaboral={props.puestoLaboral}
        />
      </Container>
    </React.Fragment>
  );
};

export default withRouter(ContainerResponsabilidadNegocio);
