import React, { Component } from "react";
import DataGrid, {
  Column,
  Pager,
  Paging,
  Sorting,
  ColumnFixing,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Grouping,
  GroupPanel,
  Export,
  Selection,
  Editing,
  Button,
} from "devextreme-react/data-grid";
import CheckBox from "devextreme-react/check-box";
import SelectBox from "devextreme-react/select-box";
// import Button from "devextreme-react/button";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
// import { exportDataGrid as exportDataGridToPdf } from "devextreme/pdf_exporter";
// import ExcelJS from "exceljs";
// import { Workbook } from 'exceljs';
// import saveAs from "file-saver";
// import { exportDataGrid } from "devextreme/excel_exporter";


import "./devexpress-grid-style.css";
import { Grid } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";

class GridInconsistencias extends Component {
  constructor(props) {
    super(props);

    this.applyFilterTypes = [
      {
        key: "auto",
        name: "Immediately",
      },
      {
        key: "onClick",
        name: "On Button Click",
      },
    ];

    this.state = {
      positionDisableSorting: false,
      showFilterRow: true,
      showHeaderFilter: true,
      currentFilter: this.applyFilterTypes[0].key,
      autoExpandAll: true,
    };

    this.dataGrid = null;

    this.onPositionSortingChanged = this.onPositionSortingChanged.bind(this);

    this.orderHeaderFilter = this.orderHeaderFilter.bind(this);
    this.onShowFilterRowChanged = this.onShowFilterRowChanged.bind(this);
    this.onShowHeaderFilterChanged = this.onShowHeaderFilterChanged.bind(this);
    this.onCurrentFilterChanged = this.onCurrentFilterChanged.bind(this);

    this.onAutoExpandAllChanged = this.onAutoExpandAllChanged.bind(this);

    this.onExporting = this.onExporting.bind(this);
    this.navegarGestion = this.navegarGestion.bind(this);
  }

  exportGrid() {
    const doc = new jsPDF();
    const dataGrid = this.dataGridRef.current.instance;

    // exportDataGridToPdf({
    //   jsPDFDocument: doc,
    //   component: dataGrid,
    // }).then(() => {
    //   doc.save("AppILU.pdf");
    // });
  }

  render() {
    return (
      <React.Fragment>
        {/* <Button
          id="exportButton"
          icon="exportpdf"
          text="Export to PDF"
          onClick={this.exportGrid}
        /> */}
        <DataGrid
          dataSource={this.props.filas}
          //   defaultColumns={this.props.columns}
          showBorders={true}
          ref={(ref) => (this.dataGrid = ref)}
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          onExporting={this.onExporting}
        >
          <FilterRow
            visible={this.state.showFilterRow}
            applyFilter={this.state.currentFilter}
          />
          <HeaderFilter visible={this.state.showHeaderFilter} />
          <GroupPanel visible={true} />
          <Grouping autoExpandAll={this.state.autoExpandAll} />
          <SearchPanel visible={true} width={240} placeholder="Search..." />
          <Selection mode="multiple" />
          <Editing
            mode="row"
            useIcons={true}
            allowUpdating={false}
            allowDeleting={false}
          />

           <Column type="buttons" width={50}>
            <Button
              hint="Ir a la gestión"
              icon="link"
              visible={true}
              onClick={this.navegarGestion}
            />
          </Column> 
          <Column
            dataField="codigoAviso"
            caption={"Gestión #"}
            //dataType="number"
            //width={230}
            //fixed={true}
          />
            <Column dataField="DPI" caption="DPI">
            <HeaderFilter allowSearch={true} />
          </Column>
          <Column dataField="nombre" caption="Nombre">
            <HeaderFilter allowSearch={true} />
          </Column>

          <Column dataField="fecha" caption="Fecha">
            <HeaderFilter allowSearch={true} />
          </Column>

          

          <ColumnFixing enabled={true} />
          <Sorting mode="multiple" />
          <Paging defaultPageSize={10} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[5, 10, 20]}
            showInfo={true}
          />
          <Export enabled={true} allowExportSelectedData={true} />
        </DataGrid>
       
        <div className="options">
          <div className="caption">Options</div>
          <div className="option">
            <span>Apply Filter </span>
            <SelectBox
              items={this.applyFilterTypes}
              value={this.state.currentFilter}
              onValueChanged={this.onCurrentFilterChanged}
              valueExpr="key"
              displayExpr="name"
              disabled={!this.state.showFilterRow}
            />
          </div>
          <div className="option">
            <CheckBox
              text="Filter Row"
              value={this.state.showFilterRow}
              onValueChanged={this.onShowFilterRowChanged}
            />
          </div>
          <div className="option">
            <CheckBox
              text="Header Filter"
              value={this.state.showHeaderFilter}
              onValueChanged={this.onShowHeaderFilterChanged}
            />
          </div>
          <div className="option">
            <CheckBox
              text="Expand All Groups"
              value={this.state.autoExpandAll}
              onValueChanged={this.onAutoExpandAllChanged}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }

  onPositionSortingChanged() {
    this.setState({
      positionDisableSorting: !this.state.positionDisableSorting,
    });

    this.dataGrid.instance.columnOption(5, "sortOrder", void 0);
  }

  calculateFilterExpression(value, selectedFilterOperations, target) {
    let column = this;
    if (target === "headerFilter" && value === "weekends") {
      return [[getOrderDay, "=", 0], "or", [getOrderDay, "=", 6]];
    }
    return column.defaultCalculateFilterExpression.apply(this, arguments);
  }

 
  orderHeaderFilter(data) {
    data.dataSource.postProcess = (results) => {
      results.push({
        text: "Weekends",
        value: "weekends",
      });
      return results;
    };
  }

  onShowFilterRowChanged(e) {
    this.setState({
      showFilterRow: e.value,
    });
    this.clearFilter();
  }
  onShowHeaderFilterChanged(e) {
    this.setState({
      showHeaderFilter: e.value,
    });
    this.clearFilter();
  }
  onCurrentFilterChanged(e) {
    this.setState({
      currentFilter: e.value,
    });
  }
  clearFilter() {
    this.dataGrid.instance.clearFilter();
  }

  onAutoExpandAllChanged() {
    this.setState({
      autoExpandAll: !this.state.autoExpandAll,
    });
  }

  

  onExporting(e) {
    // const workbook = new Workbook();
    // const worksheet = workbook.addWorksheet("Main sheet");
    // exportDataGrid({
    //   component: e.component,
    //   worksheet: worksheet,
    // }).then(function () {
    //   workbook.xlsx.writeBuffer().then(function (buffer) {
    //     saveAs(
    //       new Blob([buffer], { type: "application/octet-stream" }),
    //       "DataGrid.xlsx"
    //     );
    //   });
    // });
    // e.cancel = true;
  }

  navegarGestion(e) {
    const codigoAviso = e.row.data.codigoAviso;
    console.log("codigoAviso", codigoAviso);
    window.location.href = `/gestion/${codigoAviso}`;
  }
}

function getOrderDay(rowData) {
  return new Date(rowData.OrderDate).getDay();
}

export default GridInconsistencias;
