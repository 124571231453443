import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

//Own Componentes

const styles = theme => ({
    fab: {
        margin: theme.spacing.unit,
    }
});

function ButtonAdd(props) {
    const { classes } = props;
    return (
        <div onClick={props.onClick}>
            <Fab style={{ backgroundColor: "#2196F3", color: 'white', ...props.style }} aria-label="Add" className={classes.fab}>
                <AddIcon />
            </Fab>
        </div>
    );
}

ButtonAdd.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ButtonAdd);