import { createStore } from 'easy-peasy'
import DirectorioStore from './DirectorioStore'
import SolicitudesStore from './SolicitudesStore'
import ObsoletosStore from './ObsoletosStore'
import UsuariosStore from './UsuariosStore'

const globalModel = {
  ...DirectorioStore,
  ...SolicitudesStore,
  ...ObsoletosStore,
  ...UsuariosStore
}
const GlobalStore = createStore(globalModel);
export default GlobalStore
