import React, { useState, useEffect, useRef } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import DeleteForeverRoundedIcon from "@material-ui/icons/DeleteForeverRounded";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Chip from "@material-ui/core/Chip";
import FaceIcon from "@material-ui/icons/Face";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CancelIcon from "@material-ui/icons/Cancel";
import PersonAddIcon from "@material-ui/icons/PersonAdd";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    marginTop: "10px",
  },
  details: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    textAlign: "center",
  },
  content: {
    flex: "1 0 auto",
    width: "100%",
  },
  cover: {
    width: 151,
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  icon: {
    height: 38,
    width: 38,
  },
  text: {},
}));

export default function GrupoCard(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [usuariosCopia, setUsuariosCopia] = useState([]);
  const [textoBusqueda, setTextoBusqueda] = useState("");

  //Equivalente a didmount
  useEffect(() => {
    setUsuariosCopia(props.usuarios);
  }, []);

  //equivalente a didupdate (solo cuando cambie el valor de variable textobusqueda)
  useEffect(() => {
    if (textoBusqueda.length > 0) {
      let filtro = props.usuarios.filter((fila) => {
        return fila.NombreCompletoUsuario.toLowerCase().includes(
          textoBusqueda.toLowerCase()
        );
      });
      setUsuariosCopia(filtro);
    } else {
      setUsuariosCopia(props.usuarios);
    }
  }, [textoBusqueda]);

  //Equivalente a didmount cuando cambia el valor de props.usuarios
  useEffect(() => {
    setUsuariosCopia(props.usuarios);
  }, [props.usuarios]);

  const setSearch = (name) => (event) => {
    setTextoBusqueda(event.target.value);
  };

  return (
    <div className={classes.root}>
      <Card className={classes.root}>
        <div className={classes.details}>
          <CardContent className={classes.content}>
            <Typography
              component="h5"
              variant="h5"
              className={classes.text}
              onClick={() => {
                window.location.href = "usuarioGrupo/" + props.codigo;
              }}
              style={{ cursor: "pointer" }}
            >
              {props.nombre}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              <strong>Descripción: </strong> {props.descripcion}
            </Typography>

            <Typography variant="subtitle1" color="textSecondary">
              <strong>Estado: </strong>
              <span
                style={{
                  color:
                    props.activo === true
                      ? "rgb(63, 81, 181)"
                      : "rgb(245, 0, 87)",
                }}
              >
                {props.activo === true ? "Activo" : "Inactivo"}
              </span>
            </Typography>
          </CardContent>
          <div className={classes.controls}></div>
        </div>
        <div
          style={{
            display: "flex",
            justifytems: "flex-end",
            alignItems: "flex-start",
            flexDirection: "row",
          }}
        >
          <IconButton
            aria-label="Editar"
            onClick={() => {
              props.editar(props.codigo);
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            aria-label="Remover"
            onClick={() => {
              props.eliminar(props.codigo);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      </Card>
    </div>
  );
}
