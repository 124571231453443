import React, { useState, useEffect } from 'react'
import {
  Box,
  Avatar,
  Grid,
  Typography,
  CircularProgress
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useParams } from 'react-router-dom'
import {ToastContainer, toast} from 'react-toastify'

import Layout from '../layout/containers/Layout'
import api from '../utils/api'

const PerfilFirma = () => {
  const { id } = useParams()
  const [perfil, setPerfil] = useState(null)
  const [loading, setLoading] = useState(false)
  const getPerfil = async () => {
    try {
      setLoading(true)
      const { data } = await api.fetchGetRequest(`api/Usuario/getProfileData/${id}`)
      if (!data.response) {
        throw 'Ha ocurrido un error'
      }
      const usuario = { ...data.usuarios[0] }
      const { data: ficha } = await api.fetchGetRequest(`api/fichaClinica/empleado/${usuario.Usuario}`)
      setPerfil({
        ...usuario,
        Puesto: ficha.data[0].PuestoLaboral,
        Gerencia: ficha.data[0].Gerencia
      })
    } catch (error) {
      toast.error('No se pudo obtener los datos del perfil')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getPerfil()
  }, [])
  return <Layout title="Perfil">
    <Grid container justify="center">
      <Grid item xs={12} md={6}>
        {
          (!loading && perfil) && <>
            <Box display="flex" justifyContent="center" marginY={1}>
              <Avatar
                alt={perfil.NombreCompletoUsuario}
                src={perfil.ImagenPerfil}
                style={{width: 200, height: 200}}
              >
                {perfil.NombreCompletoUsuario.split(' ').map(i => i.substr(0, 1)).join('').substr(0,2)}
              </Avatar>
            </Box>
            <Typography variant="h5" style={{textAlign: "center"}}>
              <strong>Nombre: </strong>{perfil.NombreCompletoUsuario}
            </Typography>
            <Typography variant="h5" style={{textAlign: "center"}}>
              <strong>Correo electrónico: </strong>{perfil.CorreoElectronico}
            </Typography>
            <Typography variant="subtitle1" style={{textAlign: "center"}}>
              <strong>Gerencia: </strong>{perfil.Gerencia}
            </Typography>
            <Typography variant="subtitle1" style={{textAlign: "center"}}>
              <strong>Puesto: </strong>{perfil.Puesto}
            </Typography>
            <Box display="flex" justifyContent="center" marginY={1}>
              {
                (perfil && perfil.ImagenFirma.split('/').slice(-1)[0] === '')
                ? <Alert
                    fullWidth
                    severity="info"
                    variant="filled"
                  >No hay una firma registrada</Alert>
                : <img
                    alt="Firma"
                    src={perfil.ImagenFirma}
                    style={{width: 200, height: 'auto'}}
                  />
              }
            </Box>
          </>
        }
        {
          loading && <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        }
      </Grid>
    </Grid>
    <ToastContainer />
  </Layout>
}

export default PerfilFirma
