import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//Own Components
import Layout from "../../layout/containers/Layout";
import API from "../../utils/api";
import UsuariosTabla from "../components/usuarios-tabla";
import AddButton from "../components/button-add";
import { isSignedIn } from "../../utils/auth";
import { validaPermisoPaginaActual } from "../../utils/helpers";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
});

class DispositivoList extends React.Component {
  state = {
    open: false,
    data: [],
    nombre: "",
    usuario: "",
    email: "",
    autenticacionWindows: "1",
    actionType: "", //Crear, Actualizar
  };

  async componentWillMount() {
    //CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
    const llaveAgrupacion = "Menu.Principal.Web";
    const llavePagina = "Menu.Principal.Web.Usuarios";
    await validaPermisoPaginaActual(llaveAgrupacion, llavePagina);
    //FIN DE CONSULTA DE PERMISOS

    await this.makeRequest();
  }

  makeRequest = async () => {
    // const data = {
    //   data: [
    //     {
    //       CodigoUsuario: 2,
    //       NombreCompletoUsuario: "Jomab",
    //       Usuario: "jabzum",
    //       CorreoElectronico: "ja",
    //       AutenticacionWindows: 1
    //     }
    //   ]
    // };
    const request = await API.getUsuarios();

    this.setState({
      data: request.data.usuarios,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
      nombre: "",
      usuario: "",
      email: "",
      autenticacionWindows: "1",
      actionType: "",
    });
  };

  handleOpenAddModal = () => {
    this.setState({
      actionType: "Agregar",
      open: true,
    });
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  guardarUsuario = async () => {
    const { nombre, usuario, email, autenticacionWindows } = this.state;

    if (
      nombre.trim() == "" ||
      usuario.trim() == "" ||
      email.trim() == "" ||
      autenticacionWindows.trim() == ""
    ) {
      toast.warn("Llene todos los campos");
      return;
    }

    try {
      let vIsSignedIn = await isSignedIn();

      if (vIsSignedIn.response) {
        let request;
        if (this.state.actionType == "Agregar") {
          const data = {
            NombreCompletoUsuario: this.state.nombre,
            Usuario: this.state.usuario,
            CorreoElectronico: this.state.email,
            AutenticacionWindows:
              this.state.autenticacionWindows == "1" ? true : false,
            UsuarioInserto: vIsSignedIn.userName,
          };

          request = await API.post(`api/desktop/usuario`, data);
          if (request.status != 200) {
            alert(
              "Hubo un error al guardar el registro, por favor revisa los datos de tu formulario o consulta con un administrador"
            );
          }
        } else {
          let data = this.state.editData;
          data.Device = this.state.dispositivo;
          data.Descripcion = this.state.dispositivoDescripcion;
          data.DeviceId = this.state.dispositivoDeviceId;
          data.Activo = this.state.dispositivoActivo == "1" ? true : false;
          data.UsuarioModifico = vIsSignedIn.userName;

          request = await API.updateDispositivo(data);

          if (request.statusCode != 200) {
            alert("Hubo un error al actualizar tu registro");
          }
        }

        if (request.statusCode === 401) {
          //alert("cerrar sesion");
        } else {
          this.props.history.push("/usuario/" + request.data.usuario.id);
        }
      }
    } catch (e) {
      alert("hubo un error");
    }
  };

  editItem = async (item) => {
    await this.setState({
      open: true,
      dispositivo: item.Device,
      dispositivoDescripcion: item.Descripcion,
      dispositivoDeviceId: item.DeviceId,
      dispositivoActivo: item.Activo ? "1" : "0",
      actionType: "Actualizar",
      editData: item,
    });
  };

  redirectToUsuarioDetail = (id) => {
    this.props.history.push("/usuario/" + id);
  };

  render() {
    const { classes } = this.props;
    let { data } = this.state;

    return (
      <div>
        <Layout titulo="Usuarios">
          <UsuariosTabla
            data={data}
            editItem={this.editItem}
            redirectToUsuarioDetail={this.redirectToUsuarioDetail}
          />
          <AddButton onClick={() => this.handleOpenAddModal()} />

          <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={this.state.open}
            onClose={this.handleClose}
          >
            <div style={getModalStyle()} className={classes.paper}>
              <form className={classes.container} noValidate autoComplete="off">
                <Typography variant="title" gutterBottom>
                  {this.state.actionType == "Agregar" ? (
                    <div>Crear</div>
                  ) : (
                    <div>Editar</div>
                  )}
                </Typography>
                <TextField
                  id="txtNombre"
                  label="Nombre"
                  className={classes.textField}
                  value={this.state.nombre}
                  onChange={this.handleChange("nombre")}
                  margin="normal"
                  variant="outlined"
                />
                <TextField
                  id="txtUsuario"
                  label="Usuario"
                  className={classes.textField}
                  value={this.state.usuario}
                  onChange={this.handleChange("usuario")}
                  margin="normal"
                  variant="outlined"
                />
                <TextField
                  id="txtEmail"
                  label="Correo electrónico"
                  className={classes.textField}
                  value={this.state.email}
                  onChange={this.handleChange("email")}
                  margin="normal"
                  variant="outlined"
                />
                <h5>Autenticación Windows</h5>
                <RadioGroup
                  aria-label="AutenticacionWindows"
                  name="AutenticacionWindows"
                  className={classes.textField}
                  value={this.state.autenticacionWindows}
                  color="primary"
                  onChange={this.handleChange("autenticacionWindows")}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio style={{ color: "#2B3C4D" }} />}
                    label="Si"
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio style={{ color: "#2B3C4D" }} />}
                    label="No"
                  />
                </RadioGroup>
                {this.state.actionType === "Actualizar" ? (
                  <>
                    <h5>Estado</h5>
                    <RadioGroup
                      aria-label="Estado"
                      name="Activo"
                      className={classes.textField}
                      value={this.state.estado}
                      color="primary"
                      onChange={this.handleChange("estado")}
                    >
                      <FormControlLabel
                        value="1"
                        control={<Radio style={{ color: "#2B3C4D" }} />}
                        label="Activo"
                      />
                      <FormControlLabel
                        value="0"
                        control={<Radio style={{ color: "#2B3C4D" }} />}
                        label="Inactivo"
                      />
                    </RadioGroup>
                  </>
                ) : (
                  <></>
                )}

                <Button
                  variant="contained"
                  style={{ backgroundColor: "#69bd4b", color: "white" }}
                  className={classes.buttonAdd}
                  onClick={() => this.guardarUsuario()}
                >
                  Guardar
                </Button>
              </form>
            </div>
          </Modal>
          <ToastContainer />
        </Layout>
      </div>
    );
  }
}

// We need an intermediary variable for handling the recursive nesting.
const DispositivoListContainer = withStyles(styles)(DispositivoList);

export default withRouter(DispositivoListContainer);
