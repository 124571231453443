import React, { useEffect, useState } from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  IconButton,
  Dialog,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { MoreVert } from "@material-ui/icons";
import { ToastContainer, toast } from "react-toastify";
import dayjs from "dayjs";

import { getCodigoUsuario } from "../../utils/utilidades";
import Correlativos from "./Correlativos";
import LoadingButton from "../utils/LoadingButton";
import Api from "../../../utils/api";

// =============================================================================
// Componente para asignar el codigo de los documentos
// =============================================================================

const SolicitudAProbar = ({ data, usuarios, onClose, onRefresh }) => {
  const [codigo, setCodigo] = useState("");
  const [revisor, setRevisor] = useState(null);
  const [autorizador, setAutorizador] = useState(null);
  const [prefix, setPrefix] = useState("A-P-OA-");
  const [observaciones, setObservaciones] = useState("");
  const [dialogCorrelativos, setDialogCorrelativos] = useState(false);
  const [loading, setLoading] = useState(false);
  const [solicitud, setSolicitud] = useState({
    IdTipoDocumento: "",
    NombreDocumento: "",
    DescripcionDocumento: "",
    FechaSolicitud: "",
    Observaciones: "",
    SistemasDeGestion: [],
  });
  const [masters, setMasters] = useState({
    tipos: [],
    divisiones: [],
    sistemas: [],
    macroprocesos: [],
    procesos: [],
  });
  const getMasters = async () => {
    try {
      const reqTipos = Api.fetchGetRequest("api/documentos/tipodocumento");
      const reqDivisiones = Api.fetchGetRequest("api/documentos/division");
      const reqSistemas = Api.fetchGetRequest("api/documentos/sistemagestion");
      const requests = [reqTipos, reqDivisiones, reqSistemas];
      const response = await Promise.all(requests);
      setMasters({
        tipos: response[0].data,
        divisiones: response[1].data,
        sistemas: response[2].data,
      });
    } catch (error) {
      toast.error("Ha ocurrido un error al obtener los datos");
    }
  };
  const changeSolicitud = (key, value) => {
    const tmp = { ...solicitud };
    tmp[key] = value;
    setSolicitud(tmp);
  };
  const aprobarSolicitud = async () => {
    if (!codigo) {
      toast.error("El codigo es requerido");
      return;
    }
    if (!revisor) {
      toast.error("Selecciona un revisor");
      return;
    }
    if (!autorizador) {
      toast.error("Selecciona un autorizador");
      return;
    }
    // if (data.CodigoUsuarioAutoriza === revisor.CodigoUsuario) {
    //   toast.error('El revisor debe ser alguien diferente de quien autoriza')
    //   return
    // }
    const datosAprobar = {
      CodigoUsuarioAutoriza: getCodigoUsuario(),
      Observaciones: observaciones,
      Solicitud: {
        IdDivisionDocumento: data.IdDivisionDocumento,
        IdTipoDocumento: solicitud.IdTipoDocumento,
        IdMacroprocesoDocumento: data.IdMacroproceso,
        IdProcesoDocumento: data.IdProceso,
        SistemasDeGestion: solicitud.SistemasDeGestion,
        CorrelativoDocumento: parseInt(codigo),
        NombreDocumento: solicitud.NombreDocumento,
        DescripcionDocumento: solicitud.DescripcionDocumento,
        SistemasDeGestion: solicitud.SistemasDeGestion.map((i) => i.Id),
      },
      codigoUsuarioAutorizadorFinalDelDocumento: autorizador.CodigoUsuario,
    };
    try {
      setLoading(true);
      // const datos = await aprobarModel.validate(datosAprobar)
      // datos.UsuarioModifico = getUsuarioInserta()
      datosAprobar.Revisores = [{ CodigoUsuario: revisor.CodigoUsuario }];
      await Api.post(
        `api/documentos/solicitud/aprobar/${data.IdSolicitud}`,
        datosAprobar
      );
      toast.success("Solicitud aprobada");
      onRefresh();
    } catch (err) {
      if (err.name === "ValidationError") {
        toast.error(err.errors[0]);
      } else {
        toast.error("Ha ocurrido un error");
      }
    } finally {
      setLoading(false);
    }
  };
  const rechazarSolicitud = async () => {
    if (!window.confirm("¿Estas seguro de rechazar la solicitud?")) {
      return;
    }
    if (!observaciones) {
      toast.error("Las observaciones son requeridas");
      return;
    }
    try {
      setLoading(true);
      const datos = {
        CodigoUsuario: getCodigoUsuario(),
        Observaciones: observaciones,
      };
      await Api.realizarPeticionPostPut(
        `api/documentos/solicitud/rechazar/${data.IdSolicitud}`,
        datos,
        "POST"
      );
      toast.success("Solicitud rechazada");
      onRefresh();
    } catch (err) {
      if (err.name === "ValidationError") {
        toast.error(err.errors[0]);
      } else {
        toast.error("Ha ocurrido un error");
      }
    } finally {
      setLoading(false);
    }
  };
  const getCorrelativo = async () => {
    try {
      const datos = {
        IdTipoDocumento: data.IdTipoDocumento,
        IdMacroproceso: data.IdMacroproceso,
        IdProceso: data.IdProceso,
        IdDepartamento: data.IdDepartamento,
      };
      const { data: response } = await Api.realizarPeticionPostPut(
        `api/documentos/correlativo/siguiente-por-proceso-tipodocumento`,
        datos,
        "POST"
      );
      setCodigo(parseInt(response.siguiente));
    } catch (error) {
      toast.error("No se pudo obtener el correlativo");
    }
  };
  const selectCorrelativo = (value) => {
    setCodigo(value);
    setDialogCorrelativos(false);
  };
  useEffect(() => {
    (async () => {
      if (data) {
        setSolicitud({
          SistemasDeGestion: [],
          IdTipoDocumento: data.IdTipoDocumento,
          NombreDocumento: data.NombreDocumento,
          DescripcionDocumento: data.DescripcionDocumento,
          FechaSolicitud: dayjs(data.FechaSolicitud).format("YYYY-MM-DD"),
          Observaciones: "",
        });
        setPrefix(
          `${data.AbreviaturaDivision}-${data.CodigoTipoDocumento}-${data.AbreviaturaProceso}`
        );
        getCorrelativo();
        await getMasters();
      }
    })();
  }, []);
  useEffect(() => {
    if (masters.sistemas.length) {
      const tmp = { ...solicitud };
      const sistemas = masters.sistemas.filter((i) =>
        data.ListaSistemaGestion.find((j) => i.Id === j.IdSistemaGestion)
      );
      setSolicitud({
        ...tmp,
        SistemasDeGestion: sistemas,
      });
    }
  }, [masters]);

  return (
    <>
      <DialogTitle>Aprobar solictud</DialogTitle>
      <DialogContent>
        <Autocomplete
          disabled
          multiple
          options={masters.sistemas}
          getOptionLabel={(op) => op.NombreSistemaGestion}
          defaultValue={[]}
          value={solicitud.SistemasDeGestion}
          onChange={(ev, value) => {
            changeSolicitud("SistemasDeGestion", [...value]);
            // setSistema([...value])
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Sistema de gestión"
            />
          )}
        />
        {/* <FormControl fullWidth style={{marginTop: '1em', marginBottom: '1em'}}>
        <InputLabel>Sistema de gestión</InputLabel>
        <Select
          disabled
          fullWidth
          value={solicitud.IdSistemaGestion}
          onChange={ev => changeSolicitud('IdSistemaGestion', ev.target.value)}
        >
          {masters.sistemas.map((i, idx) => <MenuItem key={idx} value={i.Id}>
            {i.NombreSistemaGestion}
          </MenuItem>)}
        </Select>
      </FormControl> */}
        <FormControl
          fullWidth
          style={{ marginTop: "1em", marginBottom: "1em" }}
        >
          <InputLabel>Tipo de documento</InputLabel>
          <Select
            disabled
            fullWidth
            value={solicitud.IdTipoDocumento}
            onChange={(ev) =>
              changeSolicitud("IdTipoDocumento", ev.target.value)
            }
          >
            {masters.tipos.map((i, idx) => (
              <MenuItem key={idx} value={i.Id}>
                {i.NombreTipoDocumento}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          fullWidth
          label="Título del documento"
          onChange={(ev) => changeSolicitud("NombreDocumento", ev.target.value)}
          value={solicitud.NombreDocumento}
        />
        <TextField
          fullWidth
          label="Descripción"
          multiline
          onChange={(ev) =>
            changeSolicitud("DescripcionDocumento", ev.target.value)
          }
          value={solicitud.DescripcionDocumento}
        />
        {/* <TextField
        fullWidth
        label="Fecha"
        type="date"
        onChange={ev => changeSolicitud('FechaSolicitud', ev.target.value)}
        value={solicitud.FechaSolicitud}
        InputLabelProps={{ shrink: true }}
      /> */}
        <Box>
          <TextField
            disabled={true}
            label="Codigo"
            value={codigo}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">{prefix}</InputAdornment>
              ),
            }}
            onChange={(ev) => setCodigo(ev.target.value)}
          />
          <IconButton onClick={() => setDialogCorrelativos(true)}>
            <MoreVert />
          </IconButton>
        </Box>
        <Autocomplete
          options={usuarios}
          getOptionLabel={(option) =>
            option.NombreCompletoUsuario ? option.NombreCompletoUsuario : ""
          }
          value={revisor}
          onChange={(ev, value) => {
            setRevisor(value);
          }}
          defaultValue=""
          renderInput={(params) => (
            <TextField {...params} variant="standard" label="Revisor" />
          )}
        />
        <Autocomplete
          options={usuarios}
          getOptionLabel={(option) =>
            option.NombreCompletoUsuario ? option.NombreCompletoUsuario : ""
          }
          value={autorizador}
          onChange={(ev, value) => {
            setAutorizador(value);
          }}
          defaultValue=""
          renderInput={(params) => (
            <TextField {...params} variant="standard" label="Autorizador" />
          )}
        />
        {/* <Autocomplete
        multiple
        options={usuarios}
        getOptionLabel={(option) => option.NombreCompletoUsuario ? option.NombreCompletoUsuario : ''}
        defaultValue={[]}
        value={revisores}
        onChange={(ev, value) => {
          setRevisores([...value])
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="Revisores"
          />
        )}
      /> */}
        <TextField
          fullWidth
          value={observaciones}
          onChange={(ev) => setObservaciones(ev.target.value)}
          multiline
          rows={4}
          label="Observaciones"
        />
        <LoadingButton
          fullWidth
          color="primary"
          loading={loading}
          onClick={aprobarSolicitud}
          style={{ marginTop: 10, backgroundColor: "#69BD4B", color: "white" }}
        >
          Aprobar
        </LoadingButton>
        <LoadingButton
          fullWidth
          color="error"
          loading={loading}
          onClick={rechazarSolicitud}
          style={{ marginTop: 10, backgroundColor: "#f44336", color: "white" }}
        >
          Rechazar
        </LoadingButton>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
      </DialogActions>
      <ToastContainer />
      <Dialog open={dialogCorrelativos} fullWidth maxWidth="sm">
        <Correlativos
          onClose={() => setDialogCorrelativos(false)}
          onSelect={selectCorrelativo}
          solicitud={data}
        />
      </Dialog>
    </>
  );
};

export default SolicitudAProbar;
