import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import {
  Typography,
  Backdrop,
  CircularProgress,
  Tab,
  Tabs,
  Box,
  Divider,
  TabScrollButton,
} from "@material-ui/core";

import Avatar from "@material-ui/core/Avatar";
import Container from "@material-ui/core/Container";
import { green } from "@material-ui/core/colors";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { validaPermisoSecundarioPaginaActual } from "../../utils/helpers";

//iconos

import {
  Face,
  PersonPin,
  AssignmentInd,
  FlightTakeoff,
  School,
  DateRange,
} from "@material-ui/icons";

//input date

import PropTypes from "prop-types";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";

import Autocomplete from "@material-ui/lab/Autocomplete";

//Own components
// import Layout from "../../layout/containers/Layout";

import API from "../../utils/api";
import { isSignedIn, profileImageChange, signOut } from "../../utils/auth";

import MaestroEducacion from "./maestro-educacion-componente";
import FuncionesNegocio from "./funciones-negocio-componente";
import MaestroCreditosIntenrnos from "./maestro-creditos-componente";
import MaestroTemporada from "./temporada-componente";

const a11yProps = (index) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Typography component={"span"} variant={"body2"}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const styles = makeStyles((theme) => ({
  item: {
    //border: "1px solid blue",
    margin: "10",
  },
  itemFlexGrow: {
    flexGrow: 1,
    // border: "1px solid red"
  },
  content: {
    display: "flex",
    // justify-content: "space-between",
    maxWidth: "400px",
    margin: "0 auto",
    padding: "10px 0",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
  paperPerfil: {
    position: "relative",
    width: "100%", //theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 1,
    outline: "none",
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 1,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  greenAvatar: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  paperCard: {
    padding: 20,
    width: "100%",
  },
  greenAvatarFoto: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(18),
    height: theme.spacing(18),
  },
  tabsVertical: {
    borderRight: `1px solid ${theme.palette.divider}`,
    overflow: "visible",
  },
  contenedorTabVertical: {
    // flexGrow: 1,
    //backgroundColor: theme.palette.background.paper,
    display: "flex",
    //height: 224,
    width: "100%",
  },
  box: {
    // flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

const useTabs = makeStyles((theme) => {
  const tabsBackground = "linear-gradient(60deg, #53cf6d, #32613c)";
  const indicatorBackground = "rgba(255, 255, 255, .2)";
  const borderRadius = theme.spacing.unit;
  return {
    root: {
      width: "100%",
      borderRadius: theme.spacing.unit,
      background: tabsBackground,
      padding: 10,
      boxShadow: "0px 3px 15px rgba(34, 35, 58, 0.5)",
    },
    indicator: {
      height: "100%",
      borderRadius,
      backgroundColor: indicatorBackground,
    },
  };
});
const useTabItem = makeStyles((theme) => {
  const tabsGutter = theme.spacing.unit * 2;
  const labelColor = "#ffffff";
  return {
    root: {
      textTransform: "initial",
      margin: `0 ${tabsGutter}px`,
      minWidth: 0,
      color: labelColor,
    },
    wrapper: {
      fontWeight: "normal",
      letterSpacing: 0.5,
      color: labelColor,
    },
  };
});
const ConfiguracionGESPILU = () => {

  const [buscando, setBuscando] = useState(false);
  const [tabVertical, setTabVertical] = useState(2);
  const classes = styles();
  const tabsStyles = useTabs();
  const tabItemStyles = useTabItem();

  return (
    <React.Fragment>
      <Backdrop className={classes.backdrop} open={buscando}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth="xl">
        <Paper id="resultado" className={classes.paperPerfil}>
          <Box
            spacing={0}
            //bgcolor="#6ea02f"
            alignItems="center"
            justifyContent="center"
            align="center"
          >
            <Typography gutterBottom variant="h4">
              <b>Configuración</b>
            </Typography>
          </Box>
          <Divider variant="middle" />

          <Tabs
            className={tabsStyles.root}
            value={tabVertical}
            indicatorColor="primary"
            textColor="primary"
            onChange={(_, val) => setTabVertical(val)}
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            // centered
          >
            <Tab
              className={tabItemStyles.root}
              label="Educación"
              value={0}
              {...a11yProps(0)}
              style={{ textTransform: "none", color: "#ffffff" }}
              icon={<School/>}
            />
            <Tab
              className={tabItemStyles.root}
              label="Funciones de Negocio"
              value={1}
              {...a11yProps(1)}
              style={{ textTransform: "none", color: "#ffffff" }}
              icon={<School/>}
            />
            <Tab
              className={tabItemStyles.root}
              label="Creditos Internos"
              value={2}
              {...a11yProps(2)}
              style={{ textTransform: "none", color: "#ffffff" }}
              icon={<School/>}
            />
            <Tab
              className={tabItemStyles.root}
              label="Temporadas"
              value={3}
              {...a11yProps(3)}
              style={{ textTransform: "none", color: "#ffffff" }}
              icon={<DateRange/>}
            />
          </Tabs>
          <TabPanel id="educación" value={tabVertical} index={0}>
            <Box className={classes.box} pl={0} pt={0}>
              <Grid container spacing={1}>
                <MaestroEducacion />
              </Grid>
            </Box>
          </TabPanel>
          <TabPanel id="funciones-negocio" value={tabVertical} index={1}>
            <Box className={classes.box} pl={0} pt={0}>
              <Grid container spacing={1}>
                <FuncionesNegocio />
              </Grid>
            </Box>
          </TabPanel>
          <TabPanel id="creditos-internos" value={tabVertical} index={2}>
            <Box className={classes.box} pl={0} pt={0}>
              <Grid container spacing={1}>
                <MaestroCreditosIntenrnos />
              </Grid>
            </Box>
          </TabPanel>
          <TabPanel id="temporadas" value={tabVertical} index={3}>
            <Box className={classes.box} pl={0} pt={0}>
              <Grid container spacing={1}>
                <MaestroTemporada />
              </Grid>
            </Box>
          </TabPanel>
        </Paper>
      </Container>

      <ToastContainer />
    </React.Fragment>
  );
};

export default withRouter(ConfiguracionGESPILU);
