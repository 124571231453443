import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DropzoneArea } from "material-ui-dropzone";
import { AttachFile, TableChart } from "@material-ui/icons/AttachFile";
import XLSX from "xlsx";
import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Checkbox,
  FormControlLabel,
  Divider,
  Paper,
  Tooltip,
  Modal,
  Fade,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Box,
  Chip,
} from "@material-ui/core";

import DataGrid, {
  Column,
  Pager,
  Paging,
  Sorting,
  ColumnFixing,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Grouping,
  GroupPanel,
  Export,
  Selection,
  Editing,
  Scrolling,
  LoadPanel,
  Lookup,
} from "devextreme-react/data-grid";

import CustomStore from "devextreme/data/custom_store";

import BackdropModal from "@material-ui/core/Backdrop";

import { Delete, ViewList, Add, Edit, CloudUpload } from "@material-ui/icons";

import { green } from "@material-ui/core/colors";
import { NavLink } from "react-router-dom";
import {
  Timeline,
  TimelineItem,
  TimelineContent,
  TimelineSeparator,
  TimelineConnector,
  TimelineDot,
  TimelineOppositeContent,
} from "@material-ui/lab";

import API from "../../utils/api";
import { isSignedIn, signOut } from "../../utils/auth";
import { validaPermisoPaginaActual } from "../../utils/helpers";
import { ToastContainer, toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import "devextreme/dist/css/dx.material.teal.light.compact.css";
import DataSource from "devextreme/data/data_source";

const width_proportion = "100%";
const titleColor = "#2B3C4D";
let datagridEmpleadosSCILU;

const useStyles = makeStyles((theme) => ({
  paperContent: {
    padding: "6px 16px",
  },
  table: {
    width: "100%",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 210,
  },
  textFieldDireccion: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 230,
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  greenAvatar: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  paperCard: {
    padding: 20,
    width: "100%",
  },
  formControlSelects: {
    // margin: theme.spacing(1),
    minWidth: 400,

    marginBottom: 10,
    marginTop: 10,
    marginLeft: 30,
  },
  formControlSelect: {
    // margin: theme.spacing(1),
    width: width_proportion,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  rootTabla: {
    width: "100%",
    marginTop: theme.spacing.unit * 1,
    overflowX: "auto",
  },
  modal: {
    // display: "flex",
    // alignItems: "center",
    // justifyContent: "center",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: "10%",
    left: "20%",
    overflow: "scroll",
    height: "100%",
    display: "block",
    padding: theme.spacing(8, 4, 8, 8),
  },
  paperModal: {
    position: "absolute",
    width: "90%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const FuncionesPuestoLaboral = (props) => {
  const classes = useStyles();
  const { codigoCompetencia } = props;
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors: fieldsErrors },
    register,
  } = useForm();

  let applyFilterTypes = [
    {
      key: "auto",
      name: "Immediately",
    },
    {
      key: "onClick",
      name: "On Button Click",
    },
  ];

  var datosGridCompetencia = new DataSource({
    key: "CodigoConductaObservable",
    load: () => cargarDatos("api/ConductaObservable/" + codigoCompetencia),
    insert: (values) => guardarDato("api/ConductaObservable/", null, values),
    update: (key, values) =>
      guardarDato("api/ConductaObservable/", key, values),
    remove: (key, values) =>
      guardarDato("api/ConductaObservable/Eliminar/", key, values),
  });
  const [dialogoAbierto, abrirDialogo] = useState(false);
  const [userName, setUserName] = useState("");
  const [open, setOpen] = useState(false);
  const [competencia, setCompetencia] = useState(null);

  const [positionDisableSorting, setPositionDisableSorting] = useState(false);
  const [showFilterRow, setShowFilterRow] = useState(true);
  const [showHeaderFilter, setShowHeaderFilter] = useState(true);
  const [currentFilter, setCurrentFilter] = useState(applyFilterTypes[0].key);
  const [autoExpandAll, setAutoExpandAll] = useState(true);

  const [buscando, setBackDrop] = useState(false);

  const [maestroCompetencia, setMaestroCompetencia] =
    useState(datosGridCompetencia);
  const [datosMaestroEducacion, setDatosMaestroEducacion] = useState(
    new CustomStore({
      key: "CodigoCompetencia",
      loadMode: "raw",
      load: () => cargarDatos("api/competencia/listar"),
    })
  );

  const [archivo, setArchivo] = useState(null);
  const [excelCargado, setExcelCargado] = useState(false);
  const [excelValidado, setExcelValidado] = useState([]);

  useEffect(() => {
    const cargar = async () => {
      // CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
      const llaveAgrupacion = "Menu.Principal.Web";
      const llavePagina = "Menu.Principal.Web.PuestoLaboral";
      await validaPermisoPaginaActual(llaveAgrupacion, llavePagina);
      // FIN DE CONSULTA DE PERMISOS

      handleAbrirBackDrop();

      await cargarCompetencia();
      // await cargarMaestroEducacion();
      // await cargarMaestroResponsabilidadPuestoLaboral();
      handleCerrarBackDrop();
    };

    cargar();
  }, []);

  const cargarDatos = async (url) => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      console.log(session);
      setUserName(session.userName);
    }

    try {
      handleAbrirBackDrop();

      let uri = url;
      //await delay(1000000);
      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        if (request.data.response) {
          return request.data.data;
        } else {
          console.log("EXCEPTION: " + request.data.mensaje);
          toast.error(
            "Hubo un error al cargar los datos. " + request.data.mensaje
          );
          return [];
        }
        //setVerbos(request.data.datos);
      }
    } catch (e) {
      console.log(e);
      toast.error("Hubo un error al cargar los datos. " + e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const handleCerrarBackDrop = () => {
    setBackDrop(false);
  };
  const handleAbrirBackDrop = () => {
    setBackDrop(true);
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const cargarCompetencia = async () => {
    let session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    }
    try {
      //   setBuscando(true);

      const request = await API.fetchGetRequest(
        `api/Competencia/${codigoCompetencia}`
      );

      if (request.data.data == null) {
        return;
      }

      let user = request.data.data;

      //setCodigoUsuario(user.CodigoUsuario);
      setCompetencia(user);
      //   setPuestoLaboralNombre(user.PuestoLaboral);
    } catch (e) {
      console.log(e);
    } finally {
      //   setBuscando(false);
    }
  };

  const guardarDato = async (url, key, values) => {
    const data = {
      CodigoConductaObservable: key,
      Nombre: values ? values.Nombre : null,
      Descripcion: values ? values.Descripcion : null,
      CodigoCompetencia: codigoCompetencia,
      Activo: true,
      Eliminado: false,
      UsuarioInserto: userName,
      FechaInserto: "",
      UsuarioModifico: "",
      FechaModifico: "",
    };

    console.log(data);
    // dataFicha.usuarioInserto = "prueba";
    try {
      handleAbrirBackDrop();
      let vIsSignedIn = await isSignedIn();
      if (vIsSignedIn.response) {
        let request;

        request = await API.post(url, data);
        if (request.status !== 200) {
          toast.error(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        } else {
          if (request.data.response) {
            toast.success("Se guardó exitosamente");
          } else {
            toast.error(
              "Hubo un error al realizar la operación: " + request.data.mensaje
            );
          }
        }
      }
    } catch (e) {
      console.log("EXCEPTION: " + e);
      toast.error(
        "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
      );
    } finally {
      handleCerrarBackDrop();
    }
  };

  const onInitialized = (e) => {
    datagridEmpleadosSCILU = e.component;
  };

  const guardarDatoMasivo = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      const data = {
        ExcelValidado: excelValidado,
        // NumeroVersion: numeroVersion,
        // FechaCreacion: fechaCreacion,
        // Activo: true,
        // Eliminado: false,
        UsuarioInserto: session["userName"],
        // FechaInserto: "",
        // UsuarioModifico: "",
        // FechaModifico: "",
      };

      console.log(data);
      // dataFicha.usuarioInserto = "prueba";
      try {
        handleAbrirBackDrop();
        let vIsSignedIn = await isSignedIn();
        if (vIsSignedIn.response) {
          let request;

          request = await API.post("api/ConductaObservable/CargaMasiva", data);

          if (request.status !== 200) {
            toast.error(
              "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
            );
          } else {
            console.log(request);
            if (!request.data.response) {
              toast.error("Error. Contacte a TI. " + request.data.mensaje);
            } else {
              console.log(request.data.data);
              datosGridCompetencia.load();
              datosGridCompetencia.reload();
              datagridEmpleadosSCILU.refresh();
              toast.info("Se guardó la información correctamente");
            }
          }
        }
      } catch (e) {
        console.log("EXCEPTION: " + e);
        toast.error(
          "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
        );
      } finally {
        handleCerrarBackDrop();
      }
    }
  };
  const onExporting = async (e) => {};

  const cargarExcel = async () => {
    try {
      // https://stackoverflow.com/questions/63495018/convert-excel-to-json-using-react-dropzone-client-side-probably
      handleAbrirBackDrop();
      setExcelCargado(false);
      // setFileNames(acceptedFiles.map(file => file.name));
      archivo.forEach((file) => {
        console.log("handleExcelDrop:forEach(file)");
        // See https://stackoverflow.com/questions/30859901/parse-xlsx-with-node-and-create-json
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString; // !! converts object to boolean
        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = async (e) => {
          // Do what you want with the file contents
          var bstr = e.target.result;
          var workbook = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
          var sheet_name_list = workbook.SheetNames[0];
          var jsonFromExcel = XLSX.utils.sheet_to_json(
            workbook.Sheets[sheet_name_list],
            {
              raw: false,
              dateNF: "DD-MM-YYYY",
              // header:"A",
              defval: "",
            }
          );
          console.log("jsonFromExcel object=");
          console.log(jsonFromExcel);

          let vIsSignedIn = await isSignedIn();
          if (vIsSignedIn.response) {
            let request;
            const data = {
              JsonFromExcel: jsonFromExcel,
            };

            request = await API.post(
              "api/ConductaObservable/ValidacionExcel",
              data
            );
            if (request.status !== 200) {
              toast.error(
                "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
              );
            } else {
              if (!request.data.response) {
                toast.error("Error. Contacte a TI. " + request.data.mensaje);
              } else {
                console.log(request.data.data);
                setExcelValidado(request.data.data);
                setExcelCargado(true);
                toast.success("Se cargo exitosamente");
              }
            }
          }
        };
        if (rABS) reader.readAsBinaryString(file);
        else reader.readAsArrayBuffer(file);
      });
    } catch (error) {
      console.log("EXCEPTION: " + error);
      setExcelCargado(false);
      toast.error(
        "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
      );
    } finally {
      handleCerrarBackDrop();
    }
  };
  const handlePreviewIcon = (fileObject, classes) => {
    const { type } = fileObject.file;
    const iconProps = {
      className: classes.image,
    };
    switch (type) {
      case "application/msexcel":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return <TableChart {...iconProps} />;
      default:
        return <AttachFile {...iconProps} />;
    }
  };

  const handleChangeFiles = (campo) => {
    console.log("handleExcelDrop");
    console.log(campo);
    setArchivo(campo);
  };

  const cuandoHayError = async (data) => {
    toast.info(
      "Existen algunos campos con error, por favor valida el formulario",
      {
        position: "top-right",
        autoClose: 3000, // Esta en milisegundos 3 segundos
      }
    );
  };

  return (
    <React.Fragment>
      <Backdrop className={classes.backdrop} open={buscando}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer />

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={BackdropModal}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paperModal}>
            <h2 id="simple-modal-title">Carga Masiva de Competencias</h2>
            <Grid container spacing={3}>
              <form
                autoComplete="off"
                onSubmit={handleSubmit(cargarExcel, cuandoHayError)}
              >
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <FormControl
                    className={classes.formControlSelects}
                    required={true}
                  >
                    <label>Carga de Archivo</label>
                    <DropzoneArea
                      dropzoneText={`Cargue un archivo de excel`}
                      onChange={handleChangeFiles}
                      maxFileSize={1073741824}
                      acceptedFiles={[".xls", ".xlsx"]}
                      filesLimit={1}
                      getPreviewIcon={handlePreviewIcon}
                      showPreviews={true}
                      showPreviewsInDropzone={false}
                      useChipsForPreview
                      previewGridProps={{
                        container: { spacing: 1, direction: "row" },
                      }}
                      previewChipProps={{
                        classes: { root: classes.previewChip },
                      }}
                      previewText="Archivo seleccionado"
                    />
                  </FormControl>
                  <Button
                    width="100%"
                    variant="contained"
                    style={{
                      backgroundColor: "#69bd4b",
                      marginBottom: 10,
                      marginTop: 10,
                      color: "white",
                      width: "93%",
                      marginLeft: 30,
                    }}
                    type="submit"
                  >
                    &nbsp;Cargar Excel
                  </Button>
                </Grid>
              </form>
            </Grid>
            {excelCargado && (
              <Paper className={classes.rootTabla}>
                <DataGrid
                  dataSource={excelValidado}
                  //   defaultColumns={this.props.columns}
                  showBorders={true}
                  rowAlternationEnabled={true}
                  //ref={(ref) => (dataGrid = ref)}
                  allowColumnReordering={true}
                  allowColumnResizing={true}
                  columnAutoWidth={true}
                  //onExporting={onExporting}
                  className={classes.table}
                  wordWrapEnabled={true}
                >
                  <FilterRow
                    visible={showFilterRow}
                    applyFilter={currentFilter}
                  />
                  <HeaderFilter visible={showHeaderFilter} />
                  <GroupPanel visible={false} />
                  <Grouping autoExpandAll={autoExpandAll} />
                  <SearchPanel
                    visible={false}
                    width={240}
                    placeholder="Search..."
                  />
                  {/* <Selection mode="multiple" /> */}
                  <Editing
                    mode="row"
                    useIcons={true}
                    allowUpdating={false}
                    allowDeleting={false}
                  />
                  {/* <Column
              caption="Opciones"
              allowFiltering={false}
              allowSorting={false}
              cellRender={cellOpciones}
            /> */}
                  <Column
                    dataField="TieneError"
                    caption="Tiene Error"
                    dataType="boolean"
                  />
                  <Column dataField="Comentario" caption="Comentario" />

                  <Column
                    dataField="CodigoCompetencia"
                    caption="Código Competencia"
                    // dataType="number"
                  ></Column>
                  <Column
                    dataField="Competencia"
                    caption="Competencia"
                    // dataType="number"
                  ></Column>
                  {/* <Column dataField="Educacion" caption="Educación" /> */}
                  <Column
                    dataField="Nombre"
                    caption="ConductaObservable"
                    // allowEditing={false}
                  />
                  <Column
                    dataField="Descripcion"
                    caption="Descripción"
                    minWidth={200}
                    maxWidth={300}
                    // allowEditing={false}
                  />

                  <ColumnFixing enabled={true} />
                  <Sorting mode="multiple" />
                  <Paging defaultPageSize={20} />
                  <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[10, 20, 50]}
                    showInfo={true}
                  />
                  <Export enabled={true} allowExportSelectedData={true} />
                  <Scrolling columnRenderingMode="virtual" />
                </DataGrid>
                <Grid container spacing={3}>
                  <form
                    autoComplete="off"
                    onSubmit={handleSubmit(guardarDatoMasivo, cuandoHayError)}
                  >
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Button
                        width="100%"
                        variant="contained"
                        style={{
                          backgroundColor: "#69bd4b",
                          marginBottom: 10,
                          marginTop: 10,
                          color: "white",
                          width: "93%",
                          marginLeft: 30,
                        }}
                        type="submit"
                      >
                        &nbsp;Guardar Datos
                      </Button>
                    </Grid>
                  </form>
                </Grid>
              </Paper>
            )}
          </div>
        </Fade>
      </Modal>
      <AppBar
        position="sticky"
        className={classes.appBar}
        style={{ backgroundColor: titleColor }}
      >
        <Toolbar>
          {competencia !== null && competencia !== undefined && (
            <Typography
              variant="h6"
              noWrap
              style={{ display: "flex", flexGrow: 1 }}
            >
              {`Competencia: ` + competencia === null
                ? ""
                : competencia.CodigoCompetencia + `-` + competencia.Nombre}
            </Typography>
          )}
          <Tooltip
            title={"Carga Masiva de Competencias"}
            aria-label="Carga Masiva de Competencias"
          >
            <IconButton
              aria-label="Carga Masiva de Competencias"
              style={{ color: "white" }}
            >
              <CloudUpload onClick={handleOpenModal} />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {maestroCompetencia !== undefined ? (
            <Paper className={classes.rootTabla}>
              <DataGrid
                dataSource={maestroCompetencia}
                //   defaultColumns={this.props.columns}
                showBorders={true}
                rowAlternationEnabled={true}
                //ref={(ref) => (dataGrid = ref)}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                onExporting={onExporting}
                className={classes.table}
                id="tablaEducacionEmpleado"
                wordWrapEnabled={true}
                onInitialized={onInitialized}
              >
                <FilterRow
                  visible={showFilterRow}
                  applyFilter={currentFilter}
                />
                <HeaderFilter visible={showHeaderFilter} />
                <GroupPanel visible={false} />
                <Grouping autoExpandAll={autoExpandAll} />
                <SearchPanel
                  visible={false}
                  width={240}
                  placeholder="Search..."
                />
                {/* <Selection mode="multiple" /> */}
                <LoadPanel
                  enabled={true}
                  shadingColor="rgba(0,0,0,0.4)"
                  showIndicator={true}
                  shading={true}
                  showPane={true}
                  closeOnOutsideClick={false}
                />
                <Editing
                  mode="row"
                  useIcons={true}
                  allowAdding={true}
                  allowUpdating={true}
                  allowDeleting={true}
                />
                {/* <Column
            caption="Opciones"
            allowFiltering={false}
            allowSorting={false}
            cellRender={cellOpcionesSolicitud}
          /> */}

                <Column
                  dataField="CodigoConductaObservable"
                  caption="Código"
                  dataType="number"
                  allowEditing={false}

                  // visible={false}
                />
                <Column
                  dataField="TipoSkill"
                  caption="Tipo"
                  allowEditing={false}

                  // dataType="number"
                />
                <Column
                  dataField="CodigoCompetencia"
                  caption="Código"
                  dataType="number"
                  allowEditing={false}
                  // visible={false}
                />
                <Column
                  dataField="Competencia"
                  caption="Competencia"
                  allowEditing={false}
                  // dataType="number"
                >
                  {/* <Lookup
                    dataSource={datosMaestroEducacion}
                    valueExpr="CodigoCompetencia"
                    displayExpr="Competencia"
                  /> */}
                </Column>
                {/* <Column dataField="Educacion" caption="Educación" /> */}
                <Column
                  dataField="Nombre"
                  caption="Conducta Observable"
                  // allowEditing={false}
                />
                <Column
                  dataField="Descripcion"
                  caption="Descripción"
                  minWidth={350}
                  maxWidth={600}
                  // allowEditing={false}
                />
                <ColumnFixing enabled={true} />
                <Sorting mode="multiple" />
                <Paging defaultPageSize={20} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[20, 50]}
                  showInfo={true}
                />
                <Export enabled={true} allowExportSelectedData={true} />
                <Scrolling columnRenderingMode="virtual" />
              </DataGrid>
            </Paper>
          ) : undefined}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default FuncionesPuestoLaboral;
