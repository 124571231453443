import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";

import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Checkbox,
  FormControlLabel,
  Divider,
  Paper,
  Tooltip,
  Modal,
  Fade,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
} from "@material-ui/core";

import BackdropModal from "@material-ui/core/Backdrop";

import clsx from "clsx";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import {
  AssignmentTurnedIn,
  CancelOutlined,
  FiberManualRecord,
  Pageview,
} from "@material-ui/icons";

import { green } from "@material-ui/core/colors";

import DataGrid, {
  Column,
  Pager,
  Paging,
  Sorting,
  ColumnFixing,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Grouping,
  GroupPanel,
  Export,
  Selection,
  Editing,
  Scrolling,
  RowDragging,
  LoadPanel,
} from "devextreme-react/data-grid";

import {
  PlayCircleOutline,
  Edit,
  Delete,
  Add,
  TransferWithinAStation,
  Traffic,
  AssignmentInd,
} from "@material-ui/icons";

import PerfilComponente from "../../perfil/containers/perfilComponenteHorizontal";
import API from "../../utils/api";
import { isSignedIn, signOut } from "../../utils/auth";
import {
  validaPermisoPaginaActual,
  verificaPermisoPaginaActual,
} from "../../utils/helpers";
import { ToastContainer, toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import "devextreme/dist/css/dx.material.teal.light.compact.css";
import { DateBox, Lookup } from "devextreme-react";
import { nextSortDirection } from "@material-ui/data-grid";

const width_proportion = "100%";
const titleColor = "#2B3C4D";
let datagridEmpleadosSCILU;

const useStyles = makeStyles((theme) => ({
  table: {
    width: "100%",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 210,
  },
  textFieldDireccion: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 230,
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  greenAvatar: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  paperCard: {
    padding: 20,
    width: "100%",
  },
  formControlSelects: {
    // margin: theme.spacing(1),
    minWidth: 480,

    marginBottom: 10,
    marginTop: 10,
    width: "100%",
    marginLeft: 30,
  },
  formControlSelect: {
    // margin: theme.spacing(1),
    width: width_proportion,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  rootTabla: {
    width: "100%",
    marginTop: theme.spacing.unit * 1,
    overflowX: "auto",
  },
  // modal: {
  //   display: "flex",
  //   alignItems: "center",
  //   justifyContent: "center",
  // },
  // paperModal: {
  //   position: "absolute",
  //   width: "70%",
  //   backgroundColor: theme.palette.background.paper,
  //   boxShadow: theme.shadows[5],
  //   padding: theme.spacing(2, 4, 3),
  // },
  modal: {
    // display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: "10%",
    left: "20%",
    overflow: "scroll",
    height: "90%",
    display: "block",
    padding: theme.spacing(8, 4, 8, 8),
  },
  paperModal: {
    position: "absolute",
    width: "90%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1, 1, 1, 1),
  },
  selectedData: {
    marginTop: "20px",
    padding: "20px",
    backgroundColor: "rgba(191, 191, 191, 0.15)",
  },
  selectedDataCaption: {
    fontWeight: "bold",
    fontSize: "115%",
    marginRight: "4px",
  },
}));

const EmpleadoPlazaLaboral = (props) => {
  const classes = useStyles();
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors: fieldsErrors },
    register,
  } = useForm();

  let applyFilterTypes = [
    {
      key: "auto",
      name: "Immediately",
    },
    {
      key: "onClick",
      name: "On Button Click",
    },
  ];

  const monedaFormat = {
    style: "currency",
    currency: "GTQ",
    // useGrouping: true,
    minimumSignificantDigits: 3,
  };

  const porcentajeFormat = {
    type: "fixedPoint",
    format: "#0.##'%'",
    // useGrouping: true,
    // minimumSignificantDigits: 3,
  };

  const now = new Date();
  const nextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1);
  const fechaMax = new Date(nextMonth.getTime());
  fechaMax.setDate(fechaMax.getDate() + 7);
  const [editarCompleto, setEditarCompleto] = useState(false);
  const [maestroPlazaLaboral, setMaestroPlazaLaboral] = useState([]);
  const [codigoPlazaLaboral, setCodigoPlazaLaboral] = useState(0);
  const [valueAutoCompletePlazaLaboral, setValueAutoCompletePlazaLaboral] =
    useState(null);

  const [codigoPlazaLaboralEdicion, setCodigoPlazaLaboralEdicion] = useState(0);
  const [
    valueAutoCompletePlazaLaboralEdicion,
    setValueAutoCompletePlazaLaboralEdicion,
  ] = useState(null);

  const [codigoNivelOrganizacion, setCodigoNivelOrganizacion] = useState(1);
  const [maestroGerencia, setMaestroGerencia] = useState([]);
  const [textoNivelOrganizacion, setTextoNivelOrganizacion] = useState("");
  const [registroRaiz, setRegistroRaiz] = useState({});
  const [registroActual, setRegistroActual] = useState({});
  const [nombre, setNombre] = useState("");
  const [codigoUnidadOrganizacion, setCodigoUnidadOrganizacion] = useState(0);

  const [grado, setGrado] = useState(0);
  const [organigrama, setOrganigrama] = useState(null);

  const [userName, setUserName] = useState("");
  const [open, setOpen] = useState(false);
  const [openPL, setOpenPL] = useState(false);
  const [openModalAsignacionEmpleado, setOpenModalAsignacionEmpleado] =
    useState(false);

  const [cargandoMaestros, setCargandoMaestros] = useState(false);
  const [organizacionHijos, setOrganizacionHijos] = useState([]);
  const [maestroNivelPuestoLaboral, setMaestroNivelPuestoLaboral] = useState(
    []
  );
  const [maestroTipoTurno, setMaestroTipoTurno] = useState([]);

  const [codigoGerencia, setCodigoGerencia] = useState(0);
  const [valueAutoCompleteGerencia, setValueAutoCompleteGerencia] =
    useState(null);
  const [listadoEmpleadoNoAsignado, setListadoEmpleadoNoAsignado] = useState(
    []
  );
  const [
    listadoEmpleadoAsignadoPorUnidad,
    setListadoEmpleadoAsignadoPorUnidad,
  ] = useState([]);
  const [listadoEmpleadoAsignado, setListadoEmpleadoAsignado] = useState([]);
  const [empleadosSeleccion, setempleadosSeleccion] = useState([]);
  const [positionDisableSorting, setPositionDisableSorting] = useState(false);
  const [showFilterRow, setShowFilterRow] = useState(true);
  const [showHeaderFilter, setShowHeaderFilter] = useState(true);
  const [currentFilter, setCurrentFilter] = useState(applyFilterTypes[0].key);
  const [autoExpandAll, setAutoExpandAll] = useState(true);

  const [buscando, setBackDrop] = useState(false);

  const [codigoTipoTurno, setCodigoTipoTurno] = useState(0);
  const [valueAutoCompleteTipoTurno, setValueAutoCompleteTipoTurno] =
    useState(null);
  const [dpi, setDpi] = useState("");
  const [datosEmpleado, setDatosEmpleado] = useState(null);
  const [nombreEmpleado, setNombreEmpleado] = useState("");
  const [codigoEmpleadoPlazaLaboral, setCodigoEmpleadoPlazaLaboral] =
    useState(0);

  const [selectedEmployeeNames, setSelectedEmployeeNames] = useState(
    "No hay empleados seleccionados"
  );

  const [dialogoAbiertoCrearGestion, abrirDialogoCrearGestion] =
    useState(false);

  const [historicoPuestoPorEmpleado, setHistoricoPuestoPorEmpleado] = useState(
    []
  );

  const [selectAllCheckBox, setSelectAllCheckBox] = useState(null);
  const [checkBoxUpdating, setCheckBoxUpdating] = useState(false);

  const [fechaEjecucion, setFechaEjecucion] = useState(nextMonth);

  const handleCerrarBackDrop = () => {
    setBackDrop(false);
  };
  const handleAbrirBackDrop = () => {
    setBackDrop(true);
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleOpenModalPuestoLaboral = () => {
    setOpenPL(true);
  };

  const handleCloseModalPuestoLaboral = () => {
    setOpenPL(false);
  };

  const handleOpenModalAsignacionEmpleado = () => {
    setOpenModalAsignacionEmpleado(true);
  };

  const handleCloseModalAsignacionEmpleado = () => {
    setOpenModalAsignacionEmpleado(false);
  };

  useEffect(() => {
    const cargar = async () => {
      // CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
      const llaveAgrupacion = "GESPILU";
      const llaveVisualizarArea = "GESPILU-AnalisisSalarial-Editar_Completo";
      let verArea = await verificaPermisoPaginaActual(
        llaveAgrupacion,
        llaveVisualizarArea
      );
      setEditarCompleto(verArea);
      // FIN DE CONSULTA DE PERMISOS

      handleAbrirBackDrop();

      await cargarMaestroGerencia();
      //await cargarEmpleadoAsignado();
      await cargarMaestroTipoTurno();
      await cargarMaestroPlazaLaboral();
      setDpi("");
      handleCerrarBackDrop();
    };

    cargar();
  }, [props.codigoUnidadOrganizacion]);

  useEffect(() => {
    const cargar = async () => {
      await cargarEmpleadoAsignado();
    };

    cargar();
  }, [codigoPlazaLaboral]);

  useEffect(() => {
    let data = maestroGerencia;
    if (data.length > 0) {
      var nivelActual = data.find(
        (f) => f.CodigoNivelOrganizacion === codigoNivelOrganizacion
      );

      if (nivelActual !== undefined || nivelActual !== null) {
        setTextoNivelOrganizacion(nivelActual.NivelOrganizacion);
      }
    }
  }, [codigoNivelOrganizacion]);

  useEffect(() => {
    const cargar = async () => {
      await cargarHistoricoEmpleado();
      await buscarUsuario();
    };

    cargar();
  }, [dpi]);

  const cargarHistoricoEmpleado = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/EmpleadoPlazaLaboral/HistoricoEmpleado/" + dpi;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setHistoricoPuestoPorEmpleado(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const buscarUsuario = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/usuario/buscar/" + dpi;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setDatosEmpleado(request.data.usuarios[0]);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cargarMaestroGerencia = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/Empleado/MaestroGerencia";

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setMaestroGerencia(request.data.data);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cargarMaestroTipoTurno = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/TipoTurno/";

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setMaestroTipoTurno(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cargarMaestroPlazaLaboral = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri =
        "api/PlazaLaboral/PorUnidadOrganizacion/" +
        props.codigoUnidadOrganizacion;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setMaestroPlazaLaboral(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cargarEmpleadoAsignadoPorUnidadOrganizacion = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      const opciones = { year: "numeric", month: "2-digit", day: "2-digit" };
      let uri =
        "api/AnalisisSalarial/AsignadosPorUnidadOrganizacion/" +
        props.codigoUnidadOrganizacion +
        "/" +
        fechaEjecucion
          .toLocaleDateString("es-ES", opciones)
          .replace(/\//g, "-");
      // fechaEjecucion.getFullYear() +
      // "-" +
      // fechaEjecucion.getMonth() +
      // "-" +
      // fechaEjecucion.getDay();

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        if (request.data.response) {
          setListadoEmpleadoAsignadoPorUnidad(request.data.ficha);
        } else {
          toast.error(request.data.mensaje);
          return;
        }
      }
    } catch (e) {
      toast.error("ERROR:" + e);
    } finally {
      handleCerrarBackDrop();
    }
  };
  const cargarEmpleadoAsignado = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/PlazaLaboral/Asignados/" + codigoPlazaLaboral;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setListadoEmpleadoAsignado(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cargarEmpleadoPorPlaza = async () => {
    await cargarEmpleadoAsignado();
  };

  const filtrarPorGerencia = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/Empleado/Gerencia/" + codigoGerencia;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setListadoEmpleadoNoAsignado(request.data.data);
      }
    } catch (e) {
      console.log(e);
    } finally {
      deseleccionarItemsEnGridEmpleados();
      handleCerrarBackDrop();
    }
  };

  const filtrarPorPlaza = async () => {};

  const cuandoHayError = async (data) => {
    toast.info(
      "Existen algunos campos con error, por favor valida el formulario",
      {
        position: "top-right",
        autoClose: 3000, // Esta en milisegundos 3 segundos
      }
    );
  };

  const onExporting = async (e) => {};

  const [dialogoAbierto, abrirDialogo] = useState(false);
  const [dialogoAbiertoEditar, abrirDialogoEditar] = useState(false);
  const handleAbrirDialogoEliminar = (id) => {
    abrirDialogo(true);

    setCodigoEmpleadoPlazaLaboral(id);
  };

  const handleCerrarDialogo = () => {
    abrirDialogo(false);
  };

  const handleAbrirDialogoEditar = (id) => {
    abrirDialogoEditar(true);

    setCodigoGerencia(id);
  };

  const handleSeleccionEmpleado = (fila) => {
    handleOpenModalPuestoLaboral();
    setDpi(fila.DPI);
    setNombreEmpleado(fila.NombreCompleto);
  };

  const handleCerrarDialogoEditar = () => {
    abrirDialogoEditar(false);
  };

  const cellOpciones = (rowInfo) => {
    return (
      <>
        <Tooltip
          title="Ver detalle de empleado"
          style={{ marginRight: 5, cursor: "pointer" }}
        >
          <AssignmentInd
            style={{ color: "#337ab7", cursor: "pointer" }}
            onClick={() => handleSeleccionEmpleado(rowInfo.data)}
          />
        </Tooltip>
      </>
    );
  };

  const cellSemaforo = (rowInfo) => {
    let color = "#8B0000";

    if (rowInfo.data.Semaforo >= 3) {
      color = "#8B0000";
    } else if (rowInfo.data.Semaforo === 2) {
      color = "#FF8C00";
    } else {
      color = "#228B22";
    }
    return (
      <>
        <Tooltip
          title="Ver histórico de puesto laboral"
          style={{ marginRight: 5, cursor: "pointer" }}
        >
          <FiberManualRecord style={{ color: color, cursor: "pointer" }} />
        </Tooltip>
      </>
    );
  };

  const handleAbrirDialogoCrearGestion = () => {
    console.log(datagridEmpleadosSCILU.getSelectedRowsData());
    setempleadosSeleccion(datagridEmpleadosSCILU.getSelectedRowsData());

    abrirDialogoCrearGestion(true);
  };

  const handleCerrarDialogoCrearGestion = () => {
    abrirDialogoCrearGestion(false);
  };

  const generarSolicitud = async () => {
    const data = {
      ListaSolicitudes: empleadosSeleccion,
      UsuarioInserto: userName,
      fechaEjecucion: fechaEjecucion,
      CodigoUnidadOrganizacion: props.codigoUnidadOrganizacion,
      // CodigoGerencia: props.CodigoGerencia,
    };

    console.log(data);
    // dataFicha.usuarioInserto = "prueba";
    try {
      handleAbrirBackDrop();
      let vIsSignedIn = await isSignedIn();
      if (vIsSignedIn.response) {
        let request;

        request = await API.post("api/AnalisisSalarial/Gestion", data);
        if (request.status !== 200) {
          toast.error(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        } else {
          toast.success("Se guardó exitosamente");

          // await NuevaLista();

          setCodigoGerencia(0);
          setCodigoUnidadOrganizacion(0);

          deseleccionarItemsEnGridEmpleados();
        }
      }
    } catch (e) {
      console.log("EXCEPTION: " + e);
      toast.error(
        "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
      );
    } finally {
      handleCerrarDialogoCrearGestion();
      handleCerrarBackDrop();
    }
  };
  //seleccion empleados
  const onInitialized = (e) => {
    datagridEmpleadosSCILU = e.component;
  };

  const deseleccionarItemsEnGridEmpleados = () => {
    datagridEmpleadosSCILU.clearSelection();
  };

  const getEmployeeName = (row) => {
    return `[${row.CodigoAnterior} - ${row.NombreCompleto}]`;
  };

  const getEmployeeNames = (selectedRowsData) => {
    return selectedRowsData.length
      ? selectedRowsData.map(getEmployeeName).join("; ")
      : "No hay empleados seleccionados";
  };

  const onSelectionChanged = (e) => {
    // setSelectedEmployeeNames(getEmployeeNames(e.selectedRowsData));

    // var promesa = new Promise(function (resolve, reject) {
    //   let deselectRowKeys = [];

    //   e.selectedRowsData.forEach((item) => {
    //     if (!isSelectable(item)) deselectRowKeys.push(e.component.keyOf(item));
    //   });
    //   if (deselectRowKeys.length) {
    //     e.component.deselectRows(deselectRowKeys);
    //   }
    //   resolve(console.log("Correcto"));
    // });

    // promesa().then(
    //   setSelectedEmployeeNames(getEmployeeNames(e.selectedRowsData))
    // );

    let deselectRowKeys = [];

    e.selectedRowsData.forEach((item) => {
      if (!isSelectable(item)) deselectRowKeys.push(e.component.keyOf(item));
    });
    if (deselectRowKeys.length) {
      e.component.deselectRows(deselectRowKeys);
    }

    setSelectedEmployeeNames(getEmployeeNames(e.selectedRowsData));
    // setCheckBoxUpdating(true);
    // selectAllCheckBox.option("value", isSelectAll(e.component));
    // setCheckBoxUpdating(false);
  };

  const isSelectable = (item) => {
    return item.Semaforo === 1;
  };

  const isSelectAll = (dataGrid) => {
    let items = [];
    dataGrid
      .getDataSource()
      .store()
      .load()
      .done(function (data) {
        items = data;
      });
    let selectableItems = items.filter(isSelectable);
    let selectedRowKeys = dataGrid.option("selectedRowKeys");
    if (!selectedRowKeys.length) {
      return false;
    }
    return selectedRowKeys.length >= selectableItems.length ? true : undefined;
  };

  const onEditorPreparing = (e) => {
    let dataGrid = e.component;

    if (e.command === "select") {
      // console.log("editorPreparing");
      // console.log(e);
      if (e.parentType === "dataRow" && e.row) {
        if (!isSelectable(e.row.data)) {
          e.editorOptions.disabled = true;
          // e.editorOptions.visible = false;
        }
      } else if (e.parentType === "headerRow") {
        console.log("No se usa el seleccionar todo");
        e.editorOptions.disabled = true;
        // // e.editorOptions.onInitialized = (e) => {
        // setSelectAllCheckBox(e.component);
        // // };
        // e.editorOptions.value = isSelectAll(dataGrid);
        // e.editorOptions.onValueChanged = (e) => {
        //   if (!e.event) {
        //     if (e.previousValue && !checkBoxUpdating) {
        //       e.component.option("value", e.previousValue);
        //     }
        //     return;
        //   }
        //   if (isSelectAll(dataGrid) === e.value) {
        //     return;
        //   }
        //   e.value ? dataGrid.selectAll() : dataGrid.deselectAll();
        //   e.event.preventDefault();
        // };
      }
    }
  };

  const onValueChanged = (e) => {
    setFechaEjecucion(e.value);
  };
  return (
    <React.Fragment>
      <Backdrop className={classes.backdrop} open={buscando}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* Dialogo para confirmar la generación de solicitud */}
      <Dialog
        open={dialogoAbiertoCrearGestion}
        onClose={handleCerrarDialogoCrearGestion}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Crear gestión</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro de iniciar la gestión de nivelación salarial de los
            empleados seleccionados?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCerrarDialogoCrearGestion} color="secondary">
            Cancelar
          </Button>
          <Button onClick={() => generarSolicitud()} color="primary">
            Crear
          </Button>
        </DialogActions>
      </Dialog>
      <Paper className={classes.rootTabla}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <Typography className="dx-field-label">Ejecutar:</Typography>
            <DateBox
              // defaultValue={now}
              value={fechaEjecucion}
              min={now}
              max={fechaMax}
              inputAttr={{ "aria-label": "Fecha de Ejecución: " }}
              type="date"
              onValueChanged={onValueChanged}
            />
            <Button
              // fullWidth
              variant="contained"
              style={{
                backgroundColor: "#69BD4B",
                color: "white",
                alignItems: "center",
                justifyContent: "center",
              }}
              // type="submit"
              size="small"
              onClick={cargarEmpleadoAsignadoPorUnidadOrganizacion}
              startIcon={<AssignmentTurnedIn />}
            >
              Calcular
            </Button>
          </Grid>
        </Grid>

        <DataGrid
          dataSource={listadoEmpleadoAsignadoPorUnidad}
          //   defaultColumns={this.props.columns}
          showBorders={true}
          rowAlternationEnabled={true}
          //ref={(ref) => (dataGrid = ref)}
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          onExporting={onExporting}
          className={classes.table}
          onInitialized={onInitialized}
          onSelectionChanged={onSelectionChanged}
          onEditorPreparing={onEditorPreparing}
          remoteOperations={false}
          height={660}
        >
          <FilterRow visible={showFilterRow} applyFilter={currentFilter} />
          <HeaderFilter visible={showHeaderFilter} />
          <GroupPanel visible={false} />
          <Grouping autoExpandAll={autoExpandAll} />
          <SearchPanel visible={false} width={240} placeholder="Search..." />
          {/* <Selection mode="multiple" /> */}
          <Selection
            mode="multiple"
            selectAllMode="allPages"
            showCheckBoxesMode="always"
          />
          <LoadPanel
            enabled={true}
            shadingColor="rgba(0,0,0,0.4)"
            showIndicator={true}
            shading={true}
            showPane={true}
            closeOnOutsideClick={false}
          />
          <Scrolling mode="virtual" />
          <Editing
            mode="row"
            useIcons={true}
            allowUpdating={false}
            allowDeleting={false}
          />

          <Column
            caption="Perfil"
            allowFiltering={false}
            allowSorting={false}
            cellRender={cellOpciones}
            fixed={true}
            fixedPosition="left"
          />
          <Column
            cellRender={cellSemaforo}
            caption="Semaforo"
            fixedPosition="left"
          />
          <Column
            dataField="CodigoEmpleadoPlazaLaboral"
            caption="Código DB"
            visible={false}
          />

          <Column
            dataField="CodigoAnterior"
            caption="Código"
            fixed={true}
            fixedPosition="left"
          />
          <Column
            dataField="NombreCompleto"
            caption="NombreCompleto"
            fixed={true}
            fixedPosition="left"
          />
          <Column dataField="DPI" caption="DPI" />
          <Column dataField="AgrupacionDePago" caption="Agrupación de Pago" />
          <Column dataField="PuestoLaboral" caption="Puesto Laboral" />
          <Column
            dataField="CumpleEducacionTexto"
            caption="Cumple Educación?"
          />
          <Column
            dataField="CumpleExperienciaTexto"
            caption="Cumple Experiencia?"
          />

          <Column
            dataField="Salario"
            caption="SalarioActual"
            format={monedaFormat}
          />
          <Column
            dataField="NuevoSalario"
            caption="Nuevo Salario"
            format={monedaFormat}
          />
          {/*  */}
          <Column
            dataField="PorcentajeAumentoSalarial"
            caption="Porcentaje de Aumento"
            format={porcentajeFormat}
          />
          <Column dataField="ExpertisActual" caption="Expertis Actual" />
          <Column dataField="ExpertisNueva" caption="Expertis Nueva" />
          <Column
            dataField="ExperienciaRequerida"
            caption="Experiencia Requerida"
          />
          <Column
            dataField="ExperienciaEmpleado"
            caption="Experiencia Empleado"
          />
          <Column
            dataField="ExperienciaFaltante"
            caption="Experiencia Faltante"
          />
          <Column dataField="Comentario" caption="Comentario" />
          <Column dataField="FechaEjecucion" caption="FechaEjecucion" />
          <ColumnFixing enabled={true} />
          <Sorting mode="multiple" />
          {/* <Paging defaultPageSize={20} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[10, 20, 50]}
            showInfo={true}
          /> */}
          <Export enabled={true} allowExportSelectedData={true} />
          {/* <Scrolling columnRenderingMode="virtual" /> */}
        </DataGrid>
        <div className={classes.selectedData}>
          <span className={classes.selectedDataCaption}>
            Empleados seleccionados:
          </span>{" "}
          <span>{selectedEmployeeNames}</span>
        </div>
        <Typography variant="h6">Acciones</Typography>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {/* <input type="submit" /> */}
          <Tooltip title="Inicias gestión para nivelación ">
            <Button
              // fullWidth
              variant="contained"
              style={{
                backgroundColor: "#69BD4B",
                color: "white",
                alignItems: "center",
                justifyContent: "center",
              }}
              // type="submit"
              size="small"
              onClick={handleAbrirDialogoCrearGestion}
              startIcon={<AssignmentTurnedIn />}
            >
              Iniciar Nivelación
            </Button>
          </Tooltip>
        </Grid>
      </Paper>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openPL}
        onClose={handleCloseModalPuestoLaboral}
        closeAfterTransition
        BackdropComponent={BackdropModal}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openPL}>
          <div className={classes.paperModal}>
            {/* <h2 id="simple-modal-title">Expediente Laboral</h2> */}

            <Paper className={classes.rootTabla}>
              <AppBar
                position="sticky"
                className={classes.appBar}
                style={{ backgroundColor: titleColor }}
              >
                <Toolbar>
                  <Tooltip
                    title={"Cerrar el perfil del empleado"}
                    aria-label="Cerrar el perfil del empleado"
                  >
                    <IconButton
                      aria-label="Cerrar el perfil del empleado"
                      style={{ color: "white" }}
                    >
                      <CancelOutlined onClick={handleCloseModalPuestoLaboral} />
                    </IconButton>
                  </Tooltip>

                  <Typography
                    variant="h6"
                    noWrap
                    style={{ display: "flex", flexGrow: 1 }}
                  >
                    {`Análisis Salarial: ` + nombreEmpleado}
                  </Typography>
                </Toolbar>
              </AppBar>
              {dpi !== "" && <PerfilComponente dpi={dpi} />}
            </Paper>
          </div>
        </Fade>
      </Modal>
    </React.Fragment>
  );
};

export default EmpleadoPlazaLaboral;
