import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Save";

//Own Componentes
import "../css/add-button-float.css";

const styles = theme => ({
  fab: {
    margin: theme.spacing.unit
  }
});

function FloatingActionButtons(props) {
  const { classes } = props;
  return (
    <div className="FloatBottomDiv" onClick={props.onClick}>
      <Fab
        style={{ backgroundColor: "#2196F3", color: "white" }}
        aria-label="Add"
        className={classes.fab}
      >
        <AddIcon />
      </Fab>
    </div>
  );
}

export default withStyles(styles)(FloatingActionButtons);
