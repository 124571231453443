import Container from "@material-ui/core/Container";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
//Scroll bar
import { Grid } from "@material-ui/core";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

//Own components
import AttachmentIcon from "@material-ui/icons/Attachment";
import Axios from "axios";
import { Chip } from "material-ui";
import ConfirmDialog from "../../ConfirmDialog/ConfirmDialog";
import ButtonAdd from "../../helpers/components/button-add";
import GenericForm from "../../helpers/components/generic-form";
import api from "../../utils/api";
import { isSignedIn } from "../../utils/auth";

export default function DataGrid(props) {
  const [confirmTexto, setConfirmTexto] = useState("");
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [objectToDelete, setObjectToDelete] = useState({});
  const [openFormPanel, setOpenFormPanel] = useState(false);
  const [openAttachmentPanel, setOpenAttachmentPanel] = useState(false);
  const [codigoFormDeCampo, setCodigoFormDeCampo] = useState(null);
  const [codigoCampo, setCodigoCampo] = useState(null);
  const [filasCustom, setFilasCustom] = useState([]);
  const [adjuntosList, setAdjuntosList] = useState([]);
  // const [gridData, setGridData] = React.useState({
  // columns: [
  //   {
  //     title: "Nombre",
  //     field: "Nombre",
  //   },
  //   {
  //     title: "Activo",
  //     field: "Activo",

  //   },
  //   {
  //     title: "CodigoPlantilla",
  //     field: "CodigoPlantilla",
  //     hidden: true,
  //   },

  // ],
  //   columns: props.columns?[...props.columns]:[],
  //     data: props.rows?[...props.rows]:[],
  // });

  //Equivalente a didmount
  useEffect(() => {
    setCodigoFormDeCampo(props.codigoFormDeCampo);
    setCodigoCampo(props.codigoCampo);
  }, []);

  useEffect(() => {
    setCodigoFormDeCampo(props.codigoFormDeCampo);
    setCodigoCampo(props.codigoCampo);
    console.log("GRIDCOLUMNS", props.columns);
    console.log("GRIDROWS", props.rows);
  }, [props.rows, props.columns, props.codigoFormDeCampo, props.codigoCampo]);

  useEffect(() => {
    let filas = [...props.rows];
    //Obtener columnas tipo hora
    const horas = props.columns.filter((x) => x.tipoCampo === 14);
    console.log("horas", horas);
    const titulos = horas.forEach((campoHora, i) => {
      console.log("label", campoHora.title);
      console.log("hora pura", campoHora);

      //reemplaza valor de campos de fecha de cada fila
      //Por alguna razón espera que siempre haya un valor en horas y minutos. En campos opcionales no funciona, fila no tiene la propiedad. Validar...
      filas.forEach((fila, i) => {
        console.log("fila con error: ", fila);

        if (fila[campoHora.title] !== null) {
          if (
            fila[campoHora.title] &&
            typeof fila[campoHora.title] === "object"
          ) {
            if (
              fila[campoHora.title].hasOwnProperty("horas") &&
              fila[campoHora.title].hasOwnProperty("minutos")
            ) {
              fila[campoHora.title] = `${fila[campoHora.title].horas}: ${
                fila[campoHora.title].minutos
              }`;
            }
          }
        }
      });
    });
    console.log("FILAS CON HORA DE A HUEVO", filas);
    setFilasCustom(filas);
  }, [props.rows]);

  const remover = async () => {
    await props.removerFila(objectToDelete, codigoFormDeCampo, codigoCampo);
    setObjectToDelete({});
  };

  const editar = (codigo) => {
    props.editar(codigo);
  };

  const redireccionar = (codigo) => {
    props.redireccionar(codigo);
  };

  const handleCloseFormPanel = () => {
    setOpenFormPanel(false);
  };

  const handleOpenFormPanel = () => {
    setOpenFormPanel(true);
  };

  const confirmarAccion = (obj) => {
    setConfirmTexto(
      `¿Confirma que desea eliminar este registro? esta acción no podrá revertirse.`
    );
    setConfirmOpen(true);
    setObjectToDelete(obj);
  };

  const funcionOk = () => {
    remover();
  };

  const cerrarConfirm = () => {
    setConfirmTexto("");
    setConfirmOpen(false);
  };

  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
      zIndex: 800000,
    };
  }

  const verAdjuntos = async (rowData) => {
    console.log("rowData", rowData.Codigo);
    setOpenAttachmentPanel(true);
    const req = await api.fetchGetRequest(
      `/api/formulario/getFileListGrid/${rowData.Codigo}`
    );
    if (req.data.response) {
      let nombresCamposAdjuntos = [];
      req.data.data.forEach((adjunto, i) => {
        //verifica si ya existe un campo con ese nombre
        let index = nombresCamposAdjuntos.findIndex(
          (x) => x.nombreCampo === adjunto.nombreCampo
        );
        if (index < 0) {
          nombresCamposAdjuntos.push({
            nombreCampo: adjunto.nombreCampo,
            items: [adjunto],
          });
        } else {
          nombresCamposAdjuntos[index].items = [
            ...nombresCamposAdjuntos[index].items,
            adjunto,
          ];
        }
      });
      setAdjuntosList(nombresCamposAdjuntos);
    } else {
      setAdjuntosList([]);
    }
  };

  const handleCloseAttachmentPanel = () => {
    setOpenAttachmentPanel(false);
  };

  const RenderAdjunto = ({ adjuntos }) =>
    adjuntos.map((adjunto) => (
      <Chip
        icon={<AttachmentIcon />}
        label={adjunto.nombreArchivo.substring(0, 20) + "..."}
        onClick={() => {
          // window.open(
          //   adjunto.path,
          //   "Archivo adjunto",
          //   "width=300, height=200"
          // );
          newTabAuth({
            fileName: adjunto.nombreArchivo,
            key: "IMAGE_REPOSITORY_FORMULARIO",
          });
        }}
        // onDelete={() => {
        //   props.eliminarAdjuntos(adjunto.IdObjeto);
        // }}
        color="primary"
        style={{ marginRight: 5 }}
      />
    ));

  async function newTabAuth({ fileName, key }) {
    const session = await isSignedIn();
    const baseApi = await api.baseApi();

    Axios({
      url: `${baseApi}api/media/descargar?key=${key}&fileName=${fileName}`, //your url
      method: "GET",
      headers: { Authorization: "bearer " + session.accessToken },
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  }

  const useStyles = makeStyles((theme) => ({
    container: {
      display: "flex",
      flexWrap: "wrap",
      marginTop: 15,
    },
    textField: {
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit,
      width: "100%",
    },
    paper: {
      position: "absolute",
      width: "100%",
      height: "100%",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing.unit * 2,
      outline: "none",
    },
    paperUserModal: {
      position: "absolute",
      width: "75%",
      height: "600px",
      maxHeight: "600px",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing.unit * 2,
      outline: "none",
    },
    paperModal: {
      position: "absolute",
      width: "90%",
      height: "600px",
      maxHeight: "600px",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing.unit * 2,
      outline: "none",
    },
    paperNuevoForm: {
      position: "absolute",
      width: "50%",
      height: "300px",
      maxHeight: "300px",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing.unit * 2,
      outline: "none",
    },
    bigPaper: {
      position: "absolute",
      width: "95%",
      height: "95%",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing.unit * 2,
      outline: "none",
    },
    buttonAdd: {
      margin: theme.spacing.unit,
    },
  }));

  const classes = useStyles();
  return (
    <Container>
      <MaterialTable
        title={
          props.editable !== false && (
            <ButtonAdd
              style={{ width: 36, height: 36 }}
              onClick={async () => {
                await props.incrementarCodigoLocal();
                handleOpenFormPanel();
              }}
            />
          )
        }
        columns={[...props.columns]}
        // data={[...props.rows]}
        data={[...filasCustom]}
        editable={
          {
            // onRowAdd: (newData) =>
            //   new Promise((resolve) => {
            //     setTimeout(() => {
            //       resolve();
            //       setState((prevState) => {
            //         const data = [...prevState.data];
            //         data.push(newData);
            //         return {
            //           ...prevState,
            //           data,
            //         };
            //       });
            //     }, 600);
            //   }),
            // onRowUpdate: (newData, oldData) =>
            //   new Promise((resolve) => {
            //     setTimeout(() => {
            //       resolve();
            //       if (oldData) {
            //         setState((prevState) => {
            //           const data = [...prevState.data];
            //           data[data.indexOf(oldData)] = newData;
            //           return {
            //             ...prevState,
            //             data,
            //           };
            //         });
            //       }
            //     }, 600);
            //   }),
            // onRowDelete: (oldData) =>
            //   new Promise((resolve, reject) => {
            //     setTimeout(() => {
            //       // const dataDelete = [...data];
            //       // const index = oldData.tableData.id;
            //       // dataDelete.splice(index, 1);
            //       // setData([...dataDelete]);
            //       console.log(oldData.CodigoPlantilla);
            //       remover(oldData.CodigoPlantilla);
            //       resolve();
            //     }, 1000);
            //   }),
          }
        }
        actions={[
          // {
          //   icon: 'edit',
          //   tooltip: 'Editar',
          //   onClick: (event, rowData) => {
          //     editar(rowData.CodigoPlantilla);
          //                 }
          // },
          {
            icon: "delete",
            tooltip: "Eliminar",
            disabled: props.editable === false ? true : false,
            onClick: (event, rowData) => {
              confirmarAccion(rowData);
            },
          },
          {
            icon: "attachment",
            tooltip: "Adjuntos",
            disabled: props.showAttachment === false ? true : false,
            onClick: (event, rowData) => {
              verAdjuntos(rowData);
            },
          },
        ]}
      />
      <div style={{ display: "flex", justifyContent: "flex-end" }}></div>

      <ConfirmDialog
        id="confirmar"
        keepMounted
        open={confirmOpen}
        onClose={cerrarConfirm}
        value=""
        texto={confirmTexto}
        okfunction={funcionOk}
      />

      <Modal
        // style={{ zIndex: 2990 }}
        aria-labelledby="Seleccionar un formulario"
        aria-describedby="Seleccionar un formulario"
        open={openFormPanel}
        onClose={handleCloseFormPanel}
      >
        <div style={getModalStyle()} className={classes.bigPaper}>
          <PerfectScrollbar>
            <form className={classes.container} noValidate autoComplete="off">
              <Grid container spacing={1}>
                <Grid item xs={12} lg={12} sm={12}>
                  {codigoFormDeCampo === "" ||
                  codigoFormDeCampo === null ||
                  codigoFormDeCampo === undefined ? (
                    <div>No hay datos para mostrar</div>
                  ) : (
                    <GenericForm
                      CodigoFormDeCampo={codigoFormDeCampo}
                      CodigoCampoBase={codigoCampo}
                      grabarFila={props.grabarFila}
                      closeModal={handleCloseFormPanel}
                      CodigoLocal={props.codigoLocal}
                      CodigoGrid={props.codigoGrid}
                    />
                  )}
                </Grid>
              </Grid>
            </form>
          </PerfectScrollbar>
        </div>
      </Modal>

      <Modal
        // style={{ zIndex: 2990 }}
        aria-labelledby="Adjuntos"
        aria-describedby="Adjuntos"
        open={openAttachmentPanel}
        onClose={handleCloseAttachmentPanel}
      >
        <div style={getModalStyle()} className={classes.bigPaper}>
          <PerfectScrollbar>
            <form className={classes.container} noValidate autoComplete="off">
              <h1
                style={{
                  fontWeight: "bold",
                  marginTop: 10,
                  marginBottom: 25,
                  fontSize: "2.2em",
                  textAlign: "center",
                  alignItems: "center",
                  justifyItems: "center",
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                Campos con archivos adjuntos
              </h1>
              <Grid container spacing={1}>
                <Grid item xs={12} lg={12} sm={12}>
                  {adjuntosList.length < 1 ? (
                    <div
                      style={{
                        fontWeight: "bold",
                        marginTop: 10,
                        fontSize: "2em",
                      }}
                    >
                      No hay datos para mostrar
                    </div>
                  ) : (
                    adjuntosList.map((CampoAdjunto, i) => (
                      <>
                        <h3
                          style={{
                            fontWeight: "bold",
                            marginTop: 10,
                            fontSize: "1.5em",
                          }}
                        >
                          {CampoAdjunto.nombreCampo}
                        </h3>
                        <RenderAdjunto adjuntos={CampoAdjunto.items} />
                      </>
                    ))
                  )}
                </Grid>
              </Grid>
            </form>
          </PerfectScrollbar>
        </div>
      </Modal>
    </Container>
  );
}
