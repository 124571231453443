export const getUsuarioInserta = () => {
  const usuario = JSON.parse(localStorage.getItem('userData'))
  return usuario && usuario.usuarioAD ? usuario.usuarioAD : null
}
export const getCodigoUsuario = () => {
  const usuario = JSON.parse(localStorage.getItem('userData'))
  return usuario && usuario.codigoUsuario ? usuario.codigoUsuario : null
}

export const getNombreUsuario = () => {
  const usuario = JSON.parse(localStorage.getItem('userData'))
  return usuario && usuario.nombreCompletoUsuario ? usuario.nombreCompletoUsuario : '--'
}

export const range = (start, end) => {
  return (new Array(end - start + 1)).fill(undefined).map((_, i) => i + start);
}

export const getDispositivo = () => {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return 'Mobile'
  } else if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(navigator.userAgent)) {
    return 'Tablet'
  } else {
    return 'Desktop'
  }
}

export const downloadCSV = (arrayDeObjetos, nombreArchivo, mapeoColumnas = {}) => {
  const separador = ',';
  const lineas = [];

  // Encabezado CSV
  const encabezado = Object.keys(arrayDeObjetos[0])
    .map(columna => mapeoColumnas[columna] || columna) // Aplicar mapeo si está presente
    .join(separador);
  lineas.push(encabezado);

  // Datos CSV
  arrayDeObjetos.forEach(objeto => {
    const valores = Object.keys(objeto).map(columna => {
      const valor = objeto[columna];
      if (typeof valor === 'string') {
        return `"${valor}"`;
      } else {
        return valor;
      }
    });
    lineas.push(valores.join(separador));
  });

  // Crear contenido CSV
  const contenidoCSV = lineas.join('\n');

  // Crear un Blob con el contenido y descargar el archivo
  const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), contenidoCSV], { type: 'text/csv;charset=utf-8' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = nombreArchivo + '.csv';
  link.click();
}

export const formatoCorrelativo = (correlativo) => {
  let tmp = "XXX";
  const codigo = correlativo.split("-");
  const num = parseInt(codigo[codigo.length - 1]);
  if (num >= 0 && num <= 9) {
    tmp = `00${num}`;
  } else if (num > 9 && num <= 99) {
    tmp = `0${num}`;
  } else if (num > 99) {
    tmp = num;
  }
  codigo[codigo.length - 1] = tmp;
  return codigo.join("-")
}
