import React from "react";

import ArrayStore from "devextreme/data/array_store";
import PivotGridDataSource from "devextreme/ui/pivot_grid/data_source";

import Chart, {
  AdaptiveLayout,
  CommonSeriesSettings,
  Size,
  Tooltip,
} from "devextreme-react/chart";
import PivotGrid, { FieldChooser, Export } from "devextreme-react/pivot-grid";
import CustomStore from "devextreme/data/custom_store";
import { createStore } from "devextreme-aspnet-data-nojquery";

import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";

// import { sales } from "./data.js";

let store = null;

const serviceUrl = "http://localhost:49902/api/devextremewidgets";
const pivotGridDataSource = new PivotGridDataSource({
  store: createStore({
    key: "IdObjeto",
    loadUrl: serviceUrl + "/procesosgestion1",
  }),
});

class Pivot extends React.Component {
  state = {
    store: [],
  };

  componentDidMount() {
    this._pivotGrid.bindChart(this._chart, {
      dataFieldsDisplayMode: "splitPanes",
      alternateDataFields: false,
    });
    setTimeout(function () {
      dataSource.expandHeaderItem("row", ["North America"]);
      dataSource.expandHeaderItem("column", [2013]);
    });
  }

  // componentDidUpdate() {
  //   if (this.state.store !== this.props.data) {
  //     this.setState({ store: this.props.data });
  //     store = new ArrayStore(this.props.data);
  //     console.log(store);
  //   }
  // }

  render() {
    return (
      <React.Fragment>
        <Chart ref={(ref) => (this._chart = ref.instance)}>
          <Size height={200} />
          <Tooltip enabled={true} customizeTooltip={customizeTooltip} />
          <CommonSeriesSettings type="bar" />
          <AdaptiveLayout width={450} />
        </Chart>

        <PivotGrid
          id="pivotgrid"
          dataSource={pivotGridDataSource}
          allowSortingBySummary={true}
          allowFiltering={true}
          showBorders={true}
          showColumnTotals={false}
          showColumnGrandTotals={false}
          showRowTotals={false}
          showRowGrandTotals={false}
          ref={(ref) => (this._pivotGrid = ref.instance)}
        >
          <Export enabled={true} fileName="PivotTable" />
          <FieldChooser enabled={true} height={400} />
        </PivotGrid>
      </React.Fragment>
    );
  }
}

const dataSource = new PivotGridDataSource({
  // fields: this.props.fields,
  fields: [
    {
      caption: "IdObjeto",
      width: 120,
      dataField: "IdOBjeto",
      area: "row",
      // sortBySummaryField: "Total",
    },
    // {
    //   caption: "City",
    //   dataField: "city",
    //   width: 150,
    //   area: "row",
    // },
    // {
    //   dataField: "date",
    //   dataType: "date",
    //   area: "column",
    // },
    // {
    //   groupName: "date",
    //   groupInterval: "month",
    //   visible: false,
    // },
    // {
    //   caption: "Total",
    //   dataField: "amount",
    //   dataType: "number",
    //   summaryType: "sum",
    //   format: "currency",
    //   area: "data",
    // },
  ],
  store: store,
});

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "GTQ",
  minimumFractionDigits: 0,
});

function customizeTooltip(args) {
  const valueText = currencyFormatter.format(args.originalValue);
  return {
    html: `${args.seriesName} | Total<div class="currency">${valueText}</div>`,
  };
}

export default Pivot;
