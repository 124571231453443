import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Chip, Grid } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import SettingsIcon from "@material-ui/icons/Settings";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import MaskedInput from "react-text-mask";
//Select
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";

//Checkbox
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
//import "../components/styles.css";
import { arrayMoveControls } from "../../utils/dndUtils";

//Drawer
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import ConfirmDialog from "../../ConfirmDialog/ConfirmDialog";

//Tabs
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import FormLabel from "@material-ui/core/FormLabel";

//Badge
import Badge from "@material-ui/core/Badge";

//Scroll bar
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
//Radio
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

//Autocomplete
import Autocomplete from "@material-ui/lab/Autocomplete";

//Lista Etiquetas
//import TagList from "../../avisos-objects/components/tag-list";

//Own components
import Layout from "../../layout/containers/Layout";
import API from "../../utils/api";
import { isSignedIn } from "../../utils/auth";
import {
  convertirASegundos,
  convertYMD,
  convertirHHMM,
} from "../../utils/helpers";
import plantillaIcon from "../../image-repository/plantillaIcon.svg";
//import TareaProblemaCard from "../components/tarea-problema-card";
//import UsuariosList from "../components/usuarios-list";
//import FormList from "../components/form-list";
import { validaPermisoPaginaActual } from "../../utils/helpers";
import { consultarPermiso } from "../../utils/helpers";

import FormDesigner from "../../form-designer/container/form-designer";
//import ActionMenuFormTab from "../components/action-menu-form-tab";

//Inputs Formulario
//import TaskTree from "../components/tasktree";

const styles = (theme) => ({
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    textField: {
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit,
      width: "100%",
    },
    paper: {
      position: "absolute",
      width: "100%",
      height: "100%",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing.unit * 2,
      outline: "none",
    },
    paperModal: {
      position: "absolute",
      width: "90%",
      height: "600px",
      maxHeight: "600px",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing.unit * 2,
      outline: "none",
    },
  
    paperUserModal: {
      position: "absolute",
      width: "75%",
      height: "600px",
      maxHeight: "600px",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing.unit * 2,
      outline: "none",
    },
    paperNuevoForm: {
      position: "absolute",
      width: "50%",
      height: "300px",
      maxHeight: "300px",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing.unit * 2,
      outline: "none",
    },
    buttonAdd: {
      margin: theme.spacing.unit,
    },
  });


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };


const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        flexWrap: "wrap",
      },
      textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: "100%",
      },
      paperPerfil: {
        position: "relative",
        width: "100%", //theme.spacing.unit * 50,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
        outline: "none",
      },
      paper: {
        position: "absolute",
        width: theme.spacing.unit * 100,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
        outline: "none",
      },
      buttonAdd: {
        margin: theme.spacing.unit,
      },
      small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
      },
      large: {
        width: theme.spacing(12),
        height: theme.spacing(12),
      },
      paperCard: {
        padding: 20,
        width: "100%",
      },
      box: {
        position: "relative",
        width: "100%", //theme.spacing.unit * 50,
        padding: theme.spacing.unit * 4,
        outline: "none",
      },
  }));


// const theme = useTheme();

class SkillNuevaDetalle extends Component {
  state = {
    nombre: "",
    descripcion: "",
    activo: true,
    codigoCompetencia: null,

    listaTipos: [],
    listaTiposSelected: [],

    listaEtiquetas: [],
    listaEtiquetasSelected: [],
  };

  

  async componentDidMount() {
    //CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
    const llaveAgrupacion = "Menu.Principal.Web";
    const llavePagina = "Menu.Principal.Web.Aprendizaje";
    await validaPermisoPaginaActual(llaveAgrupacion, llavePagina);
    //FIN DE CONSULTA DE PERMISOS
    let codigoCompetencia = null

    if (
      this.props.match.params.codigoCompetencia === null ||
      this.props.match.params.codigoCompetencia === undefined
    ) {
        codigoCompetencia = null
        console.log("esto es nuevo completamente")
    } else if (!isNaN(this.props.match.params.codigoCompetencia)) {
      codigoCompetencia = parseInt(this.props.match.params.codigoCompetencia)
      console.log("Ya existe esta bonita skill")
    }
    
    this.setState(
        { codigoCompetencia: codigoCompetencia },
        async () => {
            await this.cargarTipoSkill();  
            await this.cargarDatosCompetencia();
          this.cargarListaEtiquetas();

        }
      );
  }

  async cargarTipoSkill() {
    const request = await API.fetchGetRequest(`api/competencia/tiposkill/listar`);
    if (Array.isArray(request.data.data)) {
        console.log("Estos son los tipos de skill",request.data)
      this.setState({
        listaTipos: request.data.data,
      });
    }
  }

  async cargarListaEtiquetas() {
    const request = await API.fetchGetRequest(`api/competencia/etiqueta/listar`);
    if (Array.isArray(request.data.data)) {
      this.setState({
        listaEtiquetas: request.data.data,
      });
    }
  }


  async cargarDatosCompetencia() {
    console.log("Así está el state",this.state.codigoCompetencia)
    if(this.state.codigoCompetencia!=null){
        const request = await API.fetchGetRequest(
        "api/competencia/" + this.state.codigoCompetencia
        );
        console.log("el request",request)
        let tipos = []
        if (Array.isArray(request.data.data.Tipo)) {
            request.data.data.Tipo.forEach(element => {
                tipos.push(element.CodigoTipoSkill)
            });
          }
        this.setState({
            nombre: request.data.data.Nombre,
            descripcion: request.data.data.Descripcion,
            activo: request.data.data.Activo,
            listaEtiquetasSelected: request.data.data.Etiquetas,
            listaTiposSelected: tipos,
        });
    }else{
        this.setState({
            nombre: "",
            descripcion: "",
            activo: true,
        });
    }
  }


  handleChangeCompetencia = (name) => (event) => {
    this.setState({
       [name]: event.target.value
    });
  };

  actualizarCompetencia = async (item) => {
    const {
      nombre,
      descripcion,
      activo,
      codigoCompetencia,
  
      listaTipos,
      listaTiposSelected,
  
      listaEtiquetas,
      listaEtiquetasSelected,
    } = this.state;
    if (nombre.trim() == "") {
      toast.warn("Ingrese un nombre válido");
      return;
    }
    
    if (listaTiposSelected.length == 0) {
        toast.warn("Debe seleccionar al menos un tipo para la competencia");
        return;
      }

    try {
      let vIsSignedIn = await isSignedIn();
      if (vIsSignedIn.response) {
        let request;
        let data;

        data = {
          codigoCompetencia: this.state.codigoCompetencia,
          Nombre: this.state.nombre,
          Descripcion: this.state.descripcion,
          Activo: this.state.activo,
          Eliminado: false,
          UsuarioOpera: vIsSignedIn.userName,
          Etiquetas: listaEtiquetasSelected,
          TiposSkill: listaTiposSelected
        };
        console.log("Esto es lo que voy a mandar",data)
        request = await API.post(`api/competencia/grabarEditarCompetencia`, data);

        if (request.status != 200) {
          alert(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        }

        if (request.statusCode === 401) {
          //alert("cerrar sesion");
        } else {
          toast.success("El registro se actualizó exitosamente.");
          window.location = "/lista-competencias/"
        }
      }
    } catch (e) {
      alert(e);
    }
  };

  handleChangeTipoSkill = (CodigoTipoSkill) => {
    let selected = this.state.listaTiposSelected
    if(selected.includes(CodigoTipoSkill)){
        let index = selected.indexOf(CodigoTipoSkill)
        selected.splice(index,1)
    }else{selected.push(CodigoTipoSkill)}
    console.log("Asi quedan los selected",selected)
    this.setState({
      listaTiposSelected: selected,
    });
  };

  

  

  render() {
    const { classes }  = this.props;
    const { items } = this.state;
    
    return (
      <Layout titulo="Nueva Competencia">
          <Grid container justify = "center">
        <TabPanel value={0} index={0}>
        <Paper className={classes.rootTab} style={{ width: "80%" }}>
        <div style={{ width: "100% !important" }}>
        <Grid container spacing={10}>
                <Grid item xs={12} sm={12} md={8} lg={8}>
                  <form
                    className={classes.container}
                    noValidate
                    autoComplete="off"
                  >
                    <Grid container spacing={4}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextField
                          required
                          id="txtNombre"
                          label="Nombre"
                          className={classes.textField}
                          value={this.state.nombre}
                          onChange={this.handleChangeCompetencia("nombre")}
                          margin="normal"
                          inputProps={{ maxLength: 512 }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextField
                          id="txtDescripcion"
                          label="Descripción"
                          className={classes.textField}
                          value={this.state.descripcion}
                          onChange={this.handleChangeCompetencia("descripcion")}
                          margin="normal"
                          multiline
                          inputProps={{ maxLength: 512 }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        {this.state.listaTipos.map((item, i) => (
                                <FormControlLabel
                                control={
                                    <Checkbox
                                    checked={this.state.listaTiposSelected.includes(item.CodigoTipoSkill)}
                                    onChange={() => this.handleChangeTipoSkill(item.CodigoTipoSkill)}
                                    value={item.CodigoTipoSkill}
                                    color="primary"
                                    />
                                }
                                label={item.Nombre}
                                />
                            ))}
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Autocomplete
                                multiple
                                id="checkboxes-tags-demo"
                                value = {this.state.listaEtiquetasSelected}
                                options={this.state.listaEtiquetas}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.Nombre}
                                getOptionSelected={(option, value) => value.CodigoEtiqueta === option.CodigoEtiqueta}
                                onChange={(_, selectedOptions) => this.setState({listaEtiquetasSelected: selectedOptions})}
                                style={{ width: 500 }}
                                renderInput={(params) => (
                                    <TextField {...params} label="Etiquetas" placeholder="Etiquetas para competencia" />
                                )}
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                      <Checkbox
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                      />
                                      {option.Nombre}
                                    </React.Fragment>
                                  )}
                                />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Button
                            variant="contained"
                            style={{
                              backgroundColor: "#69bd4b",
                              color: "white",
                            }}
                            className={classes.buttonAdd}
                            onClick={() => this.actualizarCompetencia()}
                          >
                            Guardar
                          </Button>
                        </Grid>
                    </Grid>

                  </form>
                </Grid>
                
              </Grid>
              </div>
              
        </Paper>
        
        </TabPanel>
        </Grid>
      </Layout>
    );
  }
}
const SkillNuevaDetalleContainer = withStyles(styles)(
    SkillNuevaDetalle
);
export default withRouter(SkillNuevaDetalleContainer);
