import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import Drawer from "@material-ui/core/Drawer";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Mensaje from "../../helpers/components/message";
import {
  FloatingMenu,
  MainButton,
  ChildButton,
} from "react-floating-button-menu";
import AddIcon from "@material-ui/icons/Add";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import ClearIcon from "@material-ui/icons/Clear";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";
import AddAlertIcon from "@material-ui/icons/AddAlert";
import PersonIcon from "@material-ui/icons/Person";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Loader from "react-loader-spinner";
import Popover from "@material-ui/core/Popover";
import MaskedInput from "react-text-mask";
import PropTypes from "prop-types";
import esLocale from "date-fns/locale/es";
import { DatePicker, MuiPickersUtilsProvider, Day } from "material-ui-pickers";
import DateFnsUtils from "@date-io/date-fns";
import { DropzoneArea } from "material-ui-dropzone";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Alert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";
import Paper from "@material-ui/core/Paper";
import NotificationsIcon from "@material-ui/icons/Notifications";
import AssignmentIcon from "@material-ui/icons/Assignment";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Container from "@material-ui/core/Container";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SwipeableViews from "react-swipeable-views";
import Box from "@material-ui/core/Box";
import { useTheme } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";

//Inputs Formulario
import InputHora from "../../avisos-objects/components/input-hora";
import Separador from "../../avisos-objects/components/separador";

//Scroll bar
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import UsuariosList from "../../plantillaProblema/components/usuarios-list";

//Appbar
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import InputBase from "@material-ui/core/InputBase";
import { fade, makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";

//Own components
import Layout from "../../layout/containers/Layout";
import ObjetosTabla from "../../ejecucion-avisos/components/objetos-tabla";
import AddButton from "../../helpers/components/add-button-float";
import API from "../../utils/api";
import { isSignedIn, signOut } from "../../utils/auth";
import { convertYYYYMMDDToDate, convertirHHMM } from "../../utils/helpers";
import { convertirASegundos, convertToYearMonthDay } from "../../utils/helpers";
import CardTarea from "../../ejecucion-avisos/components/card-tarea";
import { validaPermisoPaginaActual } from "../../utils/helpers";

//CSS
import "../../ejecucion-avisos/components/gestion-style.css";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

//const theme = useTheme();

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  paperUserModal: {
    position: "absolute",
    width: "75%",
    height: "600px",
    maxHeight: "600px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  popover: {
    pointerEvents: "none",
  },
  paperPopover: {
    padding: theme.spacing(1),
    background: "#000",
    color: "#fff",
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
});

const ListaTareas = (props) => {
  //   state = {
  //     tipo: 0,
  //     lista: [],
  //     pendientes: [],
  //     terminados: [],
  //     value: 0,
  //     openDrawer: false,
  //     cargando: true,
  //   };

  const [tipo, setTipo] = useState(0);
  const [lista, setLista] = useState([]);
  const [pendientes, setPendientes] = useState([]);
  const [terminados, setTerminados] = useState([]);
  const [value, setValue] = useState(0);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [cargando, setCargando] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const cargar = async () => {
      // CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
      const llaveAgrupacion = "GESPILU";
      const llavePagina = "GESPILU.Organización.Acceso";
      await validaPermisoPaginaActual(llaveAgrupacion, llavePagina);

      // FIN DE CONSULTA DE PERMISOS

    //   handleAbrirBackDrop();

      await makeRequesPendientes();

    //   handleCerrarBackDrop();
    };

    cargar();
  }, []);

  const recargar = async () => {
    makeRequesPendientes();
  };

  const makeRequesPendientes = async () => {
    //Obtenemos los filtros del storage, de lo contrario dejamos el default.
    let direccion = "asc";
    let filtroPorReq = "fecha-creacion";
    const filtrosExtras = {codigoAviso: props.codigoAviso};
    let uri;
    try {
      const session = await isSignedIn();
      uri = `api/aviso/tareaActualPorGestion/${props.codigoAviso}`;

      const request = await API.fetchGetRequest(uri);

      if (request.statusCode === 401) {
        if (await signOut()) {
          window.location.href = "/login";
        }
        return;
      }

      if (request.data.response) {
        //OK
        
          setTerminados(request.data.data); //request.data.asignados,
          setIsLoading(false);
          setError(false);
          setCargando(false);
    
      } else {
        //El API alerta error.
        setIsLoading(false);
        setError(false);
        setPendientes([]);
      }
    } catch (e) {
      console.log("error", e);
      //Hubo error en el request al server
      setIsLoading(false);
        setError(true);
    }
  };

  const handleChange = (name) => (event) => {
    if (name == "activo") {
    //   setName(event.target.checked);
    } else {
    //   setName(event.target.value);
    }
  };

  const onClickAcept = async (tarea) => {
    const session = await isSignedIn();
    let objectRequest = {};
    objectRequest.CodigoUsuario = session.codigoUsuario;
    objectRequest.CodigoEstadoTarea = 3;
    objectRequest.Comentario = null;
    await postAvisoTarea(objectRequest, tarea);
    recargar();
  };

  const onClickCancel = async (tarea) => {
    const session = await isSignedIn();
    let objectRequest = {};
    objectRequest.CodigoUsuario = session.codigoUsuario;
    objectRequest.CodigoEstadoTarea = 4;
    objectRequest.Comentario = null;
    await postAvisoTarea(objectRequest, tarea);
    recargar();
  };

  const onClickRuta = async (tarea) => {
    let codigoAvisoTarea = tarea;
    let uri = `api/aviso/tarea/obtenerRutaTarea/${codigoAvisoTarea}`;

    const request = await API.fetchGetRequest(uri);
    console.log(request);
  };

  const postAvisoTarea = async (objectRequest, codigoTarea) => {
    try {
      const uri = `api/aviso/tarea/${codigoTarea}`;
      let objects = objectRequest;
      console.log("url: " + uri);
      const request = await API.post(uri, objects);
      if (request.statusCode === 401) {
        if (await signOut()) {
          window.location.href = "/login";
        }
        return;
      }

      if (request.data.response) {
        toast.info("La tarea se operó correctamente");
      } else {
        window.alert(request.data.mensaje);
      }
    } catch (e) {
      toast.error("Error: " + e);
    }
  };

  const handleChangeCheck = (name) => (event) => {
    // this.setState({ [name]: event.target.checked });
  };

  const handlePopoverOpen = (event, textoPopover) => {
    // this.setState({
    //   anchorEl: event.currentTarget,
    //   popOverOpen: true,
    //   textoPopover: textoPopover,
    // });
  };

  const handlePopoverClose = () => {
    // this.setState({ anchorEl: null, popOverOpen: false, textoPopover: "" });
  };

  const handleChangeTabs = (event, newValue) => {
    // this.setState({ value: newValue });
  };

  const handleChangeIndex = (index) => {
    // this.setState({ value: index });
  };

  const { classes } = props;

  return (
    <React.Fragment>
      <div
        style={{
          color: "#2196F3",
          display: "flex",
          alignContent: "center",
          fontSize: "1m",
          marginTop: 10,
        }}
      ></div>

      {cargando ? (
        <div
          style={{
            width: "100%",
            marginTop: 10,
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loader
            type="Oval"
            color="#3498db"
            height="50"
            width="50"
            style={{ textAlign: "center" }}
          />
        </div>
      ) : (
        <></>
      )}
      {terminados.length === 0 && !cargando ? (
        <div
          style={{
            width: "100%",
            marginTop: 10,
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Mensaje>
            Felicitaciones! Por el momento no cuenta con ninguna tarea
            pendiente.
          </Mensaje>
        </div>
      ) : (
        <Container maxWidth="md">
          {terminados.map((item, index) => (
            <CardTarea
              key={item.CodigoTarea}
              CodigoAviso={item.CodigoAviso}
              CodigoTarea={item.CodigoAvisoTarea}
              Problema={item.Problema}
              Estado={item.Estado}
              porcentajeAvance={item.PorcentajeAvance}
              Nombre={item.TareaTitulo}
              Descripcion={item.TareaDescripcion}
              GestionTitulo={item.Titulo}
              Fecha={item.FechaInsertoAmigable}
              FechaVencimiento={item.TareaFechaVencimiento}
              NombreEstado={item.TareaEstado}
              Activo={item.Activo}
              Atrasado={item.Atrasado}
              SLA={item.SLA}
              Solicitante={item.Solicitante}
              Quedan={
                item.CausasTiempoEsperaEstimado != null
                  ? item.CausasTiempoEsperaEstimado
                  : item.ProblemaTiempoEsperaEstimado
              }
              Padre={item.TareaRuta}
              recargar={recargar}
              onClickCancel={onClickCancel}
              onClickAcept={onClickAcept}
              colorSemaforo={item.colorSemaforo}
            />
          ))}
        </Container>
      )}
      <ToastContainer />
      {/* <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paperPopover,
        }}
        open={this.state.popOverOpen}
        anchorEl={this.state.anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        onClose={this.handlePopoverClose}
        disableRestoreFocus
      >
        <Typography>{this.state.textoPopover}</Typography>
      </Popover> */}
    </React.Fragment>
  );
};
const ListaTareasPendientesContainer = withStyles(styles)(ListaTareas);
export default withRouter(ListaTareasPendientesContainer);
