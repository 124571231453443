import React, { useState, useEffect } from 'react'
import {
  Button,
  Grid,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Fab,
  makeStyles
} from '@material-ui/core'
import { GetApp, Edit, Add } from '@material-ui/icons'
import { DropzoneArea } from 'material-ui-dropzone'
import { green } from '@material-ui/core/colors'
import { ToastContainer, toast } from 'react-toastify'
import Api from '../utils/api'
import Layout from '../layout/containers/Layout'
import LoadingButton from './componentes/utils/LoadingButton'
import { getPlantillaBase64 } from './utils/media'
import { getUsuarioInserta } from './utils/utilidades'

const EditPlantilla = ({ form, changeForm, onClose, loading, submit, initial=null }) => {
  useEffect(() => {
    if (initial) {
      changeForm('Nombre', initial.Nombre)
    }
  }, [initial])
  return <>
    <DialogTitle>Plantilla</DialogTitle>
    <DialogContent>
      <TextField
        fullWidth
        style={{marginBottom: 10}}
        label="Nombre de plantilla"
        value={form.Nombre}
        onChange={ev => changeForm('Nombre', ev.target.value)}
      />
      <DropzoneArea
        maxFileSize={100000000}
        dropzoneText="Selecciona el archivo"
        acceptedFiles={["application/vnd.openxmlformats-officedocument.wordprocessingml.document"]}
        onChange={files => changeForm('Archivo', files)}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cerrar</Button>
      <LoadingButton
        loading={loading}
        onClick={submit}
        color="primary"
      >
        Guardar
      </LoadingButton>
    </DialogActions>
  </>
}

const useStyles = makeStyles((theme) => ({
  fab: {
    background: green[500],
    color: '#FFF',
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  }
}));

const PlantillasDocs = () => {
  const classes = useStyles()
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(false)
  const [selected, setSelected] = useState(null)
  const [dialogUpload, setDialogUpload] = useState(false)
  const [dialogCrear, setDialogCrear] = useState(false)
  const [form, setForm] = useState({
    Nombre: '',
    Archivo: null
  })
  const getPlantillas = async () => {
    try {
      const { data } = await Api.fetchGetRequest('api/documentos/plantilla')
      setItems(data.map(i => ({...i, Nombre: i.Nombre.trim()})))
    } catch (error) {
      toast.error('Ha ocurrido un error 😅')
    }
  }
  const refresh = () => {
    setDialogCrear(false)
    setDialogUpload(false)
    getPlantillas()
  }
  const submitDoc = async (isNew) => {
    try {
      setLoading(true)
      console.log(form)
      const formData = new FormData()
      formData.append('archivo', form.Archivo[0])
      formData.append('Nombre', form.Nombre.trim())
      formData.append('Usuario', getUsuarioInserta())
      formData.append('IdPlantilla', isNew ? 0 : selected.Id)
      await Api.postFile('api/documentos/plantilla/subir', formData, {
        'Content-Type': 'multipart/form-data',
      })
      toast.success('El archivo ha sido guardado')
      refresh()
    } catch (error) {
      console.log(error)
      toast.error('Ha ocurrido un error al subir el archivo')
    } finally {
      setLoading(false)
    }
  }
  const saveDoc = (newItem) => {
    if (!form.Archivo) {
      toast.error('El archivo es requerido')
      return
    }
    if (!form.Nombre.trim().length) {
      toast.error('El nombre es requerido')
      return
    }
    submitDoc(newItem)
  }
  const download = async (id) => {
    const item = items.find(i=> i.Id ===  id)
    let base64str = await getPlantillaBase64(item.Id)
    base64str = base64str.replace('data:application/octet-stream;base64,', '')
    const linkSource = `data:application/docx;base64,${base64str}`;
    const downloadLink = document.createElement("a");
    const fileName = `${item.Nombre}.docx`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }
  const changeForm = (key, value) => {
    const tmp = {...form}
    tmp[key] = value
    setForm(tmp)
  }
  const editPlantilla = async (id) => {
    const item = items.find(i => i.Id === id)
    setSelected(item)
    setDialogUpload(true)
  }
  useEffect(() => {
    getPlantillas()
  }, [])
  return <Layout titulo="Plantillas de documentos">
    <Grid container justify="center">
      <Grid item xs={12} md={6}>
        {/* <Typography variant="h5" component="h1">Entornos</Typography> */}
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>No.</TableCell>
                <TableCell>Nombre de plantilla</TableCell>
                <TableCell>Opciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                items.map((i, idx) =>
                <TableRow key={idx}>
                  <TableCell>{idx + 1}</TableCell>
                  <TableCell>{i.Nombre}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => download(i.Id)}>
                      <GetApp />
                    </IconButton>
                    <IconButton onClick={() => editPlantilla(i.Id)}>
                      <Edit />
                    </IconButton>
                  </TableCell>
                </TableRow>)
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
    <Dialog open={dialogUpload} onClose={() => setDialogUpload(false)} fullWidth>
      <EditPlantilla
        form={form}
        changeForm={changeForm}
        onClose={() => setDialogUpload(false)}
        submit={() => saveDoc(false)}
        initial={selected}
        loading={loading}
      />
    </Dialog>
    <Dialog open={dialogCrear} onClose={() => setDialogCrear(false)} fullWidth>
      <EditPlantilla
        form={form}
        changeForm={changeForm}
        onClose={() => setDialogCrear(false)}
        submit={() => saveDoc(true)}
        loading={loading}
      />
    </Dialog>
    <Fab
      onClick={() => setDialogCrear(true)}
      className={classes.fab}
      color="primary"
    >
      <Add />
    </Fab>
    <ToastContainer />
  </Layout>
}

export default PlantillasDocs

