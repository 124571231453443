import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
} from "@material-ui/core";
import FaceIcon from "@material-ui/icons/Face";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Autocomplete } from "@material-ui/lab";
import { Template } from "devextreme-react";
import {
  Chart,
  CommonSeriesSettings,
  Label,
  Series,
} from "devextreme-react/chart";
import DataGrid, {
  Column,
  ColumnChooser,
  Editing,
  Export,
  FilterRow,
  GroupItem,
  GroupPanel,
  Grouping,
  HeaderFilter,
  LoadPanel,
  OperationDescriptions,
  Paging,
  Scrolling,
  SearchPanel,
  StateStoring,
  Summary,
} from "devextreme-react/data-grid";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API from "../../utils/api";

const AsistenciasGrid = ({ FechaInicio, FechaFin, Aviso, TipoGestion }) => {
  const classes = styles();
  const [asistencias, setAsistencias] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedFoto, setSelectedFoto] = useState(null);
  const [headersDinamicos, setHeadersDinamicos] = useState([]);
  const [necesitaUbicaciones, setNecesitaUbicaciones] = useState(false);
  const [cargarEmpleadosPorFrente, setCargarEmpleadosPorFrente] =
    useState(false);
  const [selectedFrentes, setSelectedFrentes] = useState([]);
  const [empleadosPorFrente, setEmpleadosPorFrente] = useState([]);
  const [datosChart, setDatosChart] = useState([]);
  const [summaryColumns, setSummaryColumns] = useState([]);
  const [selectedColumn, setSelectedColumn] = useState("");
  const [summaryType, setSummaryType] = useState("");
  const [showInGroupFooter, setShowInGroupFooter] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    const storedSummaryColumns = localStorage.getItem(
      `${TipoGestion}AsistenciasummaryColumns`
    );
    if (storedSummaryColumns) {
      setSummaryColumns(JSON.parse(storedSummaryColumns));
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      let uri = `api/asistencia/getAsistenciasCorte?inicio=${FechaInicio}&fin=${FechaFin}&aviso=${Aviso}&tipoGestion=${TipoGestion}`;
      const request = await API.fetchGetRequest(uri);
      setAsistencias(request.data.data.Asistencias);
      console.log(request.data.data.Asistencias);
      setNecesitaUbicaciones(true);
      if (TipoGestion === 650) {
        setCargarEmpleadosPorFrente(true);
      }
      setHeadersDinamicos(request.data.data.CamposEncabezado);
      setLoading(false);
      console.log(request.data.data);
    };
    fetchData();
    //cargarListaUsuarios();
  }, [FechaInicio, FechaFin, Aviso, TipoGestion]);

  useEffect(() => {
    //Obtenemos ubicación de cada asistente, y lo añadimos al arreglo de asistencias
    const fetchData = async () => {
      let uri = `api/asistencia/getUbicacionesConsulta?inicio=${FechaInicio}&fin=${FechaFin}&aviso=${Aviso}&tipoGestion=${TipoGestion}`;
      const request = await API.fetchGetRequest(uri);
      if (request.data.response) {
        return request.data.data;
      } else {
        toast.error("Error al obtener ubicaciones");
        console.log(request.data.mensaje);
        return null;
      }
    };

    console.log("Se ejecuta effect");
    if (asistencias.length === 0 || !necesitaUbicaciones) {
      setNecesitaUbicaciones(false);
      return;
    }
    try {
      let toastId = toast.info(
        <div>
          <span>Obteniendo ubicaciones...</span>{" "}
          <CircularProgress size={"1.0rem"} style={{ marginBottom: "0px" }} />
        </div>,
        { autoClose: false }
      );
      fetchData().then((datos) => {
        if (datos !== null) {
          const asistenciasActualizadas = asistencias.map((asistencia) => {
            const ubicacion = datos.find(
              (ubicacion) => ubicacion.Id === asistencia.Id
            );
            if (ubicacion && ubicacion.Encontrado) {
              return { ...asistencia, ["Ubicacion"]: ubicacion.Finca };
            } else {
              return { ...asistencia, ["Ubicacion"]: "-" };
            }
          });
          setAsistencias(asistenciasActualizadas);
        }
        toast.dismiss(toastId);
      });
    } catch (error) {
      toast.dismiss();
      console.log(error);
      toast.error("Error al obtener ubicaciones");
    }
    setNecesitaUbicaciones(false);
  }, [necesitaUbicaciones]);

  useEffect(() => {
    //Cargar empleados por frente, y crear arreglo para chart
    const fetchData = async () => {
      let uri = `api/asistencia/getEmpleadosPorFrente`;
      const request = await API.fetchGetRequest(uri);
      if (request.data.response) {
        return request.data.data;
      } else {
        toast.error("Error al obtener empleados por frente");
        console.log(request.data.mensaje);
        return null;
      }
    };
    try {
      if (cargarEmpleadosPorFrente) {
        let toastId = toast.info(
          <div>
            <span>Obteniendo datos de gráfica...</span>{" "}
            <CircularProgress size={"1.0rem"} style={{ marginBottom: "0px" }} />
          </div>,
          { autoClose: false }
        );
        //let datos = dataExample;
        fetchData().then((datos) => {
          if (datos !== null) {
            setEmpleadosPorFrente(datos);
            //Recupera frentes seleccionados anteriormente
            const frentesSeleccionados = localStorage.getItem(
              `frentesChartAsistencia`
            );
            if (frentesSeleccionados) {
              setSelectedFrentes(JSON.parse(frentesSeleccionados));
            }
            console.log("Empleados por frente: ", datos);
          }
          toast.dismiss(toastId);
        });
      }
    } catch (error) {
      console.log(error);
      toast.error("Error al obtener empleados por frente");
    }
  }, [cargarEmpleadosPorFrente]);

  useEffect(() => {
    let empleadosPorFrenteTmp = empleadosPorFrente
      .filter((empleado) =>
        selectedFrentes.some((selected) => selected.Frente === empleado.Frente)
      )
      .map((obj) => {
        let asistieron = asistencias.filter(
          (asistencia) => asistencia.Frente === obj.Frente
        ).length;
        return { ...obj, ["Asistieron"]: asistieron };
      });
    setDatosChart(empleadosPorFrenteTmp);
    console.log("datosChart tmp: ", empleadosPorFrenteTmp);
  }, [selectedFrentes]);

  const handleFotoClick = (foto) => {
    setSelectedFoto(foto);
  };

  const handleFotoDialogClose = () => {
    setSelectedFoto(null);
  };

  const handleMultiFreteSelect = (event, value) => {
    console.log("valores multi", value);
    setSelectedFrentes(value);
    localStorage.setItem(`frentesChartAsistencia`, JSON.stringify(value));
  };

  const handleAddSummary = () => {
    const newSummaryColums = [
      ...summaryColumns,
      { column: selectedColumn, summaryType, showInGroupFooter },
    ];
    setSummaryColumns(newSummaryColums);
    localStorage.setItem(
      `${TipoGestion}AsistenciasummaryColumns`,
      JSON.stringify(newSummaryColums)
    );
    setModalVisible(false);
  };

  const handleRemoveSummary = (column) => {
    const newSummaryColums = summaryColumns.filter(
      (summaryColumn) => summaryColumn.column !== column
    );
    setSummaryColumns(newSummaryColums);
    localStorage.setItem(
      `${TipoGestion}AsistenciasummaryColumns`,
      JSON.stringify(newSummaryColums)
    );
  };

  const handleSummaryTypeChange = (target) => {
    setSummaryType(target.target.value);
  };

  const handleShowInGroupFooterChange = (e) => {
    setShowInGroupFooter(e.target.checked);
  };

  const handleColumnSelect = (target) => {
    //Verificar si la columna seleccionada ya tiene un resumen
    const existe = summaryColumns.find(
      (summaryColumn) => summaryColumn.column === target.target.value
    );
    if (existe) {
      toast.error("La columna seleccionada ya tiene un resumen.");
      return;
    }
    setSelectedColumn(target.target.value);
    setModalVisible(true);
  };

  const handleModalCancel = () => {
    setSelectedColumn(null);
    setSummaryType("count");
    setShowInGroupFooter(false);
    setModalVisible(false);
  };

  // const cargarListaUsuarios = async () => {
  //     const request = await API.fetchGetRequest(`api/asistencia/getListaUsuarios`);
  //     try {
  //         if (Array.isArray(request.data.usuarios)) {
  //             setListaUsuarios(request.data.usuarios);
  //         } else {
  //             setListaUsuarios([]);
  //         }
  //     } catch (error) {
  //         console.log("cargarListaUsuarios error:" + error);
  //     }
  // };

  const cellRender = (data) => {
    const foto = data.value;
    if (!foto || foto === "") {
      return <div style={{ width: "50px" }}>-</div>;
    }
    return (
      <div onClick={() => handleFotoClick(foto)} style={{ cursor: "pointer" }}>
        <img src={foto} alt="Empleado" style={{ width: "50px" }} />
      </div>
    );
  };

  const actualizarDpi = (rowData, value) => {
    console.log(rowData);
    //this.defaultSetCellValue(rowData, value);
  };

  const handleRowUpdated = (e) => {
    console.log(e);
    const { data, key, newValue } = e;
    const updatedData = [...asistencias];
    const index = updatedData.findIndex((item) => item.key === key);
    updatedData[index] = { ...updatedData[index], ["Dpi"]: newValue };
    setAsistencias(updatedData);
  };
  const dataGrid = useRef(null);

  const resetDataGrid = () => {
    dataGrid.current.instance.state(null);
  };

  const reprocesarAsistentes = async () => {
    let toastId = toast.info(
      <div>
        <span>Reconociendo pendientes...</span>{" "}
        <CircularProgress size={"1.0rem"} style={{ marginBottom: "0px" }} />
      </div>,
      { autoClose: false }
    );

    const request = await API.fetchGetRequest(
      `api/asistencia/reconocerAsistencias?inicio=${FechaInicio}&fin=${FechaFin}&tipoGestion=${TipoGestion}`
    );
    try {
      if (request.data.response) {
        toast.success(
          "Asistentes reconocidos: " + request.data.data.AsistenciasEncontradas
        );
        añadirReconocidosAsistencias(request.data.data.AsistenciasNuevas);
        dataGrid.current.instance.refresh();
      } else {
        toast.error("Error al reprocesar asistentes.");
        console.log(request.data.mensaje);
      }
    } catch (error) {
      console.log("reprocesarAsistentes error:" + error);
      toast.error("Error al reprocesar asistentes.");
    }
    toast.dismiss(toastId);
  };

  const añadirReconocidosAsistencias = (asistenciasNuevas) => {
    const asistenciasActualizadas = asistencias.map((asistencia) => {
      const asistenciaNueva = asistenciasNuevas.find(
        (asistenciaNueva) => asistenciaNueva.id === asistencia.Id
      );
      if (asistenciaNueva) {
        let asistenciaActualizada = { ...asistencia };
        asistenciaActualizada.Dpi = asistenciaNueva.DPI;
        asistenciaActualizada.NombreEmpleado = asistenciaNueva.nombre;
        asistenciaActualizada.Caporal = asistenciaNueva.caporalNombre;
        asistenciaActualizada.Frente = asistenciaNueva.frenteNombre;
        asistenciaActualizada.Puesto = asistenciaNueva.puestoNombre;
        asistenciaActualizada.Proceso = asistenciaNueva.procesoNombre;
        asistenciaActualizada.CodigoEmpleado = asistenciaNueva.codigoAnterior;
        console.log(asistenciaActualizada);
        return asistenciaActualizada;
      } else {
        return asistencia;
      }
    });
    setAsistencias(asistenciasActualizadas);
  };

  const reprocesarAsistentesToolbarButton = () => {
    return (
       <Tooltip title="Reprocesar asistentes">
        <Button
          variant="outlined"
          size="small"
          onClick={reprocesarAsistentes}
          endIcon={<FaceIcon />}
        >
          Reconocer asistentes
        </Button>
      </Tooltip>     
    );
  };

  const agregarBotonesExtras = (e) => {
    e.toolbarOptions.items.unshift(
      {
        location: "after",
        template: "botonReprocesarAsistentes",
      },
      {
        location: "after",
        widget: "dxButton",
        options: {
          icon: "refresh",
          onClick: resetDataGrid,
          hint: "Reiniciar tabla",
        },
      }
    );
  };

  return (
    <React.Fragment>
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress style={{ marginBottom: "20px" }} />
          <br />
          <br />
        </div>
      ) : (
        <React.Fragment>
          <DataGrid
            dataSource={asistencias}
            showBorders={true}
            onRowUpdated={handleRowUpdated}
            ref={dataGrid}
            showRowLines={true}
            allowColumnReordering={true}
            height={"80vh"}
            width={"100%"}
            columnAutoWidth={true}
            onToolbarPreparing={agregarBotonesExtras}
            groupPanel={{
              visible: true,
              emptyPanelText:
                "Arrastre un encabezado aquí para agrupar por esa columna",
            }}
          >
            <Editing
              mode="row"
              allowUpdating={true}
              allowDeleting={false}
              allowAdding={false}
              useIcons={true}
              texts={{
                saveRowChanges: "Guardar",
                cancelRowChanges: "Cancelar",
                deleteRow: "Eliminar",
                editRow: "Editar",
                addRow: "Agregar",
                confirmDeleteMessage: "¿Está seguro de eliminar el registro?",
              }}
            ></Editing>
            <LoadPanel
              height={100}
              width={250}
              indicatorSrc="https://js.devexpress.com/Content/data/loadingIcons/rolling.svg"
            />

            <Paging enabled={false} />
            <Scrolling mode="virtual" rowRenderingMode="virtual" />
            <Grouping autoExpandAll={false} contextMenuEnabled={true}>
              <GroupPanel visible={true} />
            </Grouping>
            <StateStoring
              enabled={true}
              type="localStorage"
              storageKey={`${TipoGestion}DataGridAsis`}
            />
            <FilterRow visible={true}>
              <OperationDescriptions
                between="Entre"
                contains="Contiene"
                endsWith="Termina con"
                equal="Igual"
                greaterThan="Mayor que"
                greaterThanOrEqual="Mayor o igual que"
                lessThan="Menor que"
                lessThanOrEqual="Menor o igual que"
                notContains="No contiene"
                notEqual="No igual"
                startsWith="Empieza con"
              />
            </FilterRow>
            <HeaderFilter visible={true} />
            <SearchPanel visible={true} width={240} placeholder="Buscar..." />
           
           

            <Template
              name="botonReprocesarAsistentes"
              render={reprocesarAsistentesToolbarButton}
            />

            {headersDinamicos.map((header) => (
              <Column
                key={header.Nombre}
                dataField={header.Nombre}
                caption={header.Descripcion}
                dataType={
                  header.TipoDato === "undefined" ? undefined : header.TipoDato
                }
                format={header.TipoDato === "date" ? "yyyy/MM/dd" : undefined}
                alignment={"center"}
                allowEditing={header.Nombre === "Dpi" ? true : false}
                cellRender={(data) => {
                  if (header.TipoCampo === 8) {
                    return (
                      <a
                        href={data.value}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {data.value !== "" && data.value !== null ? (
                          <Icon
                            component={VisibilityIcon}
                            style={{ color: "#53718f" }}
                          />
                        ) : (
                          ""
                        )}
                      </a>
                    );
                  } else if (header.Nombre === "Foto") {
                    return cellRender(data);
                  } else {
                    return data.value;
                  }
                }}
              />
            ))}
            <Summary>
              <GroupItem
                column="CodigoEmpleado"
                summaryType="count"
                displayFormat={"Total: {0}"}
              />
              {summaryColumns.map((summaryColumn) => (
                <GroupItem
                  key={summaryColumn.column}
                  column={summaryColumn.column}
                  summaryType={summaryColumn.summaryType}
                  showInGroupFooter={summaryColumn.showInGroupFooter}
                />
              ))}
            </Summary>
            <ColumnChooser
              enabled={true}
              allowSearch={true}
              mode={"select"}
              emptyPanelText="Buscar columna"
              title="Ocultar:"
            ></ColumnChooser>
            {/* Cuando se muestra un único aviso, no se muestra el código de aviso y no se muestra la opción de exportar */}
            <Column
              dataField="Aviso"
              caption="Código de Aviso"
              alignment={"center"}
              visible={Aviso !== "0" ? false : true}
              allowEditing={false}
            />
            <Export
              enabled={Aviso !== "0" ? false : true}
              fileName="Asistencias"
              allowExportSelectedData={false}
              texts={{ exportAll: "Exportar a Excel" }}
            />
          </DataGrid>
          {Aviso === "0" &&
            empleadosPorFrente.length > 0 &&
            asistencias.length > 0 &&
            TipoGestion == 650 && (
              <React.Fragment>
                <br />
                <br />
                <br />
                <Autocomplete
                  multiple
                  id="frentes-select"
                  options={empleadosPorFrente}
                  getOptionLabel={(option) =>
                    option.Frente !== null ? option.Frente : ""
                  }
                  value={selectedFrentes}
                  getOptionSelected={(option, value) =>
                    option.Frente === value.Frente
                  }
                  onChange={handleMultiFreteSelect}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Seleccionar frentes para la gráfica"
                      placeholder="Seleccionar frentes para la gráfica"
                    />
                  )}
                />
                <br />

                <Chart
                  id="chart"
                  dataSource={datosChart}
                  title="Asistencias por frente"
                  palette="Soft Pastel"
                  style={{ marginTop: "30px" }}
                >
                  <CommonSeriesSettings argumentField="Frente" type="bar">
                    <Label visible={true} />
                  </CommonSeriesSettings>

                  <Series
                    argumentField="Frente"
                    valueField="Cantidad"
                    name="Total"
                    type="bar"
                  />
                  <Series
                    argumentField="Frente"
                    valueField="Asistieron"
                    name="Asistencias"
                    type="bar"
                  />

                  <Export enabled={true} fileName="Asistencias por frente" />
                </Chart>
              </React.Fragment>
            )}

          <br />
          <Divider style={{ margin: "20px 0" }} />
          <Typography
            variant="h4"
            style={{ textAlign: "center", margin: "20px 0" }}
          >
            Configuración
          </Typography>
          <Modal
            open={modalVisible}
            onClose={handleModalCancel}
            className={classes.modal}
          >
            <div className={classes.modalDiv}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className={classes.formControl}
                  >
                    <InputLabel id="summary-type-label">
                      Tipo de Subtotal
                    </InputLabel>
                    <Select
                      value={summaryType}
                      onChange={handleSummaryTypeChange}
                      labelId="summary-type-label"
                      label="Summary Type"
                    >
                      <MenuItem value="count">Count - Conteo de filas</MenuItem>
                      <MenuItem value="min">Min - Valor mínimo</MenuItem>
                      <MenuItem value="max">Max - Valor máximo</MenuItem>
                      <MenuItem value="sum">
                        Sum - Sumatoria de la columna
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={showInGroupFooter}
                        onChange={handleShowInGroupFooterChange}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label="Mostrar en el pie de grupo"
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddSummary}
                  >
                    Añadir resumen/subtotal
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Modal>
          <Box className={classes.box}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6" style={{ textAlign: "center" }}>
                  Resumen/Subtotal
                </Typography>
                <Typography variant="body1" style={{ textAlign: "center" }}>
                  Seleccione una columna para agregar un resumen/subtotal al
                  agrupar los datos
                </Typography>
                <FormControl
                  fullWidth
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel id="column-select-label">
                    Seleccionar Columna
                  </InputLabel>
                  <Select
                    autoWidth
                    labelId="column-select-label"
                    value={selectedColumn}
                    onChange={handleColumnSelect}
                    label="Seleccionar Columna"
                  >
                    <MenuItem value="">
                      <em>Ninguna</em>
                    </MenuItem>
                    {headersDinamicos.map((header) => (
                      <MenuItem key={header.Nombre} value={header.Nombre}>
                        {header.Descripcion}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="h6" style={{ textAlign: "center" }}>
                  Subtotales:{" "}
                </Typography>
                <Grid container spacing={3}>
                  {summaryColumns.map((summaryColumn) => (
                    <Grid item xs={6} sm={12} key={summaryColumn.column}>
                      <Grid container spacing={3}>
                        <Grid item xs={6} sm={9}>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={4}>
                              <b>Columna: </b>
                              <span style={{ overflowWrap: "break-word" }}>
                                {summaryColumn.column}
                              </span>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <b>Tipo resumen: </b>
                              <span>{summaryColumn.summaryType}</span>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <b>En footer: </b>
                              <span>
                                {summaryColumn.showInGroupFooter ? "Sí" : "No"}
                              </span>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                          <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() =>
                              handleRemoveSummary(summaryColumn.column)
                            }
                          >
                            Remover
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Box>

          {selectedFoto && (
            <Dialog open={true} onClose={handleFotoDialogClose}>
              <DialogContent style={{ display: "flex" }}>
                <img
                  src={selectedFoto}
                  alt="Empleado"
                  style={{ maxHeight: "100%", objectFit: "contain" }}
                />
              </DialogContent>
            </Dialog>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const styles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  modalDiv: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "5px",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
  paperPerfil: {
    position: "relative",
    width: "100%", //theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 1,
    outline: "none",
  },
  paper: {
    position: "absolute",
    //width: theme.spacing.unit * 100,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 1,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  paperCard: {
    padding: 20,
    width: "100%",
  },
  box: {
    position: "relative",
    width: "100%", //theme.spacing.unit * 50,
    padding: theme.spacing.unit * 1,
    outline: "none",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  secondNavigationModal: {
    position: "absolute",
    width: "50%",
    height: "400px",
    maxHeight: "400px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
}));

export default AsistenciasGrid;
