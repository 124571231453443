import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Checkbox,
  FormControlLabel,
  Divider,
  Paper,
  Tooltip,
  Modal,
  Fade,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Box,
} from "@material-ui/core";

import BackdropModal from "@material-ui/core/Backdrop";

import { Delete, ViewList, Add, Edit, Visibility } from "@material-ui/icons";

import { green } from "@material-ui/core/colors";
import { NavLink } from "react-router-dom";
import {
  Timeline,
  TimelineItem,
  TimelineContent,
  TimelineSeparator,
  TimelineConnector,
  TimelineDot,
  TimelineOppositeContent,
} from "@material-ui/lab";
import DetalleFuncionComponente from "./detalle-funcion-puesto-componente";
import API from "../../utils/api";
import { isSignedIn, signOut } from "../../utils/auth";
import { validaPermisoPaginaActual } from "../../utils/helpers";
import { ToastContainer, toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import "devextreme/dist/css/dx.material.teal.light.compact.css";

const width_proportion = "100%";
const titleColor = "#2B3C4D";

const useStyles = makeStyles((theme) => ({
  paperContent: {
    padding: "8px",
  },
  table: {
    width: "100%",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 210,
  },
  textFieldDireccion: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 230,
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  greenAvatar: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  paperCard: {
    padding: 20,
    width: "100%",
  },
  formControlSelects: {
    // margin: theme.spacing(1),
    minWidth: 600,
    // width: "100%",
    marginBottom: 10,
    marginTop: 10,
    marginLeft: 30,
  },
  formControlSelect: {
    // margin: theme.spacing(1),
    width: width_proportion,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  rootTabla: {
    width: "100%",
    marginTop: theme.spacing.unit * 1,
    overflowX: "auto",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperModal: {
    position: "absolute",
    width: "90%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));
const FuncionesPuestoLaboral = (props) => {
  const classes = useStyles();
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors: fieldsErrors },
    register,
  } = useForm();

  let applyFilterTypes = [
    {
      key: "auto",
      name: "Immediately",
    },
    {
      key: "onClick",
      name: "On Button Click",
    },
  ];

  const [userName, setUserName] = useState("");
  const [open, setOpen] = useState(false);
  const [cargandoMaestros, setCargandoMaestros] = useState(false);
  const [maestroVerbo, setVerbo] = useState([]);
  const [
    maestroResponsabilidadPuestoLaboral,
    setMaestroResponsabilidadPuestoLaboral,
  ] = useState([]);

  const [codigoVerboNivelPuestoLaboral, setCodigoVerboNivelPuestoLaboral] =
    useState(0);
  const [predicado, setPredicado] = useState("");
  const [numeroVersion, setNumeroVersion] = useState("");
  const [fechaCreacion, setFechaCreacion] = useState(new Date());

  const [valueAutoCompleteVerbo, setValueAutoCompleteVerbo] = useState(null);

  const [positionDisableSorting, setPositionDisableSorting] = useState(false);
  const [showFilterRow, setShowFilterRow] = useState(true);
  const [showHeaderFilter, setShowHeaderFilter] = useState(true);
  const [currentFilter, setCurrentFilter] = useState(applyFilterTypes[0].key);
  const [autoExpandAll, setAutoExpandAll] = useState(true);

  const [buscando, setBackDrop] = useState(false);
  const [selected, setSelected] = useState(null);
  const [codigoNotificacion, setCodigoNotificacion] = useState(0);
  const [idFila, setIdFila] = useState(0);
  const [modoEdicion, setModoEdicion] = useState(false);
  const [
    codigoResponsabilidadNivelPuestoLaboral,
    setCodigoResponsabilidadNivelPuestoLaboral,
  ] = useState(0);

  const handleCerrarBackDrop = () => {
    setBackDrop(false);
  };
  const handleAbrirBackDrop = () => {
    setBackDrop(true);
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    setPredicado("");
    setCodigoVerboNivelPuestoLaboral(0);

    setValueAutoCompleteVerbo(null);
    setCodigoNotificacion(0);
  };

  useEffect(() => {
    const cargar = async () => {
      // CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
      const llaveAgrupacion = "Menu.Principal.Web";
      const llavePagina = "Menu.Principal.Web.PuestoLaboral";
      await validaPermisoPaginaActual(llaveAgrupacion, llavePagina);
      // FIN DE CONSULTA DE PERMISOS
      if (props.puestoLaboral !== undefined) {
        handleAbrirBackDrop();

        await cargarMaestroVerboNivelPuestoLaboral();
        await cargarMaestroResponsabilidadPuestoLaboral();
        handleCerrarBackDrop();
      }
    };

    cargar();
  }, [props.puestoLaboral]);

  const cargarMaestroVerboNivelPuestoLaboral = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri =
        "api/VerboNivelPuestoLaboral/PuestoLaboral/" +
        props.puestoLaboral.CodigoNivelPuestoLaboral;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setVerbo(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cargarMaestroResponsabilidadPuestoLaboral = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri =
        "api/ResponsabilidadPuestoLaboral/PuestoLaboral/1/" +
        props.puestoLaboral.CodigoPuestoLaboral;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setMaestroResponsabilidadPuestoLaboral(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const guardarDato = async () => {
    const data = {
      CodigoResponsabilidadNivelPuestoLaboral: codigoNotificacion,
      CodigoTipoResponsabilidad: 1, //técnicas,
      CodigoNivelPuestoLaboral: props.puestoLaboral.CodigoNivelPuestoLaboral,
      CodigoVerboNivelPuestoLaboral: codigoVerboNivelPuestoLaboral,
      Predicado: predicado,
      Activo: true,
      Eliminado: false,
      UsuarioInserto: userName,
      FechaInserto: "",
      UsuarioModifico: "",
      FechaModifico: "",
    };

    console.log(data);
    // dataFicha.usuarioInserto = "prueba";
    try {
      handleAbrirBackDrop();
      let vIsSignedIn = await isSignedIn();
      if (vIsSignedIn.response) {
        let request;

        request = await API.post(
          "api/ResponsabilidadNivelPuestoLaboral/PuestoLaboral/" +
            props.puestoLaboral.CodigoPuestoLaboral,
          data
        );
        if (request.status !== 200) {
          toast.error(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        } else {
          toast.success("Se guardó exitosamente");
          await cargarMaestroResponsabilidadPuestoLaboral();
          setPredicado("");
          setCodigoVerboNivelPuestoLaboral(0);

          setValueAutoCompleteVerbo(null);
          setCodigoNotificacion(0);
        }
      }
    } catch (e) {
      console.log("EXCEPTION: " + e);
      toast.error(
        "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
      );
    } finally {
      handleCerrarBackDrop();
      handleCloseModal();
    }
  };

  const cuandoHayError = async (data) => {
    toast.info(
      "Existen algunos campos con error, por favor valida el formulario",
      {
        position: "top-right",
        autoClose: 3000, // Esta en milisegundos 3 segundos
      }
    );
  };

  const handleOnChangeVerbo = async (event, newValue) => {
    handleAbrirBackDrop();
    if (newValue !== null) {
      setCodigoVerboNivelPuestoLaboral(newValue.CodigoVerboNivelPuestoLaboral);
      setValueAutoCompleteVerbo(newValue);
    }
    handleCerrarBackDrop();
  };

  const selectItem = (i, idx) => {
    // const item = { ...items[index] };

    setSelected(i);
    setCodigoNotificacion(i.CodigoResponsabilidadNivelPuestoLaboral);
    setIdFila(idx + 1);
  };

  const editar = (i, idx) => {
    // const item = { ...items[index] };
    // setSelected(item);
    setOpen(true);
    setPredicado(i.Predicado);
    setCodigoVerboNivelPuestoLaboral(i.CodigoVerboNivelPuestoLaboral);

    let item = maestroVerbo.filter(
      (w) => w.CodigoVerboNivelPuestoLaboral === i.CodigoVerboNivelPuestoLaboral
    );

    setValueAutoCompleteVerbo(item[0]);

    setCodigoNotificacion(i.CodigoResponsabilidadNivelPuestoLaboral);
    setIdFila(idx + 1);

    console.log(i);
  };

  return (
    <React.Fragment>
      <Backdrop className={classes.backdrop} open={buscando}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer />

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={BackdropModal}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paperModal}>
            <h2 style={{ marginBottom: 5 }} id="simple-modal-title">
              Crear/Editar función técnica
            </h2>
            <Grid container spacing={3}>
              <form
                autoComplete="off"
                onSubmit={handleSubmit(guardarDato, cuandoHayError)}
              >
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <FormControl className={classes.formControlSelects}>
                    <Controller
                      name="verboNivelPuestoLaboral"
                      required
                      render={({ VerboNivelPuestoLaboral }) => (
                        <Autocomplete
                          id="verboNivelPuestoLaboral"
                          options={maestroVerbo}
                          getOptionLabel={(option) => option.Verbo}
                          value={valueAutoCompleteVerbo}
                          onChange={(event, newValue) =>
                            handleOnChangeVerbo(event, newValue)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Verbo"
                              variant="outlined"
                            />
                          )}
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <FormControl className={classes.formControlSelects}>
                    <Controller
                      name="puestoLaboralNombre"
                      render={({ PuestoLaboralNombre }) => (
                        <TextField
                          multiline
                          maxRows={6}
                          id="predicado"
                          label="Predicado"
                          name="predicado"
                          value={predicado}
                          onChange={(e) => setPredicado(e.target.value)}
                          variant="outlined"
                          helperText={
                            fieldsErrors.predicado
                              ? fieldsErrors.predicado.message
                              : ""
                          }
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} md={12} lg={12}>
                  <Button
                    width="100%"
                    variant="contained"
                    style={{
                      backgroundColor: "#69bd4b",
                      marginBottom: 10,
                      marginTop: 10,
                      color: "white",
                      width: "93%",
                      marginLeft: 30,
                    }}
                    type="submit"
                  >
                    &nbsp;Guardar
                  </Button>
                </Grid>
              </form>
            </Grid>
          </div>
        </Fade>
      </Modal>

      <AppBar
        position="sticky"
        className={classes.appBar}
        style={{ backgroundColor: titleColor }}
      >
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            style={{ display: "flex", flexGrow: 1 }}
          >
            {`Responsabilidades o Funciones Técnicas`}
          </Typography>
          <Tooltip title={"Crear nuevo ítem"} aria-label="Crear nuevo ítem">
            <IconButton
              aria-label="Crear nuevo ítem"
              style={{ color: "white" }}
            >
              <Add onClick={handleOpenModal} />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      {maestroResponsabilidadPuestoLaboral !== undefined ? (
        <Paper style={{ width: "100%" }}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={3}>
              <Timeline align="left">
                {maestroResponsabilidadPuestoLaboral.map((i, idx) => (
                  <TimelineItem key={i.CodigoResponsabilidadPuestoLaboral}>
                    {/* <TimelineSeparator> */}
                    {/* <TimelineDot color="primary" /> */}
                    {/* {maestroResponsabilidadPuestoLaboral.length !== idx + 1 && <TimelineConnector />} */}
                    {/* </TimelineSeparator> */}
                    <TimelineOppositeContent className={classes.itemContent}>
                      <Paper elevation={3} className={classes.paperContent}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography
                              variant="h6"
                              component="h6"
                              align="center"
                            >
                              {`Función ` + (idx + 1)}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="body1" align="left">
                              {i.Responsabilidad}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Button
                              color="primary"
                              onClick={() => editar(i, idx)}
                            >
                              <Edit />
                            </Button>
                          </Grid>
                          <Grid item xs={6}>
                            <Button
                              color="primary"
                              onClick={() => selectItem(i, idx)}
                            >
                              <Visibility />
                            </Button>
                          </Grid>
                        </Grid>
                      </Paper>
                      {/* <Typography variant="body1" component="p">
                        {`Función ` + (idx + 1)} {i.Responsabilidad}
                      </Typography> */}
                    </TimelineOppositeContent>
                  </TimelineItem>
                ))}
              </Timeline>
            </Grid>
            {selected !== null && (
              <Grid item xs={12} md={9} style={{ padding: "1rem" }}>
                <DetalleFuncionComponente
                  puestoLaboral={props.puestoLaboral}
                  responsabilidad={selected}
                  idFila={idFila}
                  // quitarFuncionTecnica={quitarFuncionTecnica}
                  cargarMaestroResponsabilidadPuestoLaboral={
                    cargarMaestroResponsabilidadPuestoLaboral
                  }
                />
              </Grid>
            )}
          </Grid>
        </Paper>
      ) : undefined}
    </React.Fragment>
  );
};

export default FuncionesPuestoLaboral;
