import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";


//Own components
import Layout from "../../layout/containers/Layout";
import FamiliaListTable from "../components/familia-list-table";
import AddButton from "../../helpers/components/add-button-float";
import API from "../../utils/api";
import { isSignedIn } from "../../utils/auth";
import {validaPermisoPaginaActual} from "../../utils/helpers";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%"
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none"
  },
  buttonAdd: {
    margin: theme.spacing.unit
  }
});

class FamiliaList extends Component {
  state = {
    open: false,
    actionType: "", //Crear, Actualizar
    codigoFamilia: "",
    nombreFamilia: "",
    descripcionFamilia: "",
    activoFamilia: "",
    listaFamilias: [],
   
  };

  async componentWillMount() {

        //CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
        const llaveAgrupacion = "Menu.Principal.Web";
        const llavePagina = "Menu.Principal.Web.Etiquetas";
        await validaPermisoPaginaActual(llaveAgrupacion, llavePagina);
        //FIN DE CONSULTA DE PERMISOS

    this.cargarLista();
   
  }
  async cargarLista() {
    const request = await API.fetchGetRequest("api/familiaEtiqueta/listar");

    this.setState({
      listaFamilias: request.data.data
    });
  }


  async cargarDatosEdicion(codigo) {
    const request = await API.fetchGetRequest(
      "api/familiaEtiqueta/cargarDatosEdicion/" + this.state.codigoFamilia
    );

    this.setState({
      codigoFamilia: request.data.data.CodigoFamiliaEtiqueta,
      nombreFamilia: request.data.data.Nombre,
      descripcionFamilia: request.data.data.Descripcion,
      activoFamilia: request.data.data.Activo,
    
    });
  }

  editar = codigo => {
    this.setState(
      {
        codigoFamilia: codigo,
        actionType: "Actualizar"
      },
      async () => {
        await this.cargarDatosEdicion();
        this.handleOpenAddModal();
      }
    );

    
  };

  handleOpenAddModal = () => {
      
    this.setState({
      open: true
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
      codigoFamilia: "",
      nombreFamilia: "",
      descripcionFamilia: "",
      activoFamilia: "",
      actionType: ""
    });
  };

  handleChange = name => event => {
    if (name == "activoFamilia" || name == "activoEtiqueta") {
      this.setState({ [name]: event.target.checked });
    } else {
      this.setState({
        [name]: event.target.value
      });
    }
  };

  redireccionar = (codigo)=>{
    this.props.history.push("/familia/" + codigo);
  }

  guardar= async () => {
    const { nombreFamilia } = this.state;

    if (nombreFamilia.trim() == "") {
      toast.warn("Ingrese un nombre válido");
      return;
    }

    try {
      let vIsSignedIn = await isSignedIn();
      let palabra = "";
      if (vIsSignedIn.response) {
        let request;
        let data;

        if (this.state.actionType == "Agregar") {
          data = {
            CodigoFamiliaEtiqueta: this.state.codigoFamilia,
            Nombre: this.state.nombreFamilia,
            Descripcion: this.state.descripcionFamilia,
            Activo: true,
            CodigoUsuarioOpera:vIsSignedIn.codigoUsuario,
            UsuarioOpera: vIsSignedIn.userName,
            };
          palabra = "grabado";
        } else {
          data = {
            CodigoFamiliaEtiqueta: this.state.codigoFamilia,
            Nombre: this.state.nombreFamilia,
            Descripcion: this.state.descripcionFamilia,
            Activo: this.state.activoFamilia,
            CodigoUsuarioOpera:vIsSignedIn.codigoUsuario,
            UsuarioOpera: vIsSignedIn.userName,            
          };
          palabra = "editado";
        }

        request = await API.post(`api/familiaEtiqueta/grabarEditar`, data);
        if (request.status != 200) {
          alert(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        }

        if (request.statusCode === 401) {
          //alert("cerrar sesion");
        } else {
          toast.success("El registro se " + palabra + " exitosamente.");
        
        //Redirecciona cuando la acción es grabar
          if(this.state.actionType == "Agregar"){
                                  // this.props.history.push("/entidad/" + request.data.data.Codigo);
                                  this.redireccionar(request.data.data.CodigoFamiliaEtiqueta);
                                }else{
                                  this.handleClose();
                                  this.cargarLista();
                                } 
         
        }
      }
    } catch (e) {
      alert("hubo un error");
    }
  };

  
  eliminar = async codigo => {
    let vIsSignedIn = await isSignedIn();

    if (vIsSignedIn.response) {
      const request = await API.post(
        `api/familiaEtiqueta/eliminar/${codigo}/${vIsSignedIn.userName}`
      );
      if (request.status != 200) {
        alert(
          "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
        );
      }

      if (request.statusCode === 401) {
        //alert("cerrar sesion");
      } else {
        toast.success("El registro se eliminó exitosamente.");
        this.cargarLista();
      }
    } else {
      toast.error("La sessión no se encuentra activa");
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <Layout titulo="Familias de etiquetas">
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12} sm={12}>
            <FamiliaListTable
              data={this.state.listaFamilias}
              editar={this.editar}
              eliminar={this.eliminar}
              redireccionar={this.redireccionar}
            />
          </Grid>
        </Grid>

        <AddButton
          onClick={() => {
            this.setState({ actionType: "Agregar" }, this.handleOpenAddModal());
          }}
        />

        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.open}
          onClose={this.handleClose}
        >
          <div style={getModalStyle()} className={classes.paper}>
            <form className={classes.container} noValidate autoComplete="off">
              <Typography variant="title" gutterBottom>
                {this.state.actionType == "Agregar" ? (
                  <div>Crear familia</div>
                ) : (
                  <div>Editar familia</div>
                )}
              </Typography>
              <TextField
                required
                id="txtNombre"
                label="Nombre"
                className={classes.textField}
                value={this.state.nombreFamilia}
                onChange={this.handleChange("nombreFamilia")}
                margin="normal"
                variant="outlined"
                inputProps={{ maxLength: 512 }}
              />
              <TextField
                id="txtDescripcion"
                label="Descripción"
                className={classes.textField}
                value={this.state.descripcionFamilia}
                onChange={this.handleChange("descripcionFamilia")}
                margin="normal"
                variant="outlined"
                multiline
                inputProps={{ maxLength: 512 }}
              />
              {this.state.actionType === "Actualizar" ? (
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.activoFamilia}
                        onChange={this.handleChange("activoFamilia")}
                        value="activo"
                        color="primary"
                      />
                    }
                    label="Activo"
                  />
                  <br />
                </div>
              ) : (
                <div></div>
              )}
              <Grid container spacing={3}>
                <Grid
                  item
                  xs={12}
                  lg={12}
                  sm={12}
                  
                >
                  <Button
                    variant="contained"
                    style={{ backgroundColor: "#69bd4b", color: "white" }}
                    className={classes.buttonAdd}
                    onClick={() => this.guardar()}
                  >
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </Modal>
        
        <ToastContainer />
      </Layout>
    );
  }
}
const FamiliaListContainer = withStyles(styles)(FamiliaList);
export default withRouter(FamiliaListContainer);

