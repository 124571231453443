import React, { useState, useEffect } from 'react'
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputLabel,
  Select,
  FormControl,
  MenuItem,
  TextField
} from '@material-ui/core'
import { ToastContainer, toast } from 'react-toastify'

import api from '../../../utils/api'
import { correlativoModel } from '../../utils/modelos'
import { getUsuarioInserta } from '../../utils/utilidades'
import LoadingButton from '../utils/LoadingButton'

const CorrelativoCrear = ({ onClose, onRefresh }) => {
  const [procesos, setProcesos] = useState([])
  const [masters, setMasters] = useState({
    macroprocesos: [],
    procesos: [],
    tipos: []
  })
  const [macroproceso, setMacroproceso] = useState('')
  const [proceso, setProceso] = useState('')
  const [inicio, setInicio] = useState('')
  const [fin, setFin] = useState('')
  const [loading, setLoading] = useState(false)
  const getMasters = async () => {
    try {
      const getMacros = api.fetchGetRequest('api/documentos/macroproceso')
      const getProcesos = api.fetchGetRequest('api/documentos/proceso')
      const getTipos = api.fetchGetRequest('api/documentos/tipodocumento')
      const res = await Promise.all([getMacros, getProcesos, getTipos])
      setMasters({
        macroprocesos: res[0].data,
        procesos: res[1].data,
        tipos: res[2].data
      })
    } catch (error) {
      toast.error('Ha ocurrido un errror')
    }
  }
  const insertCorrelativo = async () => {
    try {
      setLoading(true)
      const correlativo = {
        IdMacroproceso: macroproceso,
        IdProceso: proceso,
        CorrelativoInicio: inicio,
        CorrelativoFin: fin,
        UsuarioInserto: getUsuarioInserta()
      }
      const datos = await correlativoModel.validate(correlativo)
      const requests = []
      for (const tipo of masters.tipos) {
        const body = {
          ...datos,
          IdTipoDocumento: tipo.Id
        }
        requests.push(api.realizarPeticionPostPut('api/documentos/correlativo/crear', body, 'POST'))
      }
      await Promise.all(requests)
      onRefresh()
      toast.success('Se creó correctamente')
    } catch (err) {
      if (err.name && err.name === 'ValidationError') {
        toast.error(err.errors[0])
      } else {
        toast.error('Ha ocurrido un error')
      }
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    if (macroproceso) {
      const tmp = masters.procesos.filter(i => i.IdMacroproceso === macroproceso)
      setProcesos(tmp)
      setProceso('')
    }
  }, [macroproceso])
  useEffect(() => {
    getMasters()
  }, [])
  return <>
    <DialogTitle>Agregar rango de correlativos</DialogTitle>
    <DialogContent>
      <FormControl fullWidth style={{marginTop: '1em', marginBottom: '1em'}}>
        <InputLabel>Macroproceso</InputLabel>
        <Select
          fullWidth
          value={macroproceso}
          onChange={ev => setMacroproceso(ev.target.value)}
        >
          {masters.macroprocesos.map((i, idx) => <MenuItem key={idx} value={i.IdMacroproceso}>
            {i.NombreMacroproceso}
          </MenuItem>)}
        </Select>
      </FormControl>
      <FormControl fullWidth style={{marginTop: '1em', marginBottom: '1em'}}>
        <InputLabel>Proceso</InputLabel>
        <Select
          fullWidth
          value={proceso}
          onChange={ev => setProceso(ev.target.value)}
        >
          {procesos.map((i, idx) => <MenuItem key={idx} value={i.Id}>{i.NombreProceso}</MenuItem>)}
        </Select>
      </FormControl>
      <TextField
        fullWidth
        label="Correlativo inicio"
        value={inicio}
        onChange={ev => {
          setInicio(ev.target.value)
        }}
        type="number"
      />
      <TextField
        fullWidth
        label="Correlativo final"
        value={fin}
        onChange={ev => {
          setFin(ev.target.value)
        }}
        type="number"
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancelar</Button>
      <LoadingButton
        loading={loading}
        color="primary"
        onClick={insertCorrelativo}
      >Agregar</LoadingButton>
    </DialogActions>
    <ToastContainer />
  </>
}

export default CorrelativoCrear
