import React, { Component } from "react";
import { withRouter } from "react-router-dom";

//Own components
import Layout from "../../layout/containers/Layout";
import NotificacionesListTable from "../components/notificaciones-list-table";
import AddButton from "../../helpers/components/add-button-float";
import API from "../../utils/api";
import DialogBox from "../components/dialog-box";
import TablaVistas from "../components/tabla-vistas";
import DevExpressDataGrid from "../components/devexpress-data-grid";

import { validaPermisoPaginaActual } from "../../utils/helpers";

class NotificacionesList extends Component {
  state = {
    notificaciones: [],
    openNoViews: false,
    cargandoVistas: true,
    vistas: [],
  };

  async componentDidMount() {
    //CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
    const llaveAgrupacion = "Menu.Principal.Web";
    const llavePagina = "Menu.Principal.Web.Notificaciones";
    await validaPermisoPaginaActual(llaveAgrupacion, llavePagina);
    //FIN DE CONSULTA DE PERMISOS

    const request = await API.fetchGetRequest(
      "api/notificacion/getListaNotificacionesWeb"
    );

    this.setState({
      notificaciones: request.data,
    });
  }

  agregarNotificacion = (a) => {
    this.props.history.push("/notificaciones/crear");
  };

  editar = (codigoNotificacion) => {
    this.props.history.push({
      pathname: "/notificaciones/editar",
      state: { CodigoNotificacion: codigoNotificacion },
    });
  };

  onClickNoVistas = async (codigoNotificacion) => {
    this.setState({ openNoViews: true });
    await this.makeRequestVistas(codigoNotificacion);
  };

  makeRequestVistas = async (codigoNotificacion) => {
    this.setState({
      cargandoVistas: true,
    });
    const request = await API.fetchGetRequest(
      `api/notificacion/vistas/${codigoNotificacion}`
    );

    this.setState({
      vistas: request.data.data,
      cargandoVistas: false,
    });

    console.log("la data jeje", request);
  };

  handleCloseDialog = () => {
    this.setState({ openNoViews: false });
  };

  render() {
    return (
      <>
        <Layout titulo="Notificaciones generales">
          {/* <NotificacionesListTable
          data={this.state.notificaciones}
          onClickNoVistas={this.onClickNoVistas}
          editar={this.editar}
        /> */}

          <DevExpressDataGrid
            filas={this.state.notificaciones}
            onClickNoVistas={this.onClickNoVistas}
            editar={this.editar}
          />

          <DialogBox
            cargandoVistas={this.state.cargandoVistas}
            openNoViews={this.state.openNoViews}
            handleCloseDialog={this.handleCloseDialog}
            titulo={"Vistas"}
            descripcion={"Usuarios que han visto esta notificación"}
          >
            <TablaVistas data={this.state.vistas} />
          </DialogBox>
        </Layout>
        <AddButton
          onClick={() => this.agregarNotificacion()}
          style={{ zIndex: "1000" }}
        />
      </>
    );
  }
}

export default withRouter(NotificacionesList);
