import React, { Component, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Backdrop, CircularProgress, Grid, makeStyles, Typography } from "@material-ui/core";
import { toast, ToastContainer } from "react-toastify";

import Layout from "../../layout/containers/Layout";
import ToolBar from '../components/toolbar';
import CardGestiones from "../components/card-gestiones";
import API from "../../utils/api";
import { format, sub } from "date-fns";
import { Pagination } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const KanbanGestiones = (props) => {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [gestiones, setGestiones] = useState({
        Page: 1,
        Total: 0,
        PageSize: 50,
        Results: []
    });
    const [page, setPage] = useState(1);
    const [carpetas, setCarpetas] = useState([]);
    const [carpeta, setCarpeta] = useState(null);
    const [fechaInicio, setFechaInicio] = useState(sub(new Date(), { months: 1 }));
    const [fechaFin, setFechaFin] = useState(new Date());
    const [checkPendiente, setCheckPendiente] = useState(true);
    const [checkFinalizada, setCheckFinalizada] = useState(false);
    const [checkResponsable, setCheckResponsable] = useState(false);


    useEffect(() => {
        // obtenerGestiones();
        obtenerCarpetas();
    }, []);

    const obtenerGestiones = async (page = 1) => {
        try {
            setLoading(true);
            if (carpeta == null) {
                toast.warn("Debe seleccionar un tipo de gestión");
            } else if (fechaInicio == null) {
                toast.warn("Debe seleccionar una fecha de inicio");
            } else if (fechaFin == null) {
                toast.warn("Debe seleccionar una fecha de fin");
            } else if (!checkPendiente && !checkFinalizada) {
                toast.warn("Seleccione al menos uno de los dos checks de estado");
            } else {
                let endpoint = `api/kanban/?page=${page}&codigoCarpeta=${carpeta.IdCarpeta}`;
                endpoint += `&fechaInicio=${format(fechaInicio, 'yyyy-MM-dd')}&fechaFin=${format(fechaFin, 'yyyy-MM-dd')}`;
                endpoint += `&pendientes=${checkPendiente}&finalizadas=${checkFinalizada}&responsable=${checkResponsable}`;
                
                const { data } = await API.fetchGetRequest(endpoint);
                setGestiones(data);
                setPage(page);
            }
            setLoading(false);
        } catch (e) {
            toast.error("Error al obtener las gestiones");
            setLoading(false);
        }
    }

    const obtenerCarpetas = async () => {
        try {
            setLoading(true);
            const { data } = await API.fetchGetRequest('api/kanban/carpetas');
            setCarpetas(data);
            setLoading(false);
        } catch (e) {
            toast.error("Error al obtener el filtro de tipos de gestiones");
            setLoading(false);
        }
    }

    return (
        <Layout titulo="Kanban Gestiones" maxWidth={"false"}>
            <ToastContainer />
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid container>
                <ToolBar
                    carpetas={carpetas}
                    carpeta={carpeta}
                    fechaInicio={fechaInicio}
                    fechaFin={fechaFin}
                    setCarpeta={setCarpeta}
                    setFechaInicio={setFechaInicio}
                    setFechaFin={setFechaFin}
                    checkPendiente={checkPendiente}
                    checkFinalizada={checkFinalizada}
                    checkResponsable={checkResponsable}
                    setCheckPendiente={setCheckPendiente}
                    setCheckFinalizada={setCheckFinalizada}
                    setCheckResponsable={setCheckResponsable}
                    onClick={() => obtenerGestiones(1)}
                />
            </Grid>
            <Typography variant="h5" style={{ margin: '1rem 0', color: '#424242', fontWeight: '600' }}>Progreso de gestiones</Typography>
            <Grid container justify="center" style={{ marginBottom: '1rem' }}>
                <Pagination page={page} color="primary" size="large" count={Math.ceil(gestiones.Total / gestiones.PageSize)} onChange={(e, page) => obtenerGestiones(page) } />
            </Grid>
            <CardGestiones data={gestiones.Results} />
            <Grid container justify="center" style={{ marginTop: '1rem' }}>
                <Pagination page={page} color="primary" size="large" count={Math.ceil(gestiones.Total / gestiones.PageSize)} onChange={(e, page) => obtenerGestiones(page) } />
            </Grid>
        </Layout>
    )
}

export default withRouter(KanbanGestiones);
