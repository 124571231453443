import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import Chip from "@material-ui/core/Chip";
import FaceIcon from "@material-ui/icons/Face";
import GroupIcon from '@material-ui/icons/Group';
import TreeIcon from "../../PlantillaFlujo/tree-icon";

//Badge
import Badge from "@material-ui/core/Badge";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "100%",
    marginTop: "15px",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: "rgb(33, 126, 207)",
  },
  chip: {
    marginTop: "10px",
    marginLeft: "5px",
    marginRight: "5px",
  },
}));

export default function TareaProblemaCard(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            {props.numero + 1}
          </Avatar>
        }
        title={props.titulo}
        subheader={"tipo de tarea: " + props.tipoTarea}
      />

      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          {props.descripcion}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <Typography paragraph>
          <Badge badgeContent={props.usuariosAsignados.length} color="primary">
            {props.usuariosAsignados.length < 1 ? (
              <span style={{ color: "rgb(245, 0, 87)" }}>
                Sin usuarios/roles asignados
              </span>
            ) : (
              <span style={{ color: "rgb(63, 81, 181)" }}>
                Usuarios/roles asignados &nbsp;&nbsp;&nbsp;
              </span>
            )}
          </Badge>
        </Typography>
        <IconButton
          style={{ marginLeft: "auto" }}
          aria-label="Agregar asignado"
          onClick={() => {
            props.openModalUsuarios(props.codigoTareaPlantilla);
          }}
        >
          <PersonAddIcon />
        </IconButton>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <div>
            {props.usuariosAsignados.map((item) => (
              <Chip
                className={classes.chip}
                icon={item.tipo==="Usuario"?<FaceIcon />: (item.tipo ==="Rol"?<GroupIcon />:<TreeIcon />)}
                label={item.NombreCompletoUsuario}
                onDelete={() => {
                  props.eliminarUsuario(item.CodigoGrupoUsuario);
                }}
                color={item.tipo==="Usuario"?"primary": (item.tipo==="Rol"?"secondary":"secondary")}
                variant = {item.tipo==="RolDinamico"?"outlined": "default"}
              />
            ))}
          </div>
          <Typography paragraph></Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}
