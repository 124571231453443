import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Layout from '../layout/containers/Layout'
import ViewPDF from './componentes/documentos/ViewPDF'
import { getPDFCorrelativoBase64 } from './utils/media'
import {toast, ToastContainer} from 'react-toastify'

const VisualizarDocumento = () => {
  const { proceso, codigo } = useParams()
  const [PDF, setPDF] = useState(null)
  const getPDF = async (proceso, codigo) => {
    try {
      let base64str = await getPDFCorrelativoBase64(proceso, codigo)
      base64str = base64str.replace('data:application/octet-stream;base64,', '')
      setPDF(base64str)
    } catch (error) {
      toast.error('No se pudo obtener el documento 😅')
    }
  }
  useEffect(() => {
    getPDF(proceso, codigo)
  }, [])
  return <Layout title="Gestión documental">
    <ViewPDF
      docBase64={PDF}
      closable={false}
    />
    <ToastContainer />
  </Layout>
}

export default VisualizarDocumento
