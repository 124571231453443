import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Grid } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import { ToastContainer, toast } from "react-toastify";

//OWN
import Layout from "../../layout/containers/Layout";
import API from "../../utils/api";
import { isSignedIn, signOut } from "../../utils/auth";
import { convertDDMMYYYYhhmmss } from "../../utils/helpers";

const mapStyle = {
  width: "100%",
  maxWidth: "100%",
  height: 700,
  position: "relative",
  left: 0,
};

const containerStyle = {
  position: "relative",
  width: "50%",
  height: "100%",
};
const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  paperUserModal: {
    position: "absolute",
    width: "75%",
    height: "600px",
    maxHeight: "600px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  popover: {
    pointerEvents: "none",
  },
  paperPopover: {
    padding: theme.spacing(1),
    background: "#000",
    color: "#fff",
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },

  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
  iframeContainer: {
    width: "100%",
    height: "100%",
  },
});

export class MapContainer extends Component {
  state = {
    codigoUsuario: "",
    coordenadas: [],
    listaUsuarios: [],
  };
  /*  async componentWillMount() {
    const session = await isSignedIn();
    if (!session.response) {
      window.location = "/home";
      return;
    }
    if (session.response) {
      this.setState(
        {
          tipo: this.props.match.params.tipo,
          codigoUsuarioActual: session["codigoUsuario"],
        },
        async () => {
          //await this.cargarListaUsuarios();
          //await this.cargarCoordenadas();
        }
      );
    } else {
      window.location.href = "/login";
    }
  }
 */
  async cargarCoordenadas() {
    try {
      //Loader
      this.setState({ cargando: true });
      const codigoUsuario = this.state.codigoUsuario;
      if (
        codigoUsuario === "" ||
        codigoUsuario === 0 ||
        codigoUsuario === undefined
      ) {
        toast.warn("Debes elegir un colaborador");
        return;
      }
      const request = await API.fetchGetRequest(
        `api/contacttracing/obtenerCoordenadas/${codigoUsuario}`
      );
      if (request.statusCode != 200) {
        alert(
          "Hubo un error al realizar la operación, consulta con un administrador"
        );
      }

      if (request.statusCode === 401) {
        //alert("cerrar sesion");
        window.location.href = "/login";
      } else {
        if (Array.isArray(request.data.data)) {
          this.setState({
            coordenadas: request.data.data,
          });
        }
      }
    } catch (error) {
      console.log(error);
      if (error.toString().includes("401")) {
        window.location.href = "/login";
      }
    } finally {
      this.setState({ cargando: false });
    }
  }

  cargarListaUsuarios = async () => {
    let criterio = "x-x";
    const request = await API.fetchGetRequest(`api/Usuario/buscar/${criterio}`);
    try {
      if (Array.isArray(request.data.usuarios)) {
        this.setState({
          listaUsuarios: request.data.usuarios,
        });
      } else {
        this.setState({
          listaUsuarios: [],
        });
      }
    } catch (error) {
      console.log("cargarListaTareas error:" + error);
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid container style={{ height: "100%" }}>
        {/* <Layout
        titulo={"Contact Tracing"}
        bodyContainerStyle={{
          padding: 0,
          margin: 0,
          maxWidth: "none",
          height: "100%",
        }}
      > */}
        <iframe
          src="http://trazabilidad.launion.com.gt/"
          style={mapStyle}
        ></iframe>
        {/* </Grid> */}
      </Grid>
      /* </Layout> */
    );
  }
}
const MapContainerr = withStyles(styles)(MapContainer);
export default GoogleApiWrapper({
  apiKey: "AIzaSyCIXmnukatFpDU6pkAxuh1O3q7s4TIxrNM",
})(MapContainerr);
