import React, { useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import EditIcon from "@material-ui/icons/Edit";
import Chip from "@material-ui/core/Chip";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import NotificationsIcon from "@material-ui/icons/Notifications";

//Own components
// import TableTitle from "../../helpers/components/table-title";
// import TableDescription from "../../helpers/components/table-description";
// import Link from "../../helpers/components/link";
// import ActionMenuObject from "./action-menu-objects";
import ConfirmDialog from "../../ConfirmDialog/ConfirmDialog";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
});

function SimpleTable(props) {
  const [confirmTexto, setConfirmTexto] = useState("");
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [idObjetoEliminar, setIdObjetoEliminar] = useState("");

  const { classes, data } = props;

  const editar = (idObjeto, llave) => {
    props.editar(idObjeto, llave);
  };

  const irConfiguracion = (idObjeto, llave) => {
    props.irConfiguracion(idObjeto, llave);
  };

  const verCausas = (idObjeto, llave) => {
    props.verCausas(idObjeto, llave);
  };

  const confirmarAccion = (id) => {
    setConfirmTexto(
      `¿Confirma que desea eliminar este registro? esta acción no podrá revertirse.`
    );
    setConfirmOpen(true);
    setIdObjetoEliminar(id);
  };

  const funcionOk = () => {
    eliminar();
  };

  const cerrarConfirm = () => {
    setConfirmTexto("");
    setConfirmOpen(false);
    setIdObjetoEliminar("");
  };

  const eliminar = async () => {
    await props.eliminar(idObjetoEliminar);
    setIdObjetoEliminar("");
  };

  const iconType = (llave) => {
    let icono;
    if (llave === "CARPETA_MODULO") {
      icono = <FolderOpenIcon />;
    } else if (llave === "PROBLEMA") {
      icono = <NotificationsIcon />;
    }

    return icono;
  };

  return (
    <React.Fragment>
      <Paper className={classes.root}>
        <Table className={classes.table}>
          {/* <TableHead>
          <TableRow>
            <TableCell style={{ width: "80%" }}>Nombre</TableCell>
 
            <TableCell style={{ width: "20%" }}>
              
            </TableCell>
            
          </TableRow>
        </TableHead> */}
          <TableBody>
            {data.map((row, i) => (
              <TableRow>
                <TableCell
                  key={i}
                  onClick={() => {
                    props.navegar(row.IdObjeto, row.Nombre, row.Llave);
                  }}
                  style={{ cursor: "pointer", width: "5%" }}
                >
                  {iconType(row.Llave)}
                </TableCell>
                <TableCell
                  style={{ width: "70%" }}
                  component="th"
                  scope="row"
                  key={i}
                  onClick={() => {
                    if (row.Llave === "CARPETA_MODULO") {
                      props.navegar(row.IdObjeto, row.Nombre, row.Llave);
                    } else if (row.Llave === "PROBLEMA") {
                      props.irConfiguracion(
                        row.IdObjeto,
                        row.Nombre,
                        row.Llave
                      );
                    }
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {row.Nombre}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <ConfirmDialog
        id="confirmar"
        keepMounted
        open={confirmOpen}
        onClose={cerrarConfirm}
        value=""
        texto={confirmTexto}
        okfunction={funcionOk}
      />
    </React.Fragment>
  );
}

export default withStyles(styles)(SimpleTable);
