import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";

//Own components
import Parrafo from "../components/parrafo";
import TituloChart from "../components/titulo-chart";

//Icons
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

//Devexpress
import PieCustom from "../../devexpress/components/pie-custom";
import Bar from "../../devexpress/components/bar";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    maxHeight: "100%",
    marginBottom: 25,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  margin: {
    margin: theme.spacing(1),
  },
  //Header
  header: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
  },
}));

function ChartLayout(props) {
  const reg = props.records;

  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <Typography
        variant="caption"
        display="block"
        gutterBottom
        style={{ margin: 5 }}
      >
        ({reg} respuesta{reg > 1 && "s"})
      </Typography>
      <CardContent>{props.children}</CardContent>
    </Card>
  );
}

export default function Dashboard(props) {
  const classes = useStyles();

  const { dashboardDatos, isLoading } = props;

  return (
    <Card className={classes.root}>
      <CardContent>
        <div className={classes.header}>
          <IconButton
            aria-label="delete"
            className={classes.margin}
            size="medium"
            onClick={() => {
              props.onPressAtras();
            }}
          >
            <ArrowBackIosIcon fontSize="inherit" />
          </IconButton>
          <Typography variant="h5" component="h2">
            Respuestas
          </Typography>
        </div>

        {isLoading && <span>cargando..</span>}

        {!isLoading &&
          dashboardDatos.map((m) => {
            switch (m.tipo) {
              case "BAR":
                return (
                  <ChartLayout records={m.registros}>
                    <Bar dataSource={m.dataSet} title={m.titulo} />
                  </ChartLayout>
                );
                break;
              case "PIE":
                return (
                  <ChartLayout records={m.registros}>
                    <PieCustom dataSource={m.dataSet} title={m.titulo} />
                  </ChartLayout>
                );
                break;
              case "TEXT":
                return (
                  <ChartLayout records={m.registros}>
                    <TituloChart>{m.titulo}</TituloChart>
                    {m.dataSet.map((m) => (
                      <Parrafo>
                        <p>{m.value}</p>
                      </Parrafo>
                    ))}
                  </ChartLayout>
                );
                break;
            }
          })}
      </CardContent>
    </Card>
  );
}
