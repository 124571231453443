import { Button, CircularProgress, Dialog, DialogContent, Divider, Tab, Tooltip, Typography } from "@material-ui/core";
import FaceIcon from '@material-ui/icons/Face';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import { Template } from "devextreme-react";
import DataGrid, { Column, ColumnChooser, Export, FilterRow, GroupItem, GroupPanel, Grouping, HeaderFilter, OperationDescriptions, Paging, Scrolling, SearchPanel, StateStoring, Summary } from "devextreme-react/data-grid";
//import { Tab, Typography } from "@material-ui";
//import Tab from '@material-ui/core/Tab';
import React, { useEffect, useRef, useState } from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API from "../../utils/api";
import GraficasCatCosechaFrente from "./graficasCatCosechaFrente";



const AsistenciasCosecha = ({ FechaInicio, FechaFin }) => {
    const [loading, setLoading] = useState(true);
    const [loadingCompensaciones, setLoadingCompensaciones] = useState(true);
    const [datosAsistencia, setDatosAsistencia] = useState([]);
    const [datosCompensaciones, setDatosCompensaciones] = useState([]);
    const [tabSelected, setTabSelected] = useState("0");
    const [selectedFoto, setSelectedFoto] = useState(null);

    //get days count between two dates
    const getDaysCount = (startDate, endDate) => {
        let count = 0;
        let currentDate = new Date(startDate);
        while (currentDate <= new Date(endDate)) {
            count++;
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return count;
    };


    useEffect(() => {
        const fetchData = async () => {
            console.log('cargando');
            let idToast = toast.info(
                <div>
                    <span>Cargando datos de asistencia...</span>{" "}
                    <CircularProgress size={"1.0rem"} style={{ marginBottom: "0px" }} />
                </div>,
                { autoClose: false }
            );
            let uri = `api/asistencia/getReportePorCaporal?fechaInicio=${FechaInicio}&fechaFin=${FechaFin}`;
            const request = await API.fetchGetRequest(uri);
            if (request.data.response) {
                setDatosAsistencia(request.data.data);
                console.log(request.data.data)
            } else {
                console.log(request.data.mensaje);
                toast.error("Ocurrió un error al cargar los datos.");
            }
            console.log(request.data.data);
            setLoading(false);
            toast.dismiss(idToast);
        };
        try {
            setLoading(true);
            fetchData();
            fetchDataCompensaciones();
        } catch (error) {
            console.log(error);
            toast.error('Ocurrió un error al cargar los datos.');
        }
    }, [FechaInicio, FechaFin]);

    const fetchDataCompensaciones = async () => {
        setLoadingCompensaciones(true);
        console.log('cargando compensaciones');
        let idToast = toast.info(
            <div>
                <span>Cargando datos de compensaciones...</span>{" "}
                <CircularProgress size={"1.0rem"} style={{ marginBottom: "0px" }} />
            </div>,
            { autoClose: false }
        );
        let uri = `api/asistencia/getReporteCompensaciones?fechaInicio=${FechaInicio}&fechaFin=${FechaFin}`;
        const request = await API.fetchGetRequest(uri);
        if (request.data.response) {
            setDatosCompensaciones(request.data.data);
            console.log(request.data.data)
        } else {
            console.log(request.data.mensaje);
            toast.error("Ocurrió un error al cargar los datos de compensaciones.");
        }
        //setLoading(false);
        setLoadingCompensaciones(false);
        console.log(request.data.data);
        toast.dismiss(idToast);
    }

    const reprocesarAsistentes = async () => {
        let toastId = toast.info(
            <div>
                <span>Reconociendo pendientes...</span> <CircularProgress size={'1.0rem'} style={{ marginBottom: "0px" }} />
            </div>
            , { autoClose: false });


        let TiposGestion = '';
        try {
            const getTiposGestion = await API.fetchGetRequest(`api/asistencia/getCodigosProblemaCosecha`);
            if (getTiposGestion.data.response) {
                console.log(getTiposGestion.data.data);
                TiposGestion = getTiposGestion.data.data.CodigosProblemaCosecha.join(',');
            }
            else {
                toast.error('Error al obtener tipos de gestión.');
                console.log(getTiposGestion.data.mensaje);
                toast.dismiss(toastId);
                return;
            }
        } catch (error) {
            console.log("reprocesarAsistentes error:" + error);
            toast.error('Error al obtener tipos de gestión.');
            toast.dismiss(toastId);
            return;
        }

        const request = await API.fetchGetRequest(`api/asistencia/reconocerAsistencias?inicio=${FechaInicio}&fin=${FechaFin}&tipoGestion=${TiposGestion}`);
        try {
            if (request.data.response) {
                toast.success('Asistentes reconocidos: ' + request.data.data.AsistenciasEncontradas);
                añadirReconocidosAsistencias(request.data.data.AsistenciasNuevas);
                dataGrid.current.instance.refresh();
                fetchDataCompensaciones();

            } else {
                toast.error('Error al reprocesar asistentes.');
                console.log(request.data.mensaje);
            }
        } catch (error) {
            console.log("reprocesarAsistentes error:" + error);
            toast.error('Error al reprocesar asistentes.');
        }
        toast.dismiss(toastId);
    };

    const añadirReconocidosAsistencias = (asistenciasNuevas) => {
        let asistencias = [...datosAsistencia];
        //For each asistencia nueva, join by CodigoAnterior, and add +1 to Asistencias field in datosAsistencia(asistencias)
        asistenciasNuevas.forEach((asistenciaNueva) => {
            let asistencia = asistencias.find((asistencia) => asistencia.CodigoAnterior === asistenciaNueva.codigoAnterior);
            if (asistencia) {
                asistencia.Asistencias = asistencia.Asistencias + 1;
            }
        });
        setDatosAsistencia(asistencias);
    };

    const dataGrid = useRef(null);

    const resetDataGrid = () => {
        dataGrid.current.instance.state(null);
    };

    const reprocesarAsistentesToolbarButton = () => {
        return (
            <Tooltip title="Reprocesar asistentes">
                <Button variant="outlined" size="small" onClick={reprocesarAsistentes} endIcon={<FaceIcon />}>
                    Reconocer asistentes
                </Button>
            </Tooltip>
        );
    };
    const handleFotoClick = (foto) => {
        setSelectedFoto(foto);
    };
    const handleFotoDialogClose = () => {
        setSelectedFoto(null);
    };

    const cellRenderFoto = (data) => {
        const foto = data.value;
        if (!foto || foto === "") {
            return <div style={{ width: "50px" }}>-</div>;
        }
        return (
            <div onClick={() => handleFotoClick(foto)} style={{ cursor: "pointer" }}>
                {/* <VisibilityIcon /> */}
                <img src={foto} alt="Empleado" style={{ width: "50px" }} />
            </div>
        );
    };

    const agregarBotonesExtras = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: "after",
                template: 'botonReprocesarAsistentes'
            },
            {
                location: "after",
                widget: "dxButton",
                options: {
                    icon: "refresh",
                    onClick: resetDataGrid,
                    hint: "Reiniciar tabla",
                },
            },
        );
    }

    return (
        <React.Fragment>

            <React.Fragment>
                {loading ? (
                    <>
                        <Typography variant="h6" align="center" gutterBottom >
                            Detalle de asistencias
                        </Typography>
                        <Divider />

                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <CircularProgress style={{ marginBottom: "20px" }} />
                            <br />
                            <br />
                        </div>
                    </>
                ) : (
                    <>
                        <br />
                        <TabContext value={tabSelected}>
                            <TabList
                                indicatorColor="primary"
                                textColor="primary"
                                variant="fullWidth"
                                centered
                                //selectionFollowsFocus={false}
                                onChange={(event, newValue) => {
                                    setTabSelected(newValue);
                                }}
                            >
                                <Tab label="Por frente" value="0" />
                                <Tab label="Por caporal" value="1" />
                                <Tab label="Por proceso" value="2" />
                                <Tab label="Por puesto" value="3" />
                            </TabList>
                            <TabPanel value="0">
                                <GraficasCatCosechaFrente datosAsistencia={datosAsistencia} cantidadDias={getDaysCount(FechaInicio, FechaFin)} campoAgrupacion={"FrenteNombre"} />
                            </TabPanel>
                            <TabPanel value="1">
                                <GraficasCatCosechaFrente datosAsistencia={datosAsistencia} cantidadDias={getDaysCount(FechaInicio, FechaFin)} campoAgrupacion={"CaporalNombre"} />
                            </TabPanel>
                            <TabPanel value="2">
                                <GraficasCatCosechaFrente datosAsistencia={datosAsistencia} cantidadDias={getDaysCount(FechaInicio, FechaFin)} campoAgrupacion={"ProcesoNombre"} />
                            </TabPanel>
                            <TabPanel value="3">
                                <GraficasCatCosechaFrente datosAsistencia={datosAsistencia} cantidadDias={getDaysCount(FechaInicio, FechaFin)} campoAgrupacion={"Puesto"} />
                            </TabPanel>
                        </TabContext>

                        <br />
                        <br />

                        <Typography variant="h6" align="center" gutterBottom >
                            Detalle de asistencias
                        </Typography>
                        <Divider />

                        <DataGrid
                            ref={dataGrid}
                            dataSource={datosAsistencia}
                            columnChooser={{ enabled: true }}
                            allowColumnReordering={true}
                            showBorders={true}
                            showRowLines={true}
                            height={'80vh'}
                            onToolbarPreparing={agregarBotonesExtras}
                            groupPanel={
                                {
                                    visible: true,
                                    emptyPanelText: "Arrastre un encabezado aquí para agrupar por esa columna"
                                }
                            }
                        >
                            <Export
                                enabled={true}
                                fileName="AsistenciasCosecha"
                                allowExportSelectedData={false}
                                texts={{ exportAll: "Exportar a Excel" }}
                            />
                            <ColumnChooser
                                enabled={true}
                                mode="select"

                            />
                            <Grouping autoExpandAll={false} contextMenuEnabled={true} />
                            <GroupPanel visible={true} />
                            <Paging enabled={false} />
                            <Export enabled={true} />
                            <FilterRow visible={true}>
                                <OperationDescriptions
                                    between="Entre"
                                    contains="Contiene"
                                    endsWith="Termina con"
                                    equal="Igual"
                                    greaterThan="Mayor que"
                                    greaterThanOrEqual="Mayor o igual que"
                                    lessThan="Menor que"
                                    lessThanOrEqual="Menor o igual que"
                                    notContains="No contiene"
                                    notEqual="No igual"
                                    startsWith="Empieza con" />
                            </FilterRow>
                            <Scrolling mode="virtual" rowRenderingMode="virtual" scrollByThumb={true} />
                            <HeaderFilter visible={true} />
                            <SearchPanel visible={true}
                                width={240}
                                placeholder="Buscar..." />
                            <StateStoring enabled={true} type="localStorage" storageKey={`DataGridAsistenciasCosecha`} />
                            <Template name="botonReprocesarAsistentes" render={reprocesarAsistentesToolbarButton} />
                            <Column dataField="CaporalNombre" caption="Caporal" />
                            <Column dataField="NombreCompleto" caption={"Nombre"} />
                            <Column dataField="CodigoAnterior" caption={"Código"} />
                            <Column dataField="PuestoId" caption={"PuestoId"} visible={false} />
                            <Column dataField="Puesto" caption={"Puesto"} />
                            <Column dataField="CaporalId" caption={"CaporalId"} visible={false} />
                            <Column dataField="ProcesoId" caption={"ProcesoId"} visible={false} />
                            <Column dataField="ProcesoNombre" caption={"Proceso"} />
                            <Column dataField="FrenteId" caption={"FrenteId"} visible={false} />
                            <Column dataField="FrenteNombre" caption={"Frente"} />
                            <Column dataField="Asistencias" caption={"Asistencias"} dataType='number' width="auto" />
                            <Column dataField="Inasistencias" caption={"Inasistencias"} dataType='number' width="auto" />
                            <Column dataField="DiasSinRegistro" caption={"Faltas"} dataType='number' width="auto" />
                            <Summary>
                                <GroupItem
                                    column="NombreCompleto"
                                    summaryType="count"
                                    displayFormat={"Total: {0}"}
                                />
                                <GroupItem
                                    column="Asistencias"
                                    summaryType="sum"
                                    displayFormat={"Asistencias: {0}"}
                                />
                                <GroupItem
                                    column="Inasistencias"
                                    summaryType="sum"
                                    displayFormat={"Inasistencias: {0}"}
                                />
                                <GroupItem
                                    column="DiasSinRegistro"
                                    summaryType="sum"
                                    displayFormat={"Faltas: {0}"}
                                />

                            </Summary>
                        </DataGrid>
                    </>
                )}
                <br />
                <br />
                <Typography variant="h6" align="center" gutterBottom >
                    Compensaciones
                </Typography>
                <Divider />

                {loadingCompensaciones ? (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <br />
                        <br />
                        <CircularProgress style={{ marginBottom: "20px" }} />
                        <br />
                        <br />
                    </div>
                ) : (
                    <DataGrid
                        dataSource={datosCompensaciones}
                        columnChooser={{ enabled: true }}
                        allowColumnReordering={true}
                        showBorders={true}
                        showRowLines={true}
                        height={'80vh'}
                        columnAutoWidth
                        onToolbarPreparing={agregarBotonesExtras}
                        groupPanel={
                            {
                                visible: true,
                                emptyPanelText: "Arrastre un encabezado aquí para agrupar por esa columna"
                            }
                        }
                    >
                        <Column dataField="Foto" caption="Foto" cellRender={cellRenderFoto} />
                        <Column dataField="CodigoEmpleado" caption="Código" />
                        <Column dataField="NombreEmpleado" caption="Nombre" />
                        <Column dataField="Tipo" caption="Tipo" />
                        <Column dataField="Motivo" caption="Motivo" />
                        <Column dataField="DPI" caption="DPI" />
                        <Column dataField="Fecha" caption="Fecha" />
                        <Column dataField="Hora" caption="Hora" />
                        <Column dataField="Frente" caption="Frente" />
                        <Column dataField="NumeroFrente" caption="# Frente" />
                        <Column dataField="Turno" caption="Turno" />
                        <Column dataField="Puesto" caption="Puesto" />
                        <Column dataField="CodigoAviso" caption="Aviso" />

                        <Template name="botonReprocesarAsistentes" render={reprocesarAsistentesToolbarButton} />
                        <Scrolling mode="virtual" rowRenderingMode="virtual" />

                        <Export
                            enabled={true}
                            fileName="CompensacionesCosecha"
                            allowExportSelectedData={false}
                            texts={{ exportAll: "Exportar a Excel" }}
                        />
                        <ColumnChooser
                            enabled={true}
                            mode="select"

                        />
                        <Grouping autoExpandAll={false} contextMenuEnabled={true} />
                        <GroupPanel visible={true} />
                        <Paging enabled={false} />
                        <FilterRow visible={true}>
                            <OperationDescriptions
                                between="Entre"
                                contains="Contiene"
                                endsWith="Termina con"
                                equal="Igual"
                                greaterThan="Mayor que"
                                greaterThanOrEqual="Mayor o igual que"
                                lessThan="Menor que"
                            />
                        </FilterRow>
                        <HeaderFilter visible={true} />
                        <SearchPanel visible={true}
                            width={240}
                            placeholder="Buscar..." />
                        <StateStoring enabled={true} type="localStorage" storageKey={`DataGridCompensacionesCosecha`} />

                    </DataGrid>
                )}

                <br />

                {selectedFoto && (
                    <Dialog open={true} onClose={handleFotoDialogClose}>
                        <DialogContent style={{ display: "flex" }}>
                            <img
                                src={selectedFoto}
                                alt="Empleado"
                                style={{ maxHeight: "100%", objectFit: "contain" }}
                            />
                        </DialogContent>
                    </Dialog>
                )}


            </React.Fragment>


        </React.Fragment>
    );
};

export default AsistenciasCosecha;