import React, { useState, useEffect } from "react";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import EditIcon from "@material-ui/icons/Edit";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  Dot,
  DotsWrapper,
  ButtonPlay,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import "./mi-carrusel.css";

const MiCarrusel = ({ cardProp }) => {
  const [cards, setCards] = useState([]);

  useEffect(() => {
    setCards(cardProp);
  }, [cardProp]);

  const buttonClasses = {
    carouselButton: true,
    buttonBack: true,
  };

  return (
    <CarouselProvider
      naturalSlideWidth={100}
      naturalSlideHeight={17}
      totalSlides={cards.length || 0}
      isPlaying={true}
      interval={5000}
      infinite={true}
      className="carouselContainer"
    >
      <Slider>
        {cards.map((item, index) => (
          <Slide index={index}>{item.content}</Slide>
        ))}
      </Slider>

      <div className="carouselDotGroup">
        <div style={{ width: "100%", textAlign: "center" }}>
          {cards.map((_, index) => (
            <Dot slide={index} key={index} className="dot">
              o
            </Dot>
          ))}
        </div>
      </div>

      <ButtonBack className="carouselLeftButton">
        <ChevronLeftIcon />
      </ButtonBack>
      <ButtonNext className="carouselRightButton">
        <ChevronRightIcon />
      </ButtonNext>
    </CarouselProvider>
  );
};

export default MiCarrusel;
