// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent} from "firebase/analytics";
import { isSignedIn } from "./auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBHhGnvVihJ-Smx3vUh6eOZDYzvZLxllQw",
  authDomain: "appilunotificaciones.firebaseapp.com",
  projectId: "appilunotificaciones",
  storageBucket: "appilunotificaciones.appspot.com",
  messagingSenderId: "805135461604",
  appId: "1:805135461604:web:953665c321b33dd150d0f1",
  measurementId: "G-2HTM2NMH9Y"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// export { app, analytics };
export const registrarAnalitica =async (evento)=>{
    try {
   console.log('Registrando analítica: ', evento);
    const session = await isSignedIn();
    //analytics().logEvent('mi_evento', { propiedad: 'valor' });
    logEvent(analytics,'Pantalla_GestionApp',{
        pantalla: evento,
        usuario: session.userName,
        //description: ['asdf', 'lkj'],
      })
           
    } catch (error) {
        console.error('Error al registrar analítica: ', error);
    }
}


