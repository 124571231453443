import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";

import { convertirHHMM } from "../../utils/helpers";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

export default function TagList(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <List component="nav" aria-label="main mailbox folders">
        {props.registros.map((item) => (
          <ListItem
            button
            onClick={async () => {
              await props.agregarCausas(item.id);
              props.closeModal();
            }}
          >
            <ListItemIcon>
              <LocalOfferIcon />
            </ListItemIcon>
            <ListItemText
              primary={item.name}
              // secondary={
              //   item.TiempoEsperaEstimado > 0
              //     ? convertirHHMM(item.TiempoEsperaEstimado)
              //     : ""
              // }
            />
          </ListItem>
        ))}
      </List>
    </div>
  );
}
