import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";

//input date
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';

//Own components
import Layout from "../../layout/containers/Layout";
import EtiquetaListTable from "../components/etiqueta-list-table";
import AddButton from "../../helpers/components/add-button-float";
import API from "../../utils/api";
import { isSignedIn } from "../../utils/auth";
import {convertirASegundos,convertToYearMonthDay,convertDMY,convertYMD,convertirHHMM} from "../../utils/helpers";
import {validaPermisoPaginaActual} from "../../utils/helpers";

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0-9]/,/[0-9]/,':',/[0-5]/,/[0-9]/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%"
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none"
  },
  buttonAdd: {
    margin: theme.spacing.unit
  }
});

class EtiquetaList extends Component {
  state = {
    open: false,
    actionType: "", //Crear, Actualizar
    codigo: "",
    nombre: "",
    tiempoEsperaEstimado:"",
    tiempoEsperaEstimadoHoras:"",
    codigoSubEntidad:null,
    publico:"",
    activo: "",
    causas: [],
    codigoFamiliaEtiqueta:null,
  };

  async componentWillMount() {
     //CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
     const llaveAgrupacion = "Menu.Principal.Web";
     const llavePagina = "Menu.Principal.Web.Etiquetas";
     await validaPermisoPaginaActual(llaveAgrupacion, llavePagina);
     //FIN DE CONSULTA DE PERMISOS

    if(this.props.match.params.codigoFamiliaEtiqueta==null || this.props.match.params.codigoFamiliaEtiqueta==undefined){
      window.location.href ="/home";
    }
    this.setState({codigoFamiliaEtiqueta:this.props.match.params.codigoFamiliaEtiqueta},
      ()=>{
        this.cargarTituloFamilia();
        this.cargarLista();}
      )
    
  }

  async cargarTituloFamilia() {
    const request = await API.fetchGetRequest(
      "api/familiaEtiqueta/cargarDatosEdicion/" + this.state.codigoFamiliaEtiqueta
    );

    this.setState({
      nombreFamilia: request.data.data.Nombre,     
    });
  }

  async cargarLista() {
    const request = await API.fetchGetRequest("api/causa/listarPorFamilia/"+this.state.codigoFamiliaEtiqueta);

    this.setState({
      causas: request.data.data
    });
  }

  async cargarDatosEdicion() {
    const request = await API.fetchGetRequest(
      "api/causa/cargarDatosEdicion/" + this.state.codigo
    );

    this.setState({
      codigo: request.data.data.CodigoCausa,
      nombre: request.data.data.Nombre,
      tiempoEsperaEstimado: request.data.data.TiempoEsperaEstimado,
      tiempoEsperaEstimadoHoras:convertirHHMM(request.data.data.TiempoEsperaEstimado) ,
      publico:request.data.data.Publico,
      activo: request.data.data.Activo
    });
  }

  editar = codigoCausa => {
    this.setState(
      {
        codigo: codigoCausa,
        actionType: "Actualizar"
      },
      async () => {
        await this.cargarDatosEdicion();
        this.handleOpenAddModal();
      }
    );
  };

  handleOpenAddModal = () => {
    this.setState({
      open: true
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
      codigo: "",
      nombre: "",
      tiempoEsperaEstimado:"",
      tiempoEsperaEstimadoHoras: "",
      activo: "",
      publico:"",
      actionType: ""
    });
  };

  handleChange = name => event => {
    if (name === "activo" || name==="publico") {
      this.setState({ [name]: event.target.checked });
    } else if(name==="tiempoEsperaEstimadoHoras") {
      
      this.setState({
        tiempoEsperaEstimadoHoras: event.target.value,
        tiempoEsperaEstimado: convertirASegundos(event.target.value)
      });
    }
    else{
      this.setState({
        [name]: event.target.value
      });
    }
  };

  redireccionar = (codigo)=>{
    this.props.history.push("/entidad/" + codigo);
  }

  guardar = async () => {
    const { nombre } = this.state;

    if (nombre.trim() == "") {
      toast.warn("Ingrese un nombre válido");
      return;
    }

    try {
      let vIsSignedIn = await isSignedIn();
      let palabra = "";
      if (vIsSignedIn.response) {
        let request;
        let data;

        if (this.state.actionType == "Agregar") {
          data = {
            CodigoCausa: null,
            Nombre: this.state.nombre,
            CodigoSubEntidad:this.state.codigoSubEntidad,
            Publico:true,//this.state.publico,
            TiempoEsperaEstimado:this.state.tiempoEsperaEstimado,
            Activo: true,
            UsuarioOpera: vIsSignedIn.userName,
            CodigoFamiliaEtiqueta:this.state.codigoFamiliaEtiqueta,
          };
          palabra = "grabado";
        } else {
          data = {
            CodigoCausa: this.state.codigo,
            Nombre: this.state.nombre,
            CodigoSubEntidad:this.state.codigoSubEntidad,
            Publico:true,//this.state.publico,
            TiempoEsperaEstimado:this.state.tiempoEsperaEstimado,
            Activo: this.state.activo,
            UsuarioOpera: vIsSignedIn.userName,
            CodigoFamiliaEtiqueta:this.state.codigoFamiliaEtiqueta,
          };
          palabra = "editado";
        }

        request = await API.post(`api/causa/grabarEditar`, data);
        if (request.status != 200) {
          alert(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        }

        if (request.statusCode === 401) {
          //alert("cerrar sesion");
        } else {
          toast.success("El registro se " + palabra + " exitosamente.");
        
           this.handleClose();
            this.cargarLista();                 
        }
      }
    } catch (e) {
      alert("hubo un error");
    }
  };

  
  eliminar = async codigo => {
    let vIsSignedIn = await isSignedIn();

    if (vIsSignedIn.response) {
      const request = await API.post(
        `api/causa/eliminar/${codigo}/${vIsSignedIn.userName}`
      );
      if (request.status != 200) {
        alert(
          "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
        );
      }

      if (request.statusCode === 401) {
        //alert("cerrar sesion");
      } else {
        toast.success("El registro se eliminó exitosamente.");
        this.cargarLista();
      }
    } else {
      toast.error("La sessión no se encuentra activa");
    }
  };

  render() {
    const { classes } = this.props;
    return (
       <Layout titulo={`Etiquetas de la famila ${this.state.nombreFamilia}`}>
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12} sm={12}>
            <EtiquetaListTable
              data={this.state.causas}
              editar={this.editar}
              eliminar={this.eliminar}
            />
          </Grid>
        </Grid>

        <AddButton 
          onClick={() => {
            this.setState({ actionType: "Agregar" }, this.handleOpenAddModal());
          }}
        />

        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.open}
          onClose={this.handleClose}
        >
          <div style={getModalStyle()} className={classes.paper}>
            <form className={classes.container} noValidate autoComplete="off">
              <Typography variant="title" gutterBottom>
                {this.state.actionType == "Agregar" ? (
                  <div>Crear</div>
                ) : (
                  <div>Editar</div>
                )}
              </Typography>
              
              <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                required
                id="txtNombre"
                label="Nombre"
                className={classes.textField}
                value={this.state.nombre}
                onChange={this.handleChange("nombre")}
                margin="normal"
                
                inputProps={{ maxLength: 512 }}
              />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl style={{ width: "100%" }}>
                  <InputLabel htmlFor="formatted-text-mask-input">
                    Tiempo de espera (HH:mm)
                  </InputLabel>
                  <Input
                    value={this.state.tiempoEsperaEstimadoHoras}
                    onChange={this.handleChange("tiempoEsperaEstimadoHoras")}
                    id="formatted-text-mask-input"
                    inputComponent={TextMaskCustom}
                  />
                </FormControl>
              </Grid>

              {/* <div>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.publico}
                      onChange={this.handleChange("publico")}
                      value="publico"
                      color="primary"
                    />
                  }
                  label="Público"
                />
                </Grid>
                <br />
              </div> */}

              {this.state.actionType === "Actualizar" ? (
                <div>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.activo}
                        onChange={this.handleChange("activo")}
                        value="activo"
                        color="primary"
                      />
                    }
                    label="Activo"
                  />
                  </Grid>
                  <br />
                </div>
              ) : (
                <div></div>
              )}
              <Grid container spacing={3}>
                <Grid item xs={12} lg={12} sm={12}>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: "#69bd4b", color: "white" }}
                    className={classes.buttonAdd}
                    onClick={() => this.guardar()}
                  >
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </Modal>
        <ToastContainer />
        </React.Fragment>
       </Layout>
    );
  }
}
const EtiquetaListContainer = withStyles(styles)(EtiquetaList);
export default withRouter(EtiquetaListContainer);

