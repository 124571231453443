import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import {
  Typography,
  Backdrop,
  CircularProgress,
  Tab,
  Tabs,
  Box,
  Divider,
  TabScrollButton,
} from "@material-ui/core";

import Avatar from "@material-ui/core/Avatar";
import Container from "@material-ui/core/Container";
import { green } from "@material-ui/core/colors";
import { makeStyles, withStyles } from "@material-ui/core/styles";
//iconos

import {
  Face,
  PersonPin,
  AssignmentInd,
  FlightTakeoff,
} from "@material-ui/icons";

//input date
import API from "../../utils/api";
import PropTypes from "prop-types";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";

import Autocomplete from "@material-ui/lab/Autocomplete";

//Own components
// import Layout from "../../layout/containers/Layout";

import ComponentePersonalAsignado from "../components/asignados-movimiento-componente";
import ComponentePersonalImportar from "../components/traslados-movimiento-componente";
import ComponentePersonalAltas from "../components/altas-movimiento-componente";

const a11yProps = (index) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const MyTabScrollButton = withStyles((theme) => ({
  root: {
    width: 28,
    overflow: "hidden",
    transition: "width 0.5s",
    "&.Mui-disabled": {
      width: 0,
    },
  },
}))(TabScrollButton);

function TabPanelVertical(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={"span"} variant={"body2"}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

TabPanelVertical.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yPropsVertical(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const styles = makeStyles((theme) => ({
  item: {
    //border: "1px solid blue",
    margin: "10",
  },
  itemFlexGrow: {
    flexGrow: 1,
    // border: "1px solid red"
  },
  content: {
    display: "flex",
    // justify-content: "space-between",
    maxWidth: "400px",
    margin: "0 auto",
    padding: "10px 0",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
  paperPerfil: {
    position: "relative",
    width: "100%", //theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  greenAvatar: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  paperCard: {
    padding: 20,
    width: "100%",
  },
  greenAvatarFoto: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(18),
    height: theme.spacing(18),
  },
  tabsVertical: {
    borderRight: `1px solid ${theme.palette.divider}`,
    overflow: "visible",
  },
  contenedorTabVertical: {
    //flexGrow: 1,
    //backgroundColor: theme.palette.background.paper,
    display: "flex",
    //height: 224,
  },
}));

const TabsMovimientoPersonal = (props) => {
  const [tabVertical, setTabVertical] = useState(0);

  const classes = styles();

  return (
    <React.Fragment>
      <Container maxWidth="xl">
        <Box className={classes.contenedorTabVertical}>
          <Tabs
            value={tabVertical}
            indicatorColor="primary"
            textColor="primary"
            onChange={(_, val) => setTabVertical(val)}
            className={classes.tabsVertical}
            orientation="vertical"
            variant="scrollable"
            aria-label="Opciones"
          >
            <Tab
              label="Personal Actual"
              // icon={<PersonPin />}
              {...a11yPropsVertical(0)}
              style={{ textTransform: "none" }}
            />
            <Tab
              label="Traslados"
              // icon={<PersonPin />}
              {...a11yPropsVertical(1)}
              style={{ textTransform: "none" }}
            />
            <Tab
              label="Altas"
              // icon={<PersonPin />}
              {...a11yPropsVertical(2)}
              style={{ textTransform: "none" }}
            />
          </Tabs>

          <TabPanelVertical id="personalActual" value={tabVertical} index={0}>
            <Box className={classes.box} pl={0} pt={0}>
              <ComponentePersonalAsignado
                codigoUnidadOrganizacion={props.codigoUnidadOrganizacion}
                actualizarListaSolicitudes={props.actualizarListaSolicitudes}
                editarCompleto={props.editarCompleto}
              />
            </Box>
          </TabPanelVertical>
          <TabPanelVertical id="personalImportar" value={tabVertical} index={1}>
            <Box className={classes.box} pl={0} pt={0}>
              <ComponentePersonalImportar
                codigoUnidadOrganizacion={props.codigoUnidadOrganizacion}
                actualizarListaSolicitudes={props.actualizarListaSolicitudes}
                editarCompleto={props.editarCompleto}
              />
            </Box>
          </TabPanelVertical>
          <TabPanelVertical id="altas" value={tabVertical} index={2}>
            <Box className={classes.box} pl={0} pt={0}>
              <ComponentePersonalAltas
                codigoUnidadOrganizacion={props.codigoUnidadOrganizacion}
                actualizarListaSolicitudes={props.actualizarListaSolicitudes}
                editarCompleto={props.editarCompleto}
              />
            </Box>
          </TabPanelVertical>
        </Box>
      </Container>

      <ToastContainer />
    </React.Fragment>
  );
};

export default withRouter(TabsMovimientoPersonal);
