import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Container from '@material-ui/core/Container';

//Own Components
import Layout from "../layout/containers/Layout";
import API from "../utils/api";
import {permisoAgrupacion} from "../utils/helpers";


import { isSignedIn } from "../utils/auth";
import { Grid } from "@material-ui/core";
import katiSad from "../image_repository/msg-bw-sad.png"

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%"
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none"
  },
  buttonAdd: {
    margin: theme.spacing.unit
  }
});

class Unauthorized extends React.Component {
  state = {
    open: false,
    data: [],
    nombre: "",
    usuario: "",
    email: "",
    autenticacionWindows: "1",
    actionType: "" //Crear, Actualizar
  };

  async componentWillMount() {    

      
  }

  
  handleClose = () => {
    this.setState({
      open: false,
      nombre: "",
      usuario: "",
      email: "",
      autenticacionWindows: "1",
      actionType: ""
    });
  };

  handleOpenAddModal = () => {
    this.setState({
      actionType: "Agregar",
      open: true
    });
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  guardarUsuario = async () => {
    const { nombre, usuario, email, autenticacionWindows } = this.state;

    if (
      nombre.trim() == "" ||
      usuario.trim() == "" ||
      email.trim() == "" ||
      autenticacionWindows.trim() == ""
    ) {
      toast.warn("Llene todos los campos");
      return;
    }

    try {
      let vIsSignedIn = await isSignedIn();

      if (vIsSignedIn.response) {
        let request;
        if (this.state.actionType == "Agregar") {
          const data = {
            NombreCompletoUsuario: this.state.nombre,
            Usuario: this.state.usuario,
            CorreoElectronico: this.state.email,
            AutenticacionWindows:
              this.state.autenticacionWindows == "1" ? true : false,
            UsuarioInserto: vIsSignedIn.userName
          };

          request = await API.post(`api/desktop/usuario`, data);
          if (request.status != 200) {
            alert(
              "Hubo un error al guardar el registro, por favor revisa los datos de tu formulario o consulta con un administrador"
            );
          }
        } else {
          let data = this.state.editData;
          data.Device = this.state.dispositivo;
          data.Descripcion = this.state.dispositivoDescripcion;
          data.DeviceId = this.state.dispositivoDeviceId;
          data.Activo = this.state.dispositivoActivo == "1" ? true : false;
          data.UsuarioModifico = vIsSignedIn.userName;

          request = await API.updateDispositivo(data);

          if (request.statusCode != 200) {
            alert("Hubo un error al actualizar tu registro");
          }
        }

        if (request.statusCode === 401) {
          //alert("cerrar sesion");
        } else {
          this.props.history.push("/usuario/" + request.data.usuario.id);
        }
      }
    } catch (e) {
      alert("hubo un error");
    }
  };

  editItem = async item => {
    await this.setState({
      open: true,
      dispositivo: item.Device,
      dispositivoDescripcion: item.Descripcion,
      dispositivoDeviceId: item.DeviceId,
      dispositivoActivo: item.Activo ? "1" : "0",
      actionType: "Actualizar",
      editData: item
    });
  };

  redirectToUsuarioDetail = id => {
    this.props.history.push("/usuario/" + id);
  };

  render() {
    const { classes } = this.props;
    let { data } = this.state;

    return (
      <div>
        <Layout titulo="">
          <Container maxWidth="lg">
          <Typography variant="h4" style={{textAlign:"center", marginBottom:15}}>
            ¡Lo siento, no tienes acceso para ver este recurso!
          </Typography>
          </Container>
         <br/>
        <Container maxWidth="sm">
           
              <img src={katiSad}style={{width:"100%"}} />
         
          </Container>
          <ToastContainer />
        </Layout>
      </div>
    );
  }
}

// We need an intermediary variable for handling the recursive nesting.
const UnauthorizedContainer = withStyles(styles)(Unauthorized);

export default withRouter(UnauthorizedContainer);
