import React, { useState } from 'react'
import {
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  Button,
  makeStyles
} from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import LoadingButton from '../utils/LoadingButton'
import { toast } from 'react-toastify'
import api from '../../../utils/api'
import { getUsuarioInserta, getCodigoUsuario } from '../../utils/utilidades'

const useStyles = makeStyles(theme => ({
  deleteButton: {
    background: red[500],
    color: '#FFF'
  }
}))

const EliminarSolicitud = ({ solicitud, onRefresh, onClose }) =>  {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [descripcion, setDescripcion] = useState('')
  const [err, setErr] = useState(false)
  const eliminar = async () => {
    if (window.confirm('¿Estas seguro de eliminar el borrador?')) {
      try {
        setLoading(true)
        if (!descripcion.trim().length) {
          setErr(true)
          return
        } else {
          setErr(false)
        }
        const body = {
          UsuarioModifico: getUsuarioInserta(),
          CodigoUsuarioModifico: getCodigoUsuario(),
          Comentario: descripcion
        }
        await api.realizarPeticionPostPut(
          `api/documentos/solicitud/eliminar/${solicitud.IdSolicitud}`,
          body,
          'POST'
        )
        onRefresh()
      } catch (error) {
        toast.error('Ha ocurrido un error')
      } finally {
        setLoading(false)
      }
    }
  }
  return <>
    <DialogTitle>Eliminar solicitud</DialogTitle>
    <DialogContent>
      <TextField
        fullWidth
        multiline
        rows={3}
        error={err}
        value={descripcion}
        label="Comentario"
        onChange={ev => setDescripcion(ev.target.value)}
        helperText={err ? 'El campo es requerido' : ''}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancelar</Button>
      <LoadingButton
        onClick={eliminar}
        loading={loading}
        className={classes.deleteButton}
      >
        Eliminar
      </LoadingButton>
    </DialogActions>
  </>
}

export default EliminarSolicitud
