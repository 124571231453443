import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";

import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Checkbox,
  FormControlLabel,
  Divider,
  Paper,
  Tooltip,
  Modal,
  Fade,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Box,
} from "@material-ui/core";

import DataGrid, {
  Column,
  Pager,
  Paging,
  Sorting,
  ColumnFixing,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Grouping,
  GroupPanel,
  Export,
  Selection,
  Editing,
  Scrolling,
  LoadPanel,
  Lookup,
  Popup,
  Form,
} from "devextreme-react/data-grid";

import CustomStore from "devextreme/data/custom_store";

import BackdropModal from "@material-ui/core/Backdrop";

import { Delete, ViewList, Add, Edit } from "@material-ui/icons";

import { green } from "@material-ui/core/colors";

// import DetalleFuncionComponente from "./detalle-funcion-puesto-componente";
import API from "../../utils/api";
import { isSignedIn, signOut } from "../../utils/auth";
import { validaPermisoPaginaActual } from "../../utils/helpers";
import { ToastContainer, toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import "devextreme/dist/css/dx.material.teal.light.compact.css";
import { Item } from "devextreme-react/form";

const width_proportion = "100%";
const titleColor = "#2B3C4D";

const useStyles = makeStyles((theme) => ({
  paperContent: {
    padding: "6px 16px",
  },
  table: {
    width: "100%",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 210,
  },
  textFieldDireccion: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 230,
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  greenAvatar: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  paperCard: {
    padding: 20,
    width: "100%",
  },
  formControlSelects: {
    // margin: theme.spacing(1),
    minWidth: 400,

    marginBottom: 10,
    marginTop: 10,
    marginLeft: 30,
  },
  formControlSelect: {
    // margin: theme.spacing(1),
    width: width_proportion,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  rootTabla: {
    width: "100%",
    marginTop: theme.spacing.unit * 1,
    overflowX: "auto",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperModal: {
    position: "absolute",
    width: "70%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));
const MaestroPuestoLaboral = () => {
  const classes = useStyles();

  let applyFilterTypes = [
    {
      key: "auto",
      name: "Immediately",
    },
    {
      key: "onClick",
      name: "On Button Click",
    },
  ];

  const [dialogoAbierto, abrirDialogo] = useState(false);
  const [userName, setUserName] = useState("");
  const [open, setOpen] = useState(false);
  const [maestroExpertis, setMestroExpertis] = useState([]);
  const [maestroEducacion, setMaestroEducacion] = useState([]);

  const [puestoLaboralEducacion, setPuestoLaboralEducacion] = useState([]);
  const [codigoEducacion, setCodigoEducacion] = useState(0);
  const [codigoExpertis, setCodigoExpertis] = useState(0);
  const [numeroVersion, setNumeroVersion] = useState("");
  const [fechaCreacion, setFechaCreacion] = useState(new Date());

  const [valueAutoCompleteExpertis, setValueAutoCompleteExpertis] =
    useState(null);
  const [valueAutoCompleteEducacion, setValueAutoCompleteEducacion] =
    useState(null);

  const [positionDisableSorting, setPositionDisableSorting] = useState(false);
  const [showFilterRow, setShowFilterRow] = useState(true);
  const [showHeaderFilter, setShowHeaderFilter] = useState(true);
  const [currentFilter, setCurrentFilter] = useState(applyFilterTypes[0].key);
  const [autoExpandAll, setAutoExpandAll] = useState(true);

  const [buscando, setBackDrop] = useState(false);

  const [MaestroFuncionesNegocio, setEmpleadoFuncionesNegocio] = useState(
    new CustomStore({
      key: "CodigoActividadNivelPuestoLaboral",
      load: () => cargarDatos("api/ActividadNivelPuestoLaboral/" + 2),
      insert: (values) => guardarDato("api/Educacion/", null, values),
      update: (key, values) => guardarDato("api/Educacion/", key, values),
      remove: (key, values) =>
        guardarDato("api/Educacion/Eliminar/", key, values),
    })
  );
  const [datosMaestroNivelPuestoLaboral, setDatosMaestroNivelPuestoLaboral] =
    useState(
      new CustomStore({
        key: "CodigoNivelPuestoLaboral",
        loadMode: "raw",
        load: () => cargarDatos("api/NivelPuestoLaboral"),
      })
    );

  useEffect(() => {
    const cargar = async () => {
      // CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
      const llaveAgrupacion = "Menu.Principal.Web";
      const llavePagina = "Menu.Principal.Web.PuestoLaboral";
      await validaPermisoPaginaActual(llaveAgrupacion, llavePagina);
      // FIN DE CONSULTA DE PERMISOS

      handleAbrirBackDrop();

      // await cargarMaestroExpertis();
      // await cargarMaestroEducacion();
      // await cargarMaestroResponsabilidadPuestoLaboral();
      handleCerrarBackDrop();
    };

    cargar();
  }, []);

  const cargarDatos = async (url) => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      console.log(session);
      setUserName(session.userName);
    }

    try {
      handleAbrirBackDrop();

      let uri = url;
      //await delay(1000000);
      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        if (request.data.response) {
          return request.data.ficha;
        } else {
          console.log("EXCEPTION: " + request.data.mensaje);
          toast.error(
            "Hubo un error al cargar los datos. " + request.data.mensaje
          );
          return [];
        }
        //setVerbos(request.data.datos);
      }
    } catch (e) {
      console.log(e);
      toast.error("Hubo un error al cargar los datos. " + e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const handleCerrarBackDrop = () => {
    setBackDrop(false);
  };
  const handleAbrirBackDrop = () => {
    setBackDrop(true);
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const guardarDato = async (url, key, values) => {
    const data = {
      CodigoEducacion: key,
      CodigoNivelEducativo: values ? values.CodigoNivelEducativo : null,
      CodigoFamiliaEducacion: values ? values.CodigoFamiliaEducacion : null,
      Descripcion: values ? values.Descripcion : null,
      Activo: values ? (values.Activo == null ? false : values.Activo) : null,
      Eliminado: false,
      UsuarioInserto: userName,
      FechaInserto: "",
      UsuarioModifico: "",
      FechaModifico: "",
    };

    console.log(data);
    // dataFicha.usuarioInserto = "prueba";
    try {
      handleAbrirBackDrop();
      let vIsSignedIn = await isSignedIn();
      if (vIsSignedIn.response) {
        let request;

        request = await API.post(url, data);
        if (request.status !== 200) {
          toast.error(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        } else {
          if (request.data.response) {
            toast.success("Se guardó exitosamente");
          } else {
            toast.error(
              "Hubo un error al realizar la operación: " + request.data.mensaje
            );
          }
        }
      }
    } catch (e) {
      console.log("EXCEPTION: " + e);
      toast.error(
        "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
      );
    } finally {
      handleCerrarBackDrop();
    }
  };

  const onExporting = async (e) => {};

  const quitarDato = async (url, key) => {
    handleAbrirBackDrop();
    try {
      let uri = url + key;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        toast.error(
          "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
        );
      } else {
        toast.success("Se quitó el registro con éxito", {
          position: "bottom-right",
          autoClose: 3000, // Esta en milisegundos 3 segundos
        });
      }
    } catch (error) {
      console.log("Error en  eliminación de proyectos" + error);
    }

    handleCerrarBackDrop();

    //alert("Quitar recurso del proyecto" + codigoRecurso);
  };

  const handleOnChangeExpertis = async (event, newValue) => {
    handleAbrirBackDrop();
    if (newValue !== null) {
      setCodigoExpertis(newValue.CodigoExpertis);
      setValueAutoCompleteExpertis(newValue);
    }
    handleCerrarBackDrop();
  };

  const handleOnChangeEducacion = async (event, newValue) => {
    handleAbrirBackDrop();
    if (newValue !== null) {
      setCodigoEducacion(newValue.CodigoEducacion);
      setValueAutoCompleteEducacion(newValue);
    }
    handleCerrarBackDrop();
  };

  return (
    <React.Fragment>
      <Backdrop className={classes.backdrop} open={buscando}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer />
      <Grid container spacing={3}>
        <Grid item xs={6} sm={6} md={12} lg={12}>
          {MaestroFuncionesNegocio !== undefined ? (
            <Paper className={classes.rootTabla}>
              <AppBar
                position="sticky"
                className={classes.appBar}
                style={{ backgroundColor: titleColor }}
              >
                <Toolbar>
                  <Typography
                    variant="h6"
                    noWrap
                    style={{ display: "flex", flexGrow: 1 }}
                  >
                    {`Listado de Funciones de Negocio`}
                  </Typography>
                </Toolbar>
              </AppBar>
              <DataGrid
                dataSource={MaestroFuncionesNegocio}
                //   defaultColumns={this.props.columns}
                showBorders={true}
                rowAlternationEnabled={true}
                //ref={(ref) => (dataGrid = ref)}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                onExporting={onExporting}
                className={classes.table}
                id="tablaEducacionEmpleado"
                wordWrapEnabled={true}
              >
                <FilterRow
                  visible={showFilterRow}
                  applyFilter={currentFilter}
                />
                <HeaderFilter visible={showHeaderFilter} />
                <GroupPanel visible={false} />
                <Grouping autoExpandAll={autoExpandAll} />
                <SearchPanel
                  visible={false}
                  width={240}
                  placeholder="Search..."
                />
                {/* <Selection mode="multiple" /> */}
                <LoadPanel
                  enabled={true}
                  shadingColor="rgba(0,0,0,0.4)"
                  showIndicator={true}
                  shading={true}
                  showPane={true}
                  closeOnOutsideClick={false}
                />
                <Editing
                  mode="popup"
                  useIcons={true}
                  allowAdding={true}
                  allowUpdating={true}
                  allowDeleting={true}
                >
                  <Popup
                    title="Información del Puesto Laboral"
                    showTitle={true}
                    width={700}
                    height={525}
                  />
                  <Form>
                    <Item
                      itemType="group"
                      // caption="Home Address"
                      colCount={1}
                      colSpan={1}
                    >
                      <Item dataField="CodigoNivelEducativo" />
                      <Item dataField="CodigoFamiliaEducacion" />
                      <Item dataField="Descripcion" />
                      <Item dataField="Activo" />
                    </Item>
                  </Form>
                </Editing>
                <Column
                  dataField="CodigoNivelPuestoLaboral"
                  caption="Nivel Puesto Laboral"
                >
                  <Lookup
                    dataSource={datosMaestroNivelPuestoLaboral}
                    valueExpr="CodigoNivelPuestoLaboral"
                    displayExpr="NivelPuestoLaboral"
                  />
                </Column>

                <Column dataField="Orden" caption="Orden" dataType="number" />
                <Column
                  dataField="Descripcion"
                  caption="Descripción"
                  minWidth={350}
                  maxWidth={800}
                  // dataType="number"
                />
                <Column
                  dataField="Activo"
                  caption="Activo"
                  dataType="boolean"
                />

                <ColumnFixing enabled={true} />
                <Sorting mode="multiple" />
                <Paging defaultPageSize={20} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[20, 50]}
                  showInfo={true}
                />
                <Export enabled={true} allowExportSelectedData={true} />
                <Scrolling columnRenderingMode="virtual" />
              </DataGrid>
            </Paper>
          ) : undefined}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default MaestroPuestoLaboral;
