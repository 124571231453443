import React, { useState, useEffect } from 'react'
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Divider
} from '@material-ui/core'
import { toast } from 'react-toastify'
import api from '../../../utils/api'
import SelectUsuario from '../usuarios/SelectUsuario'

const almacenamiento = ['Fisico', 'Digital']

const FormularioRegistro = ({ onChange, listUsuarios }) => {
  const [masters, setMasters] = useState({
    retenciones: [],
    centros: []
  })
  const [datos, setDatos] = useState({
    IdTiempoRetencion: '',
    CodigoResponsable: null,
    MedioAlmacenamiento: '',
    Proteccion: '',
    DisposicionRegistrosViejos: '',
    Correlativo: 0
  })
  const getMasters = async () => {
    try {
      const reqRetenciones = api.fetchGetRequest('api/documentos/tiempo-retencion')
      const reqCentros = api.fetchGetRequest('api/documentos/centro-documental')
      const responses = await Promise.all([reqRetenciones, reqCentros])
      setMasters({
        retenciones: responses[0].data
          .filter(i => i.UnidadDeTiempo === 'dia')
          .map(i => ({label: `${Math.round(i.Valor / 365, 2)} años`, id: i.Id})),
        centros: responses[1].data.map(i => ({
          ...i,
          CodigoCentroDocumental: `CD-${i.AbreviaturaDivision}-${i.Macroproceso.AbreviaturaProceso}-${i.Correlativo}`
        }))
      })
    } catch (error) {
      toast.error('Ha ocurrido un error')
    }
  }
  const changeDatos = (key, value, parseTo=null) => {
    const tmp = {...datos}
    let parsed = null
    switch (parseTo) {
      case 'int':
        parsed = parseInt(value)
        break
      case 'float':
        parsed = parseFloat(value)
        break
      default:
        parsed = value
        break
    }
    tmp[key] = parsed
    setDatos(tmp)
    onChange({...tmp})
  }
  useState(() => {
    getMasters()
  }, [])
  return <>
    <Grid item xs={12}>
      <Divider />
    </Grid>
    <Grid item xs={12} md={6}>
      <SelectUsuario
        label="Responsable de controlar el registro"
        onSelect={selected => changeDatos('CodigoResponsable', selected)}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <FormControl fullWidth>
        <InputLabel>Tiempo de retención</InputLabel>
        <Select
          value={datos.IdTiempoRetencion}
          onChange={ev => changeDatos('IdTiempoRetencion', ev.target.value, 'int')}
        >
          {
            masters.retenciones.map((i, idx) => <MenuItem
              key={idx}
              value={i.id}
            >{i.label}</MenuItem>)
          }
        </Select>
      </FormControl>
    </Grid>
    <Grid item xs={12} md={6}>
      <FormControl fullWidth>
        <InputLabel>Medio de almacenamiento</InputLabel>
        <Select
          value={datos.MedioAlmacenamiento}
          onChange={ev => changeDatos('MedioAlmacenamiento', ev.target.value)}
        >
          {
            almacenamiento.map((i, idx) => <MenuItem key={idx} value={i}>{i}</MenuItem>)
          }
        </Select>
      </FormControl>
    </Grid>
    <Grid item xs={12} md={6}>
      <TextField
        fullWidth
        label="Protección"
        value={datos.Proteccion}
        onChange={ev => changeDatos('Proteccion', ev.target.value)}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <TextField
        fullWidth
        label="Disposición de registros viejos"
        value={datos.DisposicionRegistrosViejos}
        onChange={ev => changeDatos('DisposicionRegistrosViejos', ev.target.value)}
      />
    </Grid>
    <Grid item xs={12}>
      <Divider />
    </Grid>
  </>
}

export default FormularioRegistro
