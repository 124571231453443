import { Box, Button, Checkbox, FormControlLabel, Grid, TextField, Typography } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Layout from "../../layout/containers/Layout";
import GridInconsistencias from "./grid";
import API from "../../utils/api";
import { registrarAnalitica } from "../../utils/firebase-config";


const styles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexWrap: "wrap",
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: "100%",
    },
    paperPerfil: {
        position: "relative",
        width: "100%", //theme.spacing.unit * 50,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 1,
        outline: "none",
    },
    paper: {
        position: "absolute",
        width: theme.spacing.unit * 100,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 1,
        outline: "none",
    },
    buttonAdd: {
        margin: theme.spacing.unit,
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    large: {
        width: theme.spacing(12),
        height: theme.spacing(12),
    },
    greenAvatar: {
        fontSize: "2.5em",
        color: "#fff",
        // borderStyle:'solid',
        // borderWidth:'1 !important',
        // borderColor:'#3f51b5 !important',
        backgroundColor: green[500],
        width: theme.spacing(12),
        height: theme.spacing(12),
    },
    paperCard: {
        padding: 20,
        width: "100%",
    },
    box: {
        position: "relative",
        width: "100%", //theme.spacing.unit * 50,
        padding: theme.spacing.unit * 1,
        outline: "none",
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    container: {
        display: "flex",
        flexWrap: "wrap",
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));

const ReporteInconsistenciaAsistencias = (props) => {
    const classes = styles();

    const [fechaInicio, setFechaInicio] = useState(new Date().toISOString().slice(0, 10));
    const [fechaFin, setFechaFin] = useState(new Date().toISOString().slice(0, 10));
    const [submitted, setSubmitted] = useState(false);
    const [asistenciasNoApunte, setAsistenciaNoApunte] = useState([]);
    const [apuntesNoAsistencia, setApuntesNoAsistencia] = useState([]);

    useEffect(()=>{
        registrarAnalitica('Reporte_Inconsistencias_Asistencia');
    },[]);

    const actualizarFiltros = async(e) => {
        e.preventDefault();
        if(!fechaInicio){
            return;
        }
        setSubmitted(true);            
            let uri = `api/asistencia/reporteInconsistencias?fechaInicio=${fechaInicio}`;
            const request = await API.fetchGetRequest(uri);
            console.log('request', request);
            setAsistenciaNoApunte(request.data.data.asistenciasSinApunte);
            setApuntesNoAsistencia(request.data.data.apuntesSinAsistencia);
            
            // const dataAsistencia = [
            //     { 
            //         id:1,
            //         codigoAviso:100, 
            //         DPI:'2524218330501', 
            //         nombre: 'Ardani Ramírez', 
            //         fecha: '31/08/2023', 
            //         fechaHora: '31/08/2023 04:30',
            //         latitud:94,
            //         longitud:-14
            //     },
            //     { 
            //         id:2,
            //         codigoAviso:100, 
            //         DPI:'2524218330501', 
            //         nombre: 'Maco Perez', 
            //         fecha: '31/08/2023', 
            //         fechaHora: '31/08/2023 04:30',
            //         latitud:94,
            //         longitud:-14
            //     },
            //     { 
            //         id:3,
            //         codigoAviso:100, 
            //         DPI:'2524218330501', 
            //         nombre: 'Brian Cuevas', 
            //         fecha: '31/08/2023', 
            //         fechaHora: '31/08/2023 04:30',
            //         latitud:94,
            //         longitud:-14
            //     }
            // ]

            // const dataApuntes = [
            //     { 
            //         id:1,
            //         codigoAviso:200, 
            //         DPI:'2524218330501', 
            //         nombre: 'Axel Meza', 
            //         fecha: '31/08/2023', 
            //         fechaHora: '31/08/2023 04:30',
            //         latitud:94,
            //         longitud:-14
            //     },
            //     { 
            //         id:2,
            //         codigoAviso:200, 
            //         DPI:'2524218330501', 
            //         nombre: 'Antonio Zúñiga', 
            //         fecha: '31/08/2023', 
            //         fechaHora: '31/08/2023 04:30',
            //         latitud:94,
            //         longitud:-14
            //     },
            //     { 
            //         id:3,
            //         codigoAviso:200, 
            //         DPI:'2524218330501', 
            //         nombre: 'Ricardo Ramírez', 
            //         fecha: '31/08/2023', 
            //         fechaHora: '31/08/2023 04:30',
            //         latitud:94,
            //         longitud:-14
            //     }
            // ];

            // setAsistenciaNoApunte(dataAsistencia);
            // setApuntesNoAsistencia(dataApuntes);
            
    }   
    

    return (
        <Layout titulo={`Reporte de inconsistencias en asistencias de Personal`} maxWidth={"100%"}>
            <React.Fragment>
                <ToastContainer />
                <Box className={classes.paperPerfil} pl={0} pt={0}>
                    <Box className={classes.box} pl={0} pt={0}>
                        <br></br>

                        <Grid container spacing={0}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <form className={classes.container} noValidate >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={3} md={3}>
                                        <TextField
                                        id="fechaInicio"
                                        label="Fecha"
                                        type="date"
                                        //defaultValue={new Date()-10}
                                        style={{ width: "100%" }}
                                        value={fechaInicio}
                                        onChange={(event)=>setFechaInicio(event.target.value)}
                                        InputLabelProps={{
                                        shrink: true,
                                        }}
                                    />
                                    
                                        </Grid>
                                        {/* <Grid item xs={12} sm={3}>
                                            <TextField
                                                id="date-fin"
                                                label="Fecha de Fin"
                                                type="date"
                                                defaultValue={new Date().toISOString().slice(0, 10)}
                                                variant="outlined"
                                                className={classes.textField}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid> */}
                                
                                        <Grid item xs={12} sm={2} md={2} 
                                        // style={{ textAlign: "right", marginRight: "100px" }}
                                        >
                                            <Button
                                                className="btn btn-primary"                                                
                                                variant="contained"
                                                style={{
                                                    backgroundColor: "#69bd4b",
                                                    color: "white",
                                                }}
                                                onClick={actualizarFiltros}
                                            >Generar Reporte</Button>
                                        </Grid>
                                    </Grid>
                                </form>

                            </Grid>
                        </Grid>
                        <br></br>
                    </Box>

                    {submitted &&(
                        <Box>
                            <Typography variant="h5" style={{marginTop:25}}>Personas que asistieron, pero no tienen apunte de labores</Typography>
                            <Box>
                                <GridInconsistencias filas ={asistenciasNoApunte} />
                            </Box>

                            <Box>
                            <Typography variant="h5" style={{marginTop:25}}>Personas con apunte de labores, pero sin asistencia registrada</Typography>
                                <GridInconsistencias filas ={apuntesNoAsistencia} />
                            </Box>
                        </Box>

                    )}
                    
                </Box>

            </React.Fragment>
        </Layout>
    );
};

export default withRouter(ReporteInconsistenciaAsistencias);