import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";

import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import { toast } from "react-toastify";

import { IOSSwitch } from "../../helpers/components/ios-switch";

import API from "../../utils/api";
import { isSignedIn } from "../../utils/auth";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
}));

function ModalCrearTarea(props) {
  const classes = useStyles();

  const [isPosting, setIsPosting] = useState(false);
  const [titulo, setTitulo] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [secuencial, setSecuencial] = useState(true);
  const [cambiaEstadoPorHijos, setCambiaEstadoPorHijos] = useState(true);

  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  const guardar = async () => {
    if (titulo.trim() === "") {
      toast.warn("Debes ponerle un título a la tarea");
      return;
    }

    setIsPosting(true);

    const session = await isSignedIn();

    var objData = {
      Titulo: titulo,
      Descripcion: descripcion,
      Secuencial: secuencial,
      CodigoAvisoTareaPadre: props.codigoAvisoTarea,
      CodigoAviso: props.match.params.codigoGestion,
      Nivel: props.nivel,
      CodigoUsuarioOpera: session.codigoUsuario,
      CambiarEstadoPorHijos: cambiaEstadoPorHijos,
    };

    try {
      const request = await API.post(`api/aviso/tarea/crear`, objData);

      if (request) {
        if (request.data.response) {
          props.makeRequest();
          props.handleClose();

          toast.info(request.data.mensaje);
        }
      } else {
        toast.error(request.data.mensaje);
        return;
      }
    } catch (e) {}

    setIsPosting(false);
  };

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={props.open}
      onClose={props.handleClose}
    >
      <div style={getModalStyle()} className={classes.paper}>
        <form className={classes.container} noValidate autoComplete="off">
          <Typography variant="title" gutterBottom>
            Crear Tarea
          </Typography>
          <TextField
            id="txtTitulo"
            label="Titulo"
            className={classes.textField}
            onChange={(event) => setTitulo(event.target.value)}
            margin="normal"
            variant="outlined"
          />
          <TextField
            id="txtDescripcion"
            label="Descripcion"
            className={classes.textField}
            onChange={(event) => setDescripcion(event.target.value)}
            margin="normal"
            variant="outlined"
          />
          <br />

          <Grid container>
            <Grid item xs={10}>
              <Typography
                color="textSecondary"
                gutterBottom
                className={classes.textField}
              >
                Es Secuencial?
              </Typography>
            </Grid>
            <Grid item xs>
              <FormControlLabel
                className={classes.textField}
                control={
                  <IOSSwitch
                    checked={secuencial}
                    onChange={(event) => setSecuencial(event.target.checked)}
                    value="Activo"
                  />
                }
              />
            </Grid>
          </Grid>
         {/* <Grid container>
            <Grid item xs={10}>
              <Typography
                color="textSecondary"
                gutterBottom
                className={classes.textField}
              >
                Los hijos cambian el estado de esta tarea?
             </Typography>
            </Grid>
            <Grid item xs>
              <FormControlLabel
                className={classes.textField}
                control={
                  <IOSSwitch
                    checked={cambiaEstadoPorHijos}
                    onChange={(event) =>
                      setCambiaEstadoPorHijos(event.target.checked)
                    }
                    value="Activo"
                  />
                }
              />
            </Grid>
          </Grid>
                */}
          <Button
            variant="contained"
            style={{ backgroundColor: "#69bd4b", color: "white" }}
            className={classes.buttonAdd}
            onClick={guardar}
            disabled={isPosting}
          >
            Guardar
          </Button>
        </form>
      </div>
    </Modal>
  );
}

export default withRouter(ModalCrearTarea);
