import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import { render } from "react-dom";
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { DatePicker, MuiPickersUtilsProvider, Day } from "material-ui-pickers";
import Fab from "@material-ui/core/Fab";
import MaskedInput from "react-text-mask";
import DetailsIcon from "@material-ui/icons/Details";
import ListAltIcon from '@material-ui/icons/ListAlt';
//Select
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";

//Upload file
import { DropzoneArea } from "material-ui-dropzone";

//Checkbox
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import "../plantillaProblema/styles.css";
import { arrayMoveControls } from "../utils/dndUtils";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Loader from "react-loader-spinner";

//Drawer
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import DeleteForeverRoundedIcon from "@material-ui/icons/DeleteForeverRounded";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";

//Tabs
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";

//chip
import ChipInput from "material-ui-chip-input";

import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import FormList  from "../avisos-objects/components/form-list";

//Own components
import Layout from "../layout/containers/Layout";
import API from "../utils/api";
import { isSignedIn } from "../utils/auth";
import {
  convertirASegundos,
  convertToYearMonthDay,
  convertDMY,
  convertYMD,
  convertirHHMM,
} from "../utils/helpers";
import InputAdornment from "@material-ui/core/InputAdornment";
import DispositivoListContainer from "../usuario/containers/usuario-list";
import TareaCard from "./tarea-card";
import AddButton from "../helpers/components/add-button-float";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const SortableItemObject = sortableElement(
  ({ value, editar, confirmarEliminar, navegar,showForm }) => (
    <li style={{ textAlign: "right" }}>
      <IconButton
        style={{ top: "0", color: "#3f51b5" }}
        aria-label="Editar"
        onClick={(event) => {
          navegar(value.CodigoTareaPlantilla, value.Titulo, "TAREA");
        }}
      >
        <DetailsIcon />
      </IconButton>

      <IconButton
        style={{ top: "0", color: "#3f51b5" }}
        aria-label="Formulario"
        onClick={(event) => {
          showForm(value.CodigoTareaPlantilla, value.Titulo, "TAREA");
        }}
      >
        <ListAltIcon />
      </IconButton>

      <IconButton
        style={{ top: "0", color: "#3f51b5" }}
        aria-label="Editar"
        onClick={(event) => {
          editar(value.CodigoTareaPlantilla);
        }}
      >
        <EditRoundedIcon />
      </IconButton>

      <IconButton
        aria-label="Eliminar"
        style={{ top: "0", color: "#f50057" }}
        onClick={() => confirmarEliminar(value.CodigoTareaPlantilla)}
      >
        <DeleteForeverRoundedIcon />
      </IconButton>

      <div className="dragCard" data-codigocampo={value.CodigoCampo} zIndex="1">
        <TareaCard
          codigoTareaPlantilla={value.codigoTareaPlantilla}
          tipoTarea={value.NombreTareaTipo}
          titulo={value.Titulo}
          descripcion={value.Descripcion}
          activo={value.Activo}
        />
      </div>
    </li>
  )
);

const SortableContainer = sortableContainer(({ children }) => {
  return <ul>{children}</ul>;
});

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },

  paperPopover: {
    padding: theme.spacing(1),
    background: "#000",
    color: "#fff",
  },
  rootAppbar: {
    flexGrow: 1,
  },
  root: {
    //flexGrow: 1,
    width: "100%",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    // backgroundColor: fade(theme.palette.common.white, 0.15),
    // "&:hover": {
    //   backgroundColor: fade(theme.palette.common.white, 0.25),
    // },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
  paperUserModal: {
    position: "absolute",
    width: "75%",
    height: "600px",
    maxHeight: "600px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
  paperModal: {
    position: "absolute",
    width: "90%",
    height: "600px",
    maxHeight: "600px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
  paperNuevoForm: {
    position: "absolute",
    width: "50%",
    height: "300px",
    maxHeight: "300px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
  bigPaper: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  rootAppbar: {
    flexGrow: 1,
  },

  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },

  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

// const theme = useTheme();

class TaskDesigner extends Component {
  state = {
    open: false,
    openToolbar: false,
    actionType: "", //Crear, Actualizar
    items: [],
    tareaTipos: [],
    codigoPlantillaTareas: "",
    codigoTareaPlantilla: "",
    codigoTareaTipo: "",
    titulo: "",
    descripcion: "",
    comentario: "",
    orden: "",
    activo: "",
    confirmTexto: "",
    confirmOpen: false,
    codigoTareaEliminar: "",
    nombreFlujo: "",
    nombreObjetoPadre: "",
    cargando: false,
    history: [],

    codigoPlantilla:"",
    openFormPanel:false,
    textoBusquedaForm: "",
    listaForms: [],
    openChooseForm: false,
    confirmTextoCambioForm: "",
    confirmOpenCambioForm: false,
    codigoFormPendienteconfirmar: "",
    txtNombreForm:"",
    openNewForm:false,
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(
      ({ items }) => ({
        items: arrayMoveControls(items, oldIndex, newIndex),
      }),
      () => {
        this.cambiarOrden();
      }
    );
  };

  async componentWillMount() {
    if (
      this.props.match.params.codigoPlantillatareas === null ||
      this.props.match.params.codigoPlantillatareas === undefined
    ) {
      window.location = "/home";
    } else if (!isNaN(this.props.match.params.codigoPlantillatareas)) {
      //Cargar lista subentidades

      let history = this.state.history;
      let codigoPlantillaTareas = parseInt(
        this.props.match.params.codigoPlantillatareas
      );

      history.push({
        idObjeto: codigoPlantillaTareas,
        nombre: "Administrador de Avisos",
      }); //inserta la primera posición del array para navegacion

      this.setState(
        {
          codigoPlantillaTareas: codigoPlantillaTareas,
          idObjetoPadre: codigoPlantillaTareas,
        },
        async () => {
          // this.cargarLista();
          this.cargarListaTareas();
          this.cargarNombreFlujo();
          this.cargarListaTipoTarea();
        }
      );
    }
  }

  async cargarListaTareas() {
    try {
      //Loader
      this.setState({ cargando: true });

      //Muestra/oculta botón para regresar en la navegación
      this.botonBack();

      const request = await API.fetchGetRequest(
        `api/flujoV2/listaTareas/${this.state.idObjetoPadre}`
      );
      this.setState({
        items: request.data.data,
      });
      if (request.statusCode != 200) {
        alert(
          "Hubo un error al realizar la operación, consulta con un administrador"
        );
      }

      console.log(request.statusCode);
      if (request.statusCode === 401) {
        //alert("cerrar sesion");
        window.location.href = "/login";
      } else {
        if (Array.isArray(request.data.data)) {
          this.setState({
            listaObjetos: request.data.data,
          });
        }
      }
    } catch (error) {
      console.log(error);
      if (error.toString().includes("401")) {
        window.location.href = "/login";
      }
    } finally {
      this.setState({ cargando: false });
    }
  }

  async cargarLista() {
    try {
      //Loader
      this.setState({ cargando: true });

      //Muestra/oculta botón para regresar en la navegación
      this.botonBack();

      let data = {
        idObjeto: null,
        idObjetoPadre: this.state.idObjetoPadre,
        llave: "",
      };
      console.log(data);
      const request = await API.post(
        `api/procesoGestionWeb/listarObjetosHijos`,
        data
      );
      if (request.status != 200) {
        alert(
          "Hubo un error al realizar la operación, consulta con un administrador"
        );
      }

      console.log(request.status);
      if (request.statusCode === 401) {
        //alert("cerrar sesion");
        window.location.href = "/login";
      } else {
        if (Array.isArray(request.data.data)) {
          this.setState({
            listaObjetos: request.data.data,
          });
        }
      }
    } catch (error) {
      console.log(error);
      if (error.toString().includes("401")) {
        window.location.href = "/login";
      }
    } finally {
      this.setState({ cargando: false });
    }
  }

  async cargarNombreFlujo() {
    const request = await API.fetchGetRequest(
      `api/flujoV2/cargarDatosEdicion/${this.state.codigoPlantillaTareas}`
    );
    // obtiene el primer elmento del historial de navegación
    let history = this.state.history;
    let historyRoot = this.state.history[0];
    let idObjeto = historyRoot["idObjeto"];
    let nombre = request.data.data.Nombre;
    let object = {
      idObjeto: idObjeto,
      nombre: nombre,
    };

    //Limpia history
    history = [];
    history.push(object);

    this.setState({
      nombreFlujo: nombre,
      nombreObjetoPadre: nombre,
      history: history,
    });
  }

  async cargarDatosEdicion() {
    const request = await API.fetchGetRequest(
      `api/flujoV2/cargarDatosEdicionTarea/${this.state.codigoTareaPlantilla}`
    );
    this.setState({
      titulo: request.data.data.Titulo,
      descripcion: request.data.data.Descripcion,
      comentario: request.data.data.Comentario,
      codigoTareaTipo: request.data.data.CodigoTareaTipo,
      orden: request.data.data.Orden,
      activo: request.data.data.Activo,
    });
  }

  async cargarListaTipoTarea() {
    const request = await API.fetchGetRequest(`api/flujo/listaTipoTarea`);
    if (Array.isArray(request.data.data)) {
      this.setState({
        tareaTipos: request.data.data,
        codigoTareaTipo: 1,
      });
    }
  }

  async cargarListaFormularios() {
    const request = await API.fetchGetRequest(`api/flujo/listaTipoTarea`);
    if (Array.isArray(request.data.data)) {
      this.setState({
        tareaTipos: request.data.data,
        codigoTareaTipo: 1,
      });
    }
  }

  editar = async (codigo) => {
    this.setState(
      {
        codigoTareaPlantilla: codigo,
        actionType: "Actualizar",
      },
      async () => {
        await this.cargarDatosEdicion();
        this.handleOpenAddModal();
      }
    );
  };

  showForm = async (codigo) => {
    this.setState(
      {
        codigoTareaPlantilla: codigo,
        },
      async () => {
        await this.cargarListaFormularios();
        this.handleOpenFormPanel();
      }
    );
  };

  guardar = async () => {
    const { titulo } = this.state;

    if (titulo.trim() == "") {
      toast.warn("Ingrese un nombre válido");
      return;
    }

    try {
      let vIsSignedIn = await isSignedIn();
      let palabra = "";
      if (vIsSignedIn.response) {
        let request;
        let data;

        if (this.state.actionType == "Agregar") {
          data = {
            //Esta línea se cambia porque ahora el código que se envía es el del parent, este podría ser la plantillaTareas como antes
            //, pero también ahora el padre podría ser otra tarea para manejar N niveles
            // CodigoPlantillaTareas: this.state.codigoPlantillaTareas,
            CodigoPlantillaTareas: this.state.idObjetoPadre,
            CodigoTareaPlantilla: null,
            CodigoTareaTipo: this.state.codigoTareaTipo,
            Titulo: this.state.titulo,
            Descripcion: this.state.descripcion,
            Comentario: this.state.comentario,
            Orden: 0,
            Activo: true,
            Eliminado: false,
            UsuarioOpera: vIsSignedIn.userName,
          };
          palabra = "grabado";
        } else {
          data = {
            //Esta línea se cambia porque ahora el código que se envía es el del parent, este podría ser la plantillaTareas como antes
            //, pero también ahora el padre podría ser otra tarea para manejar N niveles
            // CodigoPlantillaTareas: this.state.codigoPlantillaTareas,
            CodigoPlantillaTareas: this.state.idObjetoPadre,
            CodigoTareaPlantilla: this.state.codigoTareaPlantilla,
            CodigoTareaTipo: this.state.codigoTareaTipo,
            Titulo: this.state.titulo,
            Descripcion: this.state.descripcion,
            Comentario: this.state.comentario,
            Orden: this.state.orden,
            Activo: this.state.activo,
            Eliminado: false,
            UsuarioOpera: vIsSignedIn.userName,
          };
          palabra = "editado";
        }

        request = await API.post(`api/flujoV2/grabarEditarTarea`, data);
        if (request.status != 200) {
          alert(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        }

        if (request.statusCode === 401) {
          //alert("cerrar sesion");
        } else {
          toast.success("La tarea se " + palabra + " exitosamente.");
          this.handleClose();
          this.cargarListaTareas();
        }
      }
    } catch (e) {
      alert("hubo un error");
    }
  };

  cambiarOrden = async () => {
    let vIsSignedIn = await isSignedIn();
    let object = {
      tareas: this.state.items,
      usuarioOpera: vIsSignedIn.userName,
    };
    let request = await API.post(`api/flujoV2/cambiarOrden`, object);
    if (request.status != 200) {
      alert(
        "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
      );
    }

    if (request.statusCode === 401) {
      //alert("cerrar sesion");
    } else {
      //Activos
      this.cargarListaTareas();
    }
  };

  handleChange = (name) => (event) => {
    if (name === "activo" || name === "requeridoCampo") {
      this.setState({ [name]: event.target.checked });
    } else {
      this.setState({
        [name]: event.target.value,
      });
    }
  };

  confirmarAccion = (codigoTareaPlantilla) => {
    this.setState({
      confirmTexto: `¿Confirma que desea eliminar este campo? esta acción no podrá revertirse.`,
      confirmOpen: true,
      codigoTareaEliminar: codigoTareaPlantilla,
    });
  };

  funcionOk = () => {
    this.eliminarCampo();
  };

  cerrarConfirm = () => {
    this.setState({ confirmTexto: "", confirmOpen: false });
  };

  eliminarCampo = async () => {
    try {
      let vIsSignedIn = await isSignedIn();

      if (vIsSignedIn.response) {
        const request = await API.post(
          `api/flujoV2/eliminarTarea/${this.state.codigoTareaEliminar}/${vIsSignedIn.userName}`
        );
        if (request.status != 200) {
          alert(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        }

        if (request.statusCode === 401) {
          //alert("cerrar sesion");
        } else {
          toast.success("La tarea se eliminó exitosamente.");

          this.cargarListaTareas(true);
        }
      } else {
        toast.error("La sessión no se encuentra activa");
      }
    } catch (e) {
      alert(e);
    } finally {
      this.setState({ codigoTareaEliminar: "" });
    }
  };

  handleOpenAddModal = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
      codigoTareaPlantilla: "",
      codigoTareaTipo: 1,
      titulo: "",
      descripcion: "",
      comentario: "",
      orden: "",
      activo: "",
      actionType: "",
    });
  };

  handleOpenFormPanel = () => {
    console.log("openFormPanel");
    this.setState({
      openFormPanel: true,
    });
  };

  handleCloseFormPanel = () => {
    this.setState({
      openFormPanel: false,
      codigoTareaPlantilla: "",
     });
  };

  navegar = (idObjeto, nombre, llave) => {
    if (llave === "TAREA") {
      let history = this.state.history;
      history.push({ idObjeto: idObjeto, nombre: nombre });
      this.setState(
        {
          idObjetoPadre: idObjeto,
          nombreObjetoPadre: nombre,
          history: history,
        },
        () => {
          this.cargarListaTareas();
        }
      );
    }
  };

  regresar = () => {
    let history = this.state.history;

    let cantidadPosiciones = history.length;
    let nombreObjetoPadre;
    let idObjetoPadre;
    if (cantidadPosiciones > 1) {
      //Elimina el último elemento del array que corresponde al idObjetoPadre actual
      history.pop();
      //Actualiza cantidad de elementos del array
      cantidadPosiciones = history.length;
      //Encuentra el valor de la nueva última posición del array y la asigna al idObjetoPadre
      idObjetoPadre = history[cantidadPosiciones - 1].idObjeto;
      nombreObjetoPadre = history[cantidadPosiciones - 1].nombre;

      //Actualiza valores del state
      this.setState(
        {
          idObjetoPadre: idObjetoPadre,
          nombreObjetoPadre: nombreObjetoPadre,
          history: history,
        },
        () => {
          this.cargarListaTareas();
        }
      );
    }
  };

  botonBack = () => {
    let cantidadPosiciones = this.state.history.length;
    let display;
    if (cantidadPosiciones > 1) {
      display = "inline-block";
    } else {
      display = "none";
    }

    this.setState({ botonBackDisplay: display });
  };

  render() {
    const { classes } = this.props;
    const { items } = this.state;
    return (
      <Layout titulo={"Configuración de Flujo " + this.state.nombreFlujo}>
        <div className={classes.root}>
          <AppBar position="static" style={{ width: "100%" }}>
            <Toolbar>
              <IconButton
                style={{
                  display: this.state.botonBackDisplay,
                  color: "#FFF",
                }}
                onClick={() => this.regresar()}
              >
                <ArrowBackIosIcon />
              </IconButton>
              <Typography className={classes.title} variant="h6" noWrap>
                {this.state.nombreObjetoPadre}
              </Typography>

              {/* <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Buscar..."
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ "aria-label": "Buscar" }}
                  onChange={this.handleChange("criterioBusqueda")}
                  onKeyPress={(ev) => {
                    console.log("keyP: " + ev.key);
                    if (ev.key === "Enter") {
                      this.buscar();
                      ev.preventDefault();
                    }
                  }}
                />
              </div> */}
            </Toolbar>
          </AppBar>
        </div>

        {this.state.cargando ? (
          <div
            style={{
              width: "100%",
              marginTop: 10,
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loader
              type="Oval"
              color="#3498db"
              height="50"
              width="50"
              style={{ textAlign: "center" }}
            />
          </div>
        ) : (
          <></>
        )}
        <div className={classes.root}>
          <Grid item xs={12} md={12} lg={8} sm={12} style={{ margin: "auto" }}>
            <SortableContainer pressDelay={200} onSortEnd={this.onSortEnd}>
              {items.map((value, index) => (
                <SortableItemObject
                  key={`item-${value}`}
                  index={index}
                  value={value}
                  editar={this.editar}
                  confirmarEliminar={this.confirmarAccion}
                  navegar={this.navegar}
                  showForm = {this.showForm}
                />
              ))}
            </SortableContainer>
          </Grid>
        </div>
        <AddButton
          onClick={() => {
            this.setState({ actionType: "Agregar" }, this.handleOpenAddModal());
          }}
        />

        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.open}
          onClose={this.handleClose}
        >
          <div style={getModalStyle()} className={classes.paper}>
            <form className={classes.container} noValidate autoComplete="off">
              <Typography variant="title" gutterBottom>
                {this.state.actionType == "Agregar" ? (
                  <div>Crear</div>
                ) : (
                  <div>Editar</div>
                )}
              </Typography>
              <TextField
                required
                id="txtTitulo"
                label="Título"
                className={classes.textField}
                value={this.state.titulo}
                onChange={this.handleChange("titulo")}
                margin="normal"
                inputProps={{ maxLength: 512 }}
              />
              <TextField
                id="txtDescripcion"
                label="Descripción"
                className={classes.textField}
                value={this.state.descripcion}
                onChange={this.handleChange("descripcion")}
                margin="normal"
                multiline
                inputProps={{ maxLength: 512 }}
              />
              <TextField
                id="txtComentario"
                label="Comentario"
                className={classes.textField}
                value={this.state.comentario}
                onChange={this.handleChange("comentario")}
                margin="normal"
                multiline
                inputProps={{ maxLength: 512 }}
              />
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl
                  className={classes.formControl}
                  style={{ width: "100%" }}
                >
                  <InputLabel htmlFor="codigoTareaTipo">
                    Tipo de tarea
                  </InputLabel>
                  <Select
                    name="codigoTareaTipo"
                    inputRef={this.codigoTareaTipoRef}
                    value={this.state.codigoTareaTipo}
                    onChange={this.handleChange("codigoTareaTipo")}
                    input={
                      <Input name="codigoTareaTipo" id="codigoTareaTipo" />
                    }
                  >
                    {this.state.tareaTipos.map((item) => (
                      <MenuItem
                        key={item.CodigoTareaTipo}
                        value={item.CodigoTareaTipo}
                      >
                        {item.Nombre}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText />
                </FormControl>
              </Grid>

              {this.state.actionType === "Actualizar" ? (
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.activo}
                        onChange={this.handleChange("activo")}
                        value="activo"
                        color="primary"
                      />
                    }
                    label="Activo"
                  />
                  <br />
                </div>
              ) : (
                <div></div>
              )}
              <Grid container spacing={3}>
                <Grid item xs={12} lg={12} sm={12}>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: "#69bd4b", color: "white" }}
                    className={classes.buttonAdd}
                    onClick={() => this.guardar()}
                  >
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </Modal>

        <Modal
          // style={{ zIndex: 2990 }}
          aria-labelledby="Seleccionar un formulario"
          aria-describedby="Seleccionar un formulario"
          open={this.state.openFormPanel}
          onClose={this.handleCloseFormPanel}
        >
          <div style={getModalStyle()} className={classes.bigPaper}>
            <PerfectScrollbar>
              <form className={classes.container} noValidate autoComplete="off">
                <Typography variant="h6" gutterBottom>
                  Configuración de formulario
                </Typography>

                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextField
                      id="txtTextoBusquedaForm"
                      label="Nombre"
                      className={classes.textField}
                      value={this.state.textoBusquedaForm}
                      onChange={this.handleChange("textoBusquedaForm")}
                      onKeyPress={(ev) => {
                        console.log("keyP: " + ev.key);
                        if (ev.key === "Enter") {
                          this.buscarForm();
                          ev.preventDefault();
                        }
                      }}
                      margin="normal"
                      inputProps={{ maxLength: 512 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#217ecf",
                        color: "white",
                        width: "100%",
                      }}
                      className={classes.buttonAdd}
                      onClick={() => this.buscarForm()}
                    >
                      Buscar
                    </Button>
                  </Grid>
                </Grid>

                <Grid item xs={12} lg={12} sm={12}>
                  <Grid container spacing={1}>
                    <FormList
                      registros={this.state.listaForms}
                      seleccionarForm={this.asignarForm}
                    />
                  </Grid>
                </Grid>
              </form>
            </PerfectScrollbar>
          </div>
        </Modal>
        <Modal
          // style={{ zIndex: 2990 }}
          aria-labelledby="Seleccionar un formulario"
          aria-describedby="Seleccionar un formulario"
          open={this.state.openChooseForm}
          onClose={this.handleCloseChooseForm}
        >
          <div style={getModalStyle()} className={classes.paperModal}>
            <PerfectScrollbar>
              <form className={classes.container} noValidate autoComplete="off">
                <Typography variant="h6" gutterBottom>
                  Elegir formulario
                </Typography>

                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextField
                      id="txtTextoBusquedaForm"
                      label="Nombre"
                      className={classes.textField}
                      value={this.state.textoBusquedaForm}
                      onChange={this.handleChange("textoBusquedaForm")}
                      onKeyPress={(ev) => {
                        console.log("keyP: " + ev.key);
                        if (ev.key === "Enter") {
                          this.buscarForm();
                          ev.preventDefault();
                        }
                      }}
                      margin="normal"
                      inputProps={{ maxLength: 512 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#217ecf",
                        color: "white",
                        width: "100%",
                      }}
                      className={classes.buttonAdd}
                      onClick={() => this.buscarForm()}
                    >
                      Buscar
                    </Button>
                  </Grid>
                </Grid>

                <Grid item xs={12} lg={12} sm={12}>
                  <Grid container spacing={1}>
                    <FormList
                      registros={this.state.listaForms}
                      seleccionarForm={this.asignarForm}
                    />
                  </Grid>
                </Grid>
              </form>
            </PerfectScrollbar>
          </div>
        </Modal>

        <ConfirmDialog
          id="confirmar"
          keepMounted
          open={this.state.confirmOpen}
          onClose={this.cerrarConfirm}
          value=""
          texto={this.state.confirmTexto}
          okfunction={this.funcionOk}
        />
        <ToastContainer />
      </Layout>
    );
  }
}
const TaskDesignerContainer = withStyles(styles)(TaskDesigner);
export default withRouter(TaskDesignerContainer);
