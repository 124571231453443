import React from 'react'
import ImageCard from '../../components/containers/image-card'


const GraphCard = (props) => {

    const [img,setImg] = React.useState(null)
    React.useEffect(()=>{
        loadImg()
        setImg(props.image)    
    },[])

    const loadImg = React.useCallback(
        async ()=>{
            if(props.load!==undefined){
                
                const r=await props.load();
                setImg(r)
            }
        }
    ,[])
    return (
        <div>
            <ImageCard
                long={props.long}
                primary = {props.primary}
                secondary = {props.secondary}
                third = {props.third}
                image={img===null?props.default:img}
            />
        </div>
    )
}


export default GraphCard
