import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Backdrop,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  TextField,
  Paper,
  Tooltip,
  Modal,
  Fade,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { Link } from "react-router-dom";

import BackdropModal from "@material-ui/core/Backdrop";
import BackdropModal2 from "@material-ui/core/Backdrop";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import {
  Edit,
  Delete,
  Add,
  TransferWithinAStation,
  ViewList,
  Lens,
} from "@material-ui/icons";

import { green } from "@material-ui/core/colors";

import DataGrid, {
  Column,
  Pager,
  Paging,
  Sorting,
  ColumnFixing,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Grouping,
  GroupPanel,
  Export,
  Selection,
  Editing,
  Scrolling,
  LoadPanel,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";

import API from "../../utils/api";
import { isSignedIn, signOut } from "../../utils/auth";
import {
  validaPermisoPaginaActual,
  verificaPermisoPaginaActual,
} from "../../utils/helpers";
import { ToastContainer, toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import "devextreme/dist/css/dx.material.teal.light.compact.css";

const width_proportion = "100%";
const titleColor = "#2B3C4D";

const useStyles = makeStyles((theme) => ({
  paperUserModal: {
    position: "absolute",
    width: "75%",
    height: "600px",
    maxHeight: "600px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    outline: "none",
  },
  table: {
    width: "100%",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 210,
  },
  textFieldDireccion: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 230,
  },
  paper: {
    position: "absolute",
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing(1),
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  greenAvatar: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  paperCard: {
    padding: 20,
    width: "100%",
  },
  formControlSelects: {
    // margin: theme.spacing(1),

    marginBottom: 10,
    marginTop: 10,
    width: "100%",
    marginLeft: 10,
    marginRight: 100,
    minWidth: 360,
  },
  formControlSelect: {
    // margin: theme.spacing(1),
    width: width_proportion,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  rootTabla: {
    width: "100%",
    marginTop: theme.spacing(1),
    overflowX: "auto",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperModal: {
    position: "absolute",
    width: "70%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const PuestoLaboral = (props) => {
  const classes = useStyles();
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors: fieldsErrors },
    register,
  } = useForm();

  let applyFilterTypes = [
    {
      key: "auto",
      name: "Immediately",
    },
    {
      key: "onClick",
      name: "On Button Click",
    },
  ];

  let i = 1;
  const [userName, setUserName] = useState("");
  const [plazaLaboral, setPlazaLaboral] = useState([]);

  const [codigoPuestoLaboral, setCodigoPuestoLaboral] = useState(0);

  const [positionDisableSorting, setPositionDisableSorting] = useState(false);
  const [showFilterRow, setShowFilterRow] = useState(true);
  const [showHeaderFilter, setShowHeaderFilter] = useState(true);
  const [currentFilter, setCurrentFilter] = useState(applyFilterTypes[0].key);
  const [autoExpandAll, setAutoExpandAll] = useState(true);

  const [buscando, setBackDrop] = useState(false);
  const [openPL, setOpenPL] = useState(false);
  const [openModalTraslado, setOpenModalTraslado] = useState(false);

  const [grado, setGrado] = useState(0);
  const [disponibilidad, setDisponibilidad] = useState(0);

  const [maestroPuestoLaboral, setMaestroPuestoLaboral] = useState([]);
  const [valueAutoCompletePuestoLaboral, setValueAutoCompletePuestoLaboral] =
    useState(null);

  const [codigoPlazaLaboral, setCodigoPlazaLaboral] = useState(0);
  const [subNivel, setSubNivel] = useState(null);
  const [esCritico, setEsCritico] = useState(false);
  const [nombreAdicional, setNombreAdicional] = useState("");

  const [maestroPlazaLaboralPadre, setMaestroPlazaLaboralPadre] = useState([]);
  const [
    valueAutoCompletePlazaLaboralPadre,
    setValueAutoCompletePlazaLaboralPadre,
  ] = useState(null);
  const [codigoPlazaLaboralPadre, setCodigoPlazaLaboralPadre] = useState(0);

  const [maestroUnidadTraslado, setMaestroUnidadTraslado] = useState([]);
  const [valueAutoCompleteUnidadTraslado, setValueAutoCompleteUnidadTraslado] =
    useState(null);
  const [codigoCodigoUnidadTraslado, setCodigoUnidadTraslado] = useState(0);

  const [visualizarArea, setVisualizarArea] = useState(false);
  const [visualizarGerencia, setVisualizarGerencia] = useState(false);
  const [visualizarCompleto, setVisualizarCompleto] = useState(false);
  const [editarCompleto, setEditarCompleto] = useState(false);

  const handleOpenModalPuestoLaboral = () => {
    setOpenPL(true);
  };

  const handleCloseModalPuestoLaboral = () => {
    setOpenPL(false);
  };

  const handleOpenModalTraslado = () => {
    setOpenModalTraslado(true);
  };

  const handleCloseModalTraslado = () => {
    setOpenModalTraslado(false);
  };

  const handleCerrarBackDrop = () => {
    setBackDrop(false);
  };
  const handleAbrirBackDrop = () => {
    setBackDrop(true);
  };

  useEffect(() => {
    const cargar = async () => {
      // CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
      const llaveAgrupacion = "GESPILU";

      const llaveVisualizarArea = "GESPILU.Plazas.Visualizar_Area";
      let verArea = await verificaPermisoPaginaActual(
        llaveAgrupacion,
        llaveVisualizarArea
      );
      setVisualizarArea(verArea);

      const llaveVisualizarGerencia = "GESPILU.Plazas.Visualizar_Gerencia";
      let verGerencia = await verificaPermisoPaginaActual(
        llaveAgrupacion,
        llaveVisualizarGerencia
      );
      setVisualizarGerencia(verGerencia);

      const llaveVisualizarCompleto = "GESPILU.Plazas.Visualizar_Completo";
      let verCompleto = await verificaPermisoPaginaActual(
        llaveAgrupacion,
        llaveVisualizarCompleto
      );
      setVisualizarCompleto(verCompleto);

      const llaveEditarCompleto = "GESPILU.Plazas.Editar_Completo";
      let editarCompleto = await verificaPermisoPaginaActual(
        llaveAgrupacion,
        llaveEditarCompleto
      );
      setEditarCompleto(editarCompleto);
      // FIN DE CONSULTA DE PERMISOS

      i = 1;
      handleAbrirBackDrop();

      await cargarMaestroPlazaLaboral();
      await cargarMaestroPuestoLaboral();
      await cargarMaestroUnidadesParaTraslado();
      // await cargarMaestroPlazaLaboralPadre();
      setCodigoPuestoLaboral(0);
      setValueAutoCompletePuestoLaboral(null);
      handleCerrarBackDrop();
    };

    cargar();
  }, [props.codigoUnidadOrganizacion]);

  useEffect(() => {
    const cargar = async () => {
      if (codigoPuestoLaboral > 0) {
        handleAbrirBackDrop();

        await cargarMaestroPlazaLaboralPadre();

        handleCerrarBackDrop();
      }
    };

    cargar();
  }, [codigoPuestoLaboral]);

  const cargarMaestroPlazaLaboral = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri =
        "api/PlazaLaboral/PorUnidadOrganizacion/" +
        props.codigoUnidadOrganizacion;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setPlazaLaboral(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cargarMaestroPuestoLaboral = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/PuestoLaboral/";

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setMaestroPuestoLaboral(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cargarMaestroPlazaLaboralPadre = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri =
        "api/PlazaLaboral/MaestroPadres/" +
        props.codigoGerencia +
        "/" +
        codigoPuestoLaboral;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setMaestroPlazaLaboralPadre(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cargarMaestroUnidadesParaTraslado = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/Organizacion/ParaTraslado/" + props.codigoGerencia;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setMaestroUnidadTraslado(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cuandoHayError = async (data) => {
    toast.info(
      "Existen algunos campos con error, por favor valida el formulario",
      {
        position: "top-right",
        autoClose: 3000, // Esta en milisegundos 3 segundos
      }
    );
  };

  const onExporting = async (e) => {};

  const [dialogoAbierto, abrirDialogo] = useState(false);
  const [dialogoAbiertoEditar, abrirDialogoEditar] = useState(false);
  const [dialogoAbiertoTrasladar, abrirDialogoTrasladar] = useState(false);
  const handleAbrirDialogoEliminar = (id) => {
    abrirDialogo(true);

    setCodigoPlazaLaboral(id);
  };

  const handleCerrarDialogo = () => {
    limpiarCampos();
    abrirDialogo(false);
  };

  const handleAbrirDialogoEditar = async (fila) => {
    try {
      abrirDialogoEditar(true);

      setCodigoPlazaLaboral(fila.CodigoPlazaLaboral);
      setCodigoPuestoLaboral(fila.CodigoPuestoLaboral);
      setCodigoPlazaLaboralPadre(fila.CodigoPlazaLaboralPadre);

      //await cargarMaestroPlazaLaboralPadre();
    } catch (error) {
      toast.error("Hubo un error al cargar los datos para edición");
    }
  };

  const handleCerrarDialogoEditar = () => {
    abrirDialogoEditar(false);
  };

  const handleAbrirDialogoTrasladar = async (fila) => {
    try {
      abrirDialogoTrasladar(true);

      setCodigoPlazaLaboral(fila.CodigoPlazaLaboral);
    } catch (error) {
      toast.error("Hubo un error al cargar los datos para edición");
    }
  };

  const handleCerrarDialogoTrasladar = () => {
    abrirDialogoTrasladar(false);
  };

  const quitarPlaza = async (pId) => {
    handleCerrarDialogo();
    handleAbrirBackDrop();

    const proyectosExcluidos = plazaLaboral.filter(
      (elemento) => elemento.CodigoPuestoLaboral !== pId
    );

    console.log("Quitar" + proyectosExcluidos);

    try {
      let uri = "api/PlazaLaboral/Quitar/" + pId;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        toast.error(
          "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
        );
      } else {
        if (request.data.response) {
          toast.success("Se quitó el registro con éxito", {
            position: "bottom-right",
            autoClose: 3000, // Esta en milisegundos 3 segundos
          });

          await cargarMaestroPlazaLaboral();
          setCodigoPlazaLaboral(0);
          setCodigoPuestoLaboral(0);
          setGrado(0);
          setSubNivel(null);
          setDisponibilidad(0);
          setEsCritico(false);
          setNombreAdicional("");
          setValueAutoCompletePuestoLaboral(null);
          setCodigoPlazaLaboralPadre(0);
          setValueAutoCompletePlazaLaboralPadre(null);
          await props.cargaOrganigrama();
        } else {
          toast.error("Error.- " + request.data.mensaje);
        }
      }
    } catch (error) {
      console.log("Error en  eliminación de proyectos" + error);
    }

    handleCerrarBackDrop();

    //alert("Quitar recurso del proyecto" + codigoRecurso);
  };

  const editarPlaza = async (pId) => {
    handleCerrarDialogoEditar();
    handleAbrirBackDrop();

    const proyectosExcluidos = plazaLaboral.filter(
      (elemento) => elemento.CodigoPlazaLaboral === pId
    );

    console.log("Editar el proyecto" + proyectosExcluidos);

    try {
      handleOpenModalPuestoLaboral();

      //setCodigoPuestoLaboral(proyectosExcluidos[0].CodigoPuestoLaboral);

      const itemPuestoLaboral = maestroPuestoLaboral.filter(
        (elemento) =>
          elemento.CodigoPuestoLaboral ===
          proyectosExcluidos[0].CodigoPuestoLaboral
      );

      setValueAutoCompletePuestoLaboral(itemPuestoLaboral[0]);

      const itemPadre = maestroPlazaLaboralPadre.filter(
        (elemento) =>
          elemento.CodigoPlazaLaboral ===
          proyectosExcluidos[0].CodigoPlazaLaboralPadre
      );

      setValueAutoCompletePlazaLaboralPadre(itemPadre[0]);

      setDisponibilidad(proyectosExcluidos[0].Disponibilidad);
      setGrado(proyectosExcluidos[0].Grado);
      setSubNivel(proyectosExcluidos[0].SubNivel);
      setEsCritico(proyectosExcluidos[0].EsCritico);
      setNombreAdicional(proyectosExcluidos[0].NombreAdicional);
    } catch (error) {
      console.log("Error en  edición de proyectos" + error);
    }

    handleCerrarBackDrop();

    //alert("Quitar recurso del proyecto" + codigoRecurso);
  };

  const trasladoPlaza = async (pId) => {
    handleCerrarDialogoTrasladar();
    handleAbrirBackDrop();

    const proyectosExcluidos = plazaLaboral.filter(
      (elemento) => elemento.CodigoPlazaLaboral === pId
    );

    console.log("Editar el proyecto" + proyectosExcluidos);

    try {
      handleOpenModalTraslado();

      //setCodigoPuestoLaboral(proyectosExcluidos[0].CodigoPuestoLaboral);
    } catch (error) {
      console.log("Error en  edición de proyectos" + error);
    }

    handleCerrarBackDrop();

    //alert("Quitar recurso del proyecto" + codigoRecurso);
  };

  const crearPlazaLaboral = async () => {
    const data = {
      CodigoPlazaLaboral: codigoPlazaLaboral,
      CodigoUnidadOrganizacion: props.codigoUnidadOrganizacion,
      CodigoPuestoLaboral: codigoPuestoLaboral,
      Disponibilidad: disponibilidad,
      Grado: grado,
      CodigoPlazaLaboralPadre:
        codigoPlazaLaboralPadre === 0 ? null : codigoPlazaLaboralPadre,
      SubNivel: subNivel,
      EsCritico: esCritico,
      NombreAdicional: nombreAdicional,
      Activo: true,
      Eliminado: false,
      UsuarioInserto: userName,
      FechaInserto: "",
      UsuarioModifico: "",
      FechaModifico: "",
    };

    console.log(data);
    // dataFicha.usuarioInserto = "prueba";
    try {
      handleAbrirBackDrop();
      let vIsSignedIn = await isSignedIn();
      if (vIsSignedIn.response) {
        let request;

        request = await API.post("api/PlazaLaboral/", data);
        if (request.status !== 200) {
          toast.error(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        } else {
          if (request.data.response) {
            toast.success("Se guardó exitosamente");

            await cargarMaestroPlazaLaboral();
            setCodigoPlazaLaboral(0);
            setCodigoPuestoLaboral(0);
            setGrado(0);
            setSubNivel(null);
            setDisponibilidad(0);
            setEsCritico(false);
            setNombreAdicional("");

            setValueAutoCompletePuestoLaboral(null);
            setCodigoPlazaLaboralPadre(0);
            setValueAutoCompletePlazaLaboralPadre(null);

            await props.cargaOrganigrama();
          } else {
            toast.error("Error.- " + request.data.mensaje);
          }
        }
      }
    } catch (e) {
      console.log("EXCEPTION: " + e);
      toast.error(
        "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
      );
    } finally {
      handleCerrarBackDrop();
      handleCloseModalPuestoLaboral();
    }
  };

  const guardarTrasladoPlazaLaboral = async () => {
    const data = {
      CodigoPlazaLaboral: codigoPlazaLaboral,
      CodigoUnidadOrganizacion: codigoCodigoUnidadTraslado,
      CodigoPuestoLaboral: 0,
      Disponibilidad: 0,
      Grado: 0,
      CodigoPlazaLaboralPadre: null,
      SubNivel: 0,
      Activo: true,
      Eliminado: false,
      UsuarioInserto: userName,
      FechaInserto: "",
      UsuarioModifico: "",
      FechaModifico: "",
    };

    console.log(data);
    // dataFicha.usuarioInserto = "prueba";
    try {
      handleAbrirBackDrop();
      let vIsSignedIn = await isSignedIn();
      if (vIsSignedIn.response) {
        let request;

        request = await API.post("api/PlazaLaboral/Traslado", data);
        if (request.status !== 200) {
          toast.error(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        } else {
          if (request.data.response) {
            toast.success("Se guardó exitosamente");

            await cargarMaestroPlazaLaboral();
            setCodigoPlazaLaboral(0);
            setCodigoPuestoLaboral(0);
            setGrado(0);
            setSubNivel(null);
            setDisponibilidad(0);

            setCodigoUnidadTraslado(0);
            setValueAutoCompleteUnidadTraslado(null);
            setValueAutoCompletePuestoLaboral(null);
            setCodigoPlazaLaboralPadre(0);
            setValueAutoCompletePlazaLaboralPadre(null);

            await props.cargaOrganigrama();
          } else {
            toast.error("Error.- " + request.data.mensaje);
          }
        }
      }
    } catch (e) {
      console.log("EXCEPTION: " + e);
      toast.error(
        "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
      );
    } finally {
      handleCerrarBackDrop();
      handleCloseModalPuestoLaboral();
    }
  };

  const handleOnChangePuestoLaboral = async (event, newValue) => {
    handleAbrirBackDrop();
    if (newValue !== null) {
      setCodigoPuestoLaboral(newValue.CodigoPuestoLaboral);
      setValueAutoCompletePuestoLaboral(newValue);
    }
    handleCerrarBackDrop();
  };

  const handleOnChangeUnidadTraslado = async (event, newValue) => {
    handleAbrirBackDrop();
    if (newValue !== null) {
      setCodigoUnidadTraslado(newValue.id);
      setValueAutoCompleteUnidadTraslado(newValue);
    }
    handleCerrarBackDrop();
  };

  const handleOnChangePlazaLaboralPadre = async (event, newValue) => {
    handleAbrirBackDrop();
    if (newValue !== null) {
      setCodigoPlazaLaboralPadre(newValue.CodigoPlazaLaboral);
      setValueAutoCompletePlazaLaboralPadre(newValue);
    }
    handleCerrarBackDrop();
  };

  const limpiarCampos = () => {
    setCodigoPlazaLaboral(0);
    setCodigoPuestoLaboral(0);
    setGrado(0);
    setSubNivel(null);
    setDisponibilidad(0);
    setEsCritico(false);
    setNombreAdicional("");

    setValueAutoCompletePuestoLaboral(null);
    setCodigoPlazaLaboralPadre(0);
    setValueAutoCompletePlazaLaboralPadre(null);
  };

  const handleNuevaPlaza = () => {
    limpiarCampos();
    handleOpenModalPuestoLaboral();
  };

  const cellOpciones = (rowInfo) => {
    return (
      <>
        {editarCompleto && (
          <>
            <Tooltip
              title="Eliminar"
              style={{ marginRight: 10, cursor: "pointer" }}
            >
              <Delete
                style={{ color: "#337ab7", cursor: "pointer" }}
                onClick={() =>
                  handleAbrirDialogoEliminar(rowInfo.data.CodigoPlazaLaboral)
                }
              />
            </Tooltip>
            <Tooltip
              title="Editar"
              style={{ marginRight: 10, cursor: "pointer" }}
            >
              <Edit
                style={{ color: "#337ab7", cursor: "pointer" }}
                onClick={() => handleAbrirDialogoEditar(rowInfo.data)}
              />
            </Tooltip>
            <Tooltip
              title="Trasladar a otra unidad organizativa"
              style={{ marginRight: 10, cursor: "pointer" }}
            >
              <TransferWithinAStation
                style={{ color: "#337ab7", cursor: "pointer" }}
                onClick={() => handleAbrirDialogoTrasladar(rowInfo.data)}
              />
            </Tooltip>
          </>
        )}
        <Tooltip
          title="Ir a descriptor de puesto"
          style={{ marginRight: 10, cursor: "pointer" }}
        >
          <Link
            to={`/puesto-laboral-descriptor/${rowInfo.data.CodigoPuestoLaboral}`}
          >
            <ViewList
              style={{ color: "#337ab7", cursor: "pointer" }}
              // onClick={() => handleAbrirDialogoTrasladar(rowInfo.data)}
            />
          </Link>
        </Tooltip>
      </>
    );
  };

  const cellNo = (cellInfo) => {
    let i = cellInfo.rowIndex + 1;
    let texto = i.toString();
    return <div>{texto}</div>;
  };

  const cellTemplateUnidadOrganizacion = (rowInfo) => {
    return (
      <>
        <Tooltip
          title={`Ir a gestión:`}
          style={{ marginRight: 10, cursor: "pointer" }}
        ></Tooltip>
      </>
    );
  };

  const cellSemaforo = (rowInfo) => {
    let color = "#8B0000";

    if (rowInfo.data.Semaforo >= 3) {
      color = "#8B0000";
    } else if (rowInfo.data.Semaforo === 2) {
      color = "#FF8C00";
    } else {
      color = "#228B22";
    }
    return (
      <>
        <Lens style={{ color: color }} />
      </>
    );
  };
  return (
    <React.Fragment>
      <Backdrop className={classes.backdrop} open={buscando}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer />
      {/* dialogo Trasladar */}
      <Dialog
        open={dialogoAbiertoTrasladar}
        onClose={handleCerrarDialogoTrasladar}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Traslado</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro que deseas trasladar este registro?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCerrarDialogoTrasladar} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={() => trasladoPlaza(codigoPlazaLaboral)}
            color="primary"
          >
            Trasladar
          </Button>
        </DialogActions>
      </Dialog>
      {/* dialogo Eliminar */}
      <Dialog
        open={dialogoAbierto}
        onClose={handleCerrarDialogo}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Eliminación</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro que deseas eliminar este registro?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCerrarDialogo} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={() => quitarPlaza(codigoPlazaLaboral)}
            color="primary"
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
      {/* dialogo Editar */}
      <Dialog
        open={dialogoAbiertoEditar}
        onClose={handleCerrarDialogoEditar}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Edición</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro que deseas editar este registro?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCerrarDialogoEditar} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={() => editarPlaza(codigoPlazaLaboral)}
            color="primary"
          >
            Editar
          </Button>
        </DialogActions>
      </Dialog>
      {/* Modal creación plaza */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openPL}
        onClose={handleCloseModalPuestoLaboral}
        closeAfterTransition
        BackdropComponent={BackdropModal}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openPL}>
          <div className={classes.paperModal}>
            <h2 id="simple-modal-title">Datos de Plaza Laboral</h2>
            <Grid container spacing={3}>
              <form
                autoComplete="off"
                onSubmit={handleSubmit(crearPlazaLaboral, cuandoHayError)}
              >
                <Grid item xs={6} sm={6} md={12} lg={12}>
                  <FormControl className={classes.formControlSelects}>
                    <Controller
                      name="puestoLaboral"
                      required
                      render={({ PuestoLaboral }) => (
                        <Autocomplete
                          id="puestoLaboral"
                          options={maestroPuestoLaboral}
                          getOptionLabel={(option) => option.PuestoLaboral}
                          value={valueAutoCompletePuestoLaboral}
                          onChange={(event, newValue) =>
                            handleOnChangePuestoLaboral(event, newValue)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Puesto Laboral"
                              variant="outlined"
                            />
                          )}
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                  <FormControl className={classes.formControlSelects}>
                    <Controller
                      name="plazaLaboralPadre"
                      required
                      render={({ PlazaLaboralPadre }) => (
                        <Autocomplete
                          id="plazaLaboralPadre"
                          options={maestroPlazaLaboralPadre}
                          getOptionLabel={(option) => option.PuestoLaboral}
                          value={valueAutoCompletePlazaLaboralPadre}
                          onChange={(event, newValue) =>
                            handleOnChangePlazaLaboralPadre(event, newValue)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Plaza Laboral Padre"
                              variant="outlined"
                            />
                          )}
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                  <FormControl className={classes.formControlSelects}>
                    <Controller
                      name="nombreAdicional"
                      render={({ NombreAdicional }) => (
                        <TextField
                          id="nombreAdicional"
                          label="Nombre Complementario"
                          name="nombreAdicional"
                          value={nombreAdicional}
                          onChange={(e) => setNombreAdicional(e.target.value)}
                          variant="outlined"
                          helperText={
                            fieldsErrors.nombreAdicional
                              ? fieldsErrors.nombreAdicional.message
                              : ""
                          }
                          //   inputRef={refTelefono}
                          //   {...inputPropsTelefono}

                          // rules={{
                          //   pattern: {
                          //     value: /[0-9]/,
                          //     message: "Solo se aceptan números",
                          //   },
                          // }}
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                  <FormControl className={classes.formControlSelects}>
                    <Controller
                      name="disponibilidad"
                      render={({ Disponibilidad }) => (
                        <TextField
                          id="disponibilidad"
                          label="No. de Plazas"
                          name="disponibilidad"
                          value={disponibilidad}
                          onChange={(e) => setDisponibilidad(e.target.value)}
                          variant="outlined"
                          helperText={
                            fieldsErrors.disponibilidad
                              ? fieldsErrors.disponibilidad.message
                              : ""
                          }
                          //   inputRef={refTelefono}
                          //   {...inputPropsTelefono}

                          rules={{
                            pattern: {
                              value: /[0-9]/,
                              message: "Solo se aceptan números",
                            },
                          }}
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} md={12} lg={12}>
                  <FormControl className={classes.formControlSelects}>
                    <Controller
                      name="grado"
                      render={({ Grado }) => (
                        <TextField
                          id="grado"
                          label="Grado"
                          name="grado"
                          value={grado}
                          onChange={(e) => setGrado(e.target.value)}
                          variant="outlined"
                          helperText={
                            fieldsErrors.grado ? fieldsErrors.grado.message : ""
                          }
                          //   inputRef={refTelefono}
                          //   {...inputPropsTelefono}

                          rules={{
                            pattern: {
                              value: /[0-9]/,
                              message: "Solo se aceptan números",
                            },
                          }}
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                  <FormControl className={classes.formControlSelects}>
                    <Controller
                      name="subNivel"
                      render={({ SubNivel }) => (
                        <TextField
                          id="subNivel"
                          label="Sub Nivel"
                          name="subNivel"
                          value={subNivel}
                          onChange={(e) => setSubNivel(e.target.value)}
                          variant="outlined"
                          helperText={
                            fieldsErrors.subNivel
                              ? fieldsErrors.subNivel.message
                              : ""
                          }
                          //   inputRef={refTelefono}
                          //   {...inputPropsTelefono}

                          rules={{
                            pattern: {
                              value: /[0-9]/,
                              message: "Solo se aceptan números",
                            },
                          }}
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={esCritico}
                        onChange={(e) => setEsCritico(e.target.checked)}
                        value="¿Es un puesto crítico?"
                        color="primary"
                      />
                    }
                    label="¿Es un puesto crítico?"
                    labelPlacement="start"
                  />
                  {/* <FormControl>
                    
                    <Controller
                      name="esCritico"
                      render={({ esCritico }) => (
                        <Checkbox
                          id="esCritico"
                          label="Es Crítico"
                          name="esCritico"
                          checked={esCritico}
                          onChange={(e) => setEsCritico(e.target.checked)}
                          variant="outlined"
                          helperText={
                            fieldsErrors.esCritico
                              ? fieldsErrors.esCritico.message
                              : ""
                          }
                          //   inputRef={refTelefono}
                          //   {...inputPropsTelefono}

                          // rules={{
                          //   pattern: {
                          //     value: /[0-9]/,
                          //     message: "Solo se aceptan números",
                          //   },
                          // }}
                        />
                      )}
                      control={control}
                      label="Es Puesto Crítico"
                    />
                  </FormControl> */}
                </Grid>
                <Grid item xs={6} sm={6} md={12} lg={12}>
                  <Button
                    width="100%"
                    variant="contained"
                    style={{
                      backgroundColor: "#69bd4b",
                      marginBottom: 10,
                      marginTop: 10,
                      color: "white",
                      width: "93%",
                      marginLeft: 30,
                    }}
                    type="submit"
                  >
                    &nbsp;Guardar Cambios
                  </Button>
                </Grid>
              </form>
            </Grid>
          </div>
        </Fade>
      </Modal>
      {/*Modal traslado de unidad organización*/}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModalTraslado}
        onClose={handleCloseModalTraslado}
        closeAfterTransition
        BackdropComponent={BackdropModal2}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModalTraslado}>
          <div className={classes.paperModal}>
            <h2 id="simple-modal-title">Traslado de Plaza Laboral</h2>
            <Grid container spacing={3}>
              <form
                autoComplete="off"
                onSubmit={handleSubmit(
                  guardarTrasladoPlazaLaboral,
                  cuandoHayError
                )}
              >
                <Grid item xs={6} sm={6} md={12} lg={12}>
                  <FormControl className={classes.formControlSelects}>
                    <Controller
                      name="unidadTraslado"
                      required
                      render={({ UnidadTraslado }) => (
                        <Autocomplete
                          id="unidadTraslado"
                          options={maestroUnidadTraslado}
                          getOptionLabel={(option) => option.name}
                          value={valueAutoCompleteUnidadTraslado}
                          onChange={(event, newValue) =>
                            handleOnChangeUnidadTraslado(event, newValue)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Unidad Organizacional para Traslado"
                              variant="outlined"
                            />
                          )}
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} md={12} lg={12}>
                  <Button
                    width="100%"
                    variant="contained"
                    style={{
                      backgroundColor: "#69bd4b",
                      marginBottom: 10,
                      marginTop: 10,
                      color: "white",
                      width: "93%",
                      marginLeft: 30,
                    }}
                    type="submit"
                  >
                    &nbsp;Realizar traslado
                  </Button>
                </Grid>
              </form>
            </Grid>
          </div>
        </Fade>
      </Modal>
      <AppBar
        position="sticky"
        className={classes.appBar}
        style={{ backgroundColor: titleColor }}
      >
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            style={{ display: "flex", flexGrow: 1 }}
          >
            {`Inventario de Puestos Laborales`}
          </Typography>
          {editarCompleto && (
            <Tooltip title={"Crear nuevo ítem"} aria-label="Crear nuevo ítem">
              <IconButton
                aria-label="Crear nuevo ítem"
                style={{ color: "white" }}
              >
                <Add onClick={handleNuevaPlaza} />
              </IconButton>
            </Tooltip>
          )}
        </Toolbar>
      </AppBar>
      <Paper className={classes.rootTabla}>
        <DataGrid
          dataSource={plazaLaboral}
          //   defaultColumns={this.props.columns}
          showBorders={true}
          rowAlternationEnabled={true}
          //ref={(ref) => (dataGrid = ref)}
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          onExporting={onExporting}
          className={classes.table}
        >
          <FilterRow visible={showFilterRow} applyFilter={currentFilter} />
          <HeaderFilter visible={showHeaderFilter} />
          <GroupPanel visible={false} />
          <Grouping autoExpandAll={autoExpandAll} />
          <SearchPanel visible={false} width={240} placeholder="Search..." />
          {/* <Selection mode="multiple" /> */}
          <LoadPanel
            enabled={true}
            shadingColor="rgba(0,0,0,0.4)"
            showIndicator={true}
            shading={true}
            showPane={true}
            closeOnOutsideClick={false}
          />
          <Editing
            mode="row"
            useIcons={true}
            allowUpdating={false}
            allowDeleting={false}
          />
          <Column
            cellRender={cellSemaforo}
            caption="Semaforo"
            fixedPosition="left"
          />
          <Column
            caption="Opciones"
            allowFiltering={false}
            allowSorting={false}
            cellRender={cellOpciones}
            // visible={editarCompleto}
          />

          <Column
            dataField="CodigoPlazaLaboral"
            caption="Código Plaza Laboral"
            visible={false}
          />
          <Column
            dataField="CodigoPlazaLaboralPadre"
            caption="Código Plaza Laboral Padre"
            visible={false}
          />
          <Column
            dataField="CodigoUnidadOrganizacion"
            caption="Código Unidad Organización"
            visible={false}
          />

          {/* <Column
            caption="No."
            cellRender={cellNo}
            
            // visible={false}
          /> */}
          <Column dataField="index" caption="#" />

          <Column
            dataField="CodigoPuestoLaboral"
            caption="Código Puesto Laboral"
          />
          <Column dataField="PuestoLaboral" caption="Puesto Laboral" />
          <Column dataField="NombreAdicional" caption="Nombre Complementario" />
          <Column
            dataField="NivelPuestoLaboral"
            caption="Nivel Puesto Laboral"
          />
          <Column dataField="Disponibilidad" caption="Inventario de Puestos" />
          <Column dataField="Asignados" caption="Personal Asignado" />
          <Column dataField="Grado" caption="Grado" />
          <Column dataField="SubNivel" caption="SubNivel" />
          {/* <Column
            dataField="CodigoPlazaLaboralPadre"
            caption="Código Puesto Laboral Padre"
            visible={false}
          /> */}
          <Column
            dataField="PuestoLaboralPadre"
            caption="Puesto Laboral Padre"
          />
          <Column
            dataField="EsCritico"
            caption="Puesto Crítico"
            dataType="boolean"
          />
          <Summary>
            <TotalItem
              column="PuestoLaboral"
              summaryType="count"
              displayFormat="Puestos: {0}"
            />
            <TotalItem
              column="Disponibilidad"
              summaryType="sum"
              displayFormat="Inventario: {0}"
            />
            <TotalItem
              column="Asignados"
              summaryType="sum"
              displayFormat="Asignados: {0}"
            />
          </Summary>
          <ColumnFixing enabled={true} />
          <Sorting mode="multiple" />
          <Paging defaultPageSize={20} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[10, 20, 50]}
            showInfo={true}
          />
          <Export enabled={true} allowExportSelectedData={true} />
          <Scrolling columnRenderingMode="virtual" />
        </DataGrid>
      </Paper>
    </React.Fragment>
  );
};

export default PuestoLaboral;
