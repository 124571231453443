import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Checkbox,
  FormControlLabel,
  Divider,
  Paper,
  Tooltip,
  Modal,
  Fade,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
} from "@material-ui/core";

import BackdropModal from "@material-ui/core/Backdrop";

import clsx from "clsx";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import {
  ChromeReaderMode,
  Edit,
  People,
  AttachFile,
  Cached,
  Delete,
  Label,
  ViewList,
  Add,
} from "@material-ui/icons";

import { green } from "@material-ui/core/colors";
import { NavLink } from "react-router-dom";

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import DataGrid, {
  Column,
  Pager,
  Paging,
  Sorting,
  ColumnFixing,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Grouping,
  GroupPanel,
  Export,
  Selection,
  Editing,
  Scrolling,
} from "devextreme-react/data-grid";

import API from "../../utils/api";
import { isSignedIn, signOut } from "../../utils/auth";
import { validaPermisoPaginaActual } from "../../utils/helpers";
import { ToastContainer, toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import "devextreme/dist/css/dx.material.teal.light.compact.css";
import { LoadPanel } from "devextreme-react";

const width_proportion = "100%";
const titleColor = "#2B3C4D";

const useStyles = makeStyles((theme) => ({
  table: {
    width: "100%",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 210,
  },
  textFieldDireccion: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 230,
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  greenAvatar: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  paperCard: {
    padding: 20,
    width: "100%",
  },
  formControlSelects: {
    // margin: theme.spacing(1),
    minWidth: 600,

    marginBottom: 10,
    marginTop: 10,
    //width: "100%",
    marginLeft: 30,
  },
  formControlSelect: {
    // margin: theme.spacing(1),
    width: width_proportion,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  rootTabla: {
    width: "100%",
    marginTop: theme.spacing.unit * 1,
    overflowX: "auto",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperModal: {
    position: "absolute",
    width: "90%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const PuestoLaboral = (props) => {
  const classes = useStyles();
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors: fieldsErrors },
    register,
  } = useForm();

  let applyFilterTypes = [
    {
      key: "auto",
      name: "Immediately",
    },
    {
      key: "onClick",
      name: "On Button Click",
    },
  ];

  const [userName, setUserName] = useState("");
  const [open, setOpen] = useState(false);
  const [openDialogEliminarFuncion, setOpenDialogEliminarFuncion] =
    useState(false);
  const [cargandoMaestros, setCargandoMaestros] = useState(false);
  const [puestoLaboral, setPuestoLaboral] = useState([]);
  const [maestroNivelPuestoLaboral, setMaestroNivelPuestoLaboral] = useState(
    []
  );
  const [codigoPuestoLaboral, setCodigoPuestoLaboral] = useState(0);
  const [codigoNivelPuestoLaboral, setCodigoNivelPuestoLaboral] = useState(0);
  const [descripcion, setDescripcion] = useState("");

  const [codigoActividadPuestoLaboral, setCodigoActividadPuestoLaboral] =
    useState(0);

  const [
    valueAutoCompleteNivelPuestoLaboral,
    setValueAutoCompleteNivelPuestoLaboral,
  ] = useState(null);

  const [positionDisableSorting, setPositionDisableSorting] = useState(false);
  const [showFilterRow, setShowFilterRow] = useState(true);
  const [showHeaderFilter, setShowHeaderFilter] = useState(true);
  const [currentFilter, setCurrentFilter] = useState(applyFilterTypes[0].key);
  const [autoExpandAll, setAutoExpandAll] = useState(true);

  const [buscando, setBackDrop] = useState(false);

  const [dialogoAbierto, abrirDialogo] = useState(false);
  const [dialogoAbiertoEditar, abrirDialogoEditar] = useState(false);

  const handleCerrarBackDrop = () => {
    setBackDrop(false);
  };
  const handleAbrirBackDrop = () => {
    setBackDrop(true);
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    setDescripcion("");
    setCodigoPuestoLaboral(0);
    setCodigoNivelPuestoLaboral(0);

    setValueAutoCompleteNivelPuestoLaboral(null);
    setCodigoActividadPuestoLaboral(0);
  };

  const handleOpenDialogEliminarFuncion = (pFila) => {
    setOpenDialogEliminarFuncion(true);
  };

  const handleCloseDialogEliminarFuncion = () => {
    setOpenDialogEliminarFuncion(false);
  };
  useEffect(() => {
    const cargar = async () => {
      // CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
      const llaveAgrupacion = "Menu.Principal.Web";
      const llavePagina = "Menu.Principal.Web.PuestoLaboral";
      await validaPermisoPaginaActual(llaveAgrupacion, llavePagina);
      // FIN DE CONSULTA DE PERMISOS

      handleAbrirBackDrop();

      console.log("props.responsabilidad");
      console.log(props.responsabilidad);
      await cargarMaestroActividadPuestoLaboral();

      handleCerrarBackDrop();
    };

    cargar();
  }, [props.responsabilidad]);

  const cargarMaestroActividadPuestoLaboral = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri =
        "api/ActividadPuestoLaboral/" +
        props.responsabilidad.CodigoResponsabilidadPuestoLaboral;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setPuestoLaboral(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const quitarFuncionTecnica = async (pId) => {
    handleCloseDialogEliminarFuncion();
    handleAbrirBackDrop();
    try {
      let uri = "api/ResponsabilidadPuestoLaboral/Eliminar/" + pId;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        toast.error(
          "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
        );
      } else {
        if (request.data.response) {
          toast.success("Se quitó el registro con éxito", {
            position: "bottom-right",
            autoClose: 3000, // Esta en milisegundos 3 segundos
          });
          await props.cargarMaestroResponsabilidadPuestoLaboral();
        } else {
          toast.error(request.data.mensaje, {
            position: "bottom-right",
            autoClose: 3000, // Esta en milisegundos 3 segundos
          });
        }
      }
    } catch (error) {
      console.log("Error en  eliminación de proyectos" + error);
      toast.error("Error. Contacte a TI", {
        position: "bottom-right",
        autoClose: 3000, // Esta en milisegundos 3 segundos
      });
    }

    handleCerrarBackDrop();

    //alert("Quitar recurso del proyecto" + codigoRecurso);
  };

  const guardarDato = async () => {
    const data = {
      CodigoActividadNivelPuestoLaboral: codigoActividadPuestoLaboral,
      CodigoResponsabilidadNivelPuestoLaboral:
        props.responsabilidad.CodigoResponsabilidadNivelPuestoLaboral,
      Descripcion: descripcion,
      Activo: true,
      Eliminado: false,
      UsuarioInserto: userName,
      FechaInserto: "",
      UsuarioModifico: "",
      FechaModifico: "",
    };

    console.log(data);
    // dataFicha.usuarioInserto = "prueba";
    try {
      handleAbrirBackDrop();
      let vIsSignedIn = await isSignedIn();
      if (vIsSignedIn.response) {
        let request;

        request = await API.post(
          "api/ActividadNivelPuestoLaboral/PuestoLaboral/" +
            props.responsabilidad.CodigoResponsabilidadPuestoLaboral,
          data
        );
        if (request.status !== 200) {
          toast.error(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        } else {
          toast.success("Se guardó exitosamente");

          await cargarMaestroActividadPuestoLaboral();

          setDescripcion("");
          setCodigoPuestoLaboral(0);
          setCodigoNivelPuestoLaboral(0);

          setValueAutoCompleteNivelPuestoLaboral(null);
          setCodigoActividadPuestoLaboral(0);
        }
      }
    } catch (e) {
      console.log("EXCEPTION: " + e);
      toast.error(
        "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
      );
    } finally {
      handleCerrarBackDrop();
      handleCloseModal();
    }
  };

  const editarDato = async (dato) => {
    handleOpenModal();
    console.log(dato);
    setDescripcion(dato.Descripcion);
    setCodigoActividadPuestoLaboral(dato.CodigoActividadNivelPuestoLaboral);
  };
  const cuandoHayError = async (data) => {
    toast.info(
      "Existen algunos campos con error, por favor valida el formulario",
      {
        position: "top-right",
        autoClose: 3000, // Esta en milisegundos 3 segundos
      }
    );
  };

  const onExporting = async (e) => {
    // const workbook = new Workbook();
    // const worksheet = workbook.addWorksheet("Main sheet");
    // exportDataGrid({
    //   component: e.component,
    //   worksheet: worksheet,
    // }).then(function () {
    //   workbook.xlsx.writeBuffer().then(function (buffer) {
    //     saveAs(
    //       new Blob([buffer], { type: "application/octet-stream" }),
    //       "DataGrid.xlsx"
    //     );
    //   });
    // });
    // e.cancel = true;
  };

  const handleAbrirDialogoEliminar = (id) => {
    abrirDialogo(true);

    setCodigoActividadPuestoLaboral(id);
  };

  const handleCerrarDialogo = () => {
    abrirDialogo(false);
  };

  const handleAbrirDialogoEditar = (id) => {
    abrirDialogoEditar(true);

    setCodigoPuestoLaboral(id);
  };

  const handleCerrarDialogoEditar = () => {
    abrirDialogoEditar(false);
  };

  const quitarActividad = async () => {
    handleCerrarDialogo();
    handleAbrirBackDrop();

    try {
      let uri =
        "api/ActividadPuestoLaboral/Eliminar/" + codigoActividadPuestoLaboral;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        toast.error(
          "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
        );
      } else {
        if (request.data.response) {
          toast.success("Se quitó el registro con éxito", {
            position: "bottom-right",
            autoClose: 3000, // Esta en milisegundos 3 segundos
          });
          await cargarMaestroActividadPuestoLaboral();
        } else {
          toast.error(request.data.mensaje, {
            position: "bottom-right",
            autoClose: 3000, // Esta en milisegundos 3 segundos
          });
        }
      }
    } catch (error) {
      console.log("Error en  eliminación de proyectos" + error);
      toast.error("Error. Contacte a TI", {
        position: "bottom-right",
        autoClose: 3000, // Esta en milisegundos 3 segundos
      });
    }

    handleCerrarBackDrop();

    //alert("Quitar recurso del proyecto" + codigoRecurso);
  };

  const cellOpciones = (rowInfo) => {
    return (
      <>
        <Tooltip
          title="Eliminar"
          style={{ marginRight: 10, cursor: "pointer" }}
        >
          <Delete
            style={{ color: "#337ab7", cursor: "pointer" }}
            onClick={() =>
              handleAbrirDialogoEliminar(
                rowInfo.data.CodigoActividadPuestoLaboral
              )
            }
          />
        </Tooltip>
        <Tooltip title="Editar" style={{ marginRight: 10, cursor: "pointer" }}>
          <Edit
            style={{ color: "#337ab7", cursor: "pointer" }}
            onClick={() => editarDato(rowInfo.data)}
          />
        </Tooltip>
      </>
    );
  };

  const handleOnChangeTipoParentesco = async (event, newValue) => {
    handleAbrirBackDrop();
    if (newValue !== null) {
      setCodigoNivelPuestoLaboral(newValue.CodigoNivelPuestoLaboral);
      setValueAutoCompleteNivelPuestoLaboral(newValue);
    }
    handleCerrarBackDrop();
  };

  return (
    <React.Fragment>
      <Backdrop className={classes.backdrop} open={buscando}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* Dialog Eliminar Actividad */}
      <Dialog
        open={dialogoAbierto}
        onClose={handleCerrarDialogo}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Eliminar Actividad</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro que deseas eliminar este registro?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCerrarDialogo} color="primary">
            Cancelar
          </Button>
          <Button onClick={() => quitarActividad()} color="secondary">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
      {/* Dialogo para eliminar Función técnica */}
      <Dialog
        open={openDialogEliminarFuncion}
        onClose={handleCloseDialogEliminarFuncion}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Eliminar Función </DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro que deseas editar este registro y todas sus
            actividades?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogEliminarFuncion} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={() =>
              quitarFuncionTecnica(
                props.responsabilidad.CodigoResponsabilidadPuestoLaboral
              )
            }
            color="secondary"
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={BackdropModal}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paperModal}>
            <h2 id="simple-modal-title" style={{ marginBottom: "10px" }}>
              Crear/Editar actividad
            </h2>
            <Grid container spacing={3}>
              <form
                autoComplete="off"
                onSubmit={handleSubmit(guardarDato, cuandoHayError)}
              >
                <Grid item xs={6} sm={6} md={12} lg={12}>
                  <FormControl className={classes.formControlSelects}>
                    <Controller
                      name="descripcion"
                      render={({ Descripcion }) => (
                        <TextField
                          multiline
                          maxRows={6}
                          id="descripcion"
                          label="Descripción de Actividad"
                          name="descripcion"
                          value={descripcion}
                          onChange={(e) => setDescripcion(e.target.value)}
                          variant="outlined"
                          helperText={
                            fieldsErrors.descripcion
                              ? fieldsErrors.descripcion.message
                              : ""
                          }
                          //   inputRef={refTelefono}
                          //   {...inputPropsTelefono}

                          //   rules={{
                          //     pattern: { value: /[0-9]/, message: "Solo se aceptan números" },
                          //     minLength: { value: 8, message: "No menor a 8 dígitos" },
                          //     maxLength: { value: 8, message: "No mayor a 8 dígitos" },
                          //   }}
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} md={12} lg={12}>
                  <Button
                    width="100%"
                    variant="contained"
                    style={{
                      backgroundColor: "#69bd4b",
                      marginBottom: 10,
                      marginTop: 10,
                      color: "white",
                      width: "93%",
                      marginLeft: 30,
                    }}
                    type="submit"
                  >
                    &nbsp;Guardar Cambios
                  </Button>
                </Grid>
              </form>
            </Grid>
          </div>
        </Fade>
      </Modal>
      <AppBar
        position="sticky"
        className={classes.appBar}
        // style={{ backgroundColor: titleColor }}
      >
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            style={{ display: "flex", flexGrow: 1 }}
          >
            {"Función " + props.idFila}
          </Typography>
          <Tooltip title={"Crear nuevo ítem"} aria-label="Crear nuevo ítem">
            <IconButton
              aria-label="Crear nuevo ítem"
              style={{ color: "white" }}
            >
              <Add onClick={handleOpenModal} />
            </IconButton>
          </Tooltip>
          <Tooltip title={"Eliminar función"} aria-label="Eliminar función">
            <IconButton
              aria-label="Eliminar función"
              style={{ color: "white" }}
            >
              <Delete onClick={handleOpenDialogEliminarFuncion} />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <Paper className={classes.rootTabla}>
        <Typography pl={2} variant="h6" style={{ marginLeft: "10px" }}>
          {props.responsabilidad.Responsabilidad}
        </Typography>
        <DataGrid
          dataSource={puestoLaboral}
          //   defaultColumns={this.props.columns}
          showBorders={true}
          rowAlternationEnabled={true}
          //ref={(ref) => (dataGrid = ref)}
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          onExporting={onExporting}
          className={classes.table}
          wordWrapEnabled={true}
        >
          <FilterRow visible={showFilterRow} applyFilter={currentFilter} />
          <HeaderFilter visible={showHeaderFilter} />
          <GroupPanel visible={false} />
          <Grouping autoExpandAll={autoExpandAll} />
          <SearchPanel visible={false} width={240} placeholder="Search..." />
          {/* <Selection mode="multiple" /> */}
          <LoadPanel
            enabled={true}
            shadingColor="rgba(0,0,0,0.4)"
            showIndicator={true}
            shading={true}
            showPane={true}
            closeOnOutsideClick={false}
          />
          <Editing
            mode="row"
            useIcons={true}
            allowUpdating={false}
            allowDeleting={false}
          />
          <Column
            caption="Opciones"
            allowFiltering={false}
            allowSorting={false}
            cellRender={cellOpciones}
          />
          <Column
            dataField="CodigoPuestoLaboral"
            caption="Código DB"
            dataType="number"
            visible={false}
          />
          <Column
            dataField="CodigoTipoPuestoLaboral"
            caption="Código Tipo Puesto"
            dataType="number"
            visible={false}
          />
          <Column
            dataField="CodigoNivelPuestoLaboral"
            caption="Código Nivel Puesto"
            dataType="number"
            visible={false}
          />

          <Column
            dataField="Descripcion"
            caption="Actividad"
            minWidth={350}
            maxWidth={800}
          />

          <Column
            dataField="FechaInserto"
            caption="Fecha Asignación"
            dataType="date"
            format="dd/MM/yyyy"
          />
          <Column dataField="Activo" caption="Activo" dataType="boolean" />

          <ColumnFixing enabled={true} />
          <Sorting mode="multiple" />
          <Paging defaultPageSize={20} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[10, 20, 50]}
            showInfo={true}
          />
          <Export enabled={true} allowExportSelectedData={true} />
          <Scrolling columnRenderingMode="virtual" />
        </DataGrid>
      </Paper>
    </React.Fragment>
  );
};

export default PuestoLaboral;
