import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SettingsIcon from "@material-ui/icons/Settings";
import { eachDayOfInterval } from "date-fns";
import { ToastContainer, toast } from "react-toastify";
const ITEM_HEIGHT = 48;
export default function ActionMenuFormTab(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const llaveTablaCarpeta = "AITCarpeta";
  const llaveTablaGestion = "AITProblema";
  let options = [];
  if (props.mostrarAsignar === false) {
    options = ["Nuevo form"];
  } else {
    options = ["Asignar form", "Nuevo form"];
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const asignarForm = () => {
    props.asignar();
    handleClose();
  };
  const nuevoForm = () => {
    props.nuevoForm();
    handleClose();
  };
  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon style={{ color: "#3f51b5" }} />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option}
            selected={option === "Pyxis"}
            onClick={() => {
              switch (option) {
                case "Asignar form": {
                  asignarForm();
                  break;
                }
                case "Nuevo form": {
                  nuevoForm();
                  break;
                }
              }
            }}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
