import React, { useState, useEffect } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import Column from "./column";
import getList from "./lista";
import api from "../utils/api";
import { isSignedIn, signOut } from "../utils/auth";
import Modal from "@material-ui/core/Modal";
import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import SecondNavigation from "../super-filtro/components/second-navigation";
import { makeStyles } from "@material-ui/core/styles";
import FolderIcon from "@material-ui/icons/Folder";
import { green } from "@material-ui/core/colors";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import clsx from "clsx";
import Tooltip from "@material-ui/core/Tooltip";
import Layout from "../layout/containers/Layout";

export default function KanbanBoard() {
  const [completed, setCompleted] = useState([]);
  const [incomplete, setIncomplete] = useState([]);
  const [columns, setColumns] = useState([]);
  const [gestiones, setGestiones] = useState([]);
  const [listaTipoGestion, setListaTipoGestion] = useState([]);
  const [openNavegador, setOpenNavegador] = useState(false);
  const [codigoTipoGestion, setCodigoTipoGestion] = useState(null);
  const [tipoGestion, setTipoGestion] = useState(null);
  const [openToolbar, setOpenToolbar] = useState(true);

  useEffect(() => {
    cargarListaTipoGestion();
    // onLoad();
    // const list = getList();
    // console.log("lista de tareas:", list);
    // setCompleted(list.filter((task) => task.completed));
    // setIncomplete(list.filter((task) => !task.completed));
  }, []);

  useEffect(() => {
    if (codigoTipoGestion > 0) {

      onLoad();
    }
  }, [codigoTipoGestion]);

  const onLoad = async () => {
    getColumns();
    getGestiones();
  };

  const getColumns = async () => {
    //Obtenemos las columnas del kanban, estas corresponden a las tareas del flujo asignado al tipo de gestión seleccionado

    let uri = `api/kanbangestion/getcolumns/${codigoTipoGestion}`;
    try {
      const session = await isSignedIn();

      const request = await api.fetchGetRequest(uri);

      if (request.statusCode === 401) {
        if (await signOut()) {
          window.location.href = "/login";
        }
        return;
      }

      console.log("Columnas:", request.data.data);

      if (Array.isArray(request.data.data)) {
        setColumns(request.data.data);
      } else {
        setColumns([]);
      }
    } catch (e) {
      console.error("Error al obtener las columnas");
    }
  };

  const getGestiones = async () => {
    //Obtenemos las columnas del kanban, estas corresponden a las tareas del flujo asignado al tipo de gestión seleccionado

    let uri = `api/kanbangestion/getgestiones/${codigoTipoGestion}`;
    try {
      const session = await isSignedIn();

      const request = await api.fetchGetRequest(uri);

      if (request.statusCode === 401) {
        if (await signOut()) {
          window.location.href = "/login";
        }
        return;
      }

      console.log("gestiones:", request.data.data);

      if (Array.isArray(request.data.data)) {
        setGestiones(request.data.data);
      } else {
        setGestiones([]);
      }
    } catch (e) {
      console.error("Error al obtener las gestiones");
    }
  };

  const agruparGestiones = (columnId) => {
    let filtro = gestiones.filter((x) => x.CodigoTareaEnProceso === columnId);
    return filtro;
  };

  const handleDragEnd = async (result) => {
    try {
      const { destination, source, draggableId } = result;
      console.log("Result:", result);

      let sourceId = Number(source.droppableId);
      let destinationId = Number(destination.droppableId);
      //No permite regresar una gestión a una tarea anterior o al mismo panel
      if (sourceId >= destinationId) return;
      // if (source.droppableId == destination.droppableId) return;

      //Cambia de panel la gestión
      const gestionesCopy = [...gestiones];
      const index = gestionesCopy.findIndex(
        (x) => x.CodigoAviso === Number(draggableId)
      );
      gestionesCopy[index].CodigoTareaEnProceso = destinationId;
      setGestiones(gestionesCopy);
      console.log("gestionesCopy", gestionesCopy);

      //Envía datos al server
      let uri = `api/kanbangestion/cambiarestadogestion`;
      const data = {
        codigoAviso: Number(draggableId),
        codigoTareaOrigen: sourceId,
        codigoTareaDestino: destinationId,
      };
      const session = isSignedIn();
      const request = await api.post(uri, data);

      if (request.statusCode === 401) {
        if (await signOut()) {
          window.location.href = "/login";
        }
        return;
      }
    } catch (error) {
      console.error("Error al soltar", error);
    }
  };

  const getModalStyle = () => {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  };
  const styles = makeStyles((theme) => ({
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    textField: {
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit,
      width: "100%",
    },
    paperPerfil: {
      position: "relative",
      width: "100%", //theme.spacing.unit * 50,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing.unit * 1,
      outline: "none",
    },
    paper: {
      position: "absolute",
      width: theme.spacing.unit * 100,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing.unit * 1,
      outline: "none",
    },
    buttonAdd: {
      margin: theme.spacing.unit,
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    large: {
      width: theme.spacing(12),
      height: theme.spacing(12),
    },
    greenAvatar: {
      fontSize: "2.5em",
      color: "#fff",
      // borderStyle:'solid',
      // borderWidth:'1 !important',
      // borderColor:'#3f51b5 !important',
      backgroundColor: green[500],
      width: theme.spacing(12),
      height: theme.spacing(12),
    },
    paperCard: {
      padding: 20,
      width: "100%",
    },
    box: {
      position: "relative",
      width: "100%", //theme.spacing.unit * 50,
      padding: theme.spacing.unit * 1,
      outline: "none",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    secondNavigationModal: {
      position: "absolute",
      width: "50%",
      height: "400px",
      maxHeight: "400px",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing.unit * 2,
      outline: "none",
    },
  }));

  const elegirTipoGestion = (codigo, llave) => {
    console.log("elegirTipoGestion", codigo, llave);
    let objTipoGestion = listaTipoGestion.filter((el) => {
      return el.CodigoProblema === codigo;
    });
    if (!Array.isArray(objTipoGestion) && objTipoGestion.length < 1) {
      return;
    }
    console.log("objTipoGestion", objTipoGestion);
    setTipoGestion(objTipoGestion[0]);
    setCodigoTipoGestion(codigo);
    setOpenNavegador(false);
  };

  const listarProblemasCarpeta = async (carpeta) => {
    const request = await api.fetchGetRequest(
      "api/carpetaController/listarProblemasCarpeta/" +
        carpeta.Codigo +
        "/" +
        carpeta.Llave
    );
    console.log("Datos carpet", request.data.data);
    if (Array.isArray(request.data.data)) {
      setOpenNavegador(false);
      //removerTipoGestion();
    }
  };

  const cargarListaTipoGestion = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    }
    const request = await api.fetchGetRequest(
      `api/carpetaController/listadoGestiones/${session.codigoUsuario}`
    );

    if (Array.isArray(request.data.asignados)) {
      setListaTipoGestion(request.data.asignados )
    }
  };

  const datosTipoGestión = () => {
    //Obtiene tipo de gestion actual en caso de haber sido enviada por url
    if (
      codigoTipoGestion !== null &&
      codigoTipoGestion !== undefined
    ) {
      let actual = listaTipoGestion.filter((tipo) => {
        return (
          tipo.CodigoProblema === parseInt(codigoTipoGestion)
        );
      });
      if (actual.length < 1) {
        return;
      } else {
        actual = actual[0];
      }
      setTipoGestion(actual)      
    }
  }

  const classes = styles();

  return (
    <Layout titulo={`Kanban Gestiones`} maxWidth={"100%"}>
    <DragDropContext onDragEnd={handleDragEnd}>
      <AppBar
        position="sticky"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: openToolbar,
        })}
        style={{ backgroundColor: "#2B3C4D" }}
      >
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            style={{ display: "flex", flexGrow: 1 }}
            onClick={() => {
              setOpenNavegador(true);
            }}
          >
            {`Progreso de las gestiones del tipo: ${tipoGestion? tipoGestion.Nombre: '<No ha seleccionado un tipo de gestión>'}`}
          </Typography>

          <Tooltip
            title={"Seleccionar tipo de gestión"}
            aria-label="Seleccionar tipo de gestión"
          >
            <IconButton
              aria-label="Seleccionar tipo de gestión"
              style={{ color: "white" }}
            >
              <FolderIcon
                onClick={() => {
                  setOpenNavegador(true);
                }}
              />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        {columns.map((columna) => (
          <Column
            key={columna.CodigoTareaPlantilla}
            title={columna.Titulo}
            tasks={agruparGestiones(columna.CodigoTareaPlantilla)}
            id={columna.CodigoTareaPlantilla.toString()}
          />
        ))}
        {/* <Column title={"TO DO"} tasks={incomplete} id={"1"} />
        <Column title={"DONE"} tasks={completed} id={"2"} />
        <Column title={"BACKLOG"} tasks={[]} id={"3"} /> */}
      </div>
      <Modal
        // style={{ zIndex: 2990 }}
        aria-labelledby="Elegir tipo de gestión"
        aria-describedby="Elegir tipo de gestión"
        open={openNavegador}
        onClose={() => {
          setOpenNavegador(false);
        }}
      >
        <div style={getModalStyle()} className={classes.secondNavigationModal}>
          {/* <PerfectScrollbar> */}
          <form className={classes.container} noValidate autoComplete="off">
            <Typography variant="h6" gutterBottom>
              Elegir tipo de gestión
            </Typography>

            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <SecondNavigation
                  elegirTipoGestion={elegirTipoGestion}
                  listarProblemasCarpeta={listarProblemasCarpeta}
                />
              </Grid>
            </Grid>
          </form>
          {/* </PerfectScrollbar> */}
        </div>
      </Modal>
    </DragDropContext>
    </Layout>
  );
}
