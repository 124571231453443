import { Card, CardContent, CardMedia, Typography,makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((theme)=>(styles));

const styles = {
    cardimg:{
        width: 120,
        height: 120,
        borderRadius:'50%',
        objectFit:'cover',
        margin: 4
    },
    cardimglg:{
        width: 300,
        height: 200,
        objectFit:'cover',
        margin: 5
    },
    details: {
        display: 'flex',
        flexDirection: 'row',
      },
    root: {
        display: 'flex',
    },
    content: {
        flex: '1 0 auto',
      },
}

const ImageCard = (props) => {
    const classes = useStyles();

    return (
        <div>
            <Card
                className={classes.root}
                fullWidth
            >
                <div className={classes.details}>
                    <CardMedia
                        className={!props.long?classes.cardimg:classes.cardimglg}
                        image={props.image}
                    ></CardMedia>
                    <CardContent
                        className={classes.content}
                    >
                        <Typography variant="subtitle1">{props.primary}</Typography>
                        <Typography variant="subtitle1" color="textSecondary">{props.secondary}</Typography>
                        <Typography variant="subtitle1" color="textSecondary">{props.third}</Typography>
                    </CardContent>
                </div>
            </Card>
        </div>
    )
}

export default ImageCard
