import { Card } from "material-ui";
import React from "react";
import { Draggable } from "react-beautiful-dnd";
import styled from "styled-components";
import Avatar from "@material-ui/core/Avatar";
import avisoDetalleTabs from "../ejecucion-avisos/container/aviso-detalle-tabs";
import Tooltip from "@material-ui/core/Tooltip";
import { Bookmark } from "material-ui-icons";

const Container = styled.div`
  padding: 8px;
  padding-top: 15px;
  color: #000;
  min-height: 90px;
  background-color: ${(props) => bgcolorChange(props)};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const TextContent = styled.div`
  color: #fff;
  font-weight: bold;
  font-size: small;
`;

const Icons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  padding: 2px;
`;
function bgcolorChange(props) {
  return props.isDragging
    ? "#EAF4FC"
    : props.isDraggable
    ? props.isBacklog
      ? "#FFF"
      : "#DCDCDC"
    : props.isBacklog
    ? "green"
    : "#FFF";
}

export default function Task({ task, index }) {
  return (
    <Draggable
      draggableId={`${task.CodigoAviso}`}
      key={task.CodigoAviso}
      index={index}
    >
      {(provided, snapshot) => (
        <Card style={{ marginTop: 5 }}>
          <Container
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            isDragging={snapshot.isDragging}
          >
            <div
              style={{ display: "flex", justifyContent: "start", padding: 2 }}
            >
              <span
                style={{
                  backgroundColor: "#27ae60",
                  paddingLeft: 8,
                  paddingRight: 8,
                  borderRadius: 25,
                }}
              >
                <TextContent>{task.Titulo}</TextContent>
              </span>
            </div>

            <div
              style={{ display: "flex", justifyContent: "start", padding: 2 }}
            >
              {task.Descripcion}
            </div>
            <Icons>
              <Bookmark
                color="success"
                style={{ fontSize: "small", color: "#27ae60" }}
              />
              <div
                style={{
                  fontSize: "xx-small",
                  flexGrow: 4,
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                <a href={`gestion/${task.CodigoAviso}`} target="_blank">
                  #{task.CodigoAviso}
                </a>
              </div>

              <Tooltip
                title={task.UsuarioAfectado}
                aria-label="Seleccionar tipo de gestión"
              >
                <Avatar
                  aria-label="Usuario afectado"
                  style={{
                    backgroundColor: "rgb(33, 126, 207)",
                    width: 24,
                    height: 24,
                    fontSize: 10,
                  }}
                >
                  {task.UsuarioAfectado.substring(0, 2)}
                </Avatar>
              </Tooltip>
            </Icons>
            {provided.placeholder}
          </Container>
        </Card>
      )}
    </Draggable>
  );
}
