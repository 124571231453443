import React, { Fragment, useEffect, useState } from 'react';
import { Stepper, Step, StepLabel, StepConnector, makeStyles, withStyles, Typography, Avatar, Badge } from '@material-ui/core';
import './styles.css';
import UserAvatar from '../../helpers/components/user-avatar';
import ModalAsignados from './modal-asignados';

const stepSize = 60;

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: stepSize,
        height: stepSize,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
    },
    active: {
        backgroundColor: '#FFF',
        border: '2px solid #8057D7',
        // boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
        backgroundColor: '#8057D7',
    },
    responsText: {
        position: 'absolute',
        top: `-${(stepSize / 2)}px`,
        color: '#000000a6',
        fontWeight: '600',
        textAlign: 'center',
        display: 'inline-block',
        whiteSpace: 'pre'
    },
    avatarLarge: {
        width: theme.spacing(6),
        height: theme.spacing(6),
    },
    avatarGreen: {
        color: "white",
        backgroundColor: "#69BD4B",
    },
    asignadoContainer: {
        cursor: 'pointer'
    }
}));

const ColorlibStepIcon = (props) => {
    const classes = useStyles();
    const { active, completed, numero, usuarios, setOpen, setUsuarios } = props;

    const getIniciales = (nombre) => {
        const _nombre = nombre ? nombre.toUpperCase() : 'NA'
        return `${_nombre[0]}${_nombre[1]}`
    }

    return (
        <div
            className={`${classes.root} ${active && classes.active} ${completed && classes.completed}`}
        >
            {(active || completed) ? (
                <div onClick={() => {setOpen(true); setUsuarios((usuarios && usuarios.length) ? usuarios : [])}} className={classes.asignadoContainer}>
                    <Badge badgeContent={(usuarios && usuarios.length > 1) ? usuarios.length : 0} color="secondary">
                        <span className={classes.responsText}>
                            {(usuarios && usuarios.length) ? usuarios[0].Usuario.UsuarioAD : 'Sin asignar'}
                        </span>
                        <UserAvatar
                            iniciales={getIniciales((usuarios && usuarios.length) ? usuarios[0].Usuario.UsuarioAD : 'NA')}
                            userName={(usuarios && usuarios.length) ? usuarios[0].Usuario.Usuario : ''}
                            nombre={(usuarios && usuarios.length) ? usuarios[0].Usuario.NombreCompletoUsuario : 'NA'}
                            isAsignado={(usuarios && usuarios.length)}
                            // isAsignado={false}
                            imagenPerfil={(usuarios && usuarios.length) ? usuarios[0].Usuario.ImagenPerfil : ''}
                        />
                    </Badge>
                </div>
            ) : (
                <Typography>{numero}</Typography>
            )}
        </div>
    );
}

const Conector = withStyles({
    alternativeLabel: {
        top: (stepSize / 2) - 2,
    },
    active: {
        '& $line': {
            backgroundColor: '#8057D7',
        },
    },
    completed: {
        '& $line': {
            backgroundColor: '#8057D7',
        },
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: '#DDDDDD',
        borderRadius: 1,
    },
})(StepConnector);

const steps = [
    { label: 'Tarea 1', key: 1 },
    { label: 'Tarea 2', key: 2 },
    { label: 'Tarea 3', key: 3 },
    { label: 'Tarea 4', key: 4 }
];

const StepTarea = ({ data }) => {
    const classes = useStyles();

    const [activeStep, setActiveStep] = useState(0);
    const [open, setOpen] = useState(false);
    const [usuarios, setUsuarios] = useState([]);


    useEffect(() => {
        let _activeStep = (data && data.length) ? data.length : 0;
        if (data && data.length) {
            data.forEach((item, index) => {
                if (item.CodigoEstadoTarea == 2) _activeStep = index;
            });
            setActiveStep(_activeStep);
        }
    }, [data]);

    return (
        <div style={{ width: '100%' }}>
            <ModalAsignados open={open} handleClose={() => { setOpen(false); setUsuarios([]) }} usuarios={usuarios} />
            <Stepper alternativeLabel activeStep={activeStep} connector={<Conector />}>
                {data.map((item, i) => (
                    <Step key={item.CodigoAvisoTarea}>
                        <StepLabel
                            StepIconComponent={ColorlibStepIcon}
                            StepIconProps={{ numero: item.Orden, usuarios: item.Usuarios, setOpen, setUsuarios }}>
                            {item.Titulo}
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </div>
    )
}

export default StepTarea;
