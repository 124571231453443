import React from 'react';
import { Box, Button, Checkbox, FormControlLabel, Grid, makeStyles, Paper, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import DatePicker from './date-picker';

const useStyles = makeStyles((theme) => ({
    paperRounded: {
        borderRadius: 10
    },
}));

const ToolBar = (props) => {
    const { carpetas, carpeta, fechaInicio, fechaFin, setCarpeta, setFechaInicio, setFechaFin,
        checkPendiente, checkFinalizada, checkResponsable, setCheckPendiente, setCheckFinalizada,
        setCheckResponsable, onClick } = props;

    const classes = useStyles();

    return (
        <Box mt={1} style={{ width: '100%' }}>
            <Paper style={{ padding: '1rem' }} elevation={4} classes={{ rounded: classes.paperRounded }}>
                <Grid container direction="row">
                    <Grid item md={2} container alignItems='center'>
                        <Autocomplete
                            id="carpetas"
                            value={carpeta}
                            onChange={(event, value) => setCarpeta(value)}
                            fullWidth
                            options={carpetas}
                            getOptionLabel={(option) => option.Nombre}
                            renderInput={(params) => <TextField {...params} label="Módulo de gestión" variant="standard" />}
                        />
                    </Grid>
                    <Grid item md={3} container justify='center' alignItems='center'>
                        <DatePicker label='Fecha Inicio' value={fechaInicio} onChange={value => setFechaInicio(value)} />
                    </Grid>
                    <Grid item md={3} container justify='center' alignItems='center'>
                        <DatePicker label='Fecha Fin' value={fechaFin} onChange={value => setFechaFin(value)} />
                    </Grid>
                    <Grid item md={2} container direction='column' justify='center'>
                        {/* <Typography>Estados</Typography> */}
                        <FormControlLabel
                            control={<Checkbox checked={checkPendiente} onChange={event => setCheckPendiente(event.target.checked)} name="Pendientes" />}
                            label="Pendientes"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={checkFinalizada} onChange={event => setCheckFinalizada(event.target.checked)} name="Finalizadas" />}
                            label="Finalizadas"
                        />
                    </Grid>
                    <Grid item md={2} container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <FormControlLabel
                            control={<Checkbox checked={checkResponsable} onChange={event => setCheckResponsable(event.target.checked)} name="Resonsable" />}
                            label="Agrupar por responsable"
                        />
                        <Button
                            onClick={onClick}
                            variant="outlined"
                            color="primary"
                            style={{ marginBottom: '0.2rem' }}
                            // endIcon={<SearchIcon />}
                        >
                            Cargar
                        </Button>
                        {/* <Button
                            onClick={onClick}
                            variant="outlined"
                            color="default"
                            style={{ marginTop: '0.2rem' }}
                            disabled
                            // endIcon={<SearchIcon />}
                        >
                            Agrupar por responsable
                        </Button> */}
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    )
}

export default ToolBar
