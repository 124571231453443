import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
  FormControlLabel,
  Grid,
  InputLabel,
  Modal,
  Radio,
  RadioGroup,
  Select,
  Tooltip,
} from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import {
  Typography,
  Backdrop,
  CircularProgress,
  Tab,
  Tabs,
  Box,
  Divider,
  TabScrollButton,
  FormControl,
  FormLabel
} from "@material-ui/core";

import Avatar from "@material-ui/core/Avatar";
import Container from "@material-ui/core/Container";
import { green } from "@material-ui/core/colors";
import Chip from "@material-ui/core/Chip";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import DnsIcon from "@material-ui/icons/Dns";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import PeopleIcon from "@material-ui/icons/People";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  validaPermisoPaginaActual,
  validaPermisoSecundarioPaginaActual,
} from "../utils/helpers";

//iconos

import {
  Face,
  PersonPin,
  AssignmentInd,
  FlightTakeoff,
  Add,
  HeadsetMic,
  NetworkCheck,
  LockOpen,
  Create,
  ScreenShare,
} from "@material-ui/icons";

import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
//input date

import PropTypes from "prop-types";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";

import Autocomplete from "@material-ui/lab/Autocomplete";

//Own components
// import Layout from "../../layout/containers/Layout";

import API from "../utils/api";
import { isSignedIn, profileImageChange, signOut } from "../utils/auth";
import Layout from "../layout/containers/Layout";
import CardAviso from "../ejecucion-avisos/components/card-aviso";
// import { FormControl } from "material-ui";
// import { FormLabel } from "material-ui";
import UsuariosList from "../avisos-objects/components/usuarios-list";
import { registrarAnalitica } from "../utils/firebase-config";

const a11yProps = (index) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Typography component={"span"} variant={"body2"}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const styles = makeStyles((theme) => ({
  item: {
    //border: "1px solid blue",
    margin: "10",
  },
  itemFlexGrow: {
    flexGrow: 1,
    // border: "1px solid red"
  },
  content: {
    display: "flex",
    // justify-content: "space-between",
    maxWidth: "400px",
    margin: "0 auto",
    padding: "10px 0",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
  paperPerfil: {
    position: "relative",
    width: "100%", //theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 1,
    outline: "none",
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 1,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  greenAvatar: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  paperCard: {
    padding: 20,
    width: "100%",
  },
  greenAvatarFoto: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(18),
    height: theme.spacing(18),
  },
  tabsVertical: {
    borderRight: `1px solid ${theme.palette.divider}`,
    overflow: "visible",
  },
  contenedorTabVertical: {
    // flexGrow: 1,
    //backgroundColor: theme.palette.background.paper,
    display: "flex",
    //height: 224,
    width: "100%",
  },
  box: {
    // flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  paperUserModal: {
    position: "absolute",
    width: "75%",
    height: "600px",
    maxHeight: "600px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
  crearIcon: {
    // width: '100%',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 0,
    padding: "0 0.5rem",
    paddingTop: 30,
    paddingBottom: 30,
    borderRadius: 10,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
    },
  },
  filaContainer: {
    marginTop: 25,
    marginBottom: 25,
    width: "100%",
    justifyContent: "center",
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
}));

const useTabs = makeStyles((theme) => {
  const tabsBackground = "linear-gradient(60deg, #53cf6d, #32613c)";
  const indicatorBackground = "rgba(255, 255, 255, .2)";
  const borderRadius = theme.spacing.unit;
  return {
    root: {
      width: "100%",
      borderRadius: theme.spacing.unit,
      background: tabsBackground,
      padding: 10,
      boxShadow: "0px 3px 15px rgba(34, 35, 58, 0.5)",
    },
    indicator: {
      height: "100%",
      borderRadius,
      backgroundColor: indicatorBackground,
    },
  };
});
const useTabItem = makeStyles((theme) => {
  const tabsGutter = theme.spacing.unit * 2;
  const labelColor = "#ffffff";
  return {
    root: {
      textTransform: "initial",
      margin: `0 ${tabsGutter}px`,
      minWidth: 0,
      color: labelColor,
    },
    wrapper: {
      fontWeight: "normal",
      letterSpacing: 0.5,
      color: labelColor,
    },
  };
});
const PanelSoporte = (props) => {
  const [codigoUsuario, setCodigoUsuario] = useState(null);
  const [dpi, setDpi] = useState("");
  const [permisoVerTodo, setPermisoVerTodo] = useState(true);
  const [permisoRolAprendizaje, setPermisoRolAprendizaje] = useState(false);
  const [permisoAnalisisSalarial, setPermisoAnalisisSalarial] = useState(false);
  const [permisoCorreoElectronico, setPermisoCorreoElectronico] =
    useState(false);
  const [usuario, setUsuario] = useState("");
  const [usuario2, setUsuario2] = useState("");
  const [nombreCompletoUsuario, setNombreCompletoUsuario] = useState("");
  const [correoElectronico, setCorreoElectronico] = useState("");
  const [autenticacionWindows, setAutenticacionWindows] =
    useState("initialState");
  const [usuarioAD, setUsuarioAD] = useState("");
  const [imagenPerfil, setImagenPerfil] = useState(null);
  const [codigoUsuarioJefeInmediato, setCodigoUsuarioJefeInmediato] =
    useState(0);
  const [codigoUsuarioGerenteArea, setCodigoUsuarioGerenteArea] = useState(0);
  const [roles, setRoles] = useState([]);
  const [listaUsuarios, setListaUsuarios] = useState([]);
  const [listaUsuariosModal, setListaUsuariosModal] = useState([]);
  const [jefeInmediato, setJefeInmediato] = useState(null);
  const [gerenteArea, setGerenteArea] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagenFirma, setImagenFirma] = useState(null);
  const [buscando, setBuscando] = useState(false);
  const [dataEmpleado, setDataEmpleado] = useState({});
  const [encontrado, setEncontrado] = useState(false);
  const [selectedFileFirma, setSelectedFileFirma] = useState(null);
  const [foto, setFoto] = useState(null);
  const [inicialesUsuario, setInicialesUsuario] = useState("");
  const [tab, setTab] = useState(0);
  const [tabVertical, setTabVertical] = useState(2);
  const [listaTopAvisos, setListaTopAvisos] = useState([]);
  const [txtTextoBusquedaUsuario, setTxtTextoBusquedaUsuario] = useState("");
  const [openAsignarUsuarios, setOpenAsignarUsuarios] = useState(true);
  const [dataAD, setDataAD] = useState(null);
  const [aDAccordion, setADAccordion] = useState(false);
  const [respuestaPing, setRespuestaPing] = useState("");
  const [openPing, setOpenPing] = useState(false);
  const [openDesbloquearUsuario, setOpenDesbloquearUsuario] = useState(false);
  const [txtUsuario, setTxtUsuario] = useState("");
  const [sistema, setSistema] = useState("");

  const classes = styles();
  const tabsStyles = useTabs();
  const tabItemStyles = useTabItem();

  const handleCerrarBackDrop = () => {
    setBuscando(false);
  };
  const handleAbrirBackDrop = () => {
    setBuscando(true);
  };

  useEffect(() => {
    if (codigoUsuario === null) return;
    async function fetchData() {
      cargarDatosPerfil();
      getTopAvisos();
      //await buscar();
      //await cargarListaUsuarios();
    }

    fetchData();
  }, [codigoUsuario]);

  useEffect(() => {
    async function fetchData() {
      // await cargarDatosPerfil();
      if (dpi !== "") {
        await buscarPorDPI();
        //await cargarListaUsuarios();
      }
    }

    fetchData();
  }, [dpi]);

  useEffect(() => {
    if (usuarioAD !== "" && usuarioAD !== null) {
      // si tiene usuarioAD cargar datos de Active directory
      cargarDatosAD(usuarioAD);
    }
  }, [usuarioAD]);

  // useEffect(() => {
  //   async function fetchData() {
  //     await buscar();
  //   }

  //   fetchData();
  // }, [usuario]);

  const cargarDatosAD = async (userName) => {
    try {
      const request = await API.fetchGetRequest(
        `api/Usuario/getaduserdata/${userName}`
      );

      if (request.data.AD == null) {
        return;
      }

      if (request.data.AD.email === null) {
        return;
      }

      setDataAD(request.data.AD);
    } catch (error) {}
  };

  const cargarDatosPerfil = async () => {
    let session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    }
    try {
      setBuscando(true);
      setDataAD(null);
      let codigo = codigoUsuario; //session.codigoUsuario;

      const request = await API.fetchGetRequest(
        `api/Usuario/getProfileData/${codigo}`
      );

      if (request.data.usuarios == null) {
        return;
      }

      if (!Array.isArray(request.data.usuarios)) {
        return;
      }

      let user = request.data.usuarios[0];

      //setCodigoUsuario(user.CodigoUsuario);
      setUsuario(user.Usuario);
      setUsuario2(user.Usuario);
      setNombreCompletoUsuario(user.NombreCompletoUsuario);
      setCorreoElectronico(user.CorreoElectronico);
      setAutenticacionWindows(user.AutenticacionWindows);
      setUsuarioAD(user.UsuarioAD);
      setImagenPerfil(user.ImagenPerfil);
      setImagenFirma(user.ImagenFirma);
      setCodigoUsuarioJefeInmediato(user.CodigoUsuarioJefeInmediato);
      setCodigoUsuarioGerenteArea(user.CodigoUsuarioGerenteArea);
      setRoles(Array.isArray(user.Roles) ? user.Roles : []);
      if (user.NombreCompletoUsuario !== null) {
        if (user.NombreCompletoUsuario.length > 0) {
          setInicialesUsuario(
            user.NombreCompletoUsuario.substring(0, 2).toUpperCase()
          );
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setBuscando(false);
    }
  };
  const buscar = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    }

    try {
      setBuscando(true);
      setEncontrado(false);

      let uri = "api/fichaClinica/empleado/" + usuario;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        console.log(request.data.data);
        if (request.data.response) {
          setDataEmpleado(request.data.data[0]);

          setEncontrado(true);
          cargarFoto(request.data.data[0].DPI);
          setPermisoVerTodo(session.userName === request.data.data[0].DPI);
          const llaveAgrupacion = "Menu.Principal.Web";
          const llavePagina = "Menu.Principal.Web.Aprendizaje";
          let permisoSkill = await validaPermisoSecundarioPaginaActual(
            llaveAgrupacion,
            llavePagina
          );
          console.log("Tengo Permiso de aprendizaje", permisoSkill);
          setPermisoRolAprendizaje(permisoSkill);

          const llavePaginaCorreoElectronico =
            "Menu.Principal.Web.CorreoElectronico";
          let permisosCorreo = await validaPermisoSecundarioPaginaActual(
            llaveAgrupacion,
            llavePaginaCorreoElectronico
          );
          console.log("Tengo Permiso de correo", permisosCorreo);
          setPermisoCorreoElectronico(permisosCorreo);

          const llaveAgrupacionGESPILU = "GESPILU";
          const llavePaginaGESPILU = "GESPILU.AnalisisSalarial.Editar_Completo";
          let permisoGESPILU = await validaPermisoSecundarioPaginaActual(
            llaveAgrupacionGESPILU,
            llavePaginaGESPILU
          );

          setPermisoAnalisisSalarial(permisoGESPILU);

          if (session.userName === request.data.data[0].DPI) {
            setTabVertical(0);
          } else {
            setTabVertical(2);
          }
        } else {
          setTabVertical(0);
          //toast.error("No se encontro al empleado");
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setBuscando(false);
    }
  };

  const buscarPorDPI = async () => {
    try {
      setBuscando(true);
      setEncontrado(false);
      console.log("DPI", dpi);
      setUsuario2(dpi);
      let uri = "api/fichaClinica/empleado/" + dpi;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        console.log(request.data.data);
        if (request.data.response) {
          setDataEmpleado(request.data.data[0]);
          setNombreCompletoUsuario(request.data.data[0].NombreEmpleado);
          if (request.data.data[0].NombreEmpleado !== null) {
            if (request.data.data[0].NombreEmpleado.length > 0) {
              setInicialesUsuario(
                request.data.data[0].NombreEmpleado.substring(
                  0,
                  2
                ).toUpperCase()
              );
            }
          }
          setEncontrado(true);
          cargarFoto(request.data.data[0].DPI);
          setPermisoVerTodo(dpi === request.data.data[0].DPI);
          const llaveAgrupacion = "Menu.Principal.Web";
          const llavePagina = "Menu.Principal.Web.Aprendizaje";
          let permisoSkill = await validaPermisoSecundarioPaginaActual(
            llaveAgrupacion,
            llavePagina
          );
          console.log("Tengo Permiso de aprendizaje", permisoSkill);
          setPermisoRolAprendizaje(permisoSkill);

          const llaveAgrupacionGESPILU = "GESPILU";
          const llavePaginaGESPILU = "GESPILU.AnalisisSalarial.Editar_Completo";
          let permisoGESPILU = await validaPermisoSecundarioPaginaActual(
            llaveAgrupacionGESPILU,
            llavePaginaGESPILU
          );

          setPermisoAnalisisSalarial(permisoGESPILU);

          if (dpi === request.data.data[0].DPI) {
            setTabVertical(0);
          } else {
            setTabVertical(2);
          }
        } else {
          setTabVertical(0);
          //toast.error("No se encontro al empleado");
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setBuscando(false);
    }
  };

  const cargarListaUsuarios = async () => {
    let criterio = "x-x";
    const request = await API.fetchGetRequest(`api/Usuario/buscar/${criterio}`);
    try {
      if (Array.isArray(request.data.usuarios)) {
        setListaUsuarios(request.data.usuarios);
      } else {
        setListaUsuarios([]);
      }
    } catch (error) {
      console.log("cargarListaTareas error:" + error);
    }
  };

  const cargarListaUsuariosModal = async () => {
    let criterio = txtTextoBusquedaUsuario;
    const request = await API.fetchGetRequest(`api/Usuario/buscar/${criterio}`);
    try {
      if (Array.isArray(request.data.usuarios)) {
        console.log("usuariosModal", request.data.usuarios);
        setListaUsuariosModal(request.data.usuarios);
      } else {
        setListaUsuariosModal([]);
      }
    } catch (error) {
      console.log("cargarListaTareas error:" + error);
    }
  };

  const seleccionarUsuario = async (codigoUsuario) => {
    if (codigoUsuario && codigoUsuario > 0) {
      setCodigoUsuario(codigoUsuario);

      const fila = listaUsuariosModal.filter(
        (x) => x.CodigoUsuario === codigoUsuario
      );
      console.log("Fila seleccionada", fila);
      if (Array.isArray(fila) && fila.length > 0) {
        setDpi(fila[0].Usuario);
      }

      setOpenAsignarUsuarios(false);
    }
  };

  const actualizarJefeInmediato = async () => {
    //const { codigoUsuario, codigoUsuarioJefeInmediato } = this.state;

    try {
      let vIsSignedIn = await isSignedIn();
      if (vIsSignedIn.response) {
        let request;
        // let data;

        request = await API.post(
          `api/usuario/setJefeInmediato/${
            codigoUsuario === null ? 0 : codigoUsuario
          }/${
            codigoUsuarioJefeInmediato === null ? 0 : codigoUsuarioJefeInmediato
          }`
        );
        if (request.status !== 200) {
          alert(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        }

        if (request.statusCode === 401) {
          //alert("cerrar sesion");
        } else {
          toast.success("El jefe inmediato se asignó exitosamente.");
        }
      }
    } catch (e) {
      alert("hubo un error");
    }
  };

  useEffect(() => {
    async function fetchData() {
      if (
        codigoUsuarioJefeInmediato !== 0 &&
        buscando === false &&
        listaUsuarios.length > 0
      ) {
        await actualizarJefeInmediato();

        if (listaUsuarios.length > 0) {
          let jefeInmediatoItem = listaUsuarios.filter((usuario) => {
            return (
              usuario.CodigoUsuario === parseInt(codigoUsuarioJefeInmediato)
            );
          });
          if (jefeInmediatoItem.length < 1) {
            jefeInmediatoItem = null;
          } else {
            jefeInmediatoItem = jefeInmediatoItem[0];
          }
          setJefeInmediato(jefeInmediatoItem);
        }
      }
    }

    fetchData();
  }, [codigoUsuarioJefeInmediato]);

  useEffect(() => {
    if (codigoUsuarioJefeInmediato > 0) {
      let jefeInmediatoItem = listaUsuarios.filter((usuario) => {
        return usuario.CodigoUsuario === parseInt(codigoUsuarioJefeInmediato);
      });
      if (jefeInmediatoItem.length < 1) {
        jefeInmediatoItem = null;
      } else {
        jefeInmediatoItem = jefeInmediatoItem[0];
      }
      setJefeInmediato(jefeInmediatoItem);
    }
    if (codigoUsuarioJefeInmediato > 0) {
      let gerenteArea = listaUsuarios.filter((usuario) => {
        return usuario.CodigoUsuario === parseInt(codigoUsuarioGerenteArea);
      });
      if (gerenteArea.length < 1) {
        gerenteArea = null;
      } else {
        gerenteArea = gerenteArea[0];
      }
      setGerenteArea(gerenteArea);
    }
  }, [listaUsuarios]);

  const actualizarGerenteArea = async () => {
    //const { codigoUsuario, codigoUsuarioGerenteArea } = this.state;

    try {
      let vIsSignedIn = await isSignedIn();
      if (vIsSignedIn.response) {
        let request;
        // let data;

        request = await API.post(
          `api/usuario/setGerenteArea/${
            codigoUsuario === null ? 0 : codigoUsuario
          }/${codigoUsuarioGerenteArea === null ? 0 : codigoUsuarioGerenteArea}`
        );
        if (request.status !== 200) {
          alert(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        }

        if (request.statusCode === 401) {
          //alert("cerrar sesion");
        } else {
          toast.success("El gerente de área se asignó exitosamente.");
        }
      }
    } catch (e) {
      alert("hubo un error");
    }
  };

  useEffect(() => {
    async function fetchData() {
      if (
        codigoUsuarioGerenteArea !== 0 &&
        buscando === false &&
        listaUsuarios.length > 0
      ) {
        await actualizarGerenteArea();

        if (listaUsuarios.length > 0) {
          let gerenteAreaItem = listaUsuarios.filter((usuario) => {
            return usuario.CodigoUsuario === parseInt(codigoUsuarioGerenteArea);
          });
          if (gerenteAreaItem.length < 1) {
            gerenteAreaItem = null;
          } else {
            gerenteAreaItem = gerenteAreaItem[0];
          }
          setGerenteArea(gerenteAreaItem);
        }
      }
    }

    fetchData();
  }, [codigoUsuarioGerenteArea]);
  // const eliminar = async (codigo) => {
  //   let vIsSignedIn = await isSignedIn();

  //   if (vIsSignedIn.response) {
  //     const request = await API.post(
  //       `api/causa/eliminar/${codigo}/${vIsSignedIn.userName}`
  //     );
  //     if (request.status != 200) {
  //       alert(
  //         "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
  //       );
  //     }

  //     if (request.statusCode === 401) {
  //       //alert("cerrar sesion");
  //     } else {
  //       toast.success("El registro se eliminó exitosamente.");
  //       cargarLista();
  //     }
  //   } else {
  //     toast.error("La sessión no se encuentra activa");
  //   }
  // };

  const onFileChange = (event) => {
    // Update the state
    setSelectedFile(event.target.files[0]);
  };

  useEffect(() => {
    async function fetchData() {
      if (selectedFile !== null) {
        await onFileUpload();
      }
    }

    fetchData();
  }, [selectedFile]);

  const onFileChangeFirma = (event) => {
    // Update the state
    setSelectedFileFirma(event.target.files[0]);
    //  async () => {
    //   //Callback
    //   onFileUploadFirma();
    // });
  };

  useEffect(() => {
    async function fetchData() {
      if (selectedFileFirma !== null) {
        await onFileUploadFirma();
      }
    }

    fetchData();
  }, [selectedFileFirma]);

  useEffect(() => {
    //Cuando carga la página debe verificar si el usuario puede permanecer acá
    //CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
    const llaveAgrupacion = "Menu.Principal.Web";
    const llavePagina = "Menu.Principal.Web.panelsoporte";
    validaPermisoPaginaActual(llaveAgrupacion, llavePagina);
    //FIN DE CONSULTA DE PERMISOS
  }, []);

  const onFileUpload = async (event) => {
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append("myFile", selectedFile, selectedFile.name);

    // evalúa si el tipo de archivo es jpeg o png
    let tipoArchivo = selectedFile.type;

    if (tipoArchivo.includes("jpeg") || tipoArchivo.includes("png")) {
      const session = await isSignedIn();
      if (selectedFile !== null) {
        let data = new FormData();
        data.append("codigoUsuario", session["codigoUsuario"]);
        await data.append("files[]", selectedFile);

        // await files.forEach(async (element2, j) => {
        //   await data.append("files[]", element2);
        // });

        let request = await API.post("api/usuario/foto", data, {
          "content-type": "multipart/form-data",
        });

        if (request && request.data.response) {
          setImagenPerfil(request.data.data.Foto);
          //Cambia imagen de perfil en localStorage
          await profileImageChange(request.data.data.Foto);
          //Recarga la página para forzar la actualización en caché
          window.location.reload();
        } else {
          toast.error("No se pudo realizar la operación");
        }
      }
    } else {
      toast.warn("Elige un archivo válido (jpg o png)");
    }
  };

  const onFileUploadFirma = async () => {
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append("myFile", selectedFileFirma, selectedFileFirma.name);

    // evalúa si el tipo de archivo es jpeg o png
    let tipoArchivo = selectedFileFirma.type;

    if (tipoArchivo.includes("jpeg") || tipoArchivo.includes("png")) {
      const session = await isSignedIn();
      if (selectedFileFirma !== null) {
        let data = new FormData();
        data.append("codigoUsuario", session["codigoUsuario"]);
        await data.append("files[]", selectedFileFirma);

        // await files.forEach(async (element2, j) => {
        //   await data.append("files[]", element2);
        // });

        let request = await API.post("api/usuario/subirFirma", data, {
          "content-type": "multipart/form-data",
        });

        if (request && request.data.response) {
          setImagenFirma(request.data.data.Foto);
          //Cambia imagen de perfil en localStorage
          //await profileImageChange(request.data.data.Foto);
          //Recarga la página para forzar la actualización en caché
          window.location.reload();
        } else {
          toast.error("No se pudo realizar la operación");
        }
      }
    } else {
      toast.warn("Elige un archivo válido (jpg o png)");
    }
  };

  const onHandleChangeDatosEmpleado = async (nuevoDataEmpleado) => {
    setDataEmpleado(nuevoDataEmpleado);
  };

  const cargarFoto = async (dpiEmpleado) => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    }

    try {
      let uri = "api/Empleado/Foto/" + dpiEmpleado;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        setFoto(null);
        return false;
      } else {
        if (request.data.data !== null) {
          setFoto(request.data.data);
        }
      }
    } catch (e) {
      setFoto(null);
      console.log(e);
    } finally {
    }
  };

  const getAvisosTerminados = async () => {
    //Obtenemos los filtros del storage, de lo contrario dejamos el default.
    let direccion = "asc";
    let filtroPorReq = "fecha-creacion";
    let filtrosExtras = { limit: 15 };
    try {
      const session = await isSignedIn();

      let uri = `api/aviso/propiosTerminados/${codigoUsuario}`;

      const request = await API.fetchGetRequestWithParams(uri, filtrosExtras);
      console.log(request);
      if (request.statusCode === 401) {
        if (await signOut()) {
          window.location.href = "/login";
        }
        return;
      }

      if (request.data.response && Array.isArray(request.data.data)) {
        //OK
        return request.data.data;
      } else {
        //El API alerta error.
        return [];
      }
    } catch (e) {
      //Hubo error en el request al server
      console.error("Error al recuperar las gestiones terminadas", e);
    }
  };

  const getAvisosPendientes = async () => {
    //Obtenemos los filtros del storage, de lo contrario dejamos el default.
    let direccion = "asc";
    let filtroPorReq = "fecha-creacion";
    let filtrosExtras = { limit: 15 };

    try {
      const session = await isSignedIn();
      console.log("CodigoUsuarioPanel", codigoUsuario);
      let uri = `api/aviso/propios/${codigoUsuario}`;
      const request = await API.fetchGetRequestWithParams(uri, filtrosExtras);
      if (request.statusCode === 401) {
        if (await signOut()) {
          window.location.href = "/login";
        }
        return;
      }

      if (request.data.response && Array.isArray(request.data.data)) {
        return request.data.data;
      } else {
        //El API alerta error.
        return [];
      }
    } catch (e) {
      //Hubo error en el request al server
      console.error(
        "Ocurrió un error al recuperar las gestiones creadas pendientes",
        e
      );
    }
  };

  const getTopAvisos = async () => {
    setListaTopAvisos([]);
    const terminados = await getAvisosTerminados();
    const pendientes = await getAvisosPendientes();

    const merge = [...terminados, ...pendientes];
    console.log("terminados", terminados);
    console.log("pendientes", pendientes);
    console.log("merge", merge);
    //ordena descendentemente
    merge.sort((a, b) => new Date(b.FechaInserto) - new Date(a.FechaInserto));

    console.log("merge descendente", merge);
    const top = merge.slice(0, 5);
    console.log("merge Top5 miremos", top);

    setListaTopAvisos(top);
  };

  const nuevaGestion = () => {
    // props.history.push("/admongestiones");
    window.open(`/admongestiones/${dpi}`, "_blank");
  };

  const soporte = async () => {
    try {
      registrarAnalitica("Solicitar_Soporte");
      const { data } = await API.fetchGetRequest("api/aviso/idgestionSoporte");
      console.log("data ", data);
      // props.history.push(`/aviso/nuevo/${data.data}`);
      window.open(`/aviso/nuevo/${data.data}/${dpi}`);
    } catch (e) {
      this.setState({ isLoading: false, error: true });
    }
  };

  const ping = async () => {
    try {
      setRespuestaPing("Intentando conexión con el host...");
      setOpenPing(true);

      let hostname = "";
      if (
        dataAD !== null &&
        dataAD.nombrePC !== null &&
        dataAD.nombrePC !== ""
      ) {
        hostname = dataAD.nombrePC;
        const request = await API.fetchGetRequest(
          `api/Usuario/ping/${hostname}`
        );
        setRespuestaPing(request.data.AD);
        console.log("data ", request.data);
      }
    } catch (e) {
      console.error("Error al hacer ping", e);
      setRespuestaPing("Error al hacer ping", e);
    } finally {
    }
  };

  const handleChangeSistema = (e) => {
    console.log(e.target.value);
    setSistema(e.target.value);
  };

  const unlock = async () => {
    setTxtUsuario(usuarioAD);
    setSistema("1");
    setOpenDesbloquearUsuario(true);
  };

  const desbloquearUsuario = async () => {
    try {
      setBuscando(true);

      let request;

      if (!txtUsuario) {
        toast.warn("Escribe un nombre de usuario válido");
        return;
      }

      if (!sistema) {
        toast.warn("Selecciona un sistema");
        return;
      }

      if (sistema === "1") {
        // AD
        request = await API.post("/api/desbloquear/usuario/" + txtUsuario);
      } else {
        // SAP
        request = await API.post("/api/desbloquear/usuarioSap/" + txtUsuario);
      }

      toast.success(request.data.mensaje);
    } catch (error) {
      console.error("Error al desbloquear usuario:", error);
    } finally {
      setBuscando(false);
    }
  };

  const remoteAssistance = () => {
    // Crear el contenido del archivo .bat
    const ipAddress = dataAD.nombrePC; // Aquí puedes personalizar la IP o nombre de host
    const batContent = `@echo off\nmsra.exe /offerra ${ipAddress}\npause`;

    // Crear un Blob (Binary Large Object) con el contenido del archivo .bat
    const blob = new Blob([batContent], { type: "text/plain" });

    // Crear una URL para el blob
    const url = URL.createObjectURL(blob);

    // Crear un enlace temporal y simular la descarga del archivo .bat
    const a = document.createElement("a");
    a.href = url;
    a.download = "asistencia_remota.bat"; // Nombre del archivo para descargar
    a.click(); // Simular un clic para iniciar la descarga

    // Limpiar la URL del blob
    URL.revokeObjectURL(url);
  };
  return (
    <React.Fragment>
      <Layout titulo={"Panel de soporte"}>
        <Backdrop className={classes.backdrop} open={buscando}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Container maxWidth="xl">
          <Paper id="resultado" className={classes.paperPerfil}>
            <Box
              spacing={0}
              //bgcolor="#6ea02f"
              alignItems="center"
              justifyContent="center"
              align="center"
            >
              <Box
              // style={{display: "flex",
              //   flexDirection: "row",
              //   alignContent:'flex-end',
              //   alignItems:'flex-end',
              //   justifyContent:'flex-end',
              //   justifyItems:'flex-end',
              //   flexGrow:2,
              // }}
              // onClick={() => {
              //   console.log("abre");
              //   setOpenAsignarUsuarios(true);
              // }}
              >
                {foto !== null ? (
                  <Avatar
                    alt={nombreCompletoUsuario}
                    src={foto}
                    className={[classes.greenAvatarFoto]}
                    style={{
                      border: "5px solid lightgray",
                    }}
                    onClick={() => {
                      console.log("abre");
                      setOpenAsignarUsuarios(true);
                    }}
                  />
                ) : imagenPerfil === null ||
                  imagenPerfil === undefined ||
                  imagenPerfil.endsWith("perfil/") ? (
                  <div className="container">
                    <Avatar
                      alt=""
                      className={classes.greenAvatarFoto}
                      style={{
                        cursor: "pointer",
                        border: "5px solid lightgray",
                      }}
                      onClick={() => {
                        setOpenAsignarUsuarios(true);
                      }}
                    >
                      {inicialesUsuario}
                    </Avatar>
                  </div>
                ) : (
                  <div className="container">
                    <Avatar
                      alt={nombreCompletoUsuario}
                      src={imagenPerfil}
                      className={[classes.greenAvatarFoto]}
                      style={{
                        cursor: "pointer",
                        border: "5px solid lightgray",
                      }}
                      onClick={() => {
                        console.log("abre");
                        setOpenAsignarUsuarios(true);
                      }}
                    />
                  </div>
                )}
              </Box>
              <Typography gutterBottom variant="h4">
                <b>{nombreCompletoUsuario}</b>
              </Typography>
              <div className={classes.filaContainer}>
                <Tooltip title="Nueva gestión">
                  <Paper
                    onClick={nuevaGestion}
                    className={classes.crearIcon}
                    style={{ backgroundColor: "#69BD4B" }}
                  >
                    <Add fontSize={"large"} style={{ color: "white" }} />
                  </Paper>
                </Tooltip>
                <Tooltip title="Soporte">
                  <Paper
                    onClick={soporte}
                    className={classes.crearIcon}
                    style={{
                      backgroundColor: "#202e3c",
                      marginLeft: "0.3rem",
                    }}
                  >
                    <HeadsetMic fontSize={"large"} style={{ color: "white" }} />
                  </Paper>
                </Tooltip>
                {dataAD !== null && dataAD.nombrePC !== null && (
                  <Tooltip title="Ping">
                    <Paper
                      onClick={ping}
                      className={classes.crearIcon}
                      style={{
                        backgroundColor: "#202e3c",
                        marginLeft: "0.3rem",
                      }}
                    >
                      <NetworkCheck
                        fontSize={"large"}
                        style={{ color: "white" }}
                      />
                    </Paper>
                  </Tooltip>
                )}

                {dataAD !== null && dataAD.nombrePC !== null && (
                  <Tooltip title="Asistencia remota">
                    <Paper
                      onClick={remoteAssistance}
                      className={classes.crearIcon}
                      style={{
                        backgroundColor: "#202e3c",
                        marginLeft: "0.3rem",
                      }}
                    >
                      <ScreenShare
                        fontSize={"large"}
                        style={{ color: "white" }}
                      />
                    </Paper>
                  </Tooltip>
                )}

                {dataAD !== null && dataAD.email !== null && (
                  <Tooltip title="Desbloquear usuario">
                    <Paper
                      onClick={unlock}
                      className={classes.crearIcon}
                      style={{
                        backgroundColor: "#202e3c",
                        marginLeft: "0.3rem",
                      }}
                    >
                      <LockOpen fontSize={"large"} style={{ color: "white" }} />
                    </Paper>
                  </Tooltip>
                )}
              </div>
            </Box>
            <Divider variant="middle" />

            <Box className={classes.box} pl={0} pt={0}>
              {permisoVerTodo && (
                <Grid container spacing={1}>
                  <Grid item xs={12} lg={6} md={6} sm={12} style={{}}>
                    <Paper className={classes.paperCard}>
                      <div
                        style={{
                          display: "flex",
                          alignContent: "column",
                          alignItems: "center",
                        }}
                      >
                        <Create
                          // <AccountCircleIcon
                          style={{
                            color: "#85c638",
                            marginRight: 5,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            window.open(`/usuario/${codigoUsuario}`, "_blank");
                          }}
                        />
                        <Typography
                          variant="caption"
                          style={{ fontWeight: "bold" }}
                        >
                          Usuario AppILU
                        </Typography>
                      </div>

                      <div> {usuario}</div>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} lg={6} md={6} sm={12} style={{}}>
                    <Paper className={classes.paperCard}>
                      <div
                        style={{
                          display: "flex",
                          alignContent: "column",
                          alignItems: "center",
                        }}
                      >
                        <AlternateEmailIcon
                          style={{ color: "#f1c40f", marginRight: 5 }}
                        />
                        <Typography
                          variant="caption"
                          style={{ fontWeight: "bold" }}
                        >
                          Email
                        </Typography>
                      </div>
                      <div>{correoElectronico}</div>
                    </Paper>
                  </Grid>

                  <Grid item xs={12} lg={6} md={6} sm={12} style={{}}>
                    <Paper className={classes.paperCard}>
                      <div
                        style={{
                          display: "flex",
                          alignContent: "column",
                          alignItems: "center",
                        }}
                      >
                        <DnsIcon style={{ color: "#d35400", marginRight: 5 }} />
                        <Typography
                          variant="caption"
                          style={{ fontWeight: "bold" }}
                        >
                          Autenticación Windows:
                        </Typography>
                      </div>
                      <div>{autenticacionWindows === true ? "Sí" : "No"}</div>
                    </Paper>
                  </Grid>
                  {autenticacionWindows === true ? (
                    <Grid item xs={12} lg={6} md={6} sm={12} style={{}}>
                      <Paper className={classes.paperCard}>
                        <div
                          style={{
                            display: "flex",
                            alignContent: "column",
                            alignItems: "center",
                          }}
                        >
                          <ContactMailIcon
                            style={{ color: "#3498db", marginRight: 5 }}
                          />
                          <Typography
                            variant="caption"
                            style={{ fontWeight: "bold" }}
                          >
                            Usuario AD
                          </Typography>
                        </div>
                        <div>{usuarioAD}</div>
                      </Paper>
                    </Grid>
                  ) : (
                    <></>
                  )}

                  {/* <Grid item xs={12} lg={6} md={6} sm={12} style={{}}>
                    <Paper className={classes.paperCard}>
                      <Autocomplete
                        value={jefeInmediato}
                        onChange={async (event, newValue) => {
                          setJefeInmediato(newValue ? newValue : -1);
                          setCodigoUsuarioJefeInmediato(
                            newValue ? newValue.CodigoUsuario : 0
                          );
                        }}
                        id="controllable-states-demo"
                        options={listaUsuarios}
                        getOptionLabel={(option) =>
                          option.NombreCompletoUsuario
                        }
                        style={{ width: "100%", margin: 10, marginTop: 25 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Jefe inmediato"
                            variant="outlined"
                          />
                        )}
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12} lg={6} md={6} sm={12} style={{}}>
                    <Paper className={classes.paperCard}>
                      <Autocomplete
                        value={gerenteArea}
                        onChange={(event, newValue) => {
                          setGerenteArea(newValue ? newValue : -1);
                          setCodigoUsuarioGerenteArea(
                            newValue ? newValue.CodigoUsuario : 0
                          );
                        }}
                        id="controllable-states-demo"
                        options={listaUsuarios}
                        getOptionLabel={(option) =>
                          option.NombreCompletoUsuario
                        }
                        style={{ width: "100%", margin: 10, marginTop: 25 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Gerente de área"
                            variant="outlined"
                          />
                        )}
                      />
                    </Paper>
                  </Grid>

                  <Grid item xs={12} lg={6} md={6} sm={12} style={{}}>
                    <Paper className={classes.paperCard}>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <input
                              accept="image/*"
                              className={classes.input}
                              id="firmaFile"
                              type="file"
                              style={{ display: "none" }}
                              onChange={onFileChangeFirma}
                            />
                            <label htmlFor="firmaFile">
                              <IconButton
                                color="primary"
                                aria-label="upload sign"
                                component="span"
                                className={""}
                              >
                                <BorderColorIcon />
                              </IconButton>
                            </label>
                          </div>
                          <Typography
                            variant="caption"
                            style={{ fontWeight: "bold" }}
                          >
                            Firma
                          </Typography>
                        </div>
                      </div>
                      <div>
                        <div
                          className=""
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          {imagenFirma === null ||
                          imagenFirma.endsWith("firma/") ? (
                            <Avatar
                              alt={"firma"}
                              className={[classes.greenAvatar, "avatarCircle"]}
                            >
                              <span style={{ fontSize: 16 }}>Sin firma</span>
                            </Avatar>
                          ) : (
                            <Avatar
                              alt={"firma"}
                              src={imagenFirma}
                              className={[classes.greenAvatar, "avatarCircle"]}
                            />
                          )}
                        </div>
                      </div>
                    </Paper>
                  </Grid> */}
                  <Grid item xs={12} lg={12} md={12} sm={12} style={{}}>
                    <Paper className={classes.paperCard}>
                      <div style={{ marginLeft: 10 }}>
                        <div
                          style={{
                            display: "flex",
                            alignContent: "column",
                            alignItems: "center",
                          }}
                        >
                          <PeopleIcon
                            style={{ color: "#3498db", marginRight: 5 }}
                          />
                          <Typography
                            variant="caption"
                            style={{ fontWeight: "bold" }}
                          >
                            Roles
                          </Typography>
                        </div>
                        <div>
                          {roles.map((item, i) => (
                            <Chip
                              key={i}
                              label={item}
                              color="primary"
                              style={{ marginLeft: 5, marginTop: 5 }}
                            />
                          ))}
                        </div>
                      </div>
                    </Paper>
                  </Grid>

                  <Grid item xs={12} lg={12} md={12} sm={12} style={{}}>
                    <Paper className={classes.paperCard}>
                      <div style={{ marginLeft: 10 }}>
                        <div
                          style={{
                            display: "flex",
                            alignContent: "column",
                            alignItems: "center",
                          }}
                        >
                          <DnsIcon
                            style={{ color: "#3498db", marginRight: 5 }}
                          />
                          <Typography
                            variant="caption"
                            style={{ fontWeight: "bold" }}
                          >
                            ACTIVE DIRECTORY INFO
                          </Typography>
                        </div>
                        <div>
                          <Accordion
                            expanded={aDAccordion}
                            onChange={() => {
                              setADAccordion(!aDAccordion);
                            }}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1bh-content"
                              id="panel1bh-header"
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  width: "100%",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    width: "100%",
                                  }}
                                >
                                  <div>
                                    <Typography className={classes.heading}>
                                      {""}
                                      <span style={{ color: "gray" }}>
                                        {/* ({grupo.Gestiones.length}) */}
                                      </span>
                                    </Typography>
                                  </div>
                                </div>
                              </div>
                            </AccordionSummary>
                            <AccordionDetails
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Grid container spacing={1}>
                                <Grid
                                  item
                                  xs={12}
                                  lg={6}
                                  md={6}
                                  sm={12}
                                  style={{}}
                                >
                                  <Paper className={classes.paperCard}>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignContent: "column",
                                        alignItems: "center",
                                      }}
                                    >
                                      <DnsIcon
                                        style={{
                                          color: "#85c638",
                                          marginRight: 5,
                                        }}
                                      />
                                      <Typography
                                        variant="caption"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        Usuario bloqueado:
                                      </Typography>
                                    </div>

                                    <div>
                                      {" "}
                                      {dataAD !== null &&
                                      dataAD.usuarioBloqueado !== null
                                        ? dataAD.usuarioBloqueado
                                          ? "SÍ"
                                          : "NO"
                                        : "SD"}
                                    </div>
                                  </Paper>
                                </Grid>

                                <Grid
                                  item
                                  xs={12}
                                  lg={6}
                                  md={6}
                                  sm={12}
                                  style={{}}
                                >
                                  <Paper className={classes.paperCard}>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignContent: "column",
                                        alignItems: "center",
                                      }}
                                    >
                                      <DnsIcon
                                        style={{
                                          color: "#85c638",
                                          marginRight: 5,
                                        }}
                                      />
                                      <Typography
                                        variant="caption"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        Contraseña caducada:
                                      </Typography>
                                    </div>

                                    <div>
                                      {" "}
                                      {dataAD !== null &&
                                      dataAD.contrasenaCaducada !== null
                                        ? dataAD.contrasenaCaducada
                                          ? "Sí"
                                          : "NO"
                                        : "SD"}
                                    </div>
                                  </Paper>
                                </Grid>

                                <Grid
                                  item
                                  xs={12}
                                  lg={6}
                                  md={6}
                                  sm={12}
                                  style={{}}
                                >
                                  <Paper className={classes.paperCard}>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignContent: "column",
                                        alignItems: "center",
                                      }}
                                    >
                                      <DnsIcon
                                        style={{
                                          color: "#85c638",
                                          marginRight: 5,
                                        }}
                                      />
                                      <Typography
                                        variant="caption"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        PC:
                                      </Typography>
                                    </div>

                                    <div>
                                      {" "}
                                      {dataAD !== null &&
                                      dataAD.nombrePC !== null
                                        ? dataAD.nombrePC
                                        : "SD"}
                                    </div>
                                  </Paper>
                                </Grid>

                                <Grid
                                  item
                                  xs={12}
                                  lg={6}
                                  md={6}
                                  sm={12}
                                  style={{}}
                                >
                                  <Paper className={classes.paperCard}>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignContent: "column",
                                        alignItems: "center",
                                      }}
                                    >
                                      <DnsIcon
                                        style={{
                                          color: "#85c638",
                                          marginRight: 5,
                                        }}
                                      />
                                      <Typography
                                        variant="caption"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        Modelo PC:
                                      </Typography>
                                    </div>

                                    <div>
                                      {" "}
                                      {dataAD !== null &&
                                      dataAD.modeloPC !== null
                                        ? dataAD.modeloPC
                                        : "SD"}
                                    </div>
                                  </Paper>
                                </Grid>

                                <Grid
                                  item
                                  xs={12}
                                  lg={6}
                                  md={6}
                                  sm={12}
                                  style={{}}
                                >
                                  <Paper className={classes.paperCard}>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignContent: "column",
                                        alignItems: "center",
                                      }}
                                    >
                                      <DnsIcon
                                        style={{
                                          color: "#85c638",
                                          marginRight: 5,
                                        }}
                                      />
                                      <Typography
                                        variant="caption"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        Service Tag
                                      </Typography>
                                    </div>

                                    <div>
                                      {" "}
                                      {dataAD !== null &&
                                      dataAD.serviceTag !== null
                                        ? dataAD.serviceTag
                                        : "SD"}
                                    </div>
                                  </Paper>
                                </Grid>

                                <Grid
                                  item
                                  xs={12}
                                  lg={6}
                                  md={6}
                                  sm={12}
                                  style={{}}
                                >
                                  <Paper className={classes.paperCard}>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignContent: "column",
                                        alignItems: "center",
                                      }}
                                    >
                                      <DnsIcon
                                        style={{
                                          color: "#85c638",
                                          marginRight: 5,
                                        }}
                                      />
                                      <Typography
                                        variant="caption"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        Otra PC:
                                      </Typography>
                                    </div>

                                    <div>
                                      {" "}
                                      {dataAD !== null && dataAD.otraPC !== null
                                        ? dataAD.otraPC
                                        : "SD"}
                                    </div>
                                  </Paper>
                                </Grid>

                                <Grid
                                  item
                                  xs={12}
                                  lg={6}
                                  md={6}
                                  sm={12}
                                  style={{}}
                                >
                                  <Paper className={classes.paperCard}>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignContent: "column",
                                        alignItems: "center",
                                      }}
                                    >
                                      <DnsIcon
                                        style={{
                                          color: "#85c638",
                                          marginRight: 5,
                                        }}
                                      />
                                      <Typography
                                        variant="caption"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        Tel/extensión:
                                      </Typography>
                                    </div>

                                    <div>
                                      {dataAD !== null &&
                                      dataAD.telefono !== null
                                        ? dataAD.telefono
                                        : "SD"}
                                    </div>
                                  </Paper>
                                </Grid>

                                <Grid
                                  item
                                  xs={12}
                                  lg={6}
                                  md={6}
                                  sm={12}
                                  style={{}}
                                >
                                  <Paper className={classes.paperCard}>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignContent: "column",
                                        alignItems: "center",
                                      }}
                                    >
                                      <DnsIcon
                                        style={{
                                          color: "#85c638",
                                          marginRight: 5,
                                        }}
                                      />
                                      <Typography
                                        variant="caption"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        Ubicación:
                                      </Typography>
                                    </div>

                                    <div>
                                      {" "}
                                      {dataAD !== null &&
                                      dataAD.ubicacion !== null
                                        ? dataAD.ubicacion
                                        : "SD"}
                                    </div>
                                  </Paper>
                                </Grid>

                                <Grid
                                  item
                                  xs={12}
                                  lg={6}
                                  md={6}
                                  sm={12}
                                  style={{}}
                                >
                                  <Paper className={classes.paperCard}>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignContent: "column",
                                        alignItems: "center",
                                      }}
                                    >
                                      <DnsIcon
                                        style={{
                                          color: "#85c638",
                                          marginRight: 5,
                                        }}
                                      />
                                      <Typography
                                        variant="caption"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        Gerencia:
                                      </Typography>
                                    </div>

                                    <div>
                                      {" "}
                                      {dataAD !== null &&
                                      dataAD.gerencia !== null
                                        ? dataAD.gerencia
                                        : "SD"}
                                    </div>
                                  </Paper>
                                </Grid>

                                <Grid
                                  item
                                  xs={12}
                                  lg={6}
                                  md={6}
                                  sm={12}
                                  style={{}}
                                >
                                  <Paper className={classes.paperCard}>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignContent: "column",
                                        alignItems: "center",
                                      }}
                                    >
                                      <DnsIcon
                                        style={{
                                          color: "#85c638",
                                          marginRight: 5,
                                        }}
                                      />
                                      <Typography
                                        variant="caption"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        Área:
                                      </Typography>
                                    </div>

                                    <div>
                                      {" "}
                                      {dataEmpleado !== null &&
                                      dataEmpleado.AreaEmpresa !== null
                                        ? dataEmpleado.AreaEmpresa
                                        : dataAD !== null &&
                                          dataAD.area !== null
                                        ? dataAD.area
                                        : "SD"}
                                    </div>
                                  </Paper>
                                </Grid>

                                <Grid
                                  item
                                  xs={12}
                                  lg={6}
                                  md={6}
                                  sm={12}
                                  style={{}}
                                >
                                  <Paper className={classes.paperCard}>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignContent: "column",
                                        alignItems: "center",
                                      }}
                                    >
                                      <DnsIcon
                                        style={{
                                          color: "#85c638",
                                          marginRight: 5,
                                        }}
                                      />
                                      <Typography
                                        variant="caption"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        Puesto:
                                      </Typography>
                                    </div>

                                    <div>
                                      {" "}
                                      {dataEmpleado !== null &&
                                      dataEmpleado.PuestoLaboral !== null
                                        ? dataEmpleado.PuestoLaboral
                                        : dataAD !== null &&
                                          dataAD.puesto !== null
                                        ? dataAD.puesto
                                        : "SD"}
                                    </div>
                                  </Paper>
                                </Grid>
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      </div>
                    </Paper>
                  </Grid>
                </Grid>
              )}
            </Box>
          </Paper>
        </Container>

        <Container maxWidth="xl">
          <Paper id="resultado" className={classes.paperPerfil}>
            <Typography
              variant="h4"
              style={{ paddingTop: 30, textAlign: "center" }}
            >
              Últimas gestiones solicitadas
            </Typography>
            {listaTopAvisos.map((item, index) => (
              <CardAviso
                key={item.CodigoAviso}
                CodigoAviso={item.CodigoAviso}
                Nombre={item.Titulo}
                NombreCarpeta={item.Problema.Nombre}
                Fecha={item.FechaInsertoAmigable}
                NombreEstado={((item || {}).Estado || {}).Nombre}
                Activo={item.Activo}
                Atrasado={item.Atrasado}
                SLA={item.SLA}
                TiempoTranscurridoHastaCompletada={
                  item.TiempoTranscurridoHastaCompletada
                }
                Quedan={
                  item.VencimientoTiempoEsperaEstimado != null
                    ? item.VencimientoTiempoEsperaEstimado
                    : item.CausasTiempoEsperaEstimado != null
                    ? item.CausasTiempoEsperaEstimado
                    : item.ProblemaTiempoEsperaEstimado
                }
                recargar={getTopAvisos}
                porcentajeAvance={item.PorcentajeAvance}
                getTrackingFn={() => {}}
                showTrackingButton={false}
              />
            ))}
          </Paper>
        </Container>

        <ToastContainer />

        <Modal
          // style={{ zIndex: 2990 }}
          aria-labelledby="Asignar usuarios"
          aria-describedby="Asignar usuarios"
          open={openAsignarUsuarios}
          onClose={() => {
            setOpenAsignarUsuarios(false);
          }}
        >
          <div
            style={{
              top: `${50}%`,
              left: `${50}%`,
              transform: `translate(-${50}%, -${50}%)`,
            }}
            className={classes.paperUserModal}
          >
            <PerfectScrollbar>
              <form className={classes.container} noValidate autoComplete="off">
                <Typography variant="h6" gutterBottom>
                  Selecciona un usuario
                </Typography>

                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextField
                      id="txtTextoBusquedaUsuario"
                      label="Criterio de búsqueda"
                      className={classes.textField}
                      value={txtTextoBusquedaUsuario}
                      onChange={(event) => {
                        setTxtTextoBusquedaUsuario(event.target.value);
                      }}
                      onKeyPress={(ev) => {
                        console.log("keyP: " + ev.key);
                        if (ev.key === "Enter") {
                          cargarListaUsuariosModal();
                          ev.preventDefault();
                        }
                      }}
                      margin="normal"
                      inputProps={{ maxLength: 512 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#217ecf",
                        color: "white",
                        width: "100%",
                      }}
                      className={classes.buttonAdd}
                      onClick={() => cargarListaUsuariosModal()}
                    >
                      Buscar
                    </Button>
                  </Grid>
                </Grid>

                <Grid item xs={12} lg={12} sm={12}>
                  <Grid container spacing={1}>
                    <UsuariosList
                      registros={listaUsuariosModal}
                      seleccionarUsuario={seleccionarUsuario}
                    />
                  </Grid>
                </Grid>
              </form>
            </PerfectScrollbar>
          </div>
        </Modal>

        <Modal
          // style={{ zIndex: 2990 }}
          aria-labelledby="Desbloquear usuario"
          aria-describedby="Desbloquear usuario"
          open={openDesbloquearUsuario}
          onClose={() => {
            setOpenDesbloquearUsuario(false);
          }}
        >
          <div
            style={{
              top: `${50}%`,
              left: `${50}%`,
              transform: `translate(-${50}%, -${50}%)`,
            }}
            className={classes.paperUserModal}
          >
            <PerfectScrollbar>
              <Typography variant="h6" gutterBottom>
                Desbloquear usuario AD / SAP
              </Typography>

              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    id="txtUsuario"
                    label="Usuario"
                    className={classes.textField}
                    value={txtUsuario}
                    onChange={(event) => {
                      setTxtUsuario(event.target.value);
                    }}
                    // onKeyPress={(ev) => {
                    //   console.log("keyP: " + ev.key);
                    //   if (ev.key === "Enter") {
                    //     cargarListaUsuariosModal();
                    //     ev.preventDefault();
                    //   }
                    // }}
                    margin="normal"
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Sistema</FormLabel>
                    <RadioGroup
                      aria-label="sistema"
                      name="sistemaRB"
                      value={sistema}
                      onChange={handleChangeSistema}
                    >
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label="Active Directory"
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio />}
                        label="SAP"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#217ecf",
                      color: "white",
                      width: "100%",
                    }}
                    className={classes.buttonAdd}
                    onClick={() => desbloquearUsuario()}
                  >
                    Desbloquear
                  </Button>
                </Grid>
              </Grid>
            </PerfectScrollbar>
          </div>
        </Modal>

        <Modal
          // style={{ zIndex: 2990 }}
          aria-labelledby="Ping"
          aria-describedby="Ping"
          open={openPing}
          onClose={() => {
            setOpenPing(false);
          }}
        >
          <div
            style={{
              top: `${50}%`,
              left: `${50}%`,
              transform: `translate(-${50}%, -${50}%)`,
            }}
            className={classes.paperUserModal}
          >
            <PerfectScrollbar>
              <form className={classes.container} noValidate autoComplete="off">
                <Typography variant="h6" gutterBottom>
                  Respuesta del servidor
                </Typography>

                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <p>{respuestaPing}</p>
                  </Grid>
                </Grid>
              </form>
            </PerfectScrollbar>
          </div>
        </Modal>
      </Layout>
    </React.Fragment>
  );
};
const PanelSoportes = withStyles(styles,{index:1})(
  PanelSoporte
);
export default withRouter(PanelSoportes);
