import React from "react";

function Link(props) {
  return (
    <div onClick={typeof props.onClick !== "undefined" ? props.onClick : ""}>
      <a
        href={typeof props.onClick !== "undefined" ? "#!" : props.link}
        style={styles.link}
      >
        {props.icon ? props.icon : <></>}

        <span>{props.title}</span>
      </a>
    </div>
  );
}

const styles = {
  link: {
    color: "#2196F3",
    textDecoration: "none",
    fontSize: 12,
    paddingRight: 5
  }
};

export default Link;
