import React, { useState, useEffect } from "react";

import {
  Backdrop,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  TextField,
  Paper,
  Tooltip,
  Modal,
  Fade,
  AppBar,
  Toolbar,
  Typography,
} from "@material-ui/core";

import { green } from "@material-ui/core/colors";

import API from "../../utils/api";
import { isSignedIn, signOut } from "../../utils/auth";
import {
  validaPermisoPaginaActual,
  verificaPermisoPaginaActual,
} from "../../utils/helpers";
import { ToastContainer, toast } from "react-toastify";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import "devextreme/dist/css/dx.material.teal.light.compact.css";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const width_proportion = "100%";
const titleColor = "#2B3C4D";

const useStyles = makeStyles((theme) => ({
  table: {
    width: "100%",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 210,
  },
  textFieldDireccion: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 230,
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  greenAvatar: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  paperCard: {
    padding: 20,
    width: "100%",
  },
  formControlSelects: {
    // margin: theme.spacing(1),
    minWidth: 360,

    marginBottom: 10,
    marginTop: 10,
    // width: "100%",
    marginLeft: 30,
  },
  formControlSelect: {
    // margin: theme.spacing(1),
    width: width_proportion,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  rootTabla: {
    width: "100%",
    marginTop: theme.spacing.unit * 1,
    overflowX: "auto",
    paddingTop: theme.spacing.unit * 2,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperModal: {
    position: "absolute",
    width: "70%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const PuestoLaboral = (props) => {
  const classes = useStyles();
  const [token, setToken] = useState(props.token);

  let applyFilterTypes = [
    {
      key: "auto",
      name: "Immediately",
    },
    {
      key: "onClick",
      name: "On Button Click",
    },
  ];

  const [permisoPlazaLaboral, setPermisoPlazaLaboral] = useState(false);
  const [userName, setUserName] = useState("");
  const [detalle, setDetalle] = useState(null);
  const [open, setOpen] = useState(false);
  const [cargandoMaestros, setCargandoMaestros] = useState(false);

  const [buscando, setBackDrop] = useState(false);

  const handleCerrarBackDrop = () => {
    setBackDrop(false);
  };
  const handleAbrirBackDrop = () => {
    setBackDrop(true);
  };

  useEffect(() => {
    const cargar = async () => {
      handleAbrirBackDrop();

      await Detalle();
      // await NuevaLista();
      // await Notificaciones();

      handleCerrarBackDrop();
    };

    cargar();
  }, [cargandoMaestros]);

  const Detalle = async () => {
    // const session = await isSignedIn();
    // if (!session.response) {
    //   window.location.href = "/login";
    //   return;
    // } else {
    //   setUserName(session["userName"]);
    // }

    try {
      handleAbrirBackDrop();

      let uri = "api/EmpleadoSolicitudMovimiento/Detalle/" + token;

      const request = await API.fetchGetRequest(uri);

      if (false) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setDetalle(request.data.ficha);
        // setResumenMovimientos(request.data.ficha2);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  return (
    <React.Fragment>
      <Backdrop className={classes.backdrop} open={buscando}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer />
      {detalle !== null && (
        <React.Fragment>
          <AppBar
            position="sticky"
            className={classes.appBar}
            style={{ backgroundColor: titleColor }}
          >
            <Toolbar>
              <Typography
                variant="h6"
                noWrap
                style={{ display: "flex", flexGrow: 1 }}
              >
                {`Aviso de Movimiento de Personal -  ` +
                  detalle.TipoMovimientoPersonal}
              </Typography>
            </Toolbar>
          </AppBar>

          <Grid container spacing={3} style={{ marginTop: "auto" }}>
            <Grid
              item
              xs={12}
              className={classes.datoContent}
              style={{ backgroundColor: "gainsboro" }}
            >
              <Typography variant="h6" className={classes.titulo}>
                {`Colaborador -  ` + detalle.NombreCompleto}
              </Typography>
            </Grid>
            <Grid item container xs={6} className={classes.datoContent}>
              <Grid item xs={4}>
                <div className={classes.labelContainer}>
                  <Typography className={classes.datoTxt}>
                    Código de Puesto:{" "}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={8}>
                <Typography className={classes.label}>
                  {detalle ? detalle.CodigoPuestoLaboral : "-"}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container xs={6} className={classes.datoContent}>
              <Grid item xs={4}>
                <div className={classes.labelContainer}>
                  {/* <AssignmentInd className={classes.icon} /> */}
                  <Typography className={classes.datoTxt}>
                    Nombre del Puesto:
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={8}>
                <Typography className={classes.label}>
                  {detalle ? detalle.PuestoLaboral : "-"}
                </Typography>
              </Grid>
            </Grid>

            <Grid item container xs={6} className={classes.datoContent}>
              <Grid item xs={4}>
                <div className={classes.labelContainer}>
                  {/* <GroupWork className={classes.icon} /> */}
                  <Typography className={classes.datoTxt}>
                    Departamento:{" "}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={8}>
                <Typography className={classes.label}>
                  {detalle ? detalle.Departamento : "-"}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container xs={6} className={classes.datoContent}>
              <Grid item xs={4}>
                <div className={classes.labelContainer}>
                  {/* <BarChart className={classes.icon} /> */}
                  <Typography className={classes.datoTxt}>
                    Temporada:
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={8}>
                <Typography className={classes.label}>
                  {detalle ? detalle.Temporada : "-"}
                </Typography>
              </Grid>
            </Grid>

            <Grid item container xs={6} className={classes.datoContent}>
              <Grid item xs={4}>
                <div className={classes.labelContainer}>
                  {/* <Spellcheck className={classes.icon} /> */}
                  <Typography className={classes.datoTxt}>Turno: </Typography>
                </div>
              </Grid>
              <Grid item xs={8}>
                <Typography className={classes.label}>
                  {detalle ? detalle.TipoTurno : "-"}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container xs={6} className={classes.datoContent}>
              <Grid item xs={4}>
                <div className={classes.labelContainer}>
                  {/* <MergeType className={classes.icon} /> */}
                  <Typography className={classes.datoTxt}>Fecha:</Typography>
                </div>
              </Grid>
              <Grid item xs={8}>
                <Typography className={classes.label}>
                  {detalle
                    ? new Date(detalle.FechaEjecucion).toLocaleDateString(
                        "es-GT"
                      )
                    : "-"}
                </Typography>
              </Grid>
            </Grid>
            {detalle.SalarioSolicitado > 0 && (
              <Grid item container xs={6} className={classes.datoContent}>
                <Grid item xs={4}>
                  <div className={classes.labelContainer}>
                    {/* <EventAvailable className={classes.icon} /> */}
                    <Typography className={classes.datoTxt}>
                      Salario:{" "}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={8}>
                  <Typography className={classes.label}>
                    {detalle ? detalle.SalarioSolicitado : "-"}
                  </Typography>
                </Grid>
              </Grid>
            )}
            {detalle.Aceptado === true && (
              <React.Fragment>
                <Grid item container xs={6} className={classes.datoContent}>
                  <Grid item xs={4}>
                    <div className={classes.labelContainer}>
                      {/* <EventAvailable className={classes.icon} /> */}
                      <Typography className={classes.datoTxt}>
                        Fecha Aceptado por Colaborador:{" "}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography className={classes.label}>
                      {detalle
                        ? new Date(detalle.FechaAceptado).toLocaleString(
                            "es-GT"
                          )
                        : "-"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container xs={6} className={classes.datoContent}>
                  <Grid item xs={4}>
                    <div className={classes.labelContainer}>
                      {/* <EventAvailable className={classes.icon} /> */}
                      <Typography className={classes.datoTxt}>
                        Navegador:{" "}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography className={classes.label}>
                      {detalle ? detalle.DetalleAceptado : "-"}
                    </Typography>
                  </Grid>
                </Grid>
              </React.Fragment>
            )}
          </Grid>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default PuestoLaboral;
