import { Button, Checkbox, FormControl, FormControlLabel, Grid, Icon, IconButton, InputLabel, MenuItem, Select, TextField, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import API from '../../utils/api';

function ProblemaApiConfig({ CodigoProblema, Tareas, CodigoPlantilla, UpdateApi, ProblemaApi }) {
    const classes = styles();

    const [uri, setUri] = useState('');
    const [metodo, setMetodo] = useState('');
    const [tareaTrigger, setTareaTrigger] = useState('');
    const [nombreHeaderAuth, setNombreHeaderAuth] = useState('');
    const [authToken, setAuthToken] = useState('');
    const [subItems, setSubItems] = useState([]);
    const [listaCampos, setListaCampos] = useState([]);
    //const [codigoPlantilla, setCodigoPlantilla] = useState(0);

    useEffect(() => {
        const data = {
            CodigoProblema: CodigoProblema,
            Uri: uri,
            Metodo: metodo,
            TareaTrigger: tareaTrigger,
            NombreHeaderAuth: nombreHeaderAuth,
            AuthToken: authToken,
            Activo: true,
            AITApiParametro: subItems,
            ParametrosApi: subItems,
        };
        UpdateApi(data);
    }, [uri, metodo, tareaTrigger, nombreHeaderAuth, authToken, subItems]);

    useEffect(() => {
        console.log("ProblemaApi", ProblemaApi);
        if (ProblemaApi) {
            let tareaTriggerString = ProblemaApi.TareaTrigger && ProblemaApi.TareaTrigger != null ? ProblemaApi.TareaTrigger.toString() : ProblemaApi.TareaTrigger;
            setUri(ProblemaApi.Uri);
            setMetodo(ProblemaApi.Metodo);
            setTareaTrigger(tareaTriggerString);
            setNombreHeaderAuth(ProblemaApi.NombreHeaderAuth);
            setAuthToken(ProblemaApi.AuthToken);
            setSubItems(ProblemaApi.AITApiParametro);

            if (tareaTriggerString !== "0") {
                setTareaTriggerCampos(tareaTriggerString);
            } else if (tareaTriggerString === "0") {
                cargarListaCampos(CodigoPlantilla);
            }
        }
    }, []);


    const handleAddSubItem = () => {
        //Actualiza la lista de campos
        //setTareaTriggerCampos(tareaTrigger);
        setSubItems([...subItems, { Valor: '', CodigoCampo: '', NombreParametro: '', EnBody: false, Activo: true, Eliminado: false }]);
    };

    const handleSubItemChange = (index, field, value) => {
        const newSubItems = [...subItems];
        newSubItems[index][field] = value;
        setSubItems(newSubItems);
    };

    const handleRemoveSubItem = index => {
        // const newSubItems = [...subItems];
        // newSubItems.splice(index, 1);
        //get the item from the list and update Eliminado to true
        console.log("subItems", subItems);
        console.log("index", index);
        const newSubItems = subItems.map((item, i) => {
            if (i === index) {
                return { ...item, Eliminado: true, Activo: false };
            }
            return item;
        });

        setSubItems(newSubItems);
    };

    const cargarListaCampos = async (codigoPlantilla) => {
        const request = await API.fetchGetRequest(
            `api/campo/listarCampos/${codigoPlantilla}/${true}`
        );
        console.log("cargarListaCampos", request.data.data);
        setListaCampos(request.data.data);
    }

    const setTareaTriggerCampos = async (codigo) => {
        setTareaTrigger(codigo);
        console.log("codigo", codigo);
        if (codigo === "999")
            return;
        if (codigo !== "0") {
            //get the object from the list and use CodigoPlantilla as the parameter
            const tarea = Tareas.find(tarea => tarea.CodigoTareaPlantilla === codigo);
            if (tarea && tarea.CodigoPlantilla !== null) {
                if (tarea.CodigoPlantilla !== null) {
                    cargarListaCampos(tarea.CodigoPlantilla);
                } else {
                    setListaCampos([]);
                }
            } else {
                setListaCampos([]);
            }
        }
        else if (codigo === "0") {
            cargarListaCampos(CodigoPlantilla);
        }
    }


    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12} lg={3}>
                    <TextField label="CodigoProblema" value={CodigoProblema} disabled fullWidth />
                </Grid>
                <Grid item xs={12} lg={9}>
                    <TextField label="Uri" value={uri} onChange={e => setUri(e.target.value)} fullWidth />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <FormControl fullWidth>
                        <InputLabel>Método</InputLabel>
                        <Select value={metodo} onChange={e => setMetodo(e.target.value)}>
                            <MenuItem value="Get">Get</MenuItem>
                            <MenuItem value="Post">Post</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <FormControl fullWidth>
                        <InputLabel>Tarea Trigger</InputLabel>
                        <Select value={tareaTrigger} onChange={e => setTareaTriggerCampos(e.target.value)}>
                            <MenuItem value="0">Al crear gestión</MenuItem>
                            {Tareas.map(tarea => (
                                <MenuItem key={tarea.CodigoTareaPlantilla} value={tarea.CodigoTareaPlantilla}>
                                    {tarea.Descripcion}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <TextField label="Header de autenticación" value={nombreHeaderAuth} onChange={e => setNombreHeaderAuth(e.target.value)} fullWidth />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <TextField label="Token" value={authToken} onChange={e => setAuthToken(e.target.value)} fullWidth />
                </Grid>
                <br />
                {subItems.length > 0 && (
                    <div className={classes.textDivider}>Parámetros</div>
                )}
                {subItems.filter(obj => obj.Activo === true).map((subItem, index) => (
                    <Grid item xs={12} lg={12} key={index}>
                        <div key={index}>

                            <Grid container spacing={3}>
                                <Grid item xs={12} lg={2}>
                                    <TextField
                                        label="Valor"
                                        value={subItem.Valor}
                                        fullWidth
                                        onChange={e => handleSubItemChange(index, 'Valor', e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <FormControl fullWidth>
                                        <InputLabel>Campo fuente</InputLabel>
                                        <Select
                                            value={subItem.CodigoCampo}
                                            onChange={e => handleSubItemChange(index, 'CodigoCampo', e.target.value)}
                                        >
                                            {listaCampos.map(campo => (
                                                <MenuItem key={campo.CodigoCampo} value={campo.CodigoCampo}>
                                                    {campo.Nombre}
                                                </MenuItem>
                                            ))}
                                            {listaCampos.length === 0 && (
                                                <MenuItem value="999">No hay formulario asignado</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <TextField
                                        label="Nombre del parámetro"
                                        value={subItem.NombreParametro}
                                        fullWidth
                                        onChange={e => handleSubItemChange(index, 'NombreParametro', e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={4}>

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={subItem.EnBody}
                                                onChange={e => handleSubItemChange(index, 'EnBody', e.target.checked)}
                                                name="EnBody"
                                                color="primary"
                                            />
                                        }
                                        label="En body"
                                    />
                                    <IconButton onClick={() => handleRemoveSubItem(index)}>
                                        <Icon>delete</Icon>
                                    </IconButton>
                                    {index === subItems.length - 1 && (
                                        <IconButton onClick={handleAddSubItem}>
                                            <Icon>add</Icon>
                                        </IconButton>
                                    )}
                                </Grid>
                            </Grid>

                        </div>
                    </Grid>
                ))}
                {subItems.length === 0 && (
                    <Button onClick={handleAddSubItem}>Añadir parámetros</Button>
                )}

            </Grid>
        </div >
    );
}

const styles = makeStyles((theme) => ({
    textDivider: {
        display: 'flex',
        'align-items': 'center', // align text and lines vertically
        width: '100%',
        '&::before, &::after': {
            content: '""',
            height: '1px',
            'background-color': 'silver',
            'flex-grow': 1, // both lines will expand to occupy the available space
        },
    },
}));


export default ProblemaApiConfig;