import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Backdrop,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  TextField,
  Paper,
  Tooltip,
  Modal,
  Fade,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Box,
  TabScrollButton,
  Tabs,
  Tab,
} from "@material-ui/core";

import BackdropModal from "@material-ui/core/Backdrop";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import {
  Edit,
  Delete,
  ViewList,
  Add,
  PlayCircleFilled,
  Block,
  Traffic,
  Lens,
} from "@material-ui/icons";

import { green } from "@material-ui/core/colors";
import { NavLink } from "react-router-dom";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import DataGrid, {
  Column,
  Pager,
  Paging,
  Sorting,
  ColumnFixing,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Grouping,
  GroupPanel,
  Export,
  Selection,
  Editing,
  Scrolling,
  LoadPanel,
} from "devextreme-react/data-grid";

import API from "../../utils/api";
import { isSignedIn, signOut } from "../../utils/auth";
import {
  validaPermisoPaginaActual,
  verificaPermisoPaginaActual,
} from "../../utils/helpers";
import { ToastContainer, toast } from "react-toastify";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import "devextreme/dist/css/dx.material.teal.light.compact.css";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const width_proportion = "100%";
const titleColor = "#2B3C4D";

const a11yProps = (index) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={"span"} variant={"body2"}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const MyTabScrollButton = withStyles((theme) => ({
  root: {
    width: 28,
    overflow: "hidden",
    transition: "width 0.5s",
    "&.Mui-disabled": {
      width: 0,
    },
  },
}))(TabScrollButton);

const useStyles = makeStyles((theme) => ({
  table: {
    width: "100%",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 210,
  },
  textFieldDireccion: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 230,
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  greenAvatar: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  paperCard: {
    padding: 20,
    width: "100%",
  },
  formControlSelects: {
    // margin: theme.spacing(1),
    minWidth: 480,

    marginBottom: 10,
    marginTop: 10,
    //width: "100%",
    marginLeft: 30,
  },
  formControlSelect: {
    // margin: theme.spacing(1),
    width: width_proportion,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  rootTabla: {
    width: "100%",
    marginTop: theme.spacing.unit * 1,
    overflowX: "auto",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperModal: {
    position: "absolute",
    //width: "70%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const PuestoLaboral = () => {
  const classes = useStyles();
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors: fieldsErrors },
    register,
  } = useForm();

  let applyFilterTypes = [
    {
      key: "auto",
      name: "Immediately",
    },
    {
      key: "onClick",
      name: "On Button Click",
    },
  ];

  const [userName, setUserName] = useState("");
  const [open, setOpen] = useState(false);
  const [cargandoMaestros, setCargandoMaestros] = useState(false);
  const [puestoLaboral, setPuestoLaboral] = useState([]);
  const [maestroNivelPuestoLaboral, setMaestroNivelPuestoLaboral] = useState(
    []
  );
  const [codigoPuestoLaboral, setCodigoPuestoLaboral] = useState(null);
  const [codigoNivelPuestoLaboral, setCodigoNivelPuestoLaboral] = useState(0);
  const [puestoLaboralNombre, setPuestoLaboralNombre] = useState("");

  const [numeroVersion, setNumeroVersion] = useState("");
  const [fechaCreacion, setFechaCreacion] = useState(new Date());

  const [maestroTipoCambioPuestoLaboral, setMaestroTipoCambioPuestoLaboral] =
    useState([]);
  const [
    valueAutoCompleteTipoCambioPuestoLaboral,
    setValueAutoCompleteTipoCambioPuestoLaboral,
  ] = useState(null);
  const [codigoTipoCambioPuestoLaboral, setCodigoTipoCambioPuestoLaboral] =
    useState(0);

  const [maestroEstadoSolicitud, setMaestroEstadoSolicitud] = useState([]);
  const [
    valueAutoCompleteEstadoSolicitud,
    setValueAutoCompleteEstadoSolicitud,
  ] = useState(null);
  const [codigoEstadoSolicitud, setEstadoSolicitud] = useState(0);

  const [maestroPuestoLaboralSolicitud, setMaestroPuestoLaboralSolicitud] =
    useState([]);
  const [
    maestroPuestoLaboralSolicitudEjecutado,
    setMaestroPuestoLaboralSolicitudEjecutado,
  ] = useState([]);

  const [codigoPuestoLaboralSolicitud, setCodigoPuestoLaboralSolicitud] =
    useState(0);

  const [
    valueAutoCompleteNivelPuestoLaboral,
    setValueAutoCompleteNivelPuestoLaboral,
  ] = useState(null);

  const [nombreCorto, setNombreCorto] = useState("");
  const [grado, setGrado] = useState(null);

  const [codigoSubNivelPuestoLaboral, setCodigoSubNivelPuestoLaboral] =
    useState(null);
  const [maestroSubNivelPuestoLaboral, setMaestroSubNivelPuestoLaboral] =
    useState([]);
  const [
    valueAutoCompleteSubNivelPuestoLaboral,
    setValueAutoCompleteSubNivelPuestoLaboral,
  ] = useState(null);

  const [
    codigoClasificacionPuestoLaboral,
    setCodigoClasificacionPuestoLaboral,
  ] = useState(null);
  const [
    maestroClasificacionPuestoLaboral,
    setMaestroClasificacionPuestoLaboral,
  ] = useState([]);
  const [
    valueAutoCompleteClasificacionPuestoLaboral,
    setValueClasificacionSubNivelPuestoLaboral,
  ] = useState(null);

  const [positionDisableSorting, setPositionDisableSorting] = useState(false);
  const [showFilterRow, setShowFilterRow] = useState(true);
  const [showHeaderFilter, setShowHeaderFilter] = useState(true);
  const [currentFilter, setCurrentFilter] = useState(applyFilterTypes[0].key);
  const [autoExpandAll, setAutoExpandAll] = useState(true);

  const [buscando, setBackDrop] = useState(false);

  const [dialogoAbierto, abrirDialogo] = useState(false);
  const [dialogoAbiertoEditar, abrirDialogoEditar] = useState(false);
  const [dialogoAbiertoEliminarSolicitud, abrirDialogoEliminarSolicitud] =
    useState(false);
  const [dialogoAbiertoIniciarSolicitud, setDialogoAbiertoIniciarSolicitud] =
    useState(false);
  const [editarCompleto, setEditarCompleto] = useState(false);

  const [tab, setTab] = useState(0);

  const handleCerrarBackDrop = () => {
    setBackDrop(false);
  };
  const handleAbrirBackDrop = () => {
    setBackDrop(true);
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    const cargar = async () => {
      // CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
      const llaveAgrupacion = "Menu.Principal.Web";
      const llavePagina = "Menu.Principal.Web.PuestoLaboral";
      await validaPermisoPaginaActual(llaveAgrupacion, llavePagina);

      const llave = "GESPILU";
      const llaveEditarCompleto = "GESPILU.PuestoLaboral.EditarCompleto";
      let permisoEditarCompleto = await verificaPermisoPaginaActual(
        llave,
        llaveEditarCompleto
      );
      setEditarCompleto(permisoEditarCompleto);
      // FIN DE CONSULTA DE PERMISOS

      handleAbrirBackDrop();

      await cargarMaestroPuestoLaboral();
      await cargarMaestroNivelPuestoLaboral();
      await cargarMaestroPuestoLaboralSolicitud();
      await cargarMaestroClasificacionPuestoLaboral();
      handleCerrarBackDrop();
    };

    cargar();
  }, [cargandoMaestros]);

  useEffect(() => {
    const cargar = async () => {
      if (codigoNivelPuestoLaboral !== null && codigoNivelPuestoLaboral > 0) {
        handleAbrirBackDrop();

        await cargarMaestroSubNivelPuestoLaboral();

        handleCerrarBackDrop();
      }
    };

    cargar();
  }, [codigoNivelPuestoLaboral]);

  const cargarMaestroPuestoLaboral = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/PuestoLaboral/";

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setPuestoLaboral(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cargarMaestroPuestoLaboralSolicitud = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/PuestoLaboralSolicitud/";

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setMaestroPuestoLaboralSolicitud(request.data.ficha);
        setMaestroPuestoLaboralSolicitudEjecutado(request.data.ficha2);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cargarMaestroNivelPuestoLaboral = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/NivelPuestoLaboral/";

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setMaestroNivelPuestoLaboral(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cargarMaestroSubNivelPuestoLaboral = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/SubNivelPuestoLaboral/" + codigoNivelPuestoLaboral;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setMaestroSubNivelPuestoLaboral(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cargarMaestroClasificacionPuestoLaboral = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/ClasificacionPuestoLaboral/";

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setMaestroClasificacionPuestoLaboral(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const guardarDato = async () => {
    const data = {
      CodigoPuestoLaboral: codigoPuestoLaboral,
      CodigoNivelPuestoLaboral: codigoNivelPuestoLaboral,
      PuestoLaboral: puestoLaboralNombre,
      // NumeroVersion: numeroVersion,
      FechaCreacion: fechaCreacion,
      CodigoSubNivelPuestoLaboral: codigoSubNivelPuestoLaboral,
      CodigoClasificacionPuestoLaboral: codigoClasificacionPuestoLaboral,
      NombreCorto: nombreCorto,
      Grado: grado,
      CodigoEstadoSolicitud: 1,
      CodigoTipoCambioPuestoLaboral: codigoTipoCambioPuestoLaboral,
      Activo: true,
      Eliminado: false,
      UsuarioInserto: userName,
      FechaInserto: "",
      UsuarioModifico: "",
      FechaModifico: "",
    };

    console.log(data);
    // dataFicha.usuarioInserto = "prueba";
    try {
      handleCerrarDialogoIniciarSolicitud();
      handleAbrirBackDrop();
      let vIsSignedIn = await isSignedIn();
      if (vIsSignedIn.response) {
        let request;

        request = await API.post("api/PuestoLaboralSolicitud/", data);
        if (request.status !== 200) {
          toast.error(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        } else {
          if (request.data.response) {
            toast.success("Se guardó exitosamente");

            //await cargarMaestroPuestoLaboral();
            await cargarMaestroPuestoLaboralSolicitud();

            setPuestoLaboralNombre("");
            setNumeroVersion("");
            setFechaCreacion(new Date());
            setCodigoPuestoLaboral(null);
            setCodigoNivelPuestoLaboral(0);

            setValueAutoCompleteNivelPuestoLaboral(null);

            setCodigoSubNivelPuestoLaboral(null);
            setCodigoClasificacionPuestoLaboral(null);
            setGrado(null);
            setNombreCorto("");

            setValueAutoCompleteSubNivelPuestoLaboral(null);
            setValueClasificacionSubNivelPuestoLaboral(null);
          } else {
            toast.error("Error.- " + request.data.mensaje);
          }
        }
      }
    } catch (e) {
      console.log("EXCEPTION: " + e);
      toast.error(
        "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
      );
    } finally {
      handleCloseModal();
      handleCerrarBackDrop();
    }
  };

  const cuandoHayError = async (data) => {
    toast.info(
      "Existen algunos campos con error, por favor valida el formulario",
      {
        position: "top-right",
        autoClose: 3000, // Esta en milisegundos 3 segundos
      }
    );
  };

  const handleDateChangeFechaCreacion = (date) => {
    setFechaCreacion(date);
  };

  const onExporting = async (e) => {};

  const handleAbrirDialogoEliminar = (id) => {
    abrirDialogo(true);

    setCodigoPuestoLaboral(id);
  };

  const handleAbrirDialogoEliminarSolicitud = (id) => {
    abrirDialogoEliminarSolicitud(true);

    setCodigoPuestoLaboralSolicitud(id);
  };

  const handleCerrarDialogo = () => {
    limpiarCampos();
    abrirDialogo(false);
  };

  const handleAbrirDialogoEditar = (id) => {
    abrirDialogoEditar(true);

    setCodigoPuestoLaboral(id);
  };

  const handleCerrarDialogoEditar = () => {
    abrirDialogoEditar(false);
  };

  const handleAbrirDialogoIniciarSolicitud = (id) => {
    setDialogoAbiertoIniciarSolicitud(true);

    setCodigoPuestoLaboralSolicitud(id);
  };

  const handleCerrarDialogoIniciarSolicitud = () => {
    setDialogoAbiertoIniciarSolicitud(false);
  };

  const quitarProyecto = async (pId) => {
    const proyectosExcluidos = puestoLaboral.filter(
      (elemento) => elemento.CodigoPuestoLaboral !== pId
    );

    console.log("Quitar" + proyectosExcluidos);

    try {
      handleAbrirBackDrop();
      let vIsSignedIn = await isSignedIn();
      if (vIsSignedIn.response) {
        let uri = "api/PuestoLaboral/Quitar/" + pId;

        const request = await API.fetchGetRequest(uri);

        if (request.statusCode !== 200) {
          toast.error(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        } else {
          if (request.data.response) {
            toast.success("Se quitó el registro con éxito");

            await cargarMaestroPuestoLaboral();
            // await cargarMaestroPuestoLaboralSolicitud();
            setPuestoLaboralNombre("");
            setNumeroVersion("");
            setFechaCreacion(new Date());
            setCodigoPuestoLaboral(null);
            setCodigoNivelPuestoLaboral(0);

            setValueAutoCompleteNivelPuestoLaboral(null);

            setCodigoSubNivelPuestoLaboral(null);
            setCodigoClasificacionPuestoLaboral(null);
            setGrado(null);
            setNombreCorto("");

            setValueAutoCompleteSubNivelPuestoLaboral(null);
            setValueClasificacionSubNivelPuestoLaboral(null);
          } else {
            toast.error("Error.- " + request.data.mensaje);
          }
        }
      }
    } catch (error) {
      console.log("Error en desactivar el puesto laboral" + error);
    } finally {
      handleCerrarBackDrop();
      handleCerrarDialogo();
    }
  };

  const editarProyecto = async (pId) => {
    handleCerrarDialogoEditar();
    handleAbrirBackDrop();

    const proyectosExcluidos = puestoLaboral.filter(
      (elemento) => elemento.CodigoPuestoLaboral === pId
    );

    console.log("Editar el proyecto" + proyectosExcluidos);

    try {
      let uri = "api/PuestoLaboralSolicitud/DisponibilidadCambio/" + pId;

      const request = await API.fetchGetRequest(uri);

      if (request.statusCode !== 200) {
        toast.error(
          "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
        );
      } else {
        if (request.data.response) {
          handleOpenModal();
          setCodigoTipoCambioPuestoLaboral(2); //edición
          setCodigoPuestoLaboral(pId);
          setPuestoLaboralNombre(proyectosExcluidos[0].PuestoLaboral);
          setNumeroVersion(proyectosExcluidos[0].NumeroVersion);
          setFechaCreacion(proyectosExcluidos[0].FechaCreacion);
          setCodigoNivelPuestoLaboral(
            proyectosExcluidos[0].CodigoNivelPuestoLaboral
          );

          const itemNivelPuestoLaboral = maestroNivelPuestoLaboral.filter(
            (elemento) =>
              elemento.CodigoNivelPuestoLaboral ===
              proyectosExcluidos[0].CodigoNivelPuestoLaboral
          );
          setValueAutoCompleteNivelPuestoLaboral(itemNivelPuestoLaboral[0]);

          setCodigoSubNivelPuestoLaboral(
            proyectosExcluidos[0].CodigoSubNivelPuestoLaboral
          );
          const itemSubNivelPuestoLaboral = maestroSubNivelPuestoLaboral.filter(
            (elemento) =>
              elemento.CodigoSubNivelPuestoLaboral ===
              proyectosExcluidos[0].CodigoSubNivelPuestoLaboral
          );
          setValueAutoCompleteNivelPuestoLaboral(itemSubNivelPuestoLaboral[0]);
          setCodigoClasificacionPuestoLaboral(
            proyectosExcluidos[0].CodigoClasificacionPuestoLaboral
          );
          const itemClasificacionPuestoLaboral =
            maestroClasificacionPuestoLaboral.filter(
              (elemento) =>
                elemento.CodigoClasificacionPuestoLaboral ===
                proyectosExcluidos[0].CodigoClasificacionPuestoLaboral
            );
          setValueClasificacionSubNivelPuestoLaboral(
            itemClasificacionPuestoLaboral[0]
          );
          setGrado(proyectosExcluidos[0].Grado);
          setNombreCorto(proyectosExcluidos[0].NombreCorto);
        } else {
          handleCloseModal();
          toast.error("Error.- " + request.data.mensaje);
        }
      }
    } catch (error) {
      console.log("Error en  edición de proyectos" + error);
    }

    handleCerrarBackDrop();

    //alert("Quitar recurso del proyecto" + codigoRecurso);
  };

  const iniciarSolicitud = async (pId) => {
    const data = {
      CodigoPuestoLaboralSolicitud: pId,
      CodigoPuestoLaboral: 0,
      CodigoNivelPuestoLaboral: 0,
      PuestoLaboral: "",
      // NumeroVersion: numeroVersion,
      FechaCreacion: new Date(),
      CodigoSubNivelPuestoLaboral: 0,
      CodigoClasificacionPuestoLaboral: 0,
      NombreCorto: "",
      Grado: 0,
      CodigoEstadoSolicitud: 0,
      CodigoTipoCambioPuestoLaboral: 0,
      Activo: true,
      Eliminado: false,
      UsuarioInserto: userName,
      FechaInserto: "",
      UsuarioModifico: "",
      FechaModifico: "",
    };

    console.log(data);
    // dataFicha.usuarioInserto = "prueba";
    try {
      handleAbrirBackDrop();
      let vIsSignedIn = await isSignedIn();
      if (vIsSignedIn.response) {
        let request;

        request = await API.post("api/PuestoLaboralSolicitud/Iniciar", data);
        if (request.status !== 200) {
          toast.error(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        } else {
          if (request.data.response) {
            toast.success("Se guardó exitosamente");

            //await cargarMaestroPuestoLaboral();
            await cargarMaestroPuestoLaboralSolicitud();
          } else {
            toast.error("Error.- " + request.data.mensaje);
          }
        }
      }
    } catch (e) {
      console.log("EXCEPTION: " + e);
      toast.error(
        "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
      );
    } finally {
      handleCloseModal();
      handleCerrarBackDrop();
    }
  };

  const cellSemaforo = (rowInfo) => {
    let color = "#8B0000";

    if (rowInfo.data.Semaforo >= 3) {
      color = "#8B0000";
    } else if (rowInfo.data.Semaforo === 2) {
      color = "#FF8C00";
    } else {
      color = "#228B22";
    }
    return (
      <>
        <Lens style={{ color: color }} />
      </>
    );
  };

  const cellOpciones = (rowInfo) => {
    return (
      <>
        <Tooltip
          title="Descriptor de Puestos Laborales"
          style={{ marginRight: 5, cursor: "pointer" }}
        >
          <NavLink
            onClick={(e) => {
              if (false) {
                toast.warning(
                  "No tienes permisos para gestionar los anexos el proyecto",
                  {
                    position: "top-right",
                    pauseOnHover: false,
                    autoClose: 3000,
                  }
                );
                e.preventDefault();
              }
              //     props.history.push(`/proyecto-editar/${rowInfo.data.id}`);
            }}
            to={`/puesto-laboral-descriptor/${rowInfo.data.CodigoPuestoLaboral}`}
          >
            <ViewList className={classes.sizeIcons} />
          </NavLink>
        </Tooltip>
        {editarCompleto && (
          <React.Fragment>
            <Tooltip
              title="Editar"
              style={{ marginRight: 10, cursor: "pointer" }}
            >
              <Edit
                style={{ color: "#337ab7", cursor: "pointer" }}
                onClick={() =>
                  handleAbrirDialogoEditar(rowInfo.data.CodigoPuestoLaboral)
                }
              />
            </Tooltip>
            <Tooltip
              title="Desactivar"
              style={{ marginRight: 10, cursor: "pointer" }}
            >
              <Block
                style={{ color: "#337ab7", cursor: "pointer" }}
                onClick={() =>
                  handleAbrirDialogoEliminar(rowInfo.data.CodigoPuestoLaboral)
                }
              />
            </Tooltip>
          </React.Fragment>
        )}
      </>
    );
  };

  const cellOpcionesSolicitud = (rowInfo) => {
    return (
      <>
        <Tooltip
          title="Iniciar solicitud"
          style={{ marginRight: 10, cursor: "pointer" }}
        >
          <PlayCircleFilled
            style={{ color: "#337ab7", cursor: "pointer" }}
            onClick={() =>
              handleAbrirDialogoIniciarSolicitud(
                rowInfo.data.CodigoPuestoLaboralSolicitud
              )
            }
          />
        </Tooltip>
        {/* <Tooltip
          title="Eliminar"
          style={{ marginRight: 10, cursor: "pointer" }}
        >
          <Delete
            style={{ color: "#337ab7", cursor: "pointer" }}
            onClick={() =>
              handleAbrirDialogoEliminar(
                rowInfo.data.CodigoPuestoLaboralSolicitud
              )
            }
          />
        </Tooltip> */}
      </>
    );
  };

  const handleOnChangeNivelPuestoLaboral = async (event, newValue) => {
    handleAbrirBackDrop();
    if (newValue !== null) {
      setCodigoNivelPuestoLaboral(newValue.CodigoNivelPuestoLaboral);
      setValueAutoCompleteNivelPuestoLaboral(newValue);
    }
    handleCerrarBackDrop();
  };

  const handleOnChangeSubNivelPuestoLaboral = async (event, newValue) => {
    handleAbrirBackDrop();
    if (newValue !== null) {
      setCodigoSubNivelPuestoLaboral(newValue.CodigoSubNivelPuestoLaboral);
      setValueAutoCompleteSubNivelPuestoLaboral(newValue);
    }
    handleCerrarBackDrop();
  };

  const handleOnChangeClasificacionPuestoLaboral = async (event, newValue) => {
    handleAbrirBackDrop();
    if (newValue !== null) {
      setCodigoClasificacionPuestoLaboral(
        newValue.CodigoClasificacionPuestoLaboral
      );
      setValueClasificacionSubNivelPuestoLaboral(newValue);
    }
    handleCerrarBackDrop();
  };

  const limpiarCampos = () => {
    setPuestoLaboralNombre("");
    setNumeroVersion("");
    setFechaCreacion(new Date());
    setCodigoPuestoLaboral(null);
    setCodigoNivelPuestoLaboral(0);

    setValueAutoCompleteNivelPuestoLaboral(null);

    setCodigoSubNivelPuestoLaboral(null);
    setCodigoClasificacionPuestoLaboral(null);
    setGrado(null);
    setNombreCorto("");

    setValueAutoCompleteSubNivelPuestoLaboral(null);
    setValueClasificacionSubNivelPuestoLaboral(null);
  };

  const handleNuevaPlaza = () => {
    limpiarCampos();
    handleOpenModal();
    setCodigoTipoCambioPuestoLaboral(1); //nuevo
  };

  const cellTemplateUnidadOrganizacion = (rowInfo) => {
    return (
      <>
        <Tooltip
          title={`Ir a gestión:`}
          style={{ marginRight: 10, cursor: "pointer" }}
        >
          <Link to={`/gestion/${rowInfo.data.Aviso}`}>
            {rowInfo.data.Aviso}
          </Link>
        </Tooltip>
      </>
    );
  };
  return (
    <React.Fragment>
      <Backdrop className={classes.backdrop} open={buscando}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer />

      <Dialog
        open={dialogoAbierto}
        onClose={handleCerrarDialogo}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Desactivar</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro que deseas desactivar este registro?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCerrarDialogo} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={() => quitarProyecto(codigoPuestoLaboral)}
            color="primary"
          >
            Desactivar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={dialogoAbiertoEditar}
        onClose={handleCerrarDialogoEditar}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Edición</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro que deseas editar este registro?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCerrarDialogoEditar} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={() => editarProyecto(codigoPuestoLaboral)}
            color="primary"
          >
            Editar
          </Button>
        </DialogActions>
      </Dialog>
      {/* Dialogo Eliminar Solicitud */}
      <Dialog
        open={dialogoAbiertoEditar}
        onClose={handleCerrarDialogoEditar}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Edición</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro que deseas editar este registro?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCerrarDialogoEditar} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={() => editarProyecto(codigoPuestoLaboral)}
            color="primary"
          >
            Editar
          </Button>
        </DialogActions>
      </Dialog>
      {/* Dialogo para iniciar solicitud */}
      <Dialog
        open={dialogoAbiertoIniciarSolicitud}
        onClose={handleCerrarDialogoIniciarSolicitud}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Iniciar Solicitud</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro que desea iniciar la solicitud para iniciar este
            registro?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCerrarDialogoIniciarSolicitud}
            color="secondary"
          >
            Cancelar
          </Button>
          <Button
            onClick={() => iniciarSolicitud(codigoPuestoLaboralSolicitud)}
            color="primary"
          >
            Iniciar
          </Button>
        </DialogActions>
      </Dialog>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={BackdropModal}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paperModal}>
            <h2 id="simple-modal-title">Datos de Puesto Laboral</h2>
            <Grid container spacing={3}>
              <form
                autoComplete="off"
                onSubmit={handleSubmit(guardarDato, cuandoHayError)}
              >
                <Grid item xs={6} sm={6} md={12} lg={12}>
                  <FormControl className={classes.formControlSelects}>
                    <Controller
                      name="nivelPuestoLaboral"
                      required
                      render={({ NivelPuestoLaboral }) => (
                        <Autocomplete
                          id="NivelPuestoLaboral"
                          options={maestroNivelPuestoLaboral}
                          getOptionLabel={(option) => option.NivelPuestoLaboral}
                          value={valueAutoCompleteNivelPuestoLaboral}
                          onChange={(event, newValue) =>
                            handleOnChangeNivelPuestoLaboral(event, newValue)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Nivel Puesto Laboral"
                              variant="outlined"
                            />
                          )}
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} md={12} lg={12}>
                  <FormControl className={classes.formControlSelects}>
                    <Controller
                      name="subNivelPuestoLaboral"
                      required
                      render={({ SubNivelPuestoLaboral }) => (
                        <Autocomplete
                          id="subNivelPuestoLaboral"
                          options={maestroSubNivelPuestoLaboral}
                          getOptionLabel={(option) =>
                            option.SubNivelPuestoLaboral
                          }
                          value={valueAutoCompleteSubNivelPuestoLaboral}
                          onChange={(event, newValue) =>
                            handleOnChangeSubNivelPuestoLaboral(event, newValue)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Puesto Genérico "
                              variant="outlined"
                            />
                          )}
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} md={12} lg={12}>
                  <FormControl className={classes.formControlSelects}>
                    <Controller
                      name="clasificacionPuestoLaboral"
                      required
                      render={({ ClasificacionPuestoLaboral }) => (
                        <Autocomplete
                          id="clasificacionPuestoLaboral"
                          options={maestroClasificacionPuestoLaboral}
                          getOptionLabel={(option) =>
                            option.ClasificacionPuestoLaboral
                          }
                          value={valueAutoCompleteClasificacionPuestoLaboral}
                          onChange={(event, newValue) =>
                            handleOnChangeClasificacionPuestoLaboral(
                              event,
                              newValue
                            )
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Clasificación de Puesto Laboral "
                              variant="outlined"
                            />
                          )}
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={6} sm={6} md={12} lg={12}>
                  <FormControl className={classes.formControlSelects}>
                    <Controller
                      name="puestoLaboralNombre"
                      render={({ PuestoLaboralNombre }) => (
                        <TextField
                          id="puestoLaboral"
                          label="Puesto Laboral"
                          name="puestoLaboral"
                          value={puestoLaboralNombre}
                          onChange={(e) =>
                            setPuestoLaboralNombre(e.target.value)
                          }
                          variant="outlined"
                          helperText={
                            fieldsErrors.puestoLaboral
                              ? fieldsErrors.puestoLaboral.message
                              : ""
                          }
                          //   inputRef={refTelefono}
                          //   {...inputPropsTelefono}

                          //   rules={{
                          //     pattern: { value: /[0-9]/, message: "Solo se aceptan números" },
                          //     minLength: { value: 8, message: "No menor a 8 dígitos" },
                          //     maxLength: { value: 8, message: "No mayor a 8 dígitos" },
                          //   }}
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} md={12} lg={12}>
                  <FormControl className={classes.formControlSelects}>
                    <Controller
                      name="nombreCorto"
                      render={({ NombreCorto }) => (
                        <TextField
                          id="nombreCorto"
                          label="Nombre Corto"
                          name="nombreCorto"
                          value={nombreCorto}
                          onChange={(e) => setNombreCorto(e.target.value)}
                          variant="outlined"
                          helperText={
                            fieldsErrors.nombreCorto
                              ? fieldsErrors.nombreCorto.message
                              : ""
                          }
                          //   inputRef={refTelefono}
                          //   {...inputPropsTelefono}

                          //   rules={{
                          //     pattern: { value: /[0-9]/, message: "Solo se aceptan números" },
                          //     minLength: { value: 8, message: "No menor a 8 dígitos" },
                          //     maxLength: { value: 8, message: "No mayor a 8 dígitos" },
                          //   }}
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} md={12} lg={12}>
                  <FormControl className={classes.formControlSelects}>
                    <Controller
                      name="grado"
                      render={({ Grado }) => (
                        <TextField
                          id="grado"
                          label="Grado"
                          name="grado"
                          value={grado}
                          onChange={(e) => setGrado(e.target.value)}
                          variant="outlined"
                          helperText={
                            fieldsErrors.grado ? fieldsErrors.grado.message : ""
                          }
                          //   inputRef={refTelefono}
                          //   {...inputPropsTelefono}

                          //   rules={{
                          //     pattern: { value: /[0-9]/, message: "Solo se aceptan números" },
                          //     minLength: { value: 8, message: "No menor a 8 dígitos" },
                          //     maxLength: { value: 8, message: "No mayor a 8 dígitos" },
                          //   }}
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} md={12} lg={12}>
                  <FormControl className={classes.formControlSelects}>
                    <Controller
                      name="noVersion"
                      render={({ NoVersion }) => (
                        <TextField
                          id="noVersion"
                          label="Número de Versión"
                          name="noVersion"
                          value={numeroVersion}
                          onChange={(e) => setNumeroVersion(e.target.value)}
                          variant="outlined"
                          helperText={
                            fieldsErrors.noVersion
                              ? fieldsErrors.noVersion.message
                              : ""
                          }
                          //   inputRef={refTelefono}
                          //   {...inputPropsTelefono}

                          //   rules={{
                          //     pattern: { value: /[0-9]/, message: "Solo se aceptan números" },
                          //     minLength: { value: 8, message: "No menor a 8 dígitos" },
                          //     maxLength: { value: 8, message: "No mayor a 8 dígitos" },
                          //   }}
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} md={12} lg={12}>
                  <FormControl className={classes.formControlSelects}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid container justify="flex-start">
                        <KeyboardDatePicker
                          disableToolbar
                          variant="inline"
                          format="dd/MM/yyyy"
                          margin="normal"
                          id="date-picker-inline"
                          label="Fecha de Creación"
                          value={fechaCreacion}
                          onChange={handleDateChangeFechaCreacion}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          required
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} md={12} lg={12}>
                  <Button
                    width="100%"
                    variant="contained"
                    style={{
                      backgroundColor: "#69bd4b",
                      marginBottom: 10,
                      marginTop: 10,
                      color: "white",
                      width: "93%",
                      marginLeft: 30,
                    }}
                    type="submit"
                  >
                    &nbsp;Guardar Cambios
                  </Button>
                </Grid>
              </form>
            </Grid>
          </div>
        </Fade>
      </Modal>
      <AppBar
        position="sticky"
        className={classes.appBar}
        style={{ backgroundColor: titleColor }}
      >
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            style={{ display: "flex", flexGrow: 1 }}
          >
            {`Listado de Puestos Laborales`}
          </Typography>
          {editarCompleto && (
            <Tooltip title={"Crear nuevo ítem"} aria-label="Crear nuevo ítem">
              <IconButton
                aria-label="Crear nuevo ítem"
                style={{ color: "white" }}
              >
                <Add onClick={handleNuevaPlaza} />
              </IconButton>
            </Tooltip>
          )}
        </Toolbar>
      </AppBar>

      <Paper className={classes.rootTabla}>
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={(_, val) => setTab(val)}
          variant="scrollable"
          scrollButtons="on"
          aria-label="scrollable force tabs example"
          ScrollButtonComponent={MyTabScrollButton}
        >
          <Tab
            // icon={<GroupWork />}
            label="Puestos Laborales"
            {...a11yProps(0)}
            style={{ textTransform: "none" }}
            value={0}
          />
          <Tab
            // icon={<Work />}
            label="Solicitudes Activas"
            {...a11yProps(1)}
            style={{ textTransform: "none" }}
            value={1}
            // disabled={!permisoPlazaLaboral}
          />
          <Tab
            // icon={<GroupAdd />}
            label="Solicitudes Anteriores"
            {...a11yProps(2)}
            style={{ textTransform: "none" }}
            value={2}
            // disabled={!permisoPersonal}
          />
        </Tabs>
        <TabPanel id="puestosActuales" value={tab} index={0}>
          <Box className={classes.box} pl={0} pt={0}>
            <Paper className={classes.rootTabla}>
              <Typography variant={"h6"}>Puestos Laborales Actuales</Typography>
              <DataGrid
                dataSource={puestoLaboral}
                //   defaultColumns={this.props.columns}
                showBorders={true}
                rowAlternationEnabled={true}
                //ref={(ref) => (dataGrid = ref)}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                onExporting={onExporting}
                className={classes.table}
              >
                <FilterRow
                  visible={showFilterRow}
                  applyFilter={currentFilter}
                />
                <HeaderFilter visible={showHeaderFilter} />
                <GroupPanel visible={false} />
                <Grouping autoExpandAll={autoExpandAll} />
                <SearchPanel
                  visible={false}
                  width={240}
                  placeholder="Search..."
                />
                {/* <Selection mode="multiple" /> */}
                <LoadPanel
                  enabled={true}
                  shadingColor="rgba(0,0,0,0.4)"
                  showIndicator={true}
                  shading={true}
                  showPane={true}
                  closeOnOutsideClick={false}
                />
                <Editing
                  mode="row"
                  useIcons={true}
                  allowUpdating={false}
                  allowDeleting={false}
                />
                <Column
                  cellRender={cellSemaforo}
                  caption="Semaforo"
                  fixedPosition="left"
                />
                <Column
                  caption="Opciones"
                  allowFiltering={false}
                  allowSorting={false}
                  cellRender={cellOpciones}
                />
                <Column
                  dataField="CodigoPuestoLaboral"
                  caption="Código Puesto Laboral"
                  dataType="number"
                  // visible={false}
                />
                <Column
                  dataField="CodigoTipoPuestoLaboral"
                  caption="Código Tipo Puesto"
                  dataType="number"
                  visible={false}
                />
                <Column
                  dataField="CodigoNivelPuestoLaboral"
                  caption="Código Nivel Puesto"
                  dataType="number"
                  visible={false}
                />

                <Column dataField="PuestoLaboral" caption="Puesto Laboral" />
                <Column dataField="NombreCorto" caption="Nombre Corto " />
                <Column
                  dataField="NivelPuestoLaboral"
                  caption="Nivel Puesto Laboral"
                />
                <Column
                  dataField="SubNivelPuestoLaboral"
                  caption="Puesto Genérico "
                />
                <Column
                  dataField="ClasificacionPuestoLaboral"
                  caption="Clasificación "
                />
                <Column dataField="Grado" caption="Grado " />
                <Column
                  dataField="TipoPuestoLaboral"
                  caption="Tipo Puesto Laboral"
                />
                <Column dataField="NumeroVersion" caption="Número de Versión" />
                <Column
                  dataField="FechaCreacion"
                  caption="Fecha Creación"
                  dataType="date"
                  format="dd/MM/yyyy"
                />
                <Column
                  dataField="Activo"
                  caption="Activo"
                  dataType="boolean"
                />

                <ColumnFixing enabled={true} />
                <Sorting mode="multiple" />
                <Paging defaultPageSize={50} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[50, 100, 250]}
                  showInfo={true}
                />
                <Export enabled={true} allowExportSelectedData={true} />
                <Scrolling columnRenderingMode="virtual" />
              </DataGrid>
            </Paper>
          </Box>
        </TabPanel>
        <TabPanel id="plazas" value={tab} index={1}>
          <Box className={classes.box} pl={0} pt={0}>
            <Paper className={classes.rootTabla}>
              <Typography variant={"h6"}>Solicitudes</Typography>
              <DataGrid
                dataSource={maestroPuestoLaboralSolicitud}
                //   defaultColumns={this.props.columns}
                showBorders={true}
                rowAlternationEnabled={true}
                //ref={(ref) => (dataGrid = ref)}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                onExporting={onExporting}
                className={classes.table}
              >
                <FilterRow
                  visible={showFilterRow}
                  applyFilter={currentFilter}
                />
                <HeaderFilter visible={showHeaderFilter} />
                <GroupPanel visible={false} />
                <Grouping autoExpandAll={autoExpandAll} />
                <SearchPanel
                  visible={false}
                  width={240}
                  placeholder="Search..."
                />
                {/* <Selection mode="multiple" /> */}
                <LoadPanel
                  enabled={true}
                  shadingColor="rgba(0,0,0,0.4)"
                  showIndicator={true}
                  shading={true}
                  showPane={true}
                  closeOnOutsideClick={false}
                />
                <Editing
                  mode="row"
                  useIcons={true}
                  allowUpdating={false}
                  allowDeleting={false}
                />
                <Column
                  caption="Opciones"
                  allowFiltering={false}
                  allowSorting={false}
                  cellRender={cellOpcionesSolicitud}
                />
                <Column
                  dataField="Gestion"
                  caption="# Gestion"
                  cellRender={cellTemplateUnidadOrganizacion}
                />
                <Column
                  dataField="TipoPuestoLaboral"
                  caption="Tipo Puesto Laboral"
                />
                <Column
                  dataField="EstadoSolicitud"
                  caption="Estado Solicitud"
                />
                <Column
                  dataField="CodigoPuestoLaboralSolicitud"
                  caption="Código DB"
                  dataType="number"
                  visible={false}
                />
                <Column
                  dataField="CodigoPuestoLaboral"
                  caption="Código Puesto Laboral "
                  dataType="number"
                />
                <Column
                  dataField="CodigoTipoPuestoLaboral"
                  caption="Código Tipo Puesto"
                  dataType="number"
                  visible={false}
                />
                <Column
                  dataField="CodigoNivelPuestoLaboral"
                  caption="Código Nivel Puesto"
                  dataType="number"
                  visible={false}
                />

                <Column dataField="PuestoLaboral" caption="Puesto Laboral" />
                <Column dataField="NombreCorto" caption="Nombre Corto " />
                <Column
                  dataField="NivelPuestoLaboral"
                  caption="Nivel Puesto Laboral"
                />
                <Column
                  dataField="SubNivelPuestoLaboral"
                  caption="Puesto Genérico "
                />
                <Column
                  dataField="ClasificacionPuestoLaboral"
                  caption="Clasificación "
                />
                <Column dataField="Grado" caption="Grado " />
                <Column
                  dataField="TipoPuestoLaboral"
                  caption="Tipo Puesto Laboral"
                />
                <Column
                  dataField="EstadoSolicitud"
                  caption="Estado Solicitud"
                />
                <Column
                  dataField="TipoCambioPuestoLaboral"
                  caption="TipoCambio "
                />
                <Column
                  dataField="FechaCreacion"
                  caption="Fecha Creación"
                  dataType="date"
                  format="dd/MM/yyyy"
                />
                <Column
                  dataField="Activo"
                  caption="Fecha Activo"
                  dataType="boolean"
                />

                <ColumnFixing enabled={true} />
                <Sorting mode="multiple" />
                <Paging defaultPageSize={20} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[20, 50, 100]}
                  showInfo={true}
                />
                <Export enabled={true} allowExportSelectedData={true} />
                <Scrolling columnRenderingMode="virtual" />
              </DataGrid>
            </Paper>
          </Box>
        </TabPanel>
        <TabPanel id="personal" value={tab} index={2}>
          <Box className={classes.box} pl={0} pt={0}>
            <Paper className={classes.rootTabla}>
              <Typography variant={"h6"}>Solicitudes</Typography>
              <DataGrid
                dataSource={maestroPuestoLaboralSolicitudEjecutado}
                //   defaultColumns={this.props.columns}
                showBorders={true}
                rowAlternationEnabled={true}
                //ref={(ref) => (dataGrid = ref)}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                onExporting={onExporting}
                className={classes.table}
              >
                <FilterRow
                  visible={showFilterRow}
                  applyFilter={currentFilter}
                />
                <HeaderFilter visible={showHeaderFilter} />
                <GroupPanel visible={false} />
                <Grouping autoExpandAll={autoExpandAll} />
                <SearchPanel
                  visible={false}
                  width={240}
                  placeholder="Search..."
                />
                {/* <Selection mode="multiple" /> */}
                <LoadPanel
                  enabled={true}
                  shadingColor="rgba(0,0,0,0.4)"
                  showIndicator={true}
                  shading={true}
                  showPane={true}
                  closeOnOutsideClick={false}
                />
                <Editing
                  mode="row"
                  useIcons={true}
                  allowUpdating={false}
                  allowDeleting={false}
                />
                <Column
                  caption="Opciones"
                  allowFiltering={false}
                  allowSorting={false}
                  cellRender={cellOpcionesSolicitud}
                />
                <Column
                  dataField="Gestion"
                  caption="# Gestion"
                  cellRender={cellTemplateUnidadOrganizacion}
                />
                <Column
                  dataField="TipoPuestoLaboral"
                  caption="Tipo Puesto Laboral"
                />
                <Column
                  dataField="EstadoSolicitud"
                  caption="Estado Solicitud"
                />
                <Column
                  dataField="CodigoPuestoLaboralSolicitud"
                  caption="Código DB"
                  dataType="number"
                  visible={false}
                />
                <Column
                  dataField="CodigoPuestoLaboral"
                  caption="Código Puesto Laboral "
                  dataType="number"
                />
                <Column
                  dataField="CodigoTipoPuestoLaboral"
                  caption="Código Tipo Puesto"
                  dataType="number"
                  visible={false}
                />
                <Column
                  dataField="CodigoNivelPuestoLaboral"
                  caption="Código Nivel Puesto"
                  dataType="number"
                  visible={false}
                />

                <Column dataField="PuestoLaboral" caption="Puesto Laboral" />
                <Column dataField="NombreCorto" caption="Nombre Corto " />
                <Column
                  dataField="NivelPuestoLaboral"
                  caption="Nivel Puesto Laboral"
                />
                <Column
                  dataField="SubNivelPuestoLaboral"
                  caption="Puesto Genérico "
                />
                <Column
                  dataField="ClasificacionPuestoLaboral"
                  caption="Clasificación "
                />
                <Column dataField="Grado" caption="Grado " />
                <Column
                  dataField="TipoPuestoLaboral"
                  caption="Tipo Puesto Laboral"
                />
                <Column
                  dataField="EstadoSolicitud"
                  caption="Estado Solicitud"
                />
                <Column
                  dataField="TipoCambioPuestoLaboral"
                  caption="TipoCambio "
                />
                <Column
                  dataField="FechaCreacion"
                  caption="Fecha Creación"
                  dataType="date"
                  format="dd/MM/yyyy"
                />
                <Column
                  dataField="Activo"
                  caption="Fecha Activo"
                  dataType="boolean"
                />

                <ColumnFixing enabled={true} />
                <Sorting mode="multiple" />
                <Paging defaultPageSize={20} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[20, 50, 100]}
                  showInfo={true}
                />
                <Export enabled={true} allowExportSelectedData={true} />
                <Scrolling columnRenderingMode="virtual" />
              </DataGrid>
            </Paper>
          </Box>
        </TabPanel>
      </Paper>
    </React.Fragment>
  );
};

export default PuestoLaboral;
