import React, { useState, useEffect } from 'react'
import {
  Grid,
  Button,
  Select,
  TextField,
  MenuItem,
  InputLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  DialogContent,
  DialogTitle,
  DialogActions
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { ToastContainer, toast } from 'react-toastify'
import Api from '../../../utils/api'
import { solicitudModel } from '../../utils/modelos'
import { getUsuarioInserta } from '../../utils/utilidades'
import LoadingButton from '../utils/LoadingButton'
import FormularioRegistro from './FormularioRegistro'
import FormularioInterno from './FormularioExterno'

const SolicitudEditar = ({ title, usuarios, onClose, onRefresh, datos }) => {
  const [sistema, setSistema] = useState('')
  const [macroproceso, setMacroproceso] = useState('')
  const [proceso, setProceso] = useState('')
  const [tipo, setTipo] = useState('')
  const [division, setDivision] = useState('')
  const [solicitante, setSolicitante] = useState(null)
  const [titulo, setTitulo] = useState('')
  const [descripcion, setDescripcion] = useState('')
  const [encargado, setEncargado] = useState(null)
  const [loading, setLoading] = useState(false)
  const [isRegistro, setIsRegistro] = useState(false)
  const [procesos, setProcesos] = useState([])
  const [macroprocesos, setMacroprocesos] = useState([])
  const [usoDoc, setUsoDoc] = useState('iso')
  const [masters, setMasters] = useState({
    tipos: [],
    divisiones: [],
    sistemas: [],
    macroprocesos: [],
    procesos: []
  })
  const getMasters = async () => {
    try {
      const reqTipos = Api.fetchGetRequest('api/documentos/tipodocumento')
      const reqDivisiones = Api.fetchGetRequest('api/documentos/division')
      const reqSistemas = Api.fetchGetRequest('api/documentos/sistemagestion')
      const reqMacroprocesos = Api.fetchGetRequest('api/documentos/macroproceso')
      const reqProcesos = Api.fetchGetRequest('api/documentos/proceso')
      const requests = [
        reqTipos,
        reqDivisiones,
        reqSistemas,
        reqMacroprocesos,
        reqProcesos
      ]
      const response = await Promise.all(requests)
      setMasters({
        tipos: response[0].data,
        divisiones: response[1].data,
        sistemas: response[2].data,
        macroprocesos: [],
        procesos: []
      })
      setMacroprocesos(response[3].data)
      setProcesos(response[4].data)
    } catch (error) {
      toast.error('Ha ocurrido un error al obtener los datos')
    }
  }
  const initUsuarios = async () => {
    const usuario = JSON.parse(localStorage.getItem('userData'))
    const item = usuarios.find(i => i.CodigoUsuario === parseInt(usuario.codigoUsuario))
    setSolicitante(item)
  }
  const insertSolicitud = async () => {
    try {
      if (solicitante.CodigoUsuario == encargado.CodigoUsuario) {
        toast.error('El solicitante no puede ser tambien el autorizador')
        return
      }
      setLoading(true)
      const solicitud = {
        CodigoUsuarioSolicitante: solicitante ? solicitante.CodigoUsuario : null,
        IdMacroprocesoDocumento: macroproceso || null,
        IdSistemaGestion: sistema || null,
        IdTipoDocumento: tipo || null,
        IdDivisionDocumento: division || null,
        IdProcesoDocumento: proceso || null,
        NombreDocumento: titulo,
        DescripcionDocumento: descripcion,
        CodigoUsuarioAutoriza: encargado ? encargado.CodigoUsuario : null,
        UsuarioInserto: getUsuarioInserta()
      }
      const datos = await solicitudModel.validate(solicitud)
      await Api.realizarPeticionPostPut('api/documentos/solicitud/crear', datos, 'POST')
      onRefresh()
    } catch(err) {
      if (err.name && err.name === 'ValidationError') {
        toast.error(err.errors[0])
      } else {
        toast.error('Ha ocurrido un error')
      }
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    if (division) {
      const tmp = { ...masters }
      setMasters({
        ...tmp,
        macroprocesos: macroprocesos.filter(i => i.IdDivision === division)
      })
    }
  }, [division])
  useEffect(() => {
    if (macroproceso) {
      const tmp = { ...masters }
      setMasters({
        ...tmp,
        procesos: procesos.filter(i => i.IdMacroproceso === macroproceso)
      })
    }
  }, [macroproceso])
  useEffect(() => {
    if (tipo) {
      const registro = masters.tipos.find(i => i.Id === tipo)
      if (registro && registro.NombreTipoDocumento === 'Registros') {
        setIsRegistro(true)
      } else {
        setIsRegistro(false)
      }
    }
  }, [tipo])
  useEffect(() => {
    initUsuarios()
    getMasters()
  }, [])

  return <>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <Grid container>
        <Grid item xs={12}>
          <FormControl fullWidth style={{marginTop: '1em', marginBottom: '1em'}}>
            <InputLabel>División</InputLabel>
            <Select
              fullWidth
              value={division}
              onChange={ev => setDivision(ev.target.value)}
            >
              {masters.divisiones.map((i, idx) => <MenuItem key={idx} value={i.Id}>
                {i.NombreDivision}
              </MenuItem>)}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth style={{marginTop: '1em', marginBottom: '1em'}}>
            <InputLabel>Macroproceso</InputLabel>
            <Select
              fullWidth
              value={macroproceso}
              onChange={ev => setMacroproceso(ev.target.value)}
            >
              {masters.macroprocesos.map((i, idx) => <MenuItem key={idx} value={i.IdMacroproceso}>
                {i.NombreMacroproceso}
              </MenuItem>)}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth style={{marginTop: '1em', marginBottom: '1em'}}>
            <InputLabel>Proceso</InputLabel>
            <Select
              fullWidth
              value={proceso}
              onChange={ev => setProceso(ev.target.value)}
            >
              {masters.procesos.map((i, idx) => <MenuItem key={idx} value={i.Id}>{i.NombreProceso}</MenuItem>)}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth style={{marginTop: '1em', marginBottom: '1em'}}>
            <InputLabel>Sistema de gestión</InputLabel>
            <Select
              fullWidth
              value={sistema}
              onChange={ev => setSistema(ev.target.value)}
            >
              {masters.sistemas.map((i, idx) => <MenuItem key={idx} value={i.Id}>
                {i.NombreSistemaGestion}
              </MenuItem>)}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth style={{marginTop: '1em', marginBottom: '1em'}}>
            <InputLabel>Tipo de documento</InputLabel>
            <Select
              fullWidth
              value={tipo}
              onChange={ev => setTipo(ev.target.value)}
            >
              {masters.tipos.map((i, idx) => <MenuItem key={idx} value={i.Id}>
                {i.NombreTipoDocumento}
              </MenuItem>)}
            </Select>
          </FormControl>
        </Grid>
        <FormControl component="fieldset">
          <FormLabel>Uso del documento</FormLabel>
          <RadioGroup value={usoDoc} onChange={ev => setUsoDoc(ev.target.value)}>
            <FormControlLabel value="iso" control={<Radio />} label="ISO 9001" />
            <FormControlLabel value="interno" control={<Radio />} label="Documento interno" />
          </RadioGroup>
        </FormControl>
        {
          isRegistro && <Grid item xs={12}>
            <FormularioRegistro />
          </Grid>
        }
        {
          usoDoc === 'interno' && <FormularioInterno />
        }
        <Grid item xs={12}>
          <TextField
            fullWidth
            value={titulo}
            onChange={ev => setTitulo(ev.target.value)}
            label="Título del documento"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            value={descripcion}
            onChange={ev => setDescripcion(ev.target.value)}
            multiline
            rows={4}
            label="Descripción"
          />
        </Grid>
        {/* <Grid item xs={12}>
          <TextField
            fullWidth
            label="Fecha"
            type="date"
            InputLabelProps={{ shrink: true }}
          />
        </Grid> */}
        <Grid item xs={12}>
          <Autocomplete
            disabled={true}
            options={usuarios}
            getOptionLabel={(option) => option.NombreCompletoUsuario ? option.NombreCompletoUsuario : ''}
            value={solicitante}
            onChange={(ev, value) => {
              setSolicitante(value)
            }}
            defaultValue=""
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Solicitante"
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            options={usuarios}
            getOptionLabel={(option) => option.NombreCompletoUsuario ? option.NombreCompletoUsuario : ''}
            defaultValue=""
            value={encargado}
            onChange={(ev, value) => {
              setEncargado(value)
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Encargado de aprobar"
              />
            )}
          />
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => !loading && onClose()} variant="contained">Cancelar</Button>
      <LoadingButton
        variant="contained"
        color="primary"
        loading={loading}
        onClick={insertSolicitud}
      >Enviar</LoadingButton>
    </DialogActions>
    <ToastContainer />
  </>
}

export default SolicitudEditar