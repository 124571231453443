import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import Mensaje from "../../helpers/components/message";

const useStyles = makeStyles({
  table: {
    minWidth: 300,
  },
});

export default function TablaVistas(props) {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Usuario</TableCell>
            <TableCell align="left">Hora de lectura</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            {props.data.length > 0 ? 
          props.data.map((row, i) => (
            <TableRow key={i}>
              <TableCell component="th" scope="row">
                {row.Nombre}
              </TableCell>
              <TableCell align="left"><Mensaje>{row.Fecha}</Mensaje></TableCell>
            </TableRow>
          )) : 
          (
            <TableRow>
            <TableCell component="th" scope="row" colSpan={2} align={"center"}>
              
              <Mensaje>{"No hay registros."}</Mensaje>
            </TableCell>
          </TableRow>
          )
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}