import React from 'react';
import { Grid, makeStyles, Card, Typography, IconButton, Avatar } from "@material-ui/core";
import { format, formatDistance } from 'date-fns';
import esLocale from 'date-fns/locale/es'
import CardContent from "@material-ui/core/CardContent";
import CoverNoticia from "../../image-repository/CoverNoticia.png";
import { Notifications } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        margin: 0,
        marginBottom: '1rem',
        borderRadius: 10,
        cursor: 'pointer',
        height: '100%'
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'row',
        borderRadius: 10,
        flex: 1,
        width: '100%',
        height: '100%',
        padding: 0,
        justifyContent: 'center',
        alignItems: 'center'
    },
    imgContainer: {
        display: 'flex',
        width: '100%',
        flex: 0.75,
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10
    },
    contentContainer: {
        display: 'flex',
        // height: '100%',
        flexDirection: 'column',
        flex: 0.25,
        padding: '1.5rem',
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10
    },
    large: {
        width: theme.spacing(10),
        height: theme.spacing(10),
    },
    title: {
        textAlign: 'center',
        fontSize: '1.2rem',
        fontWeight: '700',
        marginBottom: '0.5rem',
        '-webkit-user-select': 'none',
        '-moz-user-select': 'none',
        '-ms-user-select': 'none',
        'user-select': 'none'
    },
    descripcion: {
        textAlign: 'center',
        fontSize: '1rem',
        '-webkit-user-select': 'none',
        '-moz-user-select': 'none',
        '-ms-user-select': 'none',
        'user-select': 'none'
    },
    fecha: {
        marginTop: '0.5rem',
        textAlign: 'center',
        fontSize: '0.8rem',
        '-webkit-user-select': 'none',
        '-moz-user-select': 'none',
        '-ms-user-select': 'none',
        'user-select': 'none'
    }
}));

const CardNoticia = ({ id, titulo, descripcion, foto, fecha, onClick }) => {

    const classes = useStyles();

    return (
        <Card onClick={() => onClick(id)} className={classes.root} elevation={0} >
            <CardContent className={classes.cardContent} style={{ paddingBottom: 0 }}>
                <div
                    className={classes.imgContainer}
                    style={{ backgroundImage: foto ? `url("${foto}")` : `url(${CoverNoticia})` }}
                />
                <div className={classes.contentContainer} >
                    <Typography className={classes.title} variant='body2' color='textPrimary'>
                        {titulo}
                    </Typography>
                    <Typography className={classes.descripcion} variant='body2' color='textSecondary'>
                        {descripcion}
                    </Typography>
                    <Typography variant='subtitle1' color='textSecondary' className={classes.fecha}>
                        {formatDistance(new Date(fecha), new Date(), { addSuffix: true, locale: esLocale })}
                    </Typography>
                </div>
            </CardContent>
        </Card>
    )
}

export default CardNoticia;
