import React, { useState, useEffect } from 'react'
import {
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField
} from '@material-ui/core'
import LoadingButton from '../utils/LoadingButton'
import { range, getUsuarioInserta } from '../../utils/utilidades'
import { toast } from 'react-toastify'
import api from '../../../utils/api'

const TiempoRetencion = ({ onRefresh, onClose, datos=null, update=false }) => {
  const [saving, setSaving] = useState(false)
  const [tiempo, setTiempo] = useState(1)
  const [descripcion, setDescripcion] = useState('')
  const tiempos = range(1, 10)
  const getURL = () => {
    if (datos) {
      return `api/documentos/tiempo-retencion/editar/${datos.Id}`
    }
    return 'api/documentos/tiempo-retencion/crear'
  }
  const saveNew = async () => {
    if (descripcion === '') {
      toast.error('La descripción es requerida')
      return
    }
    try {
      setSaving(true)
      const body = {
        Valor: tiempo * 365,
        UnidadDeTiempo: 'dia',
        Descripcion: descripcion,
        UsuarioInserto: getUsuarioInserta()
      }
      if (datos) {
        body.UsuarioModifico = getUsuarioInserta()
      }
      const url = getURL()
      await api.realizarPeticionPostPut(url, body, 'POST')
      onRefresh()
      toast.success('Registro guardado')
    } catch (error) {
      toast.error('Ha ocurrido un error')
    } finally {
      setSaving(false)
    }
  }
  useEffect(() => {
    if (datos) {
      const anhos = datos.Valor / 365
      setTiempo(anhos)
      setDescripcion(datos.Descripcion)
    }
  }, [])
  return <>
    <DialogTitle>Agregar tiempo de retención</DialogTitle>
    <DialogContent>
      <FormControl fullWidth>
        <InputLabel>Tiempo de retención</InputLabel>
        <Select
          value={tiempo}
          onChange={ev => setTiempo(ev.target.value)}
        >
          {
            tiempos.map(i => <MenuItem key={i} value={i}>
              { i > 1 ? `${i} años` : `${i} año` }
            </MenuItem>)
          }
        </Select>
      </FormControl>
      <TextField
        fullWidth
        multiline
        rows={2}
        label="Descripción"
        value={descripcion}
        onChange={ev => setDescripcion(ev.target.value)}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancelar</Button>
      <LoadingButton
        color="primary"
        loading={saving}
        onClick={saveNew}
      >
        Guardar
      </LoadingButton>
    </DialogActions>
  </>
}

export default TiempoRetencion
