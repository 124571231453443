import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Skeleton from "@material-ui/lab/Skeleton";

import DataGrid, {
  Column,
  Pager,
  Paging,
  Sorting,
  ColumnFixing,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Grouping,
  GroupPanel,
  Export,
  Selection,
  Editing,
  Button,
  Scrolling,
} from "devextreme-react/data-grid";

import {
  Typography,
  Backdrop,
  CircularProgress,
  Tab,
  Tabs,
  Box,
  Paper,
} from "@material-ui/core";

import CheckBox from "devextreme-react/check-box";
import SelectBox from "devextreme-react/select-box";
// import Button from "devextreme-react/button";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
//import DevexpressPercentColumn from "./devexpress-percent-column";
import "devextreme/dist/css/dx.material.teal.light.compact.css";
//Own components

import API from "../../utils/api";
import { isSignedIn } from "../../utils/auth";

const styles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 1,
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const renderTitleHeader = (data) => {
  return <b>{data.column.caption}</b>;
};

const HistoricoPuestoLaboral = (props) => {
  const classes = styles();
  const { dataEmpleado } = props;

  const [infovacaciones, setInfovacaciones] = useState([]);
  const [cargando, setCargando] = useState(true);
  let applyFilterTypes = [
    {
      key: "auto",
      name: "Immediately",
    },
    {
      key: "onClick",
      name: "On Button Click",
    },
  ];

  const [positionDisableSorting, setPositionDisableSorting] = useState(false);
  const [showFilterRow, setShowFilterRow] = useState(false);
  const [showHeaderFilter, setShowHeaderFilter] = useState(false);
  const [currentFilter, setCurrentFilter] = useState(applyFilterTypes[0].key);
  const [autoExpandAll, setAutoExpandAll] = useState(true);

  useEffect(() => {
    async function fetchData() {
      await cargarHistoricoSalario();
    }
    fetchData();
  }, [dataEmpleado]);

  const cargarHistoricoSalario = async () => {
    try {
      setCargando(true);
      let usuarioDPI = dataEmpleado.DPI;

      const request = await API.fetchGetRequest(
        `api/Empleado/HistoricoPuesto/${usuarioDPI}`
      );

      if (!request.data.response) {
        toast.error(
          "El usuario no inició sesión con un DPI, no puede desplegarse información"
        );
      } else {
        if (request.data.data.length === 0) {
          toast.warn(
            "El usuario no cuenta con información sobre historico de salarios"
          );
        } else {
          setInfovacaciones(
            request.data.data.filter(
              (w) => w.FechaInicio >= dataEmpleado.FechaAltaReal
            )
          );
        }
      }

      setCargando(false);
    } catch (e) {
      console.log(e);
      setCargando(true);
    } finally {
    }
  };

  const onExporting = async (e) => {
    // const workbook = new Workbook();
    // const worksheet = workbook.addWorksheet("Main sheet");
    // exportDataGrid({
    //   component: e.component,
    //   worksheet: worksheet,
    // }).then(function () {
    //   workbook.xlsx.writeBuffer().then(function (buffer) {
    //     saveAs(
    //       new Blob([buffer], { type: "application/octet-stream" }),
    //       "DataGrid.xlsx"
    //     );
    //   });
    // });
    // e.cancel = true;
  };

  return (
    <React.Fragment>
      <Backdrop className={classes.backdrop} open={cargando}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Paper className={classes.root}>
        <DataGrid
          dataSource={infovacaciones}
          //   defaultColumns={this.props.columns}
          showBorders={true}
          rowAlternationEnabled={true}
          //ref={(ref) => (dataGrid = ref)}
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          onExporting={onExporting}
          className={classes.table}
        >
          <FilterRow visible={showFilterRow} applyFilter={currentFilter} />
          <HeaderFilter visible={showHeaderFilter} />
          <GroupPanel visible={false} />
          <Grouping autoExpandAll={autoExpandAll} />
          <SearchPanel visible={false} width={240} placeholder="Search..." />
          {/* <Selection mode="multiple" /> */}
          <Editing
            mode="row"
            useIcons={true}
            allowUpdating={false}
            allowDeleting={false}
          />

          <Column
            dataField="CodigoPuestoLaboral"
            caption="Código Puesto Laboral"
            dataType="number"
          />
          <Column dataField="PuestoLaboral" caption="Puesto Laboral" />
          <Column
            dataField="FechaInicio"
            caption="Fecha Inicio"
            dataType="date"
            format="dd/MM/yyyy"
          />
          <Column
            dataField="FechaFinal"
            caption="Fecha Fin"
            dataType="date"
            format="dd/MM/yyyy"
          />
          <Column dataField="EstaActivo" caption="Activo" dataType="boolean" />

          <ColumnFixing enabled={true} />
          <Sorting mode="multiple" />
          <Paging defaultPageSize={10} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[5, 10, 20]}
            showInfo={true}
          />
          <Export enabled={true} allowExportSelectedData={true} />
          <Scrolling columnRenderingMode="virtual" />
        </DataGrid>
      </Paper>
      <ToastContainer />
    </React.Fragment>
  );
};

export default HistoricoPuestoLaboral;
