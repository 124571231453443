import React, { useState } from 'react'
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField
} from '@material-ui/core'
import { toast } from 'react-toastify'

import { getCodigoUsuario } from '../../utils/utilidades'
import LoadingButton from '../utils/LoadingButton'
import api from '../../../utils/api'

const VigenciaSinCambios = ({ doc, onClose, onRefresh }) => {
  const [observaciones, setObservaciones] = useState('')
  const [updating, setUpdating] = useState(false)
  const updateVigencia = async () => {
    if (observaciones.trim()) {
      try {
        setUpdating(true)
        console.log(doc)
        const data = {
          IdSolicitud: doc.IdSolicitud,
          CodigoUsuarioAutoriza: getCodigoUsuario(),
          Observaciones: observaciones
        }
        await api.realizarPeticionPostPut(
          '/api/documentos/actualizar-documento-expirado-sin-cambios',
          data,
          'POST'
        )
        onRefresh()
      } catch (error) {
        toast.error('No se pudieron guardar los cambios')
      } finally {
        setUpdating(false)
      }
    } else {
      toast.error('Las observaciones son requeridas')
    }
  }
  return <>
    <DialogTitle>Actualizar vigencia</DialogTitle>
    <DialogContent>
      <TextField
        fullWidth
        label="Observaciones"
        value={observaciones}
        onChange={ev => setObservaciones(ev.target.value)}
      />
    </DialogContent>
    <DialogActions>
      <Button disabled={updating} variant="contained" onClick={onClose}>Cancelar</Button>
      <LoadingButton
        loading={updating}
        variant="contained"
        color="primary"
        onClick={updateVigencia}
      >
        Actualizar
      </LoadingButton>
    </DialogActions>
  </>
}

export default VigenciaSinCambios
