import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Checkbox,
  FormControlLabel,
  Divider,
  Paper,
  Tooltip,
} from "@material-ui/core";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import {
  ChromeReaderMode,
  Edit,
  People,
  AttachFile,
  Cached,
  Delete,
  Label,
  ViewList,
} from "@material-ui/icons";

import { green } from "@material-ui/core/colors";
import { NavLink } from "react-router-dom";

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import DataGrid, {
  Column,
  Pager,
  Paging,
  Sorting,
  ColumnFixing,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Grouping,
  GroupPanel,
  Export,
  Selection,
  Editing,
  Scrolling,
} from "devextreme-react/data-grid";

import API from "../../utils/api";
import { isSignedIn, signOut } from "../../utils/auth";
import { validaPermisoPaginaActual } from "../../utils/helpers";
import { ToastContainer, toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import "devextreme/dist/css/dx.material.teal.light.compact.css";

const width_proportion = "100%";

const useStyles = makeStyles((theme) => ({
  table: {
    width: 800,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 210,
  },
  textFieldDireccion: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 230,
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  greenAvatar: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  paperCard: {
    padding: 20,
    width: "100%",
  },
  formControlSelects: {
    margin: theme.spacing(1),
    minWidth: 230,
  },
  formControlSelect: {
    // margin: theme.spacing(1),
    width: width_proportion,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  rootTabla: {
    width: "100%",
    marginTop: theme.spacing.unit * 1,
    overflowX: "auto",
  },
}));

const EmpleadoFamilia = (props) => {
  const { dataEmpleado } = props;
  const classes = useStyles();
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors: fieldsErrors },
    register,
  } = useForm();

  let applyFilterTypes = [
    {
      key: "auto",
      name: "Immediately",
    },
    {
      key: "onClick",
      name: "On Button Click",
    },
  ];

  const [userName, setUserName] = useState("");
  const [cargandoMaestros, setCargandoMaestros] = useState(false);
  const [maestroTipoParentesco, setMaestroTipoParentesco] = useState([]);
  const [empleadoFamiliar, setEmpleadoFamiliar] = useState([]);
  const [codigoEmpleadoFamiliar, setCodigoEmpleadoFamiliar] = useState(0);
  const [nombreCompleto, setNombreCompleto] = useState("");
  const [codigoTipoParentesco, setCodigoTipoParentesco] = useState(0);
  const [telefono, setTelefono] = useState("");
  const [fechaNacimiento, setFechaNacimiento] = useState(new Date());
  const [fallecido, setFallecido] = useState(false);
  const [esContactoDeEmergencia, setEsContactoDeEmergencia] = useState(false);

  const [valueAutoCompleteTipoParentesco, setValueAutoCompleteTipoParentesco] =
    useState(null);

  const [positionDisableSorting, setPositionDisableSorting] = useState(false);
  const [showFilterRow, setShowFilterRow] = useState(false);
  const [showHeaderFilter, setShowHeaderFilter] = useState(false);
  const [currentFilter, setCurrentFilter] = useState(applyFilterTypes[0].key);
  const [autoExpandAll, setAutoExpandAll] = useState(true);

  const [buscando, setBackDrop] = useState(false);

  const handleCerrarBackDrop = () => {
    setBackDrop(false);
  };
  const handleAbrirBackDrop = () => {
    setBackDrop(true);
  };

  useEffect(() => {
    const cargar = async () => {
      if (!cargandoMaestros) {
        handleAbrirBackDrop();

        await cargarMaestroTipoParentesco();
        //await cargarMaestroMunicipio();

        setCargandoMaestros(true);
      } else {
        if (props.dataEmpleado !== undefined) {
          await cargarDatosEmpleado(props.dataEmpleado.DPI);
        }
        handleCerrarBackDrop();
      }
    };

    cargar();
  }, [cargandoMaestros]);

  const cargarMaestroTipoParentesco = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/TipoParentesco/";

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setMaestroTipoParentesco(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cargarDatosEmpleado = async (dpiEmpleado) => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/Empleado/Familiar/" + dpiEmpleado;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setEmpleadoFamiliar(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const guardarDato = async () => {
    const data = {
      CodigoEmpleadoFamiliar: codigoEmpleadoFamiliar,
      DPIEmpleado: props.dataEmpleado.DPI,
      NombreCompleto: nombreCompleto,
      CodigoTipoParentesco: codigoTipoParentesco,
      Telefono: telefono === undefined ? null : telefono,
      FechaNacimiento: fechaNacimiento,
      Fallecido: fallecido,
      EsContactoDeEmergencia: esContactoDeEmergencia,
      Activo: true,
      Eliminado: false,
      UsuarioInserto: userName,
      FechaInserto: "",
      UsuarioModifico: "",
      FechaModifico: "",
    };

    console.log(data);
    // dataFicha.usuarioInserto = "prueba";
    try {
      handleAbrirBackDrop();
      let vIsSignedIn = await isSignedIn();
      if (vIsSignedIn.response) {
        let request;

        request = await API.post("api/empleado/familiar/", data);
        if (request.status !== 200) {
          toast.error(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        } else {
          toast.success("Se guardó exitosamente");
          await cargarDatosEmpleado(props.dataEmpleado.DPI);
          setCodigoTipoParentesco(0);
          setValueAutoCompleteTipoParentesco(null);
          setNombreCompleto("");
          setTelefono("");
          setFechaNacimiento(new Date());
          setEsContactoDeEmergencia(false);
          setFallecido(false);
          setCodigoEmpleadoFamiliar(0);
        }
      }
    } catch (e) {
      console.log("EXCEPTION: " + e);
      toast.error(
        "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
      );
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cuandoHayError = async (data) => {
    toast.info(
      "Existen algunos campos con error, por favor valida el formulario",
      {
        position: "top-right",
        autoClose: 3000, // Esta en milisegundos 3 segundos
      }
    );
  };

  const handleDateChangeFechaNacimiento = (date) => {
    setFechaNacimiento(date);
  };

  const handleOnChangeTipoParentesco = async (event, newValue) => {
    handleAbrirBackDrop();
    if (newValue !== null) {
      setCodigoTipoParentesco(newValue.CodigoTipoParentesco);
      setValueAutoCompleteTipoParentesco(newValue);
    }
    handleCerrarBackDrop();
  };

  const onExporting = async (e) => {
    // const workbook = new Workbook();
    // const worksheet = workbook.addWorksheet("Main sheet");
    // exportDataGrid({
    //   component: e.component,
    //   worksheet: worksheet,
    // }).then(function () {
    //   workbook.xlsx.writeBuffer().then(function (buffer) {
    //     saveAs(
    //       new Blob([buffer], { type: "application/octet-stream" }),
    //       "DataGrid.xlsx"
    //     );
    //   });
    // });
    // e.cancel = true;
  };

  const [dialogoAbierto, abrirDialogo] = useState(false);
  const [dialogoAbiertoEditar, abrirDialogoEditar] = useState(false);
  const handleAbrirDialogoEliminar = (id) => {
    abrirDialogo(true);

    setCodigoEmpleadoFamiliar(id);
  };

  const handleCerrarDialogo = () => {
    abrirDialogo(false);
  };

  const handleAbrirDialogoEditar = (id) => {
    abrirDialogoEditar(true);

    setCodigoEmpleadoFamiliar(id);
  };

  const handleCerrarDialogoEditar = () => {
    abrirDialogoEditar(false);
  };

  const quitarProyecto = async (pId) => {
    handleCerrarDialogo();
    handleAbrirBackDrop();

    const proyectosExcluidos = empleadoFamiliar.filter(
      (elemento) => elemento.CodigoEmpleadoFamiliar !== pId
    );

    console.log("Quitar el proyecto" + proyectosExcluidos);

    try {
      let uri = "api/Empleado/Familiar/Quitar/" + pId;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        toast.error(
          "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
        );
      } else {
        toast.success("Se quitó el registro con éxito", {
          position: "bottom-right",
          autoClose: 3000, // Esta en milisegundos 3 segundos
        });

        await cargarDatosEmpleado(props.dataEmpleado.DPI);
        setCodigoTipoParentesco(0);
        setValueAutoCompleteTipoParentesco(null);
        setNombreCompleto("");
        setTelefono("");
        setFechaNacimiento(new Date());
        setEsContactoDeEmergencia(false);
        setFallecido(false);
        setCodigoEmpleadoFamiliar(0);
      }
    } catch (error) {
      console.log("Error en  eliminación de proyectos" + error);
    }

    handleCerrarBackDrop();

    //alert("Quitar recurso del proyecto" + codigoRecurso);
  };

  const editarProyecto = async (pId) => {
    handleCerrarDialogoEditar();
    handleAbrirBackDrop();

    const proyectosExcluidos = empleadoFamiliar.filter(
      (elemento) => elemento.CodigoEmpleadoFamiliar === pId
    );

    console.log("Editar el proyecto" + proyectosExcluidos);

    try {
      setCodigoEmpleadoFamiliar(pId);
      setCodigoTipoParentesco(proyectosExcluidos[0].CodigoTipoParentesco);
      setNombreCompleto(proyectosExcluidos[0].NombreCompleto);
      setTelefono(proyectosExcluidos[0].Telefono);
      setFechaNacimiento(proyectosExcluidos[0].FechaNacimiento);
      setEsContactoDeEmergencia(proyectosExcluidos[0].EsContactoDeEmergencia);
      setFallecido(proyectosExcluidos[0].Falleciddo);

      const tiposParentesco = maestroTipoParentesco.filter(
        (elemento) =>
          elemento.CodigoTipoParentesco ===
          proyectosExcluidos[0].CodigoTipoParentesco
      );

      setValueAutoCompleteTipoParentesco(tiposParentesco[0]);
    } catch (error) {
      console.log("Error en  edición de proyectos" + error);
    }

    handleCerrarBackDrop();

    //alert("Quitar recurso del proyecto" + codigoRecurso);
  };

  const cellOpciones = (rowInfo) => {
    return (
      <>
        <Tooltip title="Editar" style={{ marginRight: 10, cursor: "pointer" }}>
          <Edit
            style={{ color: "#337ab7", cursor: "pointer" }}
            onClick={() =>
              handleAbrirDialogoEditar(rowInfo.data.CodigoEmpleadoFamiliar)
            }
          />
        </Tooltip>
        <Tooltip
          title="Eliminar"
          style={{ marginRight: 10, cursor: "pointer" }}
        >
          <Delete
            style={{ color: "#337ab7", cursor: "pointer" }}
            onClick={() =>
              handleAbrirDialogoEliminar(rowInfo.data.CodigoEmpleadoFamiliar)
            }
          />
        </Tooltip>
      </>
    );
  };

  return (
    <React.Fragment>
      <Backdrop className={classes.backdrop} open={buscando}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer />

      <Dialog
        open={dialogoAbierto}
        onClose={handleCerrarDialogo}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Eliminación</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro que deseas eliminar este registro?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCerrarDialogo} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={() => quitarProyecto(codigoEmpleadoFamiliar)}
            color="primary"
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={dialogoAbiertoEditar}
        onClose={handleCerrarDialogoEditar}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Edición</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro que deseas editar este registro?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCerrarDialogoEditar} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={() => editarProyecto(codigoEmpleadoFamiliar)}
            color="primary"
          >
            Editar
          </Button>
        </DialogActions>
      </Dialog>

      <Grid container spacing={3}>
        <form
          autoComplete="off"
          onSubmit={handleSubmit(guardarDato, cuandoHayError)}
        >
          <Grid item xs={6} sm={6} md={12} lg={12}>
            <FormControl className={classes.formControlSelects}>
              <Controller
                name="tipoParentesco"
                required
                render={({ TipoParentesco }) => (
                  <Autocomplete
                    id="TipoParentesco"
                    options={maestroTipoParentesco}
                    getOptionLabel={(option) => option.TipoParentesco}
                    value={valueAutoCompleteTipoParentesco}
                    onChange={(event, newValue) =>
                      handleOnChangeTipoParentesco(event, newValue)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Tipo Parentesco"
                        variant="outlined"
                      />
                    )}
                  />
                )}
                control={control}
              />
            </FormControl>
            <FormControl className={classes.formControlSelects}>
              <Controller
                name="nombreCompleto"
                render={({ name }) => (
                  <TextField
                    id="nombreCompleto"
                    label="Nombre Completo"
                    name="nombreCompleto"
                    error={fieldsErrors.nombreCompleto ? true : false}
                    value={nombreCompleto}
                    onChange={(e) => setNombreCompleto(e.target.value)}
                    variant="outlined"
                    helperText={
                      fieldsErrors.nombreCompleto
                        ? fieldsErrors.nombreCompleto.message
                        : ""
                    }
                    required
                    //   rules={{
                    //     required: "Ingrese el nombre completo",
                    //   }}
                    //   inputRef={ref}
                    //   {...inputProps}
                  />
                )}
                control={control}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={6} md={12} lg={12}>
            <FormControl className={classes.formControlSelects}>
              <Controller
                name="telefono"
                render={({ tel }) => (
                  <TextField
                    id="telefono"
                    label="Telefono"
                    name="nombre"
                    value={telefono}
                    onChange={(e) => setTelefono(e.target.value)}
                    variant="outlined"
                    helperText={
                      fieldsErrors.telefono ? fieldsErrors.telefono.message : ""
                    }
                    //   inputRef={refTelefono}
                    //   {...inputPropsTelefono}

                    //   rules={{
                    //     pattern: { value: /[0-9]/, message: "Solo se aceptan números" },
                    //     minLength: { value: 8, message: "No menor a 8 dígitos" },
                    //     maxLength: { value: 8, message: "No mayor a 8 dígitos" },
                    //   }}
                  />
                )}
                control={control}
              />
            </FormControl>
            <FormControl className={classes.formControlSelects}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="flex-start">
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Fecha de Nacimiento"
                    value={fechaNacimiento}
                    onChange={handleDateChangeFechaNacimiento}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    required
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={6} md={12} lg={12}>
            <FormControlLabel
              control={
                <Controller
                  name={"esContactoDeEmergencia"}
                  control={control}
                  defaultValue={false}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) =>
                        setEsContactoDeEmergencia(e.target.checked)
                      }
                    />
                  )}
                />
              }
              label={"Contacto de Emergencia"}
            />
            <FormControlLabel
              control={
                <Controller
                  name={"fallecido"}
                  control={control}
                  defaultValue={false}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => setFallecido(e.target.checked)}
                    />
                  )}
                />
              }
              label={"Fallecido"}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={12} lg={12}>
            <Button
              width="100%"
              variant="contained"
              style={{
                backgroundColor: "#69bd4b",
                marginBottom: 10,
                marginTop: 10,
                color: "white",
                width: "93%",
                marginLeft: 30,
              }}
              type="submit"
            >
              &nbsp;Guardar Cambios
            </Button>
          </Grid>
        </form>
      </Grid>
      <Divider variant="middle" />
      <Paper className={classes.rootTabla}>
        <DataGrid
          dataSource={empleadoFamiliar}
          //   defaultColumns={this.props.columns}
          showBorders={true}
          rowAlternationEnabled={true}
          //ref={(ref) => (dataGrid = ref)}
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          onExporting={onExporting}
          className={classes.table}
        >
          <FilterRow visible={showFilterRow} applyFilter={currentFilter} />
          <HeaderFilter visible={showHeaderFilter} />
          <GroupPanel visible={false} />
          <Grouping autoExpandAll={autoExpandAll} />
          <SearchPanel visible={false} width={240} placeholder="Search..." />
          {/* <Selection mode="multiple" /> */}
          <Editing
            mode="row"
            useIcons={true}
            allowUpdating={false}
            allowDeleting={false}
          />
          <Column
            caption="Opciones"
            allowFiltering={false}
            allowSorting={false}
            cellRender={cellOpciones}
          />
          <Column
            dataField="CodigoEmpleadoFamiliar"
            caption="Código DB"
            dataType="number"
            visible={false}
          />
          <Column
            dataField="CodigoTipoParentesco"
            caption="Código Tipo Parentesco"
            dataType="number"
            visible={false}
          />
          <Column dataField="TipoParentesco" caption="Tipo Parentesco" />
          <Column dataField="NombreCompleto" caption="Nombre Completo" />
          <Column
            dataField="FechaNacimiento"
            caption="Fecha Nacimiento"
            dataType="date"
            format="dd/MM/yyyy"
          />
          <Column
            dataField="EsContactoDeEmergencia"
            caption="Contacto de Emergencia"
            dataType="boolean"
          />
          <Column
            dataField="Fallecido"
            caption="Fallecido"
            dataType="boolean"
          />

          <ColumnFixing enabled={true} />
          <Sorting mode="multiple" />
          <Paging defaultPageSize={10} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[5, 10, 20]}
            showInfo={true}
          />
          <Export enabled={true} allowExportSelectedData={true} />
          <Scrolling columnRenderingMode="virtual" />
        </DataGrid>
      </Paper>
    </React.Fragment>
  );
};

export default EmpleadoFamilia;
