import React, { useState, useEffect, useRef } from "react";
// import ChipIcon from "../../Helpers/components/chip-icon";
import Badge from "@material-ui/core/Badge";
import { makeStyles } from "@material-ui/core/styles";

import Chip from "@material-ui/core/Chip";
import FaceIcon from "@material-ui/icons/Face";
import AssignmentIcon from "@material-ui/icons/Assignment";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Container from "@material-ui/core/Container";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Paper from "@material-ui/core/Paper";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import BlockIcon from "@material-ui/icons/Block";
import Avatar from "@material-ui/core/Avatar";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import Button from "@material-ui/core/Button";
import AttachmentIcon from "@material-ui/icons/Attachment";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";

//OWN
import { convertirHHMM } from "../../utils/helpers";

function iconType(estado, colorEstado) {
  let icono;

  switch (estado) {
    case "Pendiente":
      icono = <WatchLaterIcon style={{ color: colorEstado }} />;
      break;
    case "Completo":
      icono = <CheckCircleIcon style={{ color: colorEstado }} />;
      break;
    case "Cancelado":
      icono = <BlockIcon style={{ color: colorEstado }} />;
      break;
  }
  return icono;
}

export default function TareaCard(props) {
  let {
    numero,
    titulo,
    nombreAsignado,
    codigoEstado,
    estado,
    icono,
    colorEstado,
    ultimo,
    estilo,
    codigoTarea,
    observacion,
    idObjetoPadre,
  } = props;

  const [comentario, setComentario] = useState(observacion);
  const [observacionCopy, setObservacionCopy] = useState(observacion);

  const mounted = useRef();
  useEffect(() => {
    if (!mounted.current) {
      // do componentDidMount
      mounted.current = true;
    } else {
      //alert("update");
      // do componentDidUpdate logic
      setObservacionCopy(observacion);
      if (observacionCopy !== observacion) {
        setComentario(observacion);
      }
    }
  });

  const colorPrincipal =
    props.estilo == "Completo"
      ? "#69BD4B"
      : props.estilo == "Cancelado"
      ? "#e74c3c"
      : "#979797";

  const styles = makeStyles({
    container: { flexDirection: "row" },
    tituloHeader: {
      margin: 10,
    },
  });

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div
          style={{
            backgroundColor: colorEstado,
            color: "#FFF",
            paddingLeft: 10,
            paddingRight: 10,
            paddingTop: 1,
            paddingBottom: 1,
            width: "100%",
          }}
        >
          <h4
            style={{
              color: "#FFF",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Avatar style={{ backgroundColor: "#FFF", color: colorEstado }}>
              {numero}
            </Avatar>

            <span
              onClick={() => {
                props.navegar(codigoTarea, titulo);
              }}
              style={{ cursor: "pointer" }}
            >
              &nbsp;&nbsp;&nbsp;{titulo}
            </span>
          </h4>

          <div style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 5 }}>
            Asignado a:{" "}
            <span style={{ color: "#2980b9" }}>{nombreAsignado}</span>
          </div>
          <div
            style={{
              paddingLeft: 10,
              paddingRight: 10,
              paddingTop: 5,
              paddingBottom: 5,
            }}
          >
            <Chip icon={iconType(estilo, colorEstado)} label={estilo}>
              {iconType(estilo, colorEstado)}
            </Chip>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <Paper
          style={{
            width: "100%",
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 0,
            paddingRight: 0,
            marginBottom: 15,
          }}
        >
          {/* <div
            style={{
              backgroundColor: colorEstado,
              color: "#FFF",
              paddingLeft: 10,
              paddingRight: 10,
              paddingTop: 1,
              paddingBottom: 1,
              width: "100%",
            }}
          >
            <h4
              onClick={() => {
                props.navegar(codigoTarea, titulo);
              }}
              style={{
                color: "#FFF",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div>&nbsp;&nbsp;&nbsp;{titulo}</div>
            </h4>
          </div> */}
          <div style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 5 }}>
            Asignado a:{" "}
          </div>
          <div>
            {props.usuariosAsignados.map((item) => (
              <Chip
                //className={classes.chip}
                icon={<FaceIcon />}
                label={item.Nombre}
                onDelete={() => {
                  props.eliminarUsuario(item.IdObjeto);
                }}
                color="primary"
                style={{ marginRight: 5 }}
              />
            ))}
            <IconButton
              aria-label="Nuevo asignado"
              onClick={() => {
                props.openModalUsuarios(codigoTarea);
              }}
            >
              <PersonAddIcon color="primary" />
            </IconButton>
          </div>
          <div
            style={{
              paddingLeft: 10,
              paddingRight: 10,
              paddingTop: 5,
              marginTop: 15,
            }}
          >
            Etiquetas:{" "}
            <div>
              {props.etiquetasAsignadas.map((item) => (
                <Chip
                  //className={classes.chip}
                  variant="outlined"
                  icon={<LocalOfferIcon />}
                  label={item.Nombre}
                  onDelete={() => {
                    props.eliminarTag(item.IdObjeto);
                  }}
                  color="primary"
                  style={{ marginRight: 5 }}
                />
              ))}
              <IconButton
                aria-label="Nuevo asignado"
                onClick={() => {
                  props.openModalTag(codigoTarea);
                }}
              >
                <AddCircleOutlineIcon color="primary" />
              </IconButton>
            </div>
          </div>

          {/* Adjuntos */}
          <div
            style={{
              paddingLeft: 10,
              paddingRight: 10,
              paddingTop: 5,
              marginTop: 15,
            }}
          >
            Archivos adjuntos:
            <div>
              {props.archivosAdjuntos.map((item) => (
                <Chip
                  //className={classes.chip}
                  icon={<AttachmentIcon />}
                  label={item.NombreArchivo}
                  onClick={() => {
                    window.open(
                      item.uri,
                      "Archivo adjunto",
                      "width=300, height=200"
                    );
                  }}
                  onDelete={() => {
                    props.eliminarAdjuntos(item.IdObjeto);
                  }}
                  color="secondary"
                  style={{ marginRight: 5 }}
                />
              ))}
              <IconButton
                aria-label="Nuevo asignado"
                onClick={() => {
                  props.openModalAdjuntos(codigoTarea);
                }}
              >
                <AddPhotoAlternateIcon color="primary" />
              </IconButton>
            </div>
          </div>

          <div style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 15 }}>
            <form>
              <TextField
                style={{ width: "100%" }}
                id="standard-multiline-flexible"
                variant="outlined"
                label="Comentario"
                multiline
                rowsMax={10}
                value={comentario}
                onChange={(e) => {
                  setComentario(e.target.value);
                  observacion = e.target.value;
                }}
              />
            </form>
          </div>

          <div
            style={{
              marginTop: 15,
              display: codigoEstado > 2 ? "none" : "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<CheckCircleIcon />}
              style={{ marginRight: 10 }}
              onClick={() => {
                props.operarTarea(comentario, codigoTarea, idObjetoPadre, "Ok");
              }}
            >
              Completar
            </Button>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              startIcon={<BlockIcon />}
              style={{ marginRight: 10 }}
              onClick={() => {
                props.operarTarea(
                  comentario,
                  codigoTarea,
                  idObjetoPadre,
                  "Cancel"
                );
              }}
            >
              Cancelar
            </Button>
          </div>
        </Paper>
      </AccordionDetails>
    </Accordion>
  );
}
