import { Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Chart, CommonSeriesSettings, Export, Legend, Series, Tooltip } from 'devextreme-react/chart';
import PieChart, { Connector, Label } from 'devextreme-react/pie-chart';
import React, { useEffect, useState } from "react";
const GraficasCatCosechaFrente = ({ datosAsistencia, cantidadDias, campoAgrupacion }) => {

    const [selectedGroups, setSelectedGroups] = useState([]);
    const [grupos, setGrupos] = useState([]);
    const [datosPorGrupo, setDatosPorGrupo] = useState([]);

    useEffect(() => {
        //obtener lista de agrupaciones distintas, datosAsistencia es el arreglo de asistencias
        let agrupaciones = [];
        datosAsistencia.forEach((asistencia) => {
            if (!agrupaciones.includes(asistencia[campoAgrupacion])) {
                agrupaciones.push(asistencia[campoAgrupacion]);
            }
        });
        setGrupos(agrupaciones);
        console.log("grupos", agrupaciones);
    }, [datosAsistencia]);

    useEffect(() => {
        //obtener datos por agrupación
        let datosPorGrupo = [];
        //Resumen general
        let totalPersonalGeneral = datosAsistencia.filter((asistencia) => selectedGroups.includes(asistencia[campoAgrupacion])).length * cantidadDias;
        let asistenciasGeneral = datosAsistencia.filter((asistencia) => selectedGroups.includes(asistencia[campoAgrupacion])).reduce((total, asistencia) => total + asistencia.Asistencias, 0);
        let inasistenciasGeneral = datosAsistencia.filter((asistencia) => selectedGroups.includes(asistencia[campoAgrupacion])).reduce((total, asistencia) => total + asistencia.Inasistencias, 0);
        let faltasGeneral = datosAsistencia.filter((asistencia) => selectedGroups.includes(asistencia[campoAgrupacion])).reduce((total, asistencia) => total + asistencia.DiasSinRegistro, 0);

        let resumenGeneral = {
            grupo: "General",
            titulo: `General (${datosAsistencia.filter((asistencia) => selectedGroups.includes(asistencia[campoAgrupacion])).length})`,
            totalPersonal: totalPersonalGeneral,
            asistencias: asistenciasGeneral,
            inasistencias: inasistenciasGeneral,
            faltas: faltasGeneral,
        };
        datosPorGrupo.push(resumenGeneral);

        //Resumen por campoAgrupacion
        selectedGroups.forEach((grupo) => {
            let datos = datosAsistencia.filter((asistencia) => asistencia[campoAgrupacion] === grupo);
            let totalPersonal = datos.length * cantidadDias;
            let asistencias = datos.reduce((total, asistencia) => total + asistencia.Asistencias, 0);
            let inasistencias = datos.reduce((total, asistencia) => total + asistencia.Inasistencias, 0);
            let faltas = datos.reduce((total, asistencia) => total + asistencia.DiasSinRegistro, 0);

            let resumenDegrupo = {
                grupo: grupo,
                titulo: `${grupo} (${datos.length})`,
                totalPersonal: totalPersonal,
                asistencias: asistencias,
                inasistencias: inasistencias,
                faltas: faltas,
            };
            datosPorGrupo.push(resumenDegrupo);
        });
        setDatosPorGrupo(datosPorGrupo);
    }, [selectedGroups]);

    //Recuperar grupos seleccionados
    useEffect(() => {
        let gruposSeleccionados = JSON.parse(localStorage.getItem(`${campoAgrupacion}ReporteCosecha`));
        if (gruposSeleccionados) {
            gruposSeleccionados = gruposSeleccionados.sort((a, b) => a.localeCompare(b));
            setSelectedGroups(gruposSeleccionados);
        }
    }, []);

    const handleMultiFreteSelect = (event, value) => {
        console.log("valores multi", value);
        setSelectedGroups(value);
        localStorage.setItem(`${campoAgrupacion}ReporteCosecha`, JSON.stringify(value));
    };

    return (
        <React.Fragment>

            <div style={{ display: 'flex' }}>
                <Autocomplete
                    multiple
                    style={{ flexGrow: 1 }}
                    id="grupos-select"
                    options={grupos}
                    getOptionLabel={(option) => (option ? option : "")}
                    value={selectedGroups}
                    onChange={handleMultiFreteSelect}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Selecciona los elementos para graficar"
                            placeholder="Selecciona los elementos para graficar"
                        />
                    )}
                />
            </div>
            <br />

            <Grid container spacing={3}>

                <Grid item xs={12}>
                    <Chart
                        id="barChart"
                        dataSource={datosPorGrupo}
                    >
                        <CommonSeriesSettings argumentField="grupo" type="fullstackedbar" />
                        <Legend verticalAlignment={true} />
                        <Series
                            valueField="asistencias"
                            argumentField="grupo"
                            name="Asistencias"
                            color="#69bd4b"
                        />
                        <Series
                            valueField="inasistencias"
                            argumentField="grupo"
                            name="Inasistencias"
                            color="#e0e02d"
                        />
                        <Series
                            valueField="faltas"
                            argumentField="grupo"
                            name="Faltas"
                            color="#f21d1d"
                        />
                    </Chart>
                </Grid>
                {datosPorGrupo.length > 0 &&
                    // Por cada grupo, generar una grafica de pie
                    datosPorGrupo.map((grupo) => {
                        return (
                            <Grid key={grupo.grupo} item xs={12} sm={6} md={4} lg={4}>
                                <PieChart
                                    key={grupo.grupo}
                                    id={`pieChart${grupo.grupo}`}
                                    resolveLabelOverlapping="shift"
                                    dataSource={[
                                        { tipo: "Asistencias", valor: grupo.asistencias },
                                        { tipo: "Inasistencias", valor: grupo.inasistencias },
                                        { tipo: "Faltas", valor: grupo.faltas },
                                    ]}
                                    //palette="Bright"
                                    title={grupo.titulo}
                                    customizePoint={(point) => {
                                        if (point.argument === 'Asistencias') {
                                            return { color: '#69bd4b' }; // Change color for 'asistencias'
                                        }
                                        if (point.argument === 'Inasistencias') {
                                            return { color: '#e0e02d' }; // Change color for 'inasistencias'
                                        }
                                        if (point.argument === 'Faltas') {
                                            return { color: '#f21d1d' }; // Change color for 'faltas'
                                        }
                                    }}
                                    onPointClick={(e) => {
                                        //e.target.select();
                                    }}
                                >
                                    <CommonSeriesSettings
                                        argumentField="tipo"
                                        valueField="valor"
                                    >
                                        <Label visible={true}>
                                            <Connector visible={true} width={1} />
                                        </Label>
                                    </CommonSeriesSettings>
                                    <Tooltip enabled={true} />
                                    <Export enabled={true} />
                                    <Legend
                                        verticalAlignment="bottom"
                                        horizontalAlignment="center"
                                        itemTextPosition="right"
                                        rowCount={2}
                                    />
                                    <Series
                                        argumentField="tipo"
                                        valueField="valor"
                                        label={{
                                            visible: true,
                                            customizeText: function (e) {
                                                return `${e.argumentText}: ${e.valueText} (${e.percentText})`;
                                            },
                                        }}

                                    />
                                </PieChart>
                            </Grid>
                        );
                    })
                }
            </Grid>
        </React.Fragment >
    );
}

export default GraficasCatCosechaFrente;