import React, { useState } from 'react'
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField
} from '@material-ui/core'
import { toast } from 'react-toastify'
import { getUsuarioInserta } from '../../utils/utilidades'
import LoadingButton from '../utils/LoadingButton'
import api from '../../../utils/api'

const errorsState = {
  nombre: {
    valor: false,
    mensaje: ''
  },
  descripcion: {
    valor: false,
    mensaje: ''
  }
}

const CrearRol = ({ onClose, onRefresh }) => {
  const [loading, setLoading] = useState(false)
  const [form, setForm] = useState({
    nombre: '',
    descripcion: ''
  })
  const [errors, setErrors] = useState(errorsState)
  const changeForm = (key, value) => {
    const formTmp = { ...form }
    formTmp[key] = value
    setForm(formTmp)
  }
  const validate = () => {
    const tmp = { ...errorsState}
    if (!form.nombre.trim().length) {
      tmp.nombre = {
        valor: true,
        mensaje: 'El campo es requerido'
      }
    }
    if (!form.nombre.toUpperCase().startsWith('GESDOC')) {
      tmp.nombre = {
        valor: true,
        mensaje: 'El nombre debe incluir el prefijo GESDOC'
      }
    }
    if (form.descripcion.trim().length == 0) {
      tmp.descripcion = {
        valor: true,
        mensaje: 'El campo es requerido'
      }
    }
    setErrors(tmp)
    let valid = true
    for (const key in tmp) {
      if (tmp[key].valor) {
        valid = false
      }
    }
    return valid
  }
  const addRol = async () => {
    try {
      if (validate()) {
        setLoading(true)
        const body = {
          Rol: form.nombre,
          Descripcion: form.descripcion,
          UsuarioInserto: getUsuarioInserta()
        }
        await api.post(`api/desktop/rol`, body)
        onRefresh()
      }
    } catch (error) {
      toast.error('Ha ocurrido un error')
    } finally {
      setLoading(false)
    }
  }
  return <>
    <DialogTitle>Crear rol</DialogTitle>
    <DialogContent>
      <TextField
        fullWidth
        label="Nombre"
        value={form.nombre}
        onChange={ev => changeForm('nombre', ev.target.value)}
        error={errors.nombre.valor}
        helperText={errors.nombre.mensaje}
      />
      <TextField
        fullWidth
        multiline
        rows={2}
        label="Descripción"
        value={form.descripcion}
        onChange={ev => changeForm('descripcion', ev.target.value)}
        error={errors.descripcion.valor}
        helperText={errors.descripcion.mensaje}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancelar</Button>
      <LoadingButton
        color="primary"
        loading={loading}
        onClick={addRol}
      >
        Guardar
      </LoadingButton>
    </DialogActions>
  </>
}

export default CrearRol
