import * as React from 'react';
import { DataGrid } from '@material-ui/data-grid';

const columns = [
  { field: 'id', headerName: 'Column1', width: 70 },
  { field: 'firstName', headerName: 'Column2', width: 130 },
  { field: 'lastName', headerName: 'Column3', width: 130 },
  {
    field: 'age',
    headerName: 'Column4',
    type: 'number',
    width: 90,
  },
  {
    field: 'Column4',
    headerName: 'Column5',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 160,
    valueGetter: (params) =>
      `${params.getValue('firstName') || ''} ${params.getValue('lastName') || ''}`,
  },
];

const rows = [
  { id: 1, lastName: 'Sample data', firstName: 'Sample data', age: 35 },
  { id: 2, lastName: 'Sample data', firstName: 'Sample data', age: 42 },
  { id: 3, lastName: 'Sample data', firstName: 'Sample data', age: 45 },
  // { id: 4, lastName: 'Sample data', firstName: 'Sample data', age: 16 },
  // { id: 5, lastName: 'Sample data', firstName: 'Sample data', age: null },
  // { id: 6, lastName: 'Sample data', firstName: null, age: 150 },
  // { id: 7, lastName: 'Sample data', firstName: 'Sample data', age: 44 },
  // { id: 8, lastName: 'Sample data', firstName: 'Sample data', age: 36 },
  // { id: 9, lastName: 'Sample data', firstName: 'Sample data', age: 65 },
];

export default function DataGridSample() {
  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid rows={rows} columns={columns} pageSize={5} disabled
      // checkboxSelection
       />
    </div>
  );
}