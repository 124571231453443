import React from "react";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: 10,
    marginTop: 10,
  },
  square: {
    backgroundColor: "#f8f9fa",
    borderRadius: 4,
  },
  content: {
    padding: 8,
  },
}));

export default function Parrafo(props) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.square}>
        <div className={classes.content}>{props.children}</div>
      </div>
    </div>
  );
}
