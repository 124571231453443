import React,{useState,useEffect} from "react";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';


const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  propioContent: {
    display: "flex",
    justifyContent: "flex-end",
    padding: 2,
    width: "100%",
  },
  propio: {
    width: "50%",
    maxWidth:"50%",
    backgroundColor: "#dcf8c6",
    margin: 5,
    padding: 5,
    marginBottom:2.5,
    wordWrap:"break-word",
    
    },
  ajenoContent: {
    display: "flex",
    justifyContent: "flex-start",
    padding: 2,
  },
  ajeno: {
    width: "50%",
    backgroundColor: "#fff",
    margin: 5,
    padding: 5,
    marginBottom:2.5,
    wordWrap:"break-word",
   
  },
  fechaHora: {
    color: "rgba(0,0,0,.8)",
    fontSize: "xx-small",
    textAlign:"right",
  },
  autor: {
    color: "#e74c3c",
    fontSize: "x-small",
    fontWeight:"bold",
  },
}));
export default function Mensaje (props){
     const classes = useStyles();
    return (
      <div
        className={props.propio ? classes.propioContent : classes.ajenoContent}
      >
        <Paper className={props.propio ? classes.propio : classes.ajeno}>
          {!props.propio && <div className={classes.autor}>{props.autor}</div>}
          <div>{props.mensaje}</div>
          <div className={classes.fechaHora} >
            {props.fecha + " " + props.hora}
          </div>
        </Paper>
      </div>
    );
}