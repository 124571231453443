import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";

import DetalleTarea from "../components/detalle-tarea";
import ListadoTareas from "../components/listado-tareas";
import SimpleBreadcrumbs from "../components/tareas-breadcrumb";

export default function TareasContainer(props) {
  const [codigoTarea, setCodigoTarea] = useState(null);
  const [refresh, setRefresh] = useState(1);
  const [codigoTareaSubTareas, setCodigoTareaSubTareas] = useState(null);
  const [breadCrumbs, setBreadCrumbs] = useState([{ id: 0, nombre: "Raíz" }]);

  //Cuando vengamos del selector de tareas, activamos la tarea en el parámetro por defecto
  useEffect(() => {
    console.log("codigoTarea automático", props.tareaSeleccionada);
    if (props.tareaSeleccionada) {
      setCodigoTarea(props.tareaSeleccionada);
    }
  }, [props.codigoTarea]);


  const onClickTarea = (tarea) => {
    console.log("tarea", tarea);
    setCodigoTarea(tarea.CodigoAvisoTarea);
  };

  const onClickSubTareas = (tarea) => {
    let id = 0,
      nombre = "";
    if (tarea.AvisoTarea == null) {
      id = tarea.CodigoAvisoTarea;
      nombre = tarea.Titulo;
    } else {
      id = tarea.AvisoTarea.CodigoAvisoTarea;
      nombre = tarea.AvisoTarea.Titulo;
    }
    setBreadCrumbs([
      ...breadCrumbs,
      {
        id: id,
        nombre: nombre,
      },
    ]);
    setCodigoTareaSubTareas(id);
    setCodigoTarea(null);
  };

  const onClickBreadcrumb = (tarea) => {
    console.log(tarea);
    setCodigoTareaSubTareas(tarea.id);
    setCodigoTarea(null);

    var newBreadCrumbs = [];
    for (var i = 0; i < breadCrumbs.length; i++) {
      newBreadCrumbs = [...newBreadCrumbs, breadCrumbs[i]];
      setBreadCrumbs(newBreadCrumbs);
      if (breadCrumbs[i].id === tarea.id) {
        break;
      }
    }
  };

  return (
    <div>
      {breadCrumbs.length > 1 && (
        <SimpleBreadcrumbs
          onClickBreadcrumb={onClickBreadcrumb}
          niveles={breadCrumbs}
        />
      )}

      {!props.verTareasGrid && (
        <Grid container spacing={4}>
          <Grid item xs={12} sm={3} spacing={4}>
            <ListadoTareas
              onClickTarea={(tarea) => onClickTarea(tarea)}
              onClickSubTareas={(tarea) => onClickSubTareas(tarea)}
              tareaSeleccionada={codigoTarea}
              refresh={refresh}
              codigoTareaSubTareas={codigoTareaSubTareas}
              verTareasGrid={props.verTareasGrid}
            />
          </Grid>
          <Grid item xs={12} sm={9}>
            <DetalleTarea
              haySubtareas={true}
              codigoTarea={codigoTarea}
              refrescarTareas={() => setRefresh(refresh + 1)}
              onClickSubTareas={(tarea) => onClickSubTareas(tarea)}
              detallePermisoUsuarioActual={props.detallePermisoUsuarioActual}
            />
          </Grid>
        </Grid>
      )}
      {props.verTareasGrid && (
        <Grid container>
          <Grid item xs={6} sm={6} md={9} lg={12}>
            <ListadoTareas
              onClickTarea={(tarea) => onClickTarea(tarea)}
              onClickSubTareas={(tarea) => onClickSubTareas(tarea)}
              tareaSeleccionada={codigoTarea}
              refresh={refresh}
              codigoTareaSubTareas={codigoTareaSubTareas}
              verTareasGrid={props.verTareasGrid}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={9} lg={12}>
            <DetalleTarea
              haySubtareas={true}
              codigoTarea={codigoTarea}
              refrescarTareas={() => setRefresh(refresh + 1)}
              onClickSubTareas={(tarea) => onClickSubTareas(tarea)}
              detallePermisoUsuarioActual={props.detallePermisoUsuarioActual}
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
}
