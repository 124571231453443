import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import { render } from "react-dom";
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { DatePicker, MuiPickersUtilsProvider, Day } from "material-ui-pickers";
import Fab from "@material-ui/core/Fab";
import MaskedInput from "react-text-mask";
import DetailsIcon from "@material-ui/icons/Details";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
//Select
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";

//Checkbox
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import "../../plantillaProblema/styles.css";
import Loader from "react-loader-spinner";

//Drawer
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import DeleteForeverRoundedIcon from "@material-ui/icons/DeleteForeverRounded";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import ConfirmDialog from "../../ConfirmDialog/ConfirmDialog";
import CancelIcon from "@material-ui/icons/Cancel";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import CardContent from "@material-ui/core/CardContent";

//Tabs
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import PerfectScrollbar from "react-perfect-scrollbar";

//chip
import ChipInput from "material-ui-chip-input";

//Own components
import Layout from "../../layout/containers/Layout";
import API from "../../utils/api";
import { isSignedIn } from "../../utils/auth";
import {
  convertirASegundos,
  convertToYearMonthDay,
  convertDMY,
  convertYMD,
  convertirHHMM,
} from "../../utils/helpers";
import InputAdornment from "@material-ui/core/InputAdornment";
import GrupoCard from "../components/grupo-card";
import AddButton from "../../helpers/components/add-button-float";
import UsuariosList from "../../avisos-objects/components/usuarios-list";
//Lista de usuarios en chips
import UserList from "../components/user-list";
import UsuariosTable from "../components/usuarios-table";
import {validaPermisoPaginaActual} from "../../utils/helpers";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  paperUserModal: {
    position: "absolute",
    width: "75%",
    height: "600px",
    maxHeight: "600px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
  paperPopover: {
    padding: theme.spacing(1),
    background: "#000",
    color: "#fff",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },

  paperPopover: {
    padding: theme.spacing(1),
    background: "#000",
    color: "#fff",
  },
  rootAppbar: {
    flexGrow: 1,
  },
  root: {
    //flexGrow: 1,
    width: "100%",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    // backgroundColor: fade(theme.palette.common.white, 0.15),
    // "&:hover": {
    //   backgroundColor: fade(theme.palette.common.white, 0.25),
    // },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  rootAppbar: {
    flexGrow: 1,
  },

  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },

  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

// const theme = useTheme();

class UsuarioGrupo extends Component {
  state = {
    open: false,
    openToolbar: false,
    actionType: "", //Crear, Actualizar
    usuarios: [],
    usuariosGrupo: [],
    usuariosGrupoCopia: [],
    codigoGrupo: 0,
    openAsignarUsuarios: false,
    txtTextoBusquedaUsuario: "",
    nombre: "",
    descripcion: "",
    activo: false,
    txtFiltroUsuario: "",
  };

  async componentWillMount() {
    //CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
    const llaveAgrupacion = "Menu.Principal.Web";
    const llavePagina = "Menu.Principal.Web.Grupos";
    await validaPermisoPaginaActual(llaveAgrupacion, llavePagina);
    //FIN DE CONSULTA DE PERMISOS


    if (
      this.props.match.params.codigoGrupo === undefined ||
      this.props.match.params.codigoGrupo === null
    ) {
      this.props.history.push("/home");
      return;
    }

    this.setState({ codigoGrupo: this.props.match.params.codigoGrupo }, () => {
      this.cargarListaUsuariosGrupo();
      this.cargarDatosEdicion();
    });
  }

  async cargarDatosEdicion() {
    const request = await API.fetchGetRequest(
      `api/grupoUsuarioNotificacion/cargarDatosEdicion/${this.state.codigoGrupo}`
    );
    this.setState({
      nombre: request.data.data[0].Nombre,
      descripcion: request.data.data[0].Descripcion,
      activo: request.data.data[0].Activo,
    });
  }

  async cargarListaUsuariosGrupo() {
    try {
      this.setState({ cargando: true });

      const request = await API.fetchGetRequest(
        `api/grupoUsuarioNotificacion/listarUsuario/${this.state.codigoGrupo}`
      );
      this.setState({
        usuariosGrupo: request.data.data,
        usuariosGrupoCopia: request.data.data,
      });
      if (request.statusCode != 200) {
        alert(
          "Hubo un error al realizar la operación, consulta con un administrador"
        );
      }

      console.log(request.statusCode);
      if (request.statusCode === 401) {
        //alert("cerrar sesion");
        window.location.href = "/login";
      } else {
        if (Array.isArray(request.data.data)) {
          this.setState({
            listaObjetos: request.data.data,
          });
        }
      }
    } catch (error) {
      console.log(error);
      if (error.toString().includes("401")) {
        window.location.href = "/login";
      }
    } finally {
      this.setState({ cargando: false });
    }
  }

  handleOpenAsignarUsuarios = (codigoGrupo) => {
    this.setState({
      openAsignarUsuarios: true,
      // codigoGrupoModal: codigoGrupo,
    });
  };

  handleCloseAsignarUsuarios = () => {
    this.setState({
      openAsignarUsuarios: false,
      txtTextoBusquedaUsuario: "",
      codigoGrupoModal: "",
      listaUsuarios: [],
    });
  };

  cargarListaUsuarios = async () => {
    let criterio =
      this.state.txtTextoBusquedaUsuario === "" ||
      this.state.txtTextoBusquedaUsuario === null ||
      this.state.txtTextoBusquedaUsuario === undefined
        ? "x-x"
        : this.state.txtTextoBusquedaUsuario;

    const request = await API.fetchGetRequest(`api/Usuario/buscar/${criterio}`);
    try {
      if (Array.isArray(request.data.usuarios)) {
        this.setState({
          usuarios: request.data.usuarios,
        });
      } else {
        this.setState({
          usuarios: [],
        });
      }
    } catch (error) {
      console.log("cargarListaTareas error:" + error);
    }
  };

  seleccionarUsuario = async (codigoUsuario) => {
    try {
      let vIsSignedIn = await isSignedIn();

      if (vIsSignedIn.response) {
        let data = {
          CodigoGrupo: this.state.codigoGrupo,
          CodigoUsuario: codigoUsuario,
          usuarioOpera: vIsSignedIn.userName,
        };
        const request = await API.post(
          `api/grupoUsuarioNotificacion/agregarUsuario`,
          data
        );
        if (request.status != 200) {
          alert(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        }

        if (request.statusCode === 401) {
          //alert("cerrar sesion");
        } else {
          toast.success("Usuario asignado exitosamente.");

          this.cargarListaUsuariosGrupo();
        }
      } else {
        toast.error("La sessión no se encuentra activa");
      }
    } catch (e) {
      alert(e);
    } finally {
      this.handleCloseAsignarUsuarios();
    }
  };

  removerUsuario = async (codigoGrupoUsuario) => {
    try {
      let vIsSignedIn = await isSignedIn();

      if (vIsSignedIn.response) {
        const request = await API.post(
          `api/grupoUsuarioNotificacion/removerUsuario/${codigoGrupoUsuario}/${vIsSignedIn.userName}`
        );
        if (request.status != 200) {
          alert(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        }

        if (request.statusCode === 401) {
          //alert("cerrar sesion");
        } else {
          toast.success("El usuario se removió exitosamente.");

          this.cargarListaUsuariosGrupo();
        }
      } else {
        toast.error("La sessión no se encuentra activa");
      }
    } catch (e) {
      alert(e);
    } finally {
      this.setState({ codigoTareaEliminar: "" });
    }
  };

  handleChange = (name) => (event) => {
    if (name === "activo" || name === "requeridoCampo") {
      this.setState({ [name]: event.target.checked });
    } else if (name === "txtFiltroUsuario") {
      this.setState(
        {
          [name]: event.target.value,
        },
        async () => {}
      );
    } else {
      this.setState({
        [name]: event.target.value,
      });
    }
  };

  enterFiltro = async () => {
    let filtro = await this.filtrarListaMiembros();
    this.setState({ usuariosGrupoCopia: filtro });
  };

  filtrarListaMiembros = async () => {
    let filtro = [];
    let textoBusqueda = this.state.txtFiltroUsuario.trim();

    if (textoBusqueda === "") {
      filtro = this.state.usuariosGrupo;
    } else {
      filtro = this.state.usuariosGrupo.filter((fila) => {
        return fila.NombreCompletoUsuario.toLowerCase().includes(
          textoBusqueda.toLowerCase()
        );
      });
    }

    return filtro;
  };

  render() {
    const { classes } = this.props;
    const { grupos } = this.state;
    return (
      <Layout titulo={`Miembros de ${this.state.nombre}`}>
        {this.state.cargando ? (
          <div
            style={{
              width: "100%",
              marginTop: 10,
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loader
              type="Oval"
              color="#3498db"
              height="50"
              width="50"
              style={{ textAlign: "center" }}
            />
          </div>
        ) : (
          <></>
        )}
        <Container maxWidth="md">
          <div className={classes.root}>
            <Grid container spacing={3}>
              <UsuariosTable
                rows={this.state.usuariosGrupo}
                removerUsuario={this.removerUsuario}
              />
            </Grid>
          </div>
          <AddButton
            onClick={() => {
              this.handleOpenAsignarUsuarios();
            }}
          />
        </Container>

        <Modal
          // style={{ zIndex: 2990 }}
          aria-labelledby="Asignar usuarios"
          aria-describedby="Asignar usuarios"
          open={this.state.openAsignarUsuarios}
          onClose={this.handleCloseAsignarUsuarios}
        >
          <div style={getModalStyle()} className={classes.paperUserModal}>
            <PerfectScrollbar>
              <form className={classes.container} noValidate autoComplete="off">
                <Typography variant="h6" gutterBottom>
                  Asignar usuario(s)
                </Typography>

                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextField
                      id="txtTextoBusquedaUsuario"
                      label="Usuario"
                      className={classes.textField}
                      value={this.state.txtTextoBusquedaUsuario}
                      onChange={this.handleChange("txtTextoBusquedaUsuario")}
                      onKeyPress={(ev) => {
                        console.log("keyP: " + ev.key);
                        if (ev.key === "Enter") {
                          this.cargarListaUsuarios();
                          ev.preventDefault();
                        }
                      }}
                      margin="normal"
                      inputProps={{ maxLength: 512 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#217ecf",
                        color: "white",
                        width: "100%",
                      }}
                      className={classes.buttonAdd}
                      onClick={() => this.cargarListaUsuarios()}
                    >
                      Buscar
                    </Button>
                  </Grid>
                </Grid>

                <Grid item xs={12} lg={12} sm={12}>
                  <Grid container spacing={1}>
                    <UsuariosList
                      registros={this.state.usuarios}
                      seleccionarUsuario={this.seleccionarUsuario}
                    />
                  </Grid>
                </Grid>
              </form>
            </PerfectScrollbar>
          </div>
        </Modal>

        <ConfirmDialog
          id="confirmar"
          keepMounted
          open={this.state.confirmOpen}
          onClose={this.cerrarConfirm}
          value=""
          texto={this.state.confirmTexto}
          okfunction={this.funcionOk}
        />
        <ToastContainer />
      </Layout>
    );
  }
}
const UsuarioGrupoContainer = withStyles(styles)(UsuarioGrupo);
export default withRouter(UsuarioGrupoContainer);
