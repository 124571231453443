import { Center } from "devextreme-react/map";
import React from "react";
const ProgressBar = (props) => {
    const { completed } = props;
  
    const containerStyles = {
      height: 20,
      width: '100%',
      backgroundColor: "#e0e0de",
      borderRadius: 50,
    }
    function colorBarra  (){
        if(completed < 40) return "#F63939"
        else if(completed < 79) return "gold"
        else if(completed <= 100) return "#29CF03"
        return "#e0e0de"
    }

    const fillerStyles = {
      height: '100%',
      width: `${completed}%`,
      backgroundColor: colorBarra(),
      borderRadius: 'inherit',
      textAlign: 'right',
      transition: 'width 1s ease-in-out',
       
    }
  
    const labelStyles = {
      textAlign: "center",
      color: 'white',
      fontWeight: 'bold',
      fontSize: 14,
      justifyContent: "center",
      margin: 4,
    }

  
    return (
      <div style={containerStyles}>
        <div style={fillerStyles}>
         <center> <span style={labelStyles}>{`${completed}%`}</span></center>
        </div>
      </div>
    );
  };
  
  export default ProgressBar;