import React from "react";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  text: {
    whiteSpace: "pre",
    fontSize: 28,
    fontFamily:
      "'Segoe UI Light', 'Helvetica Neue Light', 'Segoe UI', 'Helvetica Neue', 'Trebuchet MS', Verdana, sans-serif",
    fontWeight: 200,
    fill: "#232323",
    cursor: "default",
  },
}));

export default function TituloChart(props) {
  const classes = useStyles();

  return <text className={classes.text}>{props.children}</text>;
}
