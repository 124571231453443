import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Rating from "@material-ui/lab/Rating";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import Box from "@material-ui/core/Box";

import Grid from "@material-ui/core/Grid";
import { IOSSwitch } from "../../helpers/components/ios-switch";

//Own Components
import API from "../../utils/api";
import { isSignedIn } from "../../utils/auth";
import CalificacionDetalleCard from "../components/calificacion-detalle-card";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  tituloPaper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
});

class CalificacionInput extends React.Component {
  state = {
    open: false,
    codigoGestion: null,
    codigoUsuarioActual: null,
    codigoUsuarioAfectado: null,
    estadoGestion: 10,
    calificacion: 10,
    comentario: "",
    llenoEncuestaCalificacion: true,
    calificacionDecorada: "(10/10)",
    colorIcono: "#2ecc71",
    colorTexto: "#2ecc71",
    icono: "verySatisfied",
    aplicaEncuestaSatisfaccion: false,
    hover: -1,
    labels: {
      1: "Terrible",
      2: "Malo+",
      3: "Meh",
      4: "Ok",
      5: "Bueno",
      6: "Hmm...",
      7: "Muy bueno",
      8: "Wow",
      9: "Me gustó",
      10: "Más que Increible",
    },
  };

  async componentWillMount() {
    if (this.props.codigoGestion !== null) {
      const session = await isSignedIn();
      this.setState(
        {
          codigoGestion: this.props.codigoGestion,
          codigoUsuarioActual: session.codigoUsuario,
        },
        () => {
          this.consultarCalificacion();
        }
      );
    }
  }

  async componentDidUpdate() {
    if (this.props.codigoGestion !== null) {
      if (this.state.codigoGestion !== this.props.codigoGestion) {
        const session = await isSignedIn();
        this.setState(
          {
            codigoGestion: this.props.codigoGestion,
            codigoUsuarioActual: session.codigoUsuario,
          },
          () => {
            this.consultarCalificacion();
          }
        );
      }
    }
  }

  consultarCalificacion = async () => {
    const request = await API.fetchGetRequest(
      "api/aviso/obtenerCalificacion/" + this.state.codigoGestion
    );
    console.log("request", request.data.calificacion);
    console.log("state califi", this.state.calificacion);
    console.log("decor", this.state.calificacionDecorada);
    console.log("color", this.state.colorIcono);
    this.setState(
      {
        codigoUsuarioAfectado: request.data.calificacion.CodigoUsuarioAfectado,
        estadoGestion: request.data.calificacion.CodigoEstado,
        calificacion:
          request.data.calificacion.EncuestaSatisfaccionCalificacion === null
            ? this.state.calificacion
            : request.data.calificacion.EncuestaSatisfaccionCalificacion,
        comentario: request.data.calificacion.EncuestaSatisfaccionComentario,
        llenoEncuestaCalificacion:
          request.data.calificacion.LlenoEncuestaSatisfaccion,
        calificacionDecorada:
          request.data.calificacion.CalificacionDecorada === null
            ? this.state.calificacionDecorada
            : request.data.calificacion.CalificacionDecorada,
        colorIcono:
          request.data.calificacion.ColorIcono === null
            ? this.state.colorIcono
            : request.data.calificacion.ColorIcono,
        colorTexto:
          request.data.calificacion.ColorTexto === null
            ? this.state.colorTexto
            : request.data.calificacion.ColorTexto,
        icono:
          request.data.calificacion.Icono === null
            ? this.state.icono
            : request.data.calificacion.Icono,
        aplicaEncuestaSatisfaccion:
          request.data.calificacion.EncuestaSatisfaccion === null
            ? false
            : request.data.calificacion.EncuestaSatisfaccion,
      },
      () => {
        //Abre modal automáticamente al cumplir las condiciones
        if (
          this.state.estadoGestion === 3 &&
          this.state.codigoUsuarioAfectado.toString() ===
            this.state.codigoUsuarioActual &&
          (this.state.llenoEncuestaCalificacion === null
            ? false
            : this.state.llenoEncuestaCalificacion) === false &&
          this.state.aplicaEncuestaSatisfaccion
        ) {
          this.setState({ open: true });
        }
      }
    );
  };

  handleClose = () => {
    this.setState({
      open: false,
      actionType: "",
    });
  };

  handleOpenAddModal = () => {
    this.setState({
      actionType: "Agregar",
      open: true,
    });
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  guardar = async () => {
    const { calificacion, comentario, codigoGestion, codigoUsuarioActual } =
      this.state;

    if (calificacion === null || calificacion < 1) {
      toast.warn("Por favor selecciona una cantidad de estrellas.");
      return;
    }

    // if (comentario === null || comentario.trim() === "") {
    //   toast.warn("Por favor deja un comentario.");
    //   return;
    // }

    try {
      let vIsSignedIn = await isSignedIn();

      if (vIsSignedIn.response) {
        let request;

        const data = {
          CodigoAviso: codigoGestion,
          Calificacion: calificacion,
          Comentario: comentario,
          CodigoUsuarioOpera: codigoUsuarioActual,
        };

        request = await API.post(`api/aviso/calificar`, data);
        if (request.status != 200) {
          alert(
            "Hubo un error al guardar el registro, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
          return;
        }

        if (request.status === 401) {
          //alert("cerrar sesion");
        } else {
          this.setState({ open: false });
          this.consultarCalificacion();
        }
      }
    } catch (e) {
      alert("hubo un error");
    }
  };

  render() {
    const { classes } = this.props;
    let { data } = this.state;

    return (
      <div>
        {this.state.estadoGestion === 3 &&
          this.state.codigoUsuarioAfectado.toString() ===
            this.state.codigoUsuarioActual &&
          (this.state.llenoEncuestaCalificacion === null
            ? false
            : this.state.llenoEncuestaCalificacion) === false &&
          this.state.aplicaEncuestaSatisfaccion && (
            <div>
              <div class={classes.tituloPaper}>
                <div class={classes.gestionGeneral}>
                  <Typography variant="h5" gutterBottom>
                    Encuesta de satisfacción
                  </Typography>
                </div>
              </div>

              <Button
                variant="contained"
                style={{
                  backgroundColor: "rgb(33, 150, 243)",
                  color: "white",
                  //width: "100%",
                }}
                className={classes.buttonAdd}
                onClick={() => this.handleOpenAddModal()}
              >
                Deja tu opinión
              </Button>
            </div>
          )}
        {this.state.estadoGestion === 3 &&
          this.state.llenoEncuestaCalificacion === true && (
            <CalificacionDetalleCard
              calificacion={
                this.state.calificacion === null ? 0 : this.state.calificacion
              }
              comentario={
                this.state.comentario === null ? "" : this.state.comentario
              }
              colorTexto={this.state.colorTexto}
              colorIcono={this.state.colorIcono}
              calificacionDecorada={this.state.calificacionDecorada}
              icono={this.state.icono}
            />
          )}

        <br />
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.open}
          onClose={this.handleClose}
        >
          <div style={getModalStyle()} className={classes.paper}>
            <form className={classes.container} noValidate autoComplete="off">
              <div class={classes.tituloPaper} /*style={{margin:"auto"}}*/>
                <div>
                  <Typography variant="h5" gutterBottom>
                    Encuesta de satisfacción
                  </Typography>
                </div>
              </div>

              <Box
                component="fieldset"
                mb={3}
                borderColor="transparent"
                style={{ paddingLeft: 0, textAlign: "center" }}
              >
                <Typography component="legend">
                  Indica tu satisfacción de 1 a 10 estrellas.
                </Typography>
                <div>
                  {this.state.calificacion !== null && (
                    <Box style={{ marginTop: 15, textAlign: "center" }} ml={2}>
                      {
                        this.state.labels[
                          this.state.hover !== -1
                            ? this.state.hover
                            : this.state.calificacion
                        ]
                      }
                    </Box>
                  )}
                </div>
                <Rating
                  name="customized-10"
                  defaultValue={10}
                  max={10}
                  onChange={(event, newValue) => {
                    this.setState({ calificacion: newValue });
                  }}
                  onChangeActive={(event, newHover) => {
                    this.setState({ hover: newHover });
                  }}
                />
              </Box>

              <TextField
                id="txtComentario"
                label="Comentario"
                className={classes.textField}
                value={this.state.comentario}
                onChange={this.handleChange("comentario")}
                margin="normal"
                variant="outlined"
                multiline
                rows={4}
                required
              />

              <Button
                variant="contained"
                style={{
                  backgroundColor: "rgb(33, 150, 243)",
                  color: "white",
                  width: "100%",
                }}
                className={classes.buttonAdd}
                onClick={() => this.guardar()}
              >
                Enviar
              </Button>
            </form>
          </div>
        </Modal>
        <ToastContainer />
      </div>
    );
  }
}

// We need an intermediary variable for handling the recursive nesting.
const CalificacionInputContainer = withStyles(styles)(CalificacionInput);
export default withRouter(CalificacionInputContainer);
