import React, { useEffect, useState } from "react";

import {
  Box,
  Typography,
  TabScrollButton,
  AppBar,
  Tooltip,
  Paper,
  Tabs,
  Tab,
  Modal,
  Backdrop,
  Fade,
  CircularProgress,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Toolbar,
  IconButton,
  Grid,
  FormControl,
} from "@material-ui/core";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Add, PlayCircleFilled } from "@material-ui/icons";
import DateFnsUtils from "@date-io/date-fns";
import DataGrid, {
  Column,
  Pager,
  Paging,
  Sorting,
  ColumnFixing,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Grouping,
  GroupPanel,
  Export,
  Selection,
  Editing,
  Scrolling,
  LoadPanel,
} from "devextreme-react/data-grid";

import { Form } from "devextreme-react";

import { Controller, useForm } from "react-hook-form";
import { Autocomplete } from "@material-ui/lab";
import { isSignedIn, signOut } from "../../utils/auth";
import API from "../../utils/api";
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Asegúrate de que el formulario ocupe todo el ancho del modal
  },
  modal: {
    // display: "flex",
    // alignItems: "center",
    // justifyContent: "center",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: "10%",
    left: "20%",
    overflow: "scroll",
    height: "90%",
    display: "block",
    padding: theme.spacing(8, 4, 8, 8),
  },
  paperModal: {
    position: "absolute",
    width: "90%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  formControlSelects: {
    margin: theme.spacing(1),
    // minWidth: 480,

    // marginBottom: 10,
    // marginTop: 10,
    width: "90%",
    // marginLeft: 20,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const a11yProps = (index) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
      inert={value !== index ? "true" : undefined}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={"span"} variant={"body2"}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const MyTabScrollButton = withStyles((theme) => ({
  root: {
    width: 28,
    overflow: "hidden",
    transition: "width 0.5s",
    "&.Mui-disabled": {
      width: 0,
    },
  },
}))(TabScrollButton);

const RequerimientoPersonalComponente = ({ codigoUnidadOrganizacion }) => {
  const classes = useStyles();
  let applyFilterTypes = [
    {
      key: "auto",
      name: "Immediately",
    },
    {
      key: "onClick",
      name: "On Button Click",
    },
  ];

  const {
    control,
    handleSubmit,
    formState: { errors: fieldsErrors },
  } = useForm();
  const [editarCompleto, setEditarCompleto] = useState(false);
  const [tab, setTab] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [buscando, setBuscando] = useState(false);
  const [userName, setUserName] = useState("");
  const [maestroJefeInmediato, setMaestroJefeInmediato] = useState([]);
  const [dpiJefeInmediato, setDpiJefeInmediato] = useState("");
  const [valueAutoCompleteJefeInmediato, setValueAutoCompleteJefeInmediato] =
    useState(null);
  const [maestroPlazas, setMaestroPlazas] = useState([]);
  const [codigoPlazaLaboral, setCodigoPlazaLaboral] = useState(0);
  const [valueAutoCompletePlazaLaboral, setValueAutoCompletePlazaLaboral] =
    useState(null);

  const [fechaLimite, setFechaLimite] = useState(new Date());
  const [reemplazaPlaza, setReemplazaPlaza] = useState(false);
  const [maestroPersonaReemplaza, setMaestroPersonaReemplaza] = useState([]);
  const [dpiReemplazado, setDpiReemplazado] = useState("");
  const [valueAutoCompleteReemplazado, setValueAutoCompleteReemplazado] =
    useState(null);

  const [maestroTipoContrato, SetMaestroTipoContrato] = useState([]);
  const [codigoTipoContrato, setCodigoTipoContrato] = useState(0);
  const [valueAutoCompleteTipoContrato, setValueAutoCompleteTipoContrato] =
    useState(null);

  const [fechaInicio, setFechaInicio] = useState(new Date());
  const [fechaFin, setFechaFin] = useState(new Date());
  const [tienePersonalACargo, setTienePersonalACargo] = useState(false);
  const [cantidadPlazas, setCantidadPlazas] = useState(0);

  const [maestroTipoJornadaTrabajo, setMaestroTipoJornadaTrabajo] = useState(0);
  const [
    valueAutoCompleteTipoJornadaTrabajo,
    setValueAutoCompleteTipoJornadaTrabajo,
  ] = useState(null);
  const [codigoTipoJornadaTrabajo, setCodigoTipoJornadaTrabajo] = useState(0);
  const [horarioEntrada, setHorarioEntrada] = useState(new Date());
  const [horarioSalida, setHorarioSalida] = useState(new Date());

  const [dialogoAbiertoIniciarSolicitud, setDialogoAbiertoIniciarSolicitud] =
    useState(false);
  const [codigoRequerimientoPersonal, setCodigoRequerimientoPersonal] =
    useState(null);
  const [requerimientoPersonalSolicitud, setRequerimientoPersonalSolicitud] =
    useState([]);
  const [
    requerimientoPersonalSolicitudEjecutado,
    setRequerimientoPersonalSolicitudEjecutado,
  ] = useState([]);

  const handleNuevaPlaza = () => {
    handleOpenModal();
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleCerrarBackDrop = () => {
    setBuscando(false);
  };
  const handleAbrirBackDrop = () => {
    setBuscando(true);
  };

  const handleChangeAutoCompleteJefeInmediato = async (event, newValue) => {
    handleAbrirBackDrop();
    if (newValue !== null) {
      setValueAutoCompleteJefeInmediato(newValue);
      setDpiJefeInmediato(newValue.DPI);
    }
    handleCerrarBackDrop();
  };

  const handleChangeAutoCompletePlazaLaboral = async (event, newValue) => {
    handleAbrirBackDrop();
    if (newValue !== null) {
      setValueAutoCompletePlazaLaboral(newValue);
      setCodigoPlazaLaboral(newValue.CodigoPlazaLaboral);
    }
    handleCerrarBackDrop();
  };

  const handleOnChangeReemplazado = async (event, newValue) => {
    handleAbrirBackDrop();
    if (newValue !== null) {
      setDpiReemplazado(newValue.DPI);
      setValueAutoCompleteReemplazado(newValue);
    }
    handleCerrarBackDrop();
  };

  const handleOnChangeTipoContrato = async (event, newValue) => {
    handleAbrirBackDrop();
    if (newValue !== null) {
      setCodigoTipoContrato(newValue.CodigoTipoContrato);
      setValueAutoCompleteTipoContrato(newValue);
    }
    handleCerrarBackDrop();
  };
  const handleOnChangeTipoJornadaTrabajo = async (event, newValue) => {
    handleAbrirBackDrop();
    if (newValue !== null) {
      setCodigoTipoJornadaTrabajo(newValue.CodigoTipoJornadaTrabajo);
      setValueAutoCompleteTipoJornadaTrabajo(newValue);
    }
    handleCerrarBackDrop();
  };

  const cargarMaestroJefeInmediato = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }
    try {
      handleAbrirBackDrop();

      let uri =
        "api/EmpleadoJefeInmediato/PorUnidadOrganizacion/" +
        codigoUnidadOrganizacion;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setMaestroJefeInmediato(request.data.ficha);
      }
    } catch (error) {
      console.log(error);
    } finally {
      handleCerrarBackDrop();
    }
  };
  const cargarMaestroPlazas = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }
    try {
      handleAbrirBackDrop();

      let uri =
        "api/PlazaLaboral/PorUnidadOrganizacion/" + codigoUnidadOrganizacion;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setMaestroPlazas(request.data.ficha);
      }
    } catch (error) {
      console.log(error);
    } finally {
      handleCerrarBackDrop();
    }
  };
  const cargarMaestroPersonaAReemplazar = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri =
        "api/EmpleadoJefeInmediato/PorPlazaLaboral/" + codigoPlazaLaboral;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setMaestroPersonaReemplaza(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cargaMaestroTipoContrato = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/TipoContrato";

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        SetMaestroTipoContrato(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cargaMaestroTipoJornadaTrabajo = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/TipoJornadaTrabajo";

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setMaestroTipoJornadaTrabajo(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const guardarDato = async () => {
    const data = {
      DPISolicitante: dpiJefeInmediato,
      CodigoPlazaLaboral: codigoPlazaLaboral,
      CantidadPlazas: cantidadPlazas,
      FechaLimite: fechaLimite,
      ReemplazaPlaza: reemplazaPlaza,
      DPIReemplazado: dpiReemplazado === "" ? null : dpiReemplazado,
      CodigoTipoContrato: codigoTipoContrato,
      FechaInicio: fechaInicio,
      FechaFin: fechaFin,
      // TienePersonalACargo: tienePersonalACargo,
      CodigoTipoJornadaTrabajo: codigoTipoJornadaTrabajo,
      HorarioEntrada: horarioEntrada,
      HorarioSalida: horarioSalida,
      UsuarioInserto: userName,
      Activo: true,
      Eliminado: false,
      FechaInserto: new Date(),
      CodigoEstadoSolicitud: 1,
    };

    console.log(data);
    // dataFicha.usuarioInserto = "prueba";
    try {
      handleAbrirBackDrop();
      let vIsSignedIn = await isSignedIn();
      if (vIsSignedIn.response) {
        let request;

        request = await API.post("api/RequerimientoPersonal/", data);
        if (request.status !== 200) {
          toast.error(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        } else {
          if (request.data.response) {
            toast.success("Se guardó exitosamente");
            handleCloseModal();

            await cargarSolicitudes();
            // await props.actualizarListaSolicitudes();

            // setCodigoGerencia(0);
            // setCodigoUnidadOrganizacion(0);

            // deseleccionarItemsEnGridEmpleados();
          } else {
            toast.error(request.data.mensaje);
          }
        }
      }
    } catch (e) {
      console.log("EXCEPTION: " + e);
      toast.error(
        "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
      );
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cargarSolicitudes = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/RequerimientoPersonal/" + codigoUnidadOrganizacion;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setRequerimientoPersonalSolicitud(request.data.ficha);
        setRequerimientoPersonalSolicitudEjecutado(request.data.ficha2);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const iniciarSolicitud = async (pId) => {
    // dataFicha.usuarioInserto = "prueba";
    try {
      handleAbrirBackDrop();
      let vIsSignedIn = await isSignedIn();
      if (vIsSignedIn.response) {
        let request;

        request = await API.fetchGetRequest(
          "api/RequerimientoPersonal/Iniciar/" + pId
        );
        console.log(request);

        if (request.status !== 200) {
          toast.success("Se guardó exitosamente");
          await cargarSolicitudes();
        } else {
          if (request.data.response) {
            toast.success("Se guardó exitosamente");

            //await cargarMaestroPuestoLaboral();
            await cargarSolicitudes();
          } else {
            toast.error("Error.- " + request.data.mensaje);
          }
        }
      }
    } catch (e) {
      console.log("EXCEPTION: " + e);
      toast.error(
        "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
      );
    } finally {
      handleCloseModal();
      handleCerrarBackDrop();
    }
  };

  const cuandoHayError = async (data) => {
    toast.info(
      "Existen algunos campos con error, por favor valida el formulario",
      {
        position: "top-right",
        autoClose: 3000, // Esta en milisegundos 3 segundos
      }
    );
  };

  const handleAbrirDialogoIniciarSolicitud = (id) => {
    setDialogoAbiertoIniciarSolicitud(true);

    setCodigoRequerimientoPersonal(id);
  };

  const handleCerrarDialogoIniciarSolicitud = () => {
    setDialogoAbiertoIniciarSolicitud(false);
  };

  useEffect(() => {
    const cargar = async () => {
      handleAbrirBackDrop();
      await cargaMaestroTipoContrato();
      await cargaMaestroTipoJornadaTrabajo();
      handleCerrarBackDrop();
    };

    cargar();
  }, []);

  useEffect(() => {
    const cargar = async () => {
      handleAbrirBackDrop();
      await cargarMaestroJefeInmediato();
      await cargarMaestroPlazas();
      await cargarSolicitudes();

      handleCerrarBackDrop();
    };

    cargar();
  }, [codigoUnidadOrganizacion]);

  useEffect(() => {
    const cargar = async () => {
      handleAbrirBackDrop();
      await cargarMaestroPersonaAReemplazar();
      handleCerrarBackDrop();
    };

    // console.log(codigoPlazaLaboral);
    if (codigoPlazaLaboral > 0) {
      cargar();
    }
  }, [codigoPlazaLaboral]);

  const cellTemplateUnidadOrganizacion = (rowInfo) => {
    return (
      rowInfo.data.Gestion > 0 && (
        <Tooltip
          title={`Ir a gestión:`}
          style={{ marginRight: 10, cursor: "pointer" }}
        >
          <Link
            to={`/gestion/${rowInfo.data.Gestion}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {rowInfo.data.Gestion}
          </Link>
        </Tooltip>
      )
    );
  };

  const cellOpcionesSolicitud = (rowInfo) => {
    return (
      <>
        <Tooltip
          title="Iniciar solicitud"
          style={{ marginRight: 10, cursor: "pointer" }}
        >
          <PlayCircleFilled
            style={{ color: "#337ab7", cursor: "pointer" }}
            onClick={() =>
              handleAbrirDialogoIniciarSolicitud(
                rowInfo.data.CodigoRequerimientoPersonal
              )
            }
          />
        </Tooltip>
        {/* <Tooltip
          title="Eliminar"
          style={{ marginRight: 10, cursor: "pointer" }}
        >
          <Delete
            style={{ color: "#337ab7", cursor: "pointer" }}
            onClick={() =>
              handleAbrirDialogoEliminar(
                rowInfo.data.CodigoPuestoLaboralSolicitud
              )
            }
          />
        </Tooltip> */}
      </>
    );
  };

  return (
    <React.Fragment>
      <Backdrop open={buscando} className={classes.backdrop}>
        <CircularProgress />
      </Backdrop>
      <AppBar position="sticky">
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            style={{ display: "flex", flexGrow: 1 }}
          >
            {`Requerimiento de Personal`}
          </Typography>
          {!editarCompleto && (
            <Tooltip title={"Crear nuevo ítem"} aria-label="Crear nuevo ítem">
              <IconButton
                aria-label="Crear nuevo ítem"
                style={{ color: "white" }}
              >
                <Add onClick={handleNuevaPlaza} />
              </IconButton>
            </Tooltip>
          )}
        </Toolbar>
      </AppBar>
      <Paper className={classes.paper}>
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={(_, val) => setTab(val)}
          variant="scrollable"
          scrollButtons="on"
          aria-label="scrollable force tabs example"
          ScrollButtonComponent={MyTabScrollButton}
        >
          <Tab
            // icon={<Work />}
            label="Solicitudes Activas"
            {...a11yProps(0)}
            style={{ textTransform: "none" }}
            value={0}
          />
          <Tab
            // icon={<GroupAdd />}
            label="Solicitudes Anteriores"
            {...a11yProps(1)}
            style={{ textTransform: "none" }}
            value={1}
          />
        </Tabs>
        <TabPanel id="plazas" value={tab} index={0}>
          <Box className={classes.box} pl={0} pt={0}>
            <Paper className={classes.rootTabla}>
              <Typography variant={"h6"}>Solicitudes</Typography>
              <DataGrid
                dataSource={requerimientoPersonalSolicitud}
                //   defaultColumns={this.props.columns}
                showBorders={true}
                rowAlternationEnabled={true}
                //ref={(ref) => (dataGrid = ref)}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                className={classes.table}
              >
                <FilterRow visible={true} applyFilter={applyFilterTypes[0]} />
                <HeaderFilter visible={true} />
                <GroupPanel visible={false} />
                <Grouping autoExpandAll={true} />
                <SearchPanel
                  visible={false}
                  width={240}
                  placeholder="Search..."
                />
                {/* <Selection mode="multiple" /> */}
                <LoadPanel
                  enabled={true}
                  shadingColor="rgba(0,0,0,0.4)"
                  showIndicator={true}
                  shading={true}
                  showPane={true}
                  closeOnOutsideClick={false}
                />
                <Editing
                  mode="row"
                  useIcons={true}
                  allowUpdating={false}
                  allowDeleting={false}
                />
                <Column
                  caption="Opciones"
                  allowFiltering={false}
                  allowSorting={false}
                  cellRender={cellOpcionesSolicitud}
                />
                <Column
                  dataField="Gestion"
                  caption="# Gestion"
                  cellRender={cellTemplateUnidadOrganizacion}
                />
                <Column
                  dataField="EstadoSolicitud"
                  caption="Estado Solicitud"
                />
                <Column
                  dataField={"DPISolicitante"}
                  caption={"DPI Solicitante"}
                />
                <Column
                  dataField={"NombreCompletoSolicitante"}
                  caption={"Solicitante"}
                />
                <Column
                  dataField={"CodigoPuestoLaboral"}
                  caption={"Cod. Puesto Laboral"}
                />
                <Column
                  dataField={"PuestoLaboral"}
                  caption={"Puesto Laboral"}
                />
                <Column
                  dataField={"CantidadPlazas"}
                  caption={"Cantidad de Plazas"}
                />
                <Column
                  dataField={"FechaLimite"}
                  caption={"Fecha Limite"}
                  dataType="date"
                  format="dd/MM/yyyy"
                />
                <Column
                  dataField={"EsReemplazo"}
                  caption={"Es Reemplazo"}
                  dataType="boolean"
                />
                <Column
                  dataField={"DPIReemplazado"}
                  caption={"DPI Reemplazado"}
                />
                <Column
                  dataField={"NombreReemplazado"}
                  caption={"Nombre Reemplazado"}
                />
                <Column dataField={"TipoContrato"} caption={"Tipo Contrato"} />
                <Column
                  dataField={"FechaInicioContrato"}
                  caption={"Fecha Inicio"}
                  dataType="date"
                  format="dd/MM/yyyy"
                />
                <Column
                  dataField={"FechaFinContrato"}
                  caption={"Fecha Fin"}
                  dataType="date"
                  format="dd/MM/yyyy"
                />
                <Column
                  dataField={"TipoJornadaTrabajo"}
                  caption={"Tipo Jornada Trabajo"}
                />
                <Column
                  dataField={"HorarioEntrada"}
                  caption={"Horario Entrada"}
                />
                <Column
                  dataField={"HorarioSalida"}
                  caption={"Horario Salida"}
                />
                <Column
                  dataField={"UsuarioInserto"}
                  caption={"Usuario Inserto"}
                />
                <Column
                  dataField={"FechaInserto"}
                  caption={"Fecha Inserto"}
                  dataType="date"
                  format="dd/MM/yyyy"
                />

                <ColumnFixing enabled={true} />
                <Sorting mode="multiple" />
                <Paging defaultPageSize={20} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[20, 50, 100]}
                  showInfo={true}
                />
                <Export enabled={true} allowExportSelectedData={true} />
                <Scrolling columnRenderingMode="virtual" />
              </DataGrid>
            </Paper>
          </Box>
        </TabPanel>
        <TabPanel id="personal" value={tab} index={1}>
          <Box className={classes.box} pl={0} pt={0}>
            <Paper className={classes.rootTabla}>
              <Typography variant={"h6"}>Solicitudes</Typography>
              <DataGrid
                dataSource={requerimientoPersonalSolicitudEjecutado}
                //   defaultColumns={this.props.columns}
                showBorders={true}
                rowAlternationEnabled={true}
                //ref={(ref) => (dataGrid = ref)}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                className={classes.table}
              >
                <FilterRow visible={true} applyFilter={applyFilterTypes[0]} />
                <HeaderFilter visible={true} />
                <GroupPanel visible={false} />
                <Grouping autoExpandAll={true} />
                <SearchPanel
                  visible={false}
                  width={240}
                  placeholder="Search..."
                />
                {/* <Selection mode="multiple" /> */}
                <LoadPanel
                  enabled={true}
                  shadingColor="rgba(0,0,0,0.4)"
                  showIndicator={true}
                  shading={true}
                  showPane={true}
                  closeOnOutsideClick={false}
                />
                <Editing
                  mode="row"
                  useIcons={true}
                  allowUpdating={false}
                  allowDeleting={false}
                />
                <Column
                  dataField="Gestion"
                  caption="# Gestion"
                  cellRender={cellTemplateUnidadOrganizacion}
                />
                <Column
                  dataField="EstadoSolicitud"
                  caption="Estado Solicitud"
                />
                <Column
                  dataField={"DPISolicitante"}
                  caption={"DPI Solicitante"}
                />
                <Column
                  dataField={"NombreCompletoSolicitante"}
                  caption={"Solicitante"}
                />
                <Column
                  dataField={"CodigoPuestoLaboral"}
                  caption={"Cod. Puesto Laboral"}
                />
                <Column
                  dataField={"PuestoLaboral"}
                  caption={"Puesto Laboral"}
                />
                <Column
                  dataField={"CantidadPlazas"}
                  caption={"Cantidad de Plazas"}
                />
                <Column
                  dataField={"FechaLimite"}
                  caption={"Fecha Limite"}
                  dataType="date"
                  format="dd/MM/yyyy"
                />
                <Column
                  dataField={"EsReemplazo"}
                  caption={"Es Reemplazo"}
                  dataType="boolean"
                />
                <Column
                  dataField={"DPIReemplazado"}
                  caption={"DPI Reemplazado"}
                />
                <Column
                  dataField={"NombreReemplazado"}
                  caption={"Nombre Reemplazado"}
                />
                <Column dataField={"TipoContrato"} caption={"Tipo Contrato"} />
                <Column
                  dataField={"FechaInicioContrato"}
                  caption={"Fecha Inicio"}
                  dataType="date"
                  format="dd/MM/yyyy"
                />
                <Column
                  dataField={"FechaFinContrato"}
                  caption={"Fecha Fin"}
                  dataType="date"
                  format="dd/MM/yyyy"
                />
                <Column
                  dataField={"TipoJornadaTrabajo"}
                  caption={"Tipo Jornada Trabajo"}
                />
                <Column
                  dataField={"HorarioEntrada"}
                  caption={"Horario Entrada"}
                />
                <Column
                  dataField={"HorarioSalida"}
                  caption={"Horario Salida"}
                />
                <Column
                  dataField={"UsuarioInserto"}
                  caption={"Usuario Inserto"}
                />
                <Column
                  dataField={"FechaInserto"}
                  caption={"Fecha Inserto"}
                  dataType="date"
                  format="dd/MM/yyyy"
                />

                <ColumnFixing enabled={true} />
                <Sorting mode="multiple" />
                <Paging defaultPageSize={20} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[20, 50, 100]}
                  showInfo={true}
                />
                <Export enabled={true} allowExportSelectedData={true} />
                <Scrolling columnRenderingMode="virtual" />
              </DataGrid>
            </Paper>
          </Box>
        </TabPanel>
      </Paper>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModal}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <div className={classes.paperModal}>
            <h2 id="simple-modal-title">Formulario para Cambios</h2>
            <Grid container spacing={3}>
              <form
                className={classes.form}
                autoComplete="off"
                onSubmit={handleSubmit(guardarDato, cuandoHayError)}
              >
                <Grid item id="i_solicitante" xs={6} sm={6} md={12} lg={12}>
                  <FormControl className={classes.formControlSelects}>
                    <Controller
                      name="solicitante"
                      required
                      render={({ field }) => (
                        <Autocomplete
                          id="solicitante"
                          options={maestroJefeInmediato}
                          value={valueAutoCompleteJefeInmediato}
                          onChange={(event, newValue) =>
                            handleChangeAutoCompleteJefeInmediato(
                              event,
                              newValue
                            )
                          }
                          getOptionLabel={(option) => option.NombreCompleto}
                          label="Solicitante"
                          variant="outlined"
                          fullWidth
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Solicitante"
                              variant="outlined"
                            />
                          )}
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                </Grid>
                <Grid item id="i_plazaLaboral" xs={6} sm={6} md={12} lg={12}>
                  <FormControl className={classes.formControlSelects}>
                    <Controller
                      name="plazaLaboral"
                      required
                      render={({ field }) => (
                        <Autocomplete
                          id="plazaLaboral"
                          options={maestroPlazas}
                          value={valueAutoCompletePlazaLaboral}
                          onChange={(event, newValue) =>
                            handleChangeAutoCompletePlazaLaboral(
                              event,
                              newValue
                            )
                          }
                          getOptionLabel={(option) => option.PuestoLaboral}
                          label="Plaza Laboral"
                          variant="outlined"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Plaza Laboral"
                              variant="outlined"
                            />
                          )}
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                </Grid>
                <Grid item id="i_cantidadPlazas" xs={6} sm={6} md={12} lg={12}>
                  <FormControl className={classes.formControlSelects}>
                    <Controller
                      name="cantidadPlazas"
                      render={({ Grado }) => (
                        <TextField
                          id="cantidadPlazas"
                          label="No. de Personas a contratar"
                          name="cantidadPlazas"
                          value={cantidadPlazas}
                          onChange={(e) => setCantidadPlazas(e.target.value)}
                          variant="outlined"
                          required
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                </Grid>
                <Grid item id="i_fechaLimite" xs={6} sm={6} md={12} lg={12}>
                  <FormControl className={classes.formControlSelects}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid container justify="flex-start">
                        <KeyboardDatePicker
                          disableToolbar
                          variant="inline"
                          format="dd/MM/yyyy"
                          margin="normal"
                          id="date-picker-inline"
                          label="Fecha Limite "
                          value={fechaLimite}
                          onChange={(date) => setFechaLimite(date)}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          required
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </Grid>
                <Grid item id="i_esReemplazo" xs={6} sm={6} md={12} lg={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={reemplazaPlaza}
                        onChange={(e) => setReemplazaPlaza(e.target.checked)}
                        value="¿Reemplaza una plaza?"
                        color="primary"
                      />
                    }
                    label="¿Reemplaza una plaza?"
                    labelPlacement="start"
                  />
                </Grid>
                <Grid item id="i_reemplazante" xs={6} sm={6} md={12} lg={12}>
                  <FormControl className={classes.formControlSelects}>
                    <Controller
                      name="reemplazante"
                      required
                      render={({ reemplazante }) => (
                        <Autocomplete
                          disabled={!reemplazaPlaza}
                          id="jefe"
                          options={maestroPersonaReemplaza}
                          getOptionLabel={(option) => option.NombreCompleto}
                          value={valueAutoCompleteReemplazado}
                          onChange={(event, newValue) =>
                            handleOnChangeReemplazado(event, newValue)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Persona a reemplazar"
                              variant="outlined"
                            />
                          )}
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                </Grid>
                <Grid item id="i_tipoContrato" xs={6} sm={6} md={12} lg={12}>
                  <FormControl className={classes.formControlSelects}>
                    <Controller
                      name="tipoContrato"
                      required
                      render={({ reemplazante }) => (
                        <Autocomplete
                          id="tipoContrato"
                          options={maestroTipoContrato}
                          getOptionLabel={(option) => option.TipoContrato}
                          value={valueAutoCompleteTipoContrato}
                          onChange={(event, newValue) =>
                            handleOnChangeTipoContrato(event, newValue)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Tipo de Contrato"
                              variant="outlined"
                            />
                          )}
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                </Grid>
                <Grid item id="i_fechaInicio" xs={6} sm={6} md={12} lg={12}>
                  <FormControl className={classes.formControlSelects}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid container justify="flex-start">
                        <KeyboardDatePicker
                          disableToolbar
                          variant="inline"
                          format="dd/MM/yyyy"
                          margin="normal"
                          id="date-picker-inline"
                          label="Fecha de Inicio"
                          value={fechaInicio}
                          onChange={(date) => setFechaInicio(date)}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          // required
                          disabled={codigoTipoContrato !== 2}
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </Grid>
                <Grid item id="i_fechaFin" xs={6} sm={6} md={12} lg={12}>
                  <FormControl className={classes.formControlSelects}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid container justify="flex-start">
                        <KeyboardDatePicker
                          disableToolbar
                          variant="inline"
                          format="dd/MM/yyyy"
                          margin="normal"
                          id="date-picker-inline"
                          label="Fecha Fin"
                          value={fechaFin}
                          onChange={(date) => setFechaFin(date)}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          // required
                          disabled={codigoTipoContrato !== 2}
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  id="i_tienePersonalACargo"
                  xs={6}
                  sm={6}
                  md={12}
                  lg={12}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={tienePersonalACargo}
                        onChange={(e) =>
                          setTienePersonalACargo(e.target.checked)
                        }
                        value="¿Tiene personal a su cargo?"
                        color="primary"
                      />
                    }
                    label="¿Tiene personal a su cargo?"
                    labelPlacement="start"
                  />
                </Grid>
                <Grid
                  item
                  id="i_TipoJornadaTrabajo"
                  xs={6}
                  sm={6}
                  md={12}
                  lg={12}
                >
                  <FormControl className={classes.formControlSelects}>
                    <Controller
                      name="tipoJornadaTrabajo"
                      required
                      render={({ params }) => (
                        <Autocomplete
                          id="tipoJornadaTrabajo"
                          options={maestroTipoJornadaTrabajo}
                          getOptionLabel={(option) => option.TipoJornadaTrabajo}
                          value={valueAutoCompleteTipoJornadaTrabajo}
                          onChange={(event, newValue) =>
                            handleOnChangeTipoJornadaTrabajo(event, newValue)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Jornada de Trabajo"
                              variant="outlined"
                            />
                          )}
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                </Grid>
                <Grid item id="i_horarioEntrada" xs={6} sm={6} md={12} lg={12}>
                  <FormControl className={classes.formControlSelects}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid container justify="flex-start">
                        <KeyboardTimePicker
                          margin="normal"
                          id="time-picker-entrada"
                          label="Horario de Entrada"
                          value={horarioEntrada}
                          onChange={(time) => setHorarioEntrada(time)}
                          KeyboardButtonProps={{
                            "aria-label": "change time",
                          }}
                          // disabled={
                          //   valueAutoCompleteTipoJornadaTrabajo !== null
                          //     ? true
                          //     : !valueAutoCompleteTipoJornadaTrabajo.AplicaHorario
                          // }
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </Grid>
                <Grid item id="i_horarioSalida" xs={6} sm={6} md={12} lg={12}>
                  <FormControl className={classes.formControlSelects}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid container justify="flex-start">
                        <KeyboardTimePicker
                          margin="normal"
                          id="time-picker-salida"
                          label="Horario de Salida"
                          value={horarioSalida}
                          onChange={(time) => setHorarioSalida(time)}
                          KeyboardButtonProps={{
                            "aria-label": "change time",
                          }}
                          // disabled={
                          //   valueAutoCompleteTipoJornadaTrabajo !== null
                          //     ? true
                          //     : !valueAutoCompleteTipoJornadaTrabajo.AplicaHorario
                          // }
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} md={12} lg={12}>
                  <Button
                    width="100%"
                    variant="contained"
                    style={{
                      backgroundColor: "#69bd4b",
                      marginBottom: 10,
                      marginTop: 10,
                      color: "white",
                      width: "93%",
                      marginLeft: 30,
                    }}
                    type="submit"
                  >
                    &nbsp;Guardar Cambios
                  </Button>
                </Grid>
              </form>
            </Grid>
          </div>
        </Fade>
      </Modal>
      {/* Dialogo para iniciar solicitud */}
      <Dialog
        open={dialogoAbiertoIniciarSolicitud}
        onClose={handleCerrarDialogoIniciarSolicitud}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Iniciar Solicitud</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro que desea iniciar la solicitud para iniciar este
            registro?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCerrarDialogoIniciarSolicitud}
            color="secondary"
          >
            Cancelar
          </Button>
          <Button
            onClick={() => iniciarSolicitud(codigoRequerimientoPersonal)}
            color="primary"
          >
            Iniciar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default RequerimientoPersonalComponente;
