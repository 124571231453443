import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { CardMedia, Card } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Modal from "../containers/modal";
const styles = makeStyles({
  root:{
    height: '25%',
    marginTop: 10,
  },
  firma:{
    height:'100%',
    
  },
  foto:{
    height:'40%',
    marginTop: 10,
  }
})
export default function ModalForm(props) {
  const theme = useTheme();
  const classes = styles()
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.onClose}
        title={props.title}

      >
        {
            
            props.inputs===undefined?null:
            props.inputs.map(i => 
              i.value===null?null:
              i.input==="input"?( <TextField
                  
                  id="outlined-full-width"
                  label={i.label}
                  fullWidth
                  type = {i.type}
                  value = {i.value}
                  onChange = {(e)=>i.valueChanged(e.target.value)}
                  placeholder={i.placeholder}
                  margin="normal"
                  inputProps ={
                    {readOnly:props.readOnly===undefined?false:props.readOnlu}
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                />):(i.input==="img"?( 
                <Card className={i.type===undefined?classes.root:classes.foto}>
                  <CardMedia className={classes.firma}
                    component='img'
                    src={i.value}
                    title={i.label}
                /></Card>):null)
            )
                }
                {
                  props.children===undefined?null:props.children
                }
        </Modal>
    </div>
  );
}
