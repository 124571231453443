import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";

import { green, pink } from "@material-ui/core/colors";

import { makeStyles,  } from "@material-ui/core/styles";

//input date

//Own
import ComponenteConfiguracion from "./../components/configuracion-gespilu-componente";
import Layout from "../../layout/containers/Layout";

import { isSignedIn, profileImageChange, signOut } from "../../utils/auth";


const styles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
  paperPerfil: {
    position: "relative",
    width: "100%", //theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  greenAvatar: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  paperCard: {
    padding: 20,
    width: "100%",
  },
}));



const PerfilLayout = (props) => {
  const classes = styles();
  const [codigoUsuario, setCodigoUsuario] = useState("");

  useEffect(() => {
    async function obtenerDatosSesion() {
      let session = await isSignedIn();
      
      if (!session.response) {
        window.location.href = "/login";
        return;
      } else if (session.response) {
        setCodigoUsuario(session.codigoUsuario);
      }
    }
    obtenerDatosSesion();
  }, []);


  return (
    <Layout titulo={`Configuración de GESPILU`}  maxWidth={"xl"}>
      <React.Fragment>
        <ToastContainer />
        <ComponenteConfiguracion/>
      </React.Fragment>
    </Layout>
  );
};

export default withRouter(PerfilLayout);