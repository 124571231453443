import React, { useState, useEffect } from "react";
import { green } from "@material-ui/core/colors";

import {
  Backdrop,
  CircularProgress,
  Container,
  Paper,
  Typography,
  Box,
  Tabs,
  Tab,
  TabScrollButton,
} from "@material-ui/core";
import PropTypes from "prop-types";

import API from "../../utils/api";
import { isSignedIn, signOut } from "../../utils/auth";
import { validaPermisoPaginaActual } from "../../utils/helpers";
import { ToastContainer, toast } from "react-toastify";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import "devextreme/dist/css/dx.material.teal.light.compact.css";
import { LoadPanel } from "devextreme-react";

import AltasReclutamientoComponente from "./altas-reclutamiento-componente";
import AltasPrecontratacionComponente from "./altas-precontratados-componente";
const width_proportion = "100%";
const titleColor = "#2B3C4D";
let datagridEmpleadosSCILU;

const useStyles = makeStyles((theme) => ({
  table: {
    width: "100%",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 210,
  },
  textFieldDireccion: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 230,
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  greenAvatar: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  paperCard: {
    padding: 20,
    width: "100%",
  },
  formControlSelects: {
    // margin: theme.spacing(1),
    // minWidth: 480,

    marginBottom: 10,
    marginTop: 10,
    width: "90%",
    marginLeft: 20,
  },

  formControlSelects2: {
    // margin: theme.spacing(1),
    minWidth: 480,

    marginBottom: 10,
    marginTop: 10,
    // width: "90%",
    marginLeft: 20,
  },

  formControlSelect: {
    // margin: theme.spacing(1),
    width: width_proportion,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  rootTabla: {
    width: "100%",
    marginTop: theme.spacing.unit * 1,
    overflowX: "auto",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperModal: {
    position: "absolute",
    width: "70%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const a11yProps = (index) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={"span"} variant={"body2"}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const MyTabScrollButton = withStyles((theme) => ({
  root: {
    width: 28,
    overflow: "hidden",
    transition: "width 0.5s",
    "&.Mui-disabled": {
      width: 0,
    },
  },
}))(TabScrollButton);

const MovimientoPersonal = (props) => {
  const classes = useStyles();
  const [tabVertical, setTabVertical] = useState(0);

  return (
    <React.Fragment>
      <Paper className={classes.rootTabla}>
        <Container maxWidth="100%">
          <Box className={classes.contenedorTabVertical}>
            <Tabs
              value={tabVertical}
              indicatorColor="primary"
              textColor="primary"
              onChange={(_, val) => setTabVertical(val)}
              variant="scrollable"
              scrollButtons="on"
              aria-label="scrollable force tabs example"
              ScrollButtonComponent={MyTabScrollButton}
            >
              <Tab
                label="Desde Reclutamiento"
                // icon={<PersonPin />}
                {...a11yProps(0)}
                style={{ textTransform: "none" }}
                value={0}
              />
              <Tab
                label="Desde Precontratación "
                // icon={<PersonPin />}
                {...a11yProps(1)}
                style={{ textTransform: "none" }}
                value={1}
              />
            </Tabs>

            <TabPanel id="reclutamiento" value={tabVertical} index={0}>
              <Box className={classes.box} pl={0} pt={0}>
                <AltasReclutamientoComponente
                  codigoUnidadOrganizacion={props.codigoUnidadOrganizacion}
                  editarCompleto={props.editarCompleto}
                  actualizarListaSolicitudes={props.actualizarListaSolicitudes}
                />
              </Box>
            </TabPanel>
            <TabPanel id="precontratados" value={tabVertical} index={1}>
              <Box className={classes.box} pl={0} pt={0}>
                <AltasPrecontratacionComponente
                  codigoUnidadOrganizacion={props.codigoUnidadOrganizacion}
                  editarCompleto={props.editarCompleto}
                  actualizarListaSolicitudes={props.actualizarListaSolicitudes}
                />
              </Box>
            </TabPanel>
          </Box>
        </Container>
      </Paper>
    </React.Fragment>
  );
};

export default MovimientoPersonal;
