import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AssessmentIcon from "@material-ui/icons/Assessment";
import { withStyles } from "@material-ui/core/styles";
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import AppsIcon from '@material-ui/icons/Apps';
import {
  FloatingMenu,
  MainButton,
  ChildButton,
} from "react-floating-button-menu";
import AddIcon from "@material-ui/icons/Add";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import ClearIcon from "@material-ui/icons/Clear";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";
import AddAlertIcon from "@material-ui/icons/AddAlert";
import PersonIcon from "@material-ui/icons/Person";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Loader from "react-loader-spinner";
import Popover from "@material-ui/core/Popover";
import MaskedInput from "react-text-mask";
import PropTypes from "prop-types";
import esLocale from "date-fns/locale/es";
import { DatePicker, MuiPickersUtilsProvider, Day } from "material-ui-pickers";
import DateFnsUtils from "@date-io/date-fns";
import { DropzoneArea } from "material-ui-dropzone";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Alert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";
import Paper from "@material-ui/core/Paper";
import NotificationsIcon from "@material-ui/icons/Notifications";
import AssignmentIcon from "@material-ui/icons/Assignment";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Container from "@material-ui/core/Container";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

//Inputs Formulario
import InputHora from "../../avisos-objects/components/input-hora";
import Separador from "../../avisos-objects/components/separador";

//Scroll bar
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import UsuariosList from "../../plantillaProblema/components/usuarios-list";

//Appbar
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import InputBase from "@material-ui/core/InputBase";
import { fade, makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";

//Own components
import Layout from "../../layout/containers/Layout";
import ObjetosTabla from "../components/objetos-tabla";
import AddButton from "../../helpers/components/add-button-float";
import API from "../../utils/api";
import { isSignedIn, signOut } from "../../utils/auth";
import { convertYYYYMMDDToDate, convertirHHMM } from "../../utils/helpers";
import { convertirASegundos, convertToYearMonthDay } from "../../utils/helpers";
import {validaPermisoPaginaActual} from "../../utils/helpers";
import {clearNavigationHistory} from "../../utils/navigationGestion";
import DetalleTarea from "../../gestion/components/detalle-tarea";

//CSS
import "../components/gestion-style.css";

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0-9]/, /[0-9]/, ":", /[0-5]/, /[0-9]/]}
      placeholderChar={"\u2000"}
      showMask
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  paperUserModal: {
    position: "absolute",
    width: "75%",
    height: "600px",
    maxHeight: "600px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  popover: {
    pointerEvents: "none",
  },
  paperPopover: {
    padding: theme.spacing(1),
    background: "#000",
    color: "#fff",
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
});

class InfoTarea extends Component {
  state = {
    creados: 0,
    asignados: 0,
    tareaspendientes: 0,
  };

  async componentWillMount() {
    //CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
    const llaveAgrupacion = "Menu.Principal.Web";
    const llavePagina = "Menu.Principal.Web.Gestiones";
    await validaPermisoPaginaActual(llaveAgrupacion, llavePagina);
    //FIN DE CONSULTA DE PERMISOS
    const session = await isSignedIn();
    if (session.response) {
      //Cargar lista subentidades
      this.setState(
        {
          codigoUsuarioActual: session["codigoUsuario"],
          usuarioAfectado: parseInt(session["codigoUsuario"])
         // nombreUsuarioActual: session["Luis Ardani Ramirez Morales"],
        },
      );
    } else {
      window.location.href = "/login";
    }
  }

  refrescarTareas = () => {
    let refresh= true;
  };


  render() {
    const { classes } = this.props;
    return (
        <Layout titulo="Información de Tarea">
        <div
          style={{
            color: "#2196F3",
            display: "flex",
            alignContent: "center",
            fontSize: "1m",
            marginTop: 10,
          }}
        ></div>

        {this.state.cargando ? (
          <div
            style={{
              width: "100%",
              marginTop: 10,
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loader
              type="Oval"
              color="#3498db"
              height="50"
              width="50"
              style={{ textAlign: "center" }}
            />
          </div>
        ) : (
          <></>
        )}
        <DetalleTarea
        haySubtareas={false}
        codigoTarea={this.props.match.params.codigoTarea}
        refrescarTareas={() => this.refrescarTareas()}
        onClickSubTareas={this.props.match.params.codigoTarea}
      />
      </Layout>
    );
  }
}
const InfoTareaContainer = withStyles(styles)(InfoTarea);
export default withRouter(InfoTareaContainer);
