import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    width: 35,
    flexDirection: "column",
  },
  percentContainer: {
    width: 100,
    height: 8,
    borderRadius: 45,
    backgroundColor: "#C4C4C4",
    position: "absolute",
  },
  percentAContainer: {
    width: 10,
    height: 8,
    borderRadius: 45,
    backgroundColor: "#4DAD4A",
    position: "absolute",
  },
  percentText: {
    textAlign: "center",
    marginTop: 10,
  },
}));

/*
<div className={classes.percentContainer} />
<div
  style={{
    width: 10,
    height: 8,
    borderRadius: 45,
    backgroundColor: "#4DAD4A",
    position: "absolute",
    width: percentAdvanced,
  }}
  <span className={classes.percentText}>{percent}%</span>
/>;*/
function progressComponent(props) {
  const classes = useStyles();

  const percentAdvanced = props.percent >= 5 ? props.percent : 0;
  return (
    <div className={classes.container}>
      <div style={{ position: "relative" }}>
        <CircularProgressbar
          value={percentAdvanced}
          text={`${percentAdvanced}%`}
          styles={buildStyles({
            // Rotation of path and trail, in number of turns (0-1)
            rotation: 0, //0.25,

            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
            strokeLinecap: "butt",

            // Text size
            textSize: "25px",

            // How long animation takes to go from one percentage to another, in seconds
            pathTransitionDuration: 0.5,

            // Can specify path transition in more detail, or remove it entirely
            // pathTransition: 'none',

            // Colors
            pathColor: "#4DAD4A",
            textColor: props.textColor ? props.textColor : "black",
            trailColor: "#C4C4C4",
            backgroundColor: "#fff",
          })}
        />
      </div>
    </div>
  );
}

export default progressComponent;
