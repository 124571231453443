import React from "react";

import PieChart, {
  Legend,
  Export,
  Series,
  Label,
  Font,
  Connector,
} from "devextreme-react/pie-chart";

//<props>
// dataSource
// title
//</props>

class PieCustom extends React.Component {
  render() {
    const dataSource = this.props.dataSource || [];
    const title = this.props.title || "default";

    return (
      <PieChart id="pie" palette="Bright" dataSource={dataSource} title={title}>
        <Legend
          orientation="horizontal"
          itemTextPosition="right"
          horizontalAlignment="center"
          verticalAlignment="bottom"
          columnCount={4}
        />
        <Export enabled={true} />
        <Series argumentField="argument" valueField="value">
          <Label
            visible={true}
            position="columns"
            customizeText={customizeText}
          >
            <Font size={16} />
            <Connector visible={true} width={0.5} />
          </Label>
        </Series>
      </PieChart>
    );
  }
}

function customizeText(arg) {
  return `${arg.valueText} (${arg.percentText})`;
}

export default PieCustom;
