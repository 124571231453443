import React from "react";
import TextField from "@material-ui/core/TextField";

function InputTexto(props) {
  const { CodigoCampo, Nombre } = props;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {props.Icono}
      <TextField
        disabled
        id={`txt${CodigoCampo}`}
        label={Nombre}
        style={{ width: "100%" }}
        value=""
        margin="normal"
        variant="outlined"
        inputProps={{ zIndex: 0 }}
      />
    </div>
  );
}

export default InputTexto;
