import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { eachDayOfInterval } from "date-fns";
import { ToastContainer, toast } from "react-toastify";

const ITEM_HEIGHT = 48;

export default function ActionMenuObjects(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const llaveTablaCarpeta = "AITCarpeta";
  const llaveTablaGestion = "AITProblema";

  let options = [];

  if (props.llave === llaveTablaCarpeta) {
    options = ["Eliminar", "Editar", "Adm. Permisos" /*"Adm. Causas"*/];
  } else if (props.llave === llaveTablaGestion) {
    options = [/*"Crear",*/"Editar","Eliminar",/*"Buscar",*/ "Clonar","Mover a","Link AppILU","Adm. Permisos"];
  } else if (props.llave === "PROCESO_GESTION") {
    options = ["Editar", "Eliminar", "Clonar"];
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const editar = () => {
    props.editar(props.idObjeto, props.llave);
    handleClose();
  };

  const eliminar = () => {
    props.eliminar(props.idObjeto, props.llave);
    handleClose();
  };

  const permisos = () => {
    props.permisos(props.idObjeto, props.llave);
    handleClose();
  };

  const configuracion = () => {
    props.irConfiguracion(props.idObjeto, props.llave);
    handleClose();
  };

  const nuevaInstancia = () => {
    props.nuevaInstancia(props.idObjeto, props.llave);
    handleClose();
  };

  const causas = () => {
    props.verCausas(props.idObjeto, props.llave);
    handleClose();
  };

  const clonar = () => {
    props.clonar(props.idObjeto, props.llave);
    handleClose();
  };

  const moverA = () => {
    props.moverA(props.idObjeto, props.llave);
    handleClose();
  };

  const linkAppILU = ()=>{
    navigator.clipboard.writeText(
      `appilu://home/problema/${props.idObjeto}`
    );
    toast.info("Link copiado!");
  }

  const buscar=()=>{
    window.location.href=`/superfiltro/${props.idObjeto}`
  }


  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option}
            selected={option === "Pyxis"}
            onClick={() => {
              switch (option) {
                case "Editar": {
                  editar();
                  break;
                }
                case "Eliminar": {
                  eliminar();
                  break;
                }

                case "Adm. Permisos": {
                  permisos();
                  break;
                }
                case "Configuración": {
                  configuracion();
                  break;
                }

                case "Crear": {
                  nuevaInstancia();
                  break;
                }

                case "Adm. Causas": {
                  causas();
                  break;
                }
                case "Clonar": {
                  clonar();
                  break;
                }
                case "Mover a": {
                  moverA();
                  break;
                }
                case "Link AppILU":{
                  linkAppILU();
                  break;
                }
                case "Buscar":{
                  buscar();
                  break;
                }
              }
            }}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
