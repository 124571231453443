import React, { useState, useEffect } from "react";
import { useParams } from "react-router";

import {
  Backdrop,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  TextField,
  Paper,
  Tooltip,
  Modal,
  Fade,
  AppBar,
  Toolbar,
  Typography,
} from "@material-ui/core";

import { green } from "@material-ui/core/colors";

import API from "../../utils/api";
import { isSignedIn, signOut } from "../../utils/auth";
import {
  getBrowserInfo,
  validaPermisoPaginaActual,
  verificaPermisoPaginaActual,
} from "../../utils/helpers";
import { ToastContainer, toast } from "react-toastify";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import "devextreme/dist/css/dx.material.teal.light.compact.css";

import Detalle from "../components/detalle-movimiento-personal-componente";

const width_proportion = "100%";

const useStyles = makeStyles((theme) => ({
  table: {
    width: "100%",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 210,
  },
  textFieldDireccion: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 230,
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  greenAvatar: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  paperCard: {
    padding: 20,
    width: "100%",
  },
  formControlSelects: {
    // margin: theme.spacing(1),
    minWidth: 360,

    marginBottom: 10,
    marginTop: 10,
    // width: "100%",
    marginLeft: 30,
  },
  formControlSelect: {
    // margin: theme.spacing(1),
    width: width_proportion,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  rootTabla: {
    width: "100%",
    marginTop: theme.spacing.unit * 1,
    overflowX: "auto",
    paddingTop: theme.spacing.unit * 2,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperModal: {
    position: "absolute",
    width: "70%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const PuestoLaboral = () => {
  const classes = useStyles();
  const { token } = useParams();

  let applyFilterTypes = [
    {
      key: "auto",
      name: "Immediately",
    },
    {
      key: "onClick",
      name: "On Button Click",
    },
  ];

  const [permisoPlazaLaboral, setPermisoPlazaLaboral] = useState(false);
  const [userName, setUserName] = useState("");
  const [detalle, setDetalle] = useState(null);
  const [open, setOpen] = useState(false);
  const [cargandoMaestros, setCargandoMaestros] = useState(false);

  const [buscando, setBackDrop] = useState(false);

  const handleCerrarBackDrop = () => {
    setBackDrop(false);
  };
  const handleAbrirBackDrop = () => {
    setBackDrop(true);
  };

  useEffect(() => {
    const cargar = async () => {
      handleAbrirBackDrop();

      await confirmar();

      handleCerrarBackDrop();
    };

    cargar();
  }, [cargandoMaestros]);

  const confirmar = async () => {
    const data = {
      CodigoEmpleadoSolicitudMovimiento: token,
      DetalleAceptado: getBrowserInfo(),
    };

    console.log(data);
    // dataFicha.usuarioInserto = "prueba";
    try {
      handleAbrirBackDrop();
      let request;

      request = await API.postNoAuth(
        "api/EmpleadoSolicitudMovimiento/Aceptar",
        data
      );
      if (request.status !== 200) {
        toast.error(
          "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
        );
      } else {
        if (request.data.response) {
          toast.success("Se registro exitosamente");
        } else {
          toast.warn(request.data.mensaje);
        }
      }
    } catch (e) {
      console.log("EXCEPTION: " + e);
      toast.error(
        "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
      );
    } finally {
      handleCerrarBackDrop();
    }
  };

  return (
    <React.Fragment>
      <Backdrop className={classes.backdrop} open={buscando}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer />
      <Detalle token={token} />
    </React.Fragment>
  );
};

export default PuestoLaboral;
