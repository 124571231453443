import {
  Backdrop, Button, CircularProgress, Grid
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { toast, ToastContainer } from "react-toastify";
import API from "../../utils/api";
import { isSignedIn } from "../../utils/auth";

import SaveIcon from '@material-ui/icons/Save';

import FormControl from "@material-ui/core/FormControl";
import InputLabel from '@material-ui/core/InputLabel';
import ListSubheader from "@material-ui/core/ListSubheader";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';


const width_proportion = "100%";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 210,
  },
  textFieldDireccion: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginTop: theme.spacing.unit * 4,
    minWidth: 800,
    margin: theme.spacing(1),
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  greenAvatar: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  paperCard: {
    padding: 20,
    width: "100%",
  },
  formControlSelects: {
    margin: theme.spacing(1),
    minWidth: 230,
  },
  formControlSelect: {
    // margin: theme.spacing(1),
    width: width_proportion,
  },
  formControl: {
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  datoContent: {
    alignItems: 'center',
    "border-style": "groove",
    "border-width": "thin",
    margin: "0px"
  },
  labelContainer: {
    display: 'flex',
    flex: 0.40,
    alignItems: 'center',
    fontWeight: '600',
    fontSize: '1rem',
  },
  datoContainer: {
    marginTop: '0.30rem',
    width: '1400px',
    margin: 'auto'
  },
  datoTxt: {
    flex: 0.60,
    fontSize: '1rem',
    fontWeight: '600',
    color: '#000000a6'
  },
  icon: {
    fontSize: '1.25rem',
    color: '#147085',
    marginRight: '0.5rem',
  },
  titulo: {
    "text-align": "center",
    fontWeight: "700",
  },
  datoContentActividad: {
    alignItems: 'center',
    //"border-style": "groove",
    //"border-width": "thin"
  },
  button: {
    textAlign: "right",
    marginBottom: "10px",
  }
}));

const Relaciones = (props) => {

  const [userName, setUserName] = useState("");
  const [buscando, setBackDrop] = useState(false);
  const [catalogoRelaciones, setCatalogoRelaciones] = useState([]);
  const [relacionesAsignadas, setRelacionesAsignadas] = useState([]);
  const [relacionesInternas, setRelacionesInternas] = useState([]);
  const [relacionesExternas, setRelacionesExternas] = useState([]);

  const handleCerrarBackDrop = () => {
    setBackDrop(false);
  };
  const handleAbrirBackDrop = () => {
    setBackDrop(true);
  };

  const classes = useStyles();

  useEffect(() => {
    const cargar = async () => {
      handleAbrirBackDrop();
      await cargarRelaciones(props.puestoLaboral.CodigoPuestoLaboral);
      handleCerrarBackDrop();
    };

    cargar();
  }, [props.puestoLaboral]);

  const cargarRelaciones = async (idPuesto) => {
    let session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    }
    try {
      handleAbrirBackDrop();
      const request = await API.fetchGetRequest(`api/PuestoLaboral/Relaciones/${idPuesto}`);

      if (request.data.data == null) {
        return;
      }
      console.log(request);
      setCatalogoRelaciones(request.data.data.Catalogo);
      setRelacionesInternas(request.data.data.RelacionesAsignadasInternas);
      setRelacionesExternas(request.data.data.RelacionesAsignadasExternas);
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const postData = async (data) => {
    let session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    }
    try {
      handleAbrirBackDrop();
      const request = await API.post(
        "api/PuestoLaboral/Relaciones/guardar",
        data
      );
      if (request.status !== 200) {
        toast.error(
          "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
        );
      } else {
        toast.success("Se guardó exitosamente");
      }
      console.log(request);
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const handleChangeInternas = (event) => {
    setRelacionesInternas(event.target.value);
  };

  const handleChangeExternas = (event) => {
    setRelacionesExternas(event.target.value);
  };

  const renderSelectGroup = categoria => {
    const items = categoria.Relaciones.map(p => {
      return (
        <MenuItem key={p.CodigoRelacion} value={p.CodigoRelacion}>
          {p.Descripcion}
        </MenuItem>
      );
    });
    return [<ListSubheader style={{ backgroundColor: "white" }}>{categoria.Categoria}</ListSubheader>, items];
  };

  const verificarRelaciones = categoria => {
    let tiene = false;
    let equis = false;
    categoria.Relaciones.forEach(element => {
      if (relacionesInternas.includes(element.CodigoRelacion) || relacionesExternas.includes(element.CodigoRelacion)) {
        tiene = true;
      }
    });
    return tiene;
  }

  return (
    <React.Fragment>
      <Backdrop className={classes.backdrop} open={buscando}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer />

      <div className={classes.button}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<SaveIcon />}
          onClick={() => {
            postData({
              CodigoPuestoLaboral: props.puestoLaboral.CodigoPuestoLaboral,
              Relaciones: relacionesInternas.concat(relacionesExternas),
              Usuario: userName,
              // CodigoJornadaLaboral: CodigoJornada,
              // CodigoLicenciaConducir: CodigoLicencia,
              // CodigoUbicacionLaboral: CodigoUbicacion,
              // DisponibilidadViajar: DisponibilidadViajar,
              // ExperienciaMinimaTxt: ExperienciaMinimaTxt,
              // ExperienciaRequeridaTxt: ExperienciaRequeridaTxt,
              // UsuarioInserto: userName,
              // CodigoPuestoLaboral: props.puestoLaboral.CodigoPuestoLaboral
            });
          }}
        >Guardar</Button>
      </div>
      <Grid container spacing={3} style={{ marginTop: "auto" }}>
        <Grid item md={6} className={classes.datoContent} style={{ backgroundColor: "gainsboro" }}>
          <Typography variant="h6" className={classes.titulo}>Relaciones Internas</Typography>
        </Grid>
        <Grid item md={6} className={classes.datoContent} style={{ backgroundColor: "gainsboro" }}>
          <Typography variant="h6" className={classes.titulo}>Relaciones Externas</Typography>
        </Grid>

        <Grid item md={6} className={classes.datoContent}>
          {catalogoRelaciones.filter((categ) => { return categ.TipoRelacion === 1 && verificarRelaciones(categ) }).map((categoria, idx) => (
            <React.Fragment>
              <Grid item container md={12} className={classes.datoContentActividad}>
                <Grid item md={1}>
                  <div className={classes.labelContainer}>
                    <FiberManualRecordIcon className={classes.icon} />
                    {/* <Typography className={classes.datoTxt}>{idx + 1} </Typography> */}
                  </div>
                </Grid>
                <Grid item md={11}>
                  <Typography className={classes.label}>{categoria ? categoria.Categoria : "-"}</Typography>
                </Grid>
              </Grid>
              {categoria.Relaciones.filter((rela) => { return relacionesInternas.includes(rela.CodigoRelacion) }).map((relacion, idy) => (
                <Grid item container md={12} className={classes.datoContentActividad}>
                  <Grid item md={2}>
                  </Grid>
                  <Grid item md={10}>
                    <Typography className={classes.label}>{relacion ? relacion.Descripcion : "-"}</Typography>
                  </Grid>
                </Grid>
              ))}
            </React.Fragment>
          ))}
          <br />
          <br />

          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="grouped-select">Relaciones internas...</InputLabel>
            <Select value={relacionesInternas} id="grouped-select" multiple fullWidth={true} onChange={handleChangeInternas}>
              {/* <MenuItem value="">
              <em>None</em>
            </MenuItem> */}
              {catalogoRelaciones.filter((categ) => { return categ.TipoRelacion === 1 }).map((categoria, idx) => (
                //<React.Fragment key={idx}>

                // <ListSubheader key={idx} style={{ backgroundColor: "white" }}>{categoria.Categoria}</ListSubheader>
                // {categoria.Relaciones.map((relacion, idy) => (
                //   <MenuItem key={idy} value={relacion.CodigoRelacion}>{relacion.Descripcion}</MenuItem>
                // ))}
                renderSelectGroup(categoria)

                //</React.Fragment>
              ))}

            </Select>
          </FormControl>
          <br />
          <br />
        </Grid>


        <Grid item md={6} className={classes.datoContent}>
          {catalogoRelaciones.filter((categ) => { return categ.TipoRelacion === 2 && verificarRelaciones(categ) }).map((categoria, idx) => (
            <React.Fragment>
              <Grid item container md={12} className={classes.datoContentActividad}>
                <Grid item md={1}>
                  <div className={classes.labelContainer}>
                    <FiberManualRecordIcon className={classes.icon} />
                    {/* <Typography className={classes.datoTxt}>{idx + 1} </Typography> */}
                  </div>
                </Grid>
                <Grid item md={11}>
                  <Typography className={classes.label}>{categoria ? categoria.Categoria : "-"}</Typography>
                </Grid>
              </Grid>
              {categoria.Relaciones.filter((rela) => { return relacionesExternas.includes(rela.CodigoRelacion) }).map((relacion, idy) => (
                <Grid item container md={12} className={classes.datoContentActividad}>
                  <Grid item md={2}>
                  </Grid>
                  <Grid item md={10}>
                    <Typography className={classes.label}>{relacion ? relacion.Descripcion : "-"}</Typography>
                  </Grid>
                </Grid>
              ))}
            </React.Fragment>
          ))}
          <br />
          <br />


          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="grouped-select">Relaciones externas...</InputLabel>
            <Select value={relacionesExternas} id="grouped-select" multiple fullWidth={true} onChange={handleChangeExternas}>
              {catalogoRelaciones.filter((categ) => { return categ.TipoRelacion === 2 }).map((categoria, idx) => (
                renderSelectGroup(categoria)
              ))}

            </Select>
          </FormControl>
          <br />
          <br />
        </Grid>







      </Grid>



    </React.Fragment >
  );
};

export default Relaciones;
