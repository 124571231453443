import React from "react";
import TextField from "@material-ui/core/TextField";
import "../css/notificaciones-crear.css";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import JoditEditor from "jodit-react";
import { IconButton, Typography } from "@material-ui/core";
import { PhotoCamera } from "@material-ui/icons";

const UploadInput = ({ id, setFoto, foto }) => {

  const onFileChange = (e, file) => {
    file = file || e.target.files[0];
    setFoto(file);
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
      <input
        accept="image/*"
        style={{ display: 'none' }}
        id={id}
        type="file"
        onChange={onFileChange}
      />
      <label htmlFor={id}>
        <IconButton color="primary" aria-label="upload picture" component="span">
          <PhotoCamera />
        </IconButton>
      </label>
      <label htmlFor={id}>
        {foto ? <Typography color='primary'>Imagen cargada</Typography> :
          <Typography color='textPrimary'>* Selecciona una imagen</Typography>}
      </label>
    </div>
  )
}

function PasoUnoFormulario(props) {
  const { classes } = props;
  return (
    <div className="FormularioContainer">
      <TextField
        id="standard-full-width"
        label="Titulo"
        style={{ margin: 8 }}
        placeholder="Ej. Comunicado ILU"
        fullWidth
        defaultValue={props.titulo}
        value={props.titulo}
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(event) => {
          props.guardarInputs("titulo", event.target.value);
        }}
      />
      <br />
      <TextField
        id="standard-full-width"
        label="Descripción corta"
        style={{ margin: 8 }}
        placeholder=""
        fullWidth
        defaultValue={props.descripcion}
        value={props.descripcion}
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(event) => {
          props.guardarInputs("descripcion", event.target.value);
        }}
      />
      <br />
      {/* <TextField
        id="standard-full-width"
        label="Cuerpo del mensaje"
        defaultValue={props.cuerpo}
        value={props.cuerpo}
        multiline
        rows="4"
        margin="normal"
        InputLabelProps={{
          shrink: true
        }}
        className="FormularioTextMultiline"
        onChange={event => {
          props.guardarInputs("cuerpo", event.target.value);
        }}
      /> */}
      <JoditEditor
        ref={null}
        value={props.cuerpo}
        config={{ readonly: false }}
        tabIndex={1}
        onBlur={(newContent) => props.guardarInputs("cuerpo", newContent)}
      //onChange={(newContent) => props.guardarInputs("cuerpo", newContent)}
      />
      <br />
      <UploadInput
        id="raised-button-file"
        setFoto={foto => props.guardarInputs("imagen", foto)}
        foto={props.imagen}
      />
      {/* <input
        accept="image/*"
        className={classes.input}
        style={{ display: "none" }}
        id="raised-button-file"
        onChange={(event) => {
          props.guardarInputs("imagen", event.target.files[0]);
        }}
        type="file"
      />
      <label htmlFor="raised-button-file">
        <Button variant="raised" component="span" className={classes.button}>
          Imagen
        </Button>
      </label> */}
    </div>
  );
}

const styles = (theme) => ({
  button: {
    margin: theme.spacing.unit,
  },
  input: {
    display: "none",
  },
});

export default withStyles(styles)(PasoUnoFormulario);
