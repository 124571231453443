import React from "react";
import {Grid } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import OkCancelButtons from "./ok-cancel-buttons";


function ProcessTitle({textoActual, titulo,cambiarTextoFn}) {
  const [editableMode, setEditableMode] = React.useState(false);
  const [nameState, setNameState] = React.useState("");
  const [nameBk, setNameBk] = React.useState("");
  

  React.useEffect(() => {    
    setNameState(textoActual);
    setNameBk(textoActual);
  }, [textoActual]);

  const setEdit = (value) => {
    
    setEditableMode(value);
    setNameBk(nameState);
  };

  const cancelFn = () => {
    setNameState(nameBk);
    setEditableMode(false);
  };

  const handleChangeName = async (event) => {
    setNameState(event.target.value);
  };

  const keyPress = (e) => {
    if (e.keyCode == 13) {
      save();
    } else if (e.keyCode == 27) {
      cancelFn();
    }
  };

  const save = async () => {
    if (nameBk !== nameState && nameState !== "") {
      //code to save      
      await cambiarTextoFn(nameState);
      setEdit(false);      
    }
  };

  return (
    <React.Fragment>
      {editableMode && (
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12} sm={12}>
            <TextField
              id="txtName"
              label={titulo}
              variant="outlined"
              value={nameState}
              style={{ width: "100%", marginTop: 20 }}
              onChange={handleChangeName}
              onKeyDown={keyPress}
              autoFocus
            />
          </Grid>
          <Grid item xs={12} lg={12} sm={12}>
            <OkCancelButtons okFn={save} cancelFn={cancelFn} />
          </Grid>
        </Grid>
      )}

      {editableMode === false && (
        <h1 style={{width:"100%", textAlign:'center'}}
          onClick={() => setEdit(true)}          
          className="editable-object"
        >
          {`${titulo}: ${nameState} `}
        </h1>
      )}
    </React.Fragment>
  );
}

export default ProcessTitle;
