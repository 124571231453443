import React, { useState, useEffect } from 'react'
import Layout from '../layout/containers/Layout'
import {
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Checkbox,
  Grid,
  makeStyles,
} from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { toast, ToastContainer } from 'react-toastify'
import { Alert } from '@material-ui/lab'
import SelectUsuario from './componentes/usuarios/SelectUsuario'
import Loading from './componentes/utils/Loading'
import LoadingButton from './componentes/utils/LoadingButton'
import api from '../utils/api'
import { getUsuarioInserta } from './utils/utilidades'

const useStyles = makeStyles(theme => ({
  list: {
    overflowY: 'auto',
    height: 400,
    background: grey[200]
  }
}))

const AsignarDocumentosUsuario = () => {
  const classes = useStyles()
  const { documentos } = useStoreState(state => ({
    documentos: state.documentos
  }))
  const { getDocumentos, setDocumentos } = useStoreActions(actions => ({
    getDocumentos: actions.getDocumentos,
    setDocumentos: actions.setDocumentos
  }))
  const [autor, setAutor] = useState(null)
  const [receptor, setReceptor] = useState(null)
  const [docs, setDocs] = useState([])
  const [loading, setLoading] = useState(false)
  const handleCheck = (index) => {
    const items = [...docs]
    items[index].checked = !items[index].checked
    setDocs(items)
  }
  const getDocs = async () => {
    try {
      setLoading(true)
      await getDocumentos(autor.CodigoUsuario)
    } catch (error) {
      toast.error('Ha ocurrido un error al cargar los documentos')
    } finally {
      setLoading(false)
    }
  }
  const selectAll = () => {
    const items = [...docs].map(i => ({...i, checked: !i.checked}))
    setDocs(items)
  }
  const transferirDocs = async () => {
    if (autor === null) {
      toast.error("Selecciona el autor original")
      return
    }
    if (receptor === null) {
      toast.error("Selecciona el nuevo autor")
      return
    }
    if (docs.filter(i => i.checked).length === 0) {
      toast.error("Selecciona los documentos a transferir")
      return
    }
    if (autor.CodigoUsuario === receptor.CodigoUsuario) {
      toast.error("El autor original y el nuevo autor deben ser diferentes")
      return
    }
    if (window.confirm('¿Estás seguro de transferir los archivos?')) {
      try {
        setLoading(true)
        const bodies = docs
          .filter(i => i.checked)
          .map(i => ({
              IdAutorOriginal: autor.CodigoUsuario,
              IdAutorNuevo: receptor.CodigoUsuario,
              IdDocumento: i.IdVersionDocumento,
              Usuario: getUsuarioInserta()
          }))
        const requests = bodies.map(body => api.realizarPeticionPostPut('api/documentos/transferir', body, 'POST'))
        await Promise.all(requests)
        await getDocs()
        toast.success('Los documentos han sido transferidos')
      } catch (error) {
        console.log(error)
        toast.error('Ha ocurrido un error al transferir los documentos')
      } finally {
        setLoading(false)
      }
    }
  }
  useEffect(() => {
    if (autor != null) {
      getDocs()
    }
  }, [autor])
  useEffect(() => {
    if (autor === null) {
      setDocumentos([])
    }
  }, [])
  useEffect(() => {
    setDocs(documentos.map(i => ({checked: true, ...i})))
  }, [documentos])
  return <Layout titulo="Transferencia de archivos">
    <Grid container justify="center">
      <Grid item sm={12} md={8}>
        <Grid container spacing={3} justify="center">
          <Grid item sm={12}>
            <SelectUsuario label="Autor original" onSelect={setAutor} />
          </Grid>
          <Grid item sm={12}>
            <SelectUsuario label="Nuevo autor" onSelect={setReceptor} />
          </Grid>
          <Grid item sm={12}>
            <List className={classes.list}>
              {
                (autor && docs.length === 0) && <Alert severity="info" variant="filled">
                  No hay registros por mostrar
                </Alert>
              }
              {
                (!autor && docs.length === 0) && <Alert severity="info" variant="filled">
                  Selecciona un autor
                </Alert>
              }
              {
                docs.length > 0 && <ListItem role={undefined} dense button onClick={selectAll}>
                  <ListItemText primary="Seleccionar todo" />
                </ListItem>
              }
              {
                docs.map((i, idx) => <ListItem key={idx}>
                  <ListItemAvatar>
                    <Checkbox
                      checked={i.checked}
                      onChange={ev => handleCheck(idx)}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={<><strong>{i.titulo}</strong> {i.Correlativo}</>}
                    secondary={i.descripcion}
                  />
                </ListItem>)
              }
            </List>
          </Grid>
          <Grid sm={6}>
            <LoadingButton
              fullWidth
              color="primary"
              variant="contained"
              onClick={transferirDocs}
            >Transferir</LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    <Loading loading={loading} />
    <ToastContainer />
  </Layout>
}

export default AsignarDocumentosUsuario
