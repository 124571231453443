import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Checkbox,
  Divider,
  Paper,
  Tooltip,
  Modal,
  Fade,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Box,
} from "@material-ui/core";

import BackdropModal from "@material-ui/core/Backdrop";

import clsx from "clsx";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Chip } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";

import {
  ChromeReaderMode,
  Edit,
  People,
  AttachFile,
  Cached,
  Delete,
  Visibility,
  Label,
  ViewList,
  Add,
  RadioButtonChecked,
  RadioButtonUnchecked,
  ContactPhoneSharp,
  PanoramaVerticalTwoTone,
} from "@material-ui/icons";

import { green } from "@material-ui/core/colors";
import { NavLink } from "react-router-dom";

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import DataGrid, {
  Column,
  Pager,
  Paging,
  Sorting,
  ColumnFixing,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Grouping,
  GroupPanel,
  Export,
  Selection,
  Editing,
  Scrolling,
} from "devextreme-react/data-grid";

import {
  Timeline,
  TimelineItem,
  TimelineContent,
  TimelineSeparator,
  TimelineConnector,
  TimelineDot,
} from "@material-ui/lab";

import API from "../../utils/api";
import { isSignedIn, signOut } from "../../utils/auth";
import { validaPermisoPaginaActual } from "../../utils/helpers";
import { ToastContainer, toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import "devextreme/dist/css/dx.material.teal.light.compact.css";

const width_proportion = "100%";
const titleColor = "#2B3C4D";

const useStyles = makeStyles((theme) => ({
  table: {
    width: "95%",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 210,
  },
  textFieldDireccion: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 230,
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  greenAvatar: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  paperCard: {
    padding: 20,
    width: "100%",
  },
  formControlSelects: {
    // margin: theme.spacing(1),
    minWidth: 600,

    marginBottom: 10,
    marginTop: 10,
    //width: "100%",
    marginLeft: 30,
  },
  formControlSelect: {
    // margin: theme.spacing(1),
    width: width_proportion,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  rootTabla: {
    width: "100%",
    marginTop: theme.spacing.unit * 1,
    overflowX: "auto",
  },
  modal: {
    // display: "flex",
    // alignItems: "center",
    // justifyContent: "center",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: "10%",
    left: "20%",
    overflow: "scroll",
    height: "100%",
    display: "block",
    padding: theme.spacing(8, 4, 8, 8),
  },
  paperModal: {
    position: "absolute",
    width: "90%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  itemContent: {
    padding: "6px 2px",
    minWidth: "10rem",
    textAlign: "center",
    fontWeight: "bold",
  },
  box: {
    padding: "1rem",
  },
}));

const CompetenciasPuestoLaboral = (props) => {
  const classes = useStyles();
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors: fieldsErrors },
    register,
  } = useForm();

  let applyFilterTypes = [
    {
      key: "auto",
      name: "Immediately",
    },
    {
      key: "onClick",
      name: "On Button Click",
    },
  ];

  const [userName, setUserName] = useState("");
  const [open, setOpen] = useState(false);

  const [editable, setEditable] = useState(true);
  const [isNew, setIsNew] = useState(true);

  const [
    codigoPuestoLaboralConductaObservable,
    setCodigoPuestoLaboralConductaObservable,
  ] = useState(null);
  const [listacompetencias, setListaCompetencias] = useState([]);
  const [listacompetenciasSelected, setListaCompetenciasSelected] = useState(
    []
  );

  const [valueAutoCompleteSkill, setValueAutoCompleteSkill] = useState(null);
  const [codigoCompetencia, setCodigoCompetencia] = useState(null);
  const [tipoSkill, setTipoSkill] = useState(null);
  const [evidencia, setEvidencia] = useState("");
  const [dominio, setDominio] = useState(1);
  const [descripcionDominio, setdescripcionDominio] = useState("");
  const [valoracion, setValoracion] = useState(0);
  const [listaPuestoLaboralCompetencias, setListaPuestoLaboralCompetencias] =
    useState([]);

  const [listaConductasObservable, setListaConductasObservable] = useState([]);

  const [etiquetas, setEtiquetas] = useState([]);
  const [etiquetasSelected, setEtiquetasSelected] = useState([]);

  const [dialogoAbierto, abrirDialogo] = useState(false);

  const [showFilterRow, setShowFilterRow] = useState(true);
  const [showHeaderFilter, setShowHeaderFilter] = useState(true);
  const [currentFilter, setCurrentFilter] = useState(applyFilterTypes[0].key);
  const [autoExpandAll, setAutoExpandAll] = useState(true);

  const [buscando, setBackDrop] = useState(false);

  const handleCerrarBackDrop = () => {
    setBackDrop(false);
  };
  const handleAbrirBackDrop = () => {
    setBackDrop(true);
  };

  const handleOpenModal = () => {
    setIsNew(true);
    setEditable(false);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setCodigoPuestoLaboralConductaObservable(null);
    setValueAutoCompleteSkill(null);
    setCodigoCompetencia(null);
    setTipoSkill(null);
    setEvidencia("");
    setDominio(4);
    setdescripcionDominio("");
    setValoracion(0);
    setOpen(false);
    setEditable(true);
    setIsNew(false);
  };

  useEffect(() => {
    const cargar = async () => {
      // CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
      const llaveAgrupacion = "Menu.Principal.Web";
      const llavePagina = "Menu.Principal.Web.PuestoLaboral";
      await validaPermisoPaginaActual(llaveAgrupacion, llavePagina);
      // FIN DE CONSULTA DE PERMISOS
      if (props.puestoLaboral !== undefined) {
        handleAbrirBackDrop();
        // await cargarListaEtiquetas();
        await cargarListaCompetencias();
        await cargarCompetenciasPuesto();
        handleCerrarBackDrop();
      }
    };

    cargar();
  }, [props.puestoLaboral]);

  const cargarListaCompetencias = async () => {
    console.log("Este es el tipo de skill selected", props.tipoSkillSelected);
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();
      let uri = "api/competencia/listar";

      const requestSkillList = await API.fetchGetRequest(uri);

      if (requestSkillList.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setListaCompetencias(requestSkillList.data.data);
        setListaCompetenciasSelected(requestSkillList.data.data);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };
  const cargarListaEtiquetas = async () => {
    const request = await API.fetchGetRequest(
      `api/competencia/etiqueta/listar`
    );
    if (Array.isArray(request.data.data)) {
      console.log("Las etiquetas", request.data.data);
      setEtiquetas(request.data.data);
    }
  };

  const cargarCompetenciasPuesto = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri =
        "api/PuestoLaboralConductaObservable/" +
        props.puestoLaboral.CodigoPuestoLaboral;
      console.log("Uri", uri);
      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        console.log("Esta es la data de competencias", request.data);
        setListaPuestoLaboralCompetencias(request.data.data);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cargarConductasObservables = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/ConductaObservable/" + codigoCompetencia;
      console.log("Uri", uri);
      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setListaConductasObservable(request.data.data);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const guardarDato = async () => {
    // if(valueAutoCompleteSkill == null
    // || valueAutoCompleteSkill == undefined) {
    //     toast.error("Debe escoger una competencia");
    //     return;
    // }
    // if(descripcionDominio==""||descripcionDominio==null
    // || evidencia==""||evidencia==null
    // || evidencia==""||evidencia==null
    // || tipoSkill==undefined||tipoSkill==null) {
    //     toast.error("Debe llenar todos los datos");
    //     return;
    // }
    // console.log("Puesto laboral", props.puestoLaboral);
    const data = {
      // CodigoPuestoLaboralCompetencia: codigoPuestoLaboralCompetencia,
      CodigoCompetencia: valueAutoCompleteSkill.CodigoCompetencia, //técnicas,
      CodigoPuestoLaboral: props.puestoLaboral.CodigoPuestoLaboral,
      // NivelDominio: dominio,
      // DescripcionDominio: descripcionDominio,
      // EvidenciaRequerida: evidencia,
      // RelevanciaPuesto: valoracion,
      // TipoCompetencia: +tipoSkill,
      Activo: true,
      Eliminado: false,
      UsuarioOpera: userName,
    };

    // console.log("Datos a enviar", data);
    // dataFicha.usuarioInserto = "prueba";
    try {
      handleAbrirBackDrop();
      let vIsSignedIn = await isSignedIn();
      if (vIsSignedIn.response) {
        let request;

        request = await API.post("api/PuestoLaboralConductaObservable", data);
        if (request.status !== 200) {
          toast.error(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        } else {
          toast.success(request.data.mensaje);
          setValueAutoCompleteSkill(null);
          setCodigoCompetencia(null);
        }
      }
    } catch (e) {
      console.log("EXCEPTION: " + e);
      toast.error(
        "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
      );
    } finally {
      await cargarCompetenciasPuesto();
      handleCloseModal();
      handleCerrarBackDrop();
    }
  };

  const quitarProyecto = async (codigo) => {
    try {
      handleCerrarDialogo();
      handleAbrirBackDrop();
      let vIsSignedIn = await isSignedIn();

      if (vIsSignedIn.response) {
        const request = await API.fetchGetRequest(
          `api/PuestoLaboralConductaObservable/eliminar/${codigo}/${vIsSignedIn.userName}`
        );
        // if (request.status !== 200) {
        //   alert(
        //     "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
        //   );
        // }

        if (request.statusCode === 401) {
          //alert("cerrar sesion");
        } else {
          toast.success("El registro se eliminó exitosamente.");
          await cargarCompetenciasPuesto();
        }
      } else {
        toast.error("La sessión no se encuentra activa");
      }
    } catch (error) {
      toast.error(error);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cuandoHayError = async (data) => {
    toast.info(
      "Existen algunos campos con error, por favor valida el formulario",
      {
        position: "top-right",
        autoClose: 3000, // Esta en milisegundos 3 segundos
      }
    );
  };

  const handleOnChangeSkill = async (event, newValue) => {
    handleAbrirBackDrop();
    if (newValue !== null) {
      setTipoSkill(null);
      setValueAutoCompleteSkill(newValue);
      setCodigoCompetencia(newValue.CodigoCompetencia);
    }
    handleCerrarBackDrop();
  };

  const handleChangeTipoSkill = async (event) => {
    setTipoSkill(event.target.value);
  };

  const handleAbrirDialogoEliminar = (id) => {
    abrirDialogo(true);
    setCodigoPuestoLaboralConductaObservable(id);
  };

  const handleCerrarDialogo = () => {
    abrirDialogo(false);
    setCodigoPuestoLaboralConductaObservable(null);
  };

  const handleOnFilter = async (options) => {
    if (options.length > 0) {
      let array = listacompetencias;
      function filtrarPorEtiqueta(element, options) {
        for (let i = 0; i < element.Etiquetas.length; i++) {
          let etiqueta = element.Etiquetas[i];
          let result = options.filter(
            (o) => o.CodigoEtiqueta == etiqueta.CodigoEtiqueta
          );
          if (result.length > 0) return true;
        }
        return false;
      }
      var newarray = array.filter((element) =>
        filtrarPorEtiqueta(element, options)
      );
      setListaCompetenciasSelected(newarray);
    } else {
      setListaCompetenciasSelected(listacompetencias);
    }
    setEtiquetasSelected(options);
  };

  const handleEditarSkill = async (info, editar) => {
    setCodigoPuestoLaboralConductaObservable(
      info.CodigoPuestoLaboralCompetencia
    );
    let skill = listacompetencias.find(
      (element) => element.CodigoCompetencia == info.CodigoCompetencia
    );
    setValueAutoCompleteSkill(skill);
    setCodigoCompetencia(skill.CodigoCompetencia);
    setTipoSkill(info.TipoCompetencia);
    setEvidencia(info.EvidenciaRequerida);
    setDominio(info.NivelDominio);
    setdescripcionDominio(info.DescripcionDominio);
    setValoracion(info.RelevanciaPuesto);
    setOpen(true);
    setIsNew(false);
    setEditable(editar);
  };

  const ratingStars = (data) => {
    return <Rating name="read-only" value={data.value} readOnly />;
  };
  const ratingStarsDominio = (data) => {
    return <Rating name="read-only" max={4} value={data.value} readOnly />;
  };

  const cellOpciones = (rowInfo) => {
    return (
      <>
        <Tooltip
          title="Eliminar"
          style={{ marginRight: 10, cursor: "pointer" }}
        >
          <Delete
            style={{ color: "#337ab7", cursor: "pointer" }}
            onClick={() =>
              handleAbrirDialogoEliminar(
                rowInfo.data.CodigoPuestoLaboralConductaObservable
              )
            }
          />
        </Tooltip>

        <Tooltip title="Editar" style={{ marginRight: 10, cursor: "pointer" }}>
          <Edit
            style={{ color: "#337ab7", cursor: "pointer" }}
            onClick={() => handleEditarSkill(rowInfo.data, true)}
          />
        </Tooltip>

        {/* <Tooltip title="Ver" style={{ marginRight: 10, cursor: "pointer" }}>
          <Visibility
            style={{ color: "#337ab7", cursor: "pointer" }}
            onClick={() => handleEditarSkill(rowInfo.data, false)}
          />
        </Tooltip> */}
      </>
    );
  };

  useEffect(() => {
    const cargar = async () => {
      await cargarConductasObservables();
    };

    if (codigoCompetencia !== null) {
      cargar();
    }
  }, [codigoCompetencia]);

  return (
    <React.Fragment>
      <Backdrop className={classes.backdrop} open={buscando}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer />
      <Dialog
        open={dialogoAbierto}
        onClose={handleCerrarDialogo}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Eliminación</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro que deseas eliminar este registro?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCerrarDialogo} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={() =>
              quitarProyecto(codigoPuestoLaboralConductaObservable)
            }
            color="primary"
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paperModal}>
            <h2 id="simple-modal-title">
              {isNew ? "Agregar " : editable ? "Editar " : ""} Competencia
            </h2>
            <Grid container spacing={4} direction="row">
              <Grid item>
                <Grid container direction="column">
                  <form
                    autoComplete="off"
                    onSubmit={handleSubmit(guardarDato, cuandoHayError)}
                  >
                    <Grid item>
                      <FormControl className={classes.formControlSelects}>
                        <Autocomplete
                          id="listSkill"
                          disabled={!(!editable && isNew)}
                          options={listacompetenciasSelected}
                          getOptionLabel={(option) =>
                            option.TipoSkill + " " + option.Nombre
                          }
                          value={valueAutoCompleteSkill}
                          onChange={(event, newValue) =>
                            handleOnChangeSkill(event, newValue)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Competencia"
                              variant="outlined"
                            />
                          )}
                        />
                      </FormControl>

                      {listaConductasObservable.length > 0 ? (
                        <Grid item>
                          <Typography variant="h6">
                            Conductas Observables
                          </Typography>
                          <Paper>
                            <DataGrid
                              dataSource={listaConductasObservable}
                              showBorders={true}
                              rowAlternationEnabled={true}
                              allowColumnReordering={true}
                              allowColumnResizing={true}
                              columnAutoWidth={true}
                            >
                              <Column dataField="Nombre" caption="Nombre" />
                              <Paging defaultPageSize={10} />
                              <Pager
                                showPageSizeSelector={true}
                                allowedPageSizes={[5, 10, 20]}
                                showInfo={true}
                              />
                            </DataGrid>
                          </Paper>
                        </Grid>
                      ) : (
                        <Typography variant="h6">
                          No hay conductas observables
                        </Typography>
                      )}
                    </Grid>

                    <Grid item>
                      {/* <FormControl className={classes.formControlSelects}>
                        <Typography component="legend">
                          Nivel de dominio
                        </Typography>
                        
                        <Rating
                          name="customized-11"
                          defaultValue={1}
                          disabled={!(editable || isNew)}
                          max={4}
                          value={dominio}
                          onChange={(event, newValue) => setDominio(newValue)}
                        />
                      </FormControl> */}
                      {(editable || isNew) && (
                        <Button
                          width="100%"
                          variant="contained"
                          style={{
                            backgroundColor: "#69bd4b",
                            marginBottom: 10,
                            marginTop: 10,
                            color: "white",
                            width: "93%",
                            marginLeft: 30,
                          }}
                          onClick={guardarDato}
                        >
                          &nbsp;{isNew ? "Registrar " : "Editar "} Competencia
                        </Button>
                      )}
                    </Grid>
                  </form>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>

      <AppBar
        position="sticky"
        className={classes.appBar}
        style={{ backgroundColor: titleColor }}
      >
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            style={{ display: "flex", flexGrow: 1 }}
          >
            {`Competencias`}
          </Typography>

          <Tooltip
            title={"Agregar Competencia"}
            aria-label="Agregar Competencia"
          >
            <IconButton
              aria-label="Agregar Competencia"
              style={{ color: "white" }}
            >
              <Add onClick={handleOpenModal} />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <Paper className={classes.rootTabla}>
        <DataGrid
          dataSource={listaPuestoLaboralCompetencias}
          //   defaultColumns={this.props.columns}
          showBorders={true}
          rowAlternationEnabled={true}
          //ref={(ref) => (dataGrid = ref)}
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          className={classes.table}
        >
          <FilterRow visible={showFilterRow} applyFilter={currentFilter} />
          <HeaderFilter visible={showHeaderFilter} />
          <GroupPanel visible={false} />
          <Grouping autoExpandAll={autoExpandAll} />
          <SearchPanel visible={false} width={240} placeholder="Search..." />
          {/* <Selection mode="multiple" /> */}
          <Editing
            mode="row"
            useIcons={true}
            allowUpdating={false}
            allowDeleting={false}
          />
          <Column
            caption="Opciones"
            allowFiltering={false}
            allowSorting={false}
            cellRender={cellOpciones}
          />
          <Column dataField="TipoSkill" caption="Tipo" />

          <Column dataField="Competencia" caption="Competencia" />
          <Column
            dataField="ConductaObservable"
            caption="Conducta Observable"
          />
          {/* <Column dataField="Descripcion" caption="Descripción" /> */}
          {/* <Column
            dataField="Orden"
            caption="Nivel Dominio"
            cellRender={ratingStarsDominio}
          />
          <Column dataField="NivelDominioCompetencia" caption="Nivel Dominio" /> */}

          <ColumnFixing enabled={true} />
          <Sorting mode="multiple" />
          <Paging defaultPageSize={20} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[10, 20, 50]}
            showInfo={true}
          />
          <Export enabled={true} allowExportSelectedData={true} />
          <Scrolling columnRenderingMode="virtual" />
        </DataGrid>
      </Paper>
    </React.Fragment>
  );
};

export default CompetenciasPuestoLaboral;
