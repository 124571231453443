import React from 'react';

function TableTitle(props) {
    return (
        <span style={styles.title}>
            {props.children}
        </span>
    );
}

const styles = {
    title: {
        fontSize: 12,
        fontWeight: 'bold'
    }
};

export default TableTitle;