import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Checkbox,
  FormControlLabel,
  Divider,
  Paper,
  Tooltip,
  Modal,
  Fade,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
} from "@material-ui/core";

import BackdropModal from "@material-ui/core/Backdrop";

import clsx from "clsx";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import {
  ChromeReaderMode,
  Edit,
  People,
  AttachFile,
  Cached,
  Delete,
  Label,
  ViewList,
  Add,
} from "@material-ui/icons";

import { green } from "@material-ui/core/colors";
import { NavLink } from "react-router-dom";

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import DataGrid, {
  Column,
  Pager,
  Paging,
  Sorting,
  ColumnFixing,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Grouping,
  GroupPanel,
  Export,
  Selection,
  Editing,
  Scrolling,
  Lookup,
  LoadPanel,
} from "devextreme-react/data-grid";

import CustomStore from "devextreme/data/custom_store";

import API from "../../../utils/api";
import { isSignedIn, signOut } from "../../../utils/auth";
import { validaPermisoPaginaActual } from "../../../utils/helpers";
import { ToastContainer, toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
//import "devextreme/dist/css/dx.material.teal.light.compact.css";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import esMessages from "devextreme/localization/messages/es.json";
import { locale, loadMessages, formatMessage } from "devextreme/localization";

const width_proportion = "100%";
const titleColor = "#2B3C4D";

const position = { of: "#employee" };

const useStyles = makeStyles((theme) => ({
  table: {
    width: 800,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 210,
  },
  textFieldDireccion: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 230,
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  greenAvatar: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  paperCard: {
    padding: 20,
    width: "100%",
  },
  formControlSelects: {
    // margin: theme.spacing(1),
    // minWidth: 230,

    marginBottom: 10,
    marginTop: 10,
    width: "100%",
    marginLeft: 30,
  },
  formControlSelect: {
    // margin: theme.spacing(1),
    width: width_proportion,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  rootTabla: {
    width: "100%",
    marginTop: theme.spacing.unit * 1,
    overflowX: "auto",
    "text-align": "-webkit-center",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperModal: {
    position: "absolute",
    width: "70%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  ".dx-loadpanel-content": {
    padding: "10px",
    "text-align": "center",
    "-webkit-user-select": "none",
    "-moz-user-select": "none",
    "-ms-user-select": "none",
    "user-select": "none",
    "-webkit-touch-callout": "none",
    "-webkit-user-drag": "none",
    border: "1px solid #ddd",
    background: "#fff",
    "border-radius": "6px",
    "-webkit-box-shadow": "0 6px 12px rgb(0 0 0 / 25%)",
    "box-shadow": "0 6px 12px rgb(0 0 0 / 25%)",
  },
  ".dx-loadpanel-content-wrapper": {
    display: "inline-block",
    width: "100%",
    "vertical-align": "middle",
  },
  ".dx-loadpanel-message": {
    "text-align": "center",
  },
}));

const VerboComponente = () => {
  const classes = useStyles();

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors: fieldsErrors },
    register,
  } = useForm();

  let applyFilterTypes = [
    {
      key: "auto",
      name: "Immediately",
    },
    {
      key: "onClick",
      name: "On Button Click",
    },
  ];

  const [userName, setUserName] = useState("");
  const [open, setOpen] = useState(false);
  const [cargandoMaestros, setCargandoMaestros] = useState(false);

  const [verbos, setVerbos] = useState([]);

  const [tipoTurno, setTipoTurno] = useState([]);
  const [maestroNivelTipoTurno, setMaestroNivelTipoTurno] = useState([]);
  const [codigoTipoTurno, setCodigoTipoTurno] = useState(0);
  const [codigoNivelTipoTurno, setCodigoNivelTipoTurno] = useState(0);
  const [tipoTurnoNombre, setTipoTurnoNombre] = useState("");
  const [numeroVersion, setNumeroVersion] = useState("");
  const [fechaCreacion, setFechaCreacion] = useState(new Date());

  const [positionDisableSorting, setPositionDisableSorting] = useState(false);
  const [showFilterRow, setShowFilterRow] = useState(true);
  const [showHeaderFilter, setShowHeaderFilter] = useState(true);
  const [currentFilter, setCurrentFilter] = useState(applyFilterTypes[0].key);
  const [autoExpandAll, setAutoExpandAll] = useState(true);
  const [datosVerbos, setDatosVerbos] = useState(
    new CustomStore({
      key: "CodigoVerboNivelPuestoLaboral",
      load: () => cargarDatos("api/VerboNivelPuestoLaboral/Verbos"),
      insert: (values) =>
        guardarDato("api/VerboNivelPuestoLaboral/NuevoVerbo/", null, values),
      update: (key, values) =>
        guardarDato("api/VerboNivelPuestoLaboral/NuevoVerbo", key, values),
      remove: (key) =>
        guardarDato("api/VerboNivelPuestoLaboral/EliminarVerbo/", key, null),
    })
  );
  const [datosNiveles, setDatosNiveles] = useState(
    new CustomStore({
      key: "CodigoNivelPuestoLaboral",
      loadMode: "raw",
      load: () => cargarDatos("api/VerboNivelPuestoLaboral/NivelesPuestos"),
    })
  );

  const [valueAutoCompleteNivelTipoTurno, setValueAutoCompleteNivelTipoTurno] =
    useState(null);

  const [buscando, setBackDrop] = useState(false);

  const handleCerrarBackDrop = () => {
    setBackDrop(false);
  };
  const handleAbrirBackDrop = () => {
    setBackDrop(true);
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  // this.state = {
  //   datosVerbos: new CustomStore({
  //     key: 'CodigoVerboNivelPuestoLaboral',
  //     load: () => this.cargarDatos("api/VerboNivelPuestoLaboral/Verbos"),
  //     insert: (values) => this.guardarDato("api/NuevoVerbo/", null, values),
  //     update: (key, values) => this.guardarDato("api/NuevoVerbo/", key, values),
  //     remove: (key) => this.guardarDato("api/EliminarVerbo/", key, null),
  //   }),
  //   datosNiveles: new CustomStore({
  //     key: 'CodigoNivelPuestoLaboral',
  //     loadMode: 'raw',
  //     load: () => this.cargarDatos("api/VerboNivelPuestoLaboral/NivelesPuestos"),
  //   }),
  //   requests: [],
  //   refreshMode: 'reshape',
  // };

  useEffect(() => {
    //loadMessages(esMessages);
    //locale("es");
    const cargar = async () => {
      // CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
      const llaveAgrupacion = "Menu.Principal.Web";
      const llavePagina = "Menu.Principal.Web.PuestoLaboral";
      await validaPermisoPaginaActual(llaveAgrupacion, llavePagina);
      // FIN DE CONSULTA DE PERMISOS

      handleAbrirBackDrop();

      //await cargarMaestroVerbos();
      //await cargarMaestroNivelTipoTurno();

      handleCerrarBackDrop();
    };

    cargar();
  }, [cargandoMaestros]);

  const cargarDatos = async (url) => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      console.log(session);
      setUserName(session.userName);
    }

    try {
      //handleAbrirBackDrop();

      let uri = url;
      //await delay(1000000);
      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        return request.data.datos;
        //setVerbos(request.data.datos);
      }
    } catch (e) {
      console.log(e);
    } finally {
      //handleCerrarBackDrop();
    }
  };

  const cargarMaestroVerbos = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      console.log(session);
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/VerboNivelPuestoLaboral/Verbos";

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setVerbos(request.data.verbos);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const guardarDato = async (url, key, values) => {
    const data = {
      CodigoVerboNivelPuestoLaboral: key,
      CodigoNivelPuestoLaboral: values ? values.CodigoNivelPuestoLaboral : null,
      Verbo: values ? values.Verbo : null,
      Activo: values ? values.Activo : null,
      Eliminado: values ? values.Eliminado : null,
      Usuario: userName,
    };

    // dataFicha.usuarioInserto = "prueba";
    try {
      //handleAbrirBackDrop();
      let vIsSignedIn = await isSignedIn();
      if (vIsSignedIn.response) {
        let request;

        request = await API.post(url, data);
        if (request.status !== 200) {
          toast.error(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        } else {
          toast.success("Se guardó exitosamente");

          //await cargarMaestroTipoTurno();

          //setTipoTurnoNombre("");
          //setCodigoTipoTurno(0);

          //setValueAutoCompleteNivelTipoTurno(null);
        }
      }
    } catch (e) {
      console.log("EXCEPTION: " + e);
      toast.error(
        "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
      );
    } finally {
      //handleCerrarBackDrop();
      //handleCloseModal();
    }
  };

  // const guardarDato = async () => {
  //   const data = {
  //     CodigoTipoTurno: codigoTipoTurno,
  //     TipoTurno: tipoTurnoNombre,
  //     Activo: true,
  //     Eliminado: false,
  //     UsuarioInserto: userName,
  //     FechaInserto: "",
  //     UsuarioModifico: "",
  //     FechaModifico: "",
  //   };

  //   console.log(data);
  //   // dataFicha.usuarioInserto = "prueba";
  //   try {
  //     handleAbrirBackDrop();
  //     let vIsSignedIn = await isSignedIn();
  //     if (vIsSignedIn.response) {
  //       let request;

  //       request = await API.post("api/tipoTurno/", data);
  //       if (request.status !== 200) {
  //         toast.error(
  //           "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
  //         );
  //       } else {
  //         toast.success("Se guardó exitosamente");

  //         await cargarMaestroTipoTurno();

  //         setTipoTurnoNombre("");
  //         setCodigoTipoTurno(0);

  //         setValueAutoCompleteNivelTipoTurno(null);
  //       }
  //     }
  //   } catch (e) {
  //     console.log("EXCEPTION: " + e);
  //     toast.error(
  //       "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
  //     );
  //   } finally {
  //     handleCerrarBackDrop();
  //     handleCloseModal();
  //   }
  // };

  // const cuandoHayError = async (data) => {
  //   toast.info(
  //     "Existen algunos campos con error, por favor valida el formulario",
  //     {
  //       position: "top-right",
  //       autoClose: 3000, // Esta en milisegundos 3 segundos
  //     }
  //   );
  // };

  const handleDateChangeFechaCreacion = (date) => {
    setFechaCreacion(date);
  };

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const onExporting = async (e) => {
    // const workbook = new Workbook();
    // const worksheet = workbook.addWorksheet("Main sheet");
    // exportDataGrid({
    //   component: e.component,
    //   worksheet: worksheet,
    // }).then(function () {
    //   workbook.xlsx.writeBuffer().then(function (buffer) {
    //     saveAs(
    //       new Blob([buffer], { type: "application/octet-stream" }),
    //       "DataGrid.xlsx"
    //     );
    //   });
    // });
    // e.cancel = true;
  };

  // const [dialogoAbierto, abrirDialogo] = useState(false);
  // const [dialogoAbiertoEditar, abrirDialogoEditar] = useState(false);
  // const handleAbrirDialogoEliminar = (id) => {
  //   abrirDialogo(true);

  //   setCodigoTipoTurno(id);
  // };

  // const handleCerrarDialogo = () => {
  //   abrirDialogo(false);
  // };

  // const handleAbrirDialogoEditar = (id) => {
  //   abrirDialogoEditar(true);

  //   setCodigoTipoTurno(id);
  // };

  // const handleCerrarDialogoEditar = () => {
  //   abrirDialogoEditar(false);
  // };

  // const quitarProyecto = async (pId) => {
  //   handleCerrarDialogo();
  //   handleAbrirBackDrop();

  //   const proyectosExcluidos = tipoTurno.filter(
  //     (elemento) => elemento.CodigoTipoTurno !== pId
  //   );

  //   console.log("Quitar" + proyectosExcluidos);

  //   try {
  //     let uri = "api/tipoTurno/Quitar/" + pId;

  //     const request = await API.fetchGetRequest(uri);

  //     if (request.status === 401) {
  //       //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
  //       if (await signOut()) {
  //         window.location.url = "/Login";
  //       }
  //       toast.error(
  //         "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
  //       );
  //     } else {
  //       toast.success("Se quitó el registro con éxito", {
  //         position: "bottom-right",
  //         autoClose: 3000, // Esta en milisegundos 3 segundos
  //       });

  //       await cargarMaestroTipoTurno();
  //       setTipoTurno("");
  //       setCodigoTipoTurno(0);
  //       setCodigoNivelTipoTurno(0);

  //       setValueAutoCompleteNivelTipoTurno(null);
  //     }
  //   } catch (error) {
  //     console.log("Error en  eliminación de proyectos" + error);
  //   }

  //   handleCerrarBackDrop();

  //   //alert("Quitar recurso del proyecto" + codigoRecurso);
  // };

  // const editarProyecto = async (pId) => {
  //   handleCerrarDialogoEditar();
  //   handleAbrirBackDrop();

  //   const proyectosExcluidos = tipoTurno.filter(
  //     (elemento) => elemento.CodigoTipoTurno === pId
  //   );

  //   console.log("Editar el proyecto" + proyectosExcluidos);

  //   try {
  //     handleOpenModal();
  //     setCodigoTipoTurno(pId);
  //     setTipoTurnoNombre(proyectosExcluidos[0].tipoTurno);
  //     setNumeroVersion(proyectosExcluidos[0].NumeroVersion);
  //     setFechaCreacion(proyectosExcluidos[0].FechaCreacion);
  //     setCodigoNivelTipoTurno(
  //       proyectosExcluidos[0].CodigoNivelTipoTurno
  //     );

  //     const itemNivelTipoTurno = maestroNivelTipoTurno.filter(
  //       (elemento) =>
  //         elemento.CodigoNivelTipoTurno ===
  //         proyectosExcluidos[0].CodigoNivelTipoTurno
  //     );
  //     setValueAutoCompleteNivelTipoTurno(itemNivelTipoTurno[0]);
  //   } catch (error) {
  //     console.log("Error en  edición de proyectos" + error);
  //   }

  //   handleCerrarBackDrop();

  //   //alert("Quitar recurso del proyecto" + codigoRecurso);
  // };

  // const cellOpciones = (rowInfo) => {
  //   return (
  //     <>
  //       <Tooltip
  //         title="Descriptor de Proyectos"
  //         style={{ marginRight: 5, cursor: "pointer" }}
  //       >
  //         <NavLink
  //           onClick={(e) => {
  //             if (false) {
  //               toast.warning(
  //                 "No tienes permisos para gestionar los anexos el proyecto",
  //                 {
  //                   position: "top-right",
  //                   pauseOnHover: false,
  //                   autoClose: 3000,
  //                 }
  //               );
  //               e.preventDefault();
  //             }
  //             //     props.history.push(`/proyecto-editar/${rowInfo.data.id}`);
  //           }}
  //           to={`/puesto-laboral-descriptor/${rowInfo.data.CodigoTipoTurno}`}
  //         >
  //           <ViewList className={classes.sizeIcons} />
  //         </NavLink>
  //       </Tooltip>
  //       <Tooltip title="Editar" style={{ marginRight: 10, cursor: "pointer" }}>
  //         <Edit
  //           style={{ color: "#337ab7", cursor: "pointer" }}
  //           onClick={() =>
  //             handleAbrirDialogoEditar(rowInfo.data.CodigoTipoTurno)
  //           }
  //         />
  //       </Tooltip>
  //       <Tooltip
  //         title="Eliminar"
  //         style={{ marginRight: 10, cursor: "pointer" }}
  //       >
  //         <Delete
  //           style={{ color: "#337ab7", cursor: "pointer" }}
  //           onClick={() =>
  //             handleAbrirDialogoEliminar(rowInfo.data.CodigoTipoTurno)
  //           }
  //         />
  //       </Tooltip>
  //     </>
  //   );
  // };

  // const handleOnChangeTipoParentesco = async (event, newValue) => {
  //   handleAbrirBackDrop();
  //   if (newValue !== null) {
  //     setCodigoNivelTipoTurno(newValue.CodigoNivelTipoTurno);
  //     setValueAutoCompleteNivelTipoTurno(newValue);
  //   }
  //   handleCerrarBackDrop();
  // };

  return (
    <React.Fragment>
      <Backdrop className={classes.backdrop} open={buscando}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer />

      {/* <Dialog
        open={dialogoAbierto}
        onClose={handleCerrarDialogo}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Eliminación</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro que deseas eliminar este registro?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCerrarDialogo} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={() => quitarProyecto(codigoTipoTurno)}
            color="primary"
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={dialogoAbiertoEditar}
        onClose={handleCerrarDialogoEditar}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Edición</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro que deseas editar este registro?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCerrarDialogoEditar} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={() => editarProyecto(codigoTipoTurno)}
            color="primary"
          >
            Editar
          </Button>
        </DialogActions>
      </Dialog> */}
      {/* <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={BackdropModal}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paperModal}>
            <h2 id="simple-modal-title">Datos de Tipo Turno</h2>
            <Grid container spacing={3}>
              <form
                autoComplete="off"
                onSubmit={handleSubmit(guardarDato, cuandoHayError)}
              >
                <Grid item xs={6} sm={6} md={12} lg={12}>
                  <FormControl className={classes.formControlSelects}>
                    <Controller
                      name="nivelTipoTurno"
                      required
                      render={({ NivelTipoTurno }) => (
                        <Autocomplete
                          id="NivelTipoTurno"
                          options={maestroNivelTipoTurno}
                          getOptionLabel={(option) => option.NivelTipoTurno}
                          value={valueAutoCompleteNivelTipoTurno}
                          onChange={(event, newValue) =>
                            handleOnChangeTipoParentesco(event, newValue)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Nivel Puesto Laboral"
                              variant="outlined"
                            />
                          )}
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={6} sm={6} md={12} lg={12}>
                  <FormControl className={classes.formControlSelects}>
                    <Controller
                      name="TipoTurnoNombre"
                      render={({ TipoTurnoNombre }) => (
                        <TextField
                          id="tipoTurno"
                          label="Puesto Laboral"
                          name="tipoTurno"
                          value={tipoTurnoNombre}
                          onChange={(e) =>
                            setTipoTurnoNombre(e.target.value)
                          }
                          variant="outlined"
                          helperText={
                            fieldsErrors.tipoTurno
                              ? fieldsErrors.tipoTurno.message
                              : ""
                          }
                          //   inputRef={refTelefono}
                          //   {...inputPropsTelefono}

                          //   rules={{
                          //     pattern: { value: /[0-9]/, message: "Solo se aceptan números" },
                          //     minLength: { value: 8, message: "No menor a 8 dígitos" },
                          //     maxLength: { value: 8, message: "No mayor a 8 dígitos" },
                          //   }}
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                  <FormControl className={classes.formControlSelects}>
                    <Controller
                      name="noVersion"
                      render={({ NoVersion }) => (
                        <TextField
                          id="noVersion"
                          label="Número de Versión"
                          name="noVersion"
                          value={numeroVersion}
                          onChange={(e) => setNumeroVersion(e.target.value)}
                          variant="outlined"
                          helperText={
                            fieldsErrors.noVersion
                              ? fieldsErrors.noVersion.message
                              : ""
                          }
                          //   inputRef={refTelefono}
                          //   {...inputPropsTelefono}

                          //   rules={{
                          //     pattern: { value: /[0-9]/, message: "Solo se aceptan números" },
                          //     minLength: { value: 8, message: "No menor a 8 dígitos" },
                          //     maxLength: { value: 8, message: "No mayor a 8 dígitos" },
                          //   }}
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                  <FormControl className={classes.formControlSelects}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid container justify="flex-start">
                        <KeyboardDatePicker
                          disableToolbar
                          variant="inline"
                          format="dd/MM/yyyy"
                          margin="normal"
                          id="date-picker-inline"
                          label="Fecha de Creación"
                          value={fechaCreacion}
                          onChange={handleDateChangeFechaCreacion}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          required
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} md={12} lg={12}>
                  <Button
                    width="100%"
                    variant="contained"
                    style={{
                      backgroundColor: "#69bd4b",
                      marginBottom: 10,
                      marginTop: 10,
                      color: "white",
                      width: "93%",
                      marginLeft: 30,
                    }}
                    type="submit"
                  >
                    &nbsp;Guardar Cambios
                  </Button>
                </Grid>
              </form>
            </Grid>
          </div>
        </Fade>
      </Modal> */}
      <AppBar
        position="sticky"
        className={classes.appBar}
        style={{ backgroundColor: titleColor }}
      >
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            style={{ display: "flex", flexGrow: 1 }}
          >
            {`Listado de Verbos por Nivel`}
          </Typography>

          {/* <Tooltip title={"Crear nuevo ítem"} aria-label="Crear nuevo ítem">
            <IconButton   
              aria-label="Crear nuevo ítem"
              style={{ color: "white" }}
            >
              <Add onClick={handleOpenModal} />
            </IconButton>
          </Tooltip> */}
        </Toolbar>
      </AppBar>
      <Paper className={classes.rootTabla}>
        <DataGrid
          dataSource={datosVerbos}
          //   defaultColumns={this.props.columns}
          showBorders={true}
          rowAlternationEnabled={true}
          //ref={(ref) => (dataGrid = ref)}
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          onExporting={onExporting}
          className={classes.table}
          id={"TablaVerbos"}
        >
          {/* loadPanel: {
enabled:"auto",
height:90,
indicatorSrc:"",
shading:false,
shadingColor:"",
showIndicator:true,
showPane:true,
text:"Loading...",
width:200
}, */}
          <LoadPanel
            enabled={"auto"}

            //height={180}
            //showPane={true}
            //width={400}
            //showIndicator={false}
          />
          {/* <Scrolling
            mode="virtual"
            columnRenderingMode="virtual"
          /> */}
          <FilterRow visible={showFilterRow} applyFilter={currentFilter} />
          <HeaderFilter visible={showHeaderFilter} />
          <GroupPanel visible={false} />
          <Grouping autoExpandAll={autoExpandAll} />
          <SearchPanel visible={false} width={240} placeholder="Buscar..." />
          {/* <Selection mode="multiple" /> */}
          <Editing
            mode="row"
            useIcons={true}
            allowAdding={true}
            allowUpdating={true}
            allowDeleting={true}
          />
          {/* <Column
            caption="Opciones"
            allowFiltering={false}
            allowSorting={false}
            cellRender={cellOpciones}
          /> */}
          <Column
            dataField="CodigoNivelPuestoLaboral"
            caption="Nivel de Puesto"

            //dataType="number"
            //visible={true}
          >
            <Lookup
              dataSource={datosNiveles}
              valueExpr="CodigoNivelPuestoLaboral"
              displayExpr="DisplayNivelPuesto"
            />
          </Column>
          <Column
            dataField="Verbo"
            caption="Verbo"
            //dataType="number"
          />
          <Column dataField="Activo" caption="Activo" dataType="boolean" />
          <Column
            dataField="FechaInserto"
            caption="Fecha Creación"
            dataType="date"
            format="dd/MM/yyyy"
            allowEditing={false}
          />
          <Column
            dataField="FechaModifico"
            caption="Fecha Modificación"
            dataType="date"
            format="dd/MM/yyyy"
            allowEditing={false}
          />

          {/* <ColumnFixing enabled={true} /> */}
          <Sorting mode="multiple" />
          <Paging defaultPageSize={20} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[10, 20, 50]}
            showInfo={true}
          />
          <Export enabled={true} allowExportSelectedData={true} />
          {/* <Scrolling columnRenderingMode="virtual" /> */}
        </DataGrid>
      </Paper>
    </React.Fragment>
  );
};

export default VerboComponente;
