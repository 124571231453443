import React from "react";
import { Card, CardContent, Typography, Grid } from "@material-ui/core";

const DetalleRequerimientoPersonalComponente = ({
  dataSolicitudMovimientoPersonal,
  aviso,
}) => {
  return (
    <Card>
      <CardContent>
        <Typography variant="h5" component="div">
          Detalle de Requerimiento de Personal
        </Typography>
        <Grid container spacing={2} style={{ marginTop: "10px" }}>
          <Grid item xs={6}>
            <Typography variant="body2">
              <strong>Nombre Solicitante:</strong>{" "}
              {dataSolicitudMovimientoPersonal.NombreCompletoSolicitante}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">
              <strong>Puesto Laboral:</strong>{" "}
              {dataSolicitudMovimientoPersonal.PuestoLaboral}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">
              <strong>Cantidad de Plazas:</strong>{" "}
              {dataSolicitudMovimientoPersonal.CantidadPlazas}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">
              <strong>Fecha Limite:</strong>{" "}
              {dataSolicitudMovimientoPersonal.FechaLimite}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">
              <strong>Tipo Contrato:</strong>{" "}
              {dataSolicitudMovimientoPersonal.TipoContrato}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default DetalleRequerimientoPersonalComponente;
