import React, { useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import MaterialTable from "material-table";
import ConfirmDialog from "../../ConfirmDialog/ConfirmDialog";

const TablaEventos = ({ data, editarEvento, eliminarEvento }) => {

    const [confirmOpen, setConfirmOpen] = useState(false);
    const [codigo, setCodigo] = useState(null);

    const columns = [
        {
            title: "Titulo",
            field: "Titulo",
        },
        {
            title: "Fecha",
            field: "Fecha",
            type: 'date'
        },
        {
            title: "HoraInicio",
            field: "HoraInicio",
        },
        {
            title: "HoraFin",
            field: "HoraFin",
        },
        {
            title: "Todo el dia",
            field: "TodoElDia",
            render: rowData => rowData.TodoElDia ? 'Sí' : 'No'
        },
        {
            title: "Color",
            field: "Color",
        },
    ]

    const confirmarAccion = (id) => {
        setConfirmOpen(true);
        setCodigo(id);
    };

    const cerrarConfirm = () => {
        setConfirmOpen(false);
    };

    return (
        <Container maxWidth="md">
            <MaterialTable
                title=""
                columns={columns}
                data={data}
                actions={[
                    {
                        icon: 'edit',
                        tooltip: 'Editar',
                        onClick: (event, rowData) => editarEvento(rowData.Id)
                    },
                    {
                        icon: 'delete',
                        tooltip: 'Eliminar',
                        onClick: (event, rowData) => {
                            confirmarAccion(rowData.Id);
                        }
                    },
                ]}
            />
            <ConfirmDialog
                id="confirmar"
                keepMounted
                open={confirmOpen}
                onClose={cerrarConfirm}
                value=""
                texto='¿Confirma que desea eliminar este registro? esta acción no podrá revertirse.'
                okfunction={() => eliminarEvento(codigo)}
            />
        </Container>
    );
}

export default TablaEventos;
