import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Chip, Grid } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import { render } from "react-dom";
import SettingsIcon from "@material-ui/icons/Settings";
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { DatePicker, MuiPickersUtilsProvider, Day } from "material-ui-pickers";
import Fab from "@material-ui/core/Fab";
import MaskedInput from "react-text-mask";
//Select
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl"; 
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";

//Upload file
import { DropzoneArea } from "material-ui-dropzone";

//Checkbox
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import "../components/styles.css";
import { arrayMoveControls } from "../../utils/dndUtils";

//Drawer
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import BuildIcon from "@material-ui/icons/Build";
import InputIcon from "@material-ui/icons/Input";
import ArrowDropDownCircleIcon from "@material-ui/icons/ArrowDropDownCircle";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Filter1Icon from "@material-ui/icons/Filter1";
import TodayIcon from "@material-ui/icons/Today";
import ViewHeadlineIcon from "@material-ui/icons/ViewHeadline";
import ImageIcon from "@material-ui/icons/Image";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import ArrowDropDownCircleOutlinedIcon from "@material-ui/icons/ArrowDropDownCircleOutlined";
import ArrowDropDownCircleTwoToneIcon from "@material-ui/icons/ArrowDropDownCircleTwoTone";
import MessageIcon from "@material-ui/icons/Message";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import ConfirmDialog from "../../ConfirmDialog/ConfirmDialog";

import AccessTimeIcon from "@material-ui/icons/AccessTime";
import DehazeIcon from "@material-ui/icons/Dehaze";


//chip
import ChipInput from "material-ui-chip-input";

//Scroll bar
import "react-perfect-scrollbar/dist/css/styles.css";


//Own components
import Layout from "../../layout/containers/Layout";
import FormDesigner from "./form-designer";
import API from "../../utils/api";
import { isSignedIn } from "../../utils/auth";
import InputAdornment from "@material-ui/core/InputAdornment";
import {validaPermisoPaginaActual} from "../../utils/helpers";
import { consultarPermiso } from "../../utils/helpers";






const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
  paper: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
  paperModal: {
    position: "absolute",
    width: "90%",
    height: "600px",
    maxHeight: "600px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },

  paperUserModal: {
    position: "absolute",
    width: "75%",
    height: "600px",
    maxHeight: "600px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
});




// const theme = useTheme();

class FormDesignerContainer extends Component {
  state = {    
      codigoPlantilla: null,
   };
 
  async componentDidMount() {
    //CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
    const llaveAgrupacion = "Menu.Principal.Web";
    const llavePagina = "Menu.Principal.Web.formDesigner";
    await validaPermisoPaginaActual(llaveAgrupacion, llavePagina);
    //FIN DE CONSULTA DE PERMISOS

    if (
      this.props.match.params.codigo === null ||
      this.props.match.params.codigo === undefined
    ) {
      window.location = "/home";
    } else if (!isNaN(this.props.match.params.codigo)) {
      //Cargar lista subentidades
      this.setState(
        { codigoPlantilla: parseInt(this.props.match.params.codigo) },
        async () => {
          
        }
      );
    }
  }

  render() {
    const { classes } = this.props;
    const { items } = this.state;
    return (
      <Layout titulo="Configuración del formulario">
      
        <FormDesigner
        codigoPlantilla = {this.state.codigoPlantilla}
        />        
        
      </Layout>
    );
  }
}
const FormDesignerContainerContainer = withStyles(styles)(FormDesignerContainer);
export default withRouter(FormDesignerContainerContainer);
