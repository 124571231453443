import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Backdrop,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  TextField,
  Paper,
  Tooltip,
  Modal,
  Fade,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Box,
  TabScrollButton,
  Tabs,
  Tab,
} from "@material-ui/core";

import BackdropModal from "@material-ui/core/Backdrop";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import {
  Edit,
  Delete,
  ViewList,
  Add,
  PlayCircleFilled,
  AssignmentTurnedIn,
} from "@material-ui/icons";

import { green } from "@material-ui/core/colors";
import { NavLink } from "react-router-dom";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import DataGrid, {
  Column,
  Pager,
  Paging,
  Sorting,
  ColumnFixing,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Grouping,
  GroupPanel,
  Export,
  Selection,
  Editing,
  Scrolling,
  LoadPanel,
} from "devextreme-react/data-grid";

import API from "../../utils/api";
import { isSignedIn, signOut } from "../../utils/auth";
import {
  validaPermisoPaginaActual,
  verificaPermisoPaginaActual,
} from "../../utils/helpers";
import { ToastContainer, toast } from "react-toastify";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import "devextreme/dist/css/dx.material.teal.light.compact.css";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const width_proportion = "100%";
const titleColor = "#2B3C4D";
let datagridEmpleadosSCILU;
let datagridEmpleadosSCILU2;

const a11yProps = (index) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={"span"} variant={"body2"}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const MyTabScrollButton = withStyles((theme) => ({
  root: {
    width: 28,
    overflow: "hidden",
    transition: "width 0.5s",
    "&.Mui-disabled": {
      width: 0,
    },
  },
}))(TabScrollButton);

const useStyles = makeStyles((theme) => ({
  table: {
    width: "100%",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 210,
  },
  textFieldDireccion: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 230,
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  greenAvatar: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  paperCard: {
    padding: 20,
    width: "100%",
  },
  formControlSelects: {
    // margin: theme.spacing(1),
    minWidth: 360,

    marginBottom: 10,
    marginTop: 10,
    // width: "100%",
    marginLeft: 30,
  },
  formControlSelect: {
    // margin: theme.spacing(1),
    width: width_proportion,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  rootTabla: {
    width: "100%",
    marginTop: theme.spacing.unit * 1,
    overflowX: "auto",
    paddingTop: theme.spacing.unit * 2,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperModal: {
    position: "absolute",
    width: "70%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const PuestoLaboral = () => {
  const classes = useStyles();
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors: fieldsErrors },
    register,
  } = useForm();

  let applyFilterTypes = [
    {
      key: "auto",
      name: "Immediately",
    },
    {
      key: "onClick",
      name: "On Button Click",
    },
  ];

  const [permisoPlazaLaboral, setPermisoPlazaLaboral] = useState(false);
  const [userName, setUserName] = useState("");
  const [open, setOpen] = useState(false);
  const [cargandoMaestros, setCargandoMaestros] = useState(false);
  const [puestoLaboral, setPuestoLaboral] = useState([]);
  const [maestroTemporada, setMaestroTemporada] = useState([]);
  const [codigoPuestoLaboral, setCodigoPuestoLaboral] = useState(null);
  const [codigoTemporada, setCodigoTemporada] = useState(0);
  const [puestoLaboralNombre, setPuestoLaboralNombre] = useState("");

  const [numeroVersion, setNumeroVersion] = useState("");
  const [fechaCreacion, setFechaCreacion] = useState(new Date());

  const [codigoTipoCambioPuestoLaboral, setCodigoTipoCambioPuestoLaboral] =
    useState(0);

  const [codigoPuestoLaboralSolicitud, setCodigoPuestoLaboralSolicitud] =
    useState(0);

  const [valueAutoCompleteTemporada, setValueAutoCompleteTemporada] =
    useState(null);

  const [nombreCorto, setNombreCorto] = useState("");
  const [grado, setGrado] = useState(null);

  const [codigoSubNivelPuestoLaboral, setCodigoSubNivelPuestoLaboral] =
    useState(null);
  const [maestroSubNivelPuestoLaboral, setMaestroSubNivelPuestoLaboral] =
    useState([]);
  const [
    valueAutoCompleteSubNivelPuestoLaboral,
    setValueAutoCompleteSubNivelPuestoLaboral,
  ] = useState(null);

  const [
    codigoClasificacionPuestoLaboral,
    setCodigoClasificacionPuestoLaboral,
  ] = useState(null);
  const [
    maestroClasificacionPuestoLaboral,
    setMaestroClasificacionPuestoLaboral,
  ] = useState([]);
  const [
    valueAutoCompleteClasificacionPuestoLaboral,
    setValueClasificacionSubNivelPuestoLaboral,
  ] = useState(null);

  const [tab, setTab] = useState(0);
  const [positionDisableSorting, setPositionDisableSorting] = useState(false);
  const [showFilterRow, setShowFilterRow] = useState(true);
  const [showHeaderFilter, setShowHeaderFilter] = useState(true);
  const [currentFilter, setCurrentFilter] = useState(applyFilterTypes[0].key);
  const [autoExpandAll, setAutoExpandAll] = useState(true);

  const [buscando, setBackDrop] = useState(false);

  const [dialogoAbierto, abrirDialogo] = useState(false);
  const [dialogoAbiertoEditar, abrirDialogoEditar] = useState(false);
  const [dialogoAbiertoEliminarSolicitud, abrirDialogoEliminarSolicitud] =
    useState(false);
  const [dialogoAbiertoIniciarSolicitud, setDialogoAbiertoIniciarSolicitud] =
    useState(false);

  const [selectedEmployeeNames, setSelectedEmployeeNames] = useState(
    "No hay empleados seleccionados"
  );

  const [listadoEmpleadoNoAsignado, setListadoEmpleadoNoAsignado] = useState(
    []
  );
  const [listadoNotificaciones, setListadoNotificaciones] = useState([]);

  const [empleadosSeleccion, setempleadosSeleccion] = useState([]);

  const handleCerrarBackDrop = () => {
    setBackDrop(false);
  };
  const handleAbrirBackDrop = () => {
    setBackDrop(true);
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    const cargar = async () => {
      // CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
      const llaveAgrupacion = "GESPILU";
      const llavePagina = "GESPILU.ReporteMovimientos";
      await validaPermisoPaginaActual(llaveAgrupacion, llavePagina);

      const llaveVisualizarPlazas = "GESPILU.ReporteMovimientos.Notificaciones";
      let verPlaza = await verificaPermisoPaginaActual(
        llaveAgrupacion,
        llaveVisualizarPlazas
      );
      setPermisoPlazaLaboral(verPlaza);
      // FIN DE CONSULTA DE PERMISOS

      handleAbrirBackDrop();

      await cargarMaestroTemporada();
      // await NuevaLista();
      // await Notificaciones();

      handleCerrarBackDrop();
    };

    cargar();
  }, [cargandoMaestros]);

  const NuevaLista = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri =
        "api/EmpleadoSolicitudMovimiento/Reporte/" +
        session["codigoUsuario"] +
        "/" +
        codigoTemporada;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setListadoEmpleadoNoAsignado(request.data.ficha);
        // setResumenMovimientos(request.data.ficha2);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const Notificaciones = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri =
        "api/EmpleadoSolicitudMovimiento/Notificacion/" + codigoTemporada;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setListadoNotificaciones(request.data.ficha);
        // setResumenMovimientos(request.data.ficha2);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cargarMaestroTemporada = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/Temporada/";

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setMaestroTemporada(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const consultarDato = async () => {
    // dataFicha.usuarioInserto = "prueba";
    try {
      handleCerrarDialogoIniciarSolicitud();
      handleAbrirBackDrop();
      let vIsSignedIn = await isSignedIn();
      if (vIsSignedIn.response) {
        if (codigoTemporada > 0) {
          await NuevaLista();
          await Notificaciones();
        } else {
          toast.error("Seleccione una temporada");
        }
      }
    } catch (e) {
      console.log("EXCEPTION: " + e);
      toast.error(
        "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
      );
    } finally {
      handleCloseModal();
      handleCerrarBackDrop();
    }
  };

  const cuandoHayError = async (data) => {
    toast.info(
      "Existen algunos campos con error, por favor valida el formulario",
      {
        position: "top-right",
        autoClose: 3000, // Esta en milisegundos 3 segundos
      }
    );
  };

  const handleDateChangeFechaCreacion = (date) => {
    setFechaCreacion(date);
  };

  const onExporting = async (e) => {};

  const handleAbrirDialogoNotificacion = () => {
    abrirDialogo(true);
  };

  const handleAbrirDialogoEliminarSolicitud = (id) => {
    abrirDialogoEliminarSolicitud(true);

    setCodigoPuestoLaboralSolicitud(id);
  };

  const handleCerrarDialogo = () => {
    abrirDialogo(false);
  };

  const handleAbrirDialogoEditar = (id) => {
    abrirDialogoEditar(true);

    setCodigoPuestoLaboral(id);
  };

  const handleCerrarDialogoEditar = () => {
    abrirDialogoEditar(false);
  };

  const handleAbrirDialogoIniciarSolicitud = (id) => {
    setDialogoAbiertoIniciarSolicitud(true);

    setCodigoPuestoLaboralSolicitud(id);
  };

  const handleCerrarDialogoIniciarSolicitud = () => {
    setDialogoAbiertoIniciarSolicitud(false);
  };

  const enviarNotificaciones = async () => {
    const data = {
      ListaSolicitudes: empleadosSeleccion,
      UsuarioInserto: userName,
      // CodigoGerencia: props.CodigoGerencia,
    };

    console.log(data);
    // dataFicha.usuarioInserto = "prueba";
    try {
      handleAbrirBackDrop();
      let vIsSignedIn = await isSignedIn();
      if (vIsSignedIn.response) {
        let request;

        request = await API.post(
          "api/EmpleadoSolicitudMovimiento/Notificacion",
          data
        );
        if (request.status !== 200) {
          toast.error(
            "Hubo un error al realizar la operación, por favor consulta con un administrador"
          );
        } else {
          if (request.data.response) {
            toast.success("Se envió exitosamente");

            deseleccionarItemsEnGridEmpleados();
          } else {
            toast.error(
              "Hubo un error al realizar la operación, por favor  consulta con un administrador"
            );
          }
        }
      }
    } catch (e) {
      console.log("EXCEPTION: " + e);
      toast.error(
        "Hubo un error al realizar la operación, por favor consulta con un administrador"
      );
    } finally {
      handleCerrarDialogo();
      handleCerrarBackDrop();
    }
  };

  const handleOnChangeNivelPuestoLaboral = async (event, newValue) => {
    handleAbrirBackDrop();
    if (newValue !== null) {
      setCodigoTemporada(newValue.CodigoTemporada);
      setValueAutoCompleteTemporada(newValue);
    }
    handleCerrarBackDrop();
  };

  const limpiarCampos = () => {
    setPuestoLaboralNombre("");
    setNumeroVersion("");
    setFechaCreacion(new Date());
    setCodigoPuestoLaboral(null);
    setCodigoTemporada(0);

    setValueAutoCompleteTemporada(null);

    setCodigoSubNivelPuestoLaboral(null);
    setCodigoClasificacionPuestoLaboral(null);
    setGrado(null);
    setNombreCorto("");

    setValueAutoCompleteSubNivelPuestoLaboral(null);
    setValueClasificacionSubNivelPuestoLaboral(null);
  };

  const handleNuevaPlaza = () => {
    limpiarCampos();
    handleOpenModal();
    setCodigoTipoCambioPuestoLaboral(1); //nuevo
  };

  const cellTemplateUnidadOrganizacion = (rowInfo) => {
    return (
      <>
        <Tooltip
          title={`Ir a gestión:`}
          style={{ marginRight: 10, cursor: "pointer" }}
        >
          <Link to={`/gestion/${rowInfo.data.Referencia}`}>
            {rowInfo.data.Referencia}
          </Link>
        </Tooltip>
      </>
    );
  };

  const cellTemplateDetalle = (rowInfo) => {
    return (
      <>
        {rowInfo.data.FechaAceptado ? (
          <Tooltip
            title={`Ir a detalle de notificación:`}
            style={{ marginRight: 10, cursor: "pointer" }}
          >
            <Link
              to={`/confirmar-movimiento-gespilu/${rowInfo.data.CodigoEmpleadoSolicitudMovimiento}`}
            >
              {rowInfo.data.FechaAceptado
                ? new Date(rowInfo.data.FechaAceptado).toLocaleDateString(
                    "es-GT"
                  )
                : "-"}
            </Link>
          </Tooltip>
        ) : (
          "-"
        )}
      </>
    );
  };
  //notificacion
  const handleAbrirDialogoCrearGestion = () => {
    console.log(datagridEmpleadosSCILU.getSelectedRowsData());
    setempleadosSeleccion(datagridEmpleadosSCILU.getSelectedRowsData());

    handleAbrirDialogoNotificacion();
  };
  //selección multiple

  const onInitialized = (e) => {
    datagridEmpleadosSCILU = e.component;
  };

  const deseleccionarItemsEnGridEmpleados = () => {
    datagridEmpleadosSCILU.clearSelection();
  };

  const getEmployeeName = (row) => {
    return `[${row.CodigoAnterior} - ${row.NombreCompleto}]`;
  };

  const getEmployeeNames = (selectedRowsData) => {
    return selectedRowsData.length
      ? selectedRowsData.map(getEmployeeName).join("; ")
      : "No hay empleados seleccionados";
  };

  const onSelectionChanged = ({ selectedRowKeys, selectedRowsData }) => {
    setSelectedEmployeeNames(getEmployeeNames(selectedRowsData));
  };

  return (
    <React.Fragment>
      <Backdrop className={classes.backdrop} open={buscando}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer />
      {/* Dialogo para confirmar envio de notificaciones */}
      <Dialog
        open={dialogoAbierto}
        onClose={handleCerrarDialogo}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Enviar Notificación</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro que deseas enviar las notificaciones?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCerrarDialogo} color="primary">
            Cancelar
          </Button>
          <Button onClick={() => enviarNotificaciones()} color="primary">
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
      <AppBar
        position="sticky"
        className={classes.appBar}
        style={{ backgroundColor: titleColor }}
      >
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            style={{ display: "flex", flexGrow: 1 }}
          >
            {`Reporte General de Movimientos`}
          </Typography>
        </Toolbar>
      </AppBar>

      <Paper className={classes.rootTabla}>
        <Grid container spacing={3}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit(consultarDato, cuandoHayError)}
          >
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControl className={classes.formControlSelects}>
                <Controller
                  name="temporada"
                  required
                  render={({ Temporada }) => (
                    <Autocomplete
                      id="temporada"
                      options={maestroTemporada}
                      getOptionLabel={(option) => option.Temporada}
                      value={valueAutoCompleteTemporada}
                      onChange={(event, newValue) =>
                        handleOnChangeNivelPuestoLaboral(event, newValue)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Temporada"
                          variant="outlined"
                        />
                      )}
                    />
                  )}
                  control={control}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={6} md={12} lg={12}>
              <Button
                width="100%"
                variant="contained"
                style={{
                  backgroundColor: "#69bd4b",
                  marginBottom: 10,
                  marginTop: 10,
                  color: "white",
                  width: "93%",
                  marginLeft: 30,
                }}
                type="submit"
              >
                &nbsp;Generar Reporte
              </Button>
            </Grid>
          </form>
        </Grid>
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={(_, val) => setTab(val)}
          variant="scrollable"
          scrollButtons="on"
          aria-label="scrollable force tabs example"
          ScrollButtonComponent={MyTabScrollButton}
        >
          <Tab
            label="Movimientos"
            {...a11yProps(0)}
            style={{ textTransform: "none" }}
            value={0}
          />
          <Tab
            label="Envio de Notificación"
            {...a11yProps(1)}
            style={{ textTransform: "none" }}
            value={1}
            disabled={!permisoPlazaLaboral}
          />
        </Tabs>
        <TabPanel id="movimientos" value={tab} index={0}>
          <Box className={classes.box} pl={0} pt={0}>
            <Paper className={classes.rootTabla}>
              <DataGrid
                dataSource={listadoEmpleadoNoAsignado}
                //   defaultColumns={this.props.columns}
                showBorders={true}
                rowAlternationEnabled={true}
                //ref={(ref) => (dataGrid = ref)}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                //   onExporting={onExporting}
                className={classes.table}
                // onInitialized={onInitialized}
                onSelectionChanged={onSelectionChanged}
              >
                <FilterRow
                  visible={showFilterRow}
                  applyFilter={currentFilter}
                />
                <HeaderFilter visible={showHeaderFilter} />
                <GroupPanel visible={false} />
                <Grouping autoExpandAll={autoExpandAll} />
                <SearchPanel
                  visible={false}
                  width={240}
                  placeholder="Search..."
                />
                {/* <Selection mode="multiple" /> */}
                <Editing
                  mode="row"
                  useIcons={true}
                  allowUpdating={false}
                  allowDeleting={false}
                />
                {/* <Selection mode="multiple" /> */}
                {/* <Column
            caption="Opciones"
            allowFiltering={false}
            allowSorting={false}
            cellRender={cellOpcionesMovimientos}
          /> */}
                <Column
                  dataField="Referencia"
                  caption="# Gestion"
                  cellRender={cellTemplateUnidadOrganizacion}
                />
                <Column dataField="DPI" caption="DPI" />
                <Column dataField="NombreCompleto" caption="Nombre Completo" />
                <Column dataField="CodigoAnterior" caption="Código Empleado" />

                <Column
                  dataField="TipoMovimientoPersonal"
                  caption="Tipo Movimiento"
                />
                <Column
                  dataField="EstadoMovimientoPersonal"
                  caption="Estado Movimiento "
                />
                <Column
                  dataField="FechaEjecucion"
                  caption="Fecha de Movimiento"
                  dataType="date"
                  format="dd/MM/yyyy"
                />
                <Column
                  dataField="CodigoDepartamentoAnterior"
                  caption="Cod. Departamento Actual"
                  // visible={false}
                />
                <Column
                  dataField="DepartamentoAnterior"
                  caption="Departamento Actual"
                  // visible={false}
                />
                <Column
                  dataField="CodigoDepartamento"
                  caption="Cod. Departamento Nuevo"
                  // visible={false}
                />
                <Column
                  dataField="Departamento"
                  caption="Departamento Nuevo "
                  // visible={false}
                />

                <Column dataField="Salario" caption="Salario Diario" />
                {/* <Column dataField="SalarioSolicitado" captión="Salario Diario Solicitado"/> */}

                <Column
                  dataField="CodigoAnteriorJefeInmediato"
                  caption="Código Jefe"
                />
                <Column dataField="JefeInmediato" caption="Jefe Inmediato" />
                <Column
                  dataField="MotivoMovimientoPersonal"
                  caption="Motivo "
                />
                <Column dataField="RazonMovimientoPersonal" caption="Razón " />
                <Column dataField="NoContratable" caption="No Requerido " />
                <Column
                  dataField="CodigoPuestoLaboralAnterior"
                  caption="Código Puesto Laboral Actual"
                />
                <Column
                  dataField="PuestoLaboralAnterior"
                  caption="Puesto Laboral Actual"
                />
                <Column
                  dataField="CodigoPuestoLaboral"
                  caption="Código Puesto Laboral Nuevo"
                />
                <Column
                  dataField="PuestoLaboral"
                  caption="Puesto Laboral Nuevo"
                />
                <Column
                  dataField="ClasificacionPuestoLaboral"
                  caption="Clasificación"
                />
                <Column dataField="TipoTurno" caption="Tipo Turno" />
                <Column
                  dataField="CodigoTipoObjetoSAP"
                  caption="CodigoTipoObjetoSAP"
                  visible={false}
                />
                <Column dataField="TipoObjetoSAP" caption="Tipo Objeto SAP" />
                <Column dataField="ObjetoSAP" caption="Objeto SAP" />
                <Column dataField="TextoObjetoSAP" caption="Texto SAP" />
                <Column
                  dataField="CodigoTemporadaAnterior"
                  caption="CodigoTemporadaAnterior"
                  visible={false}
                />
                <Column
                  dataField="TemporadaAnterior"
                  caption="Temporada Anterior"
                />
                <Column
                  dataField="CodigoTemporada"
                  caption="CodigoTemporada"
                  visible={false}
                />
                <Column dataField="Temporada" caption="Temporada" />
                <Column dataField="Comentario" caption="Comentario" />
                <ColumnFixing enabled={true} />
                <Sorting mode="multiple" />
                <Paging defaultPageSize={50} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[50, 100]}
                  showInfo={true}
                />
                <Export enabled={true} allowExportSelectedData={true} />
                <Scrolling columnRenderingMode="virtual" />
              </DataGrid>
            </Paper>
          </Box>
        </TabPanel>
        <TabPanel id="notificaciones" value={tab} index={1}>
          <Box className={classes.box} pl={0} pt={0}>
            <Paper className={classes.rootTabla}>
              <DataGrid
                dataSource={listadoNotificaciones}
                //   defaultColumns={this.props.columns}
                showBorders={true}
                rowAlternationEnabled={true}
                //ref={(ref) => (dataGrid = ref)}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                //   onExporting={onExporting}
                className={classes.table}
                onInitialized={onInitialized}
                onSelectionChanged={onSelectionChanged}
              >
                <FilterRow
                  visible={showFilterRow}
                  applyFilter={currentFilter}
                />
                <HeaderFilter visible={showHeaderFilter} />
                <GroupPanel visible={false} />
                <Grouping autoExpandAll={autoExpandAll} />
                <SearchPanel
                  visible={false}
                  width={240}
                  placeholder="Search..."
                />
                {/* <Selection mode="multiple" /> */}
                <Editing
                  mode="row"
                  useIcons={true}
                  allowUpdating={false}
                  allowDeleting={false}
                />
                <Selection mode="multiple" />
                {/* <Column
            caption="Opciones"
            allowFiltering={false}
            allowSorting={false}
            cellRender={cellOpcionesMovimientos}
          /> */}
                <Column
                  dataField="Referencia"
                  caption="# Gestion"
                  cellRender={cellTemplateUnidadOrganizacion}
                />
                <Column
                  dataField="Notificado"
                  caption="Notificado"
                  dataType="boolean"
                />
                <Column
                  dataField="Aceptado"
                  caption="Aceptado"
                  dataType="boolean"
                />
                {/* <Column
                  dataField="Referencia"
                  caption="# Gestion"
                  cellRender={cellTemplateUnidadOrganizacion}
                /> */}
                <Column
                  dataField="FechaAceptado"
                  caption="Fecha Aceptado por Colaborador"
                  cellRender={cellTemplateDetalle}
                />
                <Column dataField="DPI" caption="DPI" />
                <Column dataField="NombreCompleto" caption="Nombre Completo" />
                <Column dataField="CodigoAnterior" caption="Código Empleado" />

                <Column
                  dataField="TipoMovimientoPersonal"
                  caption="Tipo Movimiento"
                />
                <Column
                  dataField="EstadoMovimientoPersonal"
                  caption="Estado Movimiento "
                />
                <Column
                  dataField="FechaEjecucion"
                  caption="Fecha de Movimiento"
                  dataType="date"
                  format="dd/MM/yyyy"
                />
                <Column
                  dataField="CodigoDepartamentoAnterior"
                  caption="Cod. Departamento Actual"
                  // visible={false}
                />
                <Column
                  dataField="DepartamentoAnterior"
                  caption="Departamento Actual"
                  // visible={false}
                />
                <Column
                  dataField="CodigoDepartamento"
                  caption="Cod. Departamento Nuevo"
                  // visible={false}
                />
                <Column
                  dataField="Departamento"
                  caption="Departamento Nuevo "
                  // visible={false}
                />

                <Column dataField="Salario" caption="Salario Diario" />
                {/* <Column dataField="SalarioSolicitado" captión="Salario Diario Solicitado"/> */}

                <Column
                  dataField="CodigoAnteriorJefeInmediato"
                  caption="Código Jefe"
                />
                <Column dataField="JefeInmediato" caption="Jefe Inmediato" />
                <Column
                  dataField="MotivoMovimientoPersonal"
                  caption="Motivo "
                />
                <Column dataField="RazonMovimientoPersonal" caption="Razón " />
                <Column dataField="NoContratable" caption="No Requerido " />
                <Column
                  dataField="CodigoPuestoLaboralAnterior"
                  caption="Código Puesto Laboral Actual"
                />
                <Column
                  dataField="PuestoLaboralAnterior"
                  caption="Puesto Laboral Actual"
                />
                <Column
                  dataField="CodigoPuestoLaboral"
                  caption="Código Puesto Laboral Nuevo"
                />
                <Column
                  dataField="PuestoLaboral"
                  caption="Puesto Laboral Nuevo"
                />
                <Column
                  dataField="ClasificacionPuestoLaboral"
                  caption="Clasificación"
                />
                <Column dataField="TipoTurno" caption="Tipo Turno" />
                <Column
                  dataField="CodigoTipoObjetoSAP"
                  caption="CodigoTipoObjetoSAP"
                  visible={false}
                />
                <Column dataField="TipoObjetoSAP" caption="Tipo Objeto SAP" />
                <Column dataField="ObjetoSAP" caption="Objeto SAP" />
                <Column dataField="TextoObjetoSAP" caption="Texto SAP" />
                <Column
                  dataField="CodigoTemporadaAnterior"
                  caption="CodigoTemporadaAnterior"
                  visible={false}
                />
                <Column
                  dataField="TemporadaAnterior"
                  caption="Temporada Anterior"
                />
                <Column
                  dataField="CodigoTemporada"
                  caption="CodigoTemporada"
                  visible={false}
                />
                <Column dataField="Temporada" caption="Temporada" />
                <Column dataField="Comentario" caption="Comentario" />
                <ColumnFixing enabled={true} />
                <Sorting mode="multiple" />
                <Paging defaultPageSize={50} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[50, 100]}
                  showInfo={true}
                />
                <Export enabled={true} allowExportSelectedData={true} />
                <Scrolling columnRenderingMode="virtual" />
              </DataGrid>
              <div className={classes.selectedData}>
                <span className={classes.selectedDataCaption}>
                  Empleados seleccionados:
                </span>{" "}
                <span>{selectedEmployeeNames}</span>
              </div>

              <Typography variant="h6">Acciones</Typography>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                {/* <input type="submit" /> */}
                <Tooltip title="Enviar Notificaciones">
                  <Button
                    // fullWidth
                    variant="contained"
                    style={{
                      backgroundColor: "#69BD4B",
                      color: "white",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    // type="submit"
                    size="small"
                    onClick={handleAbrirDialogoCrearGestion}
                    startIcon={<AssignmentTurnedIn />}
                  >
                    Enviar Notificaciones
                  </Button>
                </Tooltip>
              </Grid>
            </Paper>
          </Box>
        </TabPanel>
      </Paper>
    </React.Fragment>
  );
};

export default PuestoLaboral;
