import { Backdrop, Button, Fade, Modal } from "@material-ui/core";
import GroupIcon from "@material-ui/icons/Group";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import TableChartIcon from "@material-ui/icons/TableChart";
import {
  AnnotationType,
  ChildrenPlacementType,
  Colors,
  Enabled,
  GroupByType,
  ItemType,
  LineType,
  PageFitMode,
  Thickness,
  Size,
  TemplateConfig,
  OrgDiagramPdfkit,
} from "basicprimitives";
import { OrgDiagram } from "basicprimitivesreact";
import React, { useEffect, useState } from "react";
import PDFDocument from "pdfkit-nodejs-webpack";
import blobStream from "blob-stream";

import { toast } from "react-toastify";
import ComponenteDescriptor from "../../puesto-laboral/components/descriptor-componente";
import OrgPdfViewDialog from "../components-pdf/OrgPdfViewDialog";
import CardsDetalle from "../components/detalle-modal-organigrama";
import API from "../../utils/api";
import useStyles from "../styles/styles";

const Organigrama = (props) => {
  const classes = useStyles();
  const plugin = OrgDiagramPdfkit;
  const [items, setItems] = useState([]);
  const [subNiveles, setSubNiveles] = useState([]);
  const [escala, setEscala] = useState(0.8);
  const [isPdfDialogOpen, setPdfDialogOpen] = useState(false);
  const [openModalTraslado, setOpenModalTraslado] = useState(false);
  const [openModalDescriptor, setOpenModalDescriptor] = useState(false);
  const [codigoPlazaLaboral, setCodigoPlazaLaboral] = useState(null);
  const [imprimir, setImprimir] = useState(
    props.imprimir !== undefined ? props.imprimir : false
  );
  const [url, setUrl] = React.useState(null);
  //En ciertos escenarios no se carga, como si se genera organigrama desde el mismo descriptor de puesto
  const [cargarBotonDescriptor, setCargarBotonDescriptor] = useState(true);

  useEffect(() => {
    const cargar = async () => {
      if (props.nodos !== undefined) {
        transformarNodos();
      }
    };

    cargar();
  }, [props.nodos]);

  useEffect(() => {
    if (props.cargarBotonDescriptor === false) {
      setCargarBotonDescriptor(false);
    }
  }, []);

  useEffect(() => {
    let { items } = config;

    items = items.map((item) => {
      var imageName = "a";
      if (item.image != null) {
        [, imageName] = item.image.match(/.*(.+?)\.png/m) || [];
      }
      return {
        ...item,
        //image: Photos[imageName]
      };
    });

    const orgDiagramPlugin = plugin({
      ...config,
      items,
      cursorItem: null,
      hasSelectorCheckbox: Enabled.False,
      templates: [getContactTemplate()],
      onItemRender: onTemplateRender,
    });

    function getContactTemplate() {
      var result = new TemplateConfig();
      result.name = "contactTemplate";
      result.itemTemplate = "Use onItemRener method.";
      result.itemSize = new Size(180, 108);
      result.highlightPadding = new Thickness(2, 2, 2, 2);
      return result;
    }

    function onTemplateRender(doc, position, data) {
      var itemConfig = data.context;

      if (data.templateName == "contactTemplate") {
        var contentSize = new Size(180, 108);

        contentSize.width -= 2;
        contentSize.height -= 2;

        doc.save();

        /* item border */
        doc
          .roundedRect(
            position.x,
            position.y,
            position.width,
            position.height,
            0
          )
          .lineWidth(itemConfig.esUltimoCambio ? 3 : 2)
          .stroke(itemConfig.esUltimoCambio ? "black" : "#eeecec");

        /* rectangulo puesto  */
        doc
          .roundedRect(
            position.x + 4,
            position.y + 4,
            contentSize.width - 6,
            50,
            0
          )
          .lineWidth(1)
          .fill(
            itemConfig.itemColor != null ? itemConfig.itemColor : "#d3d4da"
          );

        /* puesto laboral   */
        doc
          .fillColor("black")
          .font("Helvetica", 14)
          .text(itemConfig.description, position.x + 4, position.y + 7, {
            ellipsis: true,
            width: contentSize.width - 6,
            height: 55,
            align: "center",
          });

        /* title */
        doc
          .fillColor("black")
          .font("Helvetica", 11)
          .text(itemConfig.title, position.x, position.y + 60, {
            ellipsis: true,
            width: contentSize.width - 4,
            height: 16,
            align: "center",
          });

        /* inventario */
        doc
          .fillColor("black")
          .font("Helvetica", 11)
          .text(
            "Inventario: " + itemConfig.headcount,
            position.x,
            position.y + 80,
            {
              ellipsis: true,
              width: contentSize.width - 4,
              height: 16,
              align: "center",
            }
          );

        /* title */
        doc
          .fillColor("black")
          .font("Helvetica", 11)
          .text(
            "Asignados: " + itemConfig.asignados,
            position.x,
            position.y + 90,
            {
              ellipsis: true,
              width: contentSize.width - 4,
              height: 16,
              align: "center",
            }
          );

        doc.restore();
      }
    }

    var diagramSize = orgDiagramPlugin.getSize();

    if (props.imprimir !== undefined ? props.imprimir : false) {
      var doc = new PDFDocument({
        size: [diagramSize.width + 100, diagramSize.height + 150],
      });
      var stream = doc.pipe(blobStream());

      doc.save();

      doc.fontSize(25).text("Organigrama", 50, 50);

      orgDiagramPlugin.draw(doc, 50, 100);

      doc.restore();

      doc.end();

      stream.on("finish", function () {
        const blob = stream.toBlobURL("application/pdf");

        setUrl(blob);

        fetch(blob)
          .then((response) => response.blob())
          .then((blob) => {
            var file = new File([blob], "nombre_de_archivo.pdf", {
              type: "application/pdf",
            });
            // aquí puede usar el objeto "file" como un objeto "File" normal
            subirDoc(file);
          });
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.imprimir]);

  const subirDoc = async (pUrl) => {
    try {
      if (!pUrl) {
        toast.error("El archivo es requerido");
        return;
      }
      const form = new FormData();
      form.append("Archivo", pUrl);
      form.append("CodigoPuestoLaboral", props.codigoPuestoLaboral);
      await API.postFile("api/PuestoLaboral/SubirOrganigrama", form, {
        "Content-Type": "multipart/form-data",
      });
      // toast.success("Se ha generado el pdf exitosamente");
    } catch (error) {
      toast.error("Ha ocurrido un error al subir el archivo: ");
      console.log(error);
    } finally {
    }
  };

  const handleOpenModalTraslado = () => {
    setOpenModalTraslado(true);
  };

  const handleCloseModalTraslado = () => {
    setOpenModalTraslado(false);
  };

  const handleOpenModalDescriptor = () => {
    setOpenModalDescriptor(true);
  };

  const handleCloseModalDescriptor = () => {
    setOpenModalDescriptor(false);
  };

  const transformarNodos = () => {
    try {
      let listaItems = props.nodos.map((node) => {
        return {
          id: node.fakeId,
          parent: node.parent,
          title: node.unidadOrganizacion, //
          description: node.name,
          groupTitle: node.title,
          headcount: node.headcount,
          asignados: node.asignados,
          // image: "/react/photos/b.png",
          context: node,
          templateName: "contactTemplate",
          realParent: node.parent,
          level: node.nivel,
          isVisible: node.visible,
          // levelOffset: node.subNivel!==0? node.subNivel - 1 : null,
          itemType:
            node.esMismoNivelQuePadre === true ? ItemType.Adviser : null,
          itemColor:
            node.esUltimoCambio === true ? Colors.Yellow : Colors.LightGray,
          esUltimoCambio: node.esUltimoCambio === true ? true : false,
          // childrenPlacementType: node.esMismoNivelQuePadre===true? ChildrenPlacementType.Matrix:null,
        };
      });
      setItems(listaItems);
    } catch (error) {
      toast.error("Existe un error en la generación del organigrama");
      console.log("Error en transformar Nodos: ");
      console.log(error);
    }
  };

  useEffect(() => {
    const cargar = async () => {
      if (props.maestroSubNivelPuestoLaboral.length > 0) {
        transformarSubniveles();
      }
    };

    cargar();
  }, [props.maestroSubNivelPuestoLaboral]);

  const transformarSubniveles = () => {
    try {
      let listaItems = props.maestroSubNivelPuestoLaboral.map((node, i) => {
        return {
          annotationType: AnnotationType.Level,
          levels: [i],
          title: node.SubNivelPuestoLaboral,
          titleColor: Colors.RoyalBlue,
          offset: new Thickness(0, 0, 0, -1),
          lineWidth: new Thickness(0, 0, 0, 0),
          opacity: 0,
          borderColor: Colors.Gray,
          fillColor: Colors.Gray,
          lineType: LineType.Dotted,
        };
      });
      setSubNiveles(listaItems);
    } catch (error) {
      toast.error("Existe un error en la generación del organigrama: ");
      console.log("Error en transformar Subniveles: ");
      console.log(error);
    }
  };
  const config = {
    pageFitMode: PageFitMode.SelectionOnly,
    // autoSizeMinimum: new Size(800, 600),
    // autoSizeMaximum: new Size(1024, 768),
    cursorItem: 0,
    highlightItem: 0,
    hasSelectorCheckbox: Enabled.False,
    normalLevelShift: 20,
    dotLevelShift: 10,
    lineLevelShift: 10,
    normalItemsInterval: 20,
    dotItemsInterval: 10,
    lineItemsInterval: 5,
    itemTitleSecondFontColor: Colors.White,
    leavesPlacementType: ChildrenPlacementType.Horizontal,
    arrowsDirection: GroupByType.Children,
    items: items,
    scale: escala,
    minimumScale: 0.5,
    maximumScale: 2,
    hasButtons: Enabled.True,
    buttonsPanelSize: 60,
    onButtonsRender: ({ context: itemConfig }) => {
      return (
        <>
          <button
            key="1"
            className="StyledButton"
            onClick={(event) => {
              event.stopPropagation();
              setCodigoPlazaLaboral(itemConfig.id);
              handleOpenModalTraslado();
              // alert(`User clicked on Coffee button for node ${itemConfig.title}`)
            }}
          >
            <GroupIcon />
          </button>
          {cargarBotonDescriptor === true && (
            <button
              key="2"
              className="StyledButton"
              onClick={(event) => {
                event.stopPropagation();
                setCodigoPlazaLaboral(itemConfig.id);
                handleOpenModalDescriptor();
                // alert(`User clicked on Coffee button for node ${itemConfig.title}`)
              }}
            >
              <TableChartIcon />
            </button>
          )}
        </>
      );
    },
    templates: [
      // {
      //   name: "itemPlazaLaboral",
      //   itemSize: { width: 180, height: 130 },
      //   onItemRender: ({ context: itemConfig }) => {
      //     const itemTitleColor =
      //       itemConfig.itemTitleColor != null
      //         ? itemConfig.itemTitleColor
      //         : Colors.RoyalBlue;
      //     const itemColor =
      //       itemConfig.itemTitleColor != null
      //         ? itemConfig.itemTitleColor
      //         : Colors.RoyalBlue;
      //     return (
      //       <div className="TemplateItemFrame" style={{ color: itemColor }}>
      //         <div
      //           className="ContactTitleBackground"
      //           style={{ backgroundColor: itemTitleColor }}
      //         >
      //           <div className="ContactTitle">{itemConfig.name}</div>
      //         </div>
      //         <div className="ContactPhone">
      //           <b>{itemConfig.title}</b>
      //         </div>
      //         <div className="ContactEmail">{itemConfig.description}</div>
      //         <div className="ContactDescription">
      //           Plazas: {itemConfig.headcount}
      //         </div>
      //       </div>
      //     );
      //   },
      // },
      {
        name: "contactTemplate",
        itemSize: { width: 200, height: 140 },
        minimizedItemSize: { width: 3, height: 3 },
        highlightPadding: { left: 2, top: 2, right: 2, bottom: 2 },
        onItemRender: ({ context: itemConfig }) => {
          const itemColor =
            itemConfig.itemColor != null
              ? itemConfig.itemColor
              : Colors.LightGray;
          const itemTitleColor =
            itemConfig.itemTitleColor != null
              ? itemConfig.itemTitleColor
              : Colors.RoyalBlue;
          return (
            <div
              className={classes.ContactTemplate}
              style={{ backgroundColor: itemColor }}
            >
              <div
                className={classes.ContactTitleBackground}
                style={{ backgroundColor: itemTitleColor }}
              >
                <div className={classes.ContactTitle}>
                  {itemConfig.description}
                </div>
              </div>
              <div className={classes.ContactPhone}>{itemConfig.title}</div>
              {/* <div className={classes.ContactEmail}>{itemConfig.description}</div> */}
              <div className={classes.ContactDescription}>
                <b>Inventario: {itemConfig.headcount}</b>
                {/* <b>Asignados: {itemConfig.asignados}</b> */}
              </div>
              <div className={classes.ContactDescription2}>
                <b>Asignados: {itemConfig.asignados}</b>
              </div>
            </div>
          );
        },
      },
      {
        name: "contactTemplate1",
        itemSize: { width: 200, height: 140 },
        minimizedItemSize: { width: 3, height: 3 },
        highlightPadding: { left: 2, top: 2, right: 2, bottom: 2 },
        onItemRender: ({ context: itemConfig }) => {
          const itemTitleColor =
            itemConfig.itemTitleColor != null
              ? itemConfig.itemTitleColor
              : Colors.RoyalBlue;
          return (
            <div className={classes.ContactTemplate}>
              <div
                className={classes.ContactTitleBackground}
                style={{ backgroundColor: itemTitleColor }}
              >
                <div className={classes.ContactTitle}>
                  {itemConfig.description}
                </div>
              </div>
              <div className={classes.ContactPhone}>{itemConfig.title}</div>
              {/* <div className={classes.ContactEmail}>{itemConfig.description}</div> */}
              <div className={classes.ContactDescription}>
                <b>Plazas: {itemConfig.headcount}</b>
                <b>Asignados: {itemConfig.asignados}</b>
              </div>
            </div>
          );
        },
      },
      {
        name: "contactTemplate2",
        itemSize: { width: 150, height: 120 },
        minimizedItemSize: { width: 3, height: 3 },
        highlightPadding: { left: 2, top: 2, right: 2, bottom: 2 },
        onItemRender: ({ context: itemConfig }) => {
          const itemTitleColor =
            itemConfig.itemTitleColor != null
              ? itemConfig.itemTitleColor
              : Colors.Black;
          return (
            <div className={classes.ContactTemplate}>
              <div
                className={classes.ContactTitleBackground}
                style={{ backgroundColor: itemTitleColor }}
              >
                <div className={classes.ContactTitle}>
                  {itemConfig.description}
                </div>
              </div>
              <div className={classes.ContactPhone}>{itemConfig.title}</div>
              {/* <div className={classes.ContactEmail}>{itemConfig.description}</div> */}
              <div className={classes.ContactDescription}>
                <b>Plazas: {itemConfig.headcount}</b>
                <b>Asignados: {itemConfig.asignados}</b>
              </div>
            </div>
          );
        },
      },
      {
        name: "contactTemplate3",
        itemSize: { width: 125, height: 100 },
        minimizedItemSize: { width: 3, height: 3 },
        highlightPadding: { left: 2, top: 2, right: 2, bottom: 2 },
        onItemRender: ({ context: itemConfig }) => {
          const itemTitleColor =
            itemConfig.itemTitleColor != null
              ? itemConfig.itemTitleColor
              : Colors.Gray;
          return (
            <div className={classes.ContactTemplate}>
              <div
                className={classes.ContactTitleBackground}
                style={{ backgroundColor: itemTitleColor }}
              >
                <div className={classes.ContactTitle}>
                  {itemConfig.description}
                </div>
              </div>
              <div className={classes.ContactPhone}>{itemConfig.title}</div>
              {/* <div className={classes.ContactEmail}>{itemConfig.description}</div> */}
              <div className={classes.ContactDescription}>
                <b>Plazas: {itemConfig.headcount}</b>
                <b>Asignados: {itemConfig.asignados}</b>
              </div>
            </div>
          );
        },
      },
    ],
    annotations: subNiveles,
    // annotations: [
    //   {
    //     annotationType: AnnotationType.Level,
    //     levels: [0],
    //     title: "Nivel 9",
    //     titleColor: Colors.RoyalBlue,
    //     offset: new Thickness(0, 0, 0, -1),
    //     lineWidth: new Thickness(0, 0, 0, 0),
    //     opacity: 0,
    //     borderColor: Colors.Gray,
    //     fillColor: Colors.Gray,
    //     lineType: LineType.Dotted,
    //   },
    //   {
    //     annotationType: AnnotationType.Level,
    //     levels: [1],
    //     title: "Nivel 8",
    //     titleColor: Colors.RoyalBlue,
    //     offset: new Thickness(0, 0, 0, -1),
    //     lineWidth: new Thickness(0, 0, 0, 0),
    //     opacity: 0.08,
    //     borderColor: Colors.Gray,
    //     fillColor: Colors.Gray,
    //     lineType: LineType.Dotted,
    //   },
    //   {
    //     annotationType: AnnotationType.Level,
    //     levels: [2],
    //     title: "Nivel 7",
    //     titleColor: Colors.RoyalBlue,
    //     offset: new Thickness(0, 0, 0, -1),
    //     lineWidth: new Thickness(0, 0, 0, 0),
    //     opacity: 0,
    //     borderColor: Colors.Gray,
    //     fillColor: Colors.Gray,
    //     lineType: LineType.Solid,
    //   },
    //   {
    //     annotationType: AnnotationType.Level,
    //     levels: [3],
    //     title: "Nivel 6",
    //     titleColor: Colors.RoyalBlue,
    //     offset: new Thickness(0, 0, 0, -1),
    //     lineWidth: new Thickness(0, 0, 0, 0),
    //     opacity: 0.08,
    //     borderColor: Colors.Gray,
    //     fillColor: Colors.Gray,
    //     lineType: LineType.Dotted,
    //   },
    //   {
    //     annotationType: AnnotationType.Level,
    //     levels: [4],
    //     title: "Nivel 5",
    //     titleColor: Colors.RoyalBlue,
    //     offset: new Thickness(0, 0, 0, -1),
    //     lineWidth: new Thickness(0, 0, 0, 0),
    //     opacity: 0,
    //     borderColor: Colors.Gray,
    //     fillColor: Colors.Gray,
    //     lineType: LineType.Solid,
    //   },
    //   {
    //     annotationType: AnnotationType.Level,
    //     levels: [5],
    //     title: "Nivel 4",
    //     titleColor: Colors.RoyalBlue,
    //     offset: new Thickness(0, 0, 0, -1),
    //     lineWidth: new Thickness(0, 0, 0, 0),
    //     opacity: 0.08,
    //     borderColor: Colors.Gray,
    //     fillColor: Colors.Gray,
    //     lineType: LineType.Dotted,
    //   },
    //   {
    //     annotationType: AnnotationType.Level,
    //     levels: [6],
    //     title: "Nivel 3",
    //     titleColor: Colors.RoyalBlue,
    //     offset: new Thickness(0, 0, 0, -1),
    //     lineWidth: new Thickness(0, 0, 0, 0),
    //     opacity: 0,
    //     borderColor: Colors.Gray,
    //     fillColor: Colors.Gray,
    //     lineType: LineType.Solid,
    //   },
    //   {
    //     annotationType: AnnotationType.Level,
    //     levels: [7],
    //     title: "Nivel 2",
    //     titleColor: Colors.RoyalBlue,
    //     offset: new Thickness(0, 0, 0, -1),
    //     lineWidth: new Thickness(0, 0, 0, 0),
    //     opacity: 0.08,
    //     borderColor: Colors.Gray,
    //     fillColor: Colors.Gray,
    //     lineType: LineType.Dotted,
    //   },
    //   {
    //     annotationType: AnnotationType.Level,
    //     levels: [8],
    //     title: "Nivel 1",
    //     titleColor: Colors.RoyalBlue,
    //     offset: new Thickness(0, 0, 0, -1),
    //     lineWidth: new Thickness(0, 0, 0, 0),
    //     opacity: 0,
    //     borderColor: Colors.Gray,
    //     fillColor: Colors.Gray,
    //     lineType: LineType.Solid,
    //   },
    // ],
  };

  // let [config, setConfig] = useState(configDef);
  const scaleUp = () => {
    const { scale, maximumScale } = config;
    if (scale < maximumScale) setEscala(scale + 0.1);
  };

  const scaleDown = () => {
    const { scale, minimumScale } = config;
    if (scale > minimumScale) setEscala(scale - 0.1);
  };

  const scaleValue = ({ value }) => {
    const { minimumScale, maximumScale } = config;
    if (minimumScale <= value && value <= maximumScale) {
      setEscala(Number(value));
    }
  };

  return (
    // <div className={classes.diagrama}>
    <React.Fragment>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModalTraslado}
        onClose={handleCloseModalTraslado}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModalTraslado}>
          <div className={classes.paperModal}>
            <h2 id="simple-modal-title">Detalle de la Plaza Laboral</h2>
            <CardsDetalle
              codigoPlazaLaboral={codigoPlazaLaboral}
              handleAbrirBackDrop={props.handleAbrirBackDrop}
              handleCerrarBackDrop={props.handleCerrarBackDrop}
            />
          </div>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModalDescriptor}
        onClose={handleCloseModalDescriptor}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModalDescriptor}>
          <div className={classes.paperModalDescriptor}>
            {/* <Container maxWidth="100%"> */}
            <ComponenteDescriptor
              plazaLaboral={codigoPlazaLaboral}
              //puestoLaboral={{codigoPuestoLaboral: codigoPlazaLaboral}}
              handleAbrirBackDrop={props.handleAbrirBackDrop}
              handleCerrarBackDrop={props.handleCerrarBackDrop}
            />
            {/* </Container> */}
          </div>
        </Fade>
      </Modal>
      <div className={classes.diagrama}>
        <div>
          <button onClick={scaleDown}>-</button>
          <input
            type="number"
            value={config.scale}
            step={0.1}
            min={0.5}
            max={2}
            onChange={({ target }) => scaleValue(target)}
          />
          <button onClick={scaleUp}>+</button>
          <input
            value={config.scale}
            type="range"
            step={0.1}
            min={0.5}
            max={2}
            onChange={({ target }) => scaleValue(target)}
          />
          {/* <button onClick={downLoadPDF}>Download as PDF</button> */}
        </div>
        <div className={classes.buttonsPanel}>
          <Button
            key="downloadpdf"
            variant="contained"
            color="primary"
            onClick={() => setPdfDialogOpen(true)}
            startIcon={<PictureAsPdfIcon />}
          >
            PDF
          </Button>
        </div>
        <OrgDiagram centerOnCursor={true} config={config} />
        {isPdfDialogOpen && (
          <OrgPdfViewDialog
            isVisible={isPdfDialogOpen}
            config={config}
            fileName="organigrama.pdf"
            caption="Organigrama"
            onClose={() => setPdfDialogOpen(false)}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default Organigrama;
