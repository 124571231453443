import React, { useState, useEffect } from 'react'
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Typography,
  IconButton,
  makeStyles,
  Switch
} from '@material-ui/core'
import {
  Alert
} from '@material-ui/lab'
import { toast, ToastContainer } from 'react-toastify'
import { Add, Delete } from '@material-ui/icons'
import { useStoreActions, useStoreState } from 'easy-peasy'

import Layout from '../layout/containers/Layout'
import LoadingButton from './componentes/utils/LoadingButton'
import SelectUsuario from './componentes/usuarios/SelectUsuario'
import { getUsuarioInserta } from './utils/utilidades'
import api from '../utils/api'

const useStyles = makeStyles((theme) => ({
  list: {
    width: '100%',
    height: 350,
    backgroundColor: theme.palette.background.paper,
  },
}))

const UsuariosRoles = () => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [usuario, setUsuario] = useState(null)
  const [usuarioData, setUsuarioData] = useState(null)
  const [listaRoles, setListaRoles] = useState([])

  const getRolesUsuario = async (codigo) => {
    const { data } = await api.fetchGetRequest(`api/desktop/usuario/${codigo}`)
    const usuario = data.usuario
    const rolesUsuario = data.rolesUsuario
    setUsuarioData({
      NombreCompletoUsuario: usuario.NombreCompletoUsuario,
      Usuario: usuario.Usuario,
      CorreoElectronico: usuario.CorreoElectronico,
      UsuarioAD: usuario.UsuarioAD,
      Clave: usuario.Clave,
      Activo: usuario.Activo,
      AutenticacionWindows: usuario.AutenticacionWindows,
      RolesUsuario: rolesUsuario
    })
  }

  const savePermisos = async () => {
    try {
      setLoading(true)
      const roles = [...usuarioData.RolesUsuario]
      listaRoles.forEach(x => {
        for (let i = 0; i < roles.length; i++) {
          if (x.CodigoRol === roles[i].CodigoRol) {
            roles[i].Activo = x.Activo
          }
        }
      })
      const body = {
        ...usuarioData,
        RolesUsuario: roles,
        UsuarioModifico: getUsuarioInserta(),
        UsuarioInserto: getUsuarioInserta(),
      }
      delete body.Usuario
      await api.post(
        `api/desktop/usuario/${usuario.CodigoUsuario}`,
        body
      )
    } catch (error) {
      toast.error('Ha ocurrido un error')
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    if (usuarioData !== null) {
      console.log(usuarioData.Usuario)
      setListaRoles(usuarioData.RolesUsuario.filter(i => i.Rol.toUpperCase().includes('GESDOC')))
    }
  }, [usuarioData])
  useEffect(() => {
    if (usuario) {
      getRolesUsuario(usuario.CodigoUsuario)
    }
  }, [usuario])
  return <Layout titulo="Roles por usuario">
    <Grid container justify="center">
      <Grid item xs={12} md={6}>
        <SelectUsuario
          label="Usuario"
          onSelect={val => {
            setUsuario(val)
          }}
        />
        <Typography variant="h6">Roles</Typography>
        <List className={classes.list}>
          {
            listaRoles.map((i, idx) => <ListItem key={i.CodigoRol}>
              <ListItemAvatar>
                <Switch
                  id={i.CodigoRol}
                  checked={i.Activo}
                  onChange={ev => {
                    const codigo = parseInt(ev.target.id)
                    const roles = [...listaRoles]
                    for (let i = 0; i < roles.length; i++) {
                      if (roles[i].CodigoRol === codigo) {
                        roles[i].Activo = ev.target.checked
                      }
                    }
                    setListaRoles(roles)
                  }}
                />
              </ListItemAvatar>
              <ListItemText primary={i.Rol} />
            </ListItem>)
          }
        </List>
        <LoadingButton
          loading={loading}
          fullWidth
          variant="contained"
          color="primary"
          disabled={!Boolean(usuario)}
          style={{marginTop: 10}}
          onClick={savePermisos}
        >
          Guardar
        </LoadingButton>
      </Grid>
    </Grid>
    <ToastContainer />
  </Layout>
}

export default UsuariosRoles