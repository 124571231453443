import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Chip, Grid } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import SettingsIcon from "@material-ui/icons/Settings";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import MaskedInput from "react-text-mask";
//Select
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";

//Checkbox
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
//import "../components/styles.css";
import { arrayMoveControls } from "../../utils/dndUtils";

//Drawer
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import ConfirmDialog from "../../ConfirmDialog/ConfirmDialog";

//Tabs
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import FormLabel from "@material-ui/core/FormLabel";

//Badge
import Badge from "@material-ui/core/Badge";

//Scroll bar
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
//Radio
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

//Autocomplete
import Autocomplete from "@material-ui/lab/Autocomplete";

//Lista Etiquetas
//import TagList from "../../avisos-objects/components/tag-list";

//Own components
import Layout from "../../layout/containers/Layout";
import API from "../../utils/api";
import { isSignedIn } from "../../utils/auth";
import {
  convertirASegundos,
  convertYMD,
  convertirHHMM,
} from "../../utils/helpers";
import plantillaIcon from "../../image-repository/plantillaIcon.svg";
//import TareaProblemaCard from "../components/tarea-problema-card";
//import UsuariosList from "../components/usuarios-list";
//import FormList from "../components/form-list";
import { validaPermisoPaginaActual } from "../../utils/helpers";
import { consultarPermiso } from "../../utils/helpers";

import FormDesigner from "../../form-designer/container/form-designer";

import { DropzoneArea } from "material-ui-dropzone";
import Rating from '@material-ui/lab/Rating';
import AttachFileIcon from "@material-ui/icons/AttachFile";

const styles = (theme) => ({
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    textField: {
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit,
      width: "100%",
    },
    paper: {
      position: "absolute",
      width: "100%",
      height: "100%",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing.unit * 2,
      outline: "none",
    },
    paperModal: {
      position: "absolute",
      width: "90%",
      height: "600px",
      maxHeight: "600px",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing.unit * 2,
      outline: "none",
    },
  
    paperUserModal: {
      position: "absolute",
      width: "75%",
      height: "600px",
      maxHeight: "600px",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing.unit * 2,
      outline: "none",
    },
    paperNuevoForm: {
      position: "absolute",
      width: "50%",
      height: "300px",
      maxHeight: "300px",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing.unit * 2,
      outline: "none",
    },
    buttonAdd: {
      margin: theme.spacing.unit,
    },
  });


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };


const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        flexWrap: "wrap",
      },
      textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: "100%",
      },
      paperPerfil: {
        position: "relative",
        width: "100%", //theme.spacing.unit * 50,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
        outline: "none",
      },
      paper: {
        position: "absolute",
        width: theme.spacing.unit * 100,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
        outline: "none",
      },
      buttonAdd: {
        margin: theme.spacing.unit,
      },
      small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
      },
      large: {
        width: theme.spacing(12),
        height: theme.spacing(12),
      },
      paperCard: {
        padding: 20,
        width: "100%",
      },
      box: {
        position: "relative",
        width: "100%", //theme.spacing.unit * 50,
        padding: theme.spacing.unit * 4,
        outline: "none",
      },
  }));


// const theme = useTheme();

class SkillAsignar extends Component {
  state = {
    nombre: "",
    descripcion: "",
    activo: true,
    codigoCompetencia: null,
    dpi: null,
    codigoPuestoLaboralCompetencia: null,
    tipo: "",
    tipoId: null,
    relevanciaPuesto: 0,
    nivelDominio: 0,
    nivelDominioEmpleado: 0,
    etiquetas: [],
    files: [],
    fileSelected: null,
    path: null
  };

  

  async componentDidMount() {
    //CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
    const llaveAgrupacion = "Menu.Principal.Web";
    const llavePagina = "Menu.Principal.Web.Aprendizaje";
    await validaPermisoPaginaActual(llaveAgrupacion, llavePagina);
    //FIN DE CONSULTA DE PERMISOS
    let codigoPuestoLaboralCompetencia = null
    let dpi = null
    console.log("Estos son los params",this.props.match.params)
    if (
      this.props.match.params.codigoPuestoLaboralCompetencia === null ||
      this.props.match.params.codigoPuestoLaboralCompetencia === undefined ||
      this.props.match.params.dpi === null ||
      this.props.match.params.dpi === undefined
    ) {
        window.location = "/home";
    }else{
        codigoPuestoLaboralCompetencia = this.props.match.params.codigoPuestoLaboralCompetencia
        dpi = this.props.match.params.dpi
    }
    
    this.setState(
        { 
            codigoPuestoLaboralCompetencia: codigoPuestoLaboralCompetencia ,
            dpi: dpi
        },
        async () => {await this.cargarDatosCompetencia();}
      );
  }



  async cargarDatosCompetencia() {
    console.log("Así está el state",this.state.codigoPuestoLaboralCompetencia)
    if(this.state.codigoPuestoLaboralCompetencia!=null){
        const request = await API.fetchGetRequest(
        "api/competencia/empleado/competenciaPorPuesto/" + this.state.codigoPuestoLaboralCompetencia
        +"/"+this.state.dpi
        );
        console.log("el request",request.data)
        let competencia = request.data.data
        this.setState({
            codigoCompetencia: competencia.CodigoCompetencia,
            nombre: competencia.Nombre,
            descripcion: competencia.Descripcion,
            activo: competencia.Activo,
            etiquetas: competencia.Etiquetas,
            tipo: competencia.Tipo.Nombre,
            tipoId:competencia.Tipo.CodigoTipoSkill,
            relevanciaPuesto: competencia.RelevanciaPuesto,
            nivelDominio: competencia.NivelDominio,
            nivelDominioEmpleado: competencia.DominioEmpleado,
            fileSelected: competencia.File,
            path: competencia.Path
        });
    }
  }


  handleChangeCompetencia = (name) => (event) => {
    this.setState({
       [name]: event.target.value
    });
  };

  actualizarCompetencia = async () => {
    const {
        codigoCompetencia,
        dpi,
        relevanciaPuesto,
        nivelDominioEmpleado,
        tipoId,
        files,
        fileSelected
    } = this.state;
    const session = await isSignedIn();
    if (files.length > 0 || fileSelected != null) {
        let data = new FormData();
        data.append("UsuarioInserto", session["userName"]);
        data.append("CodigoUsuarioInserto", session["codigoUsuario"]);
        data.append("Llave", "ARCHIVO");
        
        data.append("CodigoCompetencia",codigoCompetencia)
        data.append("DPI",dpi)
        data.append("RelevanciaPuesto",relevanciaPuesto)
        data.append("NivelDominio",nivelDominioEmpleado)
        data.append("TipoSkill",tipoId)
  
        await files.forEach(async (element2, j) => {
          await data.append("files[]", element2);
        });
        console.log("Data",data)
        let request = await API.post("/api/competencia/empleado/asignarCompetencia", data, {
            "content-type": "multipart/form-data",
          });

        console.log("Respuesta asignar",request)
        await this.cargarDatosCompetencia();
      }else{
          await toast.error("Debe existir un archivo de evidencia para actualizar la competencia")
      }
    

    /*try {
      let vIsSignedIn = await isSignedIn();
      if (vIsSignedIn.response) {
        let request;
        let data;

        data = {
          codigoCompetencia: this.state.codigoCompetencia,
          Nombre: this.state.nombre,
          Descripcion: this.state.descripcion,
          Activo: this.state.activo,
          Eliminado: false,
          UsuarioOpera: vIsSignedIn.userName,
          Etiquetas: listaEtiquetasSelected,
          TiposSkill: listaTiposSelected
        };
        console.log("Esto es lo que voy a mandar",data)
        request = await API.post(`api/competencia/grabarEditarCompetencia`, data);

        if (request.status != 200) {
          alert(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        }

        if (request.statusCode === 401) {
          //alert("cerrar sesion");
        } else {
          toast.success("El registro se actualizó exitosamente.");
          window.location = "/lista-competnecias/"
        }
      }
    } catch (e) {
      alert(e);
    }*/
  };

  handleChangeFiles(files) {
    this.documentSelected(files);
  }

  documentSelected = (files) => {
    this.uploadFiles(files);
  };

  uploadFiles = async (files) => {
    const session = await isSignedIn();
    console.log("Estos son los files",files)
    this.setState({
        files: files
    })
  };
  changeRating = (newValue) => {
    console.log("Valores",newValue)
    this.setState({nivelDominioEmpleado:newValue})
  }


  newTab = (url) => {
    if(url != null){
        url = this.state.path +"\\"+ url
        var form = document.createElement("form");
        form.method = "GET";
        form.action = url;
        form.target = "_blank";
        document.body.appendChild(form);
        form.submit();
    }
  }
  

  render() {
    const { classes }  = this.props;
    const { items } = this.state;
    
    return (
      <Layout titulo={"Asignar Competencia a Empleado ("+this.state.dpi+")"}>
          <Grid container justify = "center">
        <TabPanel value={0} index={0}>
        <Paper className={classes.rootTab} style={{ width: "80%" }}>
        <div style={{ width: "100% !important" }}>
        <Grid container spacing={10}>
                <Grid item xs={12} sm={12} md={8} lg={8}>
                  <form
                    className={classes.container}
                    noValidate
                    autoComplete="off"
                  >
                    <Grid container spacing={4}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        id="txtNombre"
                        label="Nombre"
                        className={classes.textField}
                        defaultValue={this.state.nombre}
                        value={this.state.nombre}
                        InputProps={{
                            readOnly: true,
                        }}
                        variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextField
                            id="txtDescripcion"
                            label="Descripcion"
                            className={classes.textField}
                            defaultValue={this.state.descripcion}
                            value={this.state.descripcion}
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextField
                            id="txtTipo"
                            label="Tipo de Competencia"
                            className={classes.textField}
                            defaultValue={this.state.tipo}
                            value={this.state.tipo}
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Typography component="legend">Nivel de Dominio Requerido</Typography>
                            <Rating 
                                name="rat-dominio" 
                                max={4} 
                                value={this.state.nivelDominio} 
                                readOnly
                            />
                        <Typography component="legend">Relevancia en Puesto</Typography>
                            <Rating 
                                name="rat-relevancia" 
                                value={this.state.relevanciaPuesto} 
                                readOnly
                            />
                            
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                        <form className={classes.container} noValidate autoComplete="off">
                            <Typography variant="h6" gutterBottom>
                                Adjuntar archivo de evidencia
                            </Typography>

                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                <DropzoneArea 
                                    onChange={this.handleChangeFiles.bind(this)} 
                                    filesLimit = {1}
                                />
                                </Grid>
                            </Grid>
                            <div>
                            {this.state.fileSelected && (<Chip
                                    icon={<AttachFileIcon />}
                                    label={this.state.fileSelected.substring(0, 20) + "..."}
                                    color="primary"
                                    onClick={() => {
                                    this.newTab(this.state.fileSelected);
                                    }}
                                    style={{ margin: 15 }}
                                />
                            )}
                            </div>
                            </form>
                           
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography component="legend">Nivel de Dominio del empleado</Typography>
                                <Rating
                                name="customized-11"
                                defaultValue={this.state.nivelDominioEmpleado}
                                max={4}
                                value = {this.state.nivelDominioEmpleado}
                                onChange={(event, newValue) => this.changeRating(newValue)}
                                />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Button
                            variant="contained"
                            style={{
                              backgroundColor: "#69bd4b",
                              color: "white",
                            }}
                            className={classes.buttonAdd}
                            onClick={() => this.actualizarCompetencia()}
                          >
                            Guardar
                          </Button>
                        </Grid>
                            
                        
                    </Grid>

                  </form>
                </Grid>
                
              </Grid>
              </div>
              
        </Paper>
        
        </TabPanel>
        </Grid>
      </Layout>
    );
  }
}
const SkillAsignarContainer = withStyles(styles)(
    SkillAsignar
);
export default withRouter(SkillAsignarContainer);
