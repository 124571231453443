import React, { useState, useEffect } from 'react'
import {
  Grid,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  LinearProgress,
  Dialog,
  Fab,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { Edit, Add, Delete} from '@material-ui/icons'
import { ToastContainer, toast } from 'react-toastify'

import api from '../utils/api'
import Layout from '../layout/containers/Layout'
import CorrelativoCrear from './componentes/correlativos/CorrelativoCrear'
import CorrelativoModificar from './componentes/correlativos/CorrelativoModificar'
import { getUsuarioInserta, getCodigoUsuario } from './utils/utilidades'
import Loading from './componentes/utils/Loading'

const heads = [
  'Macroproceso',
  'Proceso',
  'Rango',
  'Opciones'
]

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  }
}))

const Correlativos = () => {
  const classes = useStyles()
  const [correlativos, setCorrelativos] = useState([])
  const [items, setItems] = useState([])
  const [selected, setSelected] = useState(null)
  const [loading, setLoading] = useState(false)
  const [dialogCrear, setDialogCrear] = useState(false)
  const [dialogModificar, setDialogModificar] = useState(false)
  const [macroproceso, setMacroproceso] = useState(null)
  const [macroprocesos, setMacroprocesos] = useState([])
  const [deleting, setDeleting] = useState(false)

  const getCorrelativos = async () => {
    try {
      setLoading(true)
      const { data } = await api.fetchGetRequest('/api/documentos/correlativo')
      setCorrelativos(data)
      setItems(data)
    } catch (error) {
      toast.error('Ha ocurrido un errror')
    } finally {
      setLoading(false)
    }
  }

  const getMacroProcesos = async () => {
    try {
      const { data } = await api.fetchGetRequest('api/documentos/macroproceso')
      setMacroprocesos(data)
    } catch (error) {
      toast.error('Ha ocurrido un errror')
    }
  }

  const editarCorrelativo = (idx) => {
    const item = { ...items[idx] }
    setSelected(item)
    setDialogModificar(true)
  }

  const eliminarCorrelativo = async (idx) => {
    if (window.confirm('¿Estas seguro de eliminar el correlativo.?')) {
      try {
        setDeleting(true)
        const item = {...items[idx]}
        const requests = item.ListaIdDeCorrelativos.map(i => api.post(`api/documentos/correlativo/eliminar/${i}`, {
          UsuarioModifico: getUsuarioInserta()
        }))
        await Promise.all(requests)
        getCorrelativos()
        toast.success('Se eliminó correctamente')
      } catch (error) {
        toast.error('Ha ocurrido un error')
      } finally {
        setDeleting(false)
      }
    }
  }

  const refresh = () => {
    getCorrelativos()
    setDialogCrear(false)
    setDialogModificar(false)
  }

  useEffect(() => {
    if (macroproceso) {
      setItems(correlativos.filter(i => i.IdMacroproceso === macroproceso))
    } else {
      setItems([...correlativos])
    }
  }, [macroproceso])

  useEffect(() => {
    getCorrelativos()
    getMacroProcesos()
  }, [])

  return <Layout titulo="Correlativos">
    <Grid container justify="center">
      <Grid item xs={12} md={4}>
        <FormControl fullWidth style={{marginTop: '1em', marginBottom: '1em'}}>
          <InputLabel>Macroproceso</InputLabel>
          <Select
            fullWidth
            value={macroproceso}
            onChange={ev => setMacroproceso(ev.target.value)}
          >
            {macroprocesos.map((i, idx) => <MenuItem key={idx} value={i.IdMacroproceso}>{i.NombreMacroproceso}</MenuItem>)}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={12}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {
                  heads.map(i => <TableCell key={i}>{i}</TableCell>)
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                items.map((i, idx) => <TableRow key={idx}>
                  <TableCell>{i.NombreMacroproceso}</TableCell>
                  <TableCell>{i.NombreProceso}</TableCell>
                  <TableCell>
                    {i.CorrelativoInicio} - {i.CorrelativoFin}
                  </TableCell>
                  <TableCell>
                    <Tooltip title="Editar correlativo">
                      <IconButton onClick={() => editarCorrelativo(idx)}>
                        <Edit />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Eliminar correlativo">
                      <IconButton onClick={() => eliminarCorrelativo(idx)}>
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>)
              }
            </TableBody>
          </Table>
          {
            loading && <LinearProgress />
          }
          {
            (!loading && !items.length) && <Alert variant="filled" severity="info">
              No hay correlativos registrados
            </Alert>
          }
        </TableContainer>
      </Grid>
    </Grid>
    <ToastContainer />
    <Loading loading={deleting} />
    <Fab
      onClick={() => setDialogCrear(true)}
      style={{ backgroundColor: "#69BD4B", color: 'white' }}
      className={classes.fab}
      color="primary"
    >
      <Add />
    </Fab>
    {/* --------------------------------- Dialogs -------------------------------- */}
    <Dialog fullWidth maxWidth="sm" open={dialogCrear} >
      <CorrelativoCrear
        onClose={() => setDialogCrear(false)}
        onRefresh={refresh}
      />
    </Dialog>
    <Dialog fullWidth maxWidth="sm" open={dialogModificar} >
      <CorrelativoModificar
        data={selected}
        onClose={() => setDialogModificar(false)}
        onRefresh={refresh}
      />
    </Dialog>
  </Layout>
}

export default Correlativos