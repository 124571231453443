import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import { toast } from "react-toastify";
import { withStyles } from "@material-ui/core/styles";

import AddButton from "../../helpers/components/add-button-float";
import TablaEventos from '../components/tabla-eventos';
import API from "../../utils/api";
import { isSignedIn } from "../../utils/auth";


const styles = (theme) => ({
    container: {
        display: "flex",
        flexWrap: "wrap",
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: "100%",
    },
    paper: {
        position: "absolute",
        width: theme.spacing.unit * 50,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
        outline: "none",
    },
    buttonAdd: {
        margin: theme.spacing.unit,
    },
});

class ListadoTemas extends Component {

    state = {
        data: []
    }

    componentDidMount() {
        this.obtenerTemas();
    }
    
    obtenerTemas = () => {
        API.fetchGetRequest("api/temas-notificaciones").then(response => {
            const { data } = response;
            this.setState({ data });
        });
    }

    editarTema = (id) => {
       this.props.history.push(`/temas-notificaciones/${id}/editar`);
        
    }

    eliminarTema = async(id) => {
        const session = await isSignedIn();
        const data = {usuario: session["userName"]};
        API.post(`/api/temas-notificaciones/eliminar/${id}`, data).then(res => {
            toast.success("Se ha eliminado este tema");
            this.obtenerTemas();
        }).catch(error => {
            toast.error("Error al eliminar el tema, intentelo de nuevo.")
        })
    }

    render() {
        const { classes } = this.props;
        return (
            <div style={{width: '100%'}}>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={12} sm={12}>
                        <TablaEventos
                            data={this.state.data}
                            editarTema={this.editarTema}
                            eliminarTema={this.eliminarTema}
                            />
                    </Grid>
                </Grid>

                <AddButton
                    onClick={() => this.props.history.push("/temas-notificaciones/crear")}
                />
            </div>
        );
    }
}

const ListadoTemasContainer = withStyles(styles)(ListadoTemas);
export default ListadoTemasContainer;
