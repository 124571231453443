import React, { useState, useEffect } from 'react'
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  LinearProgress
} from '@material-ui/core'
import api from '../../../utils/api'
import dayjs from 'dayjs'

const SolicitudComentarios = ({ datos, onClose }) => {
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(false)
  const getHistorial = async () => {
    try {
      setLoading(true)
      const { data } = await api.post(`api/documentos/solicitud/historico/${datos.IdSolicitud}`)
      setItems(data.map(i => ({
        ...i,
        Accion: i.Accion.toUpperCase(),
        Fecha: dayjs(i.Fecha).format('DD-MM-YYYY'),
        Comentario: i.Comentario || '--'
      })))
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getHistorial()
  }, [])
  return <>
    <DialogTitle>Comentarios</DialogTitle>
    <DialogContent>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Operación</TableCell>
              <TableCell>Fecha</TableCell>
              <TableCell>Usuario</TableCell>
              <TableCell>Comentario</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              items.map((i, idx) => <TableRow key={idx}>
                <TableCell>{i.Accion}</TableCell>
                <TableCell>{i.Fecha}</TableCell>
                <TableCell>{i.NombreCompletoUsuario}</TableCell>
                <TableCell>{i.Comentario}</TableCell>
              </TableRow>)
            }
          </TableBody>
        </Table>
      </TableContainer>
      {
        loading && <LinearProgress />
      }
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cerrar</Button>
    </DialogActions>
  </>
}

export default SolicitudComentarios
