import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import {
  Typography,
  Backdrop,
  CircularProgress,
  Tab,
  Tabs,
  Box,
  Divider,
  TabScrollButton,
} from "@material-ui/core";

import Avatar from "@material-ui/core/Avatar";
import Container from "@material-ui/core/Container";
import { green } from "@material-ui/core/colors";
import Chip from "@material-ui/core/Chip";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import DnsIcon from "@material-ui/icons/Dns";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import PeopleIcon from "@material-ui/icons/People";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { validaPermisoSecundarioPaginaActual } from "../../utils/helpers";

//iconos

import {
  Face,
  PersonPin,
  AssignmentInd,
  FlightTakeoff,
} from "@material-ui/icons";

//input date

import PropTypes from "prop-types";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";

import Autocomplete from "@material-ui/lab/Autocomplete";

//Own components
// import Layout from "../../layout/containers/Layout";

import API from "../../utils/api";
import { isSignedIn, profileImageChange, signOut } from "../../utils/auth";

import ContenedorTabPersonal from "./informacion-personal-tab-container";
import ContenedorTabLaboral from "./informacion-laboral-tab-container";

const a11yProps = (index) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Typography component={"span"} variant={"body2"}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const styles = makeStyles((theme) => ({
  item: {
    //border: "1px solid blue",
    margin: "10",
  },
  itemFlexGrow: {
    flexGrow: 1,
    // border: "1px solid red"
  },
  content: {
    display: "flex",
    // justify-content: "space-between",
    maxWidth: "400px",
    margin: "0 auto",
    padding: "10px 0",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
  paperPerfil: {
    position: "relative",
    width: "100%", //theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 1,
    outline: "none",
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 1,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  greenAvatar: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  paperCard: {
    padding: 20,
    width: "100%",
  },
  greenAvatarFoto: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(18),
    height: theme.spacing(18),
  },
  tabsVertical: {
    borderRight: `1px solid ${theme.palette.divider}`,
    overflow: "visible",
  },
  contenedorTabVertical: {
    // flexGrow: 1,
    //backgroundColor: theme.palette.background.paper,
    display: "flex",
    //height: 224,
    width: "100%",
  },
  box: {
    // flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

const useTabs = makeStyles((theme) => {
  const tabsBackground = "linear-gradient(60deg, #53cf6d, #32613c)";
  const indicatorBackground = "rgba(255, 255, 255, .2)";
  const borderRadius = theme.spacing.unit;
  return {
    root: {
      width: "100%",
      borderRadius: theme.spacing.unit,
      background: tabsBackground,
      padding: 10,
      boxShadow: "0px 3px 15px rgba(34, 35, 58, 0.5)",
    },
    indicator: {
      height: "100%",
      borderRadius,
      backgroundColor: indicatorBackground,
    },
  };
});
const useTabItem = makeStyles((theme) => {
  const tabsGutter = theme.spacing.unit * 2;
  const labelColor = "#ffffff";
  return {
    root: {
      textTransform: "initial",
      margin: `0 ${tabsGutter}px`,
      minWidth: 0,
      color: labelColor,
    },
    wrapper: {
      fontWeight: "normal",
      letterSpacing: 0.5,
      color: labelColor,
    },
  };
});
const Perfil = (props) => {
  const { codigoUsuario, dpi } = props;
  const [permisoVerTodo, setPermisoVerTodo] = useState(false);
  const [permisoRolAprendizaje, setPermisoRolAprendizaje] = useState(false);
  const [permisoAnalisisSalarial, setPermisoAnalisisSalarial] = useState(false);
  const [permisoCorreoElectronico, setPermisoCorreoElectronico] =
    useState(false);
  const [usuario, setUsuario] = useState("");
  const [usuario2, setUsuario2] = useState("");
  const [nombreCompletoUsuario, setNombreCompletoUsuario] = useState("");
  const [correoElectronico, setCorreoElectronico] = useState("");
  const [autenticacionWindows, setAutenticacionWindows] =
    useState("initialState");
  const [usuarioAD, setUsuarioAD] = useState("");
  const [imagenPerfil, setImagenPerfil] = useState(null);
  const [codigoUsuarioJefeInmediato, setCodigoUsuarioJefeInmediato] =
    useState(0);
  const [codigoUsuarioGerenteArea, setCodigoUsuarioGerenteArea] = useState(0);
  const [roles, setRoles] = useState([]);
  const [listaUsuarios, setListaUsuarios] = useState([]);
  const [jefeInmediato, setJefeInmediato] = useState(null);
  const [gerenteArea, setGerenteArea] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagenFirma, setImagenFirma] = useState(null);
  const [buscando, setBuscando] = useState(false);
  const [dataEmpleado, setDataEmpleado] = useState({});
  const [encontrado, setEncontrado] = useState(false);
  const [selectedFileFirma, setSelectedFileFirma] = useState(null);
  const [foto, setFoto] = useState(null);
  const [inicialesUsuario, setInicialesUsuario] = useState("");
  const [tab, setTab] = useState(0);
  const [tabVertical, setTabVertical] = useState(2);
  const classes = styles();
  const tabsStyles = useTabs();
  const tabItemStyles = useTabItem();

  const handleCerrarBackDrop = () => {
    setBuscando(false);
  };
  const handleAbrirBackDrop = () => {
    setBuscando(true);
  };

  useEffect(() => {
    async function fetchData() {
      await cargarDatosPerfil();
      // await buscar();
      await cargarListaUsuarios();
    }

    fetchData();
  }, [codigoUsuario]);

  useEffect(() => {
    async function fetchData() {
      // await cargarDatosPerfil();
      if (dpi !== "") {
        await buscarPorDPI();
        await cargarListaUsuarios();
      }
    }

    fetchData();
  }, [dpi]);

  useEffect(() => {
    async function fetchData() {
      await buscar();
    }

    fetchData();
  }, [usuario]);

  const cargarDatosPerfil = async () => {
    let session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    }
    try {
      setBuscando(true);
      let codigo = codigoUsuario; //session.codigoUsuario;

      const request = await API.fetchGetRequest(
        `api/Usuario/getProfileData/${codigo}`
      );

      if (request.data.usuarios == null) {
        return;
      }

      if (!Array.isArray(request.data.usuarios)) {
        return;
      }

      let user = request.data.usuarios[0];

      //setCodigoUsuario(user.CodigoUsuario);
      setUsuario(user.Usuario);
      setUsuario2(user.Usuario);
      setNombreCompletoUsuario(user.NombreCompletoUsuario);
      setCorreoElectronico(user.CorreoElectronico);
      setAutenticacionWindows(user.AutenticacionWindows);
      setUsuarioAD(user.UsuarioAD);
      setImagenPerfil(user.ImagenPerfil);
      setImagenFirma(user.ImagenFirma);
      setCodigoUsuarioJefeInmediato(user.CodigoUsuarioJefeInmediato);
      setCodigoUsuarioGerenteArea(user.CodigoUsuarioGerenteArea);
      setRoles(Array.isArray(user.Roles) ? user.Roles : []);
      if (user.NombreCompletoUsuario !== null) {
        if (user.NombreCompletoUsuario.length > 0) {
          setInicialesUsuario(
            user.NombreCompletoUsuario.substring(0, 2).toUpperCase()
          );
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setBuscando(false);
    }
  };
  const buscar = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    }

    try {
      setBuscando(true);
      setEncontrado(false);

      let uri = "api/fichaClinica/empleado/" + usuario;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        console.log(request.data.data);
        if (request.data.response) {
          setDataEmpleado(request.data.data[0]);

          setEncontrado(true);
          cargarFoto(request.data.data[0].DPI);
          setPermisoVerTodo(session.userName === request.data.data[0].DPI);
          const llaveAgrupacion = "Menu.Principal.Web";
          const llavePagina = "Menu.Principal.Web.Aprendizaje";
          let permisoSkill = await validaPermisoSecundarioPaginaActual(
            llaveAgrupacion,
            llavePagina
          );
          console.log("Tengo Permiso de aprendizaje", permisoSkill);
          setPermisoRolAprendizaje(permisoSkill);

          const llavePaginaCorreoElectronico =
            "Menu.Principal.Web.CorreoElectronico";
          let permisosCorreo = await validaPermisoSecundarioPaginaActual(
            llaveAgrupacion,
            llavePaginaCorreoElectronico
          );
          console.log("Tengo Permiso de correo", permisosCorreo);
          setPermisoCorreoElectronico(permisosCorreo);

          const llaveAgrupacionGESPILU = "GESPILU";
          const llavePaginaGESPILU = "GESPILU.AnalisisSalarial.Editar_Completo";
          let permisoGESPILU = await validaPermisoSecundarioPaginaActual(
            llaveAgrupacionGESPILU,
            llavePaginaGESPILU
          );

          setPermisoAnalisisSalarial(permisoGESPILU);

          if (session.userName === request.data.data[0].DPI) {
            setTabVertical(0);
          } else {
            setTabVertical(2);
          }
        } else {
          setTabVertical(0);
          //toast.error("No se encontro al empleado");
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setBuscando(false);
    }
  };

  const buscarPorDPI = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    }

    try {
      setBuscando(true);
      setEncontrado(false);

      setUsuario2(session.userName);
      let uri = "api/fichaClinica/empleado/" + dpi;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        console.log(request.data.data);
        if (request.data.response) {
          setDataEmpleado(request.data.data[0]);
          setNombreCompletoUsuario(request.data.data[0].NombreEmpleado);
          if (request.data.data[0].NombreEmpleado !== null) {
            if (request.data.data[0].NombreEmpleado.length > 0) {
              setInicialesUsuario(
                request.data.data[0].NombreEmpleado.substring(
                  0,
                  2
                ).toUpperCase()
              );
            }
          }
          setEncontrado(true);
          cargarFoto(request.data.data[0].DPI);
          setPermisoVerTodo(session.userName === request.data.data[0].DPI);
          const llaveAgrupacion = "Menu.Principal.Web";
          const llavePagina = "Menu.Principal.Web.Aprendizaje";
          let permisoSkill = await validaPermisoSecundarioPaginaActual(
            llaveAgrupacion,
            llavePagina
          );
          console.log("Tengo Permiso de aprendizaje", permisoSkill);
          setPermisoRolAprendizaje(permisoSkill);

          const llaveAgrupacionGESPILU = "GESPILU";
          const llavePaginaGESPILU = "GESPILU.AnalisisSalarial.Editar_Completo";
          let permisoGESPILU = await validaPermisoSecundarioPaginaActual(
            llaveAgrupacionGESPILU,
            llavePaginaGESPILU
          );

          setPermisoAnalisisSalarial(permisoGESPILU);

          if (session.userName === request.data.data[0].DPI) {
            setTabVertical(0);
          } else {
            setTabVertical(2);
          }
        } else {
          setTabVertical(0);
          //toast.error("No se encontro al empleado");
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setBuscando(false);
    }
  };

  const cargarListaUsuarios = async () => {
    let criterio = "x-x";
    const request = await API.fetchGetRequest(`api/Usuario/buscar/${criterio}`);
    try {
      if (Array.isArray(request.data.usuarios)) {
        setListaUsuarios(request.data.usuarios);
      } else {
        setListaUsuarios([]);
      }
    } catch (error) {
      console.log("cargarListaTareas error:" + error);
    }
  };

  const actualizarJefeInmediato = async () => {
    //const { codigoUsuario, codigoUsuarioJefeInmediato } = this.state;

    try {
      let vIsSignedIn = await isSignedIn();
      if (vIsSignedIn.response) {
        let request;
        // let data;

        request = await API.post(
          `api/usuario/setJefeInmediato/${
            codigoUsuario === null ? 0 : codigoUsuario
          }/${
            codigoUsuarioJefeInmediato === null ? 0 : codigoUsuarioJefeInmediato
          }`
        );
        if (request.status !== 200) {
          alert(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        }

        if (request.statusCode === 401) {
          //alert("cerrar sesion");
        } else {
          toast.success("El jefe inmediato se asignó exitosamente.");
        }
      }
    } catch (e) {
      alert("hubo un error");
    }
  };

  useEffect(() => {
    async function fetchData() {
      if (
        codigoUsuarioJefeInmediato !== 0 &&
        buscando === false &&
        listaUsuarios.length > 0
      ) {
        await actualizarJefeInmediato();

        if (listaUsuarios.length > 0) {
          let jefeInmediatoItem = listaUsuarios.filter((usuario) => {
            return (
              usuario.CodigoUsuario === parseInt(codigoUsuarioJefeInmediato)
            );
          });
          if (jefeInmediatoItem.length < 1) {
            jefeInmediatoItem = null;
          } else {
            jefeInmediatoItem = jefeInmediatoItem[0];
          }
          setJefeInmediato(jefeInmediatoItem);
        }
      }
    }

    fetchData();
  }, [codigoUsuarioJefeInmediato]);

  useEffect(() => {
    if (codigoUsuarioJefeInmediato > 0) {
      let jefeInmediatoItem = listaUsuarios.filter((usuario) => {
        return usuario.CodigoUsuario === parseInt(codigoUsuarioJefeInmediato);
      });
      if (jefeInmediatoItem.length < 1) {
        jefeInmediatoItem = null;
      } else {
        jefeInmediatoItem = jefeInmediatoItem[0];
      }
      setJefeInmediato(jefeInmediatoItem);
    }
    if (codigoUsuarioJefeInmediato > 0) {
      let gerenteArea = listaUsuarios.filter((usuario) => {
        return usuario.CodigoUsuario === parseInt(codigoUsuarioGerenteArea);
      });
      if (gerenteArea.length < 1) {
        gerenteArea = null;
      } else {
        gerenteArea = gerenteArea[0];
      }
      setGerenteArea(gerenteArea);
    }
  }, [listaUsuarios]);

  const actualizarGerenteArea = async () => {
    //const { codigoUsuario, codigoUsuarioGerenteArea } = this.state;

    try {
      let vIsSignedIn = await isSignedIn();
      if (vIsSignedIn.response) {
        let request;
        // let data;

        request = await API.post(
          `api/usuario/setGerenteArea/${
            codigoUsuario === null ? 0 : codigoUsuario
          }/${codigoUsuarioGerenteArea === null ? 0 : codigoUsuarioGerenteArea}`
        );
        if (request.status !== 200) {
          alert(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        }

        if (request.statusCode === 401) {
          //alert("cerrar sesion");
        } else {
          toast.success("El gerente de área se asignó exitosamente.");
        }
      }
    } catch (e) {
      alert("hubo un error");
    }
  };

  useEffect(() => {
    async function fetchData() {
      if (
        codigoUsuarioGerenteArea !== 0 &&
        buscando === false &&
        listaUsuarios.length > 0
      ) {
        await actualizarGerenteArea();

        if (listaUsuarios.length > 0) {
          let gerenteAreaItem = listaUsuarios.filter((usuario) => {
            return usuario.CodigoUsuario === parseInt(codigoUsuarioGerenteArea);
          });
          if (gerenteAreaItem.length < 1) {
            gerenteAreaItem = null;
          } else {
            gerenteAreaItem = gerenteAreaItem[0];
          }
          setGerenteArea(gerenteAreaItem);
        }
      }
    }

    fetchData();
  }, [codigoUsuarioGerenteArea]);
  // const eliminar = async (codigo) => {
  //   let vIsSignedIn = await isSignedIn();

  //   if (vIsSignedIn.response) {
  //     const request = await API.post(
  //       `api/causa/eliminar/${codigo}/${vIsSignedIn.userName}`
  //     );
  //     if (request.status != 200) {
  //       alert(
  //         "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
  //       );
  //     }

  //     if (request.statusCode === 401) {
  //       //alert("cerrar sesion");
  //     } else {
  //       toast.success("El registro se eliminó exitosamente.");
  //       cargarLista();
  //     }
  //   } else {
  //     toast.error("La sessión no se encuentra activa");
  //   }
  // };

  const onFileChange = (event) => {
    // Update the state
    setSelectedFile(event.target.files[0]);
  };

  useEffect(() => {
    async function fetchData() {
      if (selectedFile !== null) {
        await onFileUpload();
      }
    }

    fetchData();
  }, [selectedFile]);

  const onFileChangeFirma = (event) => {
    // Update the state
    setSelectedFileFirma(event.target.files[0]);
    //  async () => {
    //   //Callback
    //   onFileUploadFirma();
    // });
  };

  useEffect(() => {
    async function fetchData() {
      if (selectedFileFirma !== null) {
        await onFileUploadFirma();
      }
    }

    fetchData();
  }, [selectedFileFirma]);

  const onFileUpload = async (event) => {
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append("myFile", selectedFile, selectedFile.name);

    // evalúa si el tipo de archivo es jpeg o png
    let tipoArchivo = selectedFile.type;

    if (tipoArchivo.includes("jpeg") || tipoArchivo.includes("png")) {
      const session = await isSignedIn();
      if (selectedFile !== null) {
        let data = new FormData();
        data.append("codigoUsuario", session["codigoUsuario"]);
        await data.append("files[]", selectedFile);

        // await files.forEach(async (element2, j) => {
        //   await data.append("files[]", element2);
        // });

        let request = await API.post("api/usuario/foto", data, {
          "content-type": "multipart/form-data",
        });

        if (request && request.data.response) {
          setImagenPerfil(request.data.data.Foto);
          //Cambia imagen de perfil en localStorage
          await profileImageChange(request.data.data.Foto);
          //Recarga la página para forzar la actualización en caché
          window.location.reload();
        } else {
          toast.error("No se pudo realizar la operación");
        }
      }
    } else {
      toast.warn("Elige un archivo válido (jpg o png)");
    }
  };

  const onFileUploadFirma = async () => {
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append("myFile", selectedFileFirma, selectedFileFirma.name);

    // evalúa si el tipo de archivo es jpeg o png
    let tipoArchivo = selectedFileFirma.type;

    if (tipoArchivo.includes("jpeg") || tipoArchivo.includes("png")) {
      const session = await isSignedIn();
      if (selectedFileFirma !== null) {
        let data = new FormData();
        data.append("codigoUsuario", session["codigoUsuario"]);
        await data.append("files[]", selectedFileFirma);

        // await files.forEach(async (element2, j) => {
        //   await data.append("files[]", element2);
        // });

        let request = await API.post("api/usuario/subirFirma", data, {
          "content-type": "multipart/form-data",
        });

        if (request && request.data.response) {
          setImagenFirma(request.data.data.Foto);
          //Cambia imagen de perfil en localStorage
          //await profileImageChange(request.data.data.Foto);
          //Recarga la página para forzar la actualización en caché
          window.location.reload();
        } else {
          toast.error("No se pudo realizar la operación");
        }
      }
    } else {
      toast.warn("Elige un archivo válido (jpg o png)");
    }
  };

  const onHandleChangeDatosEmpleado = async (nuevoDataEmpleado) => {
    setDataEmpleado(nuevoDataEmpleado);
  };

  const cargarFoto = async (dpiEmpleado) => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    }

    try {
      let uri = "api/Empleado/Foto/" + dpiEmpleado;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        setFoto(null);
        return false;
      } else {
        if (request.data.data !== null) {
          setFoto(request.data.data);
        }
      }
    } catch (e) {
      setFoto(null);
      console.log(e);
    } finally {
    }
  };

  return (
    <React.Fragment>
      <Backdrop className={classes.backdrop} open={buscando}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth="xl">
        <Paper id="resultado" className={classes.paperPerfil}>
          <Box
            spacing={0}
            //bgcolor="#6ea02f"
            alignItems="center"
            justifyContent="center"
            align="center"
          >
            <Box>
              {foto !== null ? (
                <Avatar
                  alt={nombreCompletoUsuario}
                  src={foto}
                  className={[classes.greenAvatarFoto]}
                  style={{
                    border: "5px solid lightgray",
                  }}
                />
              ) : (
                <div className="container">
                  <Avatar
                    alt=""
                    className={classes.greenAvatarFoto}
                    style={{
                      cursor: "pointer",
                      border: "5px solid lightgray",
                    }}
                  >
                    {inicialesUsuario}
                  </Avatar>
                  {/* <input
                    accept="image/*"
                    className={classes.input}
                    id="icon-button-file"
                    type="file"
                    style={{ display: "none" }}
                    onChange={onFileChange}
                  />
                  <label htmlFor="icon-button-file">
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                      className={"middleEditIcon"}
                    >
                      <EditIcon />
                    </IconButton>
                  </label> */}
                </div>
              )}
            </Box>
            <Typography gutterBottom variant="h4">
              <b>{nombreCompletoUsuario}</b>
            </Typography>
          </Box>
          <Divider variant="middle" />
          {/* <div className={classes.contenedorTabVertical}> */}
          {/* <Grid container spacing={0}>
            <Grid item xs={12} sm={12} md={12} lg={12}> */}

          <Tabs
            className={tabsStyles.root}
            value={tabVertical}
            indicatorColor="primary"
            textColor="primary"
            onChange={(_, val) => setTabVertical(val)}
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            centered
          >
            {permisoVerTodo && (
              <Tab
                label="Usuario"
                value={0}
                {...a11yProps(0)}
                style={{ textTransform: "none", color: "#ffffff" }}
                disabled={!permisoVerTodo}
                className={tabItemStyles.root}
              />
            )}
            {(permisoVerTodo ||
              !permisoAnalisisSalarial ||
              permisoCorreoElectronico) && (
              <Tab
                className={tabItemStyles.root}
                label="Información Personal"
                value={1}
                {...a11yProps(1)}
                style={{ textTransform: "none", color: "#ffffff" }}
                disabled={
                  encontrado === false ||
                  !(
                    permisoVerTodo ||
                    !permisoAnalisisSalarial ||
                    permisoCorreoElectronico
                  )
                }
                // value={0}
              />
            )}
            {(permisoVerTodo || permisoAnalisisSalarial) && (
              <Tab
                className={tabItemStyles.root}
                label="Expediente Laboral"
                value={2}
                {...a11yProps(2)}
                style={{ textTransform: "none", color: "#ffffff" }}
                disabled={
                  encontrado === false ||
                  !(
                    (
                      permisoVerTodo || permisoAnalisisSalarial
                    ) /*|| permisoRolAprendizaje*/
                  )
                }
              />
            )}
          </Tabs>
          <TabPanel id="datosUsuario" value={tabVertical} index={0}>
            <Box className={classes.box} pl={0} pt={0}>
              {permisoVerTodo && (
                <Grid container spacing={1}>
                  <Grid item xs={12} lg={6} md={6} sm={12} style={{}}>
                    <Paper className={classes.paperCard}>
                      <div
                        style={{
                          display: "flex",
                          alignContent: "column",
                          alignItems: "center",
                        }}
                      >
                        <AccountCircleIcon
                          style={{ color: "#85c638", marginRight: 5 }}
                        />
                        <Typography
                          variant="caption"
                          style={{ fontWeight: "bold" }}
                        >
                          Usuario AppILU
                        </Typography>
                      </div>

                      <div> {usuario}</div>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} lg={6} md={6} sm={12} style={{}}>
                    <Paper className={classes.paperCard}>
                      <div
                        style={{
                          display: "flex",
                          alignContent: "column",
                          alignItems: "center",
                        }}
                      >
                        <AlternateEmailIcon
                          style={{ color: "#f1c40f", marginRight: 5 }}
                        />
                        <Typography
                          variant="caption"
                          style={{ fontWeight: "bold" }}
                        >
                          Email
                        </Typography>
                      </div>
                      <div>{correoElectronico}</div>
                    </Paper>
                  </Grid>

                  <Grid item xs={12} lg={6} md={6} sm={12} style={{}}>
                    <Paper className={classes.paperCard}>
                      <div
                        style={{
                          display: "flex",
                          alignContent: "column",
                          alignItems: "center",
                        }}
                      >
                        <DnsIcon style={{ color: "#d35400", marginRight: 5 }} />
                        <Typography
                          variant="caption"
                          style={{ fontWeight: "bold" }}
                        >
                          Autenticación Windows:
                        </Typography>
                      </div>
                      <div>{autenticacionWindows === true ? "Sí" : "No"}</div>
                    </Paper>
                  </Grid>
                  {autenticacionWindows === true ? (
                    <Grid item xs={12} lg={6} md={6} sm={12} style={{}}>
                      <Paper className={classes.paperCard}>
                        <div
                          style={{
                            display: "flex",
                            alignContent: "column",
                            alignItems: "center",
                          }}
                        >
                          <ContactMailIcon
                            style={{ color: "#3498db", marginRight: 5 }}
                          />
                          <Typography
                            variant="caption"
                            style={{ fontWeight: "bold" }}
                          >
                            Usuario AD
                          </Typography>
                        </div>
                        <div>{usuarioAD}</div>
                      </Paper>
                    </Grid>
                  ) : (
                    <></>
                  )}

                  <Grid item xs={12} lg={6} md={6} sm={12} style={{}}>
                    <Paper className={classes.paperCard}>
                      <Autocomplete
                        value={jefeInmediato}
                        onChange={async (event, newValue) => {
                          setJefeInmediato(newValue ? newValue : -1);
                          setCodigoUsuarioJefeInmediato(
                            newValue ? newValue.CodigoUsuario : 0
                          );
                        }}
                        id="controllable-states-demo"
                        options={listaUsuarios}
                        getOptionLabel={(option) =>
                          option.NombreCompletoUsuario
                        }
                        style={{ width: "100%", margin: 10, marginTop: 25 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Jefe inmediato"
                            variant="outlined"
                          />
                        )}
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12} lg={6} md={6} sm={12} style={{}}>
                    <Paper className={classes.paperCard}>
                      <Autocomplete
                        value={gerenteArea}
                        onChange={(event, newValue) => {
                          setGerenteArea(newValue ? newValue : -1);
                          setCodigoUsuarioGerenteArea(
                            newValue ? newValue.CodigoUsuario : 0
                          );
                        }}
                        id="controllable-states-demo"
                        options={listaUsuarios}
                        getOptionLabel={(option) =>
                          option.NombreCompletoUsuario
                        }
                        style={{ width: "100%", margin: 10, marginTop: 25 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Gerente de área"
                            variant="outlined"
                          />
                        )}
                      />
                    </Paper>
                  </Grid>

                  <Grid item xs={12} lg={6} md={6} sm={12} style={{}}>
                    <Paper className={classes.paperCard}>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <input
                              accept="image/*"
                              className={classes.input}
                              id="firmaFile"
                              type="file"
                              style={{ display: "none" }}
                              onChange={onFileChangeFirma}
                            />
                            <label htmlFor="firmaFile">
                              <IconButton
                                color="primary"
                                aria-label="upload sign"
                                component="span"
                                className={""}
                              >
                                <BorderColorIcon />
                              </IconButton>
                            </label>
                          </div>
                          <Typography
                            variant="caption"
                            style={{ fontWeight: "bold" }}
                          >
                            Firma
                          </Typography>
                        </div>
                      </div>
                      <div>
                        <div
                          className=""
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          {imagenFirma === null ||
                          imagenFirma.endsWith("firma/") ? (
                            <Avatar
                              alt={"firma"}
                              className={[classes.greenAvatar, "avatarCircle"]}
                            >
                              <span style={{ fontSize: 16 }}>Sin firma</span>
                            </Avatar>
                          ) : (
                            <Avatar
                              alt={"firma"}
                              src={imagenFirma}
                              className={[classes.greenAvatar, "avatarCircle"]}
                            />
                          )}
                        </div>
                      </div>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} lg={6} md={6} sm={12} style={{}}>
                    <Paper className={classes.paperCard}>
                      <div style={{ marginLeft: 10 }}>
                        <div
                          style={{
                            display: "flex",
                            alignContent: "column",
                            alignItems: "center",
                          }}
                        >
                          <PeopleIcon
                            style={{ color: "#3498db", marginRight: 5 }}
                          />
                          <Typography
                            variant="caption"
                            style={{ fontWeight: "bold" }}
                          >
                            Roles
                          </Typography>
                        </div>
                        <div>
                          {roles.map((item, i) => (
                            <Chip
                              key={i}
                              label={item}
                              color="primary"
                              style={{ marginLeft: 5, marginTop: 5 }}
                            />
                          ))}
                        </div>
                      </div>
                    </Paper>
                  </Grid>
                </Grid>
              )}
            </Box>
          </TabPanel>
          <TabPanel id="datosPersonales" value={tabVertical} index={1}>
            <Box className={classes.box} pl={0} pt={0}>
              {encontrado === true &&
                (permisoVerTodo || permisoCorreoElectronico) && (
                  <Grid container spacing={1}>
                    <ContenedorTabPersonal dataEmpleado={dataEmpleado} />
                    {/* <DatosEmpleadoEditar
                        dataEmpleado={dataEmpleado}
                        onChangeDataEmpleado={onHandleChangeDatosEmpleado}
                      /> */}
                  </Grid>
                )}
            </Box>
          </TabPanel>
          <TabPanel id="datosLaborales" value={tabVertical} index={2}>
            <Box className={classes.box} pl={0} pt={0}>
              <Grid container spacing={1}>
                {encontrado === true &&
                  (permisoVerTodo ||
                    permisoRolAprendizaje ||
                    permisoAnalisisSalarial) && (
                    <ContenedorTabLaboral
                      dataEmpleado={dataEmpleado}
                      permisoVerTodo={permisoVerTodo}
                      usuario={usuario2}
                    />
                  )}
              </Grid>
            </Box>
          </TabPanel>
          {/* <TabPanel id="vacaciones" value={tabVertical} index={3}>
                <Box className={classes.box} pl={0} pt={0}>
                  {encontrado === true && (
                    <Grid container spacing={3}>
                      <DatosVacaciones dataEmpleado={dataEmpleado} />
                    </Grid>
                  )}
                </Box>
              </TabPanel> */}
          {/* </Grid>
          </Grid> */}
          {/* </div> */}
        </Paper>
      </Container>

      <ToastContainer />
    </React.Fragment>
  );
};

export default withRouter(Perfil);
