import React, { UseState } from "react";
import IconButton from "@material-ui/core/IconButton";
import CheckIcon  from "@material-ui/icons/Check";
import CancelIcon  from "@material-ui/icons/Cancel";

function OkCancelButtons({ okFn, cancelFn }) {
  return (
    <div>
      <div
        direction="row"
        spacing={1}
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "flex-end",
        }}
      >
        <IconButton aria-label="aceptar" color="primary" onClick={okFn}>
          <CheckIcon />
        </IconButton>
        <IconButton aria-label="cancelar" color="secondary" onClick={cancelFn}>
          <CancelIcon />
        </IconButton>
      </div>
    </div>
  );
}

export default OkCancelButtons;
