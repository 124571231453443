import React, { Fragment } from 'react';
import { Grid, Paper, makeStyles } from '@material-ui/core';
import GestionItem from './gestion-item';

const useStyles = makeStyles(theme => ({
    cardContainer: {
        padding: '1.5rem',
        borderRadius: 10
    }
}));

const CardGestiones = ({ data }) => {
    
    const classes = useStyles();

    return (
        <Grid container direction='column' className={classes.cardContainer} component={Paper} elevation={4}>
            {data.map(item => (
                <GestionItem key={item.CodigoAviso} item={item} />
            ))}
        </Grid>
    )
}

export default CardGestiones;
