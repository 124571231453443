import React, { useEffect } from 'react';
import { Enabled, Size,Thickness, TemplateConfig} from 'basicprimitives';
import PDFDocument from 'pdfkit-nodejs-webpack';
import blobStream from 'blob-stream';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Photos from './Photos';
// import primitives from 'basicprimitives';

function PdfViewDialog(props) {
  const { isVisible, onClose, config, caption, plugin, datosFirmaOrganigrama } = props;

  const [url, setUrl] = React.useState(null);
    
  useEffect(() => {
    let { items } = config;
  
    items = items.map(item => {
      var imageName = "a";
      if (item.image != null) {
        [, imageName] = item.image.match(/.*(.+?)\.png/m) || [];
      }
      return {
        ...item,
        //image: Photos[imageName]
      }
    });
  
    const orgDiagramPlugin = plugin({
      ...config,
      items,
      cursorItem: null,
      hasSelectorCheckbox: Enabled.False,
      templates: [getContactTemplate()],
      onItemRender: onTemplateRender
    });

    function getContactTemplate() {
      var result = new TemplateConfig();
      result.name = "contactTemplate";
      result.itemTemplate = "Use onItemRener method.";
      result.itemSize = new Size(180, 108);
      result.highlightPadding = new Thickness(2, 2, 2, 2);
      return result;
    }

    function onTemplateRender(doc, position, data) {
      var itemConfig = data.context;

      if (data.templateName == "contactTemplate") {
        var contentSize = new Size(180, 108);

        contentSize.width -= 2;
        contentSize.height -= 2;

        doc.save();

        /* item border */
        doc.roundedRect(position.x, position.y, position.width, position.height, 0)
          .lineWidth(itemConfig.esUltimoCambio ? 3 : 2)
          .stroke(itemConfig.esUltimoCambio ? 'black' : '#eeecec');

      /* rectangulo puesto  */
          doc.roundedRect(position.x+4, position.y + 4, contentSize.width - 6, 50, 0)
          .lineWidth(1)
          .fill( itemConfig.itemColor != null? itemConfig.itemColor : '#d3d4da');

        /* puesto laboral   */
        doc.fillColor('black')
        .font('Helvetica', 14)
        .text(itemConfig.description, position.x + 4 , position.y + 7, {
          ellipsis: true,
          width: (contentSize.width - 6 ),
          height: 55,
          align: 'center'
        });

        /* title */
        doc.fillColor('black')
          .font('Helvetica', 11)
          .text(itemConfig.title, position.x , position.y + 60, {
            ellipsis: true,
            width: (contentSize.width - 4),
            height: 16,
            align: 'center'
          });

          /* inventario */
        doc.fillColor('black')
        .font('Helvetica', 11)
        .text('Inventario: ' + itemConfig.headcount, position.x , position.y + 80, {
          ellipsis: true,
          width: (contentSize.width - 4),
          height: 16,
          align: 'center'
        });

        /* title */
        doc.fillColor('black')
          .font('Helvetica', 11)
          .text('Asignados: ' + itemConfig.asignados, position.x , position.y + 90, {
            ellipsis: true,
            width: (contentSize.width - 4),
            height: 16,
            align: 'center'
          });

        

        doc.restore();
      }
    }
  
    var diagramSize = orgDiagramPlugin.getSize();
  
    var doc = new PDFDocument({ size: [diagramSize.width + 100, diagramSize.height + 150] });
    var stream = doc.pipe(blobStream());
  
    doc.save();
  
    doc.fontSize(25)
      .text(caption, 50, 50);
  
    orgDiagramPlugin.draw(doc, 50, 100);

    if (datosFirmaOrganigrama != null) {
      doc.fontSize(15)
      .text('Firma: ' + datosFirmaOrganigrama.firma, 50, diagramSize.height + 100);

      doc.fontSize(15)
      .text('Cargo: ' + datosFirmaOrganigrama.cargo, 50, diagramSize.height + 120);

      doc.fontSize(15)
      .text('Fecha: ' + datosFirmaOrganigrama.fecha, 50, diagramSize.height + 140);
    }
  
    doc.restore();
  
    doc.end();
  
    stream.on('finish', function () {
      const blob = stream.toBlobURL('application/pdf');
      setUrl(blob);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    isVisible ? (
      <Dialog fullScreen open={isVisible} onClose={onClose}>
        <AppBar style={{position: 'relative'}}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" style={{marginLeft: "5px", flex: 1}}>
              {caption}
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
        <div style={{width: "100%", height: "100%"}}>
          <iframe title="placeholder" src={url} style={{width: "100%", height: "100%", border: "none"}} />
        </div>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    ) : <></>
  ) 
}

export default PdfViewDialog;