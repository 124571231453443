import Fab from "@material-ui/core/Fab";
import { withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import React from "react";

//Own Componentes
import "../css/add-button-float.css";

const styles = theme => ({
  fab: {
    margin: theme.spacing.unit
  }
});

function FloatingActionButtons(props) {
  const { classes, backgroundColor, color, title } = props;
  return (
    <div className="FloatBottomDiv" onClick={props.onClick}>
      <Fab
        style={{ backgroundColor: backgroundColor, color: color }}
        aria-label="Add"
        title={title}
        className={classes.fab}
      >
        <AddIcon />
      </Fab>
    </div>
  );
}

FloatingActionButtons.defaultProps = {
  backgroundColor: "#69BD4B",
  color: "white",
  title: "Nuevo",
};

export default withStyles(styles)(FloatingActionButtons);
