import React from "react";
import TextField from "@material-ui/core/TextField";

//Iconos
import DehazeIcon from '@material-ui/icons/Dehaze';

function Separador(props){
    const {CodigoCampo, Nombre} = props;
    return(
        <div style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
          <DehazeIcon style={{color: "#2196F3", marginRight: 10}} />
          
        </div> 
    )
}

export default Separador;