import React, { useState, useEffect } from "react";

import { Grid } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import Typography from "@material-ui/core/Typography";

import Button from "@material-ui/core/Button";

import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import Container from "@material-ui/core/Container";
import { green } from "@material-ui/core/colors";

import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

//input date

//Own components

import API from "../../utils/api";
import { isSignedIn } from "../../utils/auth";

import "./perfil.css";

const styles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 1,
    outline: "none",
  },
  buttonAdd: {
    //margin: theme.spacing.unit,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  greenAvatar: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  paperCard: {
    padding: 20,
    width: "100%",
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const Vacaciones = (props) => {
  const classes = styles();
  const { dataEmpleado } = props;

  const [infovacaciones, setInfovacaciones] = useState([]);
  const [idGestionVacaciones, setIdGestionVacaciones] = useState(280);
  const [maxVacaciones, setMaxVacaciones] = useState(0);

  useEffect(() => {
    async function fetchData() {
      await cargarCodigoGestion();
      await cargarListaVacaciones();
    }
    fetchData();
  }, [dataEmpleado]);

  const cargarCodigoGestion = async () => {
    let session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    }

    const requestCodGestion = await API.fetchGetRequest(
      `api/vacaciones/getCodigoProblemaVacaciones`
    );
    setIdGestionVacaciones(requestCodGestion.data.data);
  };

  const cargarListaVacaciones = async () => {
    // Aqui debe ir el código de la API SCILU
    let usuarioDPI = dataEmpleado.DPI;

    const request = await API.fetchGetRequest(
      `api/vacaciones/listarVacacionesScilu/${usuarioDPI}`
    );

    if (!request.data.response) {
      toast.error(
        "El usuario no inició sesión con un DPI, no puede desplegarse información"
      );
    } else {
      if (request.data.data.length === 0) {
        toast.warn("El usuario no cuenta con información sobre vacaciones");
      }
    }
    let maxdias = 0;
    request.data.data.forEach((element) => (maxdias += element.DiasPendientes));

    setInfovacaciones(request.data.data);
    setMaxVacaciones(maxdias);
  };

  return (
    <React.Fragment>
      <Container
        maxWidth="md"
        style={{
          backgroundColor: "#f6f6f6",
          marginBottom: 25,
          padding: 10,
        }}
      >
        <Grid
          item
          xs={12}
          lg={12}
          sm={12}
          style={{
            display: "flex",
            alignContent: "column",
            alignItems: "center",
          }}
        >
          <div style={{ marginLeft: 10 }}>
            <div
              style={{
                display: "flex",
                alignContent: "column",
                alignItems: "center",
              }}
            ></div>
            <Typography variant="h6">
              Días de vacaciones disponibles: {maxVacaciones}
            </Typography>
            <div
              style={{
                display: "flex",
                alignContent: "column",
                alignItems: "center",
              }}
            ></div>
          </div>
        </Grid>
      </Container>
      <Container maxWidth="lg">
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Periodo</StyledTableCell>
                <StyledTableCell>Días Otorgados</StyledTableCell>
                <StyledTableCell>Días Gozados</StyledTableCell>
                <StyledTableCell>Días Disponibles</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {infovacaciones.map((row) => (
                <StyledTableRow key={row.CodigoPeriodoVacacion}>
                  <StyledTableCell component="th" scope="row">
                    {row.Descripcion}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {row.DiasAsignados}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {row.DiasGozados}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {row.DiasPendientes}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <br />
        <Button
          style={{
            width: "100%",
            backgroundColor: "#69bd4b",
            color: "white",
            textTransform: "none",
          }}
          className={classes.buttonAdd}
          onClick={() => {
            window.open("/aviso/nuevo/" + idGestionVacaciones, "_blank");
            // this.props.history.push(
            //   "/aviso/nuevo/" + idGestionVacaciones
            // );
          }}
        >
          Solicitar Vacaciones
        </Button>
      </Container>
      <ToastContainer />
    </React.Fragment>
  );
};
//const VacacionesContainer = withStyles(styles)(Vacaciones);
export default Vacaciones;
