//Este es un template para reutilización

import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Loader from "react-loader-spinner";
import { withStyles } from "@material-ui/core/styles";
import { ToastContainer, toast } from "react-toastify";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
//Scroll bar
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import { DropzoneArea } from "material-ui-dropzone";

import API from "../../utils/api";
import { signOut, isSignedIn } from "../../utils/auth";
import TareaCard from "./tarea-card";
import canita from "../../image_repository/msg-happy.png";
import UsuariosList from "../../avisos-objects/components/usuarios-list";
import TagList from "../../avisos-objects/components/tag-list";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  paperUserModal: {
    position: "absolute",
    width: "75%",
    height: "600px",
    maxHeight: "600px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },

  paperPopover: {
    padding: theme.spacing(1),
    background: "#000",
    color: "#fff",
  },
  rootAppbar: {
    flexGrow: 1,
  },
  root: {
    //flexGrow: 1,
    width: "100%",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    // backgroundColor: fade(theme.palette.common.white, 0.15),
    // "&:hover": {
    //   backgroundColor: fade(theme.palette.common.white, 0.25),
    // },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
});

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

class AvisoDetalleTareas extends Component {
  state = {
    isLoading: true,
    isChangingData: false,
    error: false,
    mensaje: "",
    data: this.props.aviso,
    isHelpOpen: false,
    aviso: {},
    tareas: [],
    codigoAviso: 0,
    idObjetoPadre: 0,
    nombreObjetoPadre: "",
    cargando: false,
    history: [],
    botonBackDisplay: "none",
    openAsignarUsuarios: false,
    txtTextoBusquedaUsuario: "",
    codigoTareaModal: "",
    listaUsuarios: [],
    openAdjuntarArchivos: false,
    location: { lat: 0, lon: 0 },
    txtTextoBusquedaTag: "",
    listaTag: [],
  };

  async componentDidMount() {
    console.log(this.props.codigoAviso)
    this.setState({ codigoAviso: this.props.codigoAviso });
    let history = this.state.history;
    let codigoPadre = parseInt(this.props.codigoAviso);

    history.push({
      idObjeto: codigoPadre,
      nombre: "Tareas del aviso",
    }); //inserta la primera posición del array para navegacion

    await this.setState(
      {
        idObjetoPadre: codigoPadre,
        nombreObjetoPadre: "Tareas del aviso",
        // history: history,
      },
      () => {
        this.cargarTareas();
        this.getLocation();
      }
    );

    //this.setState({isLoading: false});
  }

  cargarTareas = async () => {
    const session = await isSignedIn();
    const idObjetoPadre = this.state.idObjetoPadre;

    try {
      this.setState({ cargando: true });
      const uri = `api/objetos/aviso/listaTareas/${idObjetoPadre}`;
      const request = await API.fetchGetRequest(uri);

      if (request.statusCode === 401) {
        if (await signOut()) {
          window.location.href = "/login";
        }
        return;
      }
      if (request.data.response) {
        //OK
        this.setState({
          tareas: request.data.data,
          cargando: false,
          error: false,
        });
      } else {
        //El API alerta error.
        this.setState({
          cargando: false,
          error: true,
          mensaje: request.data.mensaje,
        });
        alert(
          "Hubo un error al realizar la operación, consulta con un administrador"
        );
      }
    } catch (e) {
      //Hubo error en el request al server
      this.setState({
        cargando: false,
        error: true,
        mensaje: "",
      });
    } finally {
      this.setState({ cargando: false });
      this.botonBack();
    }
  };

  getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        let location = this.state.location;
        location.lon = position.coords.longitude;
        location.lat = position.coords.latitude;
        this.setState({ location: location });
      });
    } else {
      toast.warn = "Su navegador no soporta geolocalización";
    }
  };

  navegar = (idObjeto, nombre) => {
    let history = this.state.history;
    history.push({ idObjeto: idObjeto, nombre: nombre });
    this.setState(
      {
        idObjetoPadre: idObjeto,
        nombreObjetoPadre: nombre,
        history: history,
      },
      () => {
        this.cargarTareas();
      }
    );
  };

  regresar = () => {
    let history = this.state.history;

    let cantidadPosiciones = history.length;
    let nombreObjetoPadre;
    let idObjetoPadre;
    if (cantidadPosiciones > 1) {
      //Elimina el último elemento del array que corresponde al idObjetoPadre actual
      history.pop();
      //Actualiza cantidad de elementos del array
      cantidadPosiciones = history.length;
      //Encuentra el valor de la nueva última posición del array y la asigna al idObjetoPadre
      idObjetoPadre = history[cantidadPosiciones - 1].idObjeto;
      nombreObjetoPadre = history[cantidadPosiciones - 1].nombre;

      //Actualiza valores del state
      this.setState(
        {
          idObjetoPadre: idObjetoPadre,
          nombreObjetoPadre: nombreObjetoPadre,
          history: history,
        },
        () => {
          this.cargarTareas();
        }
      );
    }
  };

  botonBack = () => {
    let cantidadPosiciones = this.state.history.length;
    let display;
    if (cantidadPosiciones > 1) {
      display = "inline-block";
    } else {
      display = "none";
    }

    this.setState({ botonBackDisplay: display });
  };

  onPressTareaCard = (tarea) => {
    this.props.navigation.navigate("AvisoTarea", { Tarea: tarea });
  };

  eliminarUsuarioAsignado = async (IdObjeto) => {
    try {
      let vIsSignedIn = await isSignedIn();

      if (vIsSignedIn.response) {
        const request = await API.post(
          `api/flujoV2/eliminarUsuarioTarea/${IdObjeto}/${vIsSignedIn.userName.toString()}`
        );
        if (request.status != 200) {
          alert(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        }

        if (request.statusCode === 401) {
          //alert("cerrar sesion");
        } else {
          toast.success("Usuario removido exitosamente.");
          this.cargarTareas();
        }
      } else {
        toast.error("La sessión no se encuentra activa");
      }
    } catch (e) {
      alert(e);
    } finally {
      this.setState({ codigoPlantillaTareasPendienteconfirmar: "" });
      this.handleCloseAsignarUsuarios();
    }
  };

  eliminarTag = async (IdObjeto) => {
    try {
      let vIsSignedIn = await isSignedIn();

      if (vIsSignedIn.response) {
        const request = await API.post(
          `api/flujoV2/eliminarEtiquetaTarea/${IdObjeto}/${vIsSignedIn.userName.toString()}`
        );
        if (request.status != 200) {
          alert(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        }

        if (request.statusCode === 401) {
          //alert("cerrar sesion");
        } else {
          toast.success("etiqueta removida exitosamente.");
          this.cargarTareas();
        }
      } else {
        toast.error("La sessión no se encuentra activa");
      }
    } catch (e) {
      alert(e);
    } finally {
      this.setState({ codigoPlantillaTareasPendienteconfirmar: "" });
      this.handleCloseAsignarUsuarios();
    }
  };

  handleChange = (name) => (event) => {
    if (name === "activo" || name === "requeridoCampo") {
      this.setState({ [name]: event.target.checked });
    } else {
      this.setState({
        [name]: event.target.value,
      });
    }
  };

  handleOpenAsignarUsuarios = (codigoTarea) => {
    console.log("codigoTarea: " + codigoTarea);
    this.setState({
      openAsignarUsuarios: true,
      codigoTareaModal: codigoTarea,
    });
  };

  handleCloseAsignarUsuarios = () => {
    this.setState({
      openAsignarUsuarios: false,
      txtTextoBusquedaUsuario: "",
      codigoTareaModal: "",
      listaUsuarios: [],
    });
  };

  handleOpenAsignarTag = (codigoTarea) => {
    this.setState({
      openAsignarTag: true,
      codigoTareaModal: codigoTarea,
    });
  };

  handleCloseAsignarTag = () => {
    this.setState({
      openAsignarTag: false,
      txtTextoBusquedaTag: "",
      codigoTareaModal: "",
      listaTag: [],
    });
  };

  handleOpenAdjuntos = (codigoTarea) => {
    console.log("codigoTarea: " + codigoTarea);
    this.setState({
      openAdjuntarArchivos: true,
      codigoTareaModal: codigoTarea,
    });
  };

  handleCloseAdjuntos = () => {
    this.setState({
      openAdjuntarArchivos: false,
      adjuntos: [],
    });
  };
  handleChangeFiles(files) {
    this.documentSelected(files);
  }

  cargarListaUsuarios = async () => {
    let criterio =
      this.state.txtTextoBusquedaUsuario === "" ||
      this.state.txtTextoBusquedaUsuario === null ||
      this.state.txtTextoBusquedaUsuario === undefined
        ? "x-x"
        : this.state.txtTextoBusquedaUsuario;

    const request = await API.fetchGetRequest(`api/Usuario/buscar/${criterio}`);
    try {
      if (Array.isArray(request.data.usuarios)) {
        this.setState({
          listaUsuarios: request.data.usuarios,
        });
      } else {
        this.setState({
          listaUsuarios: [],
        });
      }
    } catch (error) {
      console.log("cargarListaTareas error:" + error);
    }
  };

  seleccionarUsuario = async (codigoUsuario) => {
    try {
      let vIsSignedIn = await isSignedIn();

      if (vIsSignedIn.response) {
        let data = {
          IdObjetoPadre: this.state.codigoTareaModal,
          CodigoUsuario: codigoUsuario,
          usuarioOpera: vIsSignedIn.userName,
        };
        const request = await API.post(`api/flujoV2/grabarUsuarioTarea`, data);
        if (request.status != 200) {
          alert(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        }

        if (request.statusCode === 401) {
          //alert("cerrar sesion");
        } else {
          toast.success("Usuario asignado exitosamente.");

          this.cargarTareas();
        }
      } else {
        toast.error("La sessión no se encuentra activa");
      }
    } catch (e) {
      alert(e);
    } finally {
      this.setState({ codigoPlantillaTareasPendienteconfirmar: "" });
      this.handleCloseAsignarUsuarios();
    }
  };

  cargarListaTag = async () => {
    let criterio =
      this.state.txtTextoBusquedaTag === "" ||
      this.state.txtTextoBusquedaTag === null ||
      this.state.txtTextoBusquedaTag === undefined
        ? "x-x"
        : this.state.txtTextoBusquedaTag;

    const request = await API.fetchGetRequest(`api/causaV2/buscar/${criterio}`);
    try {
      if (Array.isArray(request.data.tags)) {
        this.setState({
          listaTag: request.data.tags,
        });
      } else {
        this.setState({
          listaTag: [],
        });
      }
    } catch (error) {
      console.log("cargarListaTag error:" + error);
    }
  };

  seleccionarTag = async (codigoTag) => {
    try {
      let vIsSignedIn = await isSignedIn();

      if (vIsSignedIn.response) {
        let data = {
          IdObjetoPadre: this.state.codigoTareaModal,
          CodigoEtiqueta: codigoTag,
          usuarioOpera: vIsSignedIn.userName,
        };
        const request = await API.post(`api/flujoV2/grabarEtiquetaTarea`, data);
        if (request.status != 200) {
          alert(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        }

        if (request.statusCode === 401) {
          //alert("cerrar sesion");
        } else {
          toast.success("Etiqueta asignada exitosamente.");

          this.cargarTareas();
        }
      } else {
        toast.error("La sessión no se encuentra activa");
      }
    } catch (e) {
      alert(e);
    } finally {
      this.setState({ codigoPlantillaTareasPendienteconfirmar: "" });
      this.handleCloseAsignarTag();
    }
  };

  operarTarea = async (comentario, codigoTarea, idObjetoPadre, boton) => {
    //Enviar a guardar las causas.
    const session = await isSignedIn();

    let objectRequest = {};

    objectRequest.UsuarioOpera = session.userName;
    objectRequest.Comentario = comentario;
    objectRequest.IdObjetoPadre = idObjetoPadre;

    switch (boton) {
      case "Ok":
        objectRequest.CodigoEstadoTarea = 3;
        break;
      case "Cancel":
        objectRequest.CodigoEstadoTarea = 4;
    }

    await this.postAvisoTarea(objectRequest, codigoTarea);

    this.cargarTareas();
  };

  postAvisoTarea = async (objectRequest, codigoTarea) => {
    try {
      const uri = `api/objetos/aviso/completarTarea/${codigoTarea}`;
      let objects = objectRequest;

      // const { location } = this.state;
      // objects = { ...objects, Longitud: location.lon, Latitud: location.lat };

      const request = await API.post(uri, objects);

      if (request.statusCode === 401) {
        if (await signOut()) {
          window.location.href = "/login";
        }
        return;
      }

      if (request.data.response) {
        //OK
        toast.info("La tarea se operó correctamente");
      } else {
        //El API alerta error.
        //toast.warn(request.data.mensaje)
        window.alert(request.data.mensaje);
      }
    } catch (e) {
      //Hubo error en el request al server
      console.log("Error", e);
    }
  };

  documentSelected = (files) => {
    this.uploadFiles(files);
  };

  uploadFiles = async (files) => {
    const session = await isSignedIn();
    var { codigoTareaModal, avisoTarea } = this.state;
    if (files.length > 0) {
      let data = new FormData();
      data.append("UsuarioInserto", session["userName"]);
      data.append("CodigoUsuarioInserto", session["codigoUsuario"]);
      data.append("Llave", "ARCHIVO");

      await files.forEach(async (element2, j) => {
        await data.append("files[]", element2);
      });

      let request = await API.post(
        `api/objeto/agregarMultimedia/${codigoTareaModal}`,
        data,
        {
          "content-type": "multipart/form-data",
        }
      );

      // avisoTarea.Multimedia = [...avisoTarea.Multimedia, ...request.data.data];

      // this.setState({ avisoTarea });
      await console.log(request);
      this.cargarTareas();
      this.handleCloseAdjuntos();
    }
  };

  onPressDeleteFile = async (codigoTarea) => {
    const session = await isSignedIn();

    try {
      const uri = `api/objeto/borrarMultimedia/${codigoTarea}/${session["userName"]}`;
      const request = await API.post(uri);

      if (request.statusCode === 401) {
        if (await signOut()) {
          window.location.href = "/login";
        }
        return;
      }

      if (request.data.response) {
        toast.info("Archivo inhabilitado correctamente");
        this.cargarTareas();
        //OK
      } else {
        //El API alerta error.
      }
    } catch (e) {
      //Hubo error en el request al server
      console.log("Error: ", e);
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <AppBar position="static" style={{ width: "100%" }}>
          <Toolbar>
            <IconButton
              style={{
                display: this.state.botonBackDisplay,
                color: "#FFF",
              }}
              onClick={() => this.regresar()}
            >
              <ArrowBackIosIcon />
            </IconButton>
            <Typography className={classes.title} variant="h6" noWrap>
              {this.state.nombreObjetoPadre}
            </Typography>
          </Toolbar>
        </AppBar>

        {this.state.cargando ? (
          <div
            style={{
              width: "100%",
              marginTop: 10,
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loader
              type="Oval"
              color="#3498db"
              height="50"
              width="50"
              style={{ textAlign: "center" }}
            />
          </div>
        ) : (
          <></>
        )}

        <Container maxWidth="md" style={{ marginTop: 15 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12} md={12} sm={12}>
              <div style={{ flex: 1, backgroundColor: "#FFF" }}>
                {/* <div style={{ marginTop: 10 }}>
                  <h3>Tareas</h3>
                </div> */}
                <div>
                  {this.state.tareas.map((tarea, i) => {
                    var usuarios = "";
                    var ultimo = false;
                    var estilo = "Pendiente";
                    //var estilo = "Completo";
                    // var icono = "md-checkmark";
                    var icono = "md-time";
                    // var colorEstado = "#2ECC71";
                    var colorEstado = "#F2CB00";

                    if (tarea.CodigoEstado == 1 || tarea.CodigoEstado == 2) {
                      estilo = "Pendiente";
                      icono = "md-time";
                    } else if (tarea.CodigoEstado == 3) {
                      estilo = "Completo";
                      icono = "md-checkmark";
                      colorEstado = "#2ECC71";
                    } else if (tarea.CodigoEstado == 4) {
                      estilo = "Cancelado";
                      colorEstado = "#e74c3c";
                    }

                    if (tarea.Usuarios != null && tarea.Usuarios.length > 0) {
                      usuarios = tarea.Usuarios.join(", ");
                    }

                    if (typeof this.state.tareas[i + 1] !== "undefined") {
                      ultimo = false;
                    } else {
                      ultimo = true;
                    }

                    return (
                      <TareaCard
                        codigoTarea={tarea.CodigoTareaPlantilla}
                        idObjetoPadre={tarea.CodigoPlantillaTareas}
                        key={i}
                        numero={tarea.Orden}
                        estilo={estilo}
                        titulo={tarea.Titulo}
                        nombreAsignado={usuarios}
                        codigoEstado={tarea.CodigoEstado}
                        estado={tarea.Estado.Nombre}
                        icono={icono}
                        colorEstado={colorEstado}
                        ultimo={ultimo}
                        observacion={tarea.Observacion}
                        navegar={this.navegar}
                        usuariosAsignados={tarea.UsuariosAsignados}
                        archivosAdjuntos={tarea.ArchivosAdjuntos}
                        etiquetasAsignadas={tarea.EtiquetasAsignadas}
                        eliminarUsuario={this.eliminarUsuarioAsignado}
                        eliminarAdjuntos={this.onPressDeleteFile}
                        eliminarTag={this.eliminarTag}
                        openModalUsuarios={this.handleOpenAsignarUsuarios}
                        openModalAdjuntos={this.handleOpenAdjuntos}
                        openModalTag={this.handleOpenAsignarTag}
                        operarTarea={this.operarTarea}
                      />
                    );
                  })}

                  {this.state.tareas.length == 0 && !this.state.cargando && (
                    // <Mensaje mensaje={"Este problema no tiene tareas relacionadas."} />
                    <div>
                      <Container maxWidth="sm">
                        <Grid container spacing={3}>
                          <Grid
                            item
                            xs={12}
                            lg={12}
                            md={12}
                            sm={12}
                            style={{ textAlign: "center" }}
                          >
                            <img src={canita} style={{ width: "100%" }} />
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            lg={12}
                            md={12}
                            sm={12}
                            style={{ textAlign: "center", fontStyle: "italic" }}
                          >
                            Este elemento no tiene tareas relacionadas.
                          </Grid>
                        </Grid>
                      </Container>
                    </div>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
        <Modal
          // style={{ zIndex: 2990 }}
          aria-labelledby="Asignar usuarios"
          aria-describedby="Asignar usuarios"
          open={this.state.openAsignarUsuarios}
          onClose={this.handleCloseAsignarUsuarios}
        >
          <div style={getModalStyle()} className={classes.paperUserModal}>
            <PerfectScrollbar>
              <form className={classes.container} noValidate autoComplete="off">
                <Typography variant="h6" gutterBottom>
                  Asignar usuario(s)
                </Typography>

                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextField
                      id="txtTextoBusquedaUsuario"
                      label="Usuario"
                      className={classes.textField}
                      value={this.state.txtTextoBusquedaUsuario}
                      onChange={this.handleChange("txtTextoBusquedaUsuario")}
                      onKeyPress={(ev) => {
                        console.log("keyP: " + ev.key);
                        if (ev.key === "Enter") {
                          this.cargarListaUsuarios();
                          ev.preventDefault();
                        }
                      }}
                      margin="normal"
                      inputProps={{ maxLength: 512 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#217ecf",
                        color: "white",
                        width: "100%",
                      }}
                      className={classes.buttonAdd}
                      onClick={() => this.cargarListaUsuarios()}
                    >
                      Buscar
                    </Button>
                  </Grid>
                </Grid>

                <Grid item xs={12} lg={12} sm={12}>
                  <Grid container spacing={1}>
                    <UsuariosList
                      registros={this.state.listaUsuarios}
                      seleccionarUsuario={this.seleccionarUsuario}
                    />
                  </Grid>
                </Grid>
              </form>
            </PerfectScrollbar>
          </div>
        </Modal>

        <Modal
          // style={{ zIndex: 2990 }}
          aria-labelledby="Asignar usuarios"
          aria-describedby="Asignar usuarios"
          open={this.state.openAsignarTag}
          onClose={this.handleCloseAsignarTag}
        >
          <div style={getModalStyle()} className={classes.paperUserModal}>
            <PerfectScrollbar>
              <form className={classes.container} noValidate autoComplete="off">
                <Typography variant="h6" gutterBottom>
                  Agregar Etiquetas
                </Typography>

                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextField
                      id="txtTextoBusquedaTag"
                      label="Tag"
                      className={classes.textField}
                      value={this.state.txtTextoBusquedaTag}
                      onChange={this.handleChange("txtTextoBusquedaTag")}
                      onKeyPress={(ev) => {
                        console.log("keyP: " + ev.key);
                        if (ev.key === "Enter") {
                          this.cargarListaTag();
                          ev.preventDefault();
                        }
                      }}
                      margin="normal"
                      inputProps={{ maxLength: 512 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#217ecf",
                        color: "white",
                        width: "100%",
                      }}
                      className={classes.buttonAdd}
                      onClick={() => this.cargarListaTag()}
                    >
                      Buscar
                    </Button>
                  </Grid>
                </Grid>

                <Grid item xs={12} lg={12} sm={12}>
                  <Grid container spacing={1}>
                    <TagList
                      registros={this.state.listaTag}
                      seleccionarTag={this.seleccionarTag}
                    />
                  </Grid>
                </Grid>
              </form>
            </PerfectScrollbar>
          </div>
        </Modal>

        <Modal
          // style={{ zIndex: 2990 }}
          aria-labelledby="Asignar usuarios"
          aria-describedby="Asignar usuarios"
          open={this.state.openAdjuntarArchivos}
          onClose={this.handleCloseAdjuntos}
        >
          <div style={getModalStyle()} className={classes.paperUserModal}>
            <form className={classes.container} noValidate autoComplete="off">
              <Typography variant="h6" gutterBottom>
                Adjuntar archivo(s)
              </Typography>

              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <DropzoneArea onChange={this.handleChangeFiles.bind(this)} />
                </Grid>
              </Grid>
            </form>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

// const styles = StyleSheet.create({
//   tituloHeader: {
//     margin: 10,
//   },
// });

const AvisoDetalleTareasContainer = withStyles(styles)(AvisoDetalleTareas);
export default AvisoDetalleTareasContainer;
