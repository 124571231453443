import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width:"100%",
    marginTop:"10px",
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    width:"100%",
    textAlign:"center",
  },
  content: {
    flex: '1 0 auto',
    width:"100%",
  },
  cover: {
    width: 151,
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  icon: {
    height: 38,
    width: 38,
  },
  text:{
      
  }
}));

export default function TareaCard(props) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Card className={classes.root}>
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography component="h5" variant="h5" className={classes.text}>
           {props.titulo}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
          <strong>Descripción: </strong> {props.descripcion}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
          <strong>Tipo de tarea: </strong> {props.tipoTarea}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
          <strong>Estado: </strong><span style={{color:props.activo===true?"rgb(63, 81, 181)":"rgb(245, 0, 87)"}}>{props.activo===true?"Activo":"Inactivo"}</span>
          </Typography>
        </CardContent>
        <div className={classes.controls}>
                  
        </div>
      </div>
      {/* <CardMedia
        className={classes.cover}
        image="/static/images/cards/live-from-space.jpg"
        title="Live from space album cover"
      /> */}
    </Card>
  );
}