import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';

const navigate = {
    PREVIOUS: 'PREV',
    NEXT: 'NEXT',
    TODAY: 'TODAY',
    DATE: 'DATE',
}

const views = {
    MONTH: 'month',
    WEEK: 'week',
    WORK_WEEK: 'work_week',
    DAY: 'day',
    AGENDA: 'agenda',
}

const nombresVistas = ['Mes', 'Semana', 'Día']

class Toolbar extends React.Component {
    render() {
        let {
            localizer: { messages },
            label,
        } = this.props

        return (
            <div className="rbc-toolbar">
                <span className="rbc-btn-group">
                    <button
                        type="button"
                        onClick={this.navigate.bind(null, navigate.TODAY)}
                    >
                        Hoy
                    </button>
                    <button
                        type="button"
                        onClick={this.navigate.bind(null, navigate.PREVIOUS)}
                    >
                        Anterior
                    </button>
                    <button
                        type="button"
                        onClick={this.navigate.bind(null, navigate.NEXT)}
                    >
                        Siguiente
                    </button>
                </span>

                <span className="rbc-toolbar-label">{label}</span>

                <span className="rbc-btn-group">{this.viewNamesGroup(messages)}</span>
            </div>
        )
    }

    navigate = action => {
        this.props.onNavigate(action)
    }

    view = view => {
        this.props.onView(view)
    }

    viewNamesGroup(messages) {
        let viewNames = this.props.views
        const view = this.props.view

        if (viewNames.length > 1) {
            return viewNames.map((name, index) => (
                <button
                    type="button"
                    key={name}
                    className={clsx({ 'rbc-active': view === name })}
                    onClick={this.view.bind(null, name)}
                >
                    {nombresVistas[index]}
                </button>
            ))
        }
    }
}

Toolbar.propTypes = {
    view: PropTypes.string.isRequired,
    views: PropTypes.arrayOf(PropTypes.string).isRequired,
    label: PropTypes.node.isRequired,
    localizer: PropTypes.object,
    onNavigate: PropTypes.func.isRequired,
    onView: PropTypes.func.isRequired,
}

export default Toolbar

