import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";

import {
  Button,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  TextField,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import LabelIcon from "@material-ui/icons/Label";

import { makeStyles, withStyles } from "@material-ui/core/styles";

//Appbar
import { fade } from "@material-ui/core/styles";

//Own components
import API from "../../utils/api";
import { isSignedIn, signOut } from "../../utils/auth";
import { verificaPermisoPaginaActual } from "../../utils/helpers";

let EtiquetaID = 0;

const styles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,

    width: "100%",
  },
  formControlSelects: {
    margin: theme.spacing.unit,
    width: "100%",
  },
  paper: {
    position: "relative",
    width: "100%", //theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  popover: {
    pointerEvents: "none",
  },
  paperPopover: {
    padding: theme.spacing(1),
    background: "#000",
    color: "#fff",
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
  rootExpansionPanel: {
    width: "100%",
    marginTop: 30,
    marginBottom: 50,
  },
  ExpansionPanelRow: {},
  alignCenter: {
    alignItems: "center",
  },
  dowloadButton: {
    backgroundColor: "white",
    color: "#2196F3",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#f3f3f3",
      color: "#2196F3",
    },
  },
  titleGestiones: {
    display: "flex",
    flexDirection: "row",
  },
  heading: {
    marginLeft: 15,
  },
  secondNavigationModal: {
    position: "absolute",
    width: "50%",
    height: "400px",
    maxHeight: "400px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
}));

const FichaClinicaEmpleado = (props) => {
  const [userName, setUserName] = useState("");
  const [usuarioAfectado, setUsuarioAfectado] = useState("");
  const [codigoUsuarioActual, setCodigoUsuarioActual] = useState("");
  const [buscando, setBuscando] = useState(false);

  const [dataEmpleado, setDataEmpleado] = useState({
    CodigoEmpleadoFichaClinica: 0,
    DPI: "0",
    FechaPrimeraConsulta: "",
    AntecedenteGeneral: "",
    MotivoConsulta: "",
    AparatoMedico: "",
    ExamenMedicoPrevio: "",
    ActitudPiel: "",
    CraneoOrofaringe: "",
    AparatoRespiratorio: "",
    Abdomen: "",
    GenitoUrinario: "",
    Miembros: "",
    SistemaNervioso: "",
    GinecologicoRectal: "",
    DiagnosticoProvisional: "",
    RegionCardiaca: "",
    Activo: true,
    Eliminado: false,
    UsuarioInserto: "",
    FechaInserto: "",
    UsuarioModifico: "",
    FechaModifico: "",
  });
  // const [CodigoEmpleadoFichaClinica, setCodigoEmpleadoFichaClinica] = useState(0);
  const [Dpi, setDpi] = useState("0");
  // const [FechaPrimeraConsulta, setFechaPrimeraConsulta] = useState("");
  // const [AntecedenteGeneral, setAntecedenteGeneral] = useState("");
  // const [MotivoConsulta, setMotivoConsulta] = useState("");
  // const [AparatoMedico, setAparatoMedico] = useState("");
  // const [ExamenMedicoPrevio, setExamenMedicoPrevio] = useState("");
  // const [ActitudPiel, setActitudPiel] = useState("");
  // const [CraneoOrofaringe, setCraneoOrofaringe] = useState("");
  // const [AparatoRespiratorio, setAparatoRespiratorio] = useState("");
  // const [Abdomen, setAbdomen] = useState("");
  // const [GenitoUrinario, setGenitoUrinario] = useState("");
  // const [Miembros, setMiembros] = useState("");
  // const [SistemaNervioso, setSistemaNervioso] = useState("");
  // const [GinecologicoRectal, setGinecologicoRectal] = useState("");
  // const [DiagnosticoProvisional, setDiagnosticoProvisional] = useState("");
  // const [RegionCardiaca, setRegionCardiaca] = useState("");
  // const [Activo, setActivo] = useState(true);
  // const [Eliminado, setEliminado] = useState(false);
  // const [UsuarioInserto, setUsuarioInserto] = useState("");
  // const [FechaInserto, setFechaInserto] = useState("");
  // const [UsuarioModifico, setUsuarioModifico] = useState("");
  // const [FechaModifico, setFechaModifico] = useState("");
  const [acceso, setAcceso] = useState(false);
  const [valueAutoCompleteEnfermedad, setValueAutoCompleteEnfermedad] =
    useState(null);
  const [codigoEnfermedad, setCodigoEnfermedad] = useState(null);
  const [enfermedades, setEnfermedades] = useState([]);
  const [enfermedadesAsignadas, setEnfermedadesAsignadas] = useState([]);
  const [dialogoAbierto, abrirDialogo] = useState(false);
  const classes = styles();
  const dpiEmpleado = props.dpiEmpleado;

  useEffect(() => {
    async function fetchData() {
      //console.log(this.props);
      // CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
      const llaveAgrupacion = "Menu.Principal.Web";
      const llavePagina = "Menu.Principal.Web.FichaClinicaEmpleado";
      let acceso = await verificaPermisoPaginaActual(
        llaveAgrupacion,
        llavePagina
      );
      // FIN DE CONSULTA DE PERMISOS
      setAcceso(acceso);
      const session = await isSignedIn();

      if (dpiEmpleado === null || dpiEmpleado === undefined) {
        //window.location = "/home";
        return;
      }
      if (session.response) {
        //console.log(session);
        setDpi(dpiEmpleado);
        setCodigoUsuarioActual(session["codigoUsuario"]);
        setUsuarioAfectado(parseInt(session["codigoUsuario"]));
        setUserName(session["userName"]);

        await buscarFicha();
        await cargarEnfermedades();
        await cargarEnfermedadesAsignadas();
      } else {
        window.location.href = "/login";
      }
    }

    fetchData();
  }, []);

  const buscarFicha = async () => {
    const session = await isSignedIn();
    let cadena = "";
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      cadena = session["userName"];
    }

    try {
      setBuscando(true);

      let uri = "api/fichaClinica/" + dpiEmpleado;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        // for (const property in request.data.ficha) {
        //   if (property !== "CodigoEmpleadoFichaClinica") {
        //     useState({
        //       [property]: request.data.ficha[property],
        //     });
        //     console.log(`${property}: ${request.data.ficha[property]}`);
        //   }
        // }

        setDataEmpleado(request.data.ficha);
        setDataEmpleado((prevState) => ({
          ...prevState,
          DPI: dpiEmpleado,
          UsuarioInserto: cadena,
        }));
        console.log("Respuesta filtro", request.data);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setBuscando(false);
    }
  };

  const cargarEnfermedades = async () => {
    const session = await isSignedIn();
    let cadena = "";
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      cadena = session["userName"];
    }

    try {
      setBuscando(true);

      let uri = "api/Enfermedad/Lista";

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setEnfermedades(request.data.data);
        console.log(request);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setBuscando(false);
    }
  };

  const cargarEnfermedadesAsignadas = async () => {
    const session = await isSignedIn();
    let cadena = "";
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      cadena = session["userName"];
    }

    try {
      setBuscando(true);

      let uri = "api/Enfermedad/Empleado/" + dpiEmpleado;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setEnfermedadesAsignadas(request.data.data);
        console.log(request);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setBuscando(false);
    }
  };
  const handleChange = (name) => (event) => {
    event.persist();

    setDataEmpleado((prevState) => ({
      ...prevState,
      [name]: event.target.value,
    }));
  };

  const guardarFicha = async () => {
    // const data = {
    //   dpi: Dpi,
    //   fechaPrimeraConsulta: null,
    //   AntecedenteGeneral: AntecedenteGeneral,
    //   motivoConsulta: MotivoConsulta,
    //   aparatoMedico: AparatoMedico,
    //   examenMedicoPrevio: ExamenMedicoPrevio,
    //   ActitudPiel: ActitudPiel,
    //   CraneoOrofaringe: CraneoOrofaringe,
    //   RegionCardiaca: RegionCardiaca,
    //   AparatoRespiratorio: AparatoRespiratorio,
    //   abdomen: Abdomen,
    //   genitoUrinario: GenitoUrinario,
    //   miembros: Miembros,
    //   sistemaNervioso: SistemaNervioso,
    //   ginecologicoRectal: GinecologicoRectal,
    //   diagnosticoProvisional: DiagnosticoProvisional,
    //   activo: true,
    //   eliminado: false,
    //   usuarioInserto: userName,
    //   fechaInserto: "",
    //   usuarioModifico: "",
    //   fechaModifico: "",
    // };

    // dataFicha.usuarioInserto = "prueba";

    //setDataEmpleado({ UsuarioInserto: userName });
    console.log(dataEmpleado);
    try {
      setBuscando(true);
      let vIsSignedIn = await isSignedIn();
      if (vIsSignedIn.response) {
        let request;

        request = await API.post("api/fichaClinica/", dataEmpleado);
        if (request.status !== 200) {
          toast.error(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        } else {
          toast.success("Se guardó exitosamente");
        }
      }
    } catch (e) {
      toast.error(
        "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
      );
    } finally {
      setBuscando(false);
    }
  };

  const BotonClickAgregarEtiqueta = async (e) => {
    //props.handleAbrirBackDrop();

    if (codigoEnfermedad === null) {
      toast.info("Selecciona una enfermedad", {
        position: "top-right",
        autoClose: 5000, // Esta en milisegundos 3 segundos
      });

      // props.handleCerrarBackDrop();

      return;
    }
    const ex = enfermedades.filter(
      (elemento) => elemento.id === codigoEnfermedad.toString()
    );

    const informacion = {
      id: codigoEnfermedad,
      name: ex[0].name,
    };

    console.log(informacion);
    const etiquetaExistente = enfermedadesAsignadas.filter(
      (elemento) => elemento.id === codigoEnfermedad.toString()
    );

    if (etiquetaExistente.length === 0) {
      const infoRecurso = enfermedadesAsignadas.filter(
        (elemento) => elemento.id === codigoEnfermedad.toString()
      );

      toast.info("Se está agregando la etiqueta", {
        position: "top-right",
        autoClose: 1500, // Esta en milisegundos 3 segundos
      });

      // Agregando recursos al proyecto
      try {
        setBuscando(true);
        const datos = [...enfermedadesAsignadas, informacion];

        let datosAActualizar = [];

        datos.forEach((element) => {
          datosAActualizar.push(element.id);
        });

        let request;

        console.log("datos" + JSON.stringify(datosAActualizar));
        request = await API.post(
          "api/Enfermedad/Empleado/" + dpiEmpleado,
          datosAActualizar
        );

        if (request.status !== 200) {
          toast.error(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        } else {
          toast.success("Se guardó exitosamente");
        }

        setEnfermedadesAsignadas(datos);
      } catch (error) {
        console.log("Error en proyecto recursos" + error);
      } finally {
        setBuscando(false);
      }
    } else {
      toast.info("La etiqueta ya habia sido asignada con anterioridad", {
        position: "top-right",
        autoClose: 5000, // Esta en milisegundos 3 segundos
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        // onOpen: () => {
        //   handleAbrirBackDrop();
        // },
        // onClose: () => {
        //   handleCloseBackDrop();
        // },
      });
    }

    //props.handleCerrarBackDrop();
  };

  const handleCerrarDialogo = () => {
    abrirDialogo(false);
  };

  const handleDeleteChips = (chipToDelete) => async () => {
    const chipsRestantes = enfermedadesAsignadas.filter(
      (chip) => chip.id !== chipToDelete.id
    );

    handleCerrarDialogo();

    // props.handleAbrirBackDrop();

    toast.info("Se está quitando la etiqueta", {
      position: "top-right",
      autoClose: 1000, // Esta en milisegundos 3 segundos
    });

    try {
      setBuscando(true);
      let datosAActualizar = [];

      datosAActualizar.push(chipToDelete.id);

      let request;

      console.log("datos" + JSON.stringify(datosAActualizar));
      request = await API.post(
        "api/Enfermedad/Empleado/Quitar/" + dpiEmpleado,
        datosAActualizar
      );

      if (request.status !== 200) {
        toast.error(
          "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
        );
      } else {
        EtiquetaID = 0;
        toast.success("Se guardó exitosamente");
      }

      //SprintID = 0;

      setEnfermedadesAsignadas(chipsRestantes);
      //console.log("datos" + JSON.stringify(data));
    } catch (error) {
      console.log("Error en eliminación de sprints del proyecto" + error);
    } finally {
      setBuscando(false);
    }

    //props.handleCerrarBackDrop();
  };

  return (
    <>
      {acceso && (
        <Grid container style={{ width: "100% !important" }}>
          <ToastContainer></ToastContainer>
          <Backdrop open={buscando}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <form className={classes.root} noValidate autoComplete="off">
            <Grid container spacing={8} style={{ width: "100% !important" }}>
              <Grid item xs={12} sm={12}>
                <TextField
                  id="txtAntecedenteFamiliar"
                  className={classes.TextField}
                  label="Antecedentes"
                  variant="outlined"
                  required
                  autoFocus
                  onChange={handleChange("AntecedenteGeneral")}
                  multiline
                  rowsMax={10}
                  fullWidth
                  value={dataEmpleado.AntecedenteGeneral}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  id="txtMotivoConsulta"
                  className={classes.TextField}
                  label="Motivo de Consulta e Historia"
                  variant="outlined"
                  required
                  autoFocus
                  onChange={handleChange("MotivoConsulta")}
                  multiline
                  rowsMax={10}
                  fullWidth
                  value={dataEmpleado.MotivoConsulta}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  id="txtAparatoMedico"
                  className={classes.TextField}
                  label="Aparatos e Historia"
                  variant="outlined"
                  required
                  autoFocus
                  onChange={handleChange("AparatoMedico")}
                  multiline
                  rowsMax={10}
                  fullWidth
                  inputProps={{ maxLength: 255 }}
                  value={dataEmpleado.AparatoMedico}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  id="txtExamenMedicoPrevio"
                  className={classes.TextField}
                  label="Exámenes y Medicación Previos"
                  variant="outlined"
                  required
                  autoFocus
                  onChange={handleChange("ExamenMedicoPrevio")}
                  multiline
                  rowsMax={10}
                  fullWidth
                  value={dataEmpleado.ExamenMedicoPrevio}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  id="txtActitud"
                  className={classes.TextField}
                  label="Actitud y Piel"
                  variant="outlined"
                  required
                  autoFocus
                  onChange={handleChange("ActitudPiel")}
                  multiline
                  rowsMax={10}
                  fullWidth
                  value={dataEmpleado.ActitudPiel}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  id="txtCraneo"
                  className={classes.TextField}
                  label="Cráneo - Cuello - Garganta"
                  variant="outlined"
                  required
                  autoFocus
                  onChange={handleChange("CraneoOrofaringe")}
                  multiline
                  rowsMax={10}
                  fullWidth
                  value={dataEmpleado.CraneoOrofaringe}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  id="txtCorazon"
                  className={classes.TextField}
                  label="Tórax - Región Cardiaca"
                  variant="outlined"
                  required
                  autoFocus
                  onChange={handleChange("RegionCardiaca")}
                  multiline
                  rowsMax={10}
                  fullWidth
                  value={dataEmpleado.RegionCardiaca}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  id="txtPulmones"
                  className={classes.TextField}
                  label="Aparato Respiratorio"
                  variant="outlined"
                  required
                  autoFocus
                  onChange={handleChange("AparatoRespiratorio")}
                  multiline
                  rowsMax={10}
                  fullWidth
                  value={dataEmpleado.AparatoRespiratorio}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  id="txtAbdomen"
                  className={classes.TextField}
                  label="Abdomen"
                  variant="outlined"
                  required
                  autoFocus
                  onChange={handleChange("Abdomen")}
                  multiline
                  rowsMax={10}
                  fullWidth
                  value={dataEmpleado.Abdomen}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  id="txtGenitoUrinario"
                  className={classes.TextField}
                  label="Genito Urinario"
                  variant="outlined"
                  required
                  autoFocus
                  onChange={handleChange("GenitoUrinario")}
                  multiline
                  rowsMax={10}
                  fullWidth
                  value={dataEmpleado.GenitoUrinario}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  id="txtMiembros"
                  className={classes.TextField}
                  label="Miembros"
                  variant="outlined"
                  required
                  autoFocus
                  onChange={handleChange("Miembros")}
                  multiline
                  rowsMax={10}
                  fullWidth
                  value={dataEmpleado.Miembros}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  id="txtSistemaNervioso"
                  className={classes.TextField}
                  label="Sistema Nervioso"
                  variant="outlined"
                  required
                  autoFocus
                  onChange={handleChange("SistemaNervioso")}
                  multiline
                  rowsMax={10}
                  fullWidth
                  value={dataEmpleado.SistemaNervioso}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  id="txtGinecologicoRectal"
                  className={classes.TextField}
                  label="Ginecológico Rectal"
                  variant="outlined"
                  required
                  autoFocus
                  onChange={handleChange("GinecologicoRectal")}
                  multiline
                  rowsMax={10}
                  fullWidth
                  value={dataEmpleado.GinecologicoRectal}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  id="txtDiagnosticoProvisional"
                  className={classes.TextField}
                  label="diagnóstico Provisional"
                  variant="outlined"
                  required
                  autoFocus
                  onChange={handleChange("DiagnosticoProvisional")}
                  multiline
                  rowsMax={10}
                  fullWidth
                  value={dataEmpleado.DiagnosticoProvisional}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl className={classes.formControlSelects}>
                  <Autocomplete
                    id="enfermedades"
                    options={enfermedades}
                    getOptionLabel={(option) => option.name}
                    value={valueAutoCompleteEnfermedad}
                    onChange={(event, newValue) => {
                      if (newValue !== null) {
                        setCodigoEnfermedad(newValue.id);
                        setValueAutoCompleteEnfermedad(newValue);
                      }
                    }}
                    // style={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Enfermedad"
                        variant="outlined"
                      />
                    )}
                  />
                </FormControl>
                <FormControl>
                  <Button
                    // fullWidth
                    variant="contained"
                    style={{
                      backgroundColor: "#69BD4B",
                      color: "white",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: 20,
                    }}
                    onClick={BotonClickAgregarEtiqueta}
                  >
                    &nbsp;Agregar Condición
                  </Button>
                </FormControl>
                <br />
                <br />

                <InputLabel id="demo-simple-select-label">
                  Etiquetas Seleccionadas
                </InputLabel>
                <br />
                <div className={classes.root}>
                  {enfermedadesAsignadas.length >0 && enfermedadesAsignadas.map((row, index) => (
                    <Chip
                      color="primary"
                      icon={<LabelIcon />}
                      label={row.name}
                      key={row.id}
                      onDelete={handleDeleteChips(row)}
                      className={classes.chip}
                    />
                  ))}
                </div>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#69bd4b",
                    marginBottom: 10,
                    marginTop: 10,
                    color: "white",
                    width: "93%",
                  }}
                  className={classes.buttonAdd}
                  onClick={() => {
                    guardarFicha();
                  }}
                >
                  Guardar
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      )}
    </>
  );
};

//const FichaClinicaEmpleadoContainer = withStyles(styles)(FichaClinicaEmpleado);
export default FichaClinicaEmpleado;
