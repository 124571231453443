import React, { useState, useEffect } from 'react'
import {
  IconButton,
  TableContainer,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  LinearProgress,
  Fab,
  Dialog,
  makeStyles
} from '@material-ui/core'
import {
  Edit,
  Delete,
  Add,
  List
} from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import { ToastContainer, toast } from 'react-toastify'

import Layout from '../layout/containers/Layout'
import CentroDocumentalEditar from './componentes/centros-documentales/CentroDocumentalEditar'
import { formatoCorrelativo, getUsuarioInserta } from './utils/utilidades'
import api from '../utils/api'
import ListaDocumentos from './componentes/centros-documentales/ListaDocumentos'

const heads = [
  'Codigo',
  'Nombre división',
  'Macroproceso',
  'Ubicación',
  'Opciones'
]

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  }
}))

const CentrosDocumentalesCRUD = () => {
  const classes = useStyles()
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(false)
  const [dialogCrear, setDialogCrear] = useState(false)
  const [dialogEditar, setDialogEditar] = useState(false)
  const [dialogDocs, setDialogDocs] = useState(false)
  const [selected, setSelected] = useState(null)
  const [docs, setDocs] = useState([])
  const getCentrosDocumentales = async () => {
    try {
      setLoading(true)
      const { data } = await api.fetchGetRequest('api/documentos/centro-documental')
      setItems(data.map(i => ({
        ...i,
        CodigoCentroDocumental: `CD-${i.AbreviaturaDivision}-${i.Macroproceso.AbreviaturaProceso}-${i.Correlativo}`
      })))
    } catch (error) {
      toast.error('No se pudo cargar los datos')
    } finally {
      setLoading(false)
    }
  }
  const showEditar = (id) => {
    const item ={...items.find(i => i.IdCentroDocumental === id)}
    setSelected(item)
    setDialogEditar(true)
  }
  const eliminarCentroDocumental = async (id) => {
    if (window.confirm('¿Estas seguro de eliminar el registro?')) {
      const datos = {
        UsuarioModifico: getUsuarioInserta()
      }
      await api.realizarPeticionPostPut(`api/documentos/centro-documental/eliminar/${id}`, datos, 'POST')
      getCentrosDocumentales()
    }
  }
  const showDocs = async (id) => {
    try {
      setDialogDocs(true)
      const item ={...items.find(i => i.IdCentroDocumental === id)}
      setSelected(item)
      const { data } = await api.fetchGetRequest(`api/documentos/centro-documental/documentos-asociados/${id}`)
      setDocs(data.map(i => ({
        ...i,
        Codigo: formatoCorrelativo(i.Codigo)
      })))
    } catch (error) {
      toast.error('Ha ocurrido un error')
    }
  }
  const closeDocs = () => {
    setDialogDocs(false)
    setDocs([])
  }
  useEffect(() => {
    getCentrosDocumentales()
  }, [])
  return <Layout titulo="Centros Documentales">
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {
              heads.map(i => <TableCell key={i}>{i}</TableCell>)
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {
            items.map((i, idx) => <TableRow key={idx}>
              <TableCell>{i.CodigoCentroDocumental}</TableCell>
              <TableCell>{i.NombreDivision}</TableCell>
              <TableCell>{i.NombreMacroproceso}</TableCell>
              <TableCell>{i.Ubicacion}</TableCell>
              <TableCell>
                <IconButton
                  onClick={() => showEditar(i.IdCentroDocumental)}
                >
                  <Edit />
                </IconButton>
                <IconButton
                  onClick={() => showDocs(i.IdCentroDocumental)}
                >
                  <List />
                </IconButton>
                <IconButton
                  onClick={() => eliminarCentroDocumental(i.IdCentroDocumental)}
                >
                  <Delete />
                </IconButton>
              </TableCell>
            </TableRow>)
          }
        </TableBody>
      </Table>
      {
        loading && <LinearProgress />
      }
      {
        !loading && !items.length && <Alert severity="info" variant="filled">
          No hay registros
        </Alert>
      }
    </TableContainer>
    <ToastContainer />
    <Fab
      onClick={() => setDialogCrear(true)}
      style={{ backgroundColor: "#69BD4B", color: 'white' }}
      className={classes.fab}
      color="primary"
    >
      <Add />
    </Fab>
    <Dialog fullWidth maxWidth="md" open={dialogCrear}>
      <CentroDocumentalEditar
        onClose={() => setDialogCrear(false)}
        onRefresh={() => {
          setDialogCrear(false)
          getCentrosDocumentales()
        }}
      />
    </Dialog>
    <Dialog fullWidth maxWidth="md" open={dialogEditar}>
      <CentroDocumentalEditar
        data={selected}
        onClose={() => setDialogEditar(false)}
        onRefresh={() => {
          setDialogEditar(false)
          getCentrosDocumentales()
        }}
        update={true}
      />
    </Dialog>
    <Dialog fullWidth maxWidth="sm" onClose={closeDocs} open={dialogDocs}>
      <ListaDocumentos
        centro={selected}
        items={docs}
        onClose={closeDocs}
      />
    </Dialog>
  </Layout>
}

export default CentrosDocumentalesCRUD
