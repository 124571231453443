import {
  Backdrop, CircularProgress, Divider, Grid
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { ToastContainer } from "react-toastify";
import API from "../../utils/api";
import { isSignedIn } from "../../utils/auth";

import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';


const width_proportion = "100%";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 210,
  },
  textFieldDireccion: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginTop: theme.spacing.unit * 4,
    minWidth: 800,
    margin: theme.spacing(1),
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  greenAvatar: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  paperCard: {
    padding: 20,
    width: "100%",
  },
  formControlSelects: {
    margin: theme.spacing(1),
    minWidth: 230,
  },
  formControlSelect: {
    // margin: theme.spacing(1),
    width: width_proportion,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  datoContent: {
    alignItems: 'center',
    //"border-style": "groove",
    //"border-width": "thin"
  },
  datoContentActividad: {
    alignItems: 'center',
    //"border-style": "groove",
    //"border-width": "thin"
  },
  labelContainer: {
    display: 'flex',
    flex: 0.40,
    alignItems: 'center',
    fontWeight: '600',
    fontSize: '1rem',
  },
  datoContainer: {
    marginTop: '0.30rem',
    width: '1400px',
    margin: 'auto'
  },
  datoTxt: {
    flex: 0.60,
    fontSize: '1rem',
    fontWeight: '600',
    color: '#000000a6'
  },
  icon: {
    fontSize: '1.25rem',
    color: '#147085',
    marginRight: '0.5rem',
  },
  titulo: {
    "text-align": "center",
    fontWeight: "700",
  }
}));

const Perfil = (props) => {

  const [userName, setUserName] = useState("");
  const [datosPerfil, setDatosPerfil] = useState([]);
  const [datosPerfilNegocio, setDatosPerfilNegocio] = useState([]);
  const [buscando, setBackDrop] = useState(false);
  const [relaciones, setRelaciones] = useState([]);

  const handleCerrarBackDrop = () => {
    setBackDrop(false);
  };
  const handleAbrirBackDrop = () => {
    setBackDrop(true);
  };

  const classes = useStyles();

  useEffect(() => {
    const cargar = async () => {
      if (props.puestoLaboral !== undefined) {
        handleAbrirBackDrop();
        await cargarDatosPerfil(props.puestoLaboral.CodigoPuestoLaboral);
        handleCerrarBackDrop();
      }
    };

    cargar();
  }, [props.puestoLaboral]);

  const cargarDatosPerfil = async (idPuesto) => {
    let session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    }
    try {
      handleAbrirBackDrop();

      const request = await API.fetchGetRequest(`api/PuestoLaboral/perfilDePuesto/${idPuesto}`);

      if (request.data.data == null) {
        return;
      }
      console.log(request.data);
      //setDatosPerfil(request.data.data.DatosTecnicos);
      setDatosPerfilNegocio(request.data.data.DatosNegocio);
      setRelaciones(request.data.data.Relaciones);
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  return (
    <React.Fragment>
      <Backdrop className={classes.backdrop} open={buscando}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer />


      <Grid container spacing={3} style={{ marginTop: "auto" }}>


        <Grid item md={12} className={classes.datoContent}>
          <Typography variant="h6" className={classes.titulo}>Responsabilidades o Funciones de Negocio</Typography>
          <Divider style={{ marginBottom: "10px" }}></Divider>
        </Grid>
        {datosPerfilNegocio.map((act, idx) => (
          <React.Fragment>
            <Grid item container md={6} className={classes.datoContentActividad}>
              <Grid item md={2}>
                <div className={classes.labelContainer}>
                  <AssignmentTurnedInIcon className={classes.icon} />
                  <Typography className={classes.datoTxt}>{act.CodigoNivelPuestoLaboral + '.' + (idx + 1)} </Typography>
                </div>
              </Grid>
              <Grid item md={10}>
                <Typography className={classes.label}>{act ? act.Descripcion : "-"}</Typography>
              </Grid>
            </Grid>
          </React.Fragment>
        ))}

        <Grid item md={12} className={classes.datoContent}>
          <Typography variant="h6" className={classes.titulo}>Responsabilidades o Funciones Genéricas</Typography>
          <Divider style={{ marginBottom: "10px" }}></Divider>
        </Grid>

        <Grid item container md={6} className={classes.datoContentActividad}>
          <Grid item md={2}>
            <div className={classes.labelContainer}>
              <AssignmentTurnedInIcon className={classes.icon} />
              <Typography className={classes.datoTxt}>{'1.1'} </Typography>
            </div>
          </Grid>
          <Grid item md={10}>
            <Typography className={classes.label}>{"TBD"}</Typography>
          </Grid>
        </Grid>
        <Grid item container md={6} className={classes.datoContentActividad}>
          <Grid item md={2}>
            <div className={classes.labelContainer}>
              <AssignmentTurnedInIcon className={classes.icon} />
              <Typography className={classes.datoTxt}>{'1.2'} </Typography>
            </div>
          </Grid>
          <Grid item md={8}>
            <Typography className={classes.label}>{"TBD"}</Typography>
          </Grid>
        </Grid>
        <Grid item container md={6} className={classes.datoContentActividad}>
          <Grid item md={2}>
            <div className={classes.labelContainer}>
              <AssignmentTurnedInIcon className={classes.icon} />
              <Typography className={classes.datoTxt}>{'1.3'} </Typography>
            </div>
          </Grid>
          <Grid item md={10}>
            <Typography className={classes.label}>{"TBD"}</Typography>
          </Grid>
        </Grid>
        <Grid item container md={6} className={classes.datoContentActividad}>
          <Grid item md={2}>
            <div className={classes.labelContainer}>
              <AssignmentTurnedInIcon className={classes.icon} />
              <Typography className={classes.datoTxt}>{'1.4'} </Typography>
            </div>
          </Grid>
          <Grid item md={8}>
            <Typography className={classes.label}>{"TBD"}</Typography>
          </Grid>
        </Grid>

        <Grid item md={12} className={classes.datoContent}>
          <Typography variant="h6" className={classes.titulo}>Competencias Técnicas</Typography>
          <Divider style={{ marginBottom: "10px" }}></Divider>
        </Grid>
        <Grid item container md={6} className={classes.datoContentActividad}>
          <Grid item md={2}>
            <div className={classes.labelContainer}>
              <AssignmentTurnedInIcon className={classes.icon} />
              <Typography className={classes.datoTxt}>{'1'} </Typography>
            </div>
          </Grid>
          <Grid item md={10}>
            <Typography className={classes.label}>{"Competencia Técnica 1"}</Typography>
          </Grid>
        </Grid>
        <Grid item container md={6} className={classes.datoContentActividad}>
          <Grid item md={2}>
            <div className={classes.labelContainer}>
              <AssignmentTurnedInIcon className={classes.icon} />
              <Typography className={classes.datoTxt}>{'2'} </Typography>
            </div>
          </Grid>
          <Grid item md={8}>
            <Typography className={classes.label}>{"Competencia Técnica 2"}</Typography>
          </Grid>
        </Grid>
        <Grid item container md={6} className={classes.datoContentActividad}>
          <Grid item md={2}>
            <div className={classes.labelContainer}>
              <AssignmentTurnedInIcon className={classes.icon} />
              <Typography className={classes.datoTxt}>{'3'} </Typography>
            </div>
          </Grid>
          <Grid item md={10}>
            <Typography className={classes.label}>{"Competencia Técnica 3"}</Typography>
          </Grid>
        </Grid>
        <Grid item container md={6} className={classes.datoContentActividad}>
          <Grid item md={2}>
            <div className={classes.labelContainer}>
              <AssignmentTurnedInIcon className={classes.icon} />
              <Typography className={classes.datoTxt}>{'4'} </Typography>
            </div>
          </Grid>
          <Grid item md={8}>
            <Typography className={classes.label}>{"Competencia Técnica 4"}</Typography>
          </Grid>
        </Grid>

        <Grid item md={12} className={classes.datoContent}>
          <Typography variant="h6" className={classes.titulo}>Competencias Transversales</Typography>
          <Divider style={{ marginBottom: "10px" }}></Divider>
        </Grid>
        <Grid item container md={6} className={classes.datoContentActividad}>
          <Grid item md={2}>
            <div className={classes.labelContainer}>
              <AssignmentTurnedInIcon className={classes.icon} />
              <Typography className={classes.datoTxt}>{'1'} </Typography>
            </div>
          </Grid>
          <Grid item md={10}>
            <Typography className={classes.label}>{"Competencia Genérica 1"}</Typography>
          </Grid>
        </Grid>
        <Grid item container md={6} className={classes.datoContentActividad}>
          <Grid item md={2}>
            <div className={classes.labelContainer}>
              <AssignmentTurnedInIcon className={classes.icon} />
              <Typography className={classes.datoTxt}>{'2'} </Typography>
            </div>
          </Grid>
          <Grid item md={8}>
            <Typography className={classes.label}>{"Competencia Genérica 2"}</Typography>
          </Grid>
        </Grid>
        <Grid item container md={6} className={classes.datoContentActividad}>
          <Grid item md={2}>
            <div className={classes.labelContainer}>
              <AssignmentTurnedInIcon className={classes.icon} />
              <Typography className={classes.datoTxt}>{'3'} </Typography>
            </div>
          </Grid>
          <Grid item md={10}>
            <Typography className={classes.label}>{"Competencia Genérica 3"}</Typography>
          </Grid>
        </Grid>
        <Grid item container md={6} className={classes.datoContentActividad}>
          <Grid item md={2}>
            <div className={classes.labelContainer}>
              <AssignmentTurnedInIcon className={classes.icon} />
              <Typography className={classes.datoTxt}>{'4'} </Typography>
            </div>
          </Grid>
          <Grid item md={8}>
            <Typography className={classes.label}>{"Competencia Genérica 4"}</Typography>
          </Grid>
        </Grid>

        <Grid item md={6} className={classes.datoContent}>
          <Typography variant="h6" className={classes.titulo}>Relaciones Internas</Typography>
          <Divider style={{ marginBottom: "10px" }}></Divider>
        </Grid>

        <Grid item md={6} className={classes.datoContent}>
          <Typography variant="h6" className={classes.titulo}>Relaciones Externas</Typography>
          <Divider style={{ marginBottom: "10px" }}></Divider>
        </Grid>

        <Grid item container md={6} className={classes.datoContent}>
          {relaciones.filter((categ) => { return categ.Tipo === 1 }).map((categoria, idx) => (
            <React.Fragment>
              <Grid item container md={12} className={classes.datoContentActividad}>
                <Grid item md={1}>
                  <div className={classes.labelContainer}>
                    <FiberManualRecordIcon className={classes.icon} />
                    {/* <Typography className={classes.datoTxt}>{idx + 1} </Typography> */}
                  </div>
                </Grid>
                <Grid item md={11}>
                  <Typography className={classes.label}>{categoria ? categoria.Categoria : "-"}</Typography>
                </Grid>
              </Grid>
              {categoria.Relaciones.map((relacion, idy) => (
                <Grid item container md={12} className={classes.datoContentActividad}>
                  <Grid item md={2}>
                  </Grid>
                  <Grid item md={10}>
                    <Typography className={classes.label}>{relacion ? relacion : "-"}</Typography>
                  </Grid>
                </Grid>
              ))}
            </React.Fragment>
          ))}
        </Grid>

        <Grid item container md={6} className={classes.datoContent}>
          {relaciones.filter((categ) => { return categ.Tipo === 2 }).map((categoria, idx) => (
            <React.Fragment>
              <Grid item container md={12} className={classes.datoContentActividad}>
                <Grid item md={1}>
                  <div className={classes.labelContainer}>
                    <FiberManualRecordIcon className={classes.icon} />
                    {/* <Typography className={classes.datoTxt}>{idx + 1} </Typography> */}
                  </div>
                </Grid>
                <Grid item md={11}>
                  <Typography className={classes.label}>{categoria ? categoria.Categoria : "-"}</Typography>
                </Grid>
              </Grid>
              {categoria.Relaciones.map((relacion, idy) => (
                <Grid item container md={12} className={classes.datoContentActividad}>
                  <Grid item md={2}>
                  </Grid>
                  <Grid item md={10}>
                    <Typography className={classes.label}>{relacion ? relacion : "-"}</Typography>
                  </Grid>
                </Grid>
              ))}
            </React.Fragment>
          ))}
        </Grid>






      </Grid>

    </React.Fragment >
  );
};

export default Perfil;
