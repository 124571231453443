import { Box, Button, Checkbox, FormControlLabel, Grid, InputLabel, ListSubheader, MenuItem, Select, TextField } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layout from "../../layout/containers/Layout";
import API from "../../utils/api";
import AsistenciasGrid from "../Components/asistenciasGrid";
import AsistenciasResumidasGrid from "../Components/asistenciasResumidasGrid";
import { registrarAnalitica } from "../../utils/firebase-config";



const ReporteAsistenciasContainer = (props) => {
    const classes = styles();
    const [fechaInicio, setFechaInicio] = useState(new Date().toISOString().slice(0, 10));
    const [fechaFin, setFechaFin] = useState(new Date().toISOString().slice(0, 10));
    const [resumido, setResumido] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [tipoGestion, setTipoGestion] = useState("");
    const [tiposGestion, setTiposGestion] = useState([]);
    //const [startDate, setStartDate] = useState(getCurrentDate());

    const actualizarFiltros = (e) => {
        e.preventDefault();
        if (tipoGestion === null || tipoGestion === "") {
            toast.warn("Debe seleccionar un tipo de gestión");
            return;
        }
        //check if date inicio is less than date fin
        if (document.getElementById("date-inicio").value > document.getElementById("date-fin").value) {
            toast.warn("La fecha de inicio no puede ser mayor a la fecha de fin");
            return;
        }
        setFechaInicio(document.getElementById("date-inicio").value);
        setFechaFin(document.getElementById("date-fin").value);
        setResumido(document.getElementById("resumir").checked);
        //setTipoGestion(document.getElementById("tipo-gestion").value);
        //console.log(document.getElementById("tipo-gestion").value);
        setSubmitted(true);
    }

    useEffect(() => {
        registrarAnalitica("Reporte_Asistencias");
        const fetchData = async () => {
            try {
                let uri = `api/asistencia/getTiposAvisoAsistencia`;
                const request = await API.fetchGetRequest(uri);
                setTiposGestion(request.data.data);
                console.log("obtenerTiposGestion: ", request.data.data);
            } catch (e) {
                console.log("obtenerTiposGestion error:" + e);
                setTiposGestion([]);
            }
        }
        fetchData();
    }, []);

    //returns a the current date for the textfield, in local time zone
    const getCurrentDate = () => {
        let now = new Date();
        let localDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        return localDate.toISOString().slice(0, 10);
    };

    return (
        <Layout titulo={`Reporte de Asistencias de Personal`} maxWidth={"100%"}>
            <React.Fragment>
                <ToastContainer />
                <Box className={classes.paperPerfil} pl={0} pt={0}>
                    <Box className={classes.box} pl={0} pt={0}>
                        <br></br>

                        <Grid container spacing={0}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <form className={classes.container} noValidate onSubmit={actualizarFiltros}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={3}>
                                            <InputLabel id="labelSelect">Tipo de Gestión</InputLabel>
                                            <Select
                                                id="tipo-gestion"
                                                value={tipoGestion}
                                                onChange={(event) => setTipoGestion(event.target.value)}
                                                label="Tipo de Gestión"
                                                labelId="labelSelect"
                                                //style={{ zIndex: 1 }}
                                                fullWidth
                                                required
                                            >
                                                <MenuItem style={{ zIndex: 0 }} value="" > </MenuItem>
                                                {tiposGestion.map((categoria, indexC) => {

                                                    return [
                                                        <ListSubheader key={indexC}>{categoria.Categoria}</ListSubheader>,
                                                        ...categoria.Tipos.map((tipo, indexT) => (
                                                            <MenuItem style={{ zIndex: 0 }} key={tipo.CodigoProblema} value={tipo.CodigoProblema}>
                                                                {tipo.Nombre}
                                                            </MenuItem>
                                                        ))
                                                    ];

                                                })}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={12} sm={6} style={{ display: 'flex', flexDirection: 'row' }}>
                                            <TextField
                                                id="date-inicio"
                                                label="Fecha de Inicio"
                                                type="date"
                                                defaultValue={getCurrentDate()}
                                                className={classes.textField}
                                                style={{ flex: '1 1 0' }}
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            //onChange={(event) => setStartDate(event.target.value)}
                                            />
                                            <TextField
                                                id="date-fin"
                                                label="Fecha de Fin"
                                                type="date"
                                                defaultValue={getCurrentDate()}
                                                variant="outlined"
                                                className={classes.textField}
                                                style={{ flex: '1 1 0' }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            // inputProps={{
                                            //    min: startDate,
                                            //}}
                                            />
                                            <FormControlLabel
                                                control={<Checkbox id="resumir" color="primary" />}
                                                label="Agrupar y mostrar mapa"
                                                style={{ flex: '1 1 0' }}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} style={{ textAlign: "right", marginRight: "100px" }}>
                                            <Button
                                                className="btn btn-primary"
                                                type="submit"
                                                variant="contained"
                                                style={{
                                                    backgroundColor: "#69bd4b",
                                                    color: "white",
                                                }}
                                            >Generar Reporte</Button>
                                        </Grid>
                                    </Grid>
                                </form>

                            </Grid>
                        </Grid>
                        <br></br>
                    </Box>
                    {submitted && (
                        <React.Fragment>
                            {resumido ? <AsistenciasResumidasGrid FechaInicio={fechaInicio} FechaFin={fechaFin} Aviso={"1"} TipoGestion={tipoGestion}></AsistenciasResumidasGrid>
                                : <AsistenciasGrid FechaInicio={fechaInicio} FechaFin={fechaFin} Aviso={"0"} TipoGestion={tipoGestion}></AsistenciasGrid>}
                        </React.Fragment>
                    )}
                </Box>

            </React.Fragment >
        </Layout >
    );
};

const styles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexWrap: "wrap",
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: "100%",
    },
    paperPerfil: {
        position: "relative",
        width: "100%", //theme.spacing.unit * 50,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 1,
        outline: "none",
    },
    paper: {
        position: "absolute",
        width: theme.spacing.unit * 100,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 1,
        outline: "none",
    },
    buttonAdd: {
        margin: theme.spacing.unit,
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    large: {
        width: theme.spacing(12),
        height: theme.spacing(12),
    },
    greenAvatar: {
        fontSize: "2.5em",
        color: "#fff",
        // borderStyle:'solid',
        // borderWidth:'1 !important',
        // borderColor:'#3f51b5 !important',
        backgroundColor: green[500],
        width: theme.spacing(12),
        height: theme.spacing(12),
    },
    paperCard: {
        padding: 20,
        width: "100%",
    },
    box: {
        position: "relative",
        width: "100%", //theme.spacing.unit * 50,
        padding: theme.spacing.unit * 1,
        outline: "none",
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}));

export default withRouter(ReporteAsistenciasContainer);