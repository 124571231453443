import { Backdrop, Button, CircularProgress, Grid } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { format, parseISO } from "date-fns";
import { ToastContainer } from "react-toastify";
import API from "../../utils/api";
import { isSignedIn } from "../../utils/auth";

import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Modal from "@material-ui/core/Modal";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import BarChartIcon from "@material-ui/icons/BarChart";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import GetAppIcon from "@material-ui/icons/GetApp";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import HorizontalSplitIcon from "@material-ui/icons/HorizontalSplit";
import MergeTypeIcon from "@material-ui/icons/MergeType";
import SpellcheckIcon from "@material-ui/icons/Spellcheck";
import UpdateIcon from "@material-ui/icons/Update";
import { toast } from "react-toastify";
import firma from "../../image-repository/loadImage.gif";

import OrganigramaPuestoComponente from "../components/organigrama-puesto-componente";

const width_proportion = "100%";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 210,
  },
  textFieldDireccion: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginTop: theme.spacing.unit * 4,
    minWidth: 800,
    margin: theme.spacing(1),
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  greenAvatar: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  paperCard: {
    padding: 20,
    width: "100%",
  },
  formControlSelects: {
    margin: theme.spacing(1),
    minWidth: 230,
  },
  formControlSelect: {
    // margin: theme.spacing(1),
    width: width_proportion,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  datoContent: {
    alignItems: "center",
    "border-style": "groove",
    "border-width": "thin",
    margin: "0px",
  },
  labelContainer: {
    display: "flex",
    flex: 0.4,
    alignItems: "center",
    fontWeight: "600",
    fontSize: "1rem",
  },
  datoContainer: {
    marginTop: "0.30rem",
    width: "1400px",
    margin: "auto",
  },
  datoTxt: {
    flex: 0.6,
    fontSize: "1rem",
    fontWeight: "600",
    color: "#000000a6",
  },
  icon: {
    fontSize: "1.25rem",
    color: "#147085",
    marginRight: "0.5rem",
  },
  titulo: {
    "text-align": "center",
    fontWeight: "700",
  },
  datoContentActividad: {
    alignItems: "center",
    //"border-style": "groove",
    //"border-width": "thin"
  },
  button: {
    textAlign: "right",
    marginBottom: "10px",
  },
}));

const Descriptor = (props) => {
  const [userName, setUserName] = useState("");
  const [datosDescriptor, setDatosDescriptor] = useState(null);
  const [funcionesTecnicas, setFuncionesTecnicas] = useState([]);
  const [firmas, setFirmas] = useState([]);
  const [qr, setQr] = useState([]);
  const [funcionesNegocio, setFuncionesNegocio] = useState([]);
  const [buscando, setBackDrop] = useState(false);
  const [idPuestoPlaza, setIdPuestoPLaza] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [idLogo, setIdLogo] = useState(3);
  const [imprimir, setImprimir] = useState(false);

  const handleOpen = () => {
    setImprimir(true);
    // console.log("Se mando a guardar: ");
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setImprimir(false);
  };

  const handleCerrarBackDrop = () => {
    if (props.handleAbrirBackDrop !== undefined) {
      props.handleCerrarBackDrop();
    } else {
      setBackDrop(false);
    }
  };
  const handleAbrirBackDrop = () => {
    if (props.handleAbrirBackDrop !== undefined) {
      props.handleAbrirBackDrop();
    } else {
      setBackDrop(true);
    }
  };

  const classes = useStyles();

  useEffect(() => {
    const cargar = async () => {
      try {
        if (props.plazaLaboral !== undefined) {
          console.log(props);
          let valor = await cargarPuestoLaboral(props.plazaLaboral);
          handleAbrirBackDrop();
          await cargarDatosDescriptor(valor);
          handleCerrarBackDrop();
        } else {
          handleAbrirBackDrop();
          console.log("descriptor");
          console.log(props);
          await cargarDatosDescriptor(props.puestoLaboral.CodigoPuestoLaboral);
          setIdPuestoPLaza(props.puestoLaboral.CodigoPuestoLaboral);
          handleCerrarBackDrop();
        }
      } catch (e) {
        console.log(e);
      }
    };

    cargar();
  }, []);

  const cargarDatosDescriptor = async (idPuesto) => {
    let session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    }
    try {
      handleAbrirBackDrop();
      const request = await API.fetchGetRequest(
        "api/PuestoLaboral/DescriptorDePuestoCompleto/" + idPuesto
      );

      if (request.statusCode !== 200) {
        toast.error("Error en obtener P&D: " + request.data.Message);
      } else {
        if (request.data.data == null) {
          return;
        }
        setDatosDescriptor(request.data.data.Encabezados);
        //setFuncionesNegocio(request.data.data.DatosNegocio);
        setFuncionesTecnicas(request.data.data.DatosTecnicos);
        setFirmas(request.data.data.Firmas);
        setQr(request.data.data.Qr);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cargarPuestoLaboral = async (idPlaza) => {
    let session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    }
    try {
      handleAbrirBackDrop();

      const request = await API.fetchGetRequest(
        `api/PuestoLaboral/getPuesto/${idPlaza}`
      );

      if (request.data.data == null) {
        return;
      }
      setIdPuestoPLaza(request.data.data.CodigoPuestoLaboral);
      handleCerrarBackDrop();
      return request.data.data.CodigoPuestoLaboral;
      //setFuncionesTecnicas(request.data.data.DatosTecnicos);
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const downloadPdf = async () => {
    try {
      handleAbrirBackDrop();
      let uri =
        "api/PuestoLaboral/descriptorPdf/" + idPuestoPlaza + "/" + idLogo;
      let base_api = API.baseApi();
      let auth = await isSignedIn();

      //const request = await API.fetchGetRequest(uri);
      //console.log(request);
      axios({
        url: `${base_api}${uri}`, //your url
        method: "GET",
        responseType: "blob", // important
        headers: {
          Authorization: "bearer " + auth.accessToken,
        },
      })
        .then((response) => {
          handleAbrirBackDrop();
          const filename = response.headers["content-disposition"]
            .split("filename=")[1]
            .replace(/['"]/g, "");
          // create file link in browser's memory
          const href = URL.createObjectURL(response.data);

          // create "a" HTLM element with href to file & click
          const link = document.createElement("a");
          link.href = href;
          link.setAttribute("download", filename); //or any other extension
          document.body.appendChild(link);
          link.click();

          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        })
        .finally(() => {
          handleClose();
          handleCerrarBackDrop();
        });
    } catch (error) {
      toast.error("Ha ocurrido un error al subir el archivo: " + error);
      console.log(error);
    } finally {
      handleClose();
      handleCerrarBackDrop();
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    backgroundColor: "white",
    padding: "10px",
  };

  return (
    <React.Fragment>
      <Backdrop className={classes.backdrop} open={buscando}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer />

      <div className={classes.button} style={{ width: "100%" }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<GetAppIcon />}
          onClick={handleOpen}
        >
          Descargar
        </Button>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        //aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Seleccionar logo de la empresa
          </Typography>

          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={idLogo}
              name="radio-buttons-group"
              onClick={(e) => setIdLogo(e.target.value)}
            >
              <FormControlLabel
                value="3"
                control={<Radio />}
                label="Ingenio La Unión, S.A."
              />
              <FormControlLabel
                value="2"
                control={<Radio />}
                label="Servicios Administrativos, S.A."
              />
              <FormControlLabel
                value="1"
                control={<Radio />}
                label="Los Tarros, S.A."
              />
              <FormControlLabel
                value="4"
                control={<Radio />}
                label="Inversiones Holanda, S.A."
              />
              <FormControlLabel
                value="5"
                control={<Radio />}
                label="Vasijas, S.A."
              />
              <FormControlLabel
                value="6"
                control={<Radio />}
                label="Seguridad ILU, S.A."
              />
              <FormControlLabel
                value="7"
                control={<Radio />}
                label="Sin logo"
              />
            </RadioGroup>
            <Button
              variant="contained"
              color="primary"
              startIcon={<GetAppIcon />}
              onClick={() => downloadPdf()}
            >
              Descargar
            </Button>
          </FormControl>
        </Box>
      </Modal>
      <Grid container spacing={3} style={{ marginTop: "auto" }}>
        <Grid
          item
          xs={12}
          className={classes.datoContent}
          style={{ backgroundColor: "gainsboro" }}
        >
          <Typography variant="h6" className={classes.titulo}>
            Descriptor de Puesto
          </Typography>
        </Grid>
        <Grid item container xs={6} className={classes.datoContent}>
          <Grid item xs={4}>
            <div className={classes.labelContainer}>
              <HorizontalSplitIcon className={classes.icon} />
              <Typography className={classes.datoTxt}>
                Código de Puesto:{" "}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={8}>
            <Typography className={classes.label}>
              {datosDescriptor ? datosDescriptor.CodigoPuestoLaboral : "-"}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container xs={6} className={classes.datoContent}>
          <Grid item xs={4}>
            <div className={classes.labelContainer}>
              <AssignmentIndIcon className={classes.icon} />
              <Typography className={classes.datoTxt}>
                Nombre del Puesto:
              </Typography>
            </div>
          </Grid>
          <Grid item xs={8}>
            <Typography className={classes.label}>
              {datosDescriptor ? datosDescriptor.PuestoLaboral : "-"}
            </Typography>
          </Grid>
        </Grid>

        <Grid item container xs={6} className={classes.datoContent}>
          <Grid item xs={4}>
            <div className={classes.labelContainer}>
              <GroupWorkIcon className={classes.icon} />
              <Typography className={classes.datoTxt}>Gerencia: </Typography>
            </div>
          </Grid>
          <Grid item xs={8}>
            <Typography className={classes.label}>
              {datosDescriptor ? datosDescriptor.Gerencia : "-"}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container xs={6} className={classes.datoContent}>
          <Grid item xs={4}>
            <div className={classes.labelContainer}>
              <BarChartIcon className={classes.icon} />
              <Typography className={classes.datoTxt}>Nivel:</Typography>
            </div>
          </Grid>
          <Grid item xs={8}>
            <Typography className={classes.label}>
              {datosDescriptor ? datosDescriptor.NivelPuestoLaboral : "-"}
            </Typography>
          </Grid>
        </Grid>

        <Grid item container xs={6} className={classes.datoContent}>
          <Grid item xs={4}>
            <div className={classes.labelContainer}>
              <SpellcheckIcon className={classes.icon} />
              <Typography className={classes.datoTxt}>Proceso: </Typography>
            </div>
          </Grid>
          <Grid item xs={8}>
            <Typography className={classes.label}>
              {datosDescriptor ? datosDescriptor.Proceso : "-"}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container xs={6} className={classes.datoContent}>
          <Grid item xs={4}>
            <div className={classes.labelContainer}>
              <MergeTypeIcon className={classes.icon} />
              <Typography className={classes.datoTxt}>
                Tipo de Puesto:
              </Typography>
            </div>
          </Grid>
          <Grid item xs={8}>
            <Typography className={classes.label}>
              {datosDescriptor ? datosDescriptor.TipoPuesto : "-"}
            </Typography>
          </Grid>
        </Grid>

        <Grid item container xs={6} className={classes.datoContent}>
          <Grid item xs={4}>
            <div className={classes.labelContainer}>
              <EventAvailableIcon className={classes.icon} />
              <Typography className={classes.datoTxt}>
                Fecha de Actualización:{" "}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={8}>
            <Typography className={classes.label}>
              {datosDescriptor
                ? datosDescriptor.FechaActualizacion
                  ? format(
                      parseISO(datosDescriptor.FechaActualizacion),
                      "dd/MM/yyyy"
                    )
                  : "-"
                : "-"}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container xs={6} className={classes.datoContent}>
          <Grid item xs={4}>
            <div className={classes.labelContainer}>
              <UpdateIcon className={classes.icon} />
              <Typography className={classes.datoTxt}>Versión:</Typography>
            </div>
          </Grid>
          <Grid item xs={8}>
            <Typography className={classes.label}>
              {datosDescriptor ? datosDescriptor.Version : "-"}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          className={classes.datoContent}
          style={{ backgroundColor: "gainsboro" }}
        >
          <Typography variant="h6" className={classes.titulo}>
            Objetivo General del Puesto
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.datoContent}
          style={{ textAlign: "center" }}
        >
          <Typography className={classes.label}>
            {" "}
            {datosDescriptor ? datosDescriptor.Objetivo : "-"}
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          className={classes.datoContent}
          style={{ backgroundColor: "gainsboro" }}
        >
          <Typography variant="h6" className={classes.titulo}>
            Organigrama
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.datoContent}>
          <div style={{ marginTop: "30px", width: "100%" }}>
            <OrganigramaPuestoComponente
              //puestoLaboral={{CodigoPuestoLaboral: idPuestoPlaza ? idPuestoPlaza : datosDescriptor ? datosDescriptor.CodigoPuestoLaboral : ""}}
              //puestoLaboral={{CodigoPuestoLaboral: "10575"}}
              puestoLaboral={{
                CodigoPuestoLaboral: datosDescriptor
                  ? datosDescriptor.CodigoPuestoLaboral
                  : "",
              }}
              marcarNodoActual={true}
              imprimir={imprimir}
              codigoPuestoLaboral={idPuestoPlaza}
              //puestoLaboral={datosDescriptor}
              //cargarBotonDescriptor={false}
            />
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          className={classes.datoContent}
          style={{ backgroundColor: "gainsboro" }}
        >
          <Typography variant="h6" className={classes.titulo}>
            Perfil de Contratación
          </Typography>
        </Grid>
        <Grid item container xs={6} className={classes.datoContent}>
          <Grid item xs={4}>
            <div className={classes.labelContainer}>
              <HorizontalSplitIcon className={classes.icon} />
              <Typography className={classes.datoTxt}>Ubicación: </Typography>
            </div>
          </Grid>
          <Grid item xs={8}>
            <Typography className={classes.label}>
              {datosDescriptor ? datosDescriptor.Ubicacion : "-"}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container xs={6} className={classes.datoContent}>
          <Grid item xs={4}>
            <div className={classes.labelContainer}>
              <HorizontalSplitIcon className={classes.icon} />
              <Typography className={classes.datoTxt}>
                Licencia de Conducir:{" "}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={8}>
            <Typography className={classes.label}>
              {datosDescriptor ? datosDescriptor.LicenciaConducir : "N/A"}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container xs={6} className={classes.datoContent}>
          <Grid item xs={4}>
            <div className={classes.labelContainer}>
              <HorizontalSplitIcon className={classes.icon} />
              <Typography className={classes.datoTxt}>Jornada: </Typography>
            </div>
          </Grid>
          <Grid item xs={8}>
            <Typography className={classes.label}>
              {datosDescriptor ? datosDescriptor.Jornada : "-"}
            </Typography>
          </Grid>
        </Grid>

        <Grid item container xs={6} className={classes.datoContent}>
          <Grid item xs={4}>
            <div className={classes.labelContainer}>
              <HorizontalSplitIcon className={classes.icon} />
              <Typography className={classes.datoTxt}>
                Disponibilidad para Viajar:{" "}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={8}>
            <Typography className={classes.label}>
              {datosDescriptor ? datosDescriptor.DisponibilidadViajar : "No"}
            </Typography>
          </Grid>
        </Grid>

        <Grid item container xs={6} className={classes.datoContent}>
          <Grid item xs={4}>
            <div className={classes.labelContainer}>
              <HorizontalSplitIcon className={classes.icon} />
              <Typography className={classes.datoTxt}>
                Estudios Mínimos para contratación:{" "}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={8}>
            <Typography className={classes.label}>
              {datosDescriptor ? datosDescriptor.EducacionMinima : "No"}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container xs={6} className={classes.datoContent}>
          <Grid item xs={4}>
            <div className={classes.labelContainer}>
              <HorizontalSplitIcon className={classes.icon} />
              <Typography className={classes.datoTxt}>
                Estudios Requeridos:{" "}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={8}>
            <Typography className={classes.label}>
              {datosDescriptor ? datosDescriptor.EducacionRequerida : "No"}
            </Typography>
          </Grid>
        </Grid>

        <Grid item container xs={6} className={classes.datoContent}>
          <Grid item xs={4}>
            <div className={classes.labelContainer}>
              <HorizontalSplitIcon className={classes.icon} />
              <Typography className={classes.datoTxt}>
                Experiencia Mínima:{" "}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={8}>
            <Typography className={classes.label}>
              {datosDescriptor ? datosDescriptor.ExperienciaMinima : "No"}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container xs={6} className={classes.datoContent}>
          <Grid item xs={4}>
            <div className={classes.labelContainer}>
              <HorizontalSplitIcon className={classes.icon} />
              <Typography className={classes.datoTxt}>
                Experiencia Requerida:{" "}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={8}>
            <Typography className={classes.label}>
              {datosDescriptor ? datosDescriptor.ExperienciaRequerida : "No"}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          className={classes.datoContent}
          style={{ backgroundColor: "gainsboro" }}
        >
          <Typography variant="h6" className={classes.titulo}>
            Responsabilidades o Funciones Técnicas
          </Typography>
        </Grid>

        {funcionesTecnicas.map((func, idx) => (
          <React.Fragment>
            <Grid item container xs={12} className={classes.datoContent}>
              {/* <Grid item xs={12}>
              <Divider style={{marginBottom:"10px"}}></Divider>
            </Grid> */}
              <Grid item xs={2}>
                <div className={classes.labelContainer}>
                  {/* <AssignmentTurnedInIcon className={classes.icon} /> */}
                  <Typography className={classes.datoTxt}>
                    {"Función " + (idx + 1) + ":"}{" "}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={10}>
                <Typography className={classes.label}>
                  {func ? func.Responsabilidad : "-"}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.datoContent}>
              <Typography variant="subtitle1" className={classes.titulo}>
                Actividades
              </Typography>
            </Grid>
            <Grid
              item
              container
              xs={12}
              className={classes.datoContent}
              spacing={3}
            >
              {func.Actividades.map((act, idy) => (
                <React.Fragment>
                  <Grid
                    item
                    container
                    xs={6}
                    className={classes.datoContentActividad}
                  >
                    <Grid item xs={2}>
                      <div className={classes.labelContainer}>
                        <AssignmentTurnedInIcon className={classes.icon} />
                        <Typography className={classes.datoTxt}>
                          {idx + 1 + "." + (idy + 1)}{" "}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={10}>
                      <Typography className={classes.label}>
                        {act ? act.Descripcion : "-"}
                      </Typography>
                    </Grid>
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </React.Fragment>
        ))}
        {/* Si no tenemos funciones en el Puesto */}
        {funcionesTecnicas.length === 0 && (
          <Grid item container xs={12} className={classes.datoContent}>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Typography className={classes.label}>
                {" "}
                Sin Funciones Técnicas Asignadas
              </Typography>
            </Grid>
          </Grid>
        )}

        <Grid
          item
          xs={12}
          className={classes.datoContent}
          style={{ backgroundColor: "gainsboro" }}
        >
          <Typography className={classes.label}>
            Nota: *Los riesgos del puesto de trabajo están identificados en la
            matriz de riesgo del área respectiva C-R-GC-806
          </Typography>
        </Grid>
        <Grid item xs={3} className={classes.datoContent}>
          <Typography variant="subtitle1" className={classes.titulo}>
            Elabora
          </Typography>
        </Grid>
        <Grid item xs={3} className={classes.datoContent}>
          <Typography variant="subtitle1" className={classes.titulo}>
            Revisa
          </Typography>
        </Grid>
        {/* <Grid item xs={3} className={classes.datoContent}>
          <Typography variant="h6" className={classes.titulo}>Revisa GDT</Typography>
      </Grid> */}
        <Grid item xs={3} className={classes.datoContent}>
          <Typography variant="subtitle1" className={classes.titulo}>
            Autoriza
          </Typography>
        </Grid>
        <Grid item xs={3} className={classes.datoContent}>
          <Typography variant="subtitle1" className={classes.titulo}>
            Gestión de Talento
          </Typography>
        </Grid>

        <Grid item container style={{ padding: "0px" }}>
          <Grid item xs={3} className={classes.datoContent} align={"center"}>
            <img
              src={
                qr ? (qr[0] ? "data:image/jpeg;base64," + qr[0] : firma) : firma
              }
              className="ImgLogo"
              alt="logo"
            />
          </Grid>
          <Grid item xs={3} className={classes.datoContent} align={"center"}>
            <img
              src={
                qr ? (qr[1] ? "data:image/jpeg;base64," + qr[1] : firma) : firma
              }
              className="ImgLogo"
              alt="logo"
            />
          </Grid>
          <Grid item xs={3} className={classes.datoContent} align={"center"}>
            <img
              src={
                qr ? (qr[2] ? "data:image/jpeg;base64," + qr[2] : firma) : firma
              }
              className="ImgLogo"
              alt="logo"
            />
          </Grid>
          <Grid item xs={3} className={classes.datoContent} align={"center"}>
            <img
              src={
                qr ? (qr[3] ? "data:image/jpeg;base64," + qr[3] : firma) : firma
              }
              className="ImgLogo"
              alt="logo"
            />
          </Grid>

          <Grid item xs={3} className={classes.datoContent} align={"center"}>
            <Typography className={classes.label}>
              {firmas
                ? firmas[0]
                  ? firmas[0].NombreCompletoUsuario
                  : "No Definido"
                : "No Definido"}
            </Typography>
          </Grid>
          <Grid item xs={3} className={classes.datoContent} align={"center"}>
            <Typography className={classes.label}>
              {firmas
                ? firmas[1]
                  ? firmas[1].NombreCompletoUsuario
                  : "No Definido"
                : "No Definido"}
            </Typography>
          </Grid>
          <Grid item xs={3} className={classes.datoContent} align={"center"}>
            <Typography className={classes.label}>
              {firmas
                ? firmas[2]
                  ? firmas[2].NombreCompletoUsuario
                  : "No Definido"
                : "No Definido"}
            </Typography>
          </Grid>
          <Grid item xs={3} className={classes.datoContent} align={"center"}>
            <Typography className={classes.label}>
              {firmas
                ? firmas[3]
                  ? firmas[3].NombreCompletoUsuario
                  : "No Definido"
                : "No Definido"}
            </Typography>
          </Grid>

          <Grid item xs={3} className={classes.datoContent} align={"center"}>
            <Typography className={classes.label}>
              {firmas
                ? firmas[0]
                  ? firmas[0].Usuario
                  : "No Definido"
                : "No Definido"}
            </Typography>
          </Grid>
          <Grid item xs={3} className={classes.datoContent} align={"center"}>
            <Typography className={classes.label}>
              {firmas
                ? firmas[1]
                  ? firmas[1].Usuario
                  : "No Definido"
                : "No Definido"}
            </Typography>
          </Grid>
          <Grid item xs={3} className={classes.datoContent} align={"center"}>
            <Typography className={classes.label}>
              {firmas
                ? firmas[2]
                  ? firmas[2].Usuario
                  : "No Definido"
                : "No Definido"}
            </Typography>
          </Grid>
          <Grid item xs={3} className={classes.datoContent} align={"center"}>
            <Typography className={classes.label}>
              {firmas
                ? firmas[3]
                  ? firmas[3].Usuario
                  : "No Definido"
                : "No Definido"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Descriptor;
