import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

//Own Components
import Layout from "../../layout/containers/Layout";
import API from "../../utils/api";
import Tabla from "../components/permisos-tabla";
import AddButton from "../../helpers/components/add-button-float";
import { isSignedIn } from "../../utils/auth";
import {validaPermisoPaginaActual} from "../../utils/helpers";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%"
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none"
  },
  buttonAdd: {
    margin: theme.spacing.unit
  }
});

class List extends React.Component {
  state = {
    open: false,
    data: [],
    agrupaciones: [],
    Permiso: "",
    Llave: "",
    Descripcion: "",
    CodigoPermisoAgrupacion: 0,
    Icono: "",
    openSelectAgrupacion: false,
    actionType: "" //Crear, Actualizar
  };

  async componentWillMount() {

    //CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
    const llaveAgrupacion = "Menu.Principal.Web";
    const llavePagina = "Menu.Principal.Web.Permisos";
    await validaPermisoPaginaActual(llaveAgrupacion, llavePagina);
    //FIN DE CONSULTA DE PERMISOS

    await this.makeRequest();
  }

  makeRequest = async () => {
    const request = await API.fetchGetRequest("api/desktop/permiso/listado");

    this.setState({
      data: request.data.permisos,
      agrupaciones: request.data.agrupaciones
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
      nombre: "",
      usuario: "",
      email: "",
      autenticacionWindows: "1",
      actionType: ""
    });
  };

  handleOpenAddModal = () => {
    this.setState({
      actionType: "Agregar",
      open: true
    });
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  guardar = async () => {
    const { CodigoPermisoAgrupacion, Permiso, Descripcion, Icono, Llave } = this.state;

    if (
      CodigoPermisoAgrupacion == 0 ||
      Permiso.trim() == "" ||
      Descripcion.trim() == ""
      || Llave.trim() == ""
    ) {
      toast.warn("Llene todos los campos");
      return;
    }

    try {
      let vIsSignedIn = await isSignedIn();

      if (vIsSignedIn.response) {
        let request;
        if (this.state.actionType == "Agregar") {
          const data = {
            CodigoPermisoAgrupacion,
            Permiso,
            Llave,
            Descripcion,
            Icono,
            UsuarioInserto: vIsSignedIn.userName
          };

          request = await API.post(`api/desktop/permiso`, data);
          if (request.status != 200) {
            alert(
              "Hubo un error al guardar el registro, por favor revisa los datos de tu formulario o consulta con un administrador"
            );
          }
        } else {
          //   let data = this.state.editData;
          //   data.Device = this.state.dispositivo;
          //   data.Descripcion = this.state.dispositivoDescripcion;
          //   data.DeviceId = this.state.dispositivoDeviceId;
          //   data.Activo = this.state.dispositivoActivo == "1" ? true : false;
          //   data.UsuarioModifico = vIsSignedIn.userName;
          //   request = await API.updateDispositivo(data);
          //   if (request.statusCode != 200) {
          //     alert("Hubo un error al actualizar tu registro");
          //   }
        }

        if (request.statusCode === 401) {
          //alert("cerrar sesion");
        } else {
          this.props.history.push(`/permiso/${request.data.permiso.id}`);
        }
      }
    } catch (e) {
      alert("hubo un error");
    }
  };

  editItem = async item => {
    await this.setState({
      open: true,
      dispositivo: item.Device,
      dispositivoDescripcion: item.Descripcion,
      dispositivoDeviceId: item.DeviceId,
      dispositivoActivo: item.Activo ? "1" : "0",
      actionType: "Actualizar",
      editData: item
    });
  };

  redirectToDetail = id => {
    this.props.history.push("/permiso/" + id);
  };

  render() {
    const { classes } = this.props;
    let { data } = this.state;

    return (
      <div>
        <Layout titulo="Permisos">
          <Tabla
            data={data}
            editItem={this.editItem}
            redirectToDetail={this.redirectToDetail}
          />
          <AddButton onClick={() => this.handleOpenAddModal()} />

          <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={this.state.open}
            onClose={this.handleClose}
          >
            <div style={getModalStyle()} className={classes.paper}>
              <form className={classes.container} noValidate autoComplete="off">
                <Typography variant="title" gutterBottom>
                  {this.state.actionType == "Agregar" ? (
                    <div>Crear</div>
                  ) : (
                    <div>Editar</div>
                  )}
                </Typography>
                <TextField
                  id="txtLlave"
                  label="Llave"
                  className={classes.textField}
                  value={this.state.Llave}
                  onChange={this.handleChange("Llave")}
                  margin="normal"
                  variant="outlined"
                />
                <TextField
                  id="txtPermiso"
                  label="Permiso"
                  className={classes.textField}
                  value={this.state.Permiso}
                  onChange={this.handleChange("Permiso")}
                  margin="normal"
                  variant="outlined"
                />
                <TextField
                  id="txtDescripcion"
                  label="Descripcion"
                  className={classes.textField}
                  value={this.state.Descripcion}
                  onChange={this.handleChange("Descripcion")}
                  margin="normal"
                  variant="outlined"
                />
                <TextField
                  id="txtIcono"
                  label="Icono"
                  className={classes.textField}
                  value={this.state.Icono}
                  onChange={this.handleChange("Icono")}
                  margin="normal"
                  variant="outlined"
                />
                <br />

                <FormControl className={classes.textField}>
                  <InputLabel htmlFor="agrupacion-simple">
                    Agrupacion
                  </InputLabel>
                  <Select
                    value={this.state.CodigoPermisoAgrupacion}
                    onChange={this.handleChange("CodigoPermisoAgrupacion")}
                    inputProps={{
                      name: "agrupacion",
                      id: "agrupacion-simple"
                    }}
                  >
                    {this.state.agrupaciones.map((a, i) => (
                      <MenuItem key={i} value={a.CodigoPermisoAgrupacion}>
                        {a.PermisoAgrupacion}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <br />
                {this.state.actionType === "Actualizar" ? (
                  <>
                    <h5>Estado</h5>
                    <RadioGroup
                      aria-label="Estado"
                      name="Activo"
                      className={classes.textField}
                      value={this.state.estado}
                      color="primary"
                      onChange={this.handleChange("estado")}
                    >
                      <FormControlLabel
                        value="1"
                        control={<Radio style={{ color: "#2B3C4D" }} />}
                        label="Activo"
                      />
                      <FormControlLabel
                        value="0"
                        control={<Radio style={{ color: "#2B3C4D" }} />}
                        label="Inactivo"
                      />
                    </RadioGroup>
                  </>
                ) : (
                  <></>
                )}

                <Button
                  variant="contained"
                  style={{ backgroundColor: "#69bd4b", color: "white" }}
                  className={classes.buttonAdd}
                  onClick={() => this.guardar()}
                >
                  Guardar
                </Button>
              </form>
            </div>
          </Modal>
          <ToastContainer />
        </Layout>
      </div>
    );
  }
}

// We need an intermediary variable for handling the recursive nesting.
const ListContainer = withStyles(styles)(List);

export default withRouter(ListContainer);
