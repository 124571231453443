import {
  Backdrop,
  CircularProgress,
  makeStyles,
  Paper,
  Tooltip,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import TareasCardPercentaje from "../../gestion/components/tareas-card-porcentaje";

import TareaBox from "../../gestion/components/tarea-box";

import DataGrid, {
  Column,
  Pager,
  Paging,
  Sorting,
  ColumnFixing,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Grouping,
  GroupPanel,
  Export,
  Selection,
  Editing,
  Scrolling,
  LoadPanel,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";

import API from "../../utils/api";
import { isSignedIn, signOut } from "../../utils/auth";
import { green } from "@material-ui/core/colors";
import { ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import Mensaje from "../../helpers/components/message";

const useStyles = makeStyles((theme) => ({
  paperUserModal: {
    position: "absolute",
    width: "75%",
    height: "600px",
    maxHeight: "600px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    outline: "none",
  },
  table: {
    width: "100%",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 210,
  },
  textFieldDireccion: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 230,
  },
  paper: {
    position: "absolute",
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing(1),
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  greenAvatar: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  paperCard: {
    padding: 20,
    width: "100%",
  },
  formControlSelects: {
    // margin: theme.spacing(1),
    // minWidth: 230,

    marginBottom: 10,
    marginTop: 10,
    width: "100%",
    marginLeft: 30,
  },
  //   formControlSelect: {
  //     // margin: theme.spacing(1),
  //     // width: width_proportion,
  //   },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  rootTabla: {
    width: "100%",
    marginTop: theme.spacing(1),
    overflowX: "auto",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperModal: {
    position: "absolute",
    width: "70%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

let applyFilterTypes = [
  {
    key: "auto",
    name: "Immediately",
  },
  {
    key: "onClick",
    name: "On Button Click",
  },
];

const Bitacora = (props) => {
  const classes = useStyles();

  const [positionDisableSorting, setPositionDisableSorting] = useState(false);
  const [showFilterRow, setShowFilterRow] = useState(true);
  const [showHeaderFilter, setShowHeaderFilter] = useState(true);
  const [currentFilter, setCurrentFilter] = useState(applyFilterTypes[0].key);
  const [autoExpandAll, setAutoExpandAll] = useState(true);
  const [userName, setUserName] = useState("");
  const [buscando, setBackDrop] = useState(false);

  const [bitacora, setBitacora] = useState([]);
  const [items, setItems] = useState([]);

  const [codigoGestion, setCodigoGestion] = useState(0);
  const [tareas, setTareas] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [permisoCrearNuevaTarea, setPermisoCrearNuevaTarea] = useState(true);
  const [porcentajeAvance, setPorcentajeAvance] = useState(0);
  const [totalTareas, setTotalTareas] = useState(0);
  const [tareasTerminadas, setTareasTerminadas] = useState(0);
  const [codigoTarea, setCodigoTarea] = useState(null);
  const [codigoTareaSubTareas, setCodigoTareaSubTareas] = useState(null);

  useEffect(() => {
    const cargar = async () => {
      if (props.codigoUnidadOrganizacion !== undefined) {
        cargarBitacora();
      }
    };

    cargar();
  }, [props.codigoUnidadOrganizacion]);

  const cargarBitacora = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/PlazaLaboral/Bitacora/" + props.codigoUnidadOrganizacion;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        setCodigoGestion(request.data.ficha[0].Gestion);
        return false;
      } else {
        setBitacora(request.data.ficha);
        if (request.data.ficha.length > 0) {
          setCodigoGestion(request.data.ficha[0].Gestion);
        }
      }
    } catch (e) {
      console.log(e);
      setCodigoGestion(0);
    } finally {
      handleCerrarBackDrop();
    }
  };

  useEffect(() => {
    const cargar = async () => {
      if (codigoGestion > 0) {
        makeRequestGestion();
      }
    };

    cargar();
  }, [codigoGestion]);

  const makeRequestGestion = async () => {
    try {
      const request = await API.fetchGetRequest(
        `api/aviso/tareas/${codigoGestion}?nivel=1`
      );
      if (request.data.response) {
        if (request.data.data.length > 0) {
          setPermisoCrearNuevaTarea(request.data.data[0].PermiteAgregarTareas);
        }
        let avance = 0;
        request.data.data.forEach(
          (element) => (avance += element.PorcentajeAvance)
        );
        setPorcentajeAvance(Math.round(avance / request.data.data.length));
        setTareas(request.data.data);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  useEffect(() => {
    const totalTareas = tareas.length;
    const tareasTerminadas = tareas.filter(
      (f) => f.CodigoEstadoTarea === 3 || f.CodigoEstadoTarea === 4
    ).length;

    setTotalTareas(totalTareas);
    setTareasTerminadas(tareasTerminadas);
  }, [tareas]);

  const handleCerrarBackDrop = () => {
    setBackDrop(false);
  };
  const handleAbrirBackDrop = () => {
    setBackDrop(true);
  };

  const cellTemplateUnidadOrganizacion = (rowInfo) => {
    return (
      <>
        <Tooltip
          title={`Ir a gestión:`}
          style={{ marginRight: 10, cursor: "pointer" }}
        >
          <Link to={`/gestion/${rowInfo.data.Gestion}`}>
            {rowInfo.data.Gestion}
          </Link>
        </Tooltip>
      </>
    );
  };

  const onClickTarea = (tarea) => {
    setCodigoTarea(tarea.CodigoAvisoTarea);
    window.open("/infotarea/" + tarea.CodigoAvisoTarea, "_blank");
    // window.location.href = "/infotarea/" + props.CodigoTarea;
  };

  const onClickSubTareas = (tarea) => {
    let id = 0,
      nombre = "";
    if (tarea.AvisoTarea == null) {
      id = tarea.CodigoAvisoTarea;
      nombre = tarea.Titulo;
    } else {
      id = tarea.AvisoTarea.CodigoAvisoTarea;
      nombre = tarea.AvisoTarea.Titulo;
    }
    setCodigoTareaSubTareas(id);
    setCodigoTarea(null);
  };

  return (
    <React.Fragment>
      <Backdrop className={classes.backdrop} open={buscando}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer />
      <Paper className={classes.rootTabla}>
        <h2>Ultimo Cambio</h2>
        {codigoGestion > 0 && (
          <div>
            {/* <div class={classes.tituloPaper}>
        <div class={classes.container}>
          <Typography variant="h5" gutterBottom>
            Tareas
          </Typography>
        </div>
      </div> */}
            <TareasCardPercentaje
              percent={
                totalTareas === 0
                  ? 100
                  : Math.round((tareasTerminadas / totalTareas) * 100)
              }
              tareasTerminadas={tareasTerminadas}
              porcentajeAvance={porcentajeAvance}
              totalTareas={totalTareas}
              percentColor={"#69BD4B"}
              permisoCrearTarea={permisoCrearNuevaTarea}
              // onClickCrearTarea={() => setOpenCrearTarea(true)}
            />
            {tareas.length <= 0 && !isLoading && (
              <div>
                <Mensaje>No hay tareas disponibles.</Mensaje>
              </div>
            )}
            {tareas.map((tarea, i) => {
              console.log("tareas datos", tarea);
              var usuarios = "";
              var estilo = "Pendiente";
              //var estilo = "Completo";
              // var icono = "md-checkmark";
              var icono = "md-time";
              // var colorEstado = "#2ECC71";
              var colorEstado = "#F2CB00";

              if (
                tarea.CodigoEstadoTarea == 1 ||
                tarea.CodigoEstadoTarea == 2
              ) {
                estilo = "Pendiente";
                icono = "md-time";
              } else if (tarea.CodigoEstadoTarea == 3) {
                estilo = "Completo";
                icono = "md-checkmark";
                colorEstado = "#2ECC71";
              } else if (tarea.CodigoEstadoTarea == 4) {
                estilo = "Cancelado";
                colorEstado = "#e74c3c";
              }

              if (tarea.Usuarios != null && tarea.Usuarios.length > 0) {
                usuarios = tarea.Usuarios.join(", ");
              }

              const colorBox =
                estilo == "Completo"
                  ? "#69BD4B"
                  : estilo == "Cancelado"
                  ? "#e74c3c"
                  : "#979797";

              return (
                <TareaBox
                  key={i}
                  numero={tarea.Orden}
                  codigo={tarea.CodigoAvisoTarea}
                  onClick={() => onClickTarea(tarea)}
                  onClickSubTareas={() => onClickSubTareas(tarea)}
                  tarea={tarea}
                  estilo={estilo}
                  titulo={tarea.Titulo}
                  nombreAsignado={usuarios}
                  usuarios={tarea.Usuarios}
                  estado={tarea.Estado.Nombre}
                  icono={icono}
                  colorEstado={colorEstado}
                  colorBox={colorBox}
                  tareaSeleccionada={props.tareaSeleccionada}
                  porcentajeAvance={tarea.PorcentajeAvance}
                  colorSemaforo={tarea.colorSemaforo}
                />
              );
            })}
          </div>
        )}
        <h2>Registro de Cambios</h2>
        <DataGrid
          dataSource={bitacora}
          //   defaultColumns={this.props.columns}
          showBorders={true}
          rowAlternationEnabled={true}
          //ref={(ref) => (dataGrid = ref)}
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          className={classes.table}
        >
          <FilterRow visible={showFilterRow} applyFilter={currentFilter} />
          <HeaderFilter visible={showHeaderFilter} />
          <GroupPanel visible={false} />
          <Grouping autoExpandAll={autoExpandAll} />
          <SearchPanel visible={false} width={240} placeholder="Search..." />
          {/* <Selection mode="multiple" /> */}
          <LoadPanel
            enabled={true}
            shadingColor="rgba(0,0,0,0.4)"
            showIndicator={true}
            shading={true}
            showPane={true}
            closeOnOutsideClick={false}
          />
          <Editing
            mode="row"
            useIcons={true}
            allowUpdating={false}
            allowDeleting={false}
          />
          {/* <Column
            caption="Opciones"
            allowFiltering={false}
            allowSorting={false}
            cellRender={cellOpciones}
          /> */}

          <Column
            dataField="CodigoPlazaLaboral"
            caption="Código Plaza Laboral"
            visible={false}
          />
          <Column
            dataField="CodigoTipoRegistroBitacoraPlazaLaboral"
            caption="CodigoTipoRegistroBitacoraPlazaLaboral"
            visible={false}
          />
          <Column
            dataField="FechaInserto"
            caption="Fecha"
            dataType="date"
            format="dd/MM/yyyy"
          />
          <Column dataField="PuestoLaboral" caption="Puesto Laboral" />
          <Column
            dataField="TipoRegistroBitacoraPlazaLaboral"
            caption="Tipo Registro"
          />
          <Column
            dataField="Gestion"
            caption="# Gestion"
            cellRender={cellTemplateUnidadOrganizacion}
          />
          <Column dataField="Comentario" caption="Comentario" />
          {/* <Summary>
            <TotalItem
              column="PuestoLaboral"
              summaryType="count"
              displayFormat="Puestos: {0}"
            />
            <TotalItem
              column="Disponibilidad"
              summaryType="sum"
              displayFormat="Plazas: {0}"
            />
          </Summary> */}
          <ColumnFixing enabled={true} />
          <Sorting mode="multiple" />
          <Paging defaultPageSize={20} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[10, 20, 50]}
            showInfo={true}
          />
          <Export enabled={true} allowExportSelectedData={true} />
          <Scrolling columnRenderingMode="virtual" />
        </DataGrid>
      </Paper>
    </React.Fragment>
  );
};

export default Bitacora;
