import React, { useState, useEffect } from 'react'
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  LinearProgress,
  Dialog,
  Tooltip,
  Backdrop,
  CircularProgress,
  makeStyles
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import {
  Delete,
  FolderOpen,
  Check,
  Visibility,
  Publish,
  Description,
  AssignmentTurnedIn,
  Message,
  FindInPage,
  Autorenew
} from '@material-ui/icons'
import { toast } from 'react-toastify'
import { useStoreState, useStoreActions } from 'easy-peasy'
import dayjs from 'dayjs'

import api from '../../../utils/api'
import SolicitudAprobar from './SolicitudAprobar'
import SolicitudEditar from './SolicitudEditar'
import SolicitudVer from './SolicitudVer'
import SolicitudRevisar from './SolicitudRevisar'
import SolictudAprobacionFinal from './SolicitudAprobacionFinal'
import SolicitudComentarios from './SolicitudComentarios'
import EtapasSolicitud from './EtapasSolicitud'
import { getPDFSolicitudBase64 } from '../../utils/media'
import SolicitudSubirDoc from './SolicitudSubirDoc'
import { getUsuarioInserta, getCodigoUsuario } from '../../utils/utilidades'
import SolicitudSolicitarCambios from './SolicitudSolicitarCambios'
import ObsolescenciaAprobar from '../documentos/ObsolescenciaAprobar'
import EliminarSolicitud from './EliminarSolicitud'
import ViewPDF from '../documentos/ViewPDF'
import { grey } from 'material-ui/colors'

const heads = [
  'No.',
  'Código de documento',
  'Edición del documento',
  'Título',
  'Fases de aprobación',
  'Fecha',
  'Autor',
  'Opciones'
]
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  btnContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr'
  },
  rowSelected: {
    background: grey[300]
  }
}))

const SolicitudesUsuario = ({ tipo='usuario', loading }) => {
  const classes = useStyles()
  const {
    solicitudes,
    asignadas,
    usuarios,
    historico,
    permisosGesDoc,
    todas
  } = useStoreState(state => ({
    solicitudes: state.solicitudes,
    asignadas: state.asignadas,
    usuarios: state.usuarios,
    historico: state.historico,
    permisosGesDoc: state.permisosGesDoc,
    todas: state.todas
  }))
  const {
    getSolicitudes,
    getHistorico,
    getDocumentos,
    getDocsOficiales
  } = useStoreActions(actions => ({
    getSolicitudes: actions.getSolicitudes,
    getDocsOficiales: actions.getDocsOficiales,
    getHistorico: actions.getHistorico,
    getDocumentos: actions.getDocumentos
  }))
  const [items, setItems] = useState([])
  const [publicando, setPublicando] = useState(false)
  const [selected, setSelected] = useState(null)
  const [rowSelected, setRowSelected] = useState(null)
  const [PDF, setPDF] = useState('')
  const [dialogAprobar, setDialogAprobar] = useState(false)
  const [dialogCrear, setDialogCrear] = useState(false)
  const [dialogVer, setDialogVer] = useState(false)
  const [dialogSubirDoc, setDialogSubirDoc] = useState(false)
  const [dialogRevision, setDialogRevision] = useState(false)
  const [dialogDoc, setDialogDoc] = useState(false)
  const [dialogComentarios, setDialogComentarios] = useState(false)
  const [dialogPublicar, setDialogPublicar] = useState(false)
  const [dialogCambios, setDialogCambios] = useState(false)
  const [dialogCambiosCargar, setDialogCambiosCargar] = useState(false)
  const [dialogAprobacionFinal, setDialogAprobacionFinal] = useState(false)
  const [dialogObsolescencia, setDialogObsolescencia] = useState(false)
  const [dialogEliminar, setDialogEliminar] = useState(false)

  const getPDF = async (idSolicitud, previsualizar=false) => {
    try {
      setPDF(null)
      let base64str = await getPDFSolicitudBase64(idSolicitud, previsualizar)
      if (base64str) {
        base64str = base64str.replace(
          "data:application/octet-stream;base64,",
          ""
        )
        setPDF(base64str)
      }
    } catch (error) {
      toast.error('Ocurrio un error al obtener el documento 😅')
    }
  }
  const eliminarSolicitud = async (idSolicitd) => {
    if (window.confirm('¿Estas seguro de eliminar la solicitud?')) {
      try {
        await api.post(`api/documentos/solicitud/eliminar/${idSolicitd}`, {
          UsuarioModifico: getUsuarioInserta()
        })
        getSolicitudes('usuario')
        getHistorico()
      } catch (error) {
        toast.error('Ha ocurrido un error')
      }
    }
  }
  const aprobarItem = (id, index) => {
    const item = items.find(i=> i.IdSolicitud ===  id)
    setSelected({...item})
    setDialogAprobar(true)
    setRowSelected(index)
  }
  const verItem = (id, index) => {
    const item = items.find(i=> i.IdSolicitud ===  id)
    setSelected({...item})
    setDialogVer(true)
    setRowSelected(index)
  }
  const verDoc = async (id, index) => {
    try {
      setDialogDoc(true)
      const item = items.find(i=> i.IdSolicitud ===  id)
      await getPDF(item.IdSolicitud)
      setRowSelected(index)
    } catch (error) {
      toast.error('No se pudo cargar el documento')
    }
  }
  const previsualizarDoc = async (id, index) => {
    try {
      setDialogDoc(true)
      const item = items.find(i=> i.IdSolicitud ===  id)
      await getPDF(item.IdSolicitud, true)
      setRowSelected(index)
    } catch (error) {
      toast.error('No se pudo cargar el documento')
    }
  }
  const hidePDF = () => {
    setPDF(null)
    setDialogDoc(false)
  }
  const publicarDocumento = async (id) => {
    if (window.confirm('¿Estas seguro de publicar el documento?')) {
      try {
        setPublicando(true)
        const datos = {
          CodigoUsuario: getCodigoUsuario(),
          IdSolicitud: id,
        }
        await api.realizarPeticionPostPut('api/documentos/solicitud/enviar-documento-a-directorio', datos, 'POST')
        getSolicitudes('usuario')
        getSolicitudes('todas')
        getDocsOficiales()
        getHistorico()
        getDocumentos()
        setRowSelected(null)
        toast.success('Documento publicado')
      } catch (error) {
        toast.error('Ha ocurrido un error')
      } finally {
        setPublicando(false)
      }
    }
  }
  const showSubirDoc = (id, index) => {
    const item = items.find(i=> i.IdSolicitud ===  id)
    setSelected({...item})
    setRowSelected(index)
    setDialogSubirDoc(true)
  }
  const showRevision = (id, index) => {
    const item = items.find(i=> i.IdSolicitud ===  id)
    setSelected({...item})
    setDialogRevision(true)
    setRowSelected(index)
  }
  const showSolicitarCambios = (id, tipo='publicacion', index) => {
    const item = items.find(i=> i.IdSolicitud ===  id)
    setSelected({...item})
    setRowSelected(index)
    if (tipo === 'publicacion') {
      setDialogCambios(true)
    } else {
      setDialogCambiosCargar(true)
    }
  }
  const showAprobacionFinal = (id, index) => {
    const item = items.find(i=> i.IdSolicitud ===  id)
    setSelected({...item})
    setDialogAprobacionFinal(true)
    setRowSelected(index)
  }
  const showAprobacionObsolescencia = (id, index) => {
    const item = items.find(i=> i.IdSolicitud ===  id)
    setSelected({...item})
    setDialogObsolescencia(true)
    setRowSelected(index)
  }
  const showComentarios = (id, index) => {
    const item = items.find(i=> i.IdSolicitud ===  id)
    setSelected({...item})
    setDialogComentarios(true)
    setRowSelected(index)
  }
  const showEliminar = (id) => {
    const item = items.find(i=> i.IdSolicitud ===  id)
    setSelected({...item})
    setDialogEliminar(true)
  }
  const showPublicar = (id) => {
    const item = items.find(i=> i.IdSolicitud ===  id)
    publicarDocumento(item.IdSolicitud)
  }
  useEffect(() => {
    if (tipo === 'usuario') {
      setItems(solicitudes)
    } else if (tipo === 'asignadas') {
      setItems(asignadas)
      setRowSelected(null)
    } else {
      setItems(todas)
    }
  }, [solicitudes, asignadas, todas])
  return<>
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {
              heads.map(j => <TableCell key={j}>{j}</TableCell>)
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {
            items.map((j, idx2) => <TableRow key={idx2} className={rowSelected == idx2 && classes.rowSelected}>
              <TableCell>
                {idx2 + 1}
              </TableCell>
              <TableCell>
                {j.codigoDoc}
              </TableCell>
              <TableCell>
                {j.Version}
              </TableCell>
              <TableCell>
                {j.NombreDocumento}
              </TableCell>
              <TableCell>
                <EtapasSolicitud
                  historico={historico}
                  ordenEstado={j.OrdenEstado}
                  solicitudData={j}
                />
              </TableCell>
              <TableCell>
                {dayjs(j.FechaSolicitud).format('DD/MM/YYYY')}
              </TableCell>
              <TableCell>
                {j.Solicitante}
              </TableCell>
              <TableCell>
                <div className={classes.btnContainer}>
                {
                  (tipo === 'usuario' && permisosGesDoc.includes('GESDOC.EliminarBorrador'))
                  && <Tooltip title="Eliminar solicitud">
                    <IconButton onClick={() => showEliminar(j.IdSolicitud)}>
                      <Delete />
                    </IconButton>
                  </Tooltip>
                }
                <Tooltip title="Ver detalles">
                  <IconButton onClick={() => verItem(j.IdSolicitud, idx2)}>
                    <Visibility />
                  </IconButton>
                </Tooltip>
                {/* {
                  (j.IdEstado === 1 && getCodigoUsuario() === j.CodigoAutorizador) && <Tooltip title="Revisar solicitud">
                    <IconButton onClick={() => aprobarItem(j.IdSolicitud)}>
                      <Check />
                    </IconButton>
                  </Tooltip>
                } */}
                {
                  ( [1, 13].includes(j.OrdenEstado) && tipo === 'asignadas' && permisosGesDoc.includes('GESDOC.AsignarCorrelativo'))
                  && <Tooltip title="Asignar correlativo y aprobación de borrador">
                    <IconButton onClick={() => aprobarItem(j.IdSolicitud, idx2)}>
                      <Check />
                    </IconButton>
                  </Tooltip>
                }
                {
                  (j.OrdenEstado === 3 && tipo === 'usuario') && <Tooltip title="Solicitar cambios">
                    <IconButton onClick={() => showSolicitarCambios(j.IdSolicitud, 'cargar', idx2)}>
                      <Autorenew />
                    </IconButton>
                  </Tooltip>
                }
                {
                  ([3,5,7,10].includes(j.OrdenEstado) && tipo === 'usuario') && <Tooltip title="Subir documento">
                    <IconButton onClick={() => showSubirDoc(j.IdSolicitud, idx2)}>
                      <Publish />
                    </IconButton>
                  </Tooltip>
                }
                {
                  j.OrdenEstado >= 4 && <Tooltip title="Ver documento">
                    <IconButton onClick={() => verDoc(j.IdSolicitud, idx2)}>
                      <Description />
                    </IconButton>
                  </Tooltip>
                }
                {
                  (j.OrdenEstado === 4 && tipo === 'asignadas' && permisosGesDoc.includes('GESDOC.RevisarBorrador'))
                  && <Tooltip title="Revisar documento">
                    <IconButton onClick={() => showRevision(j.IdSolicitud, idx2)}>
                      <AssignmentTurnedIn />
                    </IconButton>
                  </Tooltip>
                }
                <Tooltip title="Ver observaciones de documento">
                  <IconButton onClick={() => showComentarios(j.IdSolicitud, idx2)}>
                    <Message />
                  </IconButton>
                </Tooltip>
                {
                  j.OrdenEstado >= 4  && <Tooltip title="Previsualizar documento firmado">
                    <IconButton onClick={() => previsualizarDoc(j.IdSolicitud)}>
                      <FindInPage />
                    </IconButton>
                  </Tooltip>
                }
                {
                  (j.OrdenEstado === 9 && tipo === 'todas' && permisosGesDoc.includes('GESDOC.PublicarDocumento'))
                  && <Tooltip title="Publicar documento en directorio">
                    <IconButton onClick={() => showPublicar(j.IdSolicitud)}>
                      <FolderOpen />
                    </IconButton>
                  </Tooltip>
                }
                {
                  (j.OrdenEstado === 9 && tipo === 'todas' && permisosGesDoc.includes('GESDOC.CambiosPublicacion'))
                  && <Tooltip title="Solicitar cambios">
                    <IconButton onClick={() => showSolicitarCambios(j.IdSolicitud, idx2)}>
                      <Autorenew />
                    </IconButton>
                  </Tooltip>
                }
                {
                  (j.OrdenEstado === 6 && tipo === 'asignadas' && permisosGesDoc.includes('GESDOC.AprobarBorrador'))
                  && <Tooltip title="Aprobar borrador">
                    <IconButton onClick={() => showAprobacionFinal(j.IdSolicitud, idx2)}>
                      <AssignmentTurnedIn />
                    </IconButton>
                  </Tooltip>
                }
                {
                  (j.EsObsoleto && permisosGesDoc.includes('GESDOC.AprobarObsolescencia'))
                  && <Tooltip title="Aprobar obsolescencia">
                    <IconButton onClick={() => showAprobacionObsolescencia(j.IdSolicitud, idx2)}>
                      <Check />
                    </IconButton>
                  </Tooltip>
                }
                </div>
              </TableCell>
            </TableRow>)
          }
        </TableBody>
      </Table>
      {
        loading && <LinearProgress />
      }
      {
        (!loading && !items.length) && <Alert variant="filled" severity="info">
          No hay solicitudes
        </Alert>
      }
    </TableContainer>
    {/* --------------------------------- Dialogs -------------------------------- */}
    {/* <Backdrop className={classes.backdrop} open={loadingDoc}>
      <CircularProgress color="secondary" size={80} />
    </Backdrop> */}
    <Backdrop className={classes.backdrop} open={publicando}>
      <CircularProgress color="primary" size={80} />
    </Backdrop>

    <Dialog open={dialogVer} onClose={() => setDialogVer(false)}>
      <SolicitudVer datos={selected} onClose={() => setDialogVer(false)} />
    </Dialog>
    <Dialog open={dialogAprobar} onClose={() => setDialogAprobar(false)}>
      <SolicitudAprobar
        data={selected}
        usuarios={usuarios}
        onClose={() => setDialogAprobar(false)}
        onRefresh={() => {
          getSolicitudes('usuario')
          getSolicitudes('asignadas')
          getHistorico()
          setDialogAprobar(false)
        }}
      />
    </Dialog>
    <Dialog fullWidth maxWidth="md" open={dialogSubirDoc} onClose={() => setDialogSubirDoc(false)}>
      <SolicitudSubirDoc
        data={selected}
        onClose={() => setDialogSubirDoc(false)}
        onRefresh={() => {
          getSolicitudes('usuario')
          getSolicitudes('asignadas')
          getHistorico()
          setDialogSubirDoc(false)
        }}
      />
    </Dialog>
    <Dialog open={dialogCrear}>
      <SolicitudEditar
        title="Crear solicitud de documento"
        usuarios={usuarios}
        onClose={() => setDialogCrear(false)}
        onRefresh={() => {
          getSolicitudes('usuario')
          getHistorico()
          setDialogCrear(false)
        }}
      />
    </Dialog>
    <Dialog open={dialogDoc} onClose={hidePDF} fullScreen>
      <ViewPDF docBase64={PDF} onClose={hidePDF} />
    </Dialog>
    <Dialog open={dialogRevision} onClose={() => setDialogRevision(false)}>
      <SolicitudRevisar
        datos={selected}
        onClose={() => setDialogRevision(false)}
        onRefresh={() => {
          setDialogRevision(false)
          getSolicitudes('usuario')
          getSolicitudes('asignadas')
          getHistorico()
        }}
      />
    </Dialog>
    <Dialog open={dialogAprobacionFinal} onClose={() => setDialogAprobacionFinal(false)}>
      <SolictudAprobacionFinal
        datos={selected}
        onClose={() => setDialogAprobacionFinal(false)}
        onRefresh={() => {
          setDialogAprobacionFinal(false)
          getSolicitudes('usuario')
          getSolicitudes('asignadas')
          getHistorico()
        }}
      />
    </Dialog>
    <Dialog open={dialogCambios} onClose={() => setDialogCambios(false)}>
      <SolicitudSolicitarCambios
        datos={selected}
        onClose={() => setDialogCambios(false)}
        onRefresh={() => {
          setDialogCambios(false)
          getSolicitudes('usuario')
          getSolicitudes('asignadas')
          getHistorico()
        }}
      />
    </Dialog>
    <Dialog open={dialogCambiosCargar} onClose={() => setDialogCambiosCargar(false)}>
      <SolicitudSolicitarCambios
        datos={selected}
        onClose={() => setDialogCambiosCargar(false)}
        onRefresh={() => {
          setDialogCambiosCargar(false)
          getSolicitudes('usuario')
          getSolicitudes('asignadas')
          getHistorico()
        }}
        estado="cambios cargar"
      />
    </Dialog>
    <Dialog
      fullWidth
      open={dialogComentarios}
      onClose={() => setDialogComentarios(false)}
      maxWidth="md"
    >
      <SolicitudComentarios
        datos={selected}
        onClose={() => setDialogComentarios(false)}
      />
    </Dialog>
    <Dialog
      fullWidth
      open={dialogPublicar}
      onClose={() => setDialogPublicar(false)}
      maxWidth="md"
    >
      {/* <SolicitudComentarios
        datos={selected}
        onClose={() => setDialogComentarios(false)}
      /> */}
    </Dialog>
    <Dialog
      fullWidth
      open={dialogObsolescencia}
      onClose={() => setDialogObsolescencia(false)}
      maxWidth="sm"
    >
      <ObsolescenciaAprobar
        solicitud={selected}
        onRefresh={() => {
          getSolicitudes('usuario')
          getSolicitudes('asignadas')
          getDocumentos()
          setDialogObsolescencia(false)
        }}
        onClose={() => setDialogObsolescencia(false)}
      />
    </Dialog>
    <Dialog
      fullWidth
      maxWidth="sm"
      open={dialogEliminar}
      onClose={() =>  setDialogEliminar(false)}
    >
      <EliminarSolicitud
        solicitud={selected}
        onRefresh={() => {
          getSolicitudes('usuario')
          getSolicitudes('asignadas')
          getDocumentos()
          getHistorico()
          setDialogEliminar(false)
        }}
        onClose={() =>  setDialogEliminar(false)}
      />
    </Dialog>
  </>
}

export default SolicitudesUsuario
