import React from 'react'
import { Button, Typography } from '@material-ui/core'
import {
  Timeline,
  TimelineItem,
  TimelineContent,
  TimelineSeparator,
  TimelineConnector,
  TimelineDot
} from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles/'

const useStyles = makeStyles(theme => ({
  itemContent: {
    padding: '6px 16px',
    minWidth: '10rem',
    textAlign: 'center',
    fontWeight: 'bold'
  },
  box: {
    padding: '1rem'
  }
}))

const PublicacionesTimeline = ({ items, onSelect, selected }) => {
  const classes = useStyles()
  return (
    <Timeline>
      {
        items.map((i, idx) => <TimelineItem key={i.CodigoNotificacion}>
          <TimelineSeparator>
            <TimelineDot color={selected ? i.CodigoNotificacion == selected.CodigoNotificacion ? "secondary" : "grey" : "grey"} />
            { items.length !== idx + 1 && <TimelineConnector /> }
          </TimelineSeparator>
          <TimelineContent className={classes.itemContent}>
            <Typography variant="body1" component="p" color={selected ? i.CodigoNotificacion == selected.CodigoNotificacion ? "secondary" : "initial" : "initial"}>
              { i.Titulo }
            </Typography>
            <Button color="primary" onClick={() => onSelect(i.CodigoNotificacion)}>Leer más</Button>
          </TimelineContent>
        </TimelineItem>)
      }
    </Timeline>
  )
}

export default PublicacionesTimeline