import React, { useState, useEffect } from "react";
import StarRateIcon from "@material-ui/icons/StarRate";
import ErrorIcon from "@material-ui/icons/Error";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import SentimentSatisfiedIcon from "@material-ui/icons/SentimentSatisfied";
import SentimentVerySatisfiedIcon from "@material-ui/icons/SentimentVerySatisfied";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  tituloPaper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  gestionGeneral: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  paperUserModal: {
    position: "absolute",
    width: "75%",
    height: "600px",
    maxHeight: "600px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
  chip: {
    marginLeft: 10,
  },
  container: {
    margin: 10,
  },
  input: {
    marginTop: 15,
  },
  inputTitulo: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  iconColor: {
    color: "#2196F3",
  },
  classChips: {
    textAlign: "left",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
}));

export default function CalificacionTareaCard(props) {
  const [calificacion, setCalificacion] = useState(0);
  const [comentario, setComentario] = useState("");

  const classes = useStyles();

  useEffect(() => {
    setCalificacion(props.calificacion);
    setComentario(props.comentario);
  }, [props.calificacion, props.comentario]);

  return (
    <div>
      <div class={classes.tituloPaper}>
        <div class={classes.gestionGeneral}>
          <Typography variant="h5" gutterBottom>
            Encuesta de satisfacción
          </Typography>
        </div>
      </div>
      <Paper className={classes.paper}>
        <div className={classes.container}>
          <div
            style={{
              display: "fl  ex",
              alignContent: "flex-start",
              alignItems: "flex-start",
              fontWeight: "bold",
            }}
          >
            {props.icono === "sad" ? (
              <SentimentVeryDissatisfiedIcon
                style={{ color: props.colorIcono }}
              />
            ) : props.icono === "satisfied" ? (
              <SentimentSatisfiedIcon style={{ color: props.colorIcono }} />
            ) : (
              <SentimentVerySatisfiedIcon style={{ color: props.colorIcono }} />
            )}

            <span style={{ color: "#3498db", marginLeft: 5 }}>
              Calificación &nbsp;
            </span>
            <span
              style={{ color: props.colorTexto }}
            >{`${props.calificacionDecorada}`}</span>
          </div>
          <div style={{ marginTop: 10, fontStyle: "italic" }}>
            {comentario === null || comentario === ""
              ? ""
              : "“" + comentario + "„"}
          </div>
        </div>
      </Paper>
    </div>
  );
}
