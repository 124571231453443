import React, { useEffect } from 'react'
import {
  DialogContent,
  DialogActions,
  Button,
  Box,
  CircularProgress
} from '@material-ui/core'
import { red } from '@material-ui/core/colors'

const PDFView = ({ PDFbase64, onClose }) => {
  return <>
    <DialogContent style={{ height: "80vh" }}>
      { !PDFbase64 && <Box display="flex" justifyContent="center"><CircularProgress /></Box> }
      { PDFbase64 && <iframe src={PDFbase64} width="100%" height="100%" /> }
    </DialogContent>
    <DialogActions>
      <Button
        variant="contained"
        style={{background: red[500], color: '#FFF'}}
        onClick={onClose}
      >
        Cerrar
      </Button>
    </DialogActions>
  </>
}

export default PDFView
