import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";

import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Checkbox,
  FormControlLabel,
  Divider,
  Paper,
  Tooltip,
  Modal,
  Fade,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Tabs,
  Box,
  TabScrollButton,
  Tab,
} from "@material-ui/core";

import BackdropModal from "@material-ui/core/Backdrop";

import clsx from "clsx";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import {
  AssignmentTurnedIn,
  CancelOutlined,
  Pageview,
} from "@material-ui/icons";

import { green } from "@material-ui/core/colors";

import DataGrid, {
  Column,
  Pager,
  Paging,
  Sorting,
  ColumnFixing,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Grouping,
  GroupPanel,
  Export,
  Selection,
  Editing,
  Scrolling,
  RowDragging,
  LoadPanel,
} from "devextreme-react/data-grid";

import {
  PlayCircleOutline,
  Edit,
  Delete,
  Add,
  TransferWithinAStation,
  Traffic,
  AssignmentInd,
} from "@material-ui/icons";

import PropTypes from "prop-types";

import { ToastContainer, toast } from "react-toastify";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import "devextreme/dist/css/dx.material.teal.light.compact.css";

import AnalisisSalarial from "../components/analisis-salarial-componente";
import AnalisisSalarialSolicitudes from "../components/analisis-salarial-gestiones-componente";
import AnalisisSalarialHistorico from "../components/analisis-salarial-historico-componente";
import AnalisisSalarialConfiguracion from "../components/analisis-salarial-configuracion-componente";

const width_proportion = "100%";
const titleColor = "#2B3C4D";
let datagridEmpleadosSCILU;

const a11yProps = (index) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={"span"} variant={"body2"}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const MyTabScrollButton = withStyles((theme) => ({
  root: {
    width: 28,
    overflow: "hidden",
    transition: "width 0.5s",
    "&.Mui-disabled": {
      width: 0,
    },
  },
}))(TabScrollButton);

const useStyles = makeStyles((theme) => ({
  table: {
    width: "100%",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 210,
  },
  textFieldDireccion: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 230,
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  greenAvatar: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  paperCard: {
    padding: 20,
    width: "100%",
  },
  formControlSelects: {
    // margin: theme.spacing(1),
    minWidth: 480,

    marginBottom: 10,
    marginTop: 10,
    width: "100%",
    marginLeft: 30,
  },
  formControlSelect: {
    // margin: theme.spacing(1),
    width: width_proportion,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  rootTabla: {
    width: "100%",
    marginTop: theme.spacing.unit * 1,
    overflowX: "auto",
  },
  // modal: {
  //   display: "flex",
  //   alignItems: "center",
  //   justifyContent: "center",
  // },
  // paperModal: {
  //   position: "absolute",
  //   width: "70%",
  //   backgroundColor: theme.palette.background.paper,
  //   boxShadow: theme.shadows[5],
  //   padding: theme.spacing(2, 4, 3),
  // },
  modal: {
    // display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: "10%",
    left: "20%",
    overflow: "scroll",
    height: "90%",
    display: "block",
    padding: theme.spacing(8, 4, 8, 8),
  },
  paperModal: {
    position: "absolute",
    width: "90%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1, 1, 1, 1),
  },
  selectedData: {
    marginTop: "20px",
    padding: "20px",
    backgroundColor: "rgba(191, 191, 191, 0.15)",
  },
  selectedDataCaption: {
    fontWeight: "bold",
    fontSize: "115%",
    marginRight: "4px",
  },
}));

const ContenedorAnalisisSalarial = (props) => {
  const classes = useStyles();

  const [tab, setTab] = useState(0);

  return (
    <React.Fragment>
      <AppBar
        position="sticky"
        className={classes.appBar}
        style={{ backgroundColor: titleColor }}
      >
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            style={{ display: "flex", flexGrow: 1 }}
          >
            {`Análisis Salarial`}
          </Typography>
        </Toolbar>
      </AppBar>
      <Paper className={classes.rootTabla}>
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={(_, val) => setTab(val)}
          variant="scrollable"
          scrollButtons="on"
          aria-label="scrollable force tabs example"
          ScrollButtonComponent={MyTabScrollButton}
        >
          <Tab
            // icon={<GroupWork />}
            label="Analisis Salarial"
            {...a11yProps(0)}
            style={{ textTransform: "none" }}
            value={0}
          />
          <Tab
            // icon={<Work />}
            label="Solicitudes Activas"
            {...a11yProps(1)}
            style={{ textTransform: "none" }}
            value={1}
            // disabled={!permisoPlazaLaboral}
          />
          <Tab
            // icon={<GroupAdd />}
            label="Solicitudes Anteriores"
            {...a11yProps(2)}
            style={{ textTransform: "none" }}
            value={2}
            // disabled={!permisoPersonal}
          />
          <Tab
            // icon={<GroupAdd />}
            label="Configuración"
            {...a11yProps(3)}
            style={{ textTransform: "none" }}
            value={3}
            // disabled={!permisoPersonal}
          />
        </Tabs>
        <TabPanel id="organigrama" value={tab} index={0}>
          <Box className={classes.box} pl={0} pt={0}>
            <AnalisisSalarial
              codigoUnidadOrganizacion={props.codigoUnidadOrganizacion}
            />
          </Box>
        </TabPanel>
        <TabPanel id="plazas" value={tab} index={1}>
          <Box className={classes.box} pl={0} pt={0}>
            <AnalisisSalarialSolicitudes
              codigoUnidadOrganizacion={props.codigoUnidadOrganizacion}
            />
          </Box>
        </TabPanel>
        <TabPanel id="personal" value={tab} index={2}>
          <Box className={classes.box} pl={0} pt={0}>
            <AnalisisSalarialHistorico
              codigoUnidadOrganizacion={props.codigoUnidadOrganizacion}
            />
          </Box>
        </TabPanel>
        <TabPanel id="configuracion" value={tab} index={3}>
          <Box className={classes.box} pl={0} pt={0}>
            <AnalisisSalarialConfiguracion
              codigoUnidadOrganizacion={props.codigoUnidadOrganizacion}
            />
          </Box>
        </TabPanel>
      </Paper>
    </React.Fragment>
  );
};

export default ContenedorAnalisisSalarial;
