import {
  Backdrop, Button, CircularProgress, Grid
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { toast, ToastContainer } from "react-toastify";
import API from "../../utils/api";
import { isSignedIn } from "../../utils/auth";

import CommuteIcon from '@material-ui/icons/Commute';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import PublicIcon from '@material-ui/icons/Public';
import SaveIcon from '@material-ui/icons/Save';
import SettingsBrightnessIcon from '@material-ui/icons/SettingsBrightness';
import WorkIcon from '@material-ui/icons/Work';

import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from "@material-ui/core/TextField";


const width_proportion = "100%";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 210,
  },
  textFieldDireccion: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginTop: theme.spacing.unit * 4,
    minWidth: 800,
    margin: theme.spacing(1),
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  greenAvatar: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  paperCard: {
    padding: 20,
    width: "100%",
  },
  formControlSelects: {
    margin: theme.spacing(1),
    minWidth: 230,
  },
  formControlSelect: {
    // margin: theme.spacing(1),
    width: width_proportion,
  },
  formControl: {
    width: '95%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  datoContent: {
    alignItems: 'center',
    "border-style": "groove",
    "border-width": "thin",
    margin: "0px"
  },
  labelContainer: {
    display: 'flex',
    flex: 0.40,
    alignItems: 'center',
    fontWeight: '600',
    fontSize: '1rem',
  },
  datoContainer: {
    marginTop: '0.30rem',
    width: '1400px',
    margin: 'auto'
  },
  datoTxt: {
    flex: 0.60,
    fontSize: '1rem',
    fontWeight: '600',
    color: '#000000a6'
  },
  icon: {
    fontSize: '1.25rem',
    color: '#147085',
    marginRight: '0.5rem',
  },
  titulo: {
    "text-align": "center",
    fontWeight: "700",
  },
  datoContentActividad: {
    alignItems: 'center',
    //"border-style": "groove",
    //"border-width": "thin"
  },
  button: {
    textAlign: "right",
    marginBottom: "10px",
  }
}));

const PerfilContratacion = (props) => {

  const [userName, setUserName] = useState("");
  const [buscando, setBackDrop] = useState(false);
  const [CodigoLicencia, setCodigoLicencia] = useState(0);
  const [CodigoJornada, setCodigoJornada] = useState(0);
  const [CodigoUbicacion, setCodigoUbicacion] = useState(0);
  const [DisponibilidadViajar, setDisponibilidadViajar] = useState(false);
  const [ExperienciaMinimaTxt, setExperienciaMinimaTxt] = useState("");
  const [ExperienciaRequeridaTxt, setExperienciaRequeridaTxt] = useState("");
  const [CatalogoUbicaciones, setCatalogoUbicaciones] = useState([]);
  const [CatalogoJornadas, setCatalogoJornadas] = useState([]);
  const [CatalogoLicencias, setCatalogoLicencias] = useState([]);

  const handleCerrarBackDrop = () => {
    setBackDrop(false);
  };
  const handleAbrirBackDrop = () => {
    setBackDrop(true);
  };

  const classes = useStyles();

  useEffect(() => {
    const cargar = async () => {
      handleAbrirBackDrop();
      await cargarDatosContratacion(props.puestoLaboral.CodigoPuestoLaboral);
      handleCerrarBackDrop();
    };

    cargar();
  }, [props.puestoLaboral]);

  const cargarDatosContratacion = async (idPuesto) => {
    let session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    }
    try {
      handleAbrirBackDrop();
      const request = await API.fetchGetRequest(`api/PuestoLaboral/PerfilContratacionAdmin/${idPuesto}`);

      if (request.data.data == null) {
        return;
      }
      console.log(request);
      setUserName(session.userName);
      setCodigoJornada(request.data.data.ficha.CodigoJornadaLaboral);
      setCodigoLicencia(request.data.data.ficha.CodigoLicenciaConducir);
      setCodigoUbicacion(request.data.data.ficha.CodigoUbicacionLaboral);
      setExperienciaMinimaTxt(request.data.data.ficha.ExperienciaMinimaTxt);
      setExperienciaRequeridaTxt(request.data.data.ficha.ExperienciaRequeridaTxt);
      setDisponibilidadViajar(request.data.data.ficha.DisponibilidadViajar);
      setCatalogoUbicaciones(request.data.data.catalogoUbicaciones);
      setCatalogoJornadas(request.data.data.catalogoJornadas);
      setCatalogoLicencias(request.data.data.catalogoLicencias);

      //setDatosDescriptor(request.data.data.Encabezados);
      //setFuncionesNegocio(request.data.data.DatosNegocio);
      //setFuncionesTecnicas(request.data.data.DatosTecnicos);
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const postData = async (data) => {
    let session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    }
    try {
      handleAbrirBackDrop();
      const request = await API.post(
        "api/PuestoLaboral/PerfilContratacionAdmin/guardar",
        data
      );
      if (request.status !== 200) {
        toast.error(
          "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
        );
      } else {
        toast.success("Se guardó exitosamente");
      }
      console.log(request);
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };


  return (
    <React.Fragment>
      <Backdrop className={classes.backdrop} open={buscando}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer />

      <div className={classes.button}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<SaveIcon />}
          onClick={() => {
            postData({
              CodigoJornadaLaboral: CodigoJornada,
              CodigoLicenciaConducir: CodigoLicencia,
              CodigoUbicacionLaboral: CodigoUbicacion,
              DisponibilidadViajar: DisponibilidadViajar,
              ExperienciaMinimaTxt: ExperienciaMinimaTxt,
              ExperienciaRequeridaTxt: ExperienciaRequeridaTxt,
              UsuarioInserto: userName,
              CodigoPuestoLaboral: props.puestoLaboral.CodigoPuestoLaboral
            });
          }}
        >Guardar</Button>
      </div>
      <Grid container spacing={3} style={{ marginTop: "auto" }}>
        <Grid item md={12} className={classes.datoContent} style={{ backgroundColor: "gainsboro" }}>
          {/* <Typography variant="h6" className={classes.titulo}>Experiencia</Typography> */}
        </Grid>

        <Grid item container lg={6} className={classes.datoContent}>
          <Grid item md={4}>
            <div className={classes.labelContainer}>
              <WorkIcon className={classes.icon} />
              <Typography className={classes.datoTxt}>Experiencia Mínima:</Typography>
            </div>
          </Grid>
          <Grid item md={8}>
            <TextField
              className={classes.formControl}
              id="ExperienciaMinimaTxt"
              name="ExperienciaMinimaTxt"
              value={ExperienciaMinimaTxt}
              onChange={(e) => setExperienciaMinimaTxt(e.target.value)}
            ></TextField>
          </Grid>
        </Grid>

        <Grid item container lg={6} className={classes.datoContent}>
          <Grid item md={4}>
            <div className={classes.labelContainer}>
              <WorkIcon className={classes.icon} />
              <Typography className={classes.datoTxt}>Experiencia Requerida:</Typography>
            </div>
          </Grid>
          <Grid item md={8}>
            <TextField
              className={classes.formControl}
              id="ExperienciaRequeridaTxt"
              name="ExperienciaRequeridaTxt"
              value={ExperienciaRequeridaTxt}
              onChange={(e) => setExperienciaRequeridaTxt(e.target.value)}
            ></TextField>
          </Grid>
        </Grid>
      </Grid>


      <Grid container spacing={3} style={{ marginTop: "auto" }}>
        <Grid item md={12} className={classes.datoContent} style={{ backgroundColor: "gainsboro" }}>
          {/* <Typography variant="h6" className={classes.titulo}>Descriptor de Puesto</Typography> */}
        </Grid>
        <Grid item container lg={6} className={classes.datoContent}>
          <Grid item md={4}>
            <div className={classes.labelContainer}>
              <PublicIcon className={classes.icon} />
              <Typography className={classes.datoTxt}>Ubicación: </Typography>
            </div>
          </Grid>
          <Grid item md={8}>
            {/* <TextField inputProps={{ readOnly: true, }}> Por definir</TextField> */}
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label"></InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="CodigoUbicacion"
                name="CodigoUbicacion"
                value={CodigoUbicacion}
                onChange={(e) => setCodigoUbicacion(e.target.value)}
              >
                {CatalogoUbicaciones.map((item) => (
                  <MenuItem value={item.CodigoUbicacion}>
                    {item.Ubicacion}
                  </MenuItem>
                ))}

              </Select>
            </FormControl>
            {/* <Typography className={classes.label}>{datosDescriptor ? datosDescriptor.CodigoPuestoLaboral : "-"}</Typography> */}
          </Grid>
        </Grid>
        <Grid item container lg={6} className={classes.datoContent}>
          <Grid item md={4}>
            <div className={classes.labelContainer}>
              <SettingsBrightnessIcon className={classes.icon} />
              <Typography className={classes.datoTxt}>Jornada:</Typography>
            </div>
          </Grid>
          <Grid item md={8}>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label"></InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="CodigoJornada"
                name="CodigoJornada"
                value={CodigoJornada}
                onChange={(e) => setCodigoJornada(e.target.value)}
              >
                {CatalogoJornadas.map((item) => (
                  <MenuItem value={item.CodigoJornada}>
                    {item.Jornada}
                  </MenuItem>
                ))}

              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item container lg={6} className={classes.datoContent}>
          <Grid item md={4}>
            <div className={classes.labelContainer}>
              <CommuteIcon className={classes.icon} />
              <Typography className={classes.datoTxt}>Licencia de Conducir:</Typography>
            </div>
          </Grid>
          <Grid item md={8}>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label"></InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="CodigoLicencia"
                name="CodigoLicencia"
                value={CodigoLicencia}
                onChange={(e) => setCodigoLicencia(e.target.value)}
              >
                {CatalogoLicencias.map((item) => (
                  <MenuItem value={item.CodigoLicenciaConducir}>
                    {item.LicenciaConducir}
                  </MenuItem>
                ))}

              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item container lg={6} className={classes.datoContent}>
          <Grid item md={6}>
            <div className={classes.labelContainer}>
              <DriveEtaIcon className={classes.icon} />
              <Typography className={classes.datoTxt}>Disponibilidad Para Viajar:</Typography>
            </div>
          </Grid>
          <Grid item md={5}>
            <Checkbox
              checked={DisponibilidadViajar}
              onChange={(e) => setDisponibilidadViajar(e.target.checked)}
              color="primary"
            ></Checkbox>
          </Grid>
        </Grid>





      </Grid>



    </React.Fragment >
  );
};

export default PerfilContratacion;
