import React, { useState, useEffect } from "react";
import { Grid, Paper, Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles/";

import { useParams } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";

import Layout from "../../layout/containers/Layout";

import PublicacionesTimeline from "../components/notificaciones-timeline";
//import { publicionesQuery } from '../Queries/publiciones'

import API from "../../utils/api";
import { isSignedIn, profileImageChange, signOut } from "../../utils/auth";
import { registrarAnalitica } from "../../utils/firebase-config";

const useStyles = makeStyles((theme) => ({
  title: {
    color: "#ffffff",
    background: "linear-gradient(to right, #4CAF50, #3ca55c)",
    height: "12rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "1rem",
    borderRadius: "5px 5px 0 0",
  },
  detalle: {
    padding: "2rem",
  },
  box: {
    padding: "1rem",
  },
  timelineContainer: {
    height: "calc(100vh - 250px)",
    overflowY: "auto",
  },
  notificacionContainer: {
    padding: '2rem',
    height: 'calc(100vh - 176px)',
    overflowY: 'auto'
  }
}));

const Publicaciones = () => {
  const classes = useStyles();
  const { codigo } = useParams();

  const [items, setItems] = useState([]);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [buscando, setBuscando] = useState(false);

  const [codigoUsuario, setCodigoUsuario] = useState("");
  const [codigoNotificacion, setCodigoNotificacion] = useState(0);

  useEffect(() => {
    registrarAnalitica('Notificaciones_porUsuario');
    async function obtenerDatosSesion() {
      let session = await isSignedIn();
      
      if (!session.response) {
        window.location.href = "/login";
        return;
      } else if (session.response) {
        setCodigoUsuario(session.codigoUsuario);
      }
    }
    obtenerDatosSesion();
  }, []);

  const getPublicaciones = async () => {
    let session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    }
    try {
      setBuscando(true);
      let codigo = codigoUsuario; //session.codigoUsuario;

      const request = await API.fetchGetRequest(
        `api/notificacion/getNotificaciones/usuario/${codigo}`
      );

      if (request.data == null) {
        return;
      }
      console.log('data eventos ', request.data)
      setItems(request.data);

    } catch (e) {
      console.log(e);
    } finally {
      setBuscando(false);
    }
  };

  const selectItem = (idNotificacion) => {
    // const item = { ...items[index] };
    // setSelected(item);
    setCodigoNotificacion(idNotificacion);
  };

  useEffect(() => {
    getPublicaciones();
  }, [codigoUsuario]);
  
  useEffect(() => {
    if (items && items.length && codigo) {
      selectItem(codigo)
    }
  }, [items]);

  const getNotificacion = async () => {
    let session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    }
    try {
      setBuscando(true);
      let codigo = codigoNotificacion; //session.codigoUsuario;

      const request = await API.fetchGetRequest(
        `api/notificacion/${codigo}`
      );

      if (request.data == null) {
        return;
      }

      setSelected(request.data);

    } catch (e) {
      console.log(e);
    } finally {
      setBuscando(false);
    }
  };

  useEffect(() => {
      if(codigoNotificacion>0){
        getNotificacion();
      }
  }, [codigoNotificacion]);

  return (
    <Layout
      titulo="Notificaciones" bodyContainerStyle={{ paddingBottom: '0' }} maxWidth={"lg"}>
      {!loading ? (
        <Paper style={{ width: "100%", height: 'calc(100vh - 176px)' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={3} style={{ padding: "2rem" }}>
              <Typography variant="h5" component="h2">
                Notificaciones
              </Typography>
              <div id="nofificacionContainer" className={classes.timelineContainer}>
                <PublicacionesTimeline items={items} onSelect={selectItem} selected={selected} />
              </div>
            </Grid>
            { selected !== null &&
            (<Grid id="nofificacionContainer" item xs={12} md={9} className={classes.notificacionContainer}>
              <Box className={classes.title}>
                <Typography variant="h4" component="h1">
                  <b>{selected.Titulo}</b>
                </Typography>
                <Typography variant="h5" component="h2">
                  {selected.Fecha}
                </Typography>
              </Box>
              <div style={{ padding: "1rem" }}>
                <Typography variant="body1" component="div">
                  {ReactHtmlParser(selected.bodyHtml)}
                </Typography>
              </div>
            </Grid>)}
          </Grid>
        </Paper>
      ) : undefined}
    </Layout>
  );
};

export default Publicaciones;
