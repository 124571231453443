import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import DataGrid, {
  Column,
  Pager,
  Paging,
  Sorting,
  ColumnFixing,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Grouping,
  GroupPanel,
  Export,
  Selection,
  Editing,
  Scrolling,
  RowDragging,
} from "devextreme-react/data-grid";
import { Grid, Paper, Tooltip, Typography } from "@material-ui/core";
import API from "../../utils/api";
import { Button } from "devextreme-react";
import { AssignmentInd, AssignmentTurnedIn, Traffic } from "@material-ui/icons";
import { isSignedIn } from "../../utils/auth";
import { toast } from "react-toastify";

let datagridEmpleadosSCILU;

const useStyles = makeStyles((theme) => ({
  tituloPaper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  rootTabla: {
    maxWidth: 1024,
    marginTop: theme.spacing.unit * 1,
    // width: "100%"
    // overflowX: "auto",
  },
  table: {
    width: "100%",
  },
  gestionGeneral: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  paperUserModal: {
    position: "absolute",
    width: "75%",
    height: "600px",
    maxHeight: "600px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
  chip: {
    marginLeft: 10,
  },
  container: {
    margin: 10,
  },
  input: {
    marginTop: 15,
  },
  inputTitulo: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  iconColor: {
    color: "#2196F3",
  },
  classChips: {
    textAlign: "left",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
}));

const GridDetalleMovimientosPersonal = (props) => {
  const classes = useStyles();

  let applyFilterTypes = [
    {
      key: "auto",
      name: "Immediately",
    },
    {
      key: "onClick",
      name: "On Button Click",
    },
  ];

  const monedaFormat = {
    style: "currency",
    currency: "GTQ",
    // useGrouping: true,
    minimumSignificantDigits: 3,
  };

  const porcentajeFormat = {
    type: "fixedPoint",
    format: "#0.##'%'",
    // useGrouping: true,
    // minimumSignificantDigits: 3,
  };

  const [showFilterRow, setShowFilterRow] = useState(true);
  const [showHeaderFilter, setShowHeaderFilter] = useState(true);
  const [currentFilter, setCurrentFilter] = useState(applyFilterTypes[0].key);
  const [autoExpandAll, setAutoExpandAll] = useState(true);

  const [dataAviso, setDataAviso] = useState(
    props.dataAnalisisSalarial
  );
  const [esUltimoPaso, setEsUltimoPaso] = useState(false);
  const [selectedEmployeeNames, setSelectedEmployeeNames] = useState(
    "No hay empleados seleccionados"
  );

  const [userName, setUserName] = useState("");

  useEffect(() => {
    const cargar = async () => {
      await cargarDatosDetalleTarea();
    };

    cargar();
  }, []);

  const cargarDatosDetalleTarea = async () => {
    try {
      const session = await isSignedIn();
      if (!session.response) {
        window.location.href = "/login";
        return;
      } else {
        setUserName(session["userName"]);
      }

      console.log(props.aviso);
      let uri = "api/ReportesGespilu/dashboard/" + props.aviso.CodigoAviso;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        return false;
      } else {
        // setDataAviso(request.data.ficha);
        let x =
          request.data.ficha.NumeroTareas -
          request.data.ficha.NumeroTareasTerminadas;
        setEsUltimoPaso(x === 1);

        console.log("IndicadorPasos" + x);
      }
    } catch (e) {
      console.log(e);
    } finally {
    }
  };

  //selección multiple

  const onInitialized = (e) => {
    datagridEmpleadosSCILU = e.component;
  };

  const deseleccionarItemsEnGridEmpleados = () => {
    datagridEmpleadosSCILU.clearSelection();
  };

  const getEmployeeName = (row) => {
    return `[${row.CodigoAnterior} - ${row.NombreCompleto}]`;
  };

  const getEmployeeNames = (selectedRowsData) => {
    return selectedRowsData.length
      ? selectedRowsData.map(getEmployeeName).join("; ")
      : "No hay empleados seleccionados";
  };

  const onSelectionChanged = ({ selectedRowKeys, selectedRowsData }) => {
    setSelectedEmployeeNames(getEmployeeNames(selectedRowsData));
  };

  const generarSolicitud = async () => {
    const data = {
      ListaSolicitudes: datagridEmpleadosSCILU.getSelectedRowsData(),
      UsuarioInserto: userName,
      //   CodigoUnidadOrganizacion: props.codigoUnidadOrganizacion,
      // CodigoGerencia: props.CodigoGerencia,
    };

    console.log(data);
    // dataFicha.usuarioInserto = "prueba";
    try {
      //   handleAbrirBackDrop();
      let vIsSignedIn = await isSignedIn();
      if (vIsSignedIn.response) {
        let request;

        request = await API.post(
          "api/EmpleadoSolicitudMovimiento/EjecutarSCILU",
          data
        );
        if (request.status !== 200) {
          toast.error(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        } else {
          toast.success("Se guardó exitosamente");

          await cargarDatosDetalleTarea();

          deseleccionarItemsEnGridEmpleados();
        }
      }
    } catch (e) {
      console.log("EXCEPTION: " + e);
      toast.error(
        "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
      );
    } finally {
      //   handleCerrarDialogoCrearGestion();
      //   handleCerrarBackDrop();
    }
  };

  const cancelarSolicitud = async () => {
    const data = {
      ListaSolicitudes: datagridEmpleadosSCILU.getSelectedRowsData(),
      UsuarioInserto: userName,
      //   CodigoUnidadOrganizacion: props.codigoUnidadOrganizacion,
      // CodigoGerencia: props.CodigoGerencia,
    };

    console.log(data);
    // dataFicha.usuarioInserto = "prueba";
    try {
      //   handleAbrirBackDrop();
      let vIsSignedIn = await isSignedIn();
      if (vIsSignedIn.response) {
        let request;

        request = await API.post(
          "api/EmpleadoSolicitudMovimiento/CancelarSolicitud",
          data
        );
        if (request.status !== 200) {
          toast.error(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        } else {
          toast.success("Se guardó exitosamente");

          await cargarDatosDetalleTarea();

          deseleccionarItemsEnGridEmpleados();
        }
      }
    } catch (e) {
      console.log("EXCEPTION: " + e);
      toast.error(
        "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
      );
    } finally {
      //   handleCerrarDialogoCrearGestion();
      //   handleCerrarBackDrop();
    }
  };
  const solicitarModificacion = async () => {
    const data = {
      ListaSolicitudes: datagridEmpleadosSCILU.getSelectedRowsData(),
      UsuarioInserto: userName,
      CodigoUnidadOrganizacion: props.codigoUnidadOrganizacion,
      // CodigoGerencia: props.CodigoGerencia,
    };

    console.log(data);
    // dataFicha.usuarioInserto = "prueba";
    try {
      //   handleAbrirBackDrop();
      let vIsSignedIn = await isSignedIn();
      if (vIsSignedIn.response) {
        let request;

        request = await API.post(
          "api/EmpleadoSolicitudMovimiento/AplicarCambio",
          data
        );
        if (request.status !== 200) {
          toast.error(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        } else {
          toast.success("Se guardó exitosamente");

          //   await NuevaLista();

          deseleccionarItemsEnGridEmpleados();
        }
      }
    } catch (e) {
      console.log("EXCEPTION: " + e);
      toast.error(
        "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
      );
    } finally {
      //   handleCerrarDialogoCrearGestion();
      //   handleCerrarBackDrop();
    }
  };

//   const cellOpciones = (rowInfo) => {
//     return (
//       <>
//         <Tooltip
//           title="Ver detalle de empleado"
//           style={{ marginRight: 5, cursor: "pointer" }}
//         >
//           <AssignmentInd
//             style={{ color: "#337ab7", cursor: "pointer" }}
//             onClick={() => handleSeleccionEmpleado(rowInfo.data)}
//           />
//         </Tooltip>
//       </>
//     );
//   };

  const cellSemaforo = (rowInfo) => {
    let color = "#8B0000";

    if (rowInfo.data.Semaforo >= 3) {
      color = "#8B0000";
    } else if (rowInfo.data.Semaforo == 2) {
      color = "#FF8C00";
    } else {
      color = "#228B22";
    }
    return (
      <>
        <Tooltip
          title="Ver histórico de puesto laboral"
          style={{ marginRight: 5, }}
        >
          <Traffic style={{ color: color,  }} />
        </Tooltip>
      </>
    );
  };


  return (
    <Paper className={classes.rootTabla}>
      <Typography variant="h5">Lista de Movimientos</Typography>
      <DataGrid
        dataSource={dataAviso}
        //   defaultColumns={this.props.columns}
        showBorders={true}
        rowAlternationEnabled={true}
        //ref={(ref) => (dataGrid = ref)}
        allowColumnReordering={true}
        allowColumnResizing={true}
        columnAutoWidth={true}
        // onExporting={onExporting}
        className={classes.table}
        onInitialized={onInitialized}
        onSelectionChanged={onSelectionChanged}
      >
        <FilterRow visible={showFilterRow} applyFilter={currentFilter} />
        {/* <HeaderFilter visible={showHeaderFilter} /> */}
        <GroupPanel visible={false} />
        {/* <Grouping autoExpandAll={autoExpandAll} /> */}
        <SearchPanel visible={false} width={240} placeholder="Search..." />
        {/* <Selection mode="multiple" /> */}
        <Editing
          mode="row"
          useIcons={true}
          allowUpdating={false}
          allowDeleting={false}
        />
        <Selection mode="multiple" />
        {/* <Column
            caption="Perfil"
            allowFiltering={false}
            allowSorting={false}
            cellRender={cellOpciones}
            fixed={true}
            fixedPosition="left"
          /> */}
          <Column cellRender={cellSemaforo} caption="Semaforo" fixedPosition="left"/>
          <Column
            dataField="CodigoEmpleadoPlazaLaboral"
            caption="Código DB"
            visible={false}
          />

          <Column
            dataField="CodigoAnterior"
            caption="Código"
            fixed={true}
            fixedPosition="left"
          />
          <Column
            dataField="NombreCompleto"
            caption="NombreCompleto"
            fixed={true}
            fixedPosition="left"
          />
          <Column dataField="DPI" caption="DPI" />
          <Column dataField="AgrupacionDePago" caption="Agrupación de Pago" />
          <Column dataField="PuestoLaboral" caption="Puesto Laboral" />
          <Column
            dataField="CumpleEducacionTexto"
            caption="Cumple Educación?"
          />
          <Column
            dataField="CumpleExperienciaTexto"
            caption="Cumple Experiencia?"
          />
          
          <Column
            dataField="Salario"
            caption="SalarioActual"
            format={monedaFormat}
          />
          <Column
            dataField="NuevoSalario"
            caption="Nuevo Salario"
            format={monedaFormat}
          />
          {/*  */}
          <Column
            dataField="PorcentajeAumentoSalarial"
            caption="Porcentaje de Aumento"
            format={porcentajeFormat}
          />
          <Column dataField="ExpertisActual" caption="Expertis Actual" />
          <Column dataField="ExpertisNueva" caption="Expertis Nueva" />
          <Column
            dataField="ExperienciaRequerida"
            caption="Experiencia Requerida"
          />
          <Column
            dataField="ExperienciaEmpleado"
            caption="Experiencia Empleado"
          />
          <Column
            dataField="ExperienciaFaltante"
            caption="Experiencia Faltante"
          />
          <Column dataField="Comentario" caption="Comentario" />
        <ColumnFixing enabled={true} />
        <Sorting mode="multiple" />
        <Paging defaultPageSize={20} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={[10, 20, 50]}
          showInfo={true}
        />
        <Export enabled={true} allowExportSelectedData={true} />
        <Scrolling columnRenderingMode="virtual" />
      </DataGrid>

      <React.Fragment>
        <div className={classes.selectedData}>
          <span className={classes.selectedDataCaption}>
            Empleados seleccionados:
          </span>{" "}
          <span>{selectedEmployeeNames}</span>
        </div>
        <Typography variant="h6">Acciones</Typography>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {/* <input type="submit" /> */}
          {esUltimoPaso && (
            <Tooltip title="Aplicar Cambio">
              <Button
                // fullWidth
                variant="contained"
                style={{
                  backgroundColor: "#69BD4B",
                  color: "white",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                // type="submit"
                size="small"
                onClick={generarSolicitud}
                startIcon={<AssignmentTurnedIn />}
              >
                Aplicar Cambio
              </Button>
            </Tooltip>
          )}
          <Tooltip title="Cancelar">
            <Button
              // fullWidth
              variant="contained"
              style={{
                backgroundColor: "#69BD4B",
                color: "white",
                alignItems: "center",
                justifyContent: "center",
              }}
              // type="submit"
              size="small"
              onClick={cancelarSolicitud}
              startIcon={<AssignmentTurnedIn />}
            >
              Anular movimiento
            </Button>
          </Tooltip>

          {/* <Tooltip title="Solicitar corrección">
              <Button
                // fullWidth
                variant="contained"
                style={{
                  backgroundColor: "#69BD4B",
                  color: "white",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                // type="submit"
                size="small"
                onClick={solicitarModificacion}
                startIcon={<AssignmentTurnedIn />}
              >
                Solicitar Corrección
              </Button>
            </Tooltip> */}
        </Grid>
      </React.Fragment>
    </Paper>
  );
};

export default GridDetalleMovimientosPersonal;
