import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { DialogContent, DialogActions, Button } from "@material-ui/core";
import api from "../../../utils/api";
import {
  AppBar,
  Tab,
  Tabs,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function Props(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },

  lista: {
    height: 250,
    overflowY: "auto",
  },
}));

export default function SolicitudControlCopia({ datos, onClose }) {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [descargas, setDescargas] = useState([]);
  const [impresiones, setImpresiones] = useState([]);
  const [lecturas, setLecturas] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getDescargas = async () => {
    try {
      const { data } = await api.fetchGetRequest(
        `api/documentos/obtener-numero-descargas-documento-por-usuario/${datos.IdSolicitud}`
      );

      setDescargas(data.map((i) => ({ ...i })));
    } catch (error) {
      console.log(error);
    }
  };

  const getLecturas = async () => {
    try {
      const { data } = await api.fetchGetRequest(
        `api/documentos/obtener-numero-lecturas-documento-por-usuario/${datos.IdSolicitud}`
      );

      setLecturas(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getImpresiones = async () => {
    try {
      const { data } = await api.fetchGetRequest(
        `api/documentos/obtener-numero-impresiones-documento-por-usuario/${datos.IdSolicitud}`
      );

      setImpresiones(data.map((i) => ({ ...i })));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDescargas()
    getImpresiones()
    getLecturas()
  }, []);

  return (
    <div className={classes.root}>
      <DialogContent>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
          >
            <Tab label="Visualizaciones" {...Props(0)} />
            <Tab label="Descargas" {...Props(1)} />
            <Tab label="Impresiones" {...Props(2)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <div className={classes.lista}>
            {!lecturas.length && <Alert severity="info" variant="filled">
                No hay registros
              </Alert>}
            <List>
              {lecturas.map((i) => (
                <ListItem key={i.Usuario}>
                  <ListItemText>{i.Usuario}</ListItemText>
                </ListItem>
              ))}
            </List>
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div className={classes.lista}>
            {!descargas.length && <Alert severity="info" variant="filled">
                No hay registros
              </Alert>}
            <List>
              {descargas.map((i) => (
                <ListItem key={i.Usuario}>
                  <ListItemText>{i.Usuario}</ListItemText>
                </ListItem>
              ))}
            </List>
          </div>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <div className={classes.lista}>
            {!impresiones.length && <Alert severity="info" variant="filled">
                No hay registros
              </Alert>}
            <List>
              {impresiones.map((i) => (
                <ListItem key={i.Usuario}>
                  <ListItemText>{i.Usuario}</ListItemText>
                </ListItem>
              ))}
            </List>
          </div>
        </TabPanel>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cerrar</Button>
      </DialogActions>
    </div>
  );
}
