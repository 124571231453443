import React, { useState } from "react";
import Color from "color";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CardActionArea from "@material-ui/core/CardActionArea";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { useFourThreeCardMediaStyles } from "@mui-treasury/styles/cardMedia/fourThree";
import {
  Avatar,
  Chip,
  Container,
  Modal,
  Fade,
  Backdrop,
  Box,
  CircularProgress,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import PropTypes from "prop-types";

import Tarea from "../components/tarea-dashboard-componente";

const width_proportion = "100%";
const titleColor = "#2B3C4D";

const useGridStyles = makeStyles(({ breakpoints }) => ({
  root: {
    [breakpoints.up("md")]: {
      justifyContent: "center",
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  actionArea: {
    borderRadius: 16,
    transition: "0.2s",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  card: ({ color }) => ({
    minWidth: 256,
    maxWidth: 256,
    borderRadius: 16,
    boxShadow: "none",
    "&:hover": {
      boxShadow: `0 6px 12px 0 ${Color(color)
        .rotate(-12)
        .darken(0.2)
        .fade(0.5)}`,
    },
  }),
  content: ({ color }) => {
    return {
      backgroundColor: color,
      padding: "1rem 1.5rem 1.5rem",
    };
  },
  title: {
    // fontFamily: "Keania One",
    fontSize: "2rem",
    color: "#fff",
    textTransform: "uppercase",
  },
  subtitle: {
    // fontFamily: "Montserrat",
    color: "#fff",
    opacity: 0.87,
    marginTop: "0.5rem",
    fontWeight: 500,
    fontSize: 14,
  },
  table: {
    width: "100%",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 210,
  },
  textFieldDireccion: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 230,
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  greenAvatar: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  paperCard: {
    padding: 20,
    width: "100%",
  },
  formControlSelects: {
    // margin: theme.spacing(1),
    // minWidth: 230,

    marginBottom: 10,
    marginTop: 10,
    width: "100%",
    marginLeft: 30,
  },
  formControlSelect: {
    // margin: theme.spacing(1),
    width: width_proportion,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  rootTabla: {
    width: "100%",
    marginTop: theme.spacing.unit * 1,
    overflowX: "auto",
  },
  modal: {
    // display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: "10%",
    left: "20%",
    overflow: "scroll",
    height: "100%",
    display: "block",
    padding: theme.spacing(8, 4, 8, 8),
  },
  paperModal: {
    position: "absolute",
    width: "90%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1, 1, 1, 1),
  },
}));

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          className={props.classes.subtitle}
          variant="caption"
          component="div"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

const CustomCard = ({
  // classes,
  image,
  title,
  titulo,
  porcentajeAvance,
  tituloAviso,
  responsables,
  abrirModal,
  gestion,
}) => {
  const mediaStyles = useFourThreeCardMediaStyles();

  let color = "#203f52";

  const hoy = new Date();
  const fechaVencimiento = new Date(Date.parse(gestion.FechaVencimiento));

  let diferencia = hoy.getTime() - fechaVencimiento.getTime();
  let dias = Math.ceil(diferencia / (1000 * 3600 * 24));

  if (dias >= 7) {
    color = "#8B0000";
  } else if (dias >= 3 && dias < 7) {
    color = "#FF8C00";
  } else {
    color = "#228B22";
  }

  const classes = useStyles({ color: color });
  return (
    <CardActionArea
      className={classes.actionArea}
      onClick={()=>abrirModal(title)}>
      <Card className={classes.card} color={color} >
        <CardContent className={classes.content}>
          <Typography className={classes.title} variant={"h2"}>
            {"Gestión"}
          </Typography>
          <Typography className={classes.title} variant={"h2"}>
            {"[# " + title + "]"}
          </Typography>
          {/* <Typography className={classes.subtitle} variant={"p"}>{titulo}</Typography> */}

          <Typography className={classes.subtitle}>
            {"Tarea Actual: "}
          </Typography>
          <Typography className={classes.subtitle} >
            {tituloAviso}
          </Typography>
          <Typography className={classes.subtitle}>
            {"Avance: " +
              gestion.NumeroTareasTerminadas +
              "/" +
              gestion.NumeroTareas}
          </Typography>
          <CircularProgressWithLabel
            value={porcentajeAvance}
            color="primary"
            classes={classes}
          />
          <Typography className={classes.subtitle}>
            {"Responsable(s) Actual(es):"}
          </Typography>
          <Typography className={classes.subtitle}>
            {"Dias de Atraso: " + dias}
          </Typography>
          <br />
          {responsables.map((r,index) => (
            <Chip
              avatar={<Avatar>{r.substring(0, 1)}</Avatar>}
              label={r}
              // variant="outlined"
              color="primary"
              key={index}
            />
          ))}
        </CardContent>
      </Card>
    </CardActionArea>
  );
};

const DetalleDashBoard = (props) => {
  const gridStyles = useGridStyles();
  const classes = useStyles({ color: "#203f52" });

  const [abrirModalTarea, setAbrirModalTarea] = useState(false);
  const [codigoAviso, setCodigoGestion] = useState(0);

  const handleOpenModal = (pIdGestion) => {
    setAbrirModalTarea(true);
    setCodigoGestion(pIdGestion);
    // window.open('https://desagestionapp.launion.com.gt/gestion/'+ pIdGestion, '_blank', 'noopener,noreferrer');
  };

  const handleCloseModal = () => {
    setAbrirModalTarea(false);
  };

  return (
    <React.Fragment>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={abrirModalTarea}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={abrirModalTarea}>
          <div className={classes.paperModal}>
            {/* <h2 id="simple-modal-title">{"Tarea actual" + codigoAviso}</h2> */}
            <Tarea codigoAviso={codigoAviso} />
          </div>
        </Fade>
      </Modal>
      <Grid classes={gridStyles} container spacing={4} wrap={"wrap"}>
        {props.gestiones.map((gestion,index) => (
          <Grid key={index} item>
            <CustomCard
              title={gestion.CodigoAviso}
              titulo={gestion.Titulo}
              porcentajeAvance={gestion.PorcentajeAvance}
              tituloAviso={gestion.TituloAviso}
              responsables={gestion.Responsables}
              abrirModal={handleOpenModal}
              gestion={gestion}
            />
          </Grid>
        ))}
      </Grid>
    </React.Fragment>
  );
};

export default DetalleDashBoard;
