import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import {render} from 'react-dom';
import {sortableContainer, sortableElement} from 'react-sortable-hoc';
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { DatePicker, MuiPickersUtilsProvider, Day } from "material-ui-pickers";
import Fab from "@material-ui/core/Fab";
import MaskedInput from 'react-text-mask';
import DetailsIcon from "@material-ui/icons/Details";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Loader from "react-loader-spinner";
import ListAltIcon from '@material-ui/icons/ListAlt';
//Select
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";


//Upload file
import {DropzoneArea} from 'material-ui-dropzone';

//Checkbox
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from '@material-ui/core/Checkbox';
import  "../plantillaProblema/styles.css";
import {arrayMoveControls} from '../utils/dndUtils';

//Drawer
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';


//Tabs
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'

//chip
import ChipInput from 'material-ui-chip-input'

import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";

//Own components
import Layout from "../layout/containers/Layout";
import API from "../utils/api";
import { isSignedIn } from "../utils/auth";
import {convertirASegundos,convertToYearMonthDay,convertDMY,convertYMD,convertirHHMM} from "../utils/helpers";
import InputAdornment from '@material-ui/core/InputAdornment';
import DispositivoListContainer from "../usuario/containers/usuario-list";
//import TareaCard from "./tarea-card";
import AddButton from "../helpers/components/add-button-float";
import {validaPermisoPaginaActual} from "../utils/helpers";
import FormList  from "../avisos-objects/components/form-list";
import ActionMenuFormTab from "../avisos-objects/components/action-menu-form-tab";
import FormDesigner from "../form-designer/container/form-designer";
import { Value } from "devextreme-react/range-selector";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const CardIntervalo = sortableElement(
  ({ value, editar, confirmarEliminar}) => (
    <li style={{ textAlign: "right" }}>
      <IconButton
        style={{ top: "0", color: "#3f51b5" }}
        aria-label="Editar"
        onClick={(event) => {
          editar(value.CodigoIntervaloPlantillaAlerta);
        }}
      >
        <EditRoundedIcon />
      </IconButton>

      <IconButton
        aria-label="Eliminar"
        style={{ top: "0", color: "#f50057" }}
        onClick={() => confirmarEliminar(value.CodigoIntervaloPlantillaAlerta)}
      >
        <DeleteForeverRoundedIcon />
      </IconButton>
      <div className="dragCard" data-codigocampo={value.CodigoIntervaloPlantillaAlerta} zIndex="1">
      <Card>
        <CardContent >
        <Grid
        container
        spacing={0}
        alignItems="center"
        justify="center"
        >

        <Grid item xs={3}>
        <TextField
                disabled
                id="txtCantidad"
                label="Cantidad"
                type="number"
                value = {value.Cantidad}
                InputLabelProps={{
                    shrink: true,
                }}
        />
        
        </Grid>
        <Grid item xs={3}>
        <TextField
                disabled
                id="txtUnidadTiempo"
                label="Unidad de Tiempo"
                value = {value.NombreUnidadTipo}
                InputLabelProps={{
                    shrink: true,
                }}
        />
        </Grid>
        </Grid>            
        </CardContent>
    </Card>
      
      </div>
    </li>
  )
);




const CardIntervaloContainer = sortableContainer(({children}) => {
  return <ul>{children}</ul>;
});


const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%"
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none"
  },
  buttonAdd: {
    margin: theme.spacing.unit
  },
  paperPopover: {
    padding: theme.spacing(1),
    background: "#000",
    color: "#fff",
  },
  rootAppbar: {
    flexGrow: 1,
  },
  root: {
    //flexGrow: 1,
    width: "100%",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    // backgroundColor: fade(theme.palette.common.white, 0.15),
    // "&:hover": {
    //   backgroundColor: fade(theme.palette.common.white, 0.25),
    // },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
  paperUserModal: {
    position: "absolute",
    width: "75%",
    height: "600px",
    maxHeight: "600px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
  paperModal: {
    position: "absolute",
    width: "90%",
    height: "600px",
    maxHeight: "600px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
  paperNuevoForm: {
    position: "absolute",
    width: "50%",
    height: "300px",
    maxHeight: "300px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
  bigPaper: {
    position: "absolute",
    width: "95%",
    height: "95%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
});

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },

 
  menuButton: {
    marginRight: theme.spacing(2)
  },
  hide: {
    display: "none"
  },
   
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  }
}));

 // const theme = useTheme();

class TaskAlerta extends Component {
  state = {
    open: false,
    openToolbar: false,
    actionType: "", //Crear, Actualizar
    items: [],
    codigoPlantillaAlerta: "",
    codigoIntervaloPlantillaAlerta: "",
        
    cantidad: "",
    codigoUnidadMedida: "",
    activo: "",
    codigoUnidadMedidaTipo: "",
    unidadMedidaTipos:[],


    confirmTexto: "",
    confirmOpen: false,
    codigoIntervaloEliminar: "",
    nombreFlujo: "",
    cargando: false,

    codigoPlantilla:"",
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(
      ({ items }) => ({
        items: arrayMoveControls(items, oldIndex, newIndex),
      }),
      () => {
        this.cambiarOrden();
      }
    );
  };

  async componentWillMount() {
    //CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
    const llaveAgrupacion = "Menu.Principal.Web";
    const llavePagina = "Menu.Principal.Web.PlantillaNotificaciones";
    await validaPermisoPaginaActual(llaveAgrupacion, llavePagina);
    //FIN DE CONSULTA DE PERMISOS

    if (
      this.props.match.params.codigoPlantillaalerta === null ||
      this.props.match.params.codigoPlantillaalerta === undefined
    ) {
      window.location = "/home";
    } else if (!isNaN(this.props.match.params.codigoPlantillaalerta)) {
      let codigoPlantillaAlerta = parseInt(
        this.props.match.params.codigoPlantillaalerta
      );
        console.log(codigoPlantillaAlerta)
      this.setState(
        {
          codigoPlantillaAlerta: codigoPlantillaAlerta,
        },
        async () => {
          this.cargarListaIntervalos();
          this.cargarNombreFlujo();
          this.cargarListaTipoUnidadMedida();
        }
      );
    }
  }

  async cargarListaIntervalos() {
    try {
      //Loader
      this.setState({ cargando: true });
      console.log("Codigoalerta",this.state.codigoPlantillaAlerta)
      const request = await API.fetchGetRequest(
        `api/plantillaalerta/listaIntervalos/${this.state.codigoPlantillaAlerta}`
      );
      this.setState({
        items: request.data.data,
      });
      if (request.statusCode != 200) {
        alert(
          "Hubo un error al realizar la operación, consulta con un administrador"
        );
      }

      console.log(request.statusCode);
      if (request.statusCode === 401) {
        //alert("cerrar sesion");
        window.location.href = "/login";
      } else {
          console.log("array intervalos",request.data.data)
        if (Array.isArray(request.data.data)) {
          this.setState({
            listaObjetos: request.data.data,
          });
        }
      }
    } catch (error) {
      console.log(error);
      if (error.toString().includes("401")) {
        window.location.href = "/login";
      }
    } finally {
      this.setState({ cargando: false });
    }
  }




  async cargarNombreFlujo() {
    const request = await API.fetchGetRequest(
      `api/plantillaalerta/cargarDatosEdicion/${this.state.codigoPlantillaAlerta}`
    );
    let nombre = request.data.data.Nombre;
    this.setState({nombreFlujo: nombre});
  }

  async cargarDatosEdicion() {
    const request = await API.fetchGetRequest(
      `api/plantillaalerta/cargarDatosEdicionIntervalo/${this.state.codigoIntervaloPlantillaAlerta}`
    );
    this.setState({
        activo:request.data.data[0].Activo,
        cantidad:request.data.data[0].Cantidad,
        codigoUnidadMedida:request.data.data[0].CodigoUnidadMedida,
        codigoUnidadMedidaTipo:request.data.data[0].CodigoUnidadMedida,
    });
  }

  async cargarListaTipoUnidadMedida() {
    const request = await API.fetchGetRequest(`api/plantillaalerta/listaTipoUnidadMedida`);
    if (Array.isArray(request.data.data)) {
      this.setState({
        unidadMedidaTipos: request.data.data,
        codigoUnidadMedidaTipo: 1,
      });
    }
  }

  editar = async (codigo) => {
    this.setState(
      {
        codigoIntervaloPlantillaAlerta: codigo,
        actionType: "Actualizar",
      },
      async () => {
        await this.cargarDatosEdicion();
        this.handleOpenAddModal();
      }
    );
  };

  guardar = async () => {
    const { cantidad } = this.state;

    if (cantidad == 0) {
      toast.warn("Ingrese una cantidad válida");
      return;
    }

    try {
      let vIsSignedIn = await isSignedIn();
      let palabra = "";
      if (vIsSignedIn.response) {
        let request;
        let data;

        if (this.state.actionType == "Agregar") {
          data = {
            CodigoPlantillaAlerta: this.state.codigoPlantillaAlerta,
            CodigoIntervaloAlerta: null,
            CodigoUnidadMedida : this.state.codigoUnidadMedidaTipo,
            UsuarioOpera: vIsSignedIn.userName,
            CodigoUsuarioOpera: vIsSignedIn.codigoUsuario,
            Cantidad : this.state.cantidad,
            Orden: null,
            Activo: true,
            Eliminado: false
          };
          palabra = "grabado";
        } else {
          data = {
            CodigoIntervaloAlerta :this.state.codigoIntervaloPlantillaAlerta,
            CodigoPlantillaAlerta :this.state.codigoPlantillaAlerta,
            CodigoUnidadMedida : this.state.codigoUnidadMedidaTipo,
            UsuarioOpera: vIsSignedIn.userName,
            CodigoUsuarioOpera: vIsSignedIn.codigoUsuario,
            Cantidad : this.state.cantidad,
            Orden: null,
            Activo: this.state.activo,
            Eliminado: false
          };
          palabra = "editado";
        }
        console.log("Enviar a "+palabra,data)
        if(this.state.actionType == "Agregar" && this.state.items.length == 10){
            toast.error("El número máximo de intervalos de alertas es 10")
        }
        else{
            request = await API.post(`api/plantillaalerta/grabarIntervaloAlerta`, data);
            if (request.status != 200) {
              alert(
                "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
              );
            }

            if (request.statusCode === 401) {
              //alert("cerrar sesion");
            } else {
              toast.success("La tarea se ha " + palabra + " exitosamente.");
              this.handleClose();
              this.cargarListaIntervalos();
            }
        }
      }
    } catch (e) {
      alert("hubo un error");
    }
  };


  handleChange = (name) => (event) => {
    if (name === "activo" || name === "requeridoCampo" || name === "secuencial" || name === "cambiarEstadoPorHijos" || name === "cambiarEstadoHijos") {
      this.setState({ [name]: event.target.checked });
    } else {
      this.setState({
        [name]: event.target.value,
      });
    }
  };

  confirmarAccion = (CodigoIntervaloAlerta) => {
    this.setState({
      confirmTexto: `¿Confirma que desea eliminar este elemento? esta acción no podrá revertirse.`,
      confirmOpen: true,
      codigoIntervaloEliminar: CodigoIntervaloAlerta,
    });
  };

  funcionOk = () => {
    this.eliminarCampo();
  };

  cerrarConfirm = () => {
    this.setState({ confirmTexto: "", confirmOpen: false });
  };

  eliminarCampo = async () => {
    try {
      let vIsSignedIn = await isSignedIn();

      if (vIsSignedIn.response) {
        const request = await API.post(
          `api/plantillaalerta/eliminarIntervalo/${this.state.codigoIntervaloEliminar}/${vIsSignedIn.userName}`
        );
        if (request.status != 200) {
          alert(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        }

        if (request.statusCode === 401) {
          //alert("cerrar sesion");
        } else {
          toast.success("El intervalo se eliminó exitosamente.");

          this.cargarListaIntervalos(true);
        }
      } else {
        toast.error("La sessión no se encuentra activa");
      }
    } catch (e) {
      alert(e);
    } finally {
      this.setState({ codigoIntervaloEliminar: "" });
    }
  };

  handleOpenAddModal = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
        activo:"",
        cantidad:1,
        codigoUnidadMedida:"",
        codigoUnidadMedidaTipo:"",
        open: false,
    });
  };

  
  render() {
    const { classes } = this.props;
    const { items } = this.state;
    return (
      <Layout titulo={"Configuración de Flujo " + this.state.nombreFlujo}>

        {this.state.cargando ? (
          <div
            style={{
              width: "100%",
              marginTop: 10,
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loader
              type="Oval"
              color="#3498db"
              height="50"
              width="50"
              style={{ textAlign: "center" }}
            />
          </div>
        ) : (
          <></>
        )}
         <div className={classes.root}>
          <Grid item xs={12} md={12} lg={8} sm={12} style={{ margin: "auto" }}>
           <CardIntervaloContainer pressDelay={150} onSortEnd={this.onSortEnd}>
              {items.map((value, index) => (
                <CardIntervalo
                  key={`item-interval${value}`}
                  index={index}
                  value={value}
                  editar={this.editar}
                  confirmarEliminar={this.confirmarAccion}
                />
              ))}
              </CardIntervaloContainer>
          </Grid>
        </div>
        <AddButton
          onClick={() => {
            this.setState({ actionType: "Agregar" }, this.handleOpenAddModal());
          }}
        />

        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.open}
          onClose={this.handleClose}
        >
          <div style={getModalStyle()} className={classes.paper}>
            <form className={classes.container} noValidate autoComplete="off">
              <Typography variant="title" gutterBottom>
                {this.state.actionType == "Agregar" ? (
                  <div>Crear</div>
                ) : (
                  <div>Editar</div>
                )}
              </Typography>
              <TextField
                id="txtCantidad"
                label="Cantidad"
                type="number"
                className={classes.textField}
                value={this.state.cantidad}
                onChange={this.handleChange("cantidad")}
                margin="normal"
                InputLabelProps={{
                    shrink: true,
                }}
                InputProps={{ inputProps: { min: 1 } }}
                />
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl
                  className={classes.formControl}
                  style={{ width: "100%" }}
                >
                  <InputLabel htmlFor="codigoTipoUnidadMedida">
                    Tipo de unidad de medida
                  </InputLabel>
                  <Select
                    name="codigoUnidadMedida"
                    value={this.state.codigoUnidadMedidaTipo}
                    onChange={this.handleChange("codigoUnidadMedidaTipo")}
                    input={
                      <Input name="codigoUnidadMedidaTipo" id="codigoUnidadMedidaTipo" />
                    }
                  >
                    {this.state.unidadMedidaTipos.map((item) => (
                      <MenuItem
                        key={item.CodigoUnidadMedida}
                        value={item.CodigoUnidadMedida}
                      >
                        {item.Nombre}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText />
                </FormControl>
              </Grid>
              {this.state.actionType === "Actualizar" ? (
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.activo}
                        onChange={this.handleChange("activo")}
                        value="activo"
                        color="primary"
                      />
                    }
                    label="Activo"
                  />
                  <br />
                </div>
              ) : (
                <div></div>
              )}
              
              <Grid container spacing={3}>
                <Grid item xs={12} lg={12} sm={12}>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: "#69bd4b", color: "white" }}
                    className={classes.buttonAdd}
                    onClick={() => this.guardar()}
                  >
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </Modal>
        

        <ConfirmDialog
          id="confirmar"
          keepMounted
          open={this.state.confirmOpen}
          onClose={this.cerrarConfirm}
          value=""
          texto={this.state.confirmTexto}
          okfunction={this.funcionOk}
        />

       
        <ToastContainer />
      </Layout>
    );
  }
}
const TaskAlertaContainer = withStyles(styles)(TaskAlerta);
export default withRouter(TaskAlertaContainer);

