import React from "react";

import {
  Chart,
  Series,
  CommonSeriesSettings,
  Label,
  Format,
  Export,
} from "devextreme-react/chart";

class App extends React.Component {
  render() {
    const dataSource = this.props.dataSource || [];
    const title = this.props.title || "default";
    const color = this.props.color || "#69BD4B";

    return (
      <Chart id="chart" dataSource={dataSource} title={title}>
        <CommonSeriesSettings
          argumentField="state"
          type="bar"
          hoverMode="allArgumentPoints"
          selectionMode="allArgumentPoints"
        >
          <Label visible={true}>
            <Format type="fixedPoint" precision={0} />
          </Label>
        </CommonSeriesSettings>
        <Series
          valueField="value"
          argumentField="argument"
          name=""
          type="bar"
          color={color}
        />
        <Export enabled={true} />
      </Chart>
    );
  }
}

export default App;
