import React, { useState, useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import Layout from '../layout/containers/Layout'
import api from '../utils/api'

const PreguntasRespuestas = () => {
  const [content, setContent] = useState('')
  const getContent = async () => {
    try {
      const { data } = await api.fetchGetRequest('api/documentos/blog')
      setContent(updateContent(data))
    } catch (error) {
      toast.error('Ha ocurrido un error')
    }
  }
  const updateContent = (html) => {
    const template = `
      <html>
        <head>
          <meta http-equiv="Content-Type" content="text/html;charset=utf-8" />
          <link
            rel="stylesheet"
            type="text/css"
            href="https://cdn.jsdelivr.net/npm/sceditor@3/minified/themes/content/default.min.css"
          />
        </head>
        <body dir="ltr">
          ${html}
        </body>
      </html>
    `
    return template
  }
  useEffect(() => {
    getContent()
  }, [])
  return <Layout titulo="Preguntas y respuestas">
    <iframe
      frameBorder="0"
      allowFullScreen="true"
      srcDoc={content}
      style={{width: '100%', height: 'calc(100vh - 186px)'}}
    />
    <ToastContainer />
  </Layout>
}

export default PreguntasRespuestas
