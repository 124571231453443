import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Checkbox,
  FormControlLabel,
  Divider,
  Paper,
  Tooltip,
  Modal,
  Fade,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
} from "@material-ui/core";

import BackdropModal from "@material-ui/core/Backdrop";

import clsx from "clsx";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import {
  ChromeReaderMode,
  Edit,
  People,
  AttachFile,
  Cached,
  Delete,
  Label,
  ViewList,
  Add,
} from "@material-ui/icons";

import { green } from "@material-ui/core/colors";
import { NavLink } from "react-router-dom";

import DataGrid, {
  Column,
  Pager,
  Paging,
  Sorting,
  ColumnFixing,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Grouping,
  GroupPanel,
  Export,
  Selection,
  Editing,
  Scrolling,
} from "devextreme-react/data-grid";

import API from "../../utils/api";
import { isSignedIn, signOut } from "../../utils/auth";
import {
  validaPermisoPaginaActual,
  verificaPermisoPaginaActual,
} from "../../utils/helpers";
import { ToastContainer, toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import "devextreme/dist/css/dx.material.teal.light.compact.css";

const width_proportion = "100%";
const titleColor = "#2B3C4D";

const useStyles = makeStyles((theme) => ({
  table: {
    width: 800,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 210,
  },
  textFieldDireccion: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 230,
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  greenAvatar: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  paperCard: {
    padding: 20,
    width: "100%",
  },
  formControlSelects: {
    // margin: theme.spacing(1),
    // minWidth: 230,

    marginBottom: 10,
    marginTop: 10,
    width: "100%",
    marginLeft: 30,
  },
  formControlSelect: {
    // margin: theme.spacing(1),
    width: width_proportion,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  rootTabla: {
    width: "100%",
    marginTop: theme.spacing.unit * 1,
    overflowX: "auto",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperModal: {
    position: "absolute",
    width: "70%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const EmpleadoCorreo = (props) => {
  const classes = useStyles();
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors: fieldsErrors },
    register,
  } = useForm();

  let applyFilterTypes = [
    {
      key: "auto",
      name: "Immediately",
    },
    {
      key: "onClick",
      name: "On Button Click",
    },
  ];
  const [permisoEditar, setPermisoEditar] = useState(false);
  const [userName, setUserName] = useState("");
  const [open, setOpen] = useState(false);
  const [cargandoMaestros, setCargandoMaestros] = useState(false);
  const [empleadoCorreo, setEmpleadoCorreo] = useState([]);
  const [maestroTipoCorreo, setTipoCorreo] = useState([]);
  const [codigoEmpleadoCorreo, setCodigoEmpleadoCorreo] = useState(0);
  const [codigoTipoCorreo, setCodigoTipoCorreo] = useState(0);
  const [correoElectronico, setCorreoElectronico] = useState("");

  const [valueAutoCompleteTipoCorreo, setValueAutoCompleteTipoCorreo] =
    useState(null);

  const [positionDisableSorting, setPositionDisableSorting] = useState(false);
  const [showFilterRow, setShowFilterRow] = useState(true);
  const [showHeaderFilter, setShowHeaderFilter] = useState(true);
  const [currentFilter, setCurrentFilter] = useState(applyFilterTypes[0].key);
  const [autoExpandAll, setAutoExpandAll] = useState(true);

  const [buscando, setBackDrop] = useState(false);

  const handleCerrarBackDrop = () => {
    setBackDrop(false);
  };
  const handleAbrirBackDrop = () => {
    setBackDrop(true);
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    const cargar = async () => {
      if (!cargandoMaestros) {
        // CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
        const llaveAgrupacion = "Menu.Principal.Web";
        const llavePagina = "Menu.Principal.Web.CorreoElectronico";
        let acceso = await verificaPermisoPaginaActual(
          llaveAgrupacion,
          llavePagina
        );

        setPermisoEditar(acceso);
        // FIN DE CONSULTA DE PERMISOS

        handleAbrirBackDrop();

        await cargarMaestroTipoCorreo();
        // await cargarMaestroDosis();
        // await cargarMaestroPuestoVacunacion();

        setCargandoMaestros(true);
      } else {
        if (props.dataEmpleado !== undefined) {
          await cargarDatosEmpleado(props.dataEmpleado.DPI);
        }
        handleCerrarBackDrop();
      }
    };

    cargar();
  }, [cargandoMaestros]);

  const cargarDatosEmpleado = async (dpiEmpleado) => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/Empleado/Correo/" + dpiEmpleado;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setEmpleadoCorreo(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cargarMaestroTipoCorreo = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/TipoCorreo/";

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setTipoCorreo(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const guardarDato = async () => {
    const data = {
      CodigoEmpleadoCorreo: codigoEmpleadoCorreo,
      DPI: props.dataEmpleado.DPI,
      CodigoTipoCorreo: codigoTipoCorreo,
      CorreoElectronico: correoElectronico,
      Activo: true,
      Eliminado: false,
      UsuarioInserto: userName,
      FechaInserto: "",
      UsuarioModifico: "",
      FechaModifico: "",
    };

    console.log(data);
    // dataFicha.usuarioInserto = "prueba";
    try {
      handleAbrirBackDrop();
      let vIsSignedIn = await isSignedIn();
      if (vIsSignedIn.response) {
        let request;

        request = await API.post("api/Empleado/Correo/", data);
        if (request.status !== 200) {
          toast.error(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        } else {
          toast.success("Se guardó exitosamente");

          await cargarDatosEmpleado(props.dataEmpleado.DPI);

          setCorreoElectronico("");
          setCodigoEmpleadoCorreo(0);
          setCodigoTipoCorreo(0);

          setValueAutoCompleteTipoCorreo(null);
        }
      }
    } catch (e) {
      console.log("EXCEPTION: " + e);
      toast.error(
        "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
      );
    } finally {
      handleCerrarBackDrop();
      handleCloseModal();
    }
  };

  const cuandoHayError = async (data) => {
    toast.info(
      "Existen algunos campos con error, por favor valida el formulario",
      {
        position: "top-right",
        autoClose: 3000, // Esta en milisegundos 3 segundos
      }
    );
  };

  const onExporting = async (e) => {};

  const [dialogoAbierto, abrirDialogo] = useState(false);
  const [dialogoAbiertoEditar, abrirDialogoEditar] = useState(false);
  const handleAbrirDialogoEliminar = (id) => {
    abrirDialogo(true);

    setCodigoEmpleadoCorreo(id);
  };

  const handleCerrarDialogo = () => {
    abrirDialogo(false);
  };

  const handleAbrirDialogoEditar = (id) => {
    abrirDialogoEditar(true);

    setCodigoEmpleadoCorreo(id);
  };

  const handleCerrarDialogoEditar = () => {
    abrirDialogoEditar(false);
  };

  const quitarProyecto = async (pId) => {
    handleCerrarDialogo();

    handleAbrirBackDrop();

    const proyectosExcluidos = empleadoCorreo.filter(
      (elemento) => elemento.CodigoEmpleadoCorreo !== pId
    );

    console.log("Quitar" + proyectosExcluidos);

    try {
      let uri = "api/Empleado/Correo/Quitar/" + pId;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        toast.error(
          "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
        );
      } else {
        toast.success("Se quitó el registro con éxito", {
          position: "bottom-right",
          autoClose: 3000, // Esta en milisegundos 3 segundos
        });

        await cargarDatosEmpleado(props.dataEmpleado.DPI);

        setCorreoElectronico("");
        setCodigoEmpleadoCorreo(0);
        setCodigoTipoCorreo(0);

        setValueAutoCompleteTipoCorreo(null);
      }
    } catch (error) {
      console.log("Error en  eliminación de proyectos" + error);
    }

    handleCerrarBackDrop();

    //alert("Quitar recurso del proyecto" + codigoRecurso);
  };

  const editarProyecto = async (pId) => {
    handleCerrarDialogoEditar();
    handleAbrirBackDrop();

    const proyectosExcluidos = empleadoCorreo.filter(
      (elemento) => elemento.CodigoEmpleadoCorreo === pId
    );

    console.log("Editar el proyecto" + proyectosExcluidos);

    try {
      handleOpenModal();
      setCodigoEmpleadoCorreo(pId);
      setCorreoElectronico(proyectosExcluidos[0].CorreoElectronico);
      setCodigoTipoCorreo(proyectosExcluidos[0].CodigoTipoCorreo);

      const itemTipoCorreo = maestroTipoCorreo.filter(
        (elemento) =>
          elemento.CodigoTipoCorreo === proyectosExcluidos[0].CodigoTipoCorreo
      );
      setValueAutoCompleteTipoCorreo(itemTipoCorreo[0]);
    } catch (error) {
      console.log("Error en  edición de proyectos" + error);
    }

    handleCerrarBackDrop();

    //alert("Quitar recurso del proyecto" + codigoRecurso);
  };

  const cellOpciones = (rowInfo) => {
    return (
      permisoEditar && (
        <>
          <Tooltip
            title="Editar"
            style={{ marginRight: 10, cursor: "pointer" }}
          >
            <Edit
              style={{ color: "#337ab7", cursor: "pointer" }}
              onClick={() =>
                handleAbrirDialogoEditar(rowInfo.data.CodigoEmpleadoCorreo)
              }
            />
          </Tooltip>
          <Tooltip
            title="Eliminar"
            style={{ marginRight: 10, cursor: "pointer" }}
          >
            <Delete
              style={{ color: "#337ab7", cursor: "pointer" }}
              onClick={() =>
                handleAbrirDialogoEliminar(rowInfo.data.CodigoEmpleadoCorreo)
              }
            />
          </Tooltip>
        </>
      )
    );
  };

  const handleOnChangeTipoParentesco = async (event, newValue) => {
    handleAbrirBackDrop();
    if (newValue !== null) {
      setCodigoTipoCorreo(newValue.CodigoTipoCorreo);
      setValueAutoCompleteTipoCorreo(newValue);
    }
    handleCerrarBackDrop();
  };

  return (
    <React.Fragment>
      <Backdrop className={classes.backdrop} open={buscando}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer />

      <Dialog
        open={dialogoAbierto}
        onClose={handleCerrarDialogo}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Eliminación</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro que deseas eliminar este registro?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCerrarDialogo} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={() => quitarProyecto(codigoEmpleadoCorreo)}
            color="primary"
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={dialogoAbiertoEditar}
        onClose={handleCerrarDialogoEditar}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Edición</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro que deseas editar este registro?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCerrarDialogoEditar} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={() => editarProyecto(codigoEmpleadoCorreo)}
            color="primary"
          >
            Editar
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={BackdropModal}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paperModal}>
            <h2 id="simple-modal-title">Datos de Correo Electrónico</h2>
            <Grid container spacing={3}>
              <form
                autoComplete="off"
                onSubmit={handleSubmit(guardarDato, cuandoHayError)}
              >
                <Grid item xs={6} sm={6} md={12} lg={12}>
                  <FormControl className={classes.formControlSelects}>
                    <Controller
                      name="tipoCorreo"
                      required
                      render={({ TipoCorreo }) => (
                        <Autocomplete
                          id="tipoCorreo"
                          options={maestroTipoCorreo}
                          getOptionLabel={(option) => option.TipoCorreo}
                          value={valueAutoCompleteTipoCorreo}
                          onChange={(event, newValue) =>
                            handleOnChangeTipoParentesco(event, newValue)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Tipo Correo"
                              variant="outlined"
                            />
                          )}
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={6} sm={6} md={12} lg={12}>
                  <FormControl className={classes.formControlSelects}>
                    <Controller
                      name="correoElectronico"
                      render={({ CorreoElectronico }) => (
                        <TextField
                          id="correoElectronico"
                          label="Correo Electrónico"
                          name="correoElectronico"
                          value={correoElectronico}
                          onChange={(e) => setCorreoElectronico(e.target.value)}
                          variant="outlined"
                          helperText={
                            fieldsErrors.correoElectronico
                              ? fieldsErrors.correoElectronico.message
                              : ""
                          }
                          //   inputRef={refTelefono}
                          //   {...inputPropsTelefono}

                          //   rules={{
                          //     pattern: { value: /[0-9]/, message: "Solo se aceptan números" },
                          //     minLength: { value: 8, message: "No menor a 8 dígitos" },
                          //     maxLength: { value: 8, message: "No mayor a 8 dígitos" },
                          //   }}
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} md={12} lg={12}>
                  <Button
                    width="100%"
                    variant="contained"
                    style={{
                      backgroundColor: "#69bd4b",
                      marginBottom: 10,
                      marginTop: 10,
                      color: "white",
                      width: "93%",
                      marginLeft: 30,
                    }}
                    type="submit"
                  >
                    &nbsp;Guardar Cambios
                  </Button>
                </Grid>
              </form>
            </Grid>
          </div>
        </Fade>
      </Modal>
      <AppBar
        position="sticky"
        className={classes.appBar}
        style={{ backgroundColor: titleColor }}
      >
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            style={{ display: "flex", flexGrow: 1 }}
          >
            {`Correo Electrónico`}
          </Typography>
          {permisoEditar && (
            <Tooltip title={"Crear nuevo ítem"} aria-label="Crear nuevo ítem">
              <IconButton
                aria-label="Crear nuevo ítem"
                style={{ color: "white" }}
              >
                <Add onClick={handleOpenModal} />
              </IconButton>
            </Tooltip>
          )}
        </Toolbar>
      </AppBar>
      <Paper className={classes.rootTabla}>
        <DataGrid
          dataSource={empleadoCorreo}
          //   defaultColumns={this.props.columns}
          showBorders={true}
          rowAlternationEnabled={true}
          //ref={(ref) => (dataGrid = ref)}
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          onExporting={onExporting}
          className={classes.table}
        >
          <FilterRow visible={showFilterRow} applyFilter={currentFilter} />
          <HeaderFilter visible={showHeaderFilter} />
          <GroupPanel visible={false} />
          <Grouping autoExpandAll={autoExpandAll} />
          <SearchPanel visible={false} width={240} placeholder="Search..." />
          {/* <Selection mode="multiple" /> */}
          <Editing
            mode="row"
            useIcons={true}
            allowUpdating={false}
            allowDeleting={false}
          />
          <Column
            caption="Opciones"
            allowFiltering={false}
            allowSorting={false}
            cellRender={cellOpciones}
          />
          <Column
            dataField="CodigoEmpleadoCorreo"
            caption="Código DB"
            dataType="number"
            visible={false}
          />
          <Column
            dataField="CodigoTipoCorreo"
            caption="Código Tipo Correo"
            dataType="number"
            visible={false}
          />

          <Column dataField="CorreoElectronico" caption="Correo Electrónico" />
          <Column dataField="TipoCorreo" caption="Tipo Correo" />

          <Column
            dataField="Activo"
            caption="Fecha Activo"
            dataType="boolean"
          />

          <ColumnFixing enabled={true} />
          <Sorting mode="multiple" />
          <Paging defaultPageSize={20} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[10, 20, 50]}
            showInfo={true}
          />
          <Export enabled={true} allowExportSelectedData={true} />
          <Scrolling columnRenderingMode="virtual" />
        </DataGrid>
      </Paper>
    </React.Fragment>
  );
};

export default EmpleadoCorreo;
