export const getNavigationHistory = async () => {
  return new Promise((resolve, reject) => {
    let res = [];
    try {
      let navigationHistory = sessionStorage.getItem("navigationHistory");
      if (navigationHistory) {
        res = JSON.parse(navigationHistory);
      }
      resolve(res);
    } catch (err) {
      reject(res);
      console.error("Error al obtener el historial de navegación", err);
    }
  });

 
};

export const setNavigationHistory = async (newHistory) => {
  try {
    if (Array.isArray(newHistory)) {
      sessionStorage.setItem("navigationHistory", JSON.stringify(newHistory));
    }
  } catch (err) {
    console.error("Error al asignar el historial de navegación", err);
  }
};

export const clearNavigationHistory = async()=>{
    try{
        await sessionStorage.removeItem('navigationHistory');
    }catch(err){
        console.error("No se ha podido remover el historial de navegación");
    }

}
