import React, { useEffect, useState } from 'react'
import {
  Fab,
  Grid,
  Typography,
  Card,
  CardContent,
  CardActions,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Dialog,
  makeStyles
} from '@material-ui/core'
import { ToastContainer } from 'react-toastify'
import { Edit, HourglassFull, Add } from '@material-ui/icons'
import TiempoUpdate from './componentes/notificaciones/TiempoUpdate'

import Layout from '../layout/containers/Layout'
import api from '../utils/api'
import TiempoRetencion from './componentes/notificaciones/TiempoRetencion'

const useStyles = makeStyles(theme => ({
  tiemposList: {
    maxHeight: 400,
    overflowY: 'auto'
  },
  cardTitle: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  cardTitleText: {
    maxWidth: '80%'
  },
  cardTitleAction: {
    maxWidth: '20%'
  }
}))

const Notificaciones = () => {
  const classes = useStyles()
  const [selected, setSelected] = useState(null)
  const [porVencer, setPorVencer] = useState(null)
  const [vencido, setVencido] = useState(null)
  const [retencion, setRetencion] = useState([])
  const [dialogPorVencer, setDialogPorVencer] = useState(false)
  const [dialogVencido, setDialogVencido] = useState(false)
  const [dialogTiempoAdd, setDialogTiempoAdd] = useState(false)
  const [dialogTiempoUpdate, setDialogTiempoUpdate] = useState(false)
  const getPorVencer = async () => {
    const { data } = await api.fetchGetRequest('api/documentos/tiempo-recordatorio-por-vencer')
    setPorVencer(data)
  }
  const getVencido = async () => {
    const { data } = await api.fetchGetRequest('api/documentos/tiempo-recordatorio-vencido')
    setVencido(data)
  }
  const getRetencionItems = async () => {
    const { data } = await api.fetchGetRequest('api/documentos/tiempo-retencion')
    setRetencion(data.map(i => ({
      Tiempo: i.Valor / 365,
      Id: i.Id,
      ...i
    })))
  }
  const showUpdate = (idx) => {
    const tiempo = { ...retencion[idx] }
    setSelected(tiempo)
    setDialogTiempoUpdate(true)
  }
  const getDatos = () => {
    getPorVencer()
    getVencido()
    getRetencionItems()
  }
  useEffect(() => {
    getDatos()
  }, [])
  return <Layout titulo="Notificaciones">
    <Grid container spacing={3}>
      <Grid item xs={12} md={8}>
        <Card>
          <CardContent className={classes.content}>
            <div className={classes.cardTitle}>
              <Typography variant="h6" className={classes.cardTitleText}>
                Tiempos de retención
              </Typography>
              <Fab
                color="primary"
                size="small"
                className={classes.cardTitleAction}
                onClick={() => setDialogTiempoAdd(true)}
              >
                <Add />
              </Fab>
            </div>
            <List className={classes.tiemposList}>
              {
                retencion.map((i, idx) => <ListItem key={idx}>
                  <ListItemIcon>
                    <HourglassFull />
                  </ListItemIcon>
                  <ListItemText
                    primary={`${i.Tiempo} ${i.Tiempo === 1 ? 'año' : 'años'}`}
                    secondary={i.Descripcion}
                  />
                  <ListItemIcon>
                    <IconButton onClick={() => showUpdate(idx)}>
                      <Edit />
                    </IconButton>
                  </ListItemIcon>
                </ListItem>)
              }
            </List>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={4}>
        <Card>
          <CardContent>
            <div className={classes.cardTitle}>
              <Typography variant="h6" className={classes.cardTitleText}>
                Frecuencia de avisos para documentos por vencer
              </Typography>
              <Fab
                color="primary"
                size="small"
                className={classes.cardTitleAction}
                onClick={() => setDialogPorVencer(true)}
              >
                <Edit />
              </Fab>
            </div>
            <Typography variant="body1">
              Se enviará un aviso cada <strong>{porVencer ? porVencer.Valor : '--'}</strong> días
            </Typography>
          </CardContent>
        </Card>
        <Card style={{marginTop: 10}}>
          <CardContent>
            <div className={classes.cardTitle}>
              <Typography variant="h6" className={classes.cardTitleText}>
                Frecuencia de avisos para documentos vencidos
              </Typography>
              <Fab
                color="primary"
                size="small"
                className={classes.cardTitleAction}
                onClick={() => setDialogVencido(true)}
              >
                <Edit />
              </Fab>
            </div>
            <Typography variant="body1">
              Se enviará un aviso cada <strong>{vencido ? vencido.Valor : '--'}</strong> días
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
    <Dialog
      fullWidth
      maxWidth="md"
      open={dialogPorVencer}
      onClose={() => setDialogPorVencer(false)}
    >
      <TiempoUpdate
        datos={porVencer}
        min={20}
        max={60}
        onClose={() => setDialogPorVencer(false)}
        onRefresh={() => {
          getPorVencer()
          setDialogPorVencer(false)
        }}
      />
    </Dialog>
    <Dialog
      fullWidth
      maxWidth="md"
      open={dialogVencido}
      onClose={() => setDialogVencido(false)}
    >
      <TiempoUpdate
        datos={vencido}
        min={5}
        max={30}
        onClose={() => setDialogVencido(false)}
        onRefresh={() => {
          getVencido()
          setDialogVencido(false)
        }}
      />
    </Dialog>
    <Dialog
      fullWidth
      maxWidth="md"
      open={dialogTiempoAdd}
      onClose={() => setDialogTiempoAdd(false)}
    >
      <TiempoRetencion
        onClose={() => setDialogTiempoAdd(false)}
        onRefresh={() => {
          getRetencionItems()
          setDialogTiempoAdd(false)
        }}
      />
    </Dialog>
    <Dialog
      fullWidth
      maxWidth="md"
      open={dialogTiempoUpdate}
      onClose={() => setDialogTiempoUpdate(false)}
    >
      <TiempoRetencion
        datos={selected}
        update
        onClose={() => setDialogTiempoUpdate(false)}
        onRefresh={() => {
          getRetencionItems()
          setDialogTiempoUpdate(false)
        }}
      />
    </Dialog>
    <ToastContainer />
  </Layout>
}

export default Notificaciones
