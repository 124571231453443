import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/styles'
import { CircularProgress, Button } from '@material-ui/core'

const styles = theme => ({
})

const LoadingButton = ({ loading, done, ...other }) => {
    if (loading) {
    return (
      <Button {...other} disabled={true}>
        <CircularProgress size={24} color="secondary" />
      </Button>
    )
  } else {
    return (
      <Button {...other} />
    )
  }
}

LoadingButton.defaultProps = {
  loading: false,
  done: false,
  }

LoadingButton.propTypes = {
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  done: PropTypes.bool
}

export default withStyles(styles)(LoadingButton)